import { FormattedMessage } from "react-intl";
import { AppColors } from "src/components/common/Styling";
import styled from "styled-components/macro";

type Variant = "primary" | "secondary";

type Size = "xs" | "sm" | "md" | "lg";

type Color = "default" | "alternate" | "dark" | "danger";

// Buttons do not allow children
interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "children"> {
  intlTextId: string;
  size?: Size;
  color?: Color;
  fullWidthOfParent?: boolean;
}

interface MxReactButtonProps extends Omit<ButtonProps, "intlTextId"> {
  variant: Variant;
}

const getDisabledColor = (variant: Variant = "primary") =>
  variant === "primary"
    ? AppColors.neutral["light-navy-1"]
    : AppColors.neutral["light-navy-5"];

const getHeight = (size: Size = "md") =>
  ({
    xs: "20px",
    sm: "25px",
    md: "35px",
    lg: "45px",
  }[size]);

const getBorderRadius = (size: Size = "md") => {
  return size === "xs" || size === "sm" ? "4px" : "5px";
};

// There are numbers that don't line up with our grid units in here.
// They are there to be consistent with Mx styles
const getPadding = (size: Size = "md") =>
  ({
    xs: "3px 7px 1px",
    sm: "3px 13px 1px 13px",
    md: "8px 16px",
    lg: "8px 16px",
  }[size]);

// These are from Typography
const getFontSize = (size: Size = "md") =>
  ({
    xs: "11px", // h5
    sm: "14px", // h4
    md: "16px", // body
    lg: "20px", // h3
  }[size]);

const getFontWeight = (size: Size = "md") =>
  ({
    xs: "600",
    sm: "400",
    md: "300",
    lg: "300",
  }[size]);

const getBackgroundColor = (
  variant: Variant = "primary",
  color: Color = "default",
) => {
  if (variant === "primary") {
    return semanticToHex(color);
  }

  return "transparent";
};

const getHoverBackgroundColor = (
  variant: Variant = "primary",
  color: Color = "default",
) => {
  if (variant === "primary") {
    return {
      default: AppColors.semantic.green["dark-msr-green-1"],
      alternate: AppColors.neutral["light-navy-1"],
      dark: AppColors.neutral["dark-navy-2"],
      danger: AppColors.semantic.red["dark-red-3"],
    }[color];
  }

  return {
    default: AppColors.semantic.green["light-msr-green-8"],
    alternate: AppColors.neutral["light-navy-11"],
    dark: AppColors.neutral["light-navy-10"],
    danger: AppColors.semantic.red["light-red-7"],
  }[color];
};

const getBorder = (variant: Variant = "primary", color: Color = "default") => {
  if (variant === "primary") {
    return "none";
  }
  return `2px solid ${semanticToHex(color)}`;
};

const semanticToHex = (color: Color = "default") =>
  ({
    default: AppColors.primary["msr-green"],
    alternate: AppColors.neutral["light-navy-5"],
    dark: AppColors.neutral.navy,
    danger: AppColors.semantic.red.red,
  }[color]);

/**
 * This is only exported so that it can be targeted in styled components.
 * If you need to render a button, please use MxPrimaryReactButton or
 * MxSecondaryReactButton instead.
 */
export const MxReactButton = styled.button<MxReactButtonProps>`
  // this is common to all buttons
  white-space: nowrap;
  cursor: pointer;
  line-height: 0;

  height: ${({ size }) => getHeight(size)};
  border-radius: ${({ size }) => getBorderRadius(size)};
  padding: ${({ size }) => getPadding(size)};
  font-size: ${({ size }) => getFontSize(size)};
  font-weight: ${({ size }) => getFontWeight(size)};
  background-color: ${({ variant, color }) =>
    getBackgroundColor(variant, color)};
  border: ${({ variant, color }) => getBorder(variant, color)};
  color: ${({ variant = "primary", color = "default" }) =>
    variant === "primary"
      ? AppColors.neutral["light-gray-9"]
      : semanticToHex(color)};
  width: ${({ fullWidthOfParent }) => fullWidthOfParent && "100%"};
  :hover {
    background-color: ${({ variant, color }) =>
      getHoverBackgroundColor(variant, color)};
  }
  :disabled {
    pointer-events: none;
    color: ${({ variant }) => getDisabledColor(variant)};
    background-color: ${({ variant = "primary" }) =>
      variant === "primary" && AppColors.neutral["light-navy-10"]};
    border-color: ${AppColors.neutral["light-navy-10"]};
  }
`;

/**
 * A primary (filled) button component. To maintain consistency throughout the
 * app, no children allowed. This is a text only button that should be given
 * an intlTextId.
 * @param intlTextId string (required)
 * @param size "xs" | "sm" | "md" (default) | "lg"
 * @param color "default" (default) | "alternate" | "dark" | "danger"
 * @param fullWidthOfParent boolean (default false)
 */
export const MxPrimaryReactButton = (props: ButtonProps) => {
  const { intlTextId, ...rest } = props;

  return (
    <MxReactButton variant="primary" {...rest}>
      <FormattedMessage id={intlTextId} />
    </MxReactButton>
  );
};

/**
 * A secondary (outline) button component. To maintain consistency throughout
 * the app, no children allowed. This is a text only button that should be
 * given an intlTextId.
 * @param intlTextId string (required)
 * @param size "xs" | "sm" | "md" (default) | "lg"
 * @param color "default" (default) | "alternate" | "dark" | "danger"
 * @param fullWidthOfParent boolean (default false)
 */
export const MxSecondaryReactButton = (props: ButtonProps) => {
  const { intlTextId, ...rest } = props;

  return (
    <MxReactButton variant="secondary" {...rest}>
      <FormattedMessage id={intlTextId} />
    </MxReactButton>
  );
};
