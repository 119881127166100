import React, { ChangeEvent } from "react";
import styled from "styled-components/macro";
import { IconButton } from "src/components/common/Button";
import { AppColors } from "../Styling";

const darkGreen = AppColors.primary["msr-green"];
const white = AppColors.neutral["light-gray-9"];
const mediumGray = AppColors.neutral["light-navy-1"];

const StyleInput = styled.input<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>`
  text-align: center;
  margin: 0px 1px;
  padding: 4px;
  color: ${mediumGray};
  border: 0;
  background: ${white};
  font-size: 15px;
  min-width: 50px;
  width: 50px;
  max-width: 100px;
  :focus {
    outline: none;
  }
`;

const OutlineIconButton = styled(IconButton)`
  border: 1px solid;
  margin: -1px;
  border-color: ${darkGreen};
  color: ${darkGreen};
  border-radius: 8px;
  padding: 8px;
`;

const NumericInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: fit-content;
  border: 1px solid;
  border-color: ${darkGreen};
  border-radius: 8px;
`;

const NumericInput: React.FC<{
  value: number;
  onChange: (val: number) => void;
}> = ({ value, onChange }) => {
  return (
    <NumericInputWrapper>
      <OutlineIconButton
        data-testid="decrement-button"
        onClick={() => {
          onChange(value - 1);
        }}
      >
        -
      </OutlineIconButton>
      <StyleInput
        value={value}
        type="text"
        data-testid="threshold-value"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(parseInt(e.target.value, 10) || 0);
        }}
      />
      <OutlineIconButton
        data-testid="increment-button"
        onClick={() => {
          onChange(value + 1);
        }}
      >
        +
      </OutlineIconButton>
    </NumericInputWrapper>
  );
};

export default NumericInput;
