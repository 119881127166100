import styled from "styled-components/macro";

/* =============================================================== *\
   Body 300, 16px, L20, P15
\* =============================================================== */
export const Body = styled.div`
  font-weight: 300;
  line-height: 20px;
  font-size: 16px;
`;

// this is a Label, so why it is in BodyCopy we may never know....
// that said, it is the same as Body style-wise
export const BodyLabel = styled.label`
  font-weight: 300;
  line-height: 20px;
  font-size: 16px;
`;

export default Body;
