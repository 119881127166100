import React, { useContext } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import CreateProgress from "./CreateProgress";
import {
  OverlayFormAction,
  OverlayFormState,
  OverlayType,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import {
  BuildingAnnotationReason,
  ExceptionOperatingPeriodInput,
} from "src/types/graphql";
import { ToggleButton } from "src/components/common/Button";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ErrorToast, SuccessToast } from "src/components/common/Toast";
import {
  CreateScheduleExceptionDocument,
  GetOperatingScheduleDocument,
  LoadScheduleExceptionsForBuildingInExplorerDocument,
  ModifyScheduleExceptionDocument,
} from "src/queries/typed";
import {
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import { HyperLink2 } from "src/components/common/Link";
import { CurrentOrganizationContext } from "src/components/app/AuthenticatedPage";
import { BodyLabel } from "src/components/common/BodyCopy";
import DatePickerOverlay, {
  CalendarIcon,
  NewDatePickerInputStyles,
} from "src/components/common/TimeRangePicker/DatePickerOverlay";
import { useUserContext } from "src/auth/UserContext";
import { ChartDataAction } from "../../chartDataReducer";
import {
  BuildingScheduleExceptionsResponse,
  ScheduleStorage,
} from "../../types";
import { ITimeRange } from "src/types/charting";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import { tracking } from "src/tracking";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { getLocalDateFormat } from "src/helpers/dates";
import { useNavigate } from "react-router-dom";
import {
  MxPrimaryReactButton,
  MxSecondaryReactButton,
} from "src/componentLibrary/react/mx-button/MxReactButton";
import TimePicker from "src/components/common/TimePicker";
import { Info, MxReactIcon } from "src/componentLibrary/react/mx-icon-react";
import ErrorMessage from "src/components/common/ErrorMessage/ErrorMessage";
import { convertStateToMutationVariablesForStepThreeMultiDay } from "src/components/app/ExplorerPage/OverlayStuff/CreateOverlayStuff/convertStateToMutationVariablesForStepThree";
import {
  formatDateInLocalDateFormat,
  getStartAndEndDatesFromFormData,
  parseDateFromLocalDateFormat,
  timeRangeEndsBeforeStart,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";

const ScheduleStepThreeExceptionsMulti = (props: {
  dispatch: (arg0: OverlayFormAction) => void;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
  state: OverlayFormState;
  timeRange: ITimeRange;
}) => {
  const [
    ,
    setScheduleExceptionStorage,
  ] = useLocalStorage<ScheduleStorage | null>("schedule-exception", null);
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const translate = useIntl();
  const { userData } = useUserContext();
  const userId = userData.id;

  const { data: operatingScheduleResponse } = useQuery(
    GetOperatingScheduleDocument,
    {
      variables: { buildingId: props.state.formData.buildingId ?? "" },
      onCompleted: data => {
        if (data && !data.getBuildingById) {
          navigate("/app/404");
        }
      },
    },
  );

  const [createException, { loading }] = useMutation(
    CreateScheduleExceptionDocument,
    {
      onError: e => {
        if (e.message.includes("overlap")) {
          ErrorToast("settings.building.operatingSchedule.conflictError");
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
      onCompleted: response => {
        if (response.createBuildingOperatingScheduleException?.success) {
          tracking.fireEvent(ExplorerPageEvents.COMPLETE_ANNOTATION_CREATE, {
            userId,
            buildingID: props.state.formData.buildingId,
            type: props.state.formData.overlayType,
          });
          SuccessToast("settings.building.operatingSchedule.saved");

          // Reload the exceptions so the user can see the new one!
          fetchScheduleExceptions(
            props.state.formData.buildingId!,
            props.state.formData.timezone!,
          );
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
    },
  );

  const [modifyException, { loading: modLoading }] = useMutation(
    ModifyScheduleExceptionDocument,
    {
      onError: e => {
        if (e.message.includes("overlap")) {
          ErrorToast("settings.building.operatingSchedule.conflictError");
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
      onCompleted: response => {
        if (response.modifyScheduleException?.id) {
          tracking.fireEvent(ExplorerPageEvents.COMPLETE_ANNOTATION_CREATE, {
            userId,
            buildingID: props.state.formData.buildingId,
            type: props.state.formData.overlayType,
          });
          SuccessToast("settings.building.operatingSchedule.saved");
          // Reload the exceptions so the user can see the new one!
          fetchScheduleExceptions(
            props.state.formData.buildingId!,
            props.state.formData.timezone!,
          );
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
    },
  );

  const fetchScheduleExceptions = async (
    buildingId: string,
    tz: string | undefined,
  ) => {
    const { data, errors } = await apolloClient.query({
      query: LoadScheduleExceptionsForBuildingInExplorerDocument,
      variables: {
        buildingId,
      },
      fetchPolicy: "network-only", // forces the data to get refreshed
    });

    if (errors) {
      console.error(
        "failed to receive schedule exceptions for building %s",
        buildingId,
      );
      return;
    } else if (data.getBuildingById?.scheduleExceptions) {
      if (props.state.formData.overlayId) {
        props.chartDataDispatch({
          type: "REMOVE_OVERLAY_EVENT_FROM_CHART",
          payload: {
            deletedOverlayId: props.state.formData.overlayId,
          },
        });
      }
      props.chartDataDispatch({
        type: "RECEIVE_SCHEDULE_EXCEPTION_EVENT_DATA",
        payload: {
          response: data as BuildingScheduleExceptionsResponse,
          timezone: tz,
          timeRange: props.timeRange,
        },
      });
    }
  };

  const { currentOrganizationId } = useContext(CurrentOrganizationContext);
  const { startDate, endDate } = getStartAndEndDatesFromFormData(
    props.state.formData,
  );
  const isHoliday = props.state.formData.annotationCategories?.includes(
    BuildingAnnotationReason.HOLIDAY,
  );

  // Form and component state
  const [isOccupied, setIsOccupied] = React.useState(
    props.state.formData.isOccupied ?? true,
  );
  const {
    getValues,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      startDate,
      endDate,
    },
  });
  const currentStartDate = watch("startDate");
  const currentEndDate = watch("endDate");

  const handleBackButton = () => {
    if (props.state.overlayPanel.state === "EDIT_3") {
      props.dispatch({ type: "GO_EDIT_STEP_TWO" });
    } else {
      props.dispatch({ type: "GO_STEP_TWO" });
    }
  };

  const handleSaveStep = () => {
    props.dispatch({
      type: "SAVE_STEP_THREE",
    });

    const variables = convertStateToMutationVariablesForStepThreeMultiDay(
      {
        ...props.state.formData,
        startDate: getValues("startDate"),
        endDate: getValues("endDate"),
        isOccupied,
        startTime: DateTime.fromJSDate(getValues("startDate")).hour,
        endTime: DateTime.fromJSDate(getValues("endDate")).hour,
      },
      operatingScheduleResponse?.getBuildingById?.operatingSchedule,
    );

    if (props.state.formData.overlayType === OverlayType.ScheduleException) {
      if (props.state.formData.overlayId) {
        modifyException({
          variables: {
            ...variables,
            exceptionId: props.state.formData.overlayId,
            exceptionOperatingPeriods: variables.exceptionOperatingPeriods as ExceptionOperatingPeriodInput[],
          },
        });
      } else {
        createException({
          variables,
        });
      }
    }
  };

  const handleMoreOptionsClick = () => {
    tracking.fireEvent(ExplorerPageEvents.MORE_OPTIONS, {
      buildingId: props.state.formData.buildingId,
      userId,
    });

    const { formData } = props.state;

    const startDate = getValues("startDate");
    const endDate = getValues("endDate");

    const startTime = DateTime.fromJSDate(startDate);
    const endTime = DateTime.fromJSDate(endDate);

    setScheduleExceptionStorage({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      title: formData.title,
      note: formData.note,
      annotationCategories: formData.annotationCategories,
      startHour: startTime.get("hour"),
      endHour: endTime.get("hour"),
      isOccupied,
    });
  };

  return (
    <>
      <CreateProgress step={3} />
      <fieldset style={{ border: "none" }}>
        <Box margin={[6, 0, 8, 0]}>
          <VerticalLayout childSpacing={4}>
            <BodyLabel>
              <MxReactIcon Icon={Info} />{" "}
              <FormattedMessage id="charts.explorer.overlays.create.advancedOptions" />{" "}
              <HyperLink2
                href={
                  props.state.formData.overlayId
                    ? `/settings/${currentOrganizationId}/building/${props.state.formData.buildingId}/operating-schedule/${props.state.formData.overlayId}/edit`
                    : `/settings/${currentOrganizationId}/building/${props.state.formData.buildingId}/operating-schedule/create`
                }
                target="_new"
                onClick={handleMoreOptionsClick}
              >
                <FormattedMessage id="charts.explorer.overlays.create.moreOptions" />
              </HyperLink2>
            </BodyLabel>
            <NewDatePickerInputStyles />
            <Box margin={[6, 0, 8, 0]}>
              <VerticalLayout childSpacing={4}>
                {/* THE FIRST DATE ROW (start date and time) */}
                <HorizontalLayout childSpacing={2}>
                  <VerticalLayout childSpacing={2}>
                    <BodyLabel htmlFor="startDate">
                      <FormattedMessage id="settings.building.operatingScheduleExceptions.form.startDate" />
                    </BodyLabel>
                    <HorizontalLayout>
                      <CalendarIcon />
                      <Controller
                        name="startDate"
                        control={control}
                        rules={{
                          required: translate.formatMessage({
                            id:
                              "settings.building.operatingScheduleExceptions.form.validation.startDate.required",
                          }),
                        }}
                        render={({ field: { onChange, value } }) => (
                          <DayPickerInput
                            inputProps={{
                              disabled: isHoliday,
                              required: true,
                              id: "startDate",
                              className: timeRangeEndsBeforeStart(
                                currentStartDate,
                                currentEndDate,
                              )
                                ? "invalid"
                                : "",
                            }}
                            dayPickerProps={{
                              modifiers: {
                                disabled: { after: currentEndDate },
                              },
                            }}
                            placeholder={getLocalDateFormat()}
                            parseDate={parseDateFromLocalDateFormat}
                            formatDate={formatDateInLocalDateFormat}
                            overlayComponent={DatePickerOverlay}
                            onDayChange={day => {
                              onChange(day);

                              const startDate = DateTime.fromJSDate(day);

                              // We don't allow more than 7 days, so adjust the end date
                              if (
                                Math.abs(
                                  startDate.diff(
                                    DateTime.fromJSDate(getValues("endDate")),
                                    ["days"],
                                  ).days,
                                ) > 7
                              ) {
                                setValue(
                                  "endDate",
                                  startDate.plus({ days: 7 }).toJSDate(),
                                );
                              }
                            }}
                            value={value}
                          />
                        )}
                      />
                    </HorizontalLayout>
                  </VerticalLayout>
                  <VerticalLayout childSpacing={2}>
                    <BodyLabel htmlFor="startTime">
                      <FormattedMessage id="settings.building.operatingScheduleExceptions.form.startTime" />
                    </BodyLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{
                        required: translate.formatMessage({
                          id:
                            "settings.building.operatingScheduleExceptions.form.validation.startDate.required",
                        }),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          disabled={isHoliday}
                          handleOnChange={onChange}
                          keyName="startTime"
                          defaultTime={value}
                        />
                      )}
                    />
                  </VerticalLayout>
                </HorizontalLayout>
                {/* THE SECOND DATE ROW (start date and time) */}
                <HorizontalLayout childSpacing={2}>
                  <VerticalLayout childSpacing={2}>
                    <BodyLabel htmlFor="endDate">
                      <FormattedMessage id="settings.building.operatingScheduleExceptions.form.endDate" />
                    </BodyLabel>
                    <HorizontalLayout>
                      <CalendarIcon />
                      <Controller
                        name="endDate"
                        control={control}
                        rules={{
                          required: translate.formatMessage({
                            id:
                              "settings.building.operatingScheduleExceptions.form.validation.startDate.required",
                          }),
                        }}
                        render={({ field: { onChange, value } }) => (
                          <DayPickerInput
                            inputProps={{
                              disabled: isHoliday,
                              required: true,
                              id: "endDate",
                              className: timeRangeEndsBeforeStart(
                                currentStartDate,
                                currentEndDate,
                              )
                                ? "invalid"
                                : "",
                            }}
                            dayPickerProps={{
                              modifiers: {
                                disabled: {
                                  after: DateTime.fromJSDate(currentStartDate)
                                    .plus({ days: 7 })
                                    .toJSDate(),
                                },
                              },
                            }}
                            placeholder={getLocalDateFormat()}
                            parseDate={parseDateFromLocalDateFormat}
                            formatDate={formatDateInLocalDateFormat}
                            overlayComponent={DatePickerOverlay}
                            value={value}
                            onDayChange={day => onChange(day)}
                          />
                        )}
                      />
                    </HorizontalLayout>
                  </VerticalLayout>
                  <VerticalLayout childSpacing={2}>
                    <BodyLabel htmlFor="endTime">
                      <FormattedMessage id="settings.building.operatingScheduleExceptions.form.endTime" />
                    </BodyLabel>
                    <Controller
                      name="endDate"
                      control={control}
                      rules={{
                        required: translate.formatMessage({
                          id:
                            "settings.building.operatingScheduleExceptions.form.validation.startDate.required",
                        }),
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          disabled={isHoliday}
                          keyName="endTime"
                          handleOnChange={onChange}
                          defaultTime={value}
                        />
                      )}
                    />
                  </VerticalLayout>
                </HorizontalLayout>
              </VerticalLayout>
            </Box>
            <BodyLabel>
              <FormattedMessage id="settings.building.operatingScheduleExceptions.form.isDuringOperating" />
            </BodyLabel>
            <HorizontalLayout childSpacing={4}>
              <ToggleButton
                onClick={() => setIsOccupied(true)}
                isEnabled={isOccupied}
              >
                <FormattedMessage id="common.button.labels.yes" />
              </ToggleButton>
              <ToggleButton
                onClick={() => setIsOccupied(false)}
                isEnabled={!isOccupied}
              >
                <FormattedMessage id="common.button.labels.no" />
              </ToggleButton>
            </HorizontalLayout>
          </VerticalLayout>
        </Box>
      </fieldset>
      {timeRangeEndsBeforeStart(
        getValues("startDate"),
        getValues("endDate"),
      ) && (
        <ErrorMessage>
          <FormattedMessage id="settings.building.operatingScheduleExceptions.form.validation.startDate.validation" />
        </ErrorMessage>
      )}
      <HorizontalLayout childSpacing={2}>
        <MxSecondaryReactButton
          fullWidthOfParent
          onClick={handleBackButton}
          disabled={loading || modLoading}
          intlTextId="common.button.labels.back"
        />
        <MxPrimaryReactButton
          fullWidthOfParent
          onClick={handleSaveStep}
          disabled={
            Object.keys(errors).length > 0 ||
            loading ||
            modLoading ||
            timeRangeEndsBeforeStart(currentStartDate, currentEndDate)
          }
          intlTextId="common.button.labels.save"
        />
      </HorizontalLayout>
    </>
  );
};
export default ScheduleStepThreeExceptionsMulti;
