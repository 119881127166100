import React from "react";
import { FormattedMessage } from "react-intl";
import { MxReactIcon, X } from "src/componentLibrary/react/mx-icon-react";
import { H3, Body } from "src/components/common/Typography";
import {
  TintedBGModal,
  ModalHeader,
  ModalRow,
  BulletList,
} from "src/components/common/ModalParts";
import { AppColors } from "src/components/common/Styling";
import { HyperLink2 } from "src/components/common/Link";
import config from "src/configuration";

interface BaselineManagementModalProps {
  closeOnClick: () => void;
}

const supportEmail = (
  <HyperLink2 href={"mailto:" + config.SUPPORT_EMAIL}>
    <FormattedMessage id="support.url" />
  </HyperLink2>
);

export const BaselineManagementModal: React.FC<BaselineManagementModalProps> = ({
  closeOnClick,
}) => {
  return (
    <TintedBGModal>
      <ModalHeader>
        <H3>
          <FormattedMessage id="performance.baselineManagement.modal.title" />
        </H3>
        <div style={{ display: "inline-block" }} onClick={closeOnClick}>
          <MxReactIcon
            Icon={X}
            color={AppColors.neutral.navy}
            style={{ marginLeft: "30px" }}
          />
        </div>
      </ModalHeader>
      <ModalRow>
        <Body>
          <FormattedMessage
            id={"performance.baselineManagement.modal.request"}
            values={{ supportEmail: supportEmail }}
          />
        </Body>
      </ModalRow>
      <BulletList>
        <li>
          <ModalRow>
            <Body>
              <FormattedMessage
                id={"performance.baselineManagement.modal.bullet1"}
              />
            </Body>
          </ModalRow>
        </li>
        <li>
          <ModalRow>
            <Body>
              <FormattedMessage
                id={"performance.baselineManagement.modal.bullet2"}
              />
            </Body>
            <Body>
              <FormattedMessage
                id={"performance.baselineManagement.modal.bullet2b"}
              />
            </Body>
          </ModalRow>
        </li>
        <li>
          <ModalRow>
            <Body>
              <FormattedMessage
                id={"performance.baselineManagement.modal.bullet3"}
              />
            </Body>
          </ModalRow>
        </li>
        <li>
          <ModalRow>
            <Body>
              <FormattedMessage
                id={"performance.baselineManagement.modal.bullet4"}
              />
            </Body>
          </ModalRow>
        </li>
      </BulletList>
    </TintedBGModal>
  );
};
