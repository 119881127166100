import { DateTime, Duration } from "luxon";
import { ITimeRange, ChartDataGranularity } from "src/types/charting";
import { ComparePeriod } from "src/types/charting";

// note that this does not return the "proper" shifted time if that is
// what you are looking for. Use:
const dateFromLuxonParts = (s: DateTime): Date => {
  const _date = new Date(
    s.year,
    s.month - 1,
    s.day,
    s.hour,
    s.minute,
    s.second,
    s.millisecond,
  );
  return _date;
};
/**
 * Makes a DateTime of a date but in a new timezone!
 *
 * @param date
 * @param timezone
 */
export const tzLuxon = (date: Date, timezone: string): DateTime => {
  const _d = DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
      millisecond: date.getMilliseconds(),
    },
    {
      zone: timezone,
    },
  );
  return _d;
};
/**
 * Moves a date to a new timezone. For example, if it is 1am on
 * July 31 for a user in New York, but they are looking at a building
 * in Los Angeles, the date there is different, so "yesterdays" data
 * would be for the 29th (building time) not the 30th.
 *
 * @param date The date to move to a timezone
 * @param timezone The timezone it should live in
 * @returns A Date object that has been shifted to the new timezone
 */
export const tzDate = (date: Date, timezone: string): Date => {
  const _sp = tzLuxon(date, timezone);
  const dateFromSP = _sp.toJSDate();
  return dateFromSP;
};

/**
 * Creates a range "endpoint" that is the last full day in a given timezone.
 * Useful for making pickers that let a user pick ranges, but we only want
 * them to be able to pick days in the past based on building timezone.
 *
 * @param timezone - the IANA timezone string you want the limit to be in
 * @returns - A date object that you can use to constrain the user to
 */
export const rangeToLimitForTimezone = (timezone: string): Date => {
  return offsetDateInTimezone(timezone, Duration.fromObject({ day: 1 }));
};

/**
 * Creates a range "endpoint" that is the last full day in a given timezone.
 * Useful for making pickers that let a user pick ranges, but we only want
 * them to be able to pick days in the past based on building timezone.
 *
 * @param timezone - the IANA timezone string you want the limit to be in
 * @param offset - the number of days you want subtracted from the date
 * @param makeMidnight - should the returned date be midnight (in the requested timezone)
 * @returns - A date object that you can use to constrain the user to
 */
export const offsetDateInTimezone = (
  timezone: string,
  offset: Duration,
): Date => {
  const _sp = DateTime.local()
    .setZone(timezone)
    .minus(offset);
  return dateFromLuxonParts(_sp);
};

/**
 * Takes a date, and gives you back midnight on that day in the specified
 * timezone. Very handy for getting start datetimes for graphQL queries!
 *
 * @param date The date you want moved and midnighted
 * @param timezone - The timezone you would like that midnight to be in
 */
export const midnightInTimezone = (date: Date, timezone: string): Date => {
  const _d = tzLuxon(date, timezone).startOf("day");
  return _d.toJSDate();
};
/**
 * Takes a date, and gives you back the last possible milisecond on that day
 * in the specified timezone. Very handy for getting end datetimes for graphQL queries!
 *
 * @param date The date you want moved and midnighted
 * @param timezone - The timezone you would like that midnight to be in
 */
export const lastMomentInTimezone = (date: Date, timezone: string): Date => {
  const _d = tzLuxon(date, timezone).endOf("day");
  return _d.toJSDate();
};

/**
 * Gives me the number of days between two dates. Order does not
 * matter, it will give you a positive number always. Please note
 * that this function will *lie* to you on purpose sometimes. If the Dates
 * are more than 24 hours apart, it will tell you there are TWO
 * days between them, even if they are on consecutive calendar days.
 * This is because our UI treats them that way.
 */
export const daysBetweenDates = (
  startDate: string | Date,
  endDate: string | Date,
): number => {
  const _s = typeof startDate === "string" ? new Date(startDate) : startDate;
  const _e = typeof endDate === "string" ? new Date(endDate) : endDate;
  const _start = DateTime.fromJSDate(_s);
  const _end = DateTime.fromJSDate(_e);
  const diffDuration = _end.diff(_start, ["days", "hours"]);
  // NOTE: Luxon will return partial days... so a 16hr difference is 0.75
  const dayDiff = Math.abs(diffDuration.days);
  //const dayDiff = Math.abs(Math.floor(dayDuration.days));

  if (dayDiff === 1) {
    const hourDiff = Math.abs(diffDuration.hours);
    return hourDiff >= 1 ? 2 : 1;
  }
  return dayDiff;
};

export const secondaryDateRange = (
  range: ITimeRange,
  period:
    | ComparePeriod.PAST_YEAR
    | ComparePeriod.PREVIOUS_PERIOD
    | ComparePeriod.NONE,
  timezone: string,
): ITimeRange => {
  // NOTE: You probably shouln't be calling this NONE but we will handle it anyway
  //       and return a silly range for you, you silly person.
  if (period === ComparePeriod.NONE) {
    return {
      startTime: "1971-01-01T00:00:00.000Z",
      endTime: "1971-01-02T00:00:00.000Z",
    };
  }
  const _start = DateTime.fromJSDate(new Date(range.startTime), {
    zone: timezone,
  });
  const _end = DateTime.fromJSDate(new Date(range.endTime), { zone: timezone });
  const _rangeSize = Math.abs(_start.diff(_end, "days").days);
  // get a "default" start date based on range size
  // move that date BACK if the day of week does not match
  // make an end date based on the day diff
  const rangeDuration =
    period === ComparePeriod.PREVIOUS_PERIOD
      ? Duration.fromObject({ days: _rangeSize })
      : Duration.fromObject({ years: 1 });
  return calculatePastRange(_start, _end, rangeDuration, period, timezone);
};

export const customSecondaryRange = (
  range: ITimeRange,
  timezone: string,
  secondaryRangeDistance: number /**  how far away is the custom start from current start  */,
) => {
  const _start = DateTime.fromJSDate(new Date(range.startTime), {
    zone: timezone,
  });
  const _end = DateTime.fromJSDate(new Date(range.endTime), { zone: timezone });
  const _duration = Duration.fromObject({ days: secondaryRangeDistance });
  return calculatePastRange(
    _start,
    _end,
    _duration,
    ComparePeriod.CUSTOM,
    timezone,
  );
};

const calculatePastRange = (
  start: DateTime,
  end: DateTime,
  rangeDuration: Duration,
  period: ComparePeriod,
  timezone: string,
) => {
  const _targetDayOfWeek = start.weekday;
  const _rangeSize = Math.abs(start.diff(end, "days").days);
  // note: we are not calling the start date dumb, so don't feel bad for it.
  const _dumbStartDate = start.minus(rangeDuration).setZone("utc");
  const _dumbStartDateDayOfWeek = _dumbStartDate.weekday;

  const _dowDiff = Math.abs(_targetDayOfWeek - _dumbStartDateDayOfWeek);
  let _dowAdjustDays: number = 0;
  if (_rangeSize < 4 && period === ComparePeriod.PREVIOUS_PERIOD) {
    // OLD "ALWAYS" GO BACK METHOD (multiply by -1 though since we switched to adding down below)
    const _adjustAmt = _dumbStartDateDayOfWeek < _targetDayOfWeek ? 7 : 0;
    _dowAdjustDays =
      Math.abs(_targetDayOfWeek - _dumbStartDateDayOfWeek - _adjustAmt) * -1;
  } else {
    if (_dowDiff < 4) {
      _dowAdjustDays = _targetDayOfWeek - _dumbStartDateDayOfWeek;
    } else {
      const _ff = _targetDayOfWeek < _dumbStartDateDayOfWeek ? 1 : -1;
      _dowAdjustDays = _targetDayOfWeek - (_dumbStartDateDayOfWeek - 7 * _ff);
    }
  }
  const newStart = _dumbStartDate.plus(
    Duration.fromObject({ days: _dowAdjustDays }),
  );
  const _dumbEndDate = end.minus(rangeDuration).setZone("utc");
  const _adjEnd = _dumbEndDate.plus(
    Duration.fromObject({ days: _dowAdjustDays }),
  );
  const newEnd = _adjEnd;
  return {
    startTime: newStart
      .setZone(timezone)
      .toUTC()
      .toISO() as string,
    endTime: newEnd
      .setZone(timezone)
      .toUTC()
      .toISO() as string,
  };
};

/**
 * Given a time range, return a time range that is the next zoom increment.
 * These are determined by UX requirements and are as such:
 *
 *  day
 *  7 days (week)
 *  14 days (2 weeks)
 *  1 month
 *  2 months
 *  3 months
 *  6 months
 *  12 months
 *
 * The end date of the input range is unchanged, and only the start date is moved to
 * create the new range.
 *
 * So if you input a range of 5 days, you'd get a 7 day back. 95 days in, 6 months out, etc.
 * If your input range is greater than 1 year, it is returned unchanged.
 *
 */
export const zoomOutRange = (currentRange: ITimeRange): ITimeRange => {
  // use the timeRange and get the end date / time
  // and the number of days between the start and end.
  // then figure out the new range and make an ITimeRangeChange
  // that has those dates and return that.

  // note the zone is set to UTC so we get "Z" formatted strings out
  const _s = DateTime.fromISO(currentRange.startTime, { zone: "utc" });
  const _e = DateTime.fromISO(currentRange.endTime, { zone: "utc" });
  // we will return this, likely after modifying the start time.
  const newRange: ITimeRange = {
    startTime: currentRange.startTime,
    endTime: currentRange.endTime,
  };
  const distance = _e.diff(_s, ["months", "days"]);
  if (distance.months === 0) {
    if (distance.days < 7) {
      newRange.startTime = _e
        .minus(Duration.fromObject({ days: 7 }))
        .toISO() as string;
    } else if (distance.days < 14) {
      newRange.startTime = _e
        .minus(Duration.fromObject({ days: 14 }))
        .toISO() as string;
    } else {
      newRange.startTime = _e
        .minus(Duration.fromObject({ months: 1 }))
        .toISO() as string;
    }
  } else {
    if (distance.months < 2) {
      newRange.startTime = _e
        .minus(Duration.fromObject({ months: 2 }))
        .toISO() as string;
    } else if (distance.months < 3) {
      newRange.startTime = _e
        .minus(Duration.fromObject({ months: 3 }))
        .toISO() as string;
    } else if (distance.months < 6) {
      newRange.startTime = _e
        .minus(Duration.fromObject({ months: 6 }))
        .toISO() as string;
    } else if (distance.months <= 12) {
      newRange.startTime = _e
        .minus(Duration.fromObject({ years: 1 }))
        .toISO() as string;
    }
    // note that if we are already 12 or more months, we don't do anything
    // and just return the unmodified newRange which was the same as the currentRange
  }
  return newRange;
};

/**
 * Returns the size of a time window based on the granularity.
 * @param granularity
 */
const getDurationForGranularity = (
  granularity: ChartDataGranularity,
): Duration => {
  let duration: Duration;
  switch (granularity) {
    case ChartDataGranularity.Month: // 10 years = 120 data points
      duration = Duration.fromObject({ years: 10 });
      break;
    case ChartDataGranularity.Day: // 3 years = 1095 data points
      duration = Duration.fromObject({ years: 3 });
      break;
    case ChartDataGranularity.Hour: // 182 days = 4368 data points, UI shows 45 days max
      duration = Duration.fromObject({ months: 6 });
      break;
    case ChartDataGranularity.FifteenMinute: // 90 days = 8640 data points, UI shows 14 days max
      duration = Duration.fromObject({ months: 3 });
      break;
    case ChartDataGranularity.FiveMinute: // 90 days = 25920 data points, UI shows 14 days max
      duration = Duration.fromObject({ months: 3 });
      break;
    default:
      duration = Duration.fromObject({ days: 30 });
      break;
  }

  return duration;
};

/**
 * Returns the maximum amount of days shown on a chart for a given granularity.
 * @param granularity
 */
const getMaxDaysForGranularity = (
  granularity: ChartDataGranularity,
): number => {
  const bufferOffset = 1.5;
  let dayRange: number;

  switch (granularity) {
    case ChartDataGranularity.Month:
      dayRange = 365;
      break;
    case ChartDataGranularity.Day:
      dayRange = 365;
      break;
    case ChartDataGranularity.Hour:
      dayRange = 45;
      break;
    case ChartDataGranularity.FifteenMinute:
      dayRange = 14;
      break;
    case ChartDataGranularity.FiveMinute:
      dayRange = 14;
      break;
    default:
      throw Error("Unrecognized granularity!");
  }

  return dayRange * bufferOffset;
};

/**
 * Returns a time range window based on the granularity and the end date. If a date isn't provided, the
 * first day of the year is used as a default.
 * @param granularity
 * @param timezone
 * @param endOfRange
 */
const timeRangeBucket = (
  granularity: ChartDataGranularity,
  timezone: string | undefined,
  endOfRange?: Date,
): ITimeRange => {
  if (!endOfRange) {
    // Default to end of this year if an end date isn't provided
    endOfRange = DateTime.fromJSDate(new Date())
      .setZone(timezone || "utc")
      .startOf("year")
      .plus({ year: 1 })
      .toJSDate();
  }

  const duration: Duration = getDurationForGranularity(granularity);

  const startOfRange: Date = DateTime.fromJSDate(endOfRange, {
    zone: timezone || "utc",
  })
    .minus(duration)
    .toJSDate();

  return {
    startTime: startOfRange.toISOString(),
    endTime: endOfRange.toISOString(),
  };
};

/**
 * Returns the time range window for a given granularity that a date falls into.
 * @param granularity
 * @param intervalDate
 * @param endOfRange
 */
const timeRangeWindowForDate = (
  granularity: ChartDataGranularity,
  intervalDate: Date,
  timezone: string | undefined,
  endOfRange?: Date,
): ITimeRange => {
  const { startTime, endTime } = timeRangeBucket(
    granularity,
    timezone,
    endOfRange,
  );

  // End of the range should not exceed tomorrow
  const maxEndTime = tzLuxon(new Date(), timezone || "utc")
    .startOf("day")
    .plus({
      day: 1,
    })
    .toJSDate();

  const finalEndTime: Date =
    new Date(endTime).getTime() > maxEndTime.getTime()
      ? maxEndTime
      : new Date(endTime);

  if (intervalDate.getTime() > finalEndTime.getTime()) {
    throw Error("interval date must come prior to end date");
  }

  if (
    intervalDate.getTime() >= new Date(startTime).getTime() &&
    intervalDate.getTime() <= finalEndTime.getTime()
  ) {
    return {
      startTime,
      endTime: finalEndTime.toISOString(),
    };
  }

  return timeRangeWindowForDate(
    granularity,
    intervalDate,
    timezone,
    new Date(startTime),
  );
};

/**
 * Returns time range windows for a given date range based on the requested granularity
 * @param granularity
 * @param chartDateRange the date range currently displayed on the chart
 * @param timezone
 */
export const timeRangeWindowByGranularity = (
  granularity: ChartDataGranularity,
  chartDateRange: ITimeRange,
  timezone: string | undefined,
): ITimeRange[] => {
  const startTime: Date = DateTime.fromISO(chartDateRange.startTime, {
    zone: timezone,
  }).toJSDate();
  const endTime: Date = DateTime.fromISO(chartDateRange.endTime, {
    zone: timezone,
  }).toJSDate();
  const currentDate = new Date();

  if (startTime.getTime() > endTime.getTime()) {
    throw Error("Invalid date range");
  }

  if (startTime.getTime() > currentDate.getTime()) {
    throw Error("Start time must be prior to the current date time");
  }

  if (endTime.getTime() > currentDate.getTime()) {
    throw Error("End time must be prior to the current date time");
  }

  const startWindow: ITimeRange = timeRangeWindowForDate(
    granularity,
    startTime,
    timezone,
  );

  const endWindow: ITimeRange = timeRangeWindowForDate(
    granularity,
    endTime,
    timezone,
  );

  const windows: ITimeRange[] = [startWindow];

  // Date range crosses a boundary so there are two different time windows
  if (startWindow.startTime !== endWindow.startTime) {
    windows.push(endWindow);
  }

  // These following lines of code check if the edges of the date range are approaching
  // a window boundary. If they are, also grab the next window and add it to the list
  const windowSize = getMaxDaysForGranularity(granularity);
  const startOffsetDate = DateTime.fromJSDate(startTime, { zone: timezone })
    .minus({ days: windowSize })
    .toJSDate();

  const startWindowOffset = timeRangeWindowForDate(
    granularity,
    startOffsetDate,
    timezone,
  );

  if (startWindow.startTime !== startWindowOffset.startTime) {
    windows.push(startWindowOffset);
  }

  const endOffsetDate = DateTime.fromJSDate(endTime, { zone: timezone })
    .plus({ days: windowSize })
    .toJSDate();

  const endWindowOffset = timeRangeWindowForDate(
    granularity,
    endOffsetDate.getTime() <= currentDate.getTime()
      ? endOffsetDate
      : currentDate,
    timezone,
  );

  if (endWindowOffset.startTime !== endWindow.startTime) {
    windows.push(endWindowOffset);
  }

  return windows;
};

/**
 * Returns the date format used by the user's locale for display, ie. mm/dd/yyyy
 */
export const getLocalDateFormat = (asTwoDigitYear?: boolean) => {
  const formatObj = new Intl.DateTimeFormat().formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return asTwoDigitYear ? "yy" : "yyyy";
        default:
          return obj.value;
      }
    })
    .join("");
};

/**
 * Returns a date format that works with the luxon date parser.
 */
const getLuxonDateFormat = () => {
  const formatObj = new Intl.DateTimeFormat().formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case "day":
          return "d";
        case "month":
          return "M";
        case "year":
          return "yy";
        default:
          return obj.value;
      }
    })
    .join("");
};

/**
 * Returns a Date if the value provided is a valid date.
 * @param value the value to test
 */
export const parseDate = (value: string): Date | undefined => {
  const date = DateTime.fromFormat(value, getLuxonDateFormat(), {
    locale: DateTime.local().locale as string,
  });

  return date.isValid ? date.toJSDate() : undefined;
};

export const isFutureDate = (date: string | Date) => {
  const d = typeof date === "string" ? new Date(date) : date;
  const now = new Date();
  return now < d;
};
