import { gql } from "@apollo/client";

export const GET_OPERATING_SCHEDULE = gql`
  query getOperatingSchedule($buildingId: String!) {
    getBuildingById(id: $buildingId) {
      id
      name
      location {
        timezone
      }
      operatingSchedule {
        scheduleId
        sunday {
          startHour
          startMinute
          endHour
          endMinute
        }
        monday {
          startHour
          startMinute
          endHour
          endMinute
        }
        tuesday {
          startHour
          startMinute
          endHour
          endMinute
        }
        wednesday {
          startHour
          startMinute
          endHour
          endMinute
        }
        thursday {
          startHour
          startMinute
          endHour
          endMinute
        }
        friday {
          startHour
          startMinute
          endHour
          endMinute
        }
        saturday {
          startHour
          startMinute
          endHour
          endMinute
        }
      }
      scheduleExceptions {
        id
        exceptionDate
        exceptionEndDate
        title
        exceptionType
        reasons
        exceptionTimeRanges {
          startHour
          endHour
          startMinute
          endMinute
        }
        timeBlock {
          startHour
          endHour
          startMinute
          endMinute
        }
      }
    }
  }
`;

export const CREATE_OPERATING_SCHEDULE = gql`
  mutation createOperatingSchedule(
    $buildingId: String!
    $operatingPeriods: [OperatingPeriodInput!]!
  ) {
    createBuildingOperatingSchedule(
      buildingId: $buildingId
      operatingPeriods: $operatingPeriods
    ) {
      code
      success
      message
    }
  }
`;

export const SET_OPERATING_SCHEDULE = gql`
  mutation setOperatingSchedule(
    $scheduleId: String!
    $operatingPeriods: [OperatingPeriodInput!]!
  ) {
    setOperatingPeriodsForBuildingOperatingSchedule(
      scheduleId: $scheduleId
      operatingPeriods: $operatingPeriods
    ) {
      code
      success
      message
    }
  }
`;

export const CREATE_SCHEDULE_EXCEPTION = gql`
  mutation createScheduleException(
    $buildingId: String!
    $title: String!
    $applicability: TimeRangeInput!
    $operatingPeriods: [OperatingPeriodInput!]!
  ) {
    createBuildingOperatingScheduleException(
      buildingId: $buildingId
      title: $title
      applicability: $applicability
      operatingPeriods: $operatingPeriods
    ) {
      code
      success
      message
    }
  }
`;

export const SET_OPERATING_SCHEDULE_TITLE = gql`
  mutation setOperatingScheduleTitle($scheduleId: String!, $title: String!) {
    setOperatingScheduleTitle(scheduleId: $scheduleId, title: $title) {
      code
      success
      message
    }
  }
`;

export const DELETE_SCHEDULE_EXCEPTION = gql`
  mutation deleteScheduleException($id: String!) {
    deleteScheduleException(scheduleId: $id) {
      id
    }
  }
`;

export const GET_HOLIDAYS = gql`
  query getHolidays($year: Int!, $country: String) {
    holidays(year: $year, country: $country) {
      id
      holidays {
        name
        observed
        __typename
      }
    }
  }
`;

export default GET_OPERATING_SCHEDULE;
