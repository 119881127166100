import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components/macro";
import { BuildingAnnotationReason } from "src/types/graphql";
import { MultiSelect, Option } from "react-multi-select-component";
import {
  MxReactIcon,
  Square,
  SquareCheck,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "src/components/common/Styling";
import { CustomSelectCaret } from "../../../../common/Dropdown/CustomSelect";
import { useState } from "react";

const MeasurablMultiSelect = styled(MultiSelect)`
  --rmsc-gray: ${AppColors.neutral["light-navy-1"]} !important;
  --rmsc-border: ${AppColors.neutral["light-navy-1"]} !important;
  color: ${AppColors.neutral["light-navy-1"]};
  max-width: 300px;
  font-size: 14px;
`;

const mapReasonToMultiOption = (i: BuildingAnnotationReason): Option => ({
  label: `charts.explorer.overlays.annotationCategory.${i}`,
  value: i,
});

const AnnotationTypePicker = ({
  defaultSelected,
  onChange,
}: {
  defaultSelected?: BuildingAnnotationReason[];
  onChange: (value: BuildingAnnotationReason[]) => void;
  width?: string;
}) => {
  const items: BuildingAnnotationReason[] = Object.values(
    BuildingAnnotationReason,
  );
  // Reorders list of categories so that 'OTHER' is at the bottom
  items.push(items.splice(items.indexOf(BuildingAnnotationReason.OTHER), 1)[0]);

  const [isOpen, setIsOpen] = useState(false);

  const handleMultiSelect = (picked: Option[]) => {
    onChange(picked.map(o => o.value));
  };
  const translate = useIntl();

  return (
    <>
      <MeasurablMultiSelect
        labelledBy="category"
        value={defaultSelected?.map(mapReasonToMultiOption) ?? []}
        hasSelectAll={false}
        disableSearch={true}
        closeOnChangedValue={false}
        options={items.map(i => ({
          label: `charts.explorer.overlays.annotationCategory.${i}`,
          value: i,
        }))}
        valueRenderer={selected => {
          return selected.length
            ? selected
                .map(({ label }) => translate.formatMessage({ id: label }))
                .join(", ")
            : translate.formatMessage({
                id: "charts.explorer.overlays.create.selectType",
              });
        }}
        ArrowRenderer={({ expanded }) => (
          <>
            <div style={{ width: "24px", height: "24px" }} />
            <CustomSelectCaret isOpen={expanded} />
          </>
        )}
        onChange={handleMultiSelect}
        ItemRenderer={(props: any) => (
          <p onClick={props.onClick}>
            <MxReactIcon
              Icon={props.checked ? SquareCheck : Square}
              color={
                props.checked
                  ? AppColors.primary["msr-green"]
                  : AppColors.semantic.green["light-msr-green-3"]
              }
              style={{ marginRight: "8px" }}
            />
            <FormattedMessage id={props.option.label} />
          </p>
        )}
      />
    </>
  );
};
export default AnnotationTypePicker;
