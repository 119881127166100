import React, { PropsWithChildren } from "react";
import { UserData } from "src/types/user";

/* =============================================================== *\
   A thing for storing the User, but not the "Auth" user. This is
   the stuff we store about them (first name, email, preferences)
   in our database that is related to, but not the same as the Auth
   version of the user.

   This provides a context hook to get / set the user, and a Provider
   that must wrap any / all components that will use the hook.
\* =============================================================== */

interface UserContextProps {
  userData: UserData;
  setUserData: (userData: UserData) => void;
}
const UserContext = React.createContext<UserContextProps>(
  {} as UserContextProps,
);

const UserProvider: React.FC<PropsWithChildren<{
  defaultUserData?: UserData;
}>> = props => {
  const { defaultUserData = {} as UserData } = props;
  const [userData, setUserData] = React.useState<UserData>(defaultUserData);
  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined || context.setUserData === undefined) {
    throw new Error(
      "useUserContext must be used in a valid UserContextProvider",
    );
  }
  return context;
};

export { UserProvider, useUserContext };
