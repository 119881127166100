import React from "react";
import { FormattedMessage } from "react-intl";
import { friendlyNumberFormat } from "src/helpers/charting";
import { MetricDescription, MetricValue } from ".";
import ComposedMetric from "./ComposedMetric";

type MetricProps = {
  value: number;
  unit: string;
  description: string;
};

export const Metric = (props: MetricProps) => {
  return (
    <ComposedMetric
      value={
        <MetricValue>
          {friendlyNumberFormat(props.value)} {props.unit}
        </MetricValue>
      }
      description={
        <MetricDescription>
          <FormattedMessage id={props.description} />
        </MetricDescription>
      }
    />
  );
};

export default Metric;
