import { PointType } from "./charting";

export enum RecurringPeriod {
  DAILY_AM = "DAILY_AM",
  WEEKLY_MONDAY_AM = "WEEKLY_MONDAY_AM",
  MONTHLY_FIRST_AM = "MONTHLY_FIRST_AM",
  QUARTERLY_FIRST_AM = "QUARTERLY_FIRST_AM",
  WEEKDAYS_AM = "WEEKDAYS_AM",
}

// TODO: this is bad. Make the values of the above keys in en.json instead.
export enum RecurringPeriodDay {
  WEEKLY_MONDAY_AM = "Monday",
  MONTHLY_FIRST_AM = "First of the month, bypassing i18n",
  QUARTERLY_FIRST_AM = "First of the quarter, bypassing i18n",
}

export enum ReportIdentifier {
  BUILDING_ELECTRICITY_DAILY_DEMAND_PROFILE = "BUILDING_ELECTRICITY_DAILY_DEMAND_PROFILE",
  HEATING_COOLING_DEGREE_DAYS = "HEATING_COOLING_DEGREE_DAYS",
  BUILDING_HOURLY_BASELINES = "BUILDING_HOURLY_BASELINES",
  BUILDING_DAILY_BASELINES = "BUILDING_DAILY_BASELINES",
  COMMODITY_CHART = "COMMODITY_CHART",
  DAILY_MEASURE_SUMMARY = "DAILY_MEASURE_SUMMARY",
  MEASURES_ASSIGNMENT = "MEASURES_ASSIGNMENT",
  MISSING_BILLS = "MISSING_BILLS",
  MEASURES_LIST = "MEASURES_LIST",
  OPEN_MEASURES_SUMMARY = "OPEN_MEASURES_SUMMARY",
}

// TODO: this is bad. Make the values of the above keys in en.json instead.
export enum ReportIdentifierValue {
  BUILDING_ELECTRICITY_DAILY_DEMAND_PROFILE = "Daily Demand Profile",
  HEATING_COOLING_DEGREE_DAYS = "Heating & Cooling Degree Days",
  BUILDING_HOURLY_BASELINES = "Energy Baselines",
  BUILDING_DAILY_BASELINES = "This string is bypassing i18n",
}

export type User = {
  subscriptions: Subscription[];
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  roles: Role[];
  // buildings: [Building!]!
};

enum Role {
  ADMIN,
  FINANCIAL_ONLY,
  ALL_BUILDINGS_FOR_ORGANIZATION,
  PREVIEW,
}

export type Subscription = {
  id: string;
  report: GeneratedReport;
  deliverySchedule: RecurringPeriod;
  created: Date;
};

export type Asset = {
  id: string;
  name: string;
};

export interface GeneratedReport {
  reportType: ReportIdentifier;
  asset?: Asset;
  assets?: Asset[];
  pointTypes?: PointType[];
  name?: string;
}

export type SubscriptionVariables = {
  userId: string;
  reportAssetId: string;
  report: ReportIdentifier;
  deliverySchedule: RecurringPeriod;
  pointTypes: PointType[];
};

export type MultiSubscriptionVariables = {
  userId: string;
  reportAssetIds: string[];
  report: ReportIdentifier;
  deliverySchedule: RecurringPeriod;
  pointTypes?: PointType[];
};

export type ConsolidatedSubscriptionVariables = {
  userId: string;
  reportAssetIds: string[];
  report: ReportIdentifier;
  deliverySchedule: RecurringPeriod;
  pointTypes?: PointType[];
  reportName?: string;
};

export type AllowedReports =
  | ReportIdentifier.DAILY_MEASURE_SUMMARY
  | ReportIdentifier.OPEN_MEASURES_SUMMARY
  | ReportIdentifier.MISSING_BILLS
  | ReportIdentifier.BUILDING_DAILY_BASELINES
  | ReportIdentifier.BUILDING_HOURLY_BASELINES;
