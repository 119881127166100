import { tracking } from "src/tracking";
import { useEffect } from "react";

export function useLinkTracking() {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const linkSource = urlSearchParams.get("linkSource");

    if (linkSource) {
      tracking.fireEvent("FromLink", {
        linkSource,
      });
      // Remove linkSource from the search params
      urlSearchParams.delete("linkSource");
      const newSearchParams = urlSearchParams.toString();

      const newUrl = `${window.location.pathname}${
        newSearchParams === "" ? "" : "?"
      }${newSearchParams}`;

      // eslint-disable-next-line no-restricted-globals
      history.replaceState({ path: newUrl }, "", newUrl);
    }
  }, []);
}
