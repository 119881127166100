import styled from "styled-components/macro";
import { AppColors } from "../Styling";

interface CardProps {
  hasMaxWidth?: boolean;
}

export const Card = styled.section<CardProps>`
  margin-top: 15px;
  min-height: 100px;
  background: ${AppColors.neutral.white};
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
  max-width: ${props => (props.hasMaxWidth ? "1500px" : "inherit")};
`;

// for use with Layout components, since we don't want to add our own margins
// or paddings any more. Eventually, we will only use this one and can call it "Card"
export const LayoutCard = styled.section`
  min-height: 100px;
  background: ${AppColors.neutral.white};
  box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
`;
