import { useChartColors } from "src/hooks/useChartColors";
import React, { useRef } from "react";
import { useApolloClient } from "@apollo/client";
import {
  AssetState,
  DataQueueItem,
  AssetType,
} from "src/components/app/ExplorerPage/types";
import {
  ChartDataGranularity,
  ChartDataRange,
  PointType,
  TemperatureUnit,
  Unit,
} from "src/types/charting";
import {
  ChartDataAction,
  PresentationProps,
  ChartDataPayload,
} from "src/components/app/ExplorerPage/chartDataReducer";
import { AssetStateAction } from "src/components/app/ExplorerPage/assetStateReducer";
import {
  GROUP_COMMODITY_DATA,
  BUILDING_COMMODITY_DATA,
  BUILDING_WEATHER_DATA,
  FETCH_ALL_POINT_DATA,
  POINT_COMMODITY_DATA,
} from "src/queries/commodity";
import { useUnitPrefs } from "src/hooks/useUnitPrefs";
import { UnitPreference } from "src/types/graphql";

export type CurrentDataParameters = {
  granularity: ChartDataGranularity;
  primaryTimeseriesKey: string;
  secondaryTimeseriesKey: string | undefined;
  rangeSize: ChartDataRange;
};

// this is to keep our old buddy typescript happy when we
// add different vars to the query based on type later
// this is why there is buildingId, meterId, etc.
// this is probably a code smell.
type InternalQueryVars = {
  buildingId: string;
  meterId: string;
  groupId: string;
  pointId: string;
  granularity: ChartDataGranularity;
  startTime: string;
  endTime: string;
  type: string; // strignified ChartDataType,
  temperatureUnit?: TemperatureUnit | typeof Unit.FAHRENHEIT | typeof Unit.CELSIUS;
  unit?: Unit;
  unitPreferences?: UnitPreference;
};

const gqlQueryForQueueItem = (qItem: DataQueueItem) => {
  if (qItem.assetType === AssetType.BUILDING) {
    switch (qItem.commodity) {
      case PointType.ELECTRICITY_DEMAND:
      case PointType.ELECTRICITY_USAGE:
      case PointType.NATURAL_GAS_USAGE:
      case PointType.PROPANE_USAGE:
      case PointType.WATER_USAGE:
      case PointType.CHILLED_WATER_USAGE:
      case PointType.HOT_WATER_USAGE:
      case PointType.STEAM_MASS:
      case PointType.HEATING_OIL_USAGE:
      case PointType.STEAM_USAGE:
        return BUILDING_COMMODITY_DATA;
      case PointType.TEMPERATURE:
      case PointType.HUMIDITY:
        return BUILDING_WEATHER_DATA;
      default:
        throw new Error("unknown commodity type");
    }
  } else if (qItem.assetType === AssetType.GROUP) {
    return GROUP_COMMODITY_DATA;
  } else if (qItem.assetType === AssetType.EQUIPMENT_POINT) {
    return POINT_COMMODITY_DATA;
  } else {
    // points on meters can't be queried individually, so you have to get it all =(
    return FETCH_ALL_POINT_DATA;
  }
};

const useCommodiytDataFetching = (
  chartDispatch: React.Dispatch<ChartDataAction>,
  assetDispatch: React.Dispatch<AssetStateAction>,
  initialDataParameters: CurrentDataParameters,
) => {
  const apolloClient = useApolloClient();
  const { colorForAssetKeyAndCommodity } = useChartColors();
  const {
    unitPrefForPoint,
    unitPrefForTemperature,
    unitPreferences,
  } = useUnitPrefs();
  // holds the current state of what the owner is displaying. Used to handle queires
  // that return after a user has swtiched range or granularity (or both)
  // they are a ref so they are "instantly" available to the other fucntions when updated
  const dataParams = useRef(initialDataParameters);
  // flag the owner can set to tell us they are unmounted so we don't try and update state.
  const parentUnmounted = useRef(false);

  const invalidQueueItem = (queueItem: DataQueueItem): boolean => {
    return (
      (queueItem.timeseriesKey !== dataParams.current.primaryTimeseriesKey &&
        queueItem.timeseriesKey !==
          dataParams.current.secondaryTimeseriesKey) ||
      queueItem.granularity !== dataParams.current.granularity
    );
  };

  // published function to update our state from outside
  const updateDataParameters = (params: CurrentDataParameters) => {
    dataParams.current = params;
  };

  const unmount = () => {
    parentUnmounted.current = true;
  };

  // what action does the assetState need to get when we update
  const updateActionType = (
    qItem: DataQueueItem,
  ): "UPDATE_BUILDING_STATE" | "UPDATE_POINT_STATE" | "UPDATE_GROUP_STATE" => {
    switch (qItem.assetType) {
      case AssetType.BUILDING:
        return "UPDATE_BUILDING_STATE";
      case AssetType.POINT:
      case AssetType.EQUIPMENT_POINT:
        return "UPDATE_POINT_STATE";
      case AssetType.GROUP:
      default:
        return "UPDATE_GROUP_STATE";
    }
  };
  const receiveDataActionType = (qItem: DataQueueItem) => {
    switch (qItem.assetType) {
      case AssetType.BUILDING:
        return "RECEIVE_BUILDING_DATA";
      case AssetType.GROUP:
        return "RECEIVE_GROUP_DATA";
      case AssetType.EQUIPMENT_POINT:
        return "RECEIVE_POINT_DATA";
      case AssetType.POINT:
      default:
        return "RECEIVE_METER_DATA";
    }
  };
  const fetchCommodityData = async (queueItem: DataQueueItem) => {
    // if our parent was un-mounted, we should not do anything any more!
    // we do this again after the AWAIT of data fetching in case things changed
    // while we were out...
    if (parentUnmounted.current) {
      return;
    }

    const _assetStateKey = `${queueItem.assetId}.${queueItem.commodity}`;
    if (invalidQueueItem(queueItem)) {
      console.info(
        "Exiting early: processed a queue item that should not be fetched: %s",
        queueItem.queryId,
      );
      return;
    }
    // obtain color and dash info for this chart
    const _dashArray =
      queueItem.timeseriesKey !== dataParams.current.primaryTimeseriesKey
        ? "3 2"
        : "";
    const _color = colorForAssetKeyAndCommodity(
      queueItem.assetKey,
      queueItem.commodity,
    );
    const assetActionType = updateActionType(queueItem);
    assetDispatch({
      type: assetActionType,
      payload: {
        id: _assetStateKey,
        assetState: AssetState.LOADING,
      },
    });

    // ELECTRICITY, GAS, WATER, ETC.
    // NOTE: the meter, building, and group ID are set always even though they
    // will not be used for the other two query types so we can have generic code.
    const _vars: InternalQueryVars = {
      buildingId: queueItem.assetId,
      meterId: queueItem.queryId,
      groupId: queueItem.queryId,
      pointId: queueItem.queryId,
      granularity: queueItem.granularity,
      startTime: queueItem.timeRange.startTime,
      endTime: queueItem.timeRange.endTime,
      type: queueItem.commodity.toUpperCase(),
    };
    // this will tack on the correct unit / prefs to the queries
    // it is probably more complicated than it should be, but it works!
    if (queueItem.assetType === AssetType.BUILDING) {
      if (
        queueItem.commodity === PointType.TEMPERATURE ||
        queueItem.commodity === PointType.HUMIDITY
      ) {
        _vars.temperatureUnit = unitPrefForTemperature;
      } else {
        try {
          const _p = queueItem.commodity;
          _vars.unit = unitPrefForPoint(_p);
        } catch (e) {
          // TODO: what do we do here, if anything. Leaving off unit is fine query-wise for now, so this is okay
          console.error(e);
        }
      }
      // TODO: do this for ALL non group / buildings? YES!
    } else if (queueItem.assetType === AssetType.EQUIPMENT_POINT) {
      const _u = unitPrefForPoint(
        queueItem.commodity,
      );
      _vars.unitPreferences = {
        [queueItem.commodity]: _u,
      };
    } else {
      // this puts unitPreferences on the query vars
      if (unitPreferences) {
        const { __typename, ...prefs } = unitPreferences;
        _vars.unitPreferences = prefs;
      }
    }

    const { data, errors } = await apolloClient.query({
      query: gqlQueryForQueueItem(queueItem),
      variables: _vars,
    });
    // if our parent was un-mounted, we should not do anything any more!
    if (parentUnmounted.current) {
      return;
    }

    if (invalidQueueItem(queueItem)) {
      // in the case that things changed on  the outside while we
      // were fetching
      console.info(
        "Exiting early: processed a queue item that should not be fetched: %s",
        queueItem.queryId,
      );
      return;
    }

    if (errors) {
      assetDispatch({
        type: assetActionType,
        payload: {
          id: _assetStateKey,
          assetState: AssetState.ERROR,
        },
      });
    } else {
      const _p: PresentationProps = {
        stroke: _color,
        strokeDasharray: _dashArray,
      };
      const payload: ChartDataPayload = {
        response: data,
        rangeSize: dataParams.current.rangeSize,
        chartWidth: 800,
        timezone: queueItem.timezone,
        queueItem: queueItem,
        presentationProps: _p,
        isPastComparison:
          queueItem.timeseriesKey === dataParams.current.secondaryTimeseriesKey,
      };
      chartDispatch({
        type: receiveDataActionType(queueItem),
        payload,
      });

      assetDispatch({
        type: assetActionType,
        payload: {
          id: _assetStateKey,
          assetState: AssetState.SELECTED,
        },
      });
    }
  };

  return { fetchCommodityData, updateDataParameters, unmount };
};
export { useCommodiytDataFetching };
