import { useUserContext } from "src/auth/UserContext";
import { UserRole } from "src/types/roles";

export const useAuthorization = () => {
  const { userData } = useUserContext();

  /**
   * Returns the organization associated with the user.
   */
  const getOrganization = (): string => {
    return userData.organizationId || "";
  };

  /**
   * Returns true if the user has the specified role.
   * @param role the role to look for in the user's roles
   */
  const hasRole = (role: UserRole): boolean => {
    return userData.roles
      ? userData.roles.find(r => r.toLowerCase() === role.toLowerCase()) !==
          undefined
      : false;
  };

  /**
   * Returns true if the user has at least one role in the given array of roles
   * @param roles roles to look for in the user's roles
   */
  const hasAtLeastOneRoleOf = (roles: UserRole[]): boolean => {
    if (userData.roles) {
      for (const role of roles) {
        if (
          userData.roles.find(
            r => r.toLowerCase() === role.toLocaleLowerCase(),
          ) !== undefined
        ) {
          return true;
        }
      }
    }
    return false;
  };

  /**
   * Returns true if the buildingId is contained in the user's buildings claim
   * @param buildingId ID of building to search for
   */
  const hasBuilding = (buildingId: string): boolean => {
    return userData.buildings && userData.buildings.includes(buildingId);
  };

  /**
   * Returns true if the user is explicitly assigned the buildingID or the user has
   * either the all_buildings_for_organization or admin role
   * @param buildingId the ID of the building
   * @param organizationId the ID of the organization
   */
  const hasBuildingAccess = (
    buildingId: string,
    organizationId: string,
  ): boolean => {
    return (
      hasRole(UserRole.ADMIN) ||
      hasRole(UserRole.CUSTOMER_SUPPORT) ||
      hasRole(UserRole.INTERNAL_SUPPORT) ||
      (hasRole(UserRole.ALL_BUILDINGS_FOR_ORGANIZATION) &&
        hasOrganization(organizationId)) ||
      hasBuilding(buildingId)
    );
  };

  /**
   * Returns true if the user has access to multiple buildings
   * @returns true if the user has access to multiple buildings
   */
  const hasMultipleBuildings = (): boolean => {
    return userData.buildings && userData.buildings.length > 1;
  };
  /**
   * Returns true if the user has access to multiple buildings in a given
   * organization
   * @param organizationId
   * @returns true if the user has access to multiple buildings in a given
   * organization
   */
  const hasMultipleBuildingAccess = (
    organizationId: string | undefined,
  ): boolean => {
    return organizationId !== undefined
      ? hasOrganization(organizationId) && hasMultipleBuildings()
      : false;
  };

  /**
   * Returns true if the organizationId is contained in the user's organization claim
   * @param organizationId ID of organization to search for
   */
  const hasOrganization = (organizationId: string): boolean => {
    return userData && userData.organizationId === organizationId;
  };

  /**
   * Returns if a user has Demand Management access. Note that this is a
   * "fake" role that doesn't really exist as a role. Only one org has this
   * (and presumably only ever will) so it is hard coded to their org ID.
   * There are two because one is prod, one is staging.
   */
  const hasDemandManagement = (): boolean => {
    const userOrg = userData?.organizationId ?? "no_org";
    return (
      userOrg === "ea4bb2dc-bf59-42cf-9400-9cf136b5c5e9" ||
      userOrg === "af8867c2-f0e9-415f-81df-a974f1e8ef09"
    );
  };
  return {
    getOrganization,
    hasOrganization,
    hasRole,
    hasAtLeastOneRoleOf,
    hasMultipleBuildings,
    hasMultipleBuildingAccess,
    hasBuilding,
    hasBuildingAccess,
    hasDemandManagement,
  };
};
