import styled from "styled-components/macro";

export const BodyNoWrap = styled.div`
  font-weight: 300;
  line-height: 20px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;
