import {
  ChartDataGranularity,
  ChartDataRange,
  PointType,
  ChartMetadata,
  ChartRenderData,
  CommodityChartData,
  ICombinedMeasurement,
  IMeasurement,
  ITimeRange,
  IWeatherMeasurement,
  MetaInfo,
  ParsedChartData,
} from "src/types/charting";
import {
  explorerDateTickFormatter,
  multiDomainYTickMarkValues,
  xTicksForRangeAndSize,
} from "src/helpers/charting";
import * as helpers from "@hatchdata/equipment-types-package/dist/src/utils/mappers";
import { XAxisProps, YAxisProps } from "recharts";
import {
  DataQueueItem,
  TimeseriesNode,
} from "src/components/app/ExplorerPage/types";
import { keyFromQueueItem } from "src/components/app/ExplorerPage/helpers";
import { getEnumMemberByStringValue } from "src/components/app/ExplorerPage/explorerHydrateFromURL";
import { PresentationProps } from "../chartDataReducer";
import { AxisDomainItem } from "recharts/types/util/types";
import { removeEventsForAssetId, removeSpanForAssetId } from "./eventParser";
import { AppColors } from "src/components/common/Styling";

interface ITimedStringDateMinMax {
  minima: ITimestampedValue | null;
  maxima: ITimestampedValue | null;
}

interface ITimestampedValue {
  timestamp: string | Date;
  value: number;
}

interface ICommonMeasurement {
  timestamp: string | Date;
  value?: number | null;
  relativeHumidity?: number | null;
  temperature?: number | null;
}

const initialEmptyChartData: ParsedChartData = {
  xAxes: new Map(),
  yAxes: new Map(),
  charts: new Map(),
  data: [],
  metadata: {},
  events: new Map(),
  overlaySpans: new Map(),
  metrics: {},
  queueItems: [],
  thresholds: [],
};

export const removeTimeSeriesFromState = (
  timeseriesKey: string,
  currentState: ParsedChartData,
): ParsedChartData => {
  const idx = currentState.queueItems.findIndex(
    q => q.timeseriesKey === timeseriesKey,
  );
  if (idx === -1) {
    return currentState;
  }
  currentState.queueItems.splice(idx, 1);

  if (currentState.xAxes.get(timeseriesKey) === undefined) {
    return currentState;
  }

  /* =============================================================== *\
     remove X-Axis
  \* =============================================================== */
  const _newXAxes = new Map(currentState.xAxes);
  _newXAxes.delete(timeseriesKey);

  /* =============================================================== *\
     remove metadata
  \* =============================================================== */
  const {
    [timeseriesKey]: _removedMetadata,
    ...restMetadata
  } = currentState.metadata;

  /* =============================================================== *\
     remove data (readings)
  \* =============================================================== */
  const _newData = currentState.data.map((d: TimeseriesNode) => {
    const { [timeseriesKey]: _removed, ...rest } = d;
    return rest;
  });

  const chartKeys: string[] = findChartKeyByTimeseries(
    timeseriesKey,
    currentState.charts,
  );
  /* =============================================================== *\
     remove Charts
  \* =============================================================== */

  // NOTE:
  // we are cloning this if we need to not not. Maybe do `let _newCharts = currentState.charts`
  // and then if we found it, we can re-assign it to a clone
  const _newCharts = new Map(currentState.charts);
  for (const _cKey of chartKeys) {
    _newCharts.delete(_cKey);
  }

  return {
    ...currentState,
    xAxes: _newXAxes,
    metadata: restMetadata,
    data: _newData,
    charts: _newCharts,
  };
};

/**
 * returns a clone of metadata with an asset removed.
 * if it was the only asset with a given unit, that unit
 * is removed from the tree.
 *
 * exported for testing purposes only (I think)
 */
export const removeAssetFromMetadata = (
  existingMeta: ChartMetadata,
  assetId: string,
  timestampKey: string,
  unitSymbol: string,
): ChartMetadata => {
  const metadaDataCommodityData: { [key: string]: MetaInfo } = existingMeta[
    timestampKey
  ][unitSymbol] as { [key: string]: MetaInfo };
  const { [assetId]: remove, ...assetsRest } = metadaDataCommodityData;
  let _newMetadata: ChartMetadata;
  const isAnyCommodityData = Object.keys(assetsRest).length !== 0;
  //If there's no more items for the same commodity
  //Remove the commodity form the metadata
  if (isAnyCommodityData) {
    _newMetadata = {
      ...existingMeta,
      [timestampKey]: {
        ...existingMeta[timestampKey],
        [unitSymbol]: {
          ...assetsRest,
        },
      },
    };
  } else {
    _newMetadata = {
      ...existingMeta,
      [timestampKey]: {
        ...existingMeta[timestampKey],
      },
    };
    delete _newMetadata[timestampKey][unitSymbol];
  }
  return _newMetadata;
};

export const removeAssetFromState = (
  assetKey: string,
  unitSymbol: string,
  currentState: ParsedChartData,
): ParsedChartData => {
  // TODO: THERE IS A TESTED HELPER METHOD FOR THIS.
  // note to past self from future self, it would have been nice to tell yourself
  // what that method was called or where it was...
  const splitedAssetKey = assetKey.split(".");
  const timestampKey: string = splitedAssetKey[0];
  const commodity: string = splitedAssetKey[1];
  const assetId: string = splitedAssetKey[2]; // this is actually the type__id key
  // remove the queue item for this thing so we can replace state with it down in the return....
  const newQ = currentState.queueItems.filter(q => {
    return `${q.timeseriesKey}.${q.commodity}.${q.assetKey}` !== assetKey;
  });

  if (currentState.charts.get(assetKey) === undefined) {
    return currentState;
  }

  if (currentState.charts.size === 1) {
    // TODO: have a constant or function for this in case state shape changes
    return initialEmptyChartData;
  }

  /*
  // Immutability: Remove nested property from metadata
  const metadaDataCommodityData: { [key: string]: MetaInfo } = currentState
    .metadata[timestampKey][unitName] as { [key: string]: MetaInfo };
  const { [assetId]: remove, ...assetsRest } = metadaDataCommodityData;
  let _newMetadata;
  const isAnyCommodityData = Object.keys(assetsRest).length !== 0;
  //If there's no more items for the same commodity
  //Remove the commodity form the metadata
  if (isAnyCommodityData) {
    _newMetadata = {
      ...currentState.metadata,
      [timestampKey]: {
        ...currentState.metadata[timestampKey],
        [unitName]: {
          ...assetsRest,
        },
      },
    };
  } else {
    _newMetadata = {
      ...currentState.metadata,
      [timestampKey]: {
        ...currentState.metadata[timestampKey],
      },
    };
    delete _newMetadata[timestampKey][unitName];
  }
  */
  const _newMetadata = removeAssetFromMetadata(
    currentState.metadata,
    assetId,
    timestampKey,
    unitSymbol,
  );
  // Immutability: Remove nested property from metrics
  const metricsCommodityData: { [key: string]: MetaInfo } = currentState
    .metrics[timestampKey][commodity] as { [key: string]: MetaInfo };
  const { [assetId]: _r, ...metricsRest } = metricsCommodityData;
  let _newMetrics;
  const isAnyMetrics = Object.keys(metricsRest).length !== 0;
  //If there's no more items for the same commodity
  //Remove the commodity form the metadata
  if (isAnyMetrics) {
    _newMetrics = {
      ...currentState.metrics,
      [timestampKey]: {
        ...currentState.metrics[timestampKey],
        [commodity]: {
          ...metricsRest,
        },
      },
    };
  } else {
    _newMetrics = {
      ...currentState.metrics,
      [timestampKey]: {
        ...currentState.metrics[timestampKey],
      },
    };
    delete _newMetrics[timestampKey][commodity];
  }

  // chart / asset timeseries data
  const _newData: TimeseriesNode[] = currentState.data.map(dataItem => {
    const commodityData = dataItem[timestampKey][commodity];

    if (typeof commodityData === "object") {
      const { [assetId]: remove, ...dataRest } = commodityData;
      if (isAnyMetrics) {
        return {
          ...dataItem,
          [timestampKey]: {
            ...dataItem[timestampKey],
            [commodity]: {
              ...dataRest,
            },
          },
        };
      } else {
        const item = {
          ...dataItem,
          [timestampKey]: {
            ...dataItem[timestampKey],
          },
        };
        delete item[timestampKey][commodity];
        return item;
      }
    } else {
      const {
        [timestampKey]: { [commodity]: remove, ...commodityRest },
      } = dataItem;

      return {
        ...dataItem,
        [timestampKey]: {
          ...dataItem[timestampKey],
          ...commodityRest,
        },
      };
    }
  });

  const _newCharts = new Map(currentState.charts);
  _newCharts.delete(assetKey);

  const _newYAxis = new Map(currentState.yAxes);
  const chartCommodity = getEnumMemberByStringValue(PointType, commodity);
  if (chartCommodity === undefined) {
    console.error(
      "unable to determine chart commodity, returning unmodified state",
    );
    return currentState;
  }
  const hasChartWithUnit = findChartKeyByUnit(unitSymbol, _newCharts);

  // Recalculate the axis if there's items for the commodity
  if (hasChartWithUnit) {
    const _newYAxisProps = buildYAxisForChart(
      _newMetadata,
      chartCommodity,
      currentState.yAxes.get(unitSymbol)!.unit as string,
      getOrientationForCommodity(chartCommodity),
    );

    _newYAxis.set(unitSymbol, _newYAxisProps);
  } else {
    // Delete the axis if it is the last item from the commodity
    _newYAxis.delete(unitSymbol);
  }

  // handle overlay events
  let _newOverlayEvents = currentState.events;
  if (assetId.startsWith("BUILDING")) {
    const id = assetId.split("__")[1];
    // in case we got the dud.
    if (id.length) {
      _newOverlayEvents = removeEventsForAssetId(currentState.events, assetId);
    }
  }

  let _newOverlaySpans = currentState.overlaySpans;
  if (assetId.startsWith("BUILDING")) {
    const id = assetId.split("__")[1];
    // in case we got the dud.
    if (id.length) {
      _newOverlaySpans = removeSpanForAssetId(
        currentState.overlaySpans,
        assetId,
      );
    }
  }

  let _newThresholds = currentState.thresholds;
  if (assetId.startsWith("BUILDING")) {
    const id = assetId.split("__")[1];
    // in case we got the dud.
    if (id.length) {
      _newThresholds = currentState.thresholds.filter(t => t.assetId !== id);
    }
  }

  // TODO1522: BuildingAnnotations type
  // should it be a map instead of an array?

  // let _newAnnotations = currentState.annotations;
  // if (assetId.startsWith("BUILDING")) {
  //   const id = assetId.split("__")[1];
  //   // in case we got the dud.
  //   if (id.length) {
  //     _newAnnotations = currentState.annotations.filter(a => a.assetId !== id);
  //   }
  // }

  // remove queue items!

  return {
    xAxes: currentState.xAxes,
    yAxes: _newYAxis,
    metadata: _newMetadata,
    metrics: _newMetrics,
    data: _newData,
    events: _newOverlayEvents,
    charts: _newCharts,
    queueItems: newQ,
    overlaySpans: _newOverlaySpans,
    thresholds: _newThresholds,
  };
};

export const buildXAxisForChart = (
  width: number,
  values: IMeasurement[] | IWeatherMeasurement[],
  timeRange: ITimeRange,
  rangeSize: ChartDataRange,
  timezone: string | undefined,
  timestampKey: string,
  granularity: ChartDataGranularity,
): XAxisProps => {
  const xTicks = xTicksForRangeAndSize(
    width,
    (values as unknown) as ICombinedMeasurement[],
    rangeSize === 1 ? rangeSize : undefined,
    rangeSize !== 1 ? timeRange : undefined,
    granularity !== ChartDataGranularity.Day,
  );

  const domain: [AxisDomainItem, AxisDomainItem] = [
    xTicks[0],
    [...xTicks].reverse()[0],
  ];

  const xTickFormatter = explorerDateTickFormatter(timezone) as (
    date: Date,
  ) => string;

  return {
    minTickGap: 0,
    type: "number",
    scale: "time",
    axisLine: false,
    tickMargin: 40,
    tickLine: false,
    dataKey: `${timestampKey}.timestamp`,
    xAxisId: timestampKey,
    domain,
    tick: {
      fontSize: 12,
      dy: rangeSize <= ChartDataRange.SevenDays ? 0 : 5,
    },
    angle: rangeSize <= ChartDataRange.SevenDays ? 0 : 45,
    ticks: xTicks,
    tickFormatter: (t: number) => {
      return xTickFormatter(new Date(t as number))!;
    },
  };
};

export const buildYAxisForChart = (
  metadata: ChartMetadata,
  commodityName: PointType,
  unitName: string,
  orientation?: "left" | "right",
): YAxisProps => {
  const [minValue, maxValue] = extractMinimaMaximaValueFromMetadata(
    metadata,
    unitName,
  );

  const [commodityDomain] = _getDomainForCommodity(
    minValue,
    maxValue,
    commodityName,
  );

  const maxTick = commodityDomain.ticks[commodityDomain.ticks.length - 1];
  const minTick = commodityDomain.ticks[0];

  const xTickPosition = _calculateXTickPosition(orientation);

  const yAxisProperties: YAxisProps = {
    tick: { fontSize: 12, dy: -10, dx: xTickPosition },
    axisLine: false,
    tickLine: { stroke: AppColors.neutral["light-navy-9"] },
    tickSize: 40,
    yAxisId: unitName,
    domain: [minTick, maxTick] as [AxisDomainItem, AxisDomainItem],
    unit: unitName,
    ticks: commodityDomain.ticks,
    orientation: orientation ? orientation : "left",
  };

  if (orientation === "right") {
    yAxisProperties.width = 40;
  } else {
    yAxisProperties.width = 50;
  }

  return yAxisProperties;
};

const _calculateXTickPosition = (orientation?: "left" | "right") => {
  return orientation && orientation === "right" ? -30 : 50;
};

const _getDomainForCommodity = (
  minValue: number,
  maxValue: number,
  commodity: PointType,
): { min: number; max: number; ticks: number[] }[] => {
  return multiDomainYTickMarkValues([
    {
      min: helpers.minValueForPointType(commodity, minValue),
      max: helpers.maxValueForPointType(commodity, maxValue),
      minTickGap: commodity === PointType.TEMPERATURE ? 10 : undefined,
    },
  ]);
};

const _extractValueFromMeasurement = (
  item: IMeasurement | IWeatherMeasurement,
  commodity: PointType,
): number | null => {
  if (item) {
    if (PointType.TEMPERATURE === commodity) {
      return (item as IWeatherMeasurement).temperature
        ? (item as IWeatherMeasurement).temperature
        : (item as IMeasurement).value;
    } else if (PointType.HUMIDITY === commodity) {
      return (item as IWeatherMeasurement).relativeHumidity;
    } else {
      return (item as IMeasurement).value;
    }
  }
  return null;
};

export const buildDataForChart = (
  stateData: TimeseriesNode[],
  timestampKey: string,
  commodityName: PointType,
  assetKey: string,
  values: IMeasurement[] | IWeatherMeasurement[],
): TimeseriesNode[] => {
  // Must have data
  if (!values || values.length === 0) {
    return stateData;
  }

  const data: TimeseriesNode[] = stateData.slice();

  if (data.length > 0) {
    const hasTimestampKey = !!data.find(d => d[timestampKey]);

    const previousDataForCommodity = !!data.find(
      d => d[timestampKey] && d[timestampKey][commodityName],
    );

    if (hasTimestampKey && previousDataForCommodity) {
      for (let entryIndex in data) {
        if (data[entryIndex][timestampKey]) {
          data[entryIndex][timestampKey][commodityName][
            assetKey
          ] = _extractValueFromMeasurement(values[entryIndex], commodityName);
        }
      }
    } else {
      for (let entryIndex in data) {
        if (hasTimestampKey) {
          data[entryIndex][timestampKey][commodityName] = {
            [assetKey]: _extractValueFromMeasurement(
              values[entryIndex],
              commodityName,
            ),
          };
        } else {
          data[entryIndex][timestampKey] = Object.assign(
            {
              timestamp: values[entryIndex]
                ? new Date(values[entryIndex].timestamp).getTime()
                : 0,
            },
            {
              [commodityName]: {
                [assetKey]: _extractValueFromMeasurement(
                  values[entryIndex],
                  commodityName,
                ),
              },
            },
          );
        }
      }
    }
    return data;
  } else {
    return (values as (IMeasurement | IWeatherMeasurement)[]).map(item => {
      return {
        // Object.assign because of union in TimeseriesNode type
        [timestampKey]: Object.assign(
          {
            [commodityName]: {
              [assetKey]: _extractValueFromMeasurement(item, commodityName),
            },
          },
          {
            timestamp: new Date(item.timestamp).getTime(),
          },
        ),
      };
    });
  }
};

export const buildMetadataForChart = (
  stateMedatada: ChartMetadata,
  queueItem: DataQueueItem,
  minMaxValues: ITimedStringDateMinMax,
  commoditySum?: number | undefined,
  commodityAverage?: number | undefined,
  unit: string = "default_unit",
): ChartMetadata => {
  const timestampKey = queueItem.timeseriesKey;
  const assetKey = queueItem.assetKey;

  if (stateMedatada[timestampKey]) {
    if (stateMedatada[timestampKey][unit]) {
      return {
        ...stateMedatada,
        [timestampKey]: {
          ...stateMedatada[timestampKey],
          [unit]: {
            ...(stateMedatada[timestampKey][unit] as {
              [key: string]: MetaInfo;
            }),
            [assetKey]: {
              min: {
                value: minMaxValues.minima ? minMaxValues.minima.value : null,
                timestamp: minMaxValues.minima
                  ? minMaxValues.minima.timestamp
                  : "",
              },
              max: {
                value: minMaxValues.maxima ? minMaxValues.maxima.value : null,
                timestamp: minMaxValues.maxima
                  ? minMaxValues.maxima.timestamp
                  : "",
              },
              average: commodityAverage,
              sum: commoditySum,
              timezone: queueItem.timezone,
            },
          },
        },
      };
    } else {
      return {
        ...stateMedatada,
        [timestampKey]: {
          ...stateMedatada[timestampKey],
          [unit]: {
            [assetKey]: {
              min: {
                value: minMaxValues.minima ? minMaxValues.minima.value : null,
                timestamp: minMaxValues.minima
                  ? minMaxValues.minima.timestamp
                  : "",
              },
              max: {
                value: minMaxValues.maxima ? minMaxValues.maxima.value : null,
                timestamp: minMaxValues.maxima
                  ? minMaxValues.maxima.timestamp
                  : "",
              },
              average: commodityAverage,
              sum: commoditySum,
              timezone: queueItem.timezone,
            },
          },
        },
      };
    }
  } else {
    return {
      ...stateMedatada,
      [timestampKey]: {
        timezone: queueItem.timezone,
        [unit]: {
          [assetKey]: {
            min: {
              value: minMaxValues.minima ? minMaxValues.minima.value : null,
              timestamp: minMaxValues.minima
                ? minMaxValues.minima.timestamp
                : "",
            },
            max: {
              value: minMaxValues.maxima ? minMaxValues.maxima.value : null,
              timestamp: minMaxValues.maxima
                ? minMaxValues.maxima.timestamp
                : "",
            },
            average: commodityAverage,
            sum: commoditySum,
            timezone: queueItem.timezone,
          },
        },
      },
    };
  }
};

export const buildChartPropertiesForChart = (
  queueItem: DataQueueItem,
  unitName: string,
  presentationProps: PresentationProps,
  isPastComparison?: boolean,
): CommodityChartData => {
  const keyPath = keyFromQueueItem(queueItem);
  const _p = presentationProps || {};
  return {
    type: "LINE",
    visible: queueItem.startHidden ? false : true,
    queueItem: queueItem,
    isPastComparison: isPastComparison,
    chartProps: {
      strokeWidth: 2,
      isAnimationActive: false,
      type: "linear",
      dot: false,
      dataKey: keyPath,
      name: queueItem.assetName || keyPath,
      unit: unitName,
      activeDot: {
        r: 6,
        fill: isPastComparison ? "none" : presentationProps.stroke,
        stroke: isPastComparison ? presentationProps.stroke : "none",
        strokeWidth: 2,
      },
      yAxisId: unitName,
      xAxisId: queueItem.timeseriesKey,
      ..._p,
    },
  };
};

export const extractMinimaMaximaValueFromMetadata = (
  stateMetadata: ChartMetadata,
  unitName: string,
): [number, number] => {
  const maxValues: number[] = [];
  const minValues: number[] = [];

  for (const key of Object.keys(stateMetadata)) {
    if (stateMetadata[key][unitName]) {
      for (const b_key of Object.keys(
        stateMetadata[key][unitName] as { [key: string]: MetaInfo },
      )) {
        const bMaxValues = (stateMetadata[key][unitName] as {
          [key: string]: MetaInfo;
        })[b_key]["max"] as {
          value: number;
          timestamp: string;
        };
        const bMinValues = (stateMetadata[key][unitName] as {
          [key: string]: MetaInfo;
        })[b_key]["min"] as {
          value: number;
          timestamp: string;
        };
        if (bMaxValues && bMaxValues.value) {
          maxValues.push(bMaxValues.value);
        }
        if (bMinValues && bMinValues.value) {
          minValues.push(bMinValues.value);
        }
      }
    }
  }
  const minima = minValues.length > 0 ? Math.min(...minValues) : 0;
  const maxima = maxValues.length > 0 ? Math.max(...maxValues) : 0;
  return [minima, maxima];
};

const getOrientationForCommodity = (commodity: PointType) => {
  if (commodity === PointType.HUMIDITY || commodity === PointType.TEMPERATURE) {
    return "right";
  }
  return "left";
};

export const shouldCalculateCommoditySum = (commodity: PointType) => {
  return helpers.aggregateTypeForPointType(commodity) === "sum";
};

export const shouldCalculateCommodityAverage = (commodity: PointType) => {
  return helpers.aggregateTypeForPointType(commodity) === "average";
};

export const chartDataTimedReducer = (
  values: ICommonMeasurement[],
  key: "value" | "temperature" | "relativeHumidity",
) => {
  const _minMax: ITimedStringDateMinMax = {
    minima: null,
    maxima: null,
  };

  const buildTimestampedValue = (
    item: ICommonMeasurement,
    key: "value" | "temperature" | "relativeHumidity",
  ): ITimestampedValue => {
    return { timestamp: item.timestamp, value: item[key]! };
  };

  const _callback = (
    _current: ITimedStringDateMinMax,
    _item: ICommonMeasurement,
  ) => {
    // don't do much if we're null...
    const _val = _item[key];
    if (_val !== null && _val !== undefined) {
      if (_current.minima === null) {
        _current.minima = buildTimestampedValue(_item, key);
      }
      if (_current.maxima === null) {
        _current.maxima = buildTimestampedValue(_item, key);
      }
      if (_val <= _current.minima.value) {
        _current.minima = buildTimestampedValue(_item, key);
      }
      if (_val >= _current.maxima.value) {
        _current.maxima = buildTimestampedValue(_item, key);
      }
    }
    return _current;
  };
  return values.reduce(_callback, _minMax);
};

export const findChartKeyByTimeseries = (
  timeseriesKey: string,
  charts: ChartRenderData,
): string[] | [] => {
  const chartKeys: string[] = [];
  charts.forEach((_value: CommodityChartData, key: string) => {
    if (key.startsWith(timeseriesKey)) {
      chartKeys.push(key);
    }
  });

  return chartKeys;
};

const findChartKeyByUnit = (unit: string, charts: ChartRenderData): boolean => {
  const chartKeys: string[] = [];
  charts.forEach((_value: CommodityChartData, key: string) => {
    const chartUnit = _value.chartProps.unit;
    if (chartUnit === unit) {
      chartKeys.push(key);
    }
  });

  return chartKeys.length > 0;
};

export const buildMetricsForChart = (
  stateMetrics: ChartMetadata,
  queueItem: DataQueueItem,
  minMaxValues: ITimedStringDateMinMax,
  commoditySum?: number | undefined,
  commodityAverage?: number | undefined,
): ChartMetadata => {
  const timestampKey = queueItem.timeseriesKey;
  const commodityName = queueItem.commodity;
  const assetKey = queueItem.assetKey;

  if (stateMetrics[timestampKey]) {
    if (stateMetrics[timestampKey][commodityName]) {
      return {
        ...stateMetrics,
        [timestampKey]: {
          ...stateMetrics[timestampKey],
          [commodityName]: {
            ...(stateMetrics[timestampKey][commodityName] as {
              [key: string]: MetaInfo;
            }),
            [assetKey]: {
              min: {
                value: minMaxValues.minima ? minMaxValues.minima.value : null,
                timestamp: minMaxValues.minima
                  ? minMaxValues.minima.timestamp
                  : "",
              },
              max: {
                value: minMaxValues.maxima ? minMaxValues.maxima.value : null,
                timestamp: minMaxValues.maxima
                  ? minMaxValues.maxima.timestamp
                  : "",
              },
              average: commodityAverage,
              sum: commoditySum,
              timezone: queueItem.timezone,
            },
          },
        },
      };
    } else {
      return {
        ...stateMetrics,
        [timestampKey]: {
          ...stateMetrics[timestampKey],
          [commodityName]: {
            [assetKey]: {
              min: {
                value: minMaxValues.minima ? minMaxValues.minima.value : null,
                timestamp: minMaxValues.minima
                  ? minMaxValues.minima.timestamp
                  : "",
              },
              max: {
                value: minMaxValues.maxima ? minMaxValues.maxima.value : null,
                timestamp: minMaxValues.maxima
                  ? minMaxValues.maxima.timestamp
                  : "",
              },
              average: commodityAverage,
              sum: commoditySum,
              timezone: queueItem.timezone,
            },
          },
        },
      };
    }
  } else {
    return {
      ...stateMetrics,
      [timestampKey]: {
        timezone: queueItem.timezone,
        [commodityName]: {
          [assetKey]: {
            min: {
              value: minMaxValues.minima ? minMaxValues.minima.value : null,
              timestamp: minMaxValues.minima
                ? minMaxValues.minima.timestamp
                : "",
            },
            max: {
              value: minMaxValues.maxima ? minMaxValues.maxima.value : null,
              timestamp: minMaxValues.maxima
                ? minMaxValues.maxima.timestamp
                : "",
            },
            average: commodityAverage,
            sum: commoditySum,
            timezone: queueItem.timezone,
          },
        },
      },
    };
  }
};
