import React, { useState } from "react";
import CreateProgress from "./CreateProgress";
import {
  OverlayFormData,
  OverlayFormAction,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { BuildingAnnotationReason } from "src/types/graphql";
import HolidaySelect from "src/components/app/BuildingSettingsPage/Schedules/HolidaySelect";
import { FormattedMessage, useIntl } from "react-intl";
import { BodyLabel } from "src/components/common/BodyCopy";
import {
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import Input from "src/components/common/Input";
import TextArea from "src/components/common/TextArea";
import { xToDateTime } from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";
import {
  MxPrimaryReactButton,
  MxSecondaryReactButton,
} from "src/componentLibrary/react/mx-button/MxReactButton";

const ScheduleStepTwo = (props: {
  dispatch: (arg0: OverlayFormAction) => void;
  state: {
    formData: OverlayFormData;
  };
  overlayState: string;
}) => {
  const [title, setTitle] = useState(props.state.formData.title);
  const [notes, setNote] = useState(props.state.formData.note);
  const [date, setDate] = useState<string | undefined>(
    props.state.formData.startDate?.toISOString() ?? undefined,
  );
  const latitude = props.state.formData.latitude;
  const longitude = props.state.formData.longitude;
  const translate = useIntl();

  const handleNextStep = () => {
    const { startTime, endTime, timezone } = props.state.formData;
    const adjustedStartTime = startTime
      ? xToDateTime(startTime, timezone).hour
      : null;
    const adjustedEndTime = endTime
      ? xToDateTime(endTime, timezone).hour
      : null;

    const startHour =
      props.overlayState === "EDIT_2"
        ? startTime || null
        : adjustedStartTime || null;

    const endHour =
      props.overlayState === "EDIT_2"
        ? endTime || null
        : adjustedEndTime || null;

    const payload: Partial<OverlayFormData> = {
      title,
      note: notes,
      startDate: date ? new Date(date) : null,
      startTime: startHour,
      endTime: endHour,
    };

    props.overlayState === "EDIT_2"
      ? props.dispatch({
          type: "SAVE_EDIT_STEP_TWO",
          payload,
        })
      : props.dispatch({
          type: "SAVE_STEP_TWO",
          payload,
        });
  };

  const handleBackButton = () => {
    props.overlayState === "EDIT_2"
      ? props.dispatch({ type: "START_EDIT" })
      : props.dispatch({ type: "GO_STEP_ONE" });
  };

  return (
    <>
      <CreateProgress step={2} />
      <Box margin={[6, 0, 8, 0]}>
        <fieldset style={{ border: "none" }}>
          <VerticalLayout childSpacing={4}>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="title">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.title" />
              </BodyLabel>
              {props.state.formData.annotationCategories.includes(
                BuildingAnnotationReason.HOLIDAY,
              ) ? (
                <HolidaySelect
                  year={new Date().getFullYear()}
                  onChange={value => {
                    setTitle(value?.name);
                    setDate(value?.observed.replace("-", "/"));
                  }}
                  defaultSelected={title}
                  width={"100%"}
                  lat={latitude}
                  long={longitude}
                />
              ) : (
                <Input
                  id="title"
                  name="title"
                  minWidth={"250px"}
                  placeholder={
                    "settings.building.operatingScheduleExceptions.form.title.placeholder"
                  }
                  maxLength={50}
                  defaultValue={title}
                  onChange={input => {
                    setTitle(input.target.value);
                  }}
                />
              )}
            </VerticalLayout>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="note">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.note" />
              </BodyLabel>
              <TextArea
                onChange={input => setNote(input.currentTarget.value)}
                id="note"
                name="note"
                defaultValue={notes}
                placeholder={translate.formatMessage({
                  id:
                    "settings.building.operatingScheduleExceptions.form.note.placeholder",
                })}
              />
            </VerticalLayout>
          </VerticalLayout>
        </fieldset>
      </Box>
      <HorizontalLayout childSpacing={2}>
        <MxSecondaryReactButton
          fullWidthOfParent
          onClick={handleBackButton}
          intlTextId="common.button.labels.back"
        />
        <MxPrimaryReactButton
          fullWidthOfParent
          onClick={handleNextStep}
          disabled={title === "" || title === undefined}
          intlTextId="common.button.labels.next"
        />
      </HorizontalLayout>
    </>
  );
};

export default ScheduleStepTwo;
