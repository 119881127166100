import React, { Suspense, lazy } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import AuthenticatedPage from "src/components/app/AuthenticatedPage";
import InitPage from "src/components/app/InitPage";
import NotFound from "src/components/app/NotFound";
import RedirectToLogin from "src/components/app/RedirectToLogin";
import { PageErrorBoundary } from "src/components/common/ApplicationError/PageErrorBoundary";
import { useAuth } from "src/auth/AuthProvider";
import { UserProvider, useUserContext } from "src/auth/UserContext";
import { Redirect } from "src/components/common/Redirect";
import { messagesLoader } from "./i18n/messagesLoader";
/*
NOTE: All these crazy looking imports are for code splitting. This makes it
so code that lives only in certain components is loaded from files only when
that component is mounted. This makes the app load faster! So if you add any top-level
components, do this.
*/
const DemandManagementEventDetailPage = lazy(() =>
  import("./components/app/DemandManagementEventDetailPage"),
);
const DemandManagementPage = lazy(() =>
  import("./components/app/DemandManagementPage"),
);
const DemandManagementCreatePage = lazy(() =>
  import("./components/app/DemandManagementCreatePage"),
);
const NewUserRequestPage = lazy(() =>
  import("./components/app/NewUserRequestPage"),
);
const NewUserRequestFormPage = lazy(() =>
  import("./components/app/NewUserRequestPage/NewUserRequestFormPage"),
);
const NewUserConfirmationPage = lazy(() =>
  import("./components/app/NewUserRequestPage/ConfirmationPage"),
);
const MeasuresPageWrapper = lazy(() =>
  import("src/components/app/Measures/MeasuresPage/MeasuresPageWrapper"),
);
const MeasureDetailPage = lazy(() =>
  import("./components/app/Measures/MeasureDetailPage"),
);
const LegalHome = lazy(() => import("src/components/app/Legal/Legal"));
const UserTerms = lazy(() => import("src/components/app/Legal/UserTerms"));
const AcceptableUse = lazy(() =>
  import("src/components/app/Legal/AcceptableUse"),
);
const PrivacyPolicy = lazy(() =>
  import("src/components/app/Legal/PrivacyPolicy"),
);
const BuildingSummaryPage = lazy(() =>
  import("src/components/app/BuildingSummaryPage"),
);
const ForgotPasswordPage = lazy(() =>
  import("src/components/app/ForgotPasswordPage"),
);
const AdminPage = lazy(() => import("src/components/app/AdminPage"));
const BuildingSettingsPage = lazy(() =>
  import("./components/app/BuildingSettingsPage"),
);
const ScheduleExceptionsForm = lazy(() =>
  import(
    "./components/app/BuildingSettingsPage/Schedules/ScheduleExceptionsForm"
  ),
);
const LoginPage = lazy(() => import("src/components/app/LoginPage"));
const LogoutPage = lazy(() => import("src/components/app/LogoutPage"));
const SplashPage = lazy(() => import("src/components/app/SplashPage"));
const SettingsPage = lazy(() => import("src/components/app/SettingsPage"));
const HelpPage = lazy(() => import("src/components/app/HelpPage"));
const MeasureTracking = lazy(() =>
  import("src/components/app/HelpPage/MeasureTracking"),
);
const UpdateOccupancy = lazy(() =>
  import("src/components/app/HelpPage/UpdateOccupancy"),
);
const SubscribeToReports = lazy(() =>
  import("src/components/app/HelpPage/SubscribeToReports"),
);
const ConfigureAlerts = lazy(() =>
  import("src/components/app/HelpPage/ConfigureAlerts"),
);
const Legal = lazy(() => import("src/components/app/Legal"));
const AlertsPage = lazy(() => import("src/components/app/AlertsPage"));
const SourcesBaseRouteRedirect = lazy(() =>
  import("src/components/app/Sources/SourcesBaseRouteRedirect"),
);
const PerformancePage = lazy(() =>
  import("src/components/app/PerformancePage"),
);
const PerformancePageWrapper = lazy(() =>
  import("src/components/app/PerformancePageWrapper"),
);
const MakeSelectionPage = lazy(() =>
  import("src/components/app/MakeSelectionPage"),
);
const ExplorerBaseRouteRedirect = lazy(() =>
  import("src/components/app/ExplorerPage/ExplorerBaseRouteRedirect"),
);
const AlertsRedirectPage = lazy(() =>
  import("src/components/app/AlertsPage/AlertsRedirectPage"),
);
const PerformanceGroupsPage = lazy(() =>
  import("src/components/app/PerformanceGroupsPage"),
);
const PortfolioPerformancePage = lazy(() =>
  import("src/components/app/PortfolioPerformance"),
);
const ExplorerPageWrapper = lazy(() =>
  import("src/components/app/ExplorerPage/ExplorerPageWrapper"),
);
const CreateMeasurePage = lazy(() =>
  import("src/components/app/Measures/CreateMeasurePage/CreateMeasurePage"),
);
const ConsolidatedBillForm = lazy(() =>
  import("src/components/app/Sources/bill/ConsolidatedBillForm"),
);
const MakeSelection = lazy(() => import("./components/common/MakeSelection"));
const SourcesPage = lazy(() => import("./components/app/Sources/SourcesPage"));
const SourcesPageBillsRedirect = lazy(() =>
  import("src/components/app/Sources/SourcesPageBillsRedirect"),
);
const UtilityBillDataPage = lazy(() =>
  import("src/components/app/Sources/list/UtilityBillDataPage"),
);
const OccupancyPage = lazy(() =>
  import("src/components/app/Sources/occupancy/OccupancyPage"),
);

const BudgetPage = lazy(() =>
  import("src/components/app/Sources/budget/BudgetPage"),
);

const SelectDefaultBudget = lazy(() =>
  import("src/components/app/Sources/budget/SelectDefaultBudget"),
);

const SourcesPageMakeSelection = lazy(() =>
  import("src/components/app/Sources/SourcesPageMakeSelection"),
);

const ViewBudget = lazy(() =>
  import("src/components/app/Sources/budget/ViewBudget"),
);

// Appcues gloms stuff onto `window` so we have to tell typescript about it.
declare global {
  interface Window {
    Appcues: any;
  }
}
const AuthedApp = () => {
  const { userData } = useUserContext();
  if (window.Appcues && window.Appcues.identify) {
    window.Appcues.identify(userData.id);
  }
  return (
    <PageErrorBoundary>
      <Suspense fallback={<CenteredLoadingIndicator />}>
        <Routes>
          <Route path="/legal" element={<Legal />}>
            <Route path="" element={<LegalHome />} />
            <Route path="user-terms" element={<UserTerms />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="acceptable-use-policy" element={<AcceptableUse />} />
          </Route>
          <Route path="/init" element={<InitPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/" element={<AuthenticatedPage />}>
            <Route
              path="app/building-summary"
              element={<Redirect to="performance" />}
            />
            <Route path="" element={<Redirect to="performance" />} />

            <Route path="/" element={<PerformancePageWrapper />}>
              <Route path="performance" element={<PerformancePage />} />
              <Route
                path="performance/:organizationId"
                element={<MakeSelectionPage />}
              />
              <Route
                path="performance/:organizationId/building/:buildingId"
                element={<BuildingSummaryPage />}
              />
              <Route
                path="performance/:organizationId/building/:buildingId/carbon"
                element={<BuildingSummaryPage />}
              />
              <Route
                path="performance/:organizationId/group/:groupId"
                element={<PerformanceGroupsPage />}
              />
              <Route
                path="performance/:organizationId/portfolio"
                element={<PortfolioPerformancePage />}
              />
              <Route path="*" element={<PerformancePage />} />
            </Route>

            <Route path="explorer" element={<ExplorerBaseRouteRedirect />} />
            <Route
              path="explorer/:organizationId"
              element={<ExplorerPageWrapper />}
            />
            <Route
              path="measures/:organizationId"
              element={<MeasuresPageWrapper />}
            />
            <Route
              path="measures/:organizationId/measure/:measureId"
              element={<MeasureDetailPage />}
            />
            <Route
              path="measures/:organizationId/create"
              element={<CreateMeasurePage />}
            />
            <Route
              path="settings/:organizationId/building/:buildingId/operating-schedule"
              element={<BuildingSettingsPage />}
            />
            <Route
              path="settings/:organizationId/building/:buildingId/operating-schedule/create"
              element={<ScheduleExceptionsForm />}
            />
            <Route
              path="settings/:organizationId/building/:buildingId/operating-schedule/:scheduleExceptionId/edit"
              element={<ScheduleExceptionsForm />}
            />
            <Route path="settings/user" element={<SettingsPage />} />
            <Route path="help" element={<HelpPage />} />
            <Route path="help/configure-alerts" element={<ConfigureAlerts />} />
            <Route path="help/update-occupancy" element={<UpdateOccupancy />} />
            <Route
              path="help/subscribe-to-reports"
              element={<SubscribeToReports />}
            />
            <Route
              path="help/review-close-measure"
              element={<MeasureTracking />}
            />
            <Route path="alerts/user" element={<AlertsRedirectPage />} />
            <Route
              path="alerts/user/:organizationId"
              element={<AlertsPage />}
            />
            <Route path="admin" element={<AdminPage />} />
            <Route
              path="demand-management/:organizationId"
              element={<DemandManagementPage />}
            />
            <Route
              path="demand-management/:organizationId/event/:eventId"
              element={<DemandManagementEventDetailPage />}
            />
            <Route
              path="demand-management/:organizationId/create-event"
              element={<DemandManagementCreatePage />}
            />
            <Route path="new-user" element={<NewUserRequestPage />} />
            <Route
              path="new-user/:organizationId"
              element={<NewUserRequestFormPage />}
            />
            <Route
              path="new-user/:organizationId/confirmation"
              element={<NewUserConfirmationPage />}
            />
            <Route path="sources" element={<SourcesBaseRouteRedirect />} />

            <Route
              path="sources/:organizationId"
              element={<SourcesPageMakeSelection />}
            >
              <Route path="" element={<MakeSelection />} />
              <Route path="building/:buildingId" element={<SourcesPage />}>
                <Route path="" element={<SourcesPageBillsRedirect />} />
                <Route path="bills" element={<UtilityBillDataPage />} />
                <Route path="occupancy" element={<OccupancyPage />} />
                <Route path="budget/" element={<BudgetPage />}>
                  <Route path="" element={<SelectDefaultBudget />} />
                  <Route
                    path="year/:year/commodity/:commodity"
                    element={<ViewBudget />}
                  />
                </Route>

                <Route path="bill" element={<ConsolidatedBillForm />} />
                <Route
                  path="bill/:consolidatedBillId"
                  element={<ConsolidatedBillForm />}
                />
                <Route
                  path="bill/service-account-id/:serviceAccountId/start-date/:startDate/end-date/:endDate"
                  element={<ConsolidatedBillForm />}
                />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
            {/*<AuthNotFound />*/}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </PageErrorBoundary>
  );
};

const UnauthedApp = () => (
  <Suspense fallback={<CenteredLoadingIndicator />}>
    <Routes>
      <Route path="/" element={<SplashPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/legal" element={<Legal />}>
        <Route path="" element={<LegalHome />} />
        <Route path="user-terms" element={<UserTerms />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="acceptable-use-policy" element={<AcceptableUse />} />
      </Route>
      <Route path="*" element={<RedirectToLogin />} />
    </Routes>
  </Suspense>
);

const supportedLanguages = ["en", "de"] as const;
/**
 *
 * @returns a locale we support from the browsers internal settings or "en" if we don't handle that yet
 */
const getSupportedLocale = (): string => {
  const browserLocale = navigator.language || "en";
  // console.log("browserLocale is %s", browserLocale);
  const lang = browserLocale.substring(0, 2).toLowerCase();
  // console.log("lang therefore is %s", lang);
  return supportedLanguages.includes(lang as any) ? lang : "en";
};
const App = () => {
  const [messages, setMessages] = React.useState<any | null>(null);
  const locale = getSupportedLocale();
  React.useEffect(() => {
    const msg = messagesLoader(locale);
    Promise.all([msg.ui(), msg.equipment()])
      .then(vals => {
        const [ui, equip] = vals;
        setMessages({ ...ui, ...equip });
      })
      .catch(e => {
        throw new Error(`Unable to load translations for locale ${locale}`);
      });
  }, []);
  const { user } = useAuth();
  if (messages === null) {
    return <CenteredLoadingIndicator />;
  }
  return (
    <BrowserRouter>
      <IntlProvider
        locale={"en"}
        messages={messages}
        textComponent={React.Fragment}
        defaultLocale="en"
      >
        {user ? (
          <UserProvider>
            <AuthedApp />
          </UserProvider>
        ) : (
          <UnauthedApp />
        )}
      </IntlProvider>
    </BrowserRouter>
  );
};

export default App;
