import styled from "styled-components/macro";
import { SelectButton } from "../Button";
import { controlText1_style } from "../ControlText";
import { AppColors } from "../Styling";

export const DropdownWrapper = styled.div<{
  maxWidth?: string;
  disabled?: boolean;
}>`
  position: relative;
  max-width: ${props => props.maxWidth || "auto"};
  background-color: ${props =>
    props.disabled ? AppColors.neutral["light-navy-9"] : "auto"};
`;

export const AdjustedSelectButton = styled(SelectButton)`
  text-align: justify;
  margin-left: 0;
  width: 100%;
  padding: 10px 8px 10px 16px;
`;

export const DropdownMenu = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  top: 40px;
  max-height: 260px;
  overflow-y: auto;
  width: 100%;
  cursor: pointer;
  font-size: ${controlText1_style.fontSize};
  /* margin: 8px 0 0 0; */
  margin: 0;
  line-height: 1.5;
  border: 1px solid ${AppColors.neutral["light-navy-3"]};
  border-radius: 6px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${AppColors.neutral.white};
  color: ${controlText1_style.inactiveColor};
  z-index: 2;

  &:empty {
    border: none;
  }
`;

export const DropdownOption = styled.li<{ selected: boolean }>`
  width: 100%;
  display: block;
  padding: 8px 8px 8px 16px;
  background-color: ${props =>
    props.selected
      ? AppColors.semantic.lightBlue["light-seafoam-1"]
      : AppColors.neutral.white};
  font-weight: inherit;
`;
