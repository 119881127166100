/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components/macro";
import { searchFilteredData } from "src/components/app/ExplorerPage/helpers";
import {
  SelectorBuilding,
  SelectorData,
  SelectorEquipment,
  SelectorEquipmentGroup,
  SelectorEquipmentPoint,
  SelectorGroup,
  SelectorMeter,
  SelectorMeterGroup,
  SelectorPoint,
} from "./types";
import {
  AssetState,
  AssetStateIndicator,
  AssetType,
  CommoditySelection,
  SelectedAssetsMap,
} from "src/components/app/ExplorerPage/types";
import {
  ClearableInput,
  CollapseIcon,
  NoSearchResultsLabel,
  SidebarInputWrapper,
} from "src/components/common/SidebarParts";
import { InlineH4 } from "src/components/common/Typography";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MxReactIcon,
  SquareCheck,
  ThreeDotsH,
  ErrorTriangle,
  MapPin,
  Square,
  X,
} from "src/componentLibrary/react/mx-icon-react";
import { PointType, FeatureType } from "src/types/charting";
import { TinySpindicator } from "src/components/common/LoadingIndicator";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import { tracking } from "src/tracking";
import { AssetLabel, AssetListWrapper } from "./styles";
import {
  CollapseIconProps,
  MultiIconWrapper,
} from "src/components/common/SidebarParts/CollapseIcon";
import { SidebarHeading } from "../SidebarPage/SidebarHeading";
import { SidebarContainer } from "../SidebarPage/SidebarContainer";
import { AppColors } from "src/components/common/Styling";
import { useFeatureEnablement } from "src/helpers/enablements";
import { findReferenceTimezoneForGroup } from "./findReferenceTimezoneForGroup";

// MESSAGE

const accessoryForAssetState = (state: AssetState | undefined) => {
  if (state === undefined || state === AssetState.UNSELECTED) {
    return <MxReactIcon Icon={Square} color={AppColors.neutral.white} />;
  } else if (state === AssetState.ERROR) {
    return (
      <MxReactIcon
        Icon={ErrorTriangle}
        color={AppColors.semantic.red["light-red-1"]}
      />
    );
  } else if (state === AssetState.SELECTED) {
    return (
      <MxReactIcon Icon={SquareCheck} color={AppColors.primary["msr-green"]} />
    );
  } else if (state === AssetState.LOADING) {
    return <TinySpindicator />;
  }
};

// "score" each commodity so we can pick the default one for meters
const commodityScore: { [key: string]: number } = {
  [PointType.HUMIDITY]: 0,
  [PointType.TEMPERATURE]: 1,
  [PointType.HEATING_OIL_USAGE]: 2,
  [PointType.WATER_USAGE]: 3,
  [PointType.HOT_WATER_USAGE]: 4,
  [PointType.STEAM_USAGE]: 5,
  [PointType.STEAM_MASS]: 6,
  [PointType.CHILLED_WATER_USAGE]: 7,
  [PointType.NATURAL_GAS_USAGE]: 8,
  [PointType.PROPANE_USAGE]: 9,
  [PointType.STATIC_PRESSURE]: 9, // dupe is okay maybe?
  [PointType.ELECTRICITY_USAGE]: 10,
  [PointType.ELECTRICITY_DEMAND]: 11,
};

/**
 * Get which chart type you should show by default given a list of ones you can show.
 *
 * @param commodities list of chart data types you have available
 * @returns the one you should show by default
 */
export const defaultCommodityFromList = (
  commodities: PointType[],
): PointType => {
  if (commodities.includes(PointType.ELECTRICITY_DEMAND)) {
    return PointType.ELECTRICITY_DEMAND;
  } else if (commodities.includes(PointType.ELECTRICITY_USAGE)) {
    return PointType.ELECTRICITY_USAGE;
  } else if (commodities.includes(PointType.NATURAL_GAS_USAGE)) {
    return PointType.NATURAL_GAS_USAGE;
  } else if (commodities.includes(PointType.CHILLED_WATER_USAGE)) {
    return PointType.CHILLED_WATER_USAGE;
  } else if (commodities.includes(PointType.STEAM_MASS)) {
    return PointType.STEAM_MASS;
  } else if (commodities.includes(PointType.STEAM_USAGE)) {
    return PointType.STEAM_USAGE;
  } else if (commodities.includes(PointType.HOT_WATER_USAGE)) {
    return PointType.HOT_WATER_USAGE;
  } else if (commodities.includes(PointType.WATER_USAGE)) {
    return PointType.WATER_USAGE;
  } else if (commodities.includes(PointType.HEATING_OIL_USAGE)) {
    return PointType.HEATING_OIL_USAGE;
  }
  return PointType.TEMPERATURE;
};

type MultiCommoditySelectorProps = {
  data: SelectorData;
  activeTimezone: string | undefined;
  buildingStates: AssetStateIndicator;
  pointStates: AssetStateIndicator;
  groupStates: AssetStateIndicator;
  selectionHandler: (selection: CommoditySelection) => void;
  closeHandler: () => void;
  initialSelectedAssets: SelectedAssetsMap;
  clearSelectionsHandler?: () => void /** lets the parent know user wants to "reset" everything */;
};

const MultiCommoditySelector: React.FC<PropsWithChildren<
  MultiCommoditySelectorProps
>> = props => {
  const {
    data,
    buildingStates,
    pointStates,
    groupStates,
    closeHandler,
    initialSelectedAssets,
    clearSelectionsHandler,
  } = props;

  const { isFeatureEnabled } = useFeatureEnablement();

  const translate = useIntl();
  /* =============================================================== *\
     FILTER THAT DATAperfect! (eventually)
  \* =============================================================== */
  const [filterValue, setFilterValue] = useState("");
  const filterChange = (value: string) => {
    setFilterValue(value);
  };

  /**
   * Tests if a string matches the filter value in any way (ignores case, tests all over, etc.)
   *
   * @param input the string you want to check
   * @returns
   */
  const matchesFilter = (input: string): boolean => {
    return input.toLowerCase().includes(filterValue.toLowerCase());
  };

  /**
   * Returns if a piece of equipment _or_ any of it's kids match a filter.
   *
   * @param e the equipment you want to check
   * @returns - if it or a child matches the filter
   */
  const equipmentMatchesFilter = (e: SelectorEquipment): boolean => {
    if (matchesFilter(e.name)) {
      return true;
    }
    for (let i = 0; i < e.equipment.length; i += 1) {
      const r = filteredData.equipment.get(e.equipment[i]);
      if (r && equipmentMatchesFilter(r)) {
        return true;
      }
    }
    return false;
  };

  const equipmentGroupMatchesFilter = (e: SelectorEquipmentGroup): boolean => {
    if (matchesFilter(e.name)) {
      return true;
    }
    for (let i = 0; i < e.equipment.length; i += 1) {
      const r = filteredData.equipment.get(e.equipment[i]);
      if (r && equipmentMatchesFilter(r)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Returns if a meter's name matches the current filter value. I don't think they have kids, so this one is simple!
   * @param m - the meter you care about
   * @returns - if it or a child matches the filter
   */
  const meterMatchesFilter = (m: SelectorMeter): boolean => {
    if (matchesFilter(m.name)) {
      return true;
    }
    return false;
  };

  /**
   * Tests if a meter group or anything inside matches the filter
   *
   * @param mg - the meter group you want to check
   * @returns - if it or one of its meters matches the filter
   */
  const meterGroupMatchesFilter = (mg: SelectorMeterGroup): boolean => {
    if (matchesFilter(mg.name)) {
      return true;
    }
    for (let i = 0; i < mg.meters.length; i += 1) {
      const m = filteredData.meters.get(mg.meters[i]);
      if (m !== undefined && meterMatchesFilter(m)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Returns if a building matches the filter, or if any of the things indside also match the filter
   * @param b - the building you care about
   * @returns - if it or a child matches the filter
   */
  const buildingMatchesFilter = (b: SelectorBuilding): boolean => {
    if (matchesFilter(b.name)) {
      return true;
    }
    for (let i = 0; i < b.equipmentGroups.length; i += 1) {
      const _e = data.equipmentGroups.get(b.equipmentGroups[i]);
      if (_e !== undefined && equipmentGroupMatchesFilter(_e)) {
        return true;
      }
    }
    for (let i = 0; i < b.equipment.length; i += 1) {
      const _e = data.equipment.get(b.equipment[i]);
      if (_e !== undefined && equipmentMatchesFilter(_e)) {
        return true;
      }
    }
    for (let j = 0; j < b.meters.length; j += 1) {
      const _m = data.meters.get(b.meters[j]);
      if (_m !== undefined && meterMatchesFilter(_m)) {
        return true;
      }
    }
    for (let k = 0; k < b.meterGroups.length; k += 1) {
      const _mg = data.meterGroups.get(b.meterGroups[k]);
      if (_mg !== undefined && meterGroupMatchesFilter(_mg)) {
        return true;
      }
    }
    return false;
  };

  /**
   * Returns if a group or any of the groups inside match the filter
   *
   * @param g - the group you are interested in
   * @returns - if it or a child matches the filter
   */
  const groupMatchesFilter = (g: SelectorGroup): boolean => {
    if (matchesFilter(g.name)) {
      return true;
    }
    // we didn't match, but maybe our children did?
    for (let i = 0; i < g.groups.length; i += 1) {
      const _kid = data.groups.get(g.groups[i]);
      if (_kid !== undefined && groupMatchesFilter(_kid)) {
        return true;
      }
    }
    // okay, so now we have to check our buidlings....
    for (let j = 0; j < g.buildings.length; j += 1) {
      const _b = data.buildings.get(g.buildings[j]);
      if (_b !== undefined && buildingMatchesFilter(_b)) {
        return true;
      }
    }
    return false;
  };

  const filteredData: SelectorData = useMemo(
    () => (filterValue === "" ? data : searchFilteredData(data, filterValue)),
    [filterValue, data],
  );

  /* =============================================================== *\
     HANDLE SELECTIONS AND OTHER CLICKY THINGS!
  \* =============================================================== */
  const closeButtonHandler = () => {
    closeHandler();
  };

  const handleGroupCommoditySelect = (
    group: SelectorGroup,
    commodity: PointType,
  ) => {
    // Groups need a reference timezone in case a user clicks
    // on *only* a group - it has to display aggregate data - but groups
    // don't inherently "have" a timezone
    const timezone = findReferenceTimezoneForGroup(filteredData, group);
    console.log(`timezone: ${timezone}`);
    const c: CommoditySelection = {
      assetId: group.id,
      assetName: group.name,
      commodity: commodity,
      timezone,
      queryId: group.id,
      assetType: AssetType.GROUP,
    };
    props.selectionHandler(c);
  };

  const handleBuildingCommoditySelect = (
    building: SelectorBuilding,
    commodity: PointType,
  ) => {
    const c: CommoditySelection = {
      assetId: building.id,
      assetName: building.name,
      commodity: commodity,
      timezone: building.timezone,
      queryId: building.id,
      assetType: AssetType.BUILDING,
    };
    props.selectionHandler(c);
  };

  const handlePointCommoditySelect = (point: SelectorPoint) => {
    const c: CommoditySelection = {
      assetId: point.id,
      assetName: point.meterName,
      commodity: point.commodity,
      timezone: point.timezone,
      queryId: point.meterId,
      assetType: AssetType.POINT,
    };
    props.selectionHandler(c);
  };

  const handleEquipmentPointCommoditySelect = (
    point: SelectorEquipmentPoint,
  ) => {
    const c: CommoditySelection = {
      assetId: point.id,
      assetName: point.equipmentName,
      commodity: point.commodity,
      timezone: point.timezone,
      queryId: point.id,
      assetType: AssetType.EQUIPMENT_POINT,
    };
    props.selectionHandler(c);
  };
  // "default commodity" for that building or meter, so do that thing!
  /**
   * Called when a user clicks on the name of a building. User experience is to
   * behave as if they selected the "default" commodity / point for that building.
   * This default is selected by us because it is what we feel most users want
   * to see based on our knowledge of them there user folks.
   *
   * @param building
   */
  const handleBuildingLevelSelect = (building: SelectorBuilding) => {
    handleBuildingCommoditySelect(
      building,
      defaultCommodityFromList(building.commodities),
    );
  };
  /**
   * Called when a user clicks on the name of a meter. User experience is to
   * behave as if they selected the "default" commodity / point for that meter.
   * This default is selected by us because it is what we feel most users want
   * to see based on our knowledge of them there user folks.
   *
   * @param meter
   */
  const handleMeterLevelSelect = (meter: SelectorMeter) => {
    let theMostWonderfulPoint: SelectorPoint | undefined = undefined;
    let currentScore = 0;
    meter.points.forEach(_pointID => {
      const _point = filteredData.points.get(_pointID);
      if (_point) {
        const _score = commodityScore[_point.commodity] || 0;
        if (_score > currentScore) {
          currentScore = _score;
          theMostWonderfulPoint = _point;
        }
      }
    });
    if (theMostWonderfulPoint) {
      handlePointCommoditySelect(theMostWonderfulPoint);
    }
  };

  /**
   * Called when a user clicks on the name of a equipment. User experience is to
   * behave as if they selected the "default" commodity / point for that equipment.
   * This default is selected by us because it is what we feel most users want
   * to see based on our knowledge of them there user folks.
   *
   * @param meter
   */
  const handleEquipmentLevelSelect = (equipment: SelectorEquipment) => {
    let theMostWonderfulPoint: SelectorEquipmentPoint | undefined = undefined;
    let currentScore = 0;
    equipment.points.forEach(_pointID => {
      const _point = filteredData.equipmentPoints.get(_pointID);
      if (_point) {
        const _score = commodityScore[_point.commodity] || 0;
        if (_score > currentScore) {
          currentScore = _score;
          theMostWonderfulPoint = _point;
        }
      }
    });
    if (theMostWonderfulPoint) {
      handleEquipmentPointCommoditySelect(theMostWonderfulPoint);
    }
  };

  const handleGroupLevelSelect = (group: SelectorGroup) => {
    handleGroupCommoditySelect(
      group,
      defaultCommodityFromList(group.commodities),
    );
  };

  /* =============================================================== *\
     HELPERS THAT NEED TO BE INSIDE!
  \* =============================================================== */
  const isDisabled = (tz: string, state: AssetState): boolean => {
    if (state === AssetState.LOADING || state === AssetState.ERROR) {
      return true;
    }
    /*
    // THIS DISABLES SELECTING BUILDINGS IN DIFFERENT TIME ZONES.
    if (activeTimezone !== undefined && tz !== activeTimezone) {
      return true;
    }
    */
    return false;
  };

  /* =============================================================== *\
     RENDER VARIOUS BITS AND PIECES!
  \* =============================================================== */
  /** draws the filter input at the top */
  const translatedPlaceholder = translate.formatMessage({
    id: "assetSelector.searchPlaceholder",
  });
  const filterInput = () => (
    <SidebarInputWrapper>
      <ClearableInput
        value={filterValue}
        placeholder={translatedPlaceholder}
        onChange={filterChange}
      />
    </SidebarInputWrapper>
  );

  // TODO: combine this with commoditiesForBuildig maybe?
  const commoditiesForGroup = (group: SelectorGroup) => {
    return (
      <CommodityGroup>
        {group.commodities.map((c: PointType) => {
          const _state = groupStates[`${group.id}.${c}`];
          return (
            <SelectableItem
              disabled={false}
              state={_state}
              onClick={() => handleGroupCommoditySelect(group, c)}
              key={c}
            >
              <FormattedMessage id={`commodity.name.${c}`} />
            </SelectableItem>
          );
        })}
      </CommodityGroup>
    );
  };
  const commoditiesForBuilding = (building: SelectorBuilding) => {
    return (
      <CommodityGroup>
        {building.commodities.map((c: PointType) => {
          const _state = buildingStates[`${building.id}.${c}`];
          return (
            <SelectableItem
              disabled={isDisabled(building.timezone, _state)}
              state={_state}
              onClick={() => handleBuildingCommoditySelect(building, c)}
              key={c}
            >
              <FormattedMessage id={`commodity.name.${c}`} />
            </SelectableItem>
          );
        })}
      </CommodityGroup>
    );
  };
  const pointsForMeter = (meter: SelectorMeter) => {
    return (
      <CommodityGroup>
        {meter.points.map((id: string) => {
          const _p = filteredData.points.get(id);
          if (_p) {
            const _state = pointStates[`${_p.id}.${_p.commodity}`];
            return (
              <SelectableItem
                disabled={isDisabled(_p.timezone, _state)}
                state={_state}
                onClick={() => handlePointCommoditySelect(_p)}
                key={id}
              >
                {_p.name}
              </SelectableItem>
            );
          } else {
            return null;
          }
        })}
      </CommodityGroup>
    );
  };

  const pointsForEquipment = (equipment: SelectorEquipment) => {
    if (!equipment.points.length) {
      return null;
    }
    return (
      <CommodityGroup>
        {equipment.points.map(id => {
          const _p = filteredData.equipmentPoints.get(id);
          if (_p) {
            const _state = pointStates[`${_p.id}.${_p.commodity}`];
            return (
              <SelectableItem
                disabled={isDisabled(_p.timezone, _state)}
                state={_state}
                key={id}
                onClick={() => handleEquipmentPointCommoditySelect(_p)}
              >
                <FormattedMessage id={`commodity.name.${_p.commodity}`} />
              </SelectableItem>
            );
          } else {
            return null;
          }
        })}
      </CommodityGroup>
    );
  };

  const isMeterSelected = (meter: SelectorMeter): boolean => {
    return meter.points.some(pointId => {
      const selectorPoint = filteredData.points.get(pointId);
      if (selectorPoint) {
        return (
          pointStates[`${selectorPoint.id}.${selectorPoint.commodity}`] ===
          AssetState.SELECTED
        );
      }

      return false;
    });
  };

  const meterItemsForIDs = (ids: string[], timezone: string) => {
    return ids.map((id: string) => {
      const _m = filteredData.meters.get(id);
      if (_m && _m.points.length > 0) {
        return (
          <MeterItem
            onClick={handleMeterLevelSelect}
            pointList={pointsForMeter(_m)}
            meter={_m}
            key={_m.id}
            initialOpen={initialSelectedAssets.meters.has(id)}
            filterOpen={filterValue !== "" && meterMatchesFilter(_m)}
            disabled={false}
            isSelected={isMeterSelected(_m)}
          />
        );
      }
      return null;
    });
  };

  /* =============================================================== *\
     RENDERS THE METER GROUPS AND METERS UNDER A BUILDING
  \* =============================================================== */
  const metersForBuilding = (building: SelectorBuilding) => {
    return (
      <div>
        {building.meterGroups.map((id: string) => {
          const _g = filteredData.meterGroups.get(id);
          if (_g) {
            return (
              <MeterGroupItem
                key={id}
                group={_g}
                initialOpen={initialSelectedAssets.meterGroups.has(id)}
                filterOpen={filterValue !== "" && meterGroupMatchesFilter(_g)}
              >
                {meterItemsForIDs(_g.meters, building.timezone)}
              </MeterGroupItem>
            );
          } else {
            return null;
          }
        })}
        {building.meterGroups.length > 0 && <BottomLine />}
        {meterItemsForIDs(building.meters, building.timezone)}
      </div>
    );
  };

  const childEquipmentForEquipment = (equipment: SelectorEquipment) => {
    if (!equipment.equipment.length) {
      return null;
    }
    return (
      <>
        {equipment.equipment.map((id: string) => {
          const _e = filteredData.equipment.get(id);
          if (_e && (_e.points.length || _e.equipment.length)) {
            return (
              <EquipmentItem
                key={id}
                initialOpen={initialSelectedAssets.equipment.has(_e.id)}
                equipment={_e}
                pointList={pointsForEquipment(_e)}
                equipmentList={childEquipmentForEquipment(_e)}
                filterOpen={filterValue !== "" && equipmentMatchesFilter(_e)}
                disabled={false}
                onClick={handleEquipmentLevelSelect}
                isSelected={isEquipmentSelected(_e)}
              />
            );
          } else {
            return null;
          }
        })}
      </>
    );
  };
  /* =============================================================== *\
     RENDERS THE EQUIPMENT AND EQUIPMENT GROUPS UNDER A BUILDING
  \* =============================================================== */
  const equipmentForBuilding = (building: SelectorBuilding) => {
    const seenIDs = new Set<string>();
    return (
      <>
        {building.equipmentGroups.map(id => {
          const _eg = filteredData.equipmentGroups.get(id);
          if (_eg && _eg.equipment.length > 0) {
            return (
              <EquipmentGroupItem
                key={id}
                initialOpen={false}
                filterOpen={
                  filterValue !== "" && equipmentGroupMatchesFilter(_eg)
                }
                equipmentList={_eg.equipment.map(eid => {
                  seenIDs.add(eid);
                  return renderEquipmentItem(eid);
                })}
                equipmentGroup={_eg}
              />
            );
          }
          return null;
        })}
        {building.equipmentGroups.length > 0 && <BottomLine />}
        {building.equipment.map(id => {
          if (seenIDs.has(id)) {
            return null;
          }
          return renderEquipmentItem(id);
        })}
      </>
    );
  };

  const renderEquipmentItem = (id: string) => {
    const _e = filteredData.equipment.get(id);
    if (_e && (_e.points.length || _e.equipment.length)) {
      return (
        <EquipmentItem
          key={id}
          initialOpen={initialSelectedAssets.equipment.has(_e.id)}
          equipment={_e}
          pointList={pointsForEquipment(_e)}
          equipmentList={childEquipmentForEquipment(_e)}
          filterOpen={filterValue !== "" && equipmentMatchesFilter(_e)}
          disabled={false}
          onClick={handleEquipmentLevelSelect}
          isSelected={isEquipmentSelected(_e)}
        />
      );
    } else {
      return null;
    }
  };

  const isEquipmentSelected = (equipment: SelectorEquipment) => {
    return equipment.points.some(pointId => {
      const selectorPoint = filteredData.equipmentPoints.get(pointId);
      if (selectorPoint) {
        return (
          pointStates[`${selectorPoint.id}.${selectorPoint.commodity}`] ===
          AssetState.SELECTED
        );
      }
      return false;
    });
  };

  const isBuildingSelected = (building: SelectorBuilding) => {
    return building.commodities.some((chartDataType: PointType) => {
      return (
        buildingStates[`${building.id}.${chartDataType}`] ===
        AssetState.SELECTED
      );
    });
  };

  const isGroupSelected = (group: SelectorGroup) => {
    return group.commodities.some((chartDataType: PointType) => {
      return (
        groupStates[`${group.id}.${chartDataType}`] === AssetState.SELECTED
      );
    });
  };
  /** renders an individual building and builds all its child nodes
   *
   * To re-enable disabling multiple timezones, do this:
   disabled={
          activeTimezone !== undefined && building.timezone !== activeTimezone
        }
   */
  const renderBuilding = (building: SelectorBuilding) => {
    const hasPlatformRealTime = !isFeatureEnabled(
      building.enablements,
      FeatureType.PLATFORM_NO_REAL_TIME,
      false,
    );

    if (hasPlatformRealTime) {
      return (
        <BuildingItem
          key={building.id}
          filterOpen={filterValue !== "" && buildingMatchesFilter(building)}
          initialOpen={initialSelectedAssets.buildings.has(building.id)}
          commodityList={commoditiesForBuilding(building)}
          meterList={metersForBuilding(building)}
          equipmentList={equipmentForBuilding(building)}
          building={building}
          disabled={false}
          onClick={handleBuildingLevelSelect}
          isSelected={isBuildingSelected(building)}
        />
      );
    } else {
      return null;
    }
  };

  /** given a list of building IDs, it will render them and their points and commodities */
  const renderBuildingIds = (ids: string[]) => {
    return ids.map((id: string) => {
      const _b = filteredData.buildings.get(id);
      return _b ? renderBuilding(_b) : null;
    });
  };

  const renderChildGroups = (ids: string[]) => {
    // sort child groups before rendering
    const childGroups = ids
      .map(gId => filteredData.groups.get(gId))
      .sort((a, b) => (a?.name! > b?.name! ? 1 : -1));

    return childGroups.map(group => {
      return group ? renderGroup(group) : null;
    });
  };

  /** render an individual SelectorGroup (and all its kids) */
  const renderGroup = (group: SelectorGroup) => {
    const isSelect = isGroupSelected(group);
    return (
      <GroupItem
        filterOpen={filterValue !== "" && groupMatchesFilter(group)}
        key={group.id}
        group={group}
        onClick={handleGroupLevelSelect}
        initialOpen={initialSelectedAssets.groups.has(group.id)}
        commodityList={commoditiesForGroup(group)}
        isSelected={isSelect}
      >
        {renderChildGroups(group.groups)}
        {renderBuildingIds(group.buildings)}
      </GroupItem>
    );
  };

  /** renders the groups we have and all the delicious stuff inside them too! */
  const renderGroups = () => {
    const _els: React.ReactNode[] = [];
    filteredData.groups.forEach((group: SelectorGroup, id: string) => {
      // don't render groups with a parent ID!
      if (!group.parentId) {
        _els.push(renderGroup(group));
      }
    });
    return _els;
  };

  /** renders the buildings not in groups */
  const renderUngroupedBuildings = () => {
    return (
      <UngroupedBuildingWrapper>
        {renderBuildingIds(filteredData.ungroupedBuildings)}
      </UngroupedBuildingWrapper>
    );
  };

  /* =============================================================== *\
     THE ACTUAL RENDER RETURN
  \* =============================================================== */
  return (
    <SidebarContainer
      disablePadding={true}
      headingHeight={clearSelectionsHandler ? "150px" : "110px"}
      heading={
        <>
          <SidebarHeading
            title="assetSelector.title"
            closeHandler={closeButtonHandler}
            icon={MapPin}
          />
          {filterInput()}
          {clearSelectionsHandler && (
            <ClearSelectionsButton onClick={clearSelectionsHandler}>
              <MxReactIcon Icon={X} size="xs" />{" "}
              <FormattedMessage id="explorer.assetSelector.clearAllSelections" />
            </ClearSelectionsButton>
          )}
        </>
      }
      content={
        <>
          <AssetListWrapper>
            <div>{renderGroups()}</div>
            {renderUngroupedBuildings()}
            {filteredData.groups.size === 0 &&
              filteredData.ungroupedBuildings.length === 0 && (
                <NoSearchResultsLabel>
                  <FormattedMessage id="assetSelector.noResults" />
                </NoSearchResultsLabel>
              )}
          </AssetListWrapper>
        </>
      }
    />
  );
};

export default MultiCommoditySelector;

const ClearSelectionsButton = styled(InlineH4)`
  margin-left: 48px;
  margin-bottom: 8px;
  display: block;
  color: ${AppColors.neutral["light-navy-9"]};
  cursor: pointer;
`;

const DotsIconWrapper = styled.div<CollapseIconProps>`
  margin-left: 16px;
  transition: transform 0.3s;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.open ? AppColors.neutral["light-navy-9"] : "inherit"};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  :hover {
    transform: scale(1.3);
  }
`;
const DotsIcon: React.FC<CollapseIconProps> = props => (
  <DotsIconWrapper {...props}>
    <MxReactIcon
      Icon={ThreeDotsH}
      size="xs"
      color={
        props.open ? AppColors.neutral.navy : AppColors.neutral["light-gray-9"]
      }
    />
  </DotsIconWrapper>
);

/* =============================================================== *\
   GROUP RELATED STUFF
\* =============================================================== */
type GroupItemProps = {
  group: SelectorGroup;
  initialOpen: boolean;
  filterOpen: boolean;
  isSelected: boolean;
  commodityList: ReactNode;
  onClick: (group: SelectorGroup) => void;
};
const GroupItem: React.FC<PropsWithChildren<GroupItemProps>> = props => {
  const {
    group,
    filterOpen,
    initialOpen,
    isSelected,
    commodityList,
    onClick,
  } = props;
  const [open, setOpen] = useState(initialOpen);
  const [commodityListOpen, setCommodityListOpen] = useState(initialOpen);
  /* =============================================================== *\
     This complicated bit is to make things open when we are filtering
     but also allow a user to open / close them in a filtered state.

     There is probably a smarter way of doing it, but I only had one
     cup of coffee when I did this, so please replace it if you have
     a better way of doing it.
  \* =============================================================== */
  const [userInteracted, setUserInteracted] = useState(false);
  useEffect(() => {
    if (userInteracted && filterOpen !== open) {
      setOpen(filterOpen);
    }
  }, [filterOpen]);

  const toggleBuildingList = () => {
    if (!open) {
      tracking.fireEvent(ExplorerPageEvents.EXPAND_ASSET_TREE, {
        groupId: group.id,
      });
    }
    setUserInteracted(true);
    // userTouch.current = true;
    setOpen(!open);
  };

  const toggleComList = () => {
    if (!commodityListOpen) {
      tracking.fireEvent(ExplorerPageEvents.EXPAND_ASSET_TREE, {
        groupId: group.id,
      });
    }
    //userTouch.current = true;
    setUserInteracted(true);
    setCommodityListOpen(!commodityListOpen);
  };

  const isOpen = open || (!userInteracted && filterOpen);
  const GroupHeadComponent = group.parentId ? Item : LinedHeader;
  return (
    <Group>
      <GroupHeadComponent>
        <CollapseIcon
          onClick={toggleBuildingList}
          data-testid="child-list-toggle"
          disabled={false}
          open={isOpen}
        />
        <AssetLabel
          title={group.name}
          isSelected={isSelected}
          isClickable={true}
          onClick={() => onClick(group)}
        >
          {group.name}
        </AssetLabel>{" "}
        <MultiIconWrapper>
          <DotsIcon
            disabled={false}
            open={commodityListOpen}
            data-testid="commodity-list-toggle"
            onClick={toggleComList}
          />
        </MultiIconWrapper>
      </GroupHeadComponent>
      {commodityListOpen && commodityList}
      {isOpen && props.children}
    </Group>
  );
};

/* =============================================================== *\
   Meter groups
\* =============================================================== */
type MeterGroupItemProps = {
  group: SelectorGroup | SelectorMeterGroup;
  initialOpen: boolean;
  filterOpen: boolean;
};
const MeterGroupItem: React.FC<PropsWithChildren<
  MeterGroupItemProps
>> = props => {
  const { group, filterOpen, initialOpen } = props;
  const [open, setOpen] = useState(initialOpen);
  /* =============================================================== *\
     This complicated bit is to make things open when we are filtering
     but also allow a user to open / close them in a filtered state.

     There is probably a smarter way of doing it, but I only had one
     cup of coffee when I did this, so please replace it if you have
     a better way of doing it.
  \* =============================================================== */
  const userTouch = useRef<boolean>(false);
  const toggleOpen = () => {
    userTouch.current = true;
    setOpen(!open);
  };
  useEffect(() => {}, [filterOpen]);
  const isOpen = open || (!userTouch.current && filterOpen);
  return (
    <Group>
      <LinedHeader>
        <CollapseIcon
          onClick={toggleOpen}
          data-testid="child-list-toggle"
          disabled={false}
          open={isOpen}
        />
        <AssetLabel title={group.name}>{group.name}</AssetLabel>{" "}
      </LinedHeader>
      {isOpen && props.children}
    </Group>
  );
};

type BuildingItemProps = {
  initialOpen: boolean;
  filterOpen: boolean;
  building: SelectorBuilding;
  commodityList: ReactNode;
  meterList: ReactNode;
  equipmentList: ReactNode;
  disabled: boolean;
  onClick: (building: SelectorBuilding) => void;
  isSelected: boolean;
};
/**
 * Renders a building and all the kids it has
 *
 * @param props
 */
const BuildingItem: React.FC<BuildingItemProps> = props => {
  const {
    building,
    initialOpen,
    commodityList,
    meterList,
    equipmentList,
    filterOpen,
    disabled = false,
    onClick,
    isSelected = false,
  } = props;
  const [commodityListOpen, setCommodityListOpen] = useState(initialOpen);
  const [meterListOpen, setMeterListOpen] = useState(initialOpen);

  const translate = useIntl();

  /* =============================================================== *\
     This complicated bit is to make things open when we are filtering
     but also allow a user to open / close them in a filtered state.

     There is probably a smarter way of doing it, but I only had one
     cup of coffee when I did this, so please replace it if you have
     a better way of doing it.
  \* =============================================================== */
  const [userInteracted, setUserInteracted] = useState(false);
  useEffect(() => {
    if (userInteracted && filterOpen !== commodityListOpen) {
      setCommodityListOpen(filterOpen);
    }
    if (userInteracted && filterOpen !== meterListOpen) {
      setMeterListOpen(filterOpen);
    }
  }, [filterOpen]);
  const toggleComList = () => {
    // Add Expand Tree Event to MixPanel
    if (!commodityListOpen) {
      tracking.fireEvent(ExplorerPageEvents.EXPAND_ASSET_TREE, {
        buildingId: building.id,
      });
    }
    setUserInteracted(true);
    setCommodityListOpen(!commodityListOpen);
  };
  const togglePointList = () => {
    // Add Expand Tree Event to MixPanel
    if (!meterListOpen) {
      tracking.fireEvent(ExplorerPageEvents.EXPAND_ASSET_TREE, {
        buildingId: building.id,
      });
    }
    setUserInteracted(true);
    setMeterListOpen(!meterListOpen);
  };
  const isCommodityOpen = commodityListOpen || (!userInteracted && filterOpen);
  const isMeterOpen = meterListOpen || (!userInteracted && filterOpen);
  const tzText = translate.formatMessage({
    id: "assetSelector.timezoneMessage",
  });
  return (
    <Group title={disabled ? tzText : undefined}>
      <Item>
        <CollapseIcon
          disabled={false}
          open={isMeterOpen}
          data-testid="meter-list-toggle"
          onClick={togglePointList}
        />{" "}
        <AssetLabel
          title={disabled ? tzText : building.name}
          onClick={disabled ? undefined : () => onClick(building)}
          isClickable={!disabled}
          isSelected={isSelected}
        >
          {building.name}
        </AssetLabel>
        <MultiIconWrapper>
          <DotsIcon
            disabled={disabled}
            open={isCommodityOpen}
            data-testid="commodity-list-toggle"
            onClick={disabled ? undefined : toggleComList}
          />
        </MultiIconWrapper>
      </Item>
      {isCommodityOpen && commodityList}
      {isMeterOpen && meterList}
      {isMeterOpen && equipmentList}
    </Group>
  );
};
/* =============================================================== *\
   METER RELATED STUFF
\* =============================================================== */

type MeterItemProps = {
  initialOpen: boolean;
  meter: SelectorMeter;
  pointList: ReactNode;
  filterOpen: boolean;
  disabled: boolean;
  onClick: (meter: SelectorMeter) => void;
  isSelected: boolean;
};
const MeterItem: React.FC<MeterItemProps> = props => {
  const {
    initialOpen,
    meter,
    pointList,
    filterOpen,
    disabled,
    onClick,
    isSelected,
  } = props;
  const [open, setOpen] = useState(initialOpen);
  /* =============================================================== *\
     This complicated bit is to make things open when we are filtering
     but also allow a user to open / close them in a filtered state.

     There is probably a smarter way of doing it, but I only had one
     cup of coffee when I did this, so please replace it if you have
     a better way of doing it.
  \* =============================================================== */
  const [userInteracted, setUserInteracted] = useState(false);
  const toggleOpen = () => {
    setUserInteracted(true);
    setOpen(!open);
  };
  useEffect(() => {
    if (userInteracted && filterOpen !== open) {
      setOpen(filterOpen);
    }
  }, [filterOpen]);
  const isOpen = open || (!userInteracted && filterOpen);
  const translate = useIntl();
  const tzText = translate.formatMessage({
    id: "assetSelector.timezoneMessage",
  });
  return (
    <Group>
      <IndentedItem>
        <AssetLabel
          title={disabled ? tzText : meter.name}
          onClick={disabled ? undefined : () => onClick(meter)}
          isClickable={!disabled}
          isSelected={isSelected}
        >
          {meter.name}
        </AssetLabel>
        <MultiIconWrapper>
          <DotsIcon
            disabled={disabled}
            open={isOpen}
            onClick={disabled ? undefined : toggleOpen}
            data-testid="point-list-toggle"
          />
        </MultiIconWrapper>
      </IndentedItem>
      {isOpen && pointList}
    </Group>
  );
};
/* =============================================================== *\
   EQUIPMENT RELATED STUFF
\* =============================================================== */
type EquipmentGroupItemProps = {
  initialOpen: boolean;
  equipmentGroup: SelectorEquipmentGroup;
  // pointList: ReactNode;
  equipmentList: ReactNode;
  filterOpen: boolean;
  // disabled: boolean;
  // onClick: (equipment: SelectorEquipment) => void;
  // isSelected: boolean;
};
const EquipmentGroupItem = (props: EquipmentGroupItemProps) => {
  const { initialOpen, filterOpen, equipmentGroup, equipmentList } = props;
  const [childEquipmentOpen, setChildEquipmentOpen] = useState(initialOpen);

  const userInteracted = useRef<boolean>(false);
  const toggleChildEquipmentOpen = () => {
    userInteracted.current = true;
    setChildEquipmentOpen(o => !o);
  };
  useEffect(() => {
    if (userInteracted.current && filterOpen !== childEquipmentOpen) {
      setChildEquipmentOpen(filterOpen);
    }
  }, [filterOpen]);
  const isChildOpen =
    childEquipmentOpen || (!userInteracted.current && filterOpen);
  return (
    <Group>
      <LinedHeader>
        <CollapseIcon
          disabled={false}
          open={isChildOpen}
          data-testid="equip-list-toggle"
          onClick={toggleChildEquipmentOpen}
        />
        <AssetLabel
          title={equipmentGroup.name}
          onClick={undefined}
          isClickable={false}
          isSelected={false}
        >
          {equipmentGroup.name}
        </AssetLabel>
      </LinedHeader>
      {isChildOpen && equipmentList}
    </Group>
  );
};

type EquipmentItemProps = {
  initialOpen: boolean;
  equipment: SelectorEquipment;
  pointList: ReactNode;
  equipmentList: ReactNode;
  filterOpen: boolean;
  disabled: boolean;
  onClick: (equipment: SelectorEquipment) => void;
  isSelected: boolean;
};
const EquipmentItem: React.FC<EquipmentItemProps> = props => {
  const {
    initialOpen,
    equipment,
    pointList,
    equipmentList,
    filterOpen,
    disabled,
    onClick,
    isSelected,
  } = props;
  const [pointsOpen, setPointsOpen] = useState(initialOpen);
  const [childEquipmentOpen, setChildEquipmentOpen] = useState(initialOpen);
  /* =============================================================== *\
     This complicated bit is to make things open when we are filtering
     but also allow a user to open / close them in a filtered state.

     There is probably a smarter way of doing it, but I only had one
     cup of coffee when I copy and pasted this, so please replace it if you have
     a better way of doing it.
  \* =============================================================== */
  const userInteracted = useRef<boolean>(false);
  const togglePointsOpen = () => {
    userInteracted.current = true;
    setPointsOpen(!pointsOpen);
  };
  const toggleChildEquipmentOpen = () => {
    userInteracted.current = true;
    setChildEquipmentOpen(!childEquipmentOpen);
  };
  useEffect(() => {
    if (userInteracted.current && filterOpen !== pointsOpen) {
      setPointsOpen(filterOpen);
    }
    if (userInteracted.current && filterOpen !== childEquipmentOpen) {
      setChildEquipmentOpen(filterOpen);
    }
  }, [filterOpen]);
  const isOpen = pointsOpen || (!userInteracted.current && filterOpen);
  const isChildOpen =
    childEquipmentOpen || (!userInteracted.current && filterOpen);

  const translate = useIntl();
  const tzText = translate.formatMessage({
    id: "assetSelector.timezoneMessage",
  });
  return (
    <Group>
      <IndentedItem>
        {equipmentList && (
          <CollapseIcon
            disabled={false}
            open={isChildOpen}
            data-testid="equip-list-toggle"
            onClick={toggleChildEquipmentOpen}
          />
        )}
        <AssetLabel
          title={disabled ? tzText : equipment.name}
          onClick={disabled ? undefined : () => onClick(equipment)}
          isClickable={!disabled}
          isSelected={isSelected}
        >
          {equipment.name}
        </AssetLabel>
        {pointList && (
          <MultiIconWrapper>
            <DotsIcon
              disabled={disabled}
              open={isOpen}
              onClick={disabled ? undefined : togglePointsOpen}
              data-testid="point-list-toggle"
            />
          </MultiIconWrapper>
        )}
      </IndentedItem>
      {isOpen && pointList}
      {isChildOpen && equipmentList}
    </Group>
  );
};

/* =============================================================== *\
   POINT RELATED STUFF
\* =============================================================== */
const ItemLabel = styled.span`
  margin-left: 8px;
  min-width: 0px; /* stops us from growing out past parent */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
type SelectableWrapperProps = {
  state: AssetState | undefined;
  disabled: boolean;
  onClick: () => void;
};
const SelectableItem: React.FC<PropsWithChildren<SelectableWrapperProps>> = ({
  state,
  onClick,
  disabled,
  children,
}) => (
  <SelectableItemWrapper
    disabled={disabled}
    onClick={disabled ? undefined : onClick}
    title={""}
  >
    {accessoryForAssetState(state)}
    <ItemLabel>{children}</ItemLabel>
  </SelectableItemWrapper>
);

const SelectableItemWrapper = styled.div<{ disabled: boolean }>`
  color: ${props => (props.disabled ? AppColors.neutral.white : "inherit")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  padding: 8px 0 8px 20px;
  // if we are inside an indented wrapper...
`;

// generic container, as they are nested, things indent properly!
const Group = styled.div`
  padding-left: 8px;
`;
// special container that abuses CSS to get full width backgrounds even when
// they are nested. This makes life easier when we add nesting levels
const Item = styled.div`
  margin-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
    /*
  & > ${AssetLabel} {
    padding-left: 4px;
  }
  */
`;
const IndentedItem = styled(Item)`
  padding-left: 20px;
`;

const CommodityGroup = styled(Group)`
  position: relative;
  z-index: 2;
  padding-left: 12px;

  ${IndentedItem} + & {
    padding-left: 6px;
  }

  &:after {
    position: absolute;
    width: 200%;
    left: -100%;
    top: 0;
    bottom: 0;
    content: "";
    z-index: -1;
    background-color: ${AppColors.neutral["light-navy-1"]};
  }
`;
const LinedHeader = styled(Item)`
  border-top: 1px solid ${AppColors.neutral["light-gray-9"]};
`;
const UngroupedBuildingWrapper = styled(Group)`
  position: relative;

  &:after {
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    border-top: 1px solid ${AppColors.neutral["light-gray-9"]};
    content: "";
  }

  & > ${Group} {
    margin-left: -16px;
  }
`;
const BottomLine = styled.div`
  height: 1px;
  border-top: 1px solid ${AppColors.neutral["light-gray-9"]};
  margin-left: 20px;
  margin-right: 20px;
`;
