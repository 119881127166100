import { useUserContext } from "src/auth/UserContext";
import { PointType, TemperatureUnit, Unit } from "src/types/charting";
import { AreaUnit, Locale } from "src/types/graphql";
import { getUnitForPointType } from "@hatchdata/equipment-types-package/dist/src";

// TODO: Another API query to get ALL default units?

const useUnitPrefs = () => {
  const { userData } = useUserContext();
  /** returns the user's preferred unit for a PointType or undefined if missing */
  const unitPrefForPoint = (pointType: PointType): Unit | undefined => {
    const locale = userData?.preferences?.locale || Locale.EN_US;
    return getUnitForPointType(pointType, locale) as Unit;
  };
  /** returns the user's preferred Temperature unit or a default if missing */
  const unitPrefForTemperature = (userData?.preferences?.preferredUnits?.TEMPERATURE ?? TemperatureUnit.FAHRENHEIT) as TemperatureUnit;

  /** returns the users preferred Area unit based on locale or a default if missing */
  const unitPrefForArea = (userData?.preferences?.locale === Locale.EN_US) ? AreaUnit.SQFT : AreaUnit.SQM;
  /**
   * returns all the user's preferred units or the EN_US defaults if missing
   * TODO: remember why we do this instead of just using `unitPrefForPoint`
   * I think it is because meter level queries get ALL THE POINTS so we just
   * pass in ALL THE PREFS because we don't know what we are getting?
   */
  const unitPreferences = userData?.preferences?.preferredUnits;

  return { unitPrefForPoint, unitPrefForTemperature, unitPrefForArea, unitPreferences };
};

export { useUnitPrefs };