import * as React from "react";
import { PointType } from "src/types/charting";
import { Locale } from "@hatchdata/intl-formatter";
import { useSelect } from "downshift";
import {
  AdjustedSelectButton,
  DropdownMenu,
  DropdownOption,
  DropdownWrapper,
} from "src/components/common/Dropdown/Dropdown";
import { getSymbolForPointType } from "src/helpers/charting";
import { FormattedMessage } from "react-intl";

export const PointTypeSelector = ({
  defaultValue,
  items,
  onChange,
  width = "100%",
  locale,
}: {
  defaultValue: PointType;
  items: PointType[];
  onChange: (value: PointType) => void;
  width?: string;
  locale: Locale;
}) => {
  const {
    selectedItem,
    isOpen,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
  } = useSelect({
    items,
    defaultSelectedItem: defaultValue,
    onSelectedItemChange: changes => {
      if (changes.selectedItem) {
        onChange(changes.selectedItem);
      }
    },
  });

  return (
    <>
      <DropdownWrapper maxWidth={width}>
        <AdjustedSelectButton
          type="button"
          {...getToggleButtonProps()}
          disabled={true}
        >
          <FormattedMessage id={`commodity.name.${selectedItem}`} /> (
          {getSymbolForPointType(selectedItem, locale)})
        </AdjustedSelectButton>
        <DropdownMenu {...getMenuProps()}>
          {isOpen &&
            items.map((item, index) => (
              <DropdownOption
                selected={highlightedIndex === index}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                <FormattedMessage id={`commodity.name.${item}`} /> (
                {getSymbolForPointType(item, locale)} )
              </DropdownOption>
            ))}
        </DropdownMenu>
      </DropdownWrapper>
    </>
  );
};
