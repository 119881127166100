// TODO: stop using this and change all imports to use the GQL types. HOWEVER....
// WARNING: if you do the above as-is, RoleBasedComponent will stop working!
// Turns out the roles you get from the token are lower case, but the ones in the GQL
// types are upper case, so all role based checks fail.
export enum UserRole {
  ADMIN = "admin",
  ALL_BUILDINGS_FOR_ORGANIZATION = "all_buildings_for_organization",
  CUSTOMER_PREVIEW = "customer_preview",
  FINANCIAL_ONLY = "financial_only",
  PREVIEW = "preview",
  READ_ONLY = "read_only",
  INTERNAL_SUPPORT = "internal_support",
  CUSTOMER_ADMIN = "customer_admin",
  FIELD_OPERATOR = "field_operator",
  CUSTOMER_SUPPORT = "customer_support",
}
