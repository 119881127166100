import { ScheduleExceptionEvent } from "src/components/app/ExplorerPage/types";
import { OverlayFormData } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { DateTime } from "luxon";
import { ExceptionOperatingTimeBlock } from "src/types/graphql";
import { isSameDay } from "src/components/common/TimeRangePicker/helpers";

export const mapScheduleExceptionEventToOverlayFormData = (
  scheduleExceptionEvent: ScheduleExceptionEvent,
  timezone: string,
): OverlayFormData => {
  const exceptionTimeBlock: ExceptionOperatingTimeBlock | null =
    scheduleExceptionEvent.exceptionTimeRanges &&
    scheduleExceptionEvent.exceptionTimeRanges.length > 0
      ? scheduleExceptionEvent.exceptionTimeRanges[0]
      : null;

  const defaultStartTime: DateTime = DateTime.local()
    .set({
      year: DateTime.fromJSDate(
        new Date(scheduleExceptionEvent.exceptionDate),
      ).get("year"),
    })
    .startOf("day")
    .set({
      hour: exceptionTimeBlock?.startHour ?? 0,
      minute: exceptionTimeBlock?.startMinute ?? 0,
    });

  const defaultEndTime: DateTime = DateTime.local()
    .set({
      year: DateTime.fromJSDate(
        new Date(scheduleExceptionEvent.exceptionEndDate!),
      ).get("year"),
    })
    .startOf("day")
    .set({
      hour: exceptionTimeBlock?.endHour ?? 0,
      minute: exceptionTimeBlock?.endMinute ?? 0,
    });

  const startDate = DateTime.fromISO(scheduleExceptionEvent.exceptionDate, {
    zone: timezone,
  }).setZone("local", { keepLocalTime: true });

  const endDate = DateTime.fromISO(scheduleExceptionEvent.exceptionEndDate, {
    zone: timezone,
  }).setZone("local", { keepLocalTime: true });

  // the stored exception end date is exclusive, so subtract a day
  const adjustedEndDate = isSameDay(startDate.toJSDate(), endDate.toJSDate())
    ? endDate
    : endDate.minus({ days: 1 });

  return {
    overlayId: scheduleExceptionEvent.id,
    buildingId: scheduleExceptionEvent.buildingId,
    startDate: startDate.toJSDate(),
    startTime: defaultStartTime.hour + defaultStartTime.minute / 60,
    endDate: adjustedEndDate.toJSDate(),
    endTime: defaultEndTime.hour + defaultEndTime.minute / 60,
    annotationCategories: scheduleExceptionEvent.reasons,
    note: scheduleExceptionEvent.notes,
    title: scheduleExceptionEvent.name,
    isOccupied: exceptionTimeBlock?.isOperating ?? true,
  } as OverlayFormData;
};
