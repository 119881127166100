import { SelectorData } from "../../MultiCommoditySelector/types";
import { AssetType } from "../types";

type Asset = { assetId: string; assetType: AssetType };

/**
 * Returns the parent building for each asset in `queueItems` along
 * with the total number of selected assets belonging to each building.
 * @param queueItems
 * @param assetData
 * @returns
 */
export const allSelectedParentBuildings = (
  queueItems: Asset[],
  assetData: SelectorData,
): Map<string, number> => {
  const buildings = new Map<string, number>();

  queueItems.forEach(item => {
    const buildingId = getBuildingForAsset(item, assetData);

    if (buildingId) {
      if (!buildings.has(buildingId)) {
        buildings.set(buildingId, 0);
      }

      buildings.set(buildingId, buildings.get(buildingId)! + 1);
    }
  });

  return buildings;
};

/**
 * Returns the building ID associated with an asset. If no asset is found,
 * an empty string is returned.
 * @param asset the asset to search for
 * @param assetData the assets used to create the asset selector.
 * @returns
 */
export const getBuildingForAsset = (
  asset: Asset,
  assetData: SelectorData,
): string => {
  if (asset.assetType === AssetType.BUILDING) {
    return asset.assetId;
  } else if (asset.assetType === AssetType.METER) {
    return getBuildingForMeter(asset.assetId, assetData);
  } else if (asset.assetType === AssetType.POINT) {
    return getBuildingForPoint(asset.assetId, assetData);
  } else if (asset.assetType === AssetType.EQUIPMENT_POINT) {
    return getBuildingForEquipmentPoint(asset.assetId, assetData);
  } else {
    return "";
  }
};

const getBuildingForMeter = (
  meterId: string,
  assetData: SelectorData,
): string => {
  let buildingId = "";

  for (const [, building] of assetData.buildings) {
    if (building.meters.includes(meterId)) {
      buildingId = building.id;
      break;
    }
  }

  // The meter might be in the meter group map instead.
  if (!buildingId) {
    for (const [, meterGroup] of assetData.meterGroups) {
      if (meterGroup.meters.includes(meterId)) {
        buildingId = meterGroup.buildingId;
        break;
      }
    }
  }

  return buildingId;
};

const getBuildingForPoint = (pointId: string, assetData: SelectorData) => {
  let buildingId = "";

  for (const [, meter] of assetData.meters) {
    if (meter.points.includes(pointId)) {
      buildingId = getBuildingForMeter(meter.id, assetData);
      break;
    }
  }

  return buildingId;
};

const getBuildingForEquipmentPoint = (
  equipmentPointId: string,
  assetData: SelectorData,
) => {
  let equipmentId: string = "";
  let buildingId: string = "";

  for (const [, equipment] of assetData.equipment) {
    if (equipment.points.includes(equipmentPointId)) {
      equipmentId = equipment.id;
      break;
    }
  }

  if (equipmentId) {
    for (const [, building] of assetData.buildings) {
      if (building.equipment.includes(equipmentId)) {
        buildingId = building.id;
        break;
      }
    }
  }

  return buildingId;
};
