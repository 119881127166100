import React, { PropsWithChildren } from "react";
import styled from "styled-components/macro";
import AOLogotype from "src/components/common/AOLogotype";
import { LogoSize } from "src/components/common/AOLogotype/AOLogotype";
import { AppColors } from "src/components/common/Styling";

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenteredBoxStyle = styled.div`
  max-width: 500px;
  min-width: 320px;
  background-color: ${AppColors.neutral.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 82px 48px;
  @media (max-width: 600px) {
    padding: 24px 16px 32px;
    min-width: 280px;
  }
`;

export const PlainCenteredBox = styled.div`
  max-width: 500px;
  min-width: 320px;
  background-color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 82px 96px;
  text-align: center;
  @media (max-width: 600px) {
    padding: 24px 16px 32px;
    min-width: 280px;
  }
`;

const CenteredBoxContent = styled.div`
  text-align: center;
  margin-top: 36px;
`;

const CenteredBox: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Container>
      <CenteredBoxStyle>
        <AOLogotype size={LogoSize.medium} />
        <CenteredBoxContent>{children}</CenteredBoxContent>
      </CenteredBoxStyle>
    </Container>
  );
};

export default CenteredBox;
