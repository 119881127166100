import React from "react";
import styled from "styled-components/macro";
import { Body } from "src/components/common/Typography";
import {
  ParsedChartData,
  MetaInfo,
  CommodityChartData,
  PointType,
} from "src/types/charting";
import { FormattedMessage } from "react-intl";
import { friendlyNumberFormat } from "src/helpers/charting";
import { AppColors } from "src/components/common/Styling";
import { aggregateTypeForPointType } from "@hatchdata/equipment-types-package/dist/src";
import { dateToLocaleString, FormatOptions } from "@hatchdata/intl-formatter";

const SummaryMetricsTable = styled.table`
  table-layout: fixed;
  width: 100%;
  background-color: ${AppColors.neutral.white};

  border-left: 1px solid ${AppColors.neutral["light-navy-5"]};
  border-right: 1px solid ${AppColors.neutral["light-navy-5"]};
  /*
  tbody tr:nth-child(even) {
    background-color: ${AppColors.neutral["light-gray-9"]};
  }
  */
`;
type SummaryMetricsHeaderProps = {
  multiSeries: boolean;
};
const SummaryMetricsHeader = styled.th<SummaryMetricsHeaderProps>`
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  color: ${AppColors.neutral.navy};
  padding: 12px;
  border-top: 1px solid ${AppColors.neutral["light-navy-5"]};
  border-bottom: 1px solid ${AppColors.neutral["light-navy-5"]};
  background-color: ${AppColors.neutral["light-navy-11"]};
`;

const SummaryMetricsCell = styled.td`
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid ${AppColors.neutral["light-navy-5"]};
`;
const SummaryMetricCellText = styled(Body)`
  min-width: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${AppColors.neutral["navy"]};
`;
const SummaryMetricCellInfo = styled(Body)`
  color: ${AppColors.neutral["navy"]};
`;

const SummaryMetricsGroup = styled.div`
  padding-left: 16px;
  color: ${AppColors.neutral["light-navy-1"]};
`;

// ADD PROPS LIKE "OUTLINED and COLOR" OR SOMETHING
// THEN MAKE BG COLOR AND BORDER BAESD ON THAT
type ColorBlockometerProps = {
  color: string;
  isOutlined?: boolean;
};
const ColorBlockometer = styled.span<ColorBlockometerProps>`
  display: inline-block;
  height: 16px;
  width: 16px;
  background-color: ${props =>
    props.isOutlined ? "transparent" : props.color};
  margin-right: 8px;
  border-radius: 50%;
  border: ${props => (props.isOutlined ? `2px solid ${props.color}` : "none")};
  position: relative;
  top: 2px;
`;

// takes a thing that may not exist and returns a nicely formatted thing if it
// exists. It is its own function because a simple ternary treats zero as not there
const displayVal = (
  val: number | null | undefined,
  unit: string = "",
): string => {
  if (val === null || val === undefined) {
    return "--";
  }
  return `${friendlyNumberFormat(val)} ${unit}`;
};

const dateFmtFunc = (date: Date, timezone?: string) =>
  dateToLocaleString(
    date,
    undefined,
    timezone,
    FormatOptions.DATETIME_WITH_TIMEZONE,
  );
const shortDateFn = (date: Date, timezone?: string) =>
  dateToLocaleString(date, undefined, timezone);
const rowForMeta = (
  meta: MetaInfo,
  chartData: CommodityChartData,
  commodity: string,
  showPeriod: boolean = false,
) => {
  // technically I should do this down in the `showPeriod` bit since we don't
  // need this unless we are comparing to past, but it is ugly down there.
  const [_start, _end] = chartData.queueItem.timeseriesKey.split("_");
  const _sd = new Date(Number(_start));
  const _ed = new Date(Number(_end));
  // hooray!
  const aggregateKey = aggregateTypeForPointType(commodity as PointType);
  const unit = chartData.chartProps.unit || "";
  return (
    <tr key={chartData.chartProps.dataKey as string}>
      <SummaryMetricsCell>
        <SummaryMetricCellText title={chartData.queueItem.assetName || "--"}>
          <ColorBlockometer
            color={chartData.chartProps.stroke as string}
            isOutlined={chartData.isPastComparison}
          />
          {chartData.queueItem.assetName || "--"}
        </SummaryMetricCellText>
      </SummaryMetricsCell>
      {showPeriod && (
        <SummaryMetricsCell>
          <SummaryMetricCellText>
            {shortDateFn(_sd, chartData.queueItem.timezone)} -
            {shortDateFn(_ed, chartData.queueItem.timezone)}
          </SummaryMetricCellText>
        </SummaryMetricsCell>
      )}
      <SummaryMetricsCell>
        <Body>{displayVal(meta.min.value, unit.toString())}</Body>
        <SummaryMetricCellInfo>
          {meta.min.timestamp
            ? dateFmtFunc(
                new Date(meta.min.timestamp),
                chartData.queueItem.timezone,
              )
            : ""}
        </SummaryMetricCellInfo>
      </SummaryMetricsCell>
      <SummaryMetricsCell>
        <Body>{displayVal(meta.max.value, unit.toString())}</Body>
        <SummaryMetricCellInfo>
          {meta.max.timestamp
            ? dateFmtFunc(
                new Date(meta.max.timestamp),
                chartData.queueItem.timezone,
              )
            : ""}
        </SummaryMetricCellInfo>
      </SummaryMetricsCell>
      <SummaryMetricsCell>
        <Body>{displayVal(meta[aggregateKey], unit.toString())}</Body>
      </SummaryMetricsCell>
    </tr>
  );
};

type SummaryMetricsProps = {
  data: ParsedChartData;
};
const SummaryMetrics: React.FC<SummaryMetricsProps> = ({ data }) => {
  const tsKeys = Object.keys(data.metrics);
  if (tsKeys.length === 0) {
    return null;
  }

  const timeseriesKey = tsKeys[0];
  const commodityMetrics = data.metrics[timeseriesKey];

  const commodityGroups = Object.keys(commodityMetrics).filter(
    _k => _k !== "timezone",
  );

  return (
    <section>
      {commodityGroups.map(commodity => {
        return (
          <section key={commodity}>
            <SummaryMetricsGroup>
              <Body>
                <FormattedMessage id={`commodity.name.${commodity}`} />
              </Body>
            </SummaryMetricsGroup>
            <SummaryMetricsTable cellSpacing={0}>
              <thead>
                <tr>
                  <SummaryMetricsHeader multiSeries={tsKeys.length > 1}>
                    <FormattedMessage id="summaryMetrics.label.selection" />
                  </SummaryMetricsHeader>
                  {tsKeys.length > 1 && (
                    <SummaryMetricsHeader multiSeries={tsKeys.length > 1}>
                      <FormattedMessage id="summaryMetrics.label.period" />
                    </SummaryMetricsHeader>
                  )}
                  <SummaryMetricsHeader multiSeries={tsKeys.length > 1}>
                    <FormattedMessage id="summaryMetrics.label.minimum" />
                  </SummaryMetricsHeader>
                  <SummaryMetricsHeader multiSeries={tsKeys.length > 1}>
                    <FormattedMessage id="summaryMetrics.label.maximum" />
                  </SummaryMetricsHeader>
                  <SummaryMetricsHeader multiSeries={tsKeys.length > 1}>
                    <FormattedMessage
                      id={`summaryMetrics.label.${aggregateTypeForPointType(
                        commodity as PointType,
                      )}`}
                    />
                  </SummaryMetricsHeader>
                </tr>
              </thead>
              <tbody>
                {tsKeys.map(_tsk => {
                  // outer map over time series keys to support compare to past
                  // these guard against rendering while loading a bunch of
                  // past data streams: we may not have the "past" version yet!
                  const metaForTime = data.metrics[_tsk];
                  if (!metaForTime) {
                    return null;
                  }
                  const commodityForTime = metaForTime[commodity];
                  if (!commodityForTime) {
                    return null;
                  }
                  return Object.keys(
                    metaForTime[commodity] as { [key: string]: MetaInfo },
                  ).map(_k => {
                    // inner map over commodity names

                    const dataKey = `${_tsk}.${commodity}.${_k}`;
                    const chartData = data.charts.get(dataKey);
                    if (chartData === undefined) {
                      return null;
                    }
                    return rowForMeta(
                      (metaForTime[commodity] as { [key: string]: MetaInfo })[
                        _k
                      ],
                      chartData,
                      commodity,
                      tsKeys.length > 1,
                    );
                  });
                })}
              </tbody>
            </SummaryMetricsTable>
          </section>
        );
      })}
    </section>
  );
};

export default SummaryMetrics;
