import React, {
  ReactNode,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import throttle from "lodash.throttle";
import clamp from "lodash.clamp";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutsideClickEvent } from "src/hooks/useOutsideClickEvent";
import Auth from "src/auth/Auth";
import {
  MxReactToggle,
  MxReactToggleBar,
} from "src/componentLibrary/react/mx-toggle";
import DotMenu, { DotMenuItem } from "src/components/common/DotMenu";
import { ComposableErrorToast, ErrorToast } from "src/components/common/Toast/";
import { exportToCsv } from "src/components/app/ExplorerPage/explorerCSVExporter";
import explorerBuildPDFURL from "src/components/app/ExplorerPage/explorerBuildPDFURL";
import {
  Circle,
  CircleSolid,
  Download,
  Hand,
  MagnifyMinus,
  MagnifyPlus,
  MxReactIcon,
  X,
} from "src/componentLibrary/react/mx-icon-react";
import TimeRangePicker, {
  CompareToPastRangePicker,
  ICompareRangeChange,
  ITimeRangeChange,
  TimePickerHolder,
} from "src/components/common/TimeRangePicker";
import {
  Card,
  CardHeaderBar,
  CardHeading,
} from "src/components/common/CardLayout";
import SummaryMetrics from "src/components/app/SummaryMetrics";
import {
  ChartControlOutlineContainer,
  ChartControls,
  WrappingLegendContainer,
} from "src/components/common/ChartParts";
import {
  CartesianGrid,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxisProps,
  YAxisProps,
} from "recharts";
import { DataKey } from "recharts/types/util/types";
import { charts, legends, xAxes, yAxes } from "./ChartFactory";
import {
  chartGranularityAsSeconds,
  convertRangeToPickerDates,
  dayBoundariesInRange,
  getGranularityOptions,
  getSymbolForPointType,
  precisionNumberFormat,
} from "src/helpers/charting";
import { truncateStringEnd } from "src/helpers/strings";
import {
  BuildingThreshold,
  ChartDataGranularity,
  ChartDataRange,
  ComparePeriod,
  ExportType,
  ITimeRange,
  ParsedChartData,
} from "src/types/charting";
import GenericDataError from "src/components/common/GenericDataError";
import { GenericDataErrorType } from "src/components/common/GenericDataError/GenericDataError";
import { tracking } from "src/tracking";
import { ExplorerPageEvents, UserMode } from "./ExplorerPage";
import { downloadBlobFile } from "src/helpers/csvExporter";
import DotMenuIconItem from "src/components/common/DotMenu/DotMenuIconItem";
import { keyFromTimeRange } from "./helpers";
import { AppColors } from "src/components/common/Styling";
import ExplorerExportModal from "./ExplorerExportModal";
import { Payload } from "recharts/types/component/DefaultTooltipContent";
import { eventReferenceLines } from "src/components/app/ExplorerPage/ChartFactory/EventReferenceLines";
import { eventReferenceAreas } from "src/components/app/ExplorerPage/ChartFactory/EventReferenceAreas";
import {
  BookendLayout,
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import { OverlayMenu } from "src/components/app/ExplorerPage/OverlayMenu";
import {
  AnnotationEvent,
  AssetType,
  MeasureEvent,
  OverlayEventState,
  ScheduleExceptionEvent,
} from "./types";
import ReactTooltip from "react-tooltip";
import MeasureEventTooltip from "./ChartFactory/MeasureEventTooltip";
import { CurrentOrganizationContext } from "../AuthenticatedPage";
import {
  initialState,
  overlayFormReducer,
  OverlayType,
} from "./OverlayStuff/overlayReducer";
import CreateOverlayContainer from "./OverlayStuff/OverlayContainer";
import { useUserContext } from "src/auth/UserContext";
import { ChartDataAction } from "./chartDataReducer";
import { DateTime } from "luxon";
import {
  dateToLocaleString,
  FormatOptions,
  Locale,
} from "@hatchdata/intl-formatter";
import SimpleTabs from "src/components/common/SimpleTabs";
import OverlaysTab from "src/components/app/ExplorerPage/OverlayStuff/OverlaysTab";
import { MxSecondaryReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";
import { ScheduleExceptionsTooltip } from "src/components/app/ExplorerPage/Tooltips/ScheduleExceptionTooltip";
import { AnnotationsTooltip } from "src/components/app/ExplorerPage/Tooltips/AnnotationsTooltip";
import { MultiTooltip } from "src/components/app/ExplorerPage/Tooltips/MultiTooltip";
import { BasicSelect } from "src/components/common/Dropdown/BasicSelect";
import TimePickerButton from "src/components/common/TimeRangePicker/TimePickerButton";
import { formattedDate } from "src/components/app/BaselineChart";
import {
  BuildingAnnotationReason,
  BuildingAnnotationType,
} from "src/types/graphql";
import { UserRole } from "src/types/roles";
import { useAuthorization } from "src/hooks/useAuthorization";
import { mapScheduleExceptionEventToOverlayFormData } from "src/components/app/ExplorerPage/mapScheduleExceptionEventToOverlayFormData";

const tooltipLabelFormatter = (value: number) => {
  return [precisionNumberFormat(value), " "];
};

/* =============================================================== *\
   PAN HELPER THINGS
\* =============================================================== */
const getScrollBounds = () => {
  const _d = new Date();
  const _r = _d.getTime();
  _d.setFullYear(_d.getFullYear() - 3);
  return {
    rightScrollBound: _r,
    leftScrollBound: _d.getTime(),
  };
};

// what are the domains for the charts at the moment?
// the key is the primary / secondary timeseries keys,
// and the numbers are the start / end timestamps
export type ChartDomains = {
  primary: [number, number];
  secondary: [number, number];
};

/**
 * Takes a domain in [timestamp, timestamp] format and converts it
 * into an ITimeRange using the first element as the startTime and
 * the second as the endTime.
 *
 * @param domain the domain you want to convert
 */
const domainToTimeRange = (domain: [number, number]): ITimeRange => ({
  startTime: new Date(domain[0]).toISOString(),
  endTime: new Date(domain[1]).toISOString(),
});

/* =============================================================== *\
   END PAN HELPER THINGS
\* =============================================================== */

// shape of user selection
type SelectedRegion = {
  left: number | false;
  right: number | false;
};
/* =============================================================== *\
   COMPONENT

   // TODO: see if we can get the header out of here or do something
   // to get rid of all those awful props
\* =============================================================== */
type ExplorerCardProps = {
  timezone: string;
  chartDataState: ParsedChartData;
  timeRange: ITimeRange;
  secondaryTimeRange: ITimeRange;
  granularity: ChartDataGranularity;
  chartDataRange: ChartDataRange;
  compareToPast: boolean;
  compareType: ComparePeriod;
  handleGranularityChange: (s: string) => void;
  handleRangeAccept: (vals: ITimeRangeChange, tz: string) => void; // handles selcteing new primary date range
  handleLegendItemClick: (dataKey: DataKey<string>) => void;
  handleCompareChange: (data: ICompareRangeChange, tz: string) => void; // handles chanign dates / period
  handleCompareCancel: () => void; // handles turning compare OFF
  userMode: UserMode;
  setUserMode: (mode: UserMode) => void;
  zoomFunctions: {
    in: (vals: ITimeRangeChange) => void;
    out: () => void;
  };
  overlayProps: {
    overlayState: OverlayEventState;
    setOverlayState: React.Dispatch<React.SetStateAction<OverlayEventState>>;
  };
  latitude: number;
  longitude: number;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
};
export const ExplorerCard: React.FC<ExplorerCardProps> = props => {
  const { hasRole } = useAuthorization();

  const {
    timezone,
    chartDataState,
    timeRange,
    secondaryTimeRange,
    granularity,
    chartDataRange,
    compareToPast,
    compareType,
    handleGranularityChange,
    handleRangeAccept,
    handleLegendItemClick,
    handleCompareChange,
    handleCompareCancel,
    zoomFunctions,
    setUserMode,
    userMode,
    overlayProps,
    chartDataDispatch,
    latitude,
    longitude,
  } = props;
  /* =============================================================== *\
     PAN RELATED THINGS
  \* =============================================================== */

  // create the clamps for scrolling
  const scrollBounds = getScrollBounds();
  // track the current domains for our chart(s)
  const [currentDomains, setCurrentDomains] = useState<ChartDomains>({
    primary: [
      new Date(timeRange.startTime).getTime(),
      new Date(timeRange.endTime).getTime(),
    ],
    secondary: [
      new Date(secondaryTimeRange.startTime).getTime(),
      new Date(secondaryTimeRange.endTime).getTime(),
    ],
  });
  const domainSize =
    new Date(timeRange.endTime).getTime() -
    new Date(timeRange.startTime).getTime();
  // are we actively panning (not "do we have pan mode selected", but are we in the act of panning)
  const isPanning = useRef(false);
  // will hold the activeTooltipIndex of panning to use to caluculate new domain
  const panStartPosition = useRef<null | number>(null);
  const panIncrement = chartGranularityAsSeconds(granularity) * 100; // amount to move domain by when we pan
  /* =============================================================== *\
     END PAN STUFF
  \* =============================================================== */
  // ZOOM STUFF!
  const [dragArea, setDragArea] = useState<SelectedRegion>({
    left: false,
    right: false,
  });
  // are we generating a PDF?
  const [loadingExport, setLoadingExport] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  // timepicker stuff
  const timeRangeRef = useRef(null);
  const compareRangeRef = useRef(null);
  const handleNonPickClick = () => {
    if (showPicker) {
      hidePicker();
    }
    if (showComparePicker) {
      hideComparePicker();
    }
  };

  const primaryTSK = keyFromTimeRange(timeRange);
  // get the first x-axis id
  let xAxisId: string | number | undefined;
  if (chartDataState.xAxes.size > 0) {
    xAxisId = (chartDataState.xAxes.values().next().value as XAxisProps)
      .xAxisId;
  }
  // get the first y-axis id
  let yAxisId: string | number | undefined;
  if (chartDataState.yAxes.size > 0) {
    yAxisId = (chartDataState.yAxes.values().next().value as YAxisProps)
      .yAxisId;
  }
  let dayBoundaries: Date[] = [];
  if (chartDataRange <= 45) {
    //dayBoundaries = dayBoundariesInRange(timeRange, timezone);
    // figure out which axis was rendered first... sometimes it is
    // the secondary for a brief moment and that explodes things
    const useDomain =
      xAxisId === primaryTSK
        ? currentDomains.primary
        : currentDomains.secondary;
    const _r = domainToTimeRange(useDomain);
    dayBoundaries = dayBoundariesInRange(_r, timezone);
  }

  useOutsideClickEvent(handleNonPickClick, timeRangeRef);
  useOutsideClickEvent(handleNonPickClick, compareRangeRef);
  const [showPicker, setShowPicker] = useState(false);
  const hidePicker = () => setShowPicker(false);
  const togglePicker = () => setShowPicker(!showPicker);
  const userAcceptTimeChange = (vals: ITimeRangeChange) => {
    hidePicker();
    handleRangeAccept(vals, timezone);
  };

  const [showComparePicker, setShowComparePicker] = useState(false);
  const hideComparePicker = () => setShowComparePicker(false);
  const toggleComparePicker = () => setShowComparePicker(!showComparePicker);
  const userAcceptCompareChange = (vals: ICompareRangeChange) => {
    hideComparePicker();
    handleCompareChange(vals, timezone);
  };
  const translate = useIntl();

  const tooltipHeaderFormat = (_v: number | string) => {
    return (_timeStampKey: string) => {
      if (!_v) {
        return "";
      }

      //Defensive code to defaults to timezone variable if no timezone is found with the given timestamp key
      const tz = chartDataState.metadata[_timeStampKey]
        ? chartDataState.metadata[_timeStampKey].timezone
        : timezone;

      const _d = new Date(_v);
      return dateToLocaleString(
        _d,
        undefined,
        tz,
        FormatOptions.DATETIME_SHORT,
      ).replace(",", "");
    };
  };

  const handleCsvExportClick = (selectedGranularity: ChartDataGranularity) => {
    if (granularity === selectedGranularity) {
      // This is what's being displayed on the page, so no point in hitting the server
      exportToCsv(chartDataState, timezone);
    } else {
      exportRequest(ExportType.Csv, selectedGranularity);
    }

    tracking.fireEvent(ExplorerPageEvents.EXPORT_DATA, {});
  };

  /**
   * Perform a request to the export service and downloads it to the user's browser
   * @param exportType The type of export being performed, ie. CSV or PDF
   * @param granularityOverride If included, this will override the granularity currently used on the chart
   */
  const exportRequest = (
    exportType: ExportType,
    granularityOverride?: ChartDataGranularity,
  ) => {
    // eslint-disable-next-line no-restricted-globals
    if (location) {
      const url = explorerBuildPDFURL(
        // eslint-disable-next-line no-restricted-globals
        location.search,
        secondaryTimeRange,
        compareToPast,
        exportType,
        granularityOverride,
      );
      if (url !== "") {
        // make a loading thing for the user....
        setLoadingExport(true);
        fetch(url, {
          mode: "cors",
          headers: {
            Authorization: `Bearer ${Auth.getAccessToken()}`,
          },
        })
          .then(response => {
            if (!response.ok) {
              throw new Error(`Unable to generate export ${exportType}`);
            }
            return response.blob();
          })
          .then(blob => {
            const extension = exportType === ExportType.Pdf ? "pdf" : "csv";
            downloadBlobFile(blob, `explorer_${Date.now()}.${extension}`);
            // remove a loading thing for the user....
            setLoadingExport(false);
          })
          .catch(err => {
            console.error(err);
            setLoadingExport(false);
            ComposableErrorToast(
              <FormattedMessage
                id="common.error.exportDownloadFailed"
                values={{ fileExtension: exportType }}
              />,
            );
          });

        tracking.fireEvent("ExplorerExportPDF", {});
      } else {
        ErrorToast("common.error.pdfDownloadFailed");
      }
    }
  };

  /* =============================================================== *\
     ZOOM / PAN / WHATEVER FUNCTIONS
  \* =============================================================== */
  // handles moving the mouse around
  // NOTE: the event is "any" because ReCharts  types these functions as:
  // export type RechartsFunction = (...args: any[]) => void;
  // so we can create our own types and cast them, but I am not sure of
  // the value of that. This is true for all the mouse functions
  const handleChartMouseMove = throttle(
    (e: any | undefined) => {
      if (!e) {
        return;
      }
      if (userMode === UserMode.ZOOM_IN && dragArea.left) {
        setDragArea({
          left: dragArea.left,
          right: e.activeLabel,
        });
      }
      if (
        userMode === UserMode.PAN &&
        isPanning.current &&
        panStartPosition.current !== null &&
        e.activeTooltipIndex
      ) {
        const distance = e.activeTooltipIndex - panStartPosition.current;
        if (distance !== 0) {
          // NOTE: if you want to do "reverse" scrolling, you need to adjust start position
          // because we moved away by `distance` AND our start point is also `distance` further away
          // from where it was after we adjust, the start point effectively moved twice as far away.
          // so change `distance * -1` to: panStartPosition.current += distance * 2;
          // DO THIS
          moveDomainBy(distance * -1);
        }
      }

      if (
        !hasRole(UserRole.READ_ONLY) &&
        userMode === UserMode.DEFAULT &&
        exceptionDragArea.left &&
        isMouseDown
      ) {
        const date1 = DateTime.fromMillis(exceptionDragArea.left);
        const date2 = DateTime.fromMillis(e.activeLabel);
        setExceptionDragArea({
          left: exceptionDragArea.left,
          right: e.activeLabel,
        });

        // We need to limit it to 7 days because that's how operating schedules work
        if (Math.abs(date1.diff(date2, ["hours"]).hours) >= 168) {
          setIsMouseDown(false);
        }
      }
    },
    750,
    { trailing: true },
  );

  const handleChartMousedown = (e: any | undefined) => {
    if (!e) {
      return;
    }
    if (userMode === UserMode.ZOOM_IN) {
      setDragArea({
        left: e.activeLabel,
        right: dragArea.right,
      });
    }
    if (userMode === UserMode.PAN && e.activeTooltipIndex) {
      isPanning.current = true;
      panStartPosition.current = e.activeTooltipIndex;
    }
    if (!hasRole(UserRole.READ_ONLY) && userMode === UserMode.DEFAULT) {
      setIsMouseDown(true);
      setExceptionDragArea({
        left: e.activeLabel,
        right: false,
      });
    }
  };

  const handleChartMouseup = (e: any) => {
    if (!e) {
      return;
    }
    if (userMode === UserMode.ZOOM_IN) {
      if (dragArea.left !== false && dragArea.right !== false) {
        const f = Math.min(dragArea.left, dragArea.right);
        const t = Math.max(dragArea.left, dragArea.right);
        setDragArea({ left: false, right: false });
        tracking.fireEvent(ExplorerPageEvents.ZOOM_IN, { from: f, to: t });
        zoomFunctions.in({
          selectedDates: {
            from: new Date(f),
            to: new Date(t),
          },
        });
      }
    }
    if (userMode === UserMode.PAN) {
      // CHANGE THIS TO MAYBE JUST CALL RANGE ACCEPT??
      // tell our parent that we panned so it can figure out if we need to fetch more data or whatever
      // panFunctions.pannedTo(currentDomains);
      // make a thing that is a time range accept or whatever
      // call the thing that does a thing
      const updatedRange: ITimeRangeChange = {
        selectedDates: {
          from: new Date(currentDomains.primary[0]),
          to: new Date(currentDomains.primary[1]),
        },
      };
      handleRangeAccept(updatedRange, timezone);

      isPanning.current = false;
      panStartPosition.current = null;
    }
    if (!hasRole(UserRole.READ_ONLY) && userMode === UserMode.DEFAULT) {
      setIsMouseDown(false);
      if (
        exceptionDragArea.left !== false &&
        exceptionDragArea.right !== false
      ) {
        tracking.fireEvent(ExplorerPageEvents.ADD_SCHEDULE_EXCEPTION, {
          userId,
          method: "Drag",
        });
        const ordered = [
          exceptionDragArea.left,
          exceptionDragArea.right,
        ].sort();
        handleDragEnd(ordered[0] ?? false, ordered[1] ?? false);
      }
    }
  };
  // END MOUSE EVENT FUNCTIONS
  useEffect(() => {
    setCurrentDomains({
      ...currentDomains,
      secondary: [
        new Date(secondaryTimeRange.startTime).getTime(),
        new Date(secondaryTimeRange.endTime).getTime(),
      ],
    });
  }, [secondaryTimeRange]);

  const dragReferenceBox = () => {
    if (dragArea.left !== false && dragArea.right !== false) {
      return (
        <ReferenceArea
          fill={AppColors.semantic.lightBlue.seafoam}
          opacity={0.15}
          x1={dragArea.left}
          x2={dragArea.right}
          yAxisId={yAxisId}
          xAxisId={xAxisId}
        />
      );
    }
  };

  const exceptionDragReferenceBox = () => {
    if (exceptionDragArea.left !== false && exceptionDragArea.right !== false) {
      return (
        <ReferenceArea
          fill={AppColors.semantic.lightPurple["light-purple-1"]}
          x1={exceptionDragArea.left}
          x2={exceptionDragArea.right}
          yAxisId={yAxisId}
          xAxisId={xAxisId}
        />
      );
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    overlayFormDispatch({
      type: "UPDATE_SELECTED_BUILDINGS",
      payload: {
        buildings: chartDataState.queueItems
          .filter(item => item.assetType === AssetType.BUILDING)
          .map(item => ({
            name: item.assetName!,
            id: item.assetId,
            // Timezone is required for buildings, but not for ALL assets
            timezone: item.timezone!,
            latitude: item.latitude!,
            longitude: item.longitude!,
          })),
      },
    });
  }, [chartDataState.queueItems]);

  const zoomOut = () => {
    setUserMode(UserMode.DEFAULT);
    zoomFunctions.out();
    tracking.fireEvent(ExplorerPageEvents.ZOOM_OUT, {});
  };

  /**
   * Change the domains to a new thing! Note that (as of now) only updates the
   * internal state of this component.
   *
   * @param distance - is the distance in number of data points, not values!
   */
  const moveDomainBy = (distance: number) => {
    const rawAdjustDistance = distance * panIncrement;
    const _pr = currentDomains.primary[0] + rawAdjustDistance;
    const _pl = currentDomains.primary[1] + rawAdjustDistance;
    const newPrimaryRight = clamp(
      _pr,
      scrollBounds.leftScrollBound,
      scrollBounds.rightScrollBound - domainSize,
    );
    const newPrimaryLeft = clamp(
      _pl,
      scrollBounds.leftScrollBound + domainSize,
      scrollBounds.rightScrollBound,
    );
    /* =============================================================== *\
       NOTE: we have to do this business so compare to past stays
       in sync. Otherwise it will keep moving even when the present
       has stopped due to clamping.
    \* =============================================================== */
    let useAdjustDistance: number;
    if (_pl !== newPrimaryLeft) {
      useAdjustDistance = newPrimaryLeft - currentDomains.primary[1];
    } else {
      useAdjustDistance = newPrimaryRight - currentDomains.primary[0];
    }
    const newDomains: ChartDomains = {
      primary: [newPrimaryRight, newPrimaryLeft],
      secondary: [
        currentDomains.secondary[0] + useAdjustDistance,
        currentDomains.secondary[1] + useAdjustDistance,
      ],
    };
    // set the chart domains so it will update the view
    setCurrentDomains(newDomains);
  };

  const compareOff = (event: React.MouseEvent) => {
    event.persist();
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    handleCompareCancel();
  };
  // calculated things we need...
  const {
    startTime: pickerStartDate,
    endTime: pickerEndDate,
  } = convertRangeToPickerDates(timeRange, timezone);
  const {
    startTime: compareStartDate,
    endTime: compareEndDate,
  } = convertRangeToPickerDates(secondaryTimeRange, timezone);

  /* SCHEDULE EXCEPTIONS STUFF */
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [overlayFormState, overlayFormDispatch] = useReducer(
    overlayFormReducer,
    initialState,
  );

  // At this point, we have lat/long in formData.ui.buildingsSelected[0]
  const [exceptionDragArea, setExceptionDragArea] = useState<SelectedRegion>({
    left: false,
    right: false,
  });

  const handleEditThreshold = (assetId: string) => {
    overlayFormDispatch({
      type: "OPEN_OVERLAY",
    });
    overlayFormDispatch({
      type: "GO_TO_THRESHOLD",
      payload: { initialBuildingId: assetId },
    });
  };

  const handleOperatingSchedule = () => {
    if (chartDataState.overlays?.operatingSchedule?.enabled) {
      tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
        overlay: "OPERATING_SCHEDULE",
      });
      setInactiveLegendItems({
        ...inactiveLegendItems,
        operatingHours: false,
      });
      overlayProps.setOverlayState(s => ({
        ...s,
        OPERATING_SCHEDULE: !s.OPERATING_SCHEDULE,
      }));
    }
  };

  /*
  called when a user finishes dragging a rectangle on the chart area.
  */
  const handleDragEnd = (startX: number | false, endX: number | false) => {
    if (startX && endX) {
      if (Math.abs(startX - endX) < 10) {
        console.info("Small drag, ignoring");
        return;
      }
      const eventStartStuff = new Date(startX);
      let endStuff = new Date(endX);
      let endTime = endX;

      overlayFormDispatch({ type: "OPEN_OVERLAY" });
      overlayFormDispatch({
        type: "START_CREATE",
        payload: {
          startDate: eventStartStuff,
          startTime: startX,
          endDate: endStuff,
          endTime: endTime,
          buildings: chartDataState.queueItems
            .filter(item => item.assetType === AssetType.BUILDING)
            .map(item => ({
              name: item.assetName!,
              id: item.assetId,
              timezone: item.timezone!,
              latitude: item.latitude!,
              longitude: item.longitude!,
            })),
        },
      });
    }
  };

  useEffect(() => {
    if (!overlayFormState.ui.overlayOpen) {
      setExceptionDragArea({
        left: false,
        right: false,
      });
    }
  }, [overlayFormState.ui.overlayOpen]);

  useEffect(() => {
    if (chartDataState.overlays?.operatingSchedule?.enabled === false) {
      overlayProps.setOverlayState({
        ...overlayProps.overlayState,
        OPERATING_SCHEDULE: false,
      });
    }
  }, [chartDataState.overlays?.operatingSchedule?.enabled]);
  const { currentOrganizationId } = useContext(CurrentOrganizationContext);

  const { userData } = useUserContext();
  const userId = userData.id;
  const Authz = useAuthorization();

  /**
   * Handles threshold rendering because it may be outside of the y axis domain
   */
  const renderThreshold = (threshold: BuildingThreshold) => {
    // We need to identify the symbol and yAxis so we can get that axis's maxTick
    const symbol = getSymbolForPointType(
      threshold.type,
      userData.preferences.locale,
    );

    const yAxis = symbol ? chartDataState.yAxes.get(symbol) : undefined;

    const maxTick = yAxis?.ticks
      ? yAxis.ticks[yAxis.ticks.length - 1]
      : threshold.value;

    const isOutsideYDomain = threshold.value > (maxTick as number);

    return (
      <ReferenceLine
        key={`threshold_${threshold.assetId}_${threshold.type}`}
        y={isOutsideYDomain ? maxTick : threshold.value}
        strokeDasharray="4 2 4 8"
        strokeWidth={2.5}
        xAxisId={xAxisId}
        yAxisId={yAxisId}
        opacity={isOutsideYDomain ? 0.5 : 1}
        stroke={
          chartDataState.charts.get(
            `${primaryTSK}.${threshold.type}.BUILDING__${threshold.assetId}`,
          )?.chartProps.stroke
        }
      />
    );
  };

  const mapAnnotation = (
    annotation: AnnotationEvent,
  ): {
    overlayId: string;
    buildingId: string;
    startDate: Date;
    startTime: number;
    endDate: Date;
    endTime: number;
    reasons: BuildingAnnotationReason[];
    annotationType: BuildingAnnotationType;
    title?: string;
    notes?: string;
  } => {
    const { id, buildingId, reasons, type, title, notes } = annotation;
    const startDateTime = DateTime.fromISO(annotation.startTime).setZone(
      timezone,
    );
    const endDateTime = DateTime.fromISO(annotation.endTime).setZone(timezone);

    return {
      overlayId: id,
      buildingId,
      startDate: startDateTime.toJSDate(),
      startTime: startDateTime.hour,
      endDate: endDateTime.toJSDate(),
      endTime: endDateTime.hour,
      reasons,
      annotationType: type,
      title,
      notes,
    };
  };

  const handleScheduleExceptionMarkerClick = (
    scheduleExceptionEvent: ScheduleExceptionEvent,
  ) => {
    const {
      overlayId,
      buildingId,
      overlayType,
      annotationCategories,
      title,
      note,
      startDate,
      endDate,
      startTime,
      endTime,
      isOccupied,
    } = mapScheduleExceptionEventToOverlayFormData(
      scheduleExceptionEvent,
      timezone,
    );

    const payload = {
      overlayId,
      buildingId,
      overlayType: OverlayType.ScheduleException,
      annotationCategories,
      title,
      note,
      startDate,
      startTime,
      endDate,
      endTime,
      isOccupied,
      timezone,
    };

    overlayFormDispatch({
      type: "BEGIN_EDIT_SCHEDULE_EXCEPTION",
      payload,
    });
  };

  const handleAnnotationMarkerClick = (annotationEvent: AnnotationEvent) => {
    const {
      overlayId,
      title,
      notes,
      reasons,
      annotationType,
      startDate,
      startTime,
      endDate,
      endTime,
    } = mapAnnotation(annotationEvent);

    overlayFormDispatch({
      type: "BEGIN_EDIT_ANNOTATION",
      payload: {
        overlayId,
        ...(title && { title }),
        ...(notes && { note: notes }),
        annotationCategories: reasons,
        annotationType,
        startDate,
        startTime,
        endDate,
        endTime,
        timezone,
      },
    });
  };

  const [inactiveLegendItems, setInactiveLegendItems] = useState({
    operatingHours: false,
  });

  return (
    <>
      <Card>
        <CardHeaderBar>
          <CardHeading>
            <ChartControls>
              {" "}
              <TimePickerButton
                togglePicker={togglePicker}
                dataTestId={"baseline-date-picker-button"}
                startTime={pickerStartDate}
                endTime={pickerEndDate}
                timezone={timezone}
              />
              {showPicker && (
                <TimePickerHolder left={true} ref={timeRangeRef}>
                  <TimeRangePicker
                    startDate={pickerStartDate}
                    endDate={pickerEndDate}
                    timezone={timezone}
                    onChange={userAcceptTimeChange}
                    onCancel={hidePicker}
                    limit={365 * 3}
                    allowToday={true}
                  />
                </TimePickerHolder>
              )}
              <BasicSelect
                values={getGranularityOptions(
                  chartDataRange,
                  translate.formatMessage,
                )}
                value={granularity}
                onChange={handleGranularityChange}
              />
              {!compareToPast ? (
                <MxSecondaryReactButton
                  color="alternate"
                  onClick={toggleComparePicker}
                  intlTextId="charts.explorer.compareToPast.checkboxLabel"
                />
              ) : (
                <ChartControlOutlineContainer
                  disabled={!compareToPast}
                  onClick={toggleComparePicker}
                >
                  <p>
                    <FormattedMessage id="charts.explorer.compareToPast.comparingTo" />
                    {formattedDate(compareStartDate, timezone)}-
                    {formattedDate(compareEndDate, timezone)}
                  </p>
                  <MxReactIcon
                    Icon={X}
                    color={AppColors.neutral.navy}
                    size="s"
                    style={{ marginLeft: "8px" }}
                    onClick={compareOff}
                  />
                </ChartControlOutlineContainer>
              )}
              {showComparePicker && (
                <TimePickerHolder left={true} ref={compareRangeRef}>
                  <CompareToPastRangePicker
                    primaryRangeEnd={pickerEndDate}
                    primaryRangeStart={pickerStartDate}
                    startDate={compareToPast ? compareStartDate : undefined}
                    endDate={compareToPast ? compareEndDate : undefined}
                    timezone={timezone}
                    onChange={userAcceptCompareChange}
                    onCancel={hideComparePicker}
                    selectedComparePeriod={compareType}
                  />
                </TimePickerHolder>
              )}
              <MxReactToggleBar>
                <MxReactToggle
                  onClick={() =>
                    setUserMode(
                      userMode === UserMode.ZOOM_IN
                        ? UserMode.DEFAULT
                        : UserMode.ZOOM_IN,
                    )
                  }
                  isEnabled={userMode === UserMode.ZOOM_IN}
                >
                  <MxReactIcon Icon={MagnifyPlus} size="s" />
                </MxReactToggle>
                <MxReactToggle isEnabled={false} onClick={() => zoomOut()}>
                  <MxReactIcon Icon={MagnifyMinus} size="s" />
                </MxReactToggle>
                <MxReactToggle
                  onClick={() => {
                    setUserMode(
                      userMode === UserMode.PAN
                        ? UserMode.DEFAULT
                        : UserMode.PAN,
                    );
                    tracking.fireEvent("ExplorerPan", {});
                  }}
                  isEnabled={userMode === UserMode.PAN}
                >
                  <MxReactIcon Icon={Hand} size="s" />
                </MxReactToggle>
              </MxReactToggleBar>
            </ChartControls>

            <HorizontalLayout childSpacing={4}>
              {overlayFormState.ui.overlayOpen ? (
                <CreateOverlayContainer
                  state={overlayFormState}
                  dispatch={overlayFormDispatch}
                  chartDataDispatch={chartDataDispatch}
                  timeRange={timeRange}
                  overlayProps={overlayProps}
                  latitude={latitude}
                  longitude={longitude}
                />
              ) : (
                <OverlayMenu
                  overlayProps={overlayProps}
                  overlayFormState={overlayFormState}
                  chartDataState={chartDataState}
                  overlayFormDispatch={overlayFormDispatch}
                  operatingScheduleOnClick={handleOperatingSchedule}
                />
              )}

              <DotMenu isRelative>
                <DotMenuItem onClick={() => setShowExportModal(true)}>
                  <DotMenuIconItem
                    icon={Download}
                    intlTextId="common.menuItem.labels.exportCSV"
                  />
                </DotMenuItem>
                <DotMenuItem onClick={() => exportRequest(ExportType.Pdf)}>
                  <DotMenuIconItem
                    icon={Download}
                    intlTextId="common.menuItem.labels.exportPDF"
                  />
                </DotMenuItem>
              </DotMenu>
            </HorizontalLayout>
          </CardHeading>
        </CardHeaderBar>
        {chartDataState.charts.size === 0 &&
          chartDataState.queueItems.length > 0 && (
            <GenericDataError
              type={GenericDataErrorType.Info}
              messageId={"charts.noDataGeneric"}
            />
          )}
        {showExportModal && (
          <ExplorerExportModal
            startDate={formattedDate(pickerStartDate, timezone)}
            endDate={formattedDate(pickerEndDate, timezone)}
            defaultGranularity={granularity}
            exportClicked={handleCsvExportClick}
            inProgress={loadingExport}
            handleClose={() => setShowExportModal(false)}
            charts={chartDataState.charts}
          />
        )}
        {chartDataState.charts.size > 0 && (
          <div style={{ userSelect: "none" }}>
            <ResponsiveContainer debounce={300} width="100%" height={600}>
              <LineChart
                onMouseDown={handleChartMousedown}
                onMouseMove={handleChartMouseMove}
                onMouseUp={handleChartMouseup}
                data={chartDataState.data}
                margin={{ top: 30, bottom: 50, right: 10, left: 10 }}
              >
                {/* this is the grey bar under the chart that "holds" the overlay markers*/}
                <rect
                  width="100%"
                  height="25"
                  x="0"
                  y={compareToPast ? 490 : 519}
                  fill={AppColors.neutral["light-gray-9"]}
                />
                <CartesianGrid
                  stroke={AppColors.neutral["light-navy-9"]}
                  vertical={false}
                />
                <Tooltip
                  isAnimationActive={false}
                  separator=""
                  labelFormatter={props =>
                    tooltipHeaderFormat(props as string | number)(props)
                  }
                  formatter={tooltipLabelFormatter}
                  content={
                    <MultiAxisExplorerTooltip
                      primaryTSK={primaryTSK}
                      tooltipLabelFormatFunc={tooltipHeaderFormat}
                      thresholds={
                        overlayProps.overlayState.THRESHOLDS
                          ? chartDataState.thresholds
                          : []
                      }
                    />
                  }
                />
                {yAxes(chartDataState.yAxes)}
                {/* xAxes(chartDataState.xAxes) */}
                {xAxes(chartDataState.xAxes, currentDomains, primaryTSK)}
                {dayBoundaries.map((date, index) => (
                  <ReferenceLine
                    key={"day" + index}
                    ifOverflow={"hidden"}
                    x={date.getTime()}
                    stroke={AppColors.neutral["light-navy-9"]}
                    yAxisId={yAxisId}
                    xAxisId={xAxisId}
                  />
                ))}
                {overlayProps.overlayState.THRESHOLDS &&
                  chartDataState.thresholds.map(renderThreshold)}

                {/* TODO1522: add annotation rendering to this */}
                {eventReferenceLines(
                  chartDataState.events,
                  yAxisId,
                  xAxisId,
                  overlayProps.overlayState,
                  false,
                  handleAnnotationMarkerClick,
                  handleScheduleExceptionMarkerClick,
                )}
                {eventReferenceAreas(
                  chartDataState.overlaySpans,
                  yAxisId,
                  xAxisId,
                  overlayProps.overlayState,
                )}
                {charts(chartDataState.charts)}
                {dragReferenceBox()}
                {exceptionDragReferenceBox()}
              </LineChart>
            </ResponsiveContainer>
            <WrappingLegendContainer>
              {legends(chartDataState.charts, handleLegendItemClick, {
                operatingHours: {
                  visible: overlayProps.overlayState.OPERATING_SCHEDULE,
                  inactive: inactiveLegendItems.operatingHours,
                  onClick: inactive => {
                    setInactiveLegendItems({
                      ...inactiveLegendItems,
                      operatingHours: inactive,
                    });
                    chartDataDispatch({
                      type: "TOGGLE_OPERATING_HOURS_CHART_DISPLAY",
                      payload: { visible: !inactive },
                    });
                  },
                },
              })}
            </WrappingLegendContainer>
          </div>
        )}

        <ReactTooltip
          id="measuresTooltip"
          globalEventOff="scroll mousewheel"
          delayHide={200}
          type="light"
          clickable={true}
          border={true}
          effect="solid"
          getContent={content => {
            if (!content) {
              return;
            }

            const measureEvents: MeasureEvent[] = JSON.parse(content);
            return (
              <VerticalLayout
                key={`tooltip-content-${measureEvents[0].id}`}
                childSpacing={2}
                style={{
                  overflowY: "auto",
                  overflowX: "clip",
                  height: measureEvents.length > 1 ? "360px" : "180px",
                  maxWidth: "360px",
                }}
              >
                {measureEvents.map(measureEvent =>
                  MeasureEventTooltip(
                    measureEvent,
                    currentOrganizationId ?? "",
                    userId,
                  ),
                )}
              </VerticalLayout>
            );
          }}
        />
        <ScheduleExceptionsTooltip
          organizationId={currentOrganizationId}
          userId={userId}
          timezone={timezone}
        />
        <AnnotationsTooltip timezone={timezone} />
        <MultiTooltip
          timezone={timezone}
          currentOrganizationId={currentOrganizationId}
          userId={userId}
        />
      </Card>
      <Card>
        <Box padding={[8, 0, 0, 0]}>
          {chartDataState.charts.size > 0 && (
            <SimpleTabs
              tabs={[
                {
                  id: "trends",
                  tabTitleItem: (
                    <FormattedMessage
                      id={"charts.explorer.tabs.trends.tabTitle"}
                    />
                  ),
                  content: (
                    <Box padding={[8, 0, 0, 0]}>
                      <SummaryMetrics data={chartDataState} />
                    </Box>
                  ),
                },
                {
                  id: "overlays",
                  tabTitleItem: (
                    <FormattedMessage
                      id={"charts.explorer.tabs.overlays.tabTitle"}
                    />
                  ),
                  content: (
                    <Box padding={[8, 0, 0, 0]}>
                      {/* The Overlays tab uses a hacky implementation which enhances the
                        list of thresholds with a color prop to match the trace color in the
                        chart so it can be displayed in the Overlays tab */}
                      <OverlaysTab
                        onEditThreshold={handleEditThreshold}
                        thresholds={
                          overlayProps.overlayState.THRESHOLDS
                            ? chartDataState.thresholds.map(threshold => ({
                                ...threshold,
                                color: chartDataState.charts.get(
                                  `${primaryTSK}.${threshold.type}.BUILDING__${threshold.assetId}`,
                                )?.chartProps.stroke,
                              }))
                            : []
                        }
                        userLocale={userData.preferences.locale || Locale.EN_US}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          )}
        </Box>
      </Card>
    </>
  );
};

// NOTE: If performance sucks on the "non-compare" tooltip,
// we can check # of x-axis and use the original (down below) if there
// is only one.
type ExplorerToolipData = {
  [key: string]: {
    label: string;
    items: ReactNode[];
  };
};

/**
 *
 */
interface ExplorerTooltipProps extends TooltipProps<number, string> {
  primaryTSK: string;
  tooltipLabelFormatFunc: (
    _v: number | string,
  ) => (_timeStampKey: string) => string;
  thresholds: BuildingThreshold[];
}

const MultiAxisExplorerTooltip = (props: ExplorerTooltipProps) => {
  if (!props.active) {
    return null;
  }
  // there is a prop in the contents of each payload called payload as well...
  const _outerPayload: Payload<number, string>[] = props.payload?.slice() ?? [];

  const _renderData: ExplorerToolipData = {};
  const _renderKeys: string[] = []; // store keys so we don't have to Object.keys  because we need to be fast!
  /*
  NOTE: if this is not performant enough, we can try just mapping over the payloads twice.
        and keeping track of which timeseries we drew already. We will still have to extract
        the timestamp from an entry, but would skip creating objects and arrays and whatnot.

  */
  _outerPayload.forEach(_p => {
    const dk = (_p.dataKey as string) || false;
    if (dk) {
      const [_tsk, pointType, asset] = dk.split(".");
      // make the things we need to store the things
      if (!_renderData[_tsk]) {
        _renderKeys.push(_tsk);
        const _stamp = _p.payload[_tsk].timestamp;
        _renderData[_tsk] = {
          items: [],
          label: props.tooltipLabelFormatFunc(_stamp)(_tsk),
        };
      }

      // now actually make things
      _renderData[_tsk].items.push(
        <BookendLayout key={dk} style={{ marginTop: "4px" }}>
          <span>
            <MxReactIcon
              Icon={_tsk === props.primaryTSK ? CircleSolid : Circle}
              color={_p.color}
              style={{ marginRight: "8px", height: "12px", width: "12px" }}
              size="xs"
            />
            {`${truncateStringEnd(_p.name || "")} `}
          </span>

          <span style={{ fontWeight: "bold" }}>
            {props.formatter &&
              props.formatter(_p.value as number, _p.name as string, _p, 0, _outerPayload)}
            {_p.unit}
          </span>
        </BookendLayout>,
      );

      // Reference lines aren't passed into tooltips, so we have to manually pass
      // in the threshold values as a prop
      if (props.thresholds.length > 0) {
        const threshold = props.thresholds.find(
          t =>
            t.assetId === asset.replace("BUILDING__", "") &&
            t.type === pointType,
        );

        if (threshold) {
          _renderData[_tsk].items.push(
            <BookendLayout key={`${dk}_threshold`} style={{ marginTop: "4px" }}>
              <span>
                <MxReactIcon
                  Icon={Circle}
                  color={_p.color}
                  style={{ marginRight: "8px", height: "12px", width: "12px" }}
                  size="xs"
                />
                <FormattedMessage id="charts.explorer.tooltip.threshold" />
              </span>

              <span style={{ fontWeight: "bold" }}>
                {props.formatter &&
                  props.formatter(threshold.value, _p.name as string, _p, 0, _outerPayload)}
                {_p.unit}
              </span>
            </BookendLayout>,
          );
        }
      }
    }
  });

  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: AppColors.neutral.white,
        border: `1px solid ${AppColors.neutral["light-navy-9"]}`,
        fontSize: "14px",
        color: AppColors.neutral.navy,
      }}
    >
      {_renderKeys.map((_key: string, idx: number) => (
        <div key={_key}>
          <p
            style={{
              fontSize: "16px",
              marginBottom: "8px",
              marginTop: idx !== 0 ? "8px" : "0",
            }}
          >
            {_renderData[_key].label}
          </p>
          {_renderData[_key].items}
        </div>
      ))}
    </div>
  );
};
