import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { useUserContext } from "src/auth/UserContext";
import { BaselineChart } from "src/components/app/BaselineChart/BaselineChart";
import {
  Card,
  CardHeaderBar,
  CardHeading,
} from "src/components/common/CardLayout";
import { HorizontalLayout } from "src/components/common/Layout";
import { MxReactIcon, Info } from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "src/components/common/Styling";
import { ChartControls } from "src/components/common/ChartParts";
import GenericDataError, {
  GenericDataErrorType,
} from "src/components/common/GenericDataError/GenericDataError";
import InfoAccordion from "src/components/common/InfoAccordion";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import { Body, H3 } from "src/components/common/Typography";
import {
  calculatePercentDiff,
  colorFromPercent,
  createTimeRange,
  formattedPercentDifference,
} from "src/helpers/charting";
import { GetOrgAggregatedBaselineTimeseriesDocument } from "src/queries/typed";
import {
  ChartDataGranularity,
  ChartDataRange,
  ICombinedIntervalData,
  ICombinedMeasurement,
  PointDataType,
  PointType,
  Unit,
} from "src/types/charting";
import {
  Unit as GeneratedUnit,
  UsageVsBaselineIntervalData,
  UsageVsBaselineReportPeriod,
} from "src/types/graphql";
import { useGraphqlMappings } from "src/hooks/useGraphqlMappings";
import { useUnitPrefs } from "src/hooks/useUnitPrefs";
import { tracking } from "src/tracking";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";
import {
  ComposedMetric,
  MetricLayout,
  Metric,
  MetricValue,
  MetricDescription,
} from "src/components/common/Metrics";
import { BasicSelect } from "src/components/common/Dropdown/BasicSelect";

export enum BaselineVarianceEvents {
  CHANGE_POINT_TYPE = "PerformanceBaselineChangePointType",
  CHANGE_DATE_RANGE = "PerformanceBaselineChangeDateRange",
  CHANGE_BASELINE_MODEL = "PerformanceBaselineChangeModel",
  BASELINE_INFO = "PerformanceBaselineInfo",
  MANAGE_BASELINES = "PerformanceBaselineManageBaselines",
}

export type DateRange = {
  from: Date;
  to: Date;
};

const overId = "charts.baseline.metric.difference.overLabel";
const underId = "charts.baseline.metric.difference.underLabel";
const overInfoId = "charts.baseline.multiBuildingMetric.infoText.over";
const underInfoId = "charts.baseline.multiBuildingMetric.infoText.under";

interface BaselineVarianceCardProps {
  organizationId: string;
  commodities: PointType[];
}
const BaselineVarianceCard = (props: BaselineVarianceCardProps) => {
  const { organizationId, commodities } = props;
  const { userData } = useUserContext();
  const { unitPrefForPoint } = useUnitPrefs();
  const { generatedUnitForUnit } = useGraphqlMappings();

  const timezone = userData.timezone;
  // portfolio only supports Day (for now)
  const granularity = ChartDataGranularity.Day;
  const [period, setPeriod] = useState<UsageVsBaselineReportPeriod>(
    UsageVsBaselineReportPeriod.LAST_30_DAYS,
  );

  const chartDataRangeFromPeriod = (): ChartDataRange => {
    switch (period) {
      case UsageVsBaselineReportPeriod.LAST_30_DAYS:
      default:
        return ChartDataRange.ThirtyDays;
    }
  };

  // the "this date to that date" type of range.
  const [chartTimeRange] = useState(
    createTimeRange(chartDataRangeFromPeriod(), timezone, granularity),
  );
  const [chartData, setChartData] = useState<ICombinedIntervalData | false>(
    false,
  );
  // summed values for actual and usage, and the difference for metrics
  const [totalBaselineUsage, setTotalBaselineUsage] = useState<
    number | undefined
  >(undefined);
  const [totalUsage, setTotalUsage] = useState<number | undefined>(undefined);
  const translate = useIntl();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [totalUsage, totalBaselineUsage]);

  const periodData: { [p: string]: string } = {
    [UsageVsBaselineReportPeriod.LAST_30_DAYS.toString()]: translate.formatMessage(
      { id: "dataRange.thirtyDays" },
    ),
  };

  const baselineChartTypes: Set<PointType> = new Set([
    PointType.ELECTRICITY_USAGE,
    PointType.NATURAL_GAS_USAGE,
    PointType.CHILLED_WATER_USAGE,
    PointType.HOT_WATER_USAGE,
    PointType.STEAM_MASS,
  ]);

  const allowedChartDataTypes: PointType[] = commodities.filter(commodity =>
    baselineChartTypes.has(commodity),
  );

  const chartTypeData: PointDataType = allowedChartDataTypes.reduce<
    PointDataType
  >((obj, current) => {
    obj[current] = translate.formatMessage({ id: `commodity.name.${current}` });
    return obj;
  }, {});

  const [chartType, setChartType] = useState(allowedChartDataTypes[0]);

  const handlePointTypeChange = (val: string) => {
    tracking.fireEvent(BaselineVarianceEvents.CHANGE_POINT_TYPE, {
      pointType: val as PointType,
    });
    setChartType(val as PointType);
  };

  const handleDateRangeChange = (val: string) => {
    tracking.fireEvent(BaselineVarianceEvents.CHANGE_DATE_RANGE, {
      dateRange: val as UsageVsBaselineReportPeriod,
    });
    setPeriod(val as UsageVsBaselineReportPeriod);
  };

  let unit = unitPrefForPoint(chartType as PointType) as Unit | undefined;
  // I don't think this can happen?
  // Turns out it CAN happen, and does! Doing this hack to set a "bad" unit so
  // they query will error and we may figure out why
  if (unit === undefined) {
    console.error(
      `Unrecognized unit while searching for pointType ${chartType}`,
    );
    tracking.fireErrorEvent(
      `BV Portfolio: Unrecognized unit while searching for pointType ${chartType}`,
    );
    unit = Unit.KILOWATT_HOURS;
  }
  /*
  if (unit === undefined) {
    throw new Error("Unrecognized unit");
  }
  */

  let convertedUnit: GeneratedUnit = generatedUnitForUnit(unit);

  // takes the values data from the query and makes this fancy thing the chart will understand
  function mapAndAnnotateQueryData(
    input: UsageVsBaselineIntervalData[],
  ): ICombinedIntervalData {
    // console.log("map and annotate called with input:", input);
    const values: ICombinedMeasurement[] = input.map(v => {
      const _d = new Date(v.timestamp);
      return {
        value: v.usage ?? null,
        baseline: v.baselineUsage ?? null,
        timestamp: _d.getTime(),
      };
    });
    const _chartData: ICombinedIntervalData = {
      range: chartTimeRange,
      granularity,
      values,
      units: {
        baseline: displayUnit,
        value: displayUnit,
      },
      labels: {
        baseline: "Weather Adjusted Baseline Usage",
        value: "Actual Usage",
      },
    };
    return _chartData;
  }

  const { loading, error, data, refetch } = useQuery(
    GetOrgAggregatedBaselineTimeseriesDocument,
    {
      variables: {
        organizationId,
        unit: convertedUnit,
        pointType: chartType,
        period,
        timezone,
      },
    },
  );

  useEffect(() => {
    setTotalBaselineUsage(undefined);
    setTotalUsage(undefined);
    setChartData(false);

    if (data?.getOrganizationById?.usageVsBaselineForPeriod?.values) {
      const { values } = data?.getOrganizationById?.usageVsBaselineForPeriod;

      const { summedUsage, summedBaseline } = values.reduce(
        (acc, current) => {
          acc.summedBaseline += current!.baselineUsage ?? 0;
          acc.summedUsage += current!.usage ?? 0;
          return acc;
        },
        { summedUsage: 0, summedBaseline: 0 },
      );
      setChartData(
        mapAndAnnotateQueryData(values as UsageVsBaselineIntervalData[]),
      );
      setTotalBaselineUsage(summedBaseline);
      setTotalUsage(summedUsage);
    }
  }, [data]);

  // THIS WILL RUN THE QUERY AGAIN WHEN SOMETHING CHANGES THAT WOULD
  // CAUSE THE DATA TO NOT BE GOOD ANY MORE
  // Another option would be to put the query it a DataProvider type thing
  useEffect(() => {
    refetch({
      organizationId,
      unit: convertedUnit,
      pointType: chartType,
      period,
      timezone,
    });
  }, [period, chartType]);
  const displayUnit = getSymbolForUnit(unit);

  // render the loading / error /chart / whatever in the main part of the card
  function cardContents() {
    if (loading) {
      return <CenteredLoadingIndicator />;
    }
    if (error) {
      return <GenericDataError type={GenericDataErrorType.Info} />;
    }
    /*
    // TODO: can this happen at the portfolio level? I don't think so
    if (baselineUnavailable) {
      return (
        <InfoAccordion
          titleId="charts.baseline.tooltip.noBaselineTitle"
          startOpen={true}
        >
          <Body>
            <FormattedMessage id="charts.baseline.tooltip.noBaselineMessage" />
            {": "}
            <HyperLink href="mailto: support@measurabl.com">
          <FormattedMessage id="support.url" />
            </HyperLink>
          </Body>
        </InfoAccordion>
      );
    }
    */
    if (chartData && chartData.values.length > 0) {
      return (
        <BaselineChart
          data={chartData}
          rangeSize={chartDataRangeFromPeriod()}
          timeRange={chartTimeRange}
          timezone={timezone}
          unit={displayUnit}
          height={600 - 72 - 82 - 32}
        />
      );
    } else {
      return (
        <GenericDataError
          messageId="charts.noData"
          type={GenericDataErrorType.Info}
        />
      );
    }
  }
  function metricsContents() {
    if (totalUsage && totalBaselineUsage) {
      const percentDifference = calculatePercentDiff(
        totalBaselineUsage,
        totalUsage,
      );
      return (
        <MetricLayout>
          <Metric
            value={totalBaselineUsage}
            unit={displayUnit}
            description="charts.baseline.metric.baseline.label"
          />
          <Metric
            value={totalUsage}
            unit={displayUnit}
            description="charts.baseline.metric.actual.label"
          />
          <ComposedMetric
            value={
              <MetricValue color={colorFromPercent(percentDifference)}>
                {formattedPercentDifference(percentDifference, false)}
              </MetricValue>
            }
            description={
              <HorizontalLayout childSpacing={2}>
                <MetricDescription>
                  <FormattedMessage
                    id={percentDifference > 0 ? overId : underId}
                  />{" "}
                </MetricDescription>
                <MxReactIcon
                  data-for="over-under-info"
                  data-tip={percentDifference}
                  Icon={Info}
                  color={AppColors.semantic.blue.sky}
                />
                <ReactTooltip
                  id="over-under-info"
                  type="light"
                  border={true}
                  place="top"
                  backgroundColor={AppColors.neutral.white}
                  getContent={c => (
                    <FormattedMessage
                      id={parseFloat(c) > 0 ? overInfoId : underInfoId}
                      values={{
                        percentDifference: formattedPercentDifference(
                          parseFloat(c),
                          false,
                        ),
                      }}
                    />
                  )}
                />
              </HorizontalLayout>
            }
          />
        </MetricLayout>
      );
    }
  }

  return (
    <Card>
      <CardHeaderBar>
        <CardHeading>
          <H3>
            <FormattedMessage id="charts.baseline.usage.title" />
          </H3>
          <ChartControls>
            <BasicSelect
              values={chartTypeData}
              value={chartType}
              onChange={handlePointTypeChange}
            />
            <BasicSelect
              values={periodData}
              value={period}
              onChange={handleDateRangeChange}
            />
          </ChartControls>
        </CardHeading>
        {metricsContents()}
      </CardHeaderBar>
      {cardContents()}
      <InfoAccordion
        titleId="charts.baseline.userInfo.title"
        startOpen={false}
        onClick={() => {
          tracking.fireEvent(BaselineVarianceEvents.BASELINE_INFO, {});
        }}
      >
        <Body>
          <FormattedMessage id="charts.baseline.userInfo.paragraph1" />
        </Body>
      </InfoAccordion>
    </Card>
  );
};
export default BaselineVarianceCard;
