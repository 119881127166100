import styled from "styled-components/macro";
import {
  MxReactIcon,
  X,
  Square,
  SquareCheck,
  CirclePlus,
} from "src/componentLibrary/react/mx-icon-react";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IconButton } from "src/components/common/Button";
import {
  HorizontalLayout,
  VerticalLayout,
  Box,
  BookendLayout,
} from "src/components/common/Layout";
import { useOutsideClickEvent } from "src/hooks/useOutsideClickEvent";
import { AppColors } from "src/components/common/Styling";
import { H3, InlineH4 } from "src/components/common/Typography";

export const OverlaysMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: white;
  min-width: 300px;
  border: 1px solid ${AppColors.neutral["light-navy-9"]};
  display: inline-block;
  hr {
    background-color: ${AppColors.neutral["light-navy-9"]};
    height: 1px;
    border: none;
  }
  h3 {
    font-weight: 400;
  }
`;

const ItemLabel = styled(InlineH4)<{ isActive: boolean; disabled?: boolean }>`
  cursor: ${props => (props.disabled ? "cursor" : "pointer")};
  display: flex;
  color: ${props =>
    props.isActive
      ? AppColors.neutral.navy
      : props.disabled
      ? AppColors.neutral["light-navy-9"]
      : AppColors.neutral["light-navy-3"]};
`;

interface IOverlaysMenuItem {
  color: string;
  intlTextId: string;
  onClick?: () => void;
  isActive?: boolean;
  disabled?: boolean;
}

export const OverlaysMenuItem: React.FC<IOverlaysMenuItem> = ({
  color,
  onClick,
  intlTextId,
  isActive = false,
  disabled,
}) => {
  let icon = Square;

  if (disabled) {
    icon = Square;
  } else if (isActive) {
    icon = SquareCheck;
  }

  return (
    <ItemLabel isActive={isActive} onClick={onClick} disabled={disabled}>
      <MxReactIcon
        Icon={icon}
        style={{ marginRight: "8px" }}
        size="s"
        color={disabled ? AppColors.neutral["light-navy-9"] : color}
      />
      <FormattedMessage id={intlTextId} />
    </ItemLabel>
  );
};

interface IOverlaysMenu {
  children: React.ReactNode;
  isActive: boolean;
}

export const OverlaysMenuWrapper: React.FC<IOverlaysMenu> = ({
  children,
  isActive,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => setShowMenu(true);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const menuItemsRef = useRef(null);

  const handleOutsideClick = () => {
    if (showMenu) {
      closeMenu();
    }
  };
  useOutsideClickEvent(handleOutsideClick, menuItemsRef);

  return (
    <>
      <IconButton>
        <HorizontalLayout childSpacing={2} onClick={openMenu}>
          {isActive ? (
            <MxReactIcon
              Icon={CirclePlus}
              color={AppColors.primary["msr-green"]}
            />
          ) : (
            <MxReactIcon Icon={CirclePlus} />
          )}
          <p>
            <FormattedMessage id={"charts.explorer.overlays"} />
          </p>
        </HorizontalLayout>
      </IconButton>
      {showMenu && (
        <OverlaysMenuContainer ref={menuItemsRef}>
          <Box padding={5}>
            <BookendLayout>
              <H3>
                <FormattedMessage id="charts.explorer.overlays.create.heading" />
              </H3>
              <IconButton>
                <MxReactIcon
                  Icon={X}
                  onClick={closeMenu}
                  style={{ height: "18px", width: "18px" }}
                />
              </IconButton>
            </BookendLayout>
          </Box>
          <hr />
          <Box padding={5}>
            <VerticalLayout childSpacing={4}>{children}</VerticalLayout>
          </Box>
        </OverlaysMenuContainer>
      )}
    </>
  );
};
