import React, { useCallback, useRef } from "react";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import {
  MxReactIcon,
  ErrorTriangle,
} from "src/componentLibrary/react/mx-icon-react";
import { FormattedMessage } from "react-intl";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import styled from "styled-components/macro";
import { H1, H3 } from "src/components/common/Typography";
import { ApolloError } from "@apollo/client";
import { AppColors } from "src/components/common/Styling";

interface SidebarContainerProps {
  error?: ApolloError;
  loading?: boolean;
  heading: React.ReactNode;
  content: React.ReactNode;
  disablePadding?: boolean;
  headingHeight?: string; // how tall is the header contnet? ex: "120px"
}

const minWidth = 300;
const reservedWidth = 940;
const defaultWidth = 350;

export const SidebarContainer: React.FC<SidebarContainerProps> = ({
  error,
  loading,
  heading,
  content,
  disablePadding,
  headingHeight,
}) => {
  const maxWidth = document.body.clientWidth - reservedWidth;
  const isResizable = maxWidth > defaultWidth;
  const [width, setWidth] = useLocalStorage<number>(
    "sidebar.width",
    defaultWidth,
  );
  const ref = useRef<HTMLDivElement>(null);
  const handler = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    function onMouseMove(e: MouseEvent) {
      const currentWidth = ref.current?.clientWidth;
      if (currentWidth) {
        ref.current!.style.width = `${Math.max(
          Math.min(currentWidth + e.movementX, maxWidth),
          minWidth,
        )}px`;
      }
    }
    function onMouseUp() {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
      const finalWidth = ref.current?.clientWidth;
      if (finalWidth) {
        setWidth(finalWidth);
      }
    }
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  }, []);
  if (error) {
    return (
      <ErrorWrapper>
        <ErrorMessage>
          <Header>
            <MxReactIcon
              Icon={ErrorTriangle}
              size="m"
              color={AppColors.neutral["light-navy-9"]}
            />
            <ErrorH1>
              <FormattedMessage id="assetSelector.errorTitle" />
            </ErrorH1>
          </Header>
          <ErrorH3>
            <FormattedMessage id="assetSelector.errorText" />
          </ErrorH3>
        </ErrorMessage>
      </ErrorWrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        {heading}
        <LoadingWrapper>
          <CenteredLoadingIndicator>
            <LoadingText>
              <FormattedMessage id="assetSelector.loading" />
            </LoadingText>
          </CenteredLoadingIndicator>
        </LoadingWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      style={{ width: isResizable ? Math.min(width, maxWidth) : defaultWidth }}
      ref={ref}
    >
      {heading}
      <ScrollableContainer top={headingHeight} disablePadding={disablePadding}>
        {content}
      </ScrollableContainer>
      {isResizable && <DragHandle onMouseDown={handler} />}
    </Wrapper>
  );
};

const DragHandle = styled.div`
  position: absolute;
  top: 0;
  right: -10px;
  height: 100%;
  width: 10px;

  ::before {
    position: absolute;
    content: "";
    top: 0;
    right: 7px;
    bottom: 0;
    left: 0;
    background: ${AppColors.primary["light-msr-green-1"]};
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  :hover {
    cursor: col-resize;
    :before {
      opacity: 1;
    }
  }
`;

const Wrapper = styled.div`
  padding: 20px 27px;
  background: ${AppColors.neutral["light-navy-1"]};
  color: white;
  max-width: 100vw;
  position: relative;
  /* height: 100%; */
  flex: 1;
  /* This lets us get fill width on small screens */
  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
`;

const ErrorWrapper = styled.div`
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 1px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
`;

const LoadingText = styled.span`
  display: block;
  font-weight: 300;
  margin-top: 5px;
  color: ${AppColors.neutral["light-navy-9"]};
`;

/* NOTE: the scrollbar CSS does not work in Firefox or
 IE 11. This is known and was deemed "acceptable" */
/**
 * NOTE: passing in TOP like this is a band-aid for a design
 * decision (that I think I made!) that is not that good any more
 *
 * So maybe make it so it's not fixed height / abs. position any more?
 */
const ScrollableContainer = styled.div<{
  top?: string;
  disablePadding?: boolean;
}>`
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: ${props => (props.top ? props.top : "60px")};
  max-height: calc(100vh - 144px);
  overflow-y: auto;
  padding-left: ${props => (props.disablePadding ? "0" : "24px")};
  padding-right: ${props => (props.disablePadding ? "0" : "24px")};
  width: 100%;
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: ${AppColors.neutral["light-navy-9"]};
  }
  /* if the parent outer container switches to flex-direction column for small screens, having this positioned absolute hides the buildings, so you have to un-do that. */
  @media (max-width: 600px) {
    position: relative;
    top: 0;
  }
`;

const ErrorMessage = styled.div`
  flex: 0 1;
`;

const ErrorH1 = styled(H1)`
  color: white;
  flex-grow: 1;
  margin-bottom: 0;
  margin-left: 15px;
`;
const ErrorH3 = styled(H3)`
  color: white;
`;
