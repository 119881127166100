import React, { useState, useEffect, PropsWithChildren } from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { Body } from "src/components/common/Typography";
import {
  MxReactIcon,
  SquareCheck,
  Square,
} from "src/componentLibrary/react/mx-icon-react";
import { RequireOnlyOne } from "src/components/app/ExplorerPage/types";
import { AppColors } from "../Styling";

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
`;

// Still accessible to screen readers.
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export type FontSizeOptions = "large" | "small";

interface ICheckboxProps {
  labelId?: string /** tranlation id for the label that will be next it */;
  message?: string; // TODO: what is this, why is it different from labelId, and why do things get their title set to it when it is optional?
  name: string;
  defaultChecked: boolean /** this is actually the checked prop */;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  fontSize?: FontSizeOptions;
  disabled?: boolean;
  color?: string; // color of the unchecked checkbox
  id?: string;
}

// TODO: get rid of `defaultChecked` and just make a `checked` prop
// the Operating Schedules page is the only place that seems to rely
// on the internal "checkedness" of the component, so it's tests fail
// but I think that means that test / parent component needs to be
// refactored as well.
const Checkbox = React.forwardRef<
  HTMLInputElement,
  RequireOnlyOne<ICheckboxProps, "labelId" | "message">
>((props, ref) => {
  const {
    id,
    name,
    labelId,
    message,
    defaultChecked,
    onChange,
    fontSize,
    disabled,
    color,
  } = props;
  const [checked, setChecked] = useState(defaultChecked);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <CheckboxContainer htmlFor={id}>
      <HiddenCheckbox
        id={id}
        name={name}
        checked={checked}
        onChange={handleChange}
        data-testid={name}
        disabled={disabled}
        ref={ref}
      />
      <MxReactIcon
        Icon={checked ? SquareCheck : Square}
        color={
          checked
            ? AppColors.primary["msr-green"]
            : color
            ? color
            : AppColors.semantic.green["light-msr-green-3"]
        }
        style={{ marginRight: "8px" }}
      />
      {!fontSize || fontSize === "large" ? (
        <Body>{labelId ? <FormattedMessage id={labelId} /> : message}</Body>
      ) : (
        <Body title={message}>
          {labelId ? <FormattedMessage id={labelId} /> : message}
        </Body>
      )}
    </CheckboxContainer>
  );
});

export default Checkbox;

type SimpleCheckboxProps = {
  checked: boolean;
  disabled: boolean;
  clickHandler: React.ChangeEventHandler<HTMLInputElement>;
};
export const SimpleCheckbox: React.FC<PropsWithChildren<
  SimpleCheckboxProps
>> = ({ checked, clickHandler, disabled, children }) => {
  return (
    <CheckboxContainer style={{ marginLeft: "8px" }}>
      <HiddenCheckbox
        checked={checked}
        onChange={clickHandler}
        disabled={disabled}
      />
      <MxReactIcon
        Icon={checked ? SquareCheck : Square}
        color={
          checked
            ? AppColors.primary["msr-green"]
            : AppColors.semantic.green["light-msr-green-3"]
        }
        style={{ marginRight: "8px" }}
      />
      <Body>{children}</Body>
    </CheckboxContainer>
  );
};
