import {
  BodyNoWrap,
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import {
  MxReactIcon,
  DoubleUp,
} from "src/componentLibrary/react/mx-icon-react";
import { AnnotationEvent } from "../types";
import { AppColors } from "src/components/common/Styling";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import {
  convertDateToDateTime,
  dateToLocaleString,
  FormatOptions,
} from "@hatchdata/intl-formatter";
import { isSameDay } from "src/components/common/TimeRangePicker/helpers";
import {
  BuildingAnnotationReason,
  BuildingAnnotationType,
} from "src/types/graphql";
import { DateTime } from "luxon";
import { OverlayType } from "../OverlayStuff/overlayReducer";

const Label = styled.span`
  font-weight: 500;
`;

const mapAnnotationTypeToOverlayType = (type: BuildingAnnotationType) => {
  switch (type) {
    case BuildingAnnotationType.PEAK_DEMAND:
      return OverlayType.PeakDemand;
    case BuildingAnnotationType.HIGH_USAGE:
      return OverlayType.HighUsage;
    case BuildingAnnotationType.CUSTOM:
      return OverlayType.Custom;
  }
};

export const AnnotationEventTooltip = (
  annotation: AnnotationEvent,
  timezone: string,
  translatedReasons: { [key: string]: string },
) => {
  const formatDateTime = (date: Date) => {
    return dateToLocaleString(
      date,
      undefined,
      timezone,
      FormatOptions.DATETIME_SHORT,
    );
  };

  // Convert ["CHANGES_TO_OPERATION", "CONSTRUCTION"] to
  // "Changes to Operation, Construction"
  const convertReasonsArrayToString = (reasons: BuildingAnnotationReason[]) => {
    return reasons.reduce((acc, reason, i) => {
      const reasonString = translatedReasons[reason];

      return i !== reasons.length - 1
        ? acc.concat(reasonString, ", ")
        : acc.concat(reasonString);
    }, "");
  };

  const formatTime = (date: Date) => {
    const dateTime = convertDateToDateTime(date, timezone);
    return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  };

  const formatStartAndEndTime = (startTime: string, endTime: string) => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    if (isSameDay(startDate, endDate)) {
      return `${formatDateTime(startDate)} - ${formatTime(endDate)}`.replaceAll(
        ",",
        "",
      );
    } else {
      return `${formatDateTime(startDate)} - ${formatDateTime(
        endDate,
      )}`.replaceAll(",", "");
    }
  };

  return (
    <div key={`annotation-tooltip-${annotation.id}`}>
      <VerticalLayout
        childSpacing={1}
        style={{ fontSize: "16px", fontWeight: 300 }}
      >
        <Box>
          <HorizontalLayout childSpacing={1}>
            <MxReactIcon
              Icon={DoubleUp}
              color={AppColors.semantic.yellow.yellow}
            />
            <Label>
              {annotation.title ? (
                annotation.title
              ) : (
                <FormattedMessage
                  id={`charts.explorer.overlays.${mapAnnotationTypeToOverlayType(
                    annotation.type,
                  )}`}
                />
              )}
            </Label>
          </HorizontalLayout>
        </Box>

        <Box>
          <FormattedMessage id={"utilityDataVariance.varianceNote.enteredBy"} />
          {": "}
          {annotation.createdBy.firstName} {annotation.createdBy.lastName}
        </Box>
        <BodyNoWrap>{annotation.buildingName}</BodyNoWrap>
        <Box>
          {formatStartAndEndTime(annotation.startTime, annotation.endTime)}
        </Box>
        <Box>
          <Label>
            <FormattedMessage
              id={"charts.explorer.overlays.annotationsTooltip.category"}
            />
            {": "}
            {convertReasonsArrayToString(annotation.reasons)}
          </Label>
        </Box>
        <Box>
          <Label>
            <FormattedMessage id={"utilityDataVariance.varianceNote.note"} />
            {": "}
          </Label>
          {annotation.notes}
        </Box>
      </VerticalLayout>
    </div>
  );
};
