/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
//
import { useApolloClient } from "@apollo/client";
import {
  GetMeasuresForBuildingsInExplorerDocument,
  LoadAnnotationsForBuildingInExplorerDocument,
  LoadScheduleExceptionsForBuildingInExplorerDocument,
  LoadThresholdsForBuildingInExplorerDocument,
  LoadOperatingScheduleForBuildingDocument,
  GetLatLongForBuildingDocument,
} from "src/queries/typed";
//
import { DateTime } from "luxon";
import debounce from "lodash.debounce";
import { ErrorBoundary } from "react-error-boundary";
import { ExplorerCard } from "./ExplorerCard";
import { useNavigate, useLocation, Location } from "react-router-dom";
import { CurrentOrganizationContext } from "src/components/app/AuthenticatedPage";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import {
  AssetState,
  AssetStateIndicator,
  AssetType,
  BuildingAnnotation,
  BuildingAnnotationsResponse,
  BuildingMeasuresResponse,
  BuildingScheduleExceptionsResponse,
  CommoditySelection,
  AnnotationEventType,
  DataQueueItem,
  MeasureEventType,
  OverlayEvents,
  OverlayEventState,
  OverlayEventType,
  ScheduleEventType,
  SelectedAssetsMap,
  ThresholdEventType,
} from "./types";
import { assetStateReducer, hasAssetSelections } from "./assetStateReducer";
import { InfoToast } from "src/components/common/Toast";
import { MakeSelection } from "src/components/common/MakeSelection";
import {
  buildingsWithChartData,
  chartDataReducer,
  initialEmptyChartData,
  userViewState,
} from "./chartDataReducer";
import Queue from "queue";
import * as helpers from "./helpers";
import SidebarPage, {
  SideBarContainer,
  SidebarPageContentArea,
} from "src/components/app/SidebarPage";
import { FormattedMessage, useIntl } from "react-intl";
import { MxReactIcon, MapPin } from "src/componentLibrary/react/mx-icon-react";
import MultiCommoditySelector from "src/components/app/MultiCommoditySelector";
import { Body } from "src/components/common/Typography";
import { AppToolbar, AppToolbarItem } from "src/components/app/AppToolbar";
import {
  ChartDataGranularity,
  ComparePeriod,
  ITimeRange,
  PointType,
  Unit,
} from "src/types/charting";
import {
  chartDataRangeFromInterval,
  convertPickerDatesToCustomTimeRange,
  convertPickerDatesToTimeRange,
  getDefaultGranularityForRange,
  getGranularityOptions,
} from "src/helpers/charting";
import {
  customSecondaryRange,
  daysBetweenDates,
  secondaryDateRange,
  zoomOutRange,
} from "src/helpers/dates";
import { parse } from "query-string";
import {
  createQItemsFromURL,
  getDefaultValues,
  getInitialParamsFromURL,
} from "src/components/app/ExplorerPage/explorerHydrateFromURL";
import {
  buildURLFiltersFromState,
  buildURLFromAssetRemoval,
  buildURLFromNewAssetSelection,
} from "src/components/app/ExplorerPage/explorerUpdateURLFromState";
import {
  ICompareRangeChange,
  ITimeRangeChange,
} from "src/components/common/TimeRangePicker";
import { SelectorData } from "../MultiCommoditySelector/types";
import { tracking } from "src/tracking";
import {
  CurrentDataParameters,
  useCommodiytDataFetching,
} from "src/hooks/useCommodityDataFetching";
import { useUnitPrefs } from "src/hooks/useUnitPrefs";
import { ErrorFallback } from "./ExplorerErrorFallback";
import { usePageTitle } from "src/hooks/usePageTitle";
import { DataKey } from "recharts/types/util/types";
import { useLatestSelectionContext } from "src/components/app/AdminTools/LastSelectionContext";
import { AppColors } from "src/components/common/Styling";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";
import { BuildingAnnotationType } from "../../../types/graphql";
import {
  allSelectedParentBuildings,
  getBuildingForAsset,
} from "./parsers/assetParser";

const extractQueueItemsFromURL = (
  location: Location,
  timeRange: ITimeRange,
  parsedAssetData: SelectorData,
): DataQueueItem[] => {
  const _s = parse(location.search);

  const buildingItems = _s.buildings
    ? createQItemsFromURL(
        _s.buildings as string,
        AssetType.BUILDING,
        timeRange,
        parsedAssetData,
        _s.granularity as ChartDataGranularity,
      )
    : [];

  const groupItems = _s.groups
    ? createQItemsFromURL(
        _s.groups as string,
        AssetType.GROUP,
        timeRange,
        parsedAssetData,
        _s.granularity as ChartDataGranularity,
      )
    : [];

  const pointItems = _s.points
    ? createQItemsFromURL(
        _s.points as string,
        AssetType.POINT,
        timeRange,
        parsedAssetData,
        _s.granularity as ChartDataGranularity,
      )
    : [];

  const equipItems = _s.equipmentPoints
    ? createQItemsFromURL(
        _s.equipmentPoints as string,
        AssetType.EQUIPMENT_POINT,
        timeRange,
        parsedAssetData,
        _s.granularity as ChartDataGranularity,
      )
    : [];

  return [...buildingItems, ...groupItems, ...pointItems, ...equipItems];
};

export enum UserMode {
  DEFAULT = "DEFAULT",
  ZOOM_IN = "ZOOM_IN",
  ZOOM_OUT = "ZOOM_OUT", // not really used at the moment
  PAN = "PAN",
}

export enum ExplorerPageEvents {
  ADD_SCHEDULE_EXCEPTION = "ExplorerAddScheduleException",
  BEGIN_ANNOTATION_CREATE = "ExplorerBeginAnnotationCreate",
  CANCEL_ANNOTATION_CREATE = "ExplorerCancelAnnotationCreate",
  COMPLETE_ANNOTATION_CREATE = "ExplorerCompleteAnnotationCreate",
  COMPLETE_ANNOTATION_UPDATE = "ExplorerCompleteAnnotationUpdate",
  CANCEL_ADD_SCHEDULE_EXCEPTION = "ExplorerCancelAddScheduleException",
  CHANGE_DATE_RANGE = "ExplorerChangeDateRange",
  CLICK_EXPLORER_HEADER_LINK = "ExplorerClickExplorerHeaderLink",
  CLICK_VIEW_IN_EXPLORER_LINK = "ExplorerClickViewInExplorerLink",
  COMPARE_TO_PAST = "ExplorerComparetoPast",
  EXPAND_ASSET_TREE = "ExplorerExpandAssetTree",
  EXPORT_DATA = "ExplorerExportData",
  FILTER_CHANGE = "ExplorerFilterChange",
  LOAD_EXPLORER_PAGE = "ExplorerLoadExplorerPage",
  MORE_OPTIONS = "ExplorerAddScheduledExceptionMoreOptions",
  OPEN_IN_MEASURES = "ExplorerOpenInMeasures",
  OPEN_SCHEDULES = "ExplorerOpenSchedules",
  OVERLAY_NEW_TAB = "ExplorerOverlayViewInNewTab",
  SAVE_ADD_EXCEPTION = "ExplorerAddScheduleExceptionSuccess",
  SCHEDULES_ADD_EXCEPTION = "SchedulesAddScheduleException",
  SCHEDULES_CANCEL_ADD_EXCEPTION = "SchedulesCancelAddScheduleException",
  SELECT_POINT = "ExplorerSelectPoint",
  TOGGLE_OVERLAY = "ExplorerToggleOverlay",
  TOOGLE_LEGEND_ITEM = "ExplorerToogleLegendItem",
  VIEW_STATE = "ExplorerViewState",
  ZOOM_IN = "ExplorerZoomIn",
  ZOOM_OUT = "ExplorerZoomOut",
}

const initialOverlayState: OverlayEventState = {
  IMPLEMENTED: false,
  IDENTIFIED: false,
  SCHEDULE_EXCEPTIONS: true,
  THRESHOLDS: false,
  PEAK_DEMAND: false,
  HIGH_USAGE: false,
  CUSTOM: false,
  OPERATING_SCHEDULE: false,
};

/* =============================================================== *\
   COMPONENT!
\* =============================================================== */
interface ExplorerPageProps {
  initialBuildingStates: AssetStateIndicator;
  initialPointStates: AssetStateIndicator;
  initialGroupStates: AssetStateIndicator;
  initialSelectedAssets: SelectedAssetsMap;
  assetData: SelectorData;
  initialTimezone: string | undefined;
}

export const ExplorerPage: React.FC<ExplorerPageProps> = props => {
  const {
    initialBuildingStates,
    initialPointStates,
    initialGroupStates,
    initialTimezone,
    initialSelectedAssets,
    assetData,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const translate = useIntl();
  const pageTitleString = translate.formatMessage({
    id: "navigation.explorer",
  });
  usePageTitle(pageTitleString);

  // This is here just because some things require a tz string, so if timezone is undefined, they'll use this...
  const defaultTimeZone = "America/New_York";

  const { currentOrganizationId } = useContext(CurrentOrganizationContext);
  const [timezone, setTimezone] = useState(initialTimezone ?? defaultTimeZone);
  const [latitude, setLatitude] = useState((undefined as unknown) as number);
  const [longitude, setLongitude] = useState((undefined as unknown) as number);
  const [overrideTimezone, setOverrideTimezone] = useState(
    initialTimezone ? false : true,
  );

  const {
    unitPrefForPoint,
    unitPrefForTemperature,
    unitPreferences,
  } = useUnitPrefs();
  // TODO: this is clever, but unclear? VERY UNCLEAR!
  const {
    _chartDataRange,
    _chartTimeRange,
    _chartGranularity,
    _overlayEventState,
  } = location
    ? getInitialParamsFromURL(parse(location.search))
    : getDefaultValues(timezone);

  /* =============================================================== *\
     INITIAL STATE OF THE WORLD
     | Time range (from URL or default)
     | Commodity (from URL or default)
     | Granularity (from URL or default)
     | What is the secondary time range
     | What is selected (currently a building maybe from @client)
  \* =============================================================== */

  const [granularity, setGranularity] = useState(_chartGranularity);
  // this is the actual set of dates for which we will fetch data.
  const [timeRange, setTimeRange] = useState(_chartTimeRange);
  // NOTE: the dates stored here initially are "bad" in that they are in the 1970s
  //       I did this because I am lazy. When comparing actually
  //       happens they get replaced, so technically, these never get seen
  // TODO: refactor this so the secondaryTimeRange is just stored in
  // the compareData, and not in two places. Also, allow it to be null or
  // undefined. I'm sure there was some reason I did this, but I can't
  // see why today!
  const [secondaryTimeRange, setSecondaryTimeRange] = useState(
    secondaryDateRange(
      timeRange,
      ComparePeriod.NONE,
      timezone || defaultTimeZone,
    ),
  );
  const [compareData, setCompareData] = useState<ICompareRangeChange>({
    comparePeriod: ComparePeriod.NONE,
    selectedDates: {
      from: new Date(secondaryTimeRange.startTime),
      to: new Date(secondaryTimeRange.endTime),
    },
  });
  // these guys hold the "old" timeseries keys that we last fetched data on.
  // we need that so when we change the time range, we can see which queue items
  // belonged to the present and the past data sets. There is probably a more clever
  // way to do this, but alas, I am not that clever.
  const [pastSecondaryTimeseriesKey, setPastSecondaryTimeseriesKey] = useState(
    "0_0",
  );
  // This is the "bucket size" for our data. It may not actually be the
  // interval our timeRange is. It was calculated from the dates in the URL
  // or given a sane default based on the default time range
  const [chartDataRange, setChartDataRange] = useState(_chartDataRange);
  // this holds the prior states the user has zoomed in from so we can zoom them back out
  const [zoomStack, setZoomStack] = useState<ITimeRangeChange[]>([]);
  const [userMode, setUserMode] = useState(UserMode.DEFAULT);

  // used to store the combo of the two measure event states to see if it became active at some point
  const overlayActive = useRef({
    measures: false,
    schedules: false,
    thresholds: false,
    peakDemand: false,
    highUsage: false,
    custom: false,
    operatingSchedules: false,
  });
  const [overlayState, setOverlayState] = useState<OverlayEventState>(
    _overlayEventState,
  );
  // used to fake knowing if we have overlay data...
  const overlayCheckTime = useRef<number | null>(null);
  const [lookAtEventState, setLookAtEventState] = useState(false);

  const compareToPast = compareData.comparePeriod !== ComparePeriod.NONE;

  const turnOffCompare = () => {
    setCompareData({
      comparePeriod: ComparePeriod.NONE,
      selectedDates: compareData.selectedDates,
    });
  };

  const handleCompareRangeChange: (
    data: ICompareRangeChange,
    tz: string,
  ) => void = (data, tz) => {
    // in theory, we should always get dates, but just in case
    if (data.selectedDates.from && data.selectedDates.to) {
      const _f = data.selectedDates.from;
      const _t = data.selectedDates.to;
      // if we're looking at a range that doesn't start at midnight,
      // we need to get a secondary range that starts at the right time
      // otherwise, we can use midnight as our starting time
      const currentRangeStartTime = DateTime.fromJSDate(new Date(timeRange.startTime), { zone: timezone }).hour;
      const _newSecondary =
        currentRangeStartTime !== 0
          ? convertPickerDatesToCustomTimeRange(
              {
                startTime: _f,
                endTime: _t,
              },
              tz,
              currentRangeStartTime,
              DateTime.fromJSDate(new Date(timeRange.endTime), { zone: timezone }).hour,
            )
          : convertPickerDatesToTimeRange(
              {
                startTime: _f,
                endTime: _t,
              },
              tz,
            );
      // make a new secondary time range
      // set the past key to what it is at the moment before we change it...
      const _pastSecondaryTSKey = helpers.keyFromTimeRange(secondaryTimeRange);

      // update the data fetching hook
      updateDataParameters({
        granularity: granularity,
        primaryTimeseriesKey: helpers.keyFromTimeRange(timeRange),
        secondaryTimeseriesKey: helpers.keyFromTimeRange(_newSecondary),
        rangeSize: chartDataRange,
      });
      // our changes will make the useEffect for the compare stuff changing fire
      // and that will handle the removing / fetching of new data!
      setPastSecondaryTimeseriesKey(_pastSecondaryTSKey);
      setSecondaryTimeRange(_newSecondary);
      setCompareData(data);
    }
  };

  useEffect(() => {
    tracking.fireEvent(ExplorerPageEvents.COMPARE_TO_PAST, {
      compareType: compareData.comparePeriod,
    });
    // do something when we are NONE
    if (compareData.comparePeriod === ComparePeriod.NONE) {
      const _key = helpers.keyFromTimeRange(secondaryTimeRange);
      chartDataDispatch({
        type: "REMOVE_TIMESERIES",
        payload: {
          timeseriesKey: _key,
        },
      });
    } else {
      // at this point, the secondary date range is correct,
      // and the past secondary key is what the current compare data is keyed with
      // so remove the "past" and queue the new.
      const _primaryTSKey = helpers.keyFromTimeRange(timeRange);
      const _newSecondaryKey = helpers.keyFromTimeRange(secondaryTimeRange);
      chartDataDispatch({
        type: "REMOVE_TIMESERIES",
        payload: {
          timeseriesKey: pastSecondaryTimeseriesKey,
        },
      });
      const queue = chartDataState.queueItems;
      const newQItems: DataQueueItem[] = [];
      queue.forEach(_q => {
        if (_q.timeseriesKey === _primaryTSKey) {
          const _new: DataQueueItem = {
            ..._q,
            timeseriesKey: _newSecondaryKey,
            timeRange: {
              startTime: secondaryTimeRange.startTime,
              endTime: secondaryTimeRange.endTime,
            },
          };
          newQItems.push(_new);
        }
      });
      enqueueRequests(newQItems);
    }
    // so something when we are not none...
  }, [compareData]);

  /* =============================================================== *\
     QUEUE TO HANDLE DATA FETCHING
     Figure out the initial queue items by looping through the initial
     buildings and points and grabbing things that are SELECTED
  \* =============================================================== */
  const requestQ = Queue({
    autostart: true,
    concurrency: 3,
  });

  /** called when user selects a new granularity from a select */
  const handleGranularityChange: (s: string) => void = s => {
    const _g = s as ChartDataGranularity;
    setGranularity(_g);
    updateDataParameters({
      granularity: _g,
      primaryTimeseriesKey: helpers.keyFromTimeRange(timeRange),
      secondaryTimeseriesKey: helpers.keyFromTimeRange(secondaryTimeRange),
      rangeSize: chartDataRange,
    });
    tracking.fireEvent(ExplorerPageEvents.FILTER_CHANGE, {
      granularity,
      startTime: timeRange.startTime,
      endTime: timeRange.endTime,
      dayRange: daysBetweenDates(timeRange.startTime, timeRange.endTime),
    });
    tracking.fireEvent("ExplorerChangeInterval", {
      interval: granularity,
    });
  };

  const zoomOut = () => {
    if (compareData.comparePeriod === ComparePeriod.CUSTOM) {
      turnOffCompare();
    }
    if (zoomStack.length > 0) {
      const item = zoomStack[0];
      setZoomStack(zoomStack.slice(1));
      const _t: ITimeRange = {
        startTime: item.selectedDates.from?.toISOString() ?? "",
        endTime: item.selectedDates.to?.toISOString() ?? "",
      };
      changeSelectedDates(_t);
    } else {
      // calculate the next range and make a thing
      const zoomRange = zoomOutRange(timeRange);
      changeSelectedDates(zoomRange);
    }
  };
  const zoomIn = (vals: ITimeRangeChange) => {
    // make a range change from the CURRENT time range
    // so we can go back to it.
    if (compareData.comparePeriod === ComparePeriod.CUSTOM) {
      turnOffCompare();
    }
    const rChange: ITimeRangeChange = {
      selectedDates: {
        from: new Date(timeRange.startTime),
        to: new Date(timeRange.endTime),
      },
    };
    setZoomStack([rChange, ...zoomStack]);
    // create a new time range and go to it
    const newRange: ITimeRange = {
      startTime: vals.selectedDates.from?.toISOString() ?? "",
      endTime: vals.selectedDates.to?.toISOString() ?? "",
    };
    changeSelectedDates(newRange);
  };
  // called when the date picker gives us new values
  // TODO: Deal with the hack in place down there.
  //  one thing to do is make this accept TZ as a param
  //  and add it in from the CARD, since TZ has to exist there.
  //  in fact, that is smart, and I will do that when I am
  //  done doing what I am in the middle of. Or, I will forget
  //  and whoever is reading this can do it =)
  const handleRangeAccept = (vals: ITimeRangeChange, tz: string) => {
    // if we have a custom compare period, we need to turn off compare since
    // the new dates may not make any sense at all relative to the custom compare we
    // were doing before.
    if (compareData.comparePeriod === ComparePeriod.CUSTOM) {
      turnOffCompare();
    }
    const { selectedDates } = vals;
    const _f = selectedDates.from as Date;
    const _t = selectedDates.to as Date;
    const _newRange = convertPickerDatesToTimeRange(
      {
        startTime: _f,
        endTime: _t,
      },
      tz
    );
    changeSelectedDates(_newRange);
  };
  const distanceBetweenRanges = () => {
    const _currentStart = DateTime.fromJSDate(new Date(timeRange.startTime), {
      zone: timezone,
    });
    const _compareStart = DateTime.fromJSDate(
      new Date(secondaryTimeRange.endTime),
      {
        zone: timezone,
      },
    );
    const _rangeSize = Math.abs(_compareStart.diff(_currentStart, "days").days);
    return _rangeSize;
  };
  const changeSelectedDates = (newRange: ITimeRange) => {
    // get the fixed range that corresponds to
    const _r = chartDataRangeFromInterval(newRange);
    const _g = getDefaultGranularityForRange(_r);

    // note that we always make and keep track of this, even if we are not comparing to past
    // at the moment.
    const _s =
      compareData.comparePeriod === ComparePeriod.CUSTOM
        ? customSecondaryRange(
            newRange,
            timezone || defaultTimeZone,
            distanceBetweenRanges(),
          )
        : secondaryDateRange(
            newRange,
            compareData.comparePeriod,
            timezone || defaultTimeZone,
          );
    updateDataParameters({
      granularity: _g,
      primaryTimeseriesKey: helpers.keyFromTimeRange(newRange),
      secondaryTimeseriesKey: helpers.keyFromTimeRange(_s),
      rangeSize: _r,
    });
    // store the now "old" timeseries keys so we can reference them when we
    // make new queue items in that useEffect that handles that somewhere.
    setPastSecondaryTimeseriesKey(helpers.keyFromTimeRange(secondaryTimeRange));
    /*
    // WHAT HAPPENS IF WE DON'T REMOVE THIS?? :iiam: -- BAD THINGS!
    if (compareData.comparePeriod === ComparePeriod.CUSTOM) {
      // useEffect will do the removing for us
      setCompareData({
        comparePeriod: ComparePeriod.NONE,
        selectedDates: compareData.selectedDates,
      });
    }
    */

    setGranularity(_g);
    // set the new time ranges
    setTimeRange(newRange);
    setSecondaryTimeRange(_s);
    // UPDATE THE HOOK
    setChartDataRange(_r);
    // useEffect will queue and handle data fetching

    tracking.fireEvent(ExplorerPageEvents.CHANGE_DATE_RANGE, {
      DateRange: daysBetweenDates(newRange.startTime, newRange.endTime),
    });
  };

  /* =============================================================== *\
     NUKE EVERYTHING AND MAKE NEW QUEUE ITEMS WITH NEW INFO WHEN
     THINGS LIKE RANGE AND GRANULARITY CHANGE
  \* =============================================================== */
  useEffect(() => {
    // stops and empties the request queue(ueueueue)
    requestQ.end();
    // get all charts and make new queue items based on them
    const queue = chartDataState.queueItems;
    const newQItems: DataQueueItem[] = [];

    const primaryKey = helpers.keyFromTimeRange(timeRange);
    const secondaryKey = helpers.keyFromTimeRange(secondaryTimeRange);

    // will hold the unique keys (ts.asset.commodity) of charts that are currently HIDDEN!!!!
    // (hence the name)
    // we will use that to set a flag on new queue items we make so things that are
    // currently hidden are hidden when we load new data.
    const currentlyHidden = new Set<string>();
    chartDataState.charts.forEach(chart => {
      if (!chart.visible) {
        currentlyHidden.add(helpers.keyFromQueueItem(chart.queueItem));
      }
    });

    queue.forEach(_q => {
      const range =
        _q.timeseriesKey === secondaryKey ? secondaryTimeRange : timeRange;
      const _k = _q.timeseriesKey === secondaryKey ? secondaryKey : primaryKey;
      const _new: DataQueueItem = {
        ..._q,
        timeseriesKey: _k,
        granularity: granularity,
        timeRange: {
          startTime: range.startTime,
          endTime: range.endTime,
        },
        startHidden: currentlyHidden.has(helpers.keyFromQueueItem(_q)),
      };
      // just to be safe do not queue new secondary items if comparePeriod is None
      // which can happen during zoom in/ out
      if (
        !(
          compareData.comparePeriod === ComparePeriod.NONE &&
          _k === secondaryKey
        )
      ) {
        newQItems.push(_new);
      }
    });

    updateURLFilters();
    // fire action to reset the chart state and fill queue with new stuff
    chartDataDispatch({
      type: "RESET_STATE",
    });
    enqueueRequests(newQItems);
    if (newQItems.length > 0 && isOverlayActive()) {
      fetchOverlayDataForQueueItems(newQItems);
    }
  }, [timeRange, granularity]);

  useEffect(() => {
    updateURLFilters();
  }, [overlayState]);

  /*
  requestQ.on("end", () => {
    console.log("/!\\ requestQ finished all jobs /!\\");
  });
  */
  const fetchOverlayDataForQueueItems = (qItems: DataQueueItem[]) => {
    const seenIds = new Set<string>();
    let seenOperatingSchedule = false;
    qItems.forEach(item => {
      if (item.assetType === AssetType.BUILDING) {
        if (!seenIds.has(item.assetId)) {
          requestQ.push(c => {
            if (overlayState.IDENTIFIED || overlayState.IMPLEMENTED) {
              fetchMeasureEventsForBuilding(item.assetId, item.timezone);
            }

            if (overlayState.SCHEDULE_EXCEPTIONS) {
              fetchScheduleExceptions(item.assetId, item.timezone);
            }

            if (overlayState.THRESHOLDS) {
              fetchThresholds(item.assetId);
            }

            if (overlayState.PEAK_DEMAND) {
              fetchAnnotations(
                item.assetId,
                BuildingAnnotationType.PEAK_DEMAND,
                item.timezone,
              );
            }

            if (overlayState.HIGH_USAGE) {
              fetchAnnotations(
                item.assetId,
                BuildingAnnotationType.HIGH_USAGE,
                item.timezone,
              );
            }

            if (overlayState.CUSTOM) {
              fetchAnnotations(
                item.assetId,
                BuildingAnnotationType.CUSTOM,
                item.timezone,
              );
            }

            if (c) {
              c();
            }
          });
          seenIds.add(item.assetId);
        }
      }

      // Only want to load the operating schedule once since it'll only be for one building
      if (!seenOperatingSchedule && overlayState.OPERATING_SCHEDULE) {
        const buildingId = getBuildingForAsset(item, assetData);
        fetchOperatingSchedule(buildingId, timeRange);
        seenOperatingSchedule = true;
      }
    });
    if (seenIds.size > 0) {
      startOverlayDataCheckTimer();
    }
  };

  const startOverlayDataCheckTimer = () => {
    if (overlayCheckTime.current === null) {
      overlayCheckTime.current = window.setTimeout(
        () => setLookAtEventState(false), // setting to false for now to turn off toast
        2000,
      );
    }
  };

  useEffect(() => {
    if (lookAtEventState) {
      if (overlayCheckTime.current !== null) {
        clearTimeout(overlayCheckTime.current);
        overlayCheckTime.current = null;
      }
      if (chartDataState.events.size === 0) {
        InfoToast("explorer.events.noDataMessage");
      } else {
        const noData = checkForActiveEvents(chartDataState.events);

        if (noData) {
          InfoToast("explorer.events.noDataMessage");
        }
      }
      setLookAtEventState(false);
    }
  }, [lookAtEventState]);

  const checkForActiveEvents = (items: OverlayEvents): boolean => {
    let noData = true;
    items.forEach(_eventGroup => {
      _eventGroup.forEach(_event => {
        if (eventMatchesActiveEvents(_event.eventType)) {
          noData = false;
        }
      });
    });

    return noData;
  };

  const eventMatchesActiveEvents = (eventType: OverlayEventType) => {
    // TODO: this will have to get a lot smarter when there are more things.
    switch (eventType) {
      case MeasureEventType.Implemented:
        return overlayState.IMPLEMENTED;
      case MeasureEventType.Identified:
        return overlayState.IDENTIFIED;
      case ScheduleEventType.ScheduleExceptions:
        return overlayState.SCHEDULE_EXCEPTIONS;
      case ThresholdEventType.Thresholds:
        return overlayState.THRESHOLDS;
      case AnnotationEventType.PeakDemand:
        return overlayState.PEAK_DEMAND;
      case AnnotationEventType.HighUsage:
        return overlayState.HIGH_USAGE;
      case AnnotationEventType.Custom:
        return overlayState.CUSTOM;
      default:
        return false;
    }
  };
  /* =============================================================== *\
     DATA FETCHING
  \* =============================================================== */

  const enqueueRequests = (qItems: DataQueueItem[]) => {
    qItems.forEach(qItem => {
      requestQ.push(c => {
        fetchCommodityData(qItem);
        if (c) {
          c();
        }
      });
    });
  };
  /* =============================================================== *\
     HANDLES UPDATE URL FROM STATE
  \* =============================================================== */
  const updateURLFilters = () => {
    // eslint-disable-next-line no-restricted-globals
    if (location && history.replaceState) {
      const queryString = buildURLFiltersFromState(
        location.search,
        granularity,
        timeRange,
        overlayState,
      );
      const newUrl = window.location.pathname + queryString;
      location.search = queryString;
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(
        {
          path: newUrl,
        },
        "",
        newUrl,
      );
    }
  };

  const updateURL = (_currentState: AssetState, asset: CommoditySelection) => {
    // eslint-disable-next-line no-restricted-globals
    if (location && history.replaceState) {
      let queryString = "";

      if (_currentState !== AssetState.SELECTED) {
        queryString = buildURLFromNewAssetSelection(
          asset.assetId,
          asset.assetType,
          location.search,
          granularity,
          timeRange,
          asset.commodity,
          asset.timezone,
          overlayState,
        );
      } else {
        queryString = buildURLFromAssetRemoval(
          asset.assetId,
          asset.assetType,
          asset.commodity,
          location.search,
        );
      }

      // TODO: determine if this is the right thing to do...
      if (queryString === "") {
        setTimezone(defaultTimeZone);
      }

      const newUrl = window.location.pathname + queryString;
      location.search = queryString;
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(
        {
          path: newUrl,
        },
        "",
        newUrl,
      );
    }
  };

  /* =============================================================== *\
     HANDLES UPDATING THE BUILDING AND METER STATES!

     This really should be combined with the other reducer at some
     point, but I am lazy.
  \* =============================================================== */
  const [assetState, assetDispatch] = useReducer(assetStateReducer, {
    buildingStates: initialBuildingStates,
    pointStates: initialPointStates,
    groupStates: initialGroupStates,
  });
  /* =============================================================== *\
     HANDLES CHART DATA STUFF
  \* =============================================================== */
  const [chartDataState, chartDataDispatch] = useReducer(
    chartDataReducer,
    initialEmptyChartData,
  );

  /* =============================================================== *\
     CALL TO TRACK CURRENT USER VIEW STATE

     Will wait 500ms before doing so, and is debounced so if user is
     clicking furiously, we don't send a bunch of transient events.
  \* =============================================================== */
  const logUserView = debounce(() => {
    const vs = userViewState(chartDataState);
    tracking.fireEvent(ExplorerPageEvents.VIEW_STATE, {
      viewState: vs,
    });
  }, 500);

  /* =============================================================== *\
     Sets up data fetching hook
  \* =============================================================== */
  const initalDataParams: CurrentDataParameters = {
    granularity: granularity,
    primaryTimeseriesKey: helpers.keyFromTimeRange(timeRange),
    secondaryTimeseriesKey: helpers.keyFromTimeRange(secondaryTimeRange),
    rangeSize: chartDataRange,
  };
  const {
    fetchCommodityData,
    updateDataParameters,
    unmount,
  } = useCommodiytDataFetching(
    chartDataDispatch,
    assetDispatch,
    initalDataParams,
  );

  const currentAssetState = (
    asset: CommoditySelection,
    key: string,
  ): AssetState => {
    switch (asset.assetType) {
      case AssetType.BUILDING:
        return assetState.buildingStates[key];
      case AssetType.POINT:
      case AssetType.EQUIPMENT_POINT:
        return assetState.pointStates[key];
      case AssetType.GROUP:
      default:
        return assetState.groupStates[key];
    }
  };

  const updateActionType = (
    asset: CommoditySelection,
  ): "UPDATE_BUILDING_STATE" | "UPDATE_POINT_STATE" | "UPDATE_GROUP_STATE" => {
    switch (asset.assetType) {
      case AssetType.BUILDING:
        return "UPDATE_BUILDING_STATE";
      case AssetType.POINT:
      case AssetType.EQUIPMENT_POINT:
        return "UPDATE_POINT_STATE";
      case AssetType.GROUP:
      default:
        return "UPDATE_GROUP_STATE";
    }
  };

  // MOVE THIS TO ITS OWN THING MAYBE?
  const apolloClient = useApolloClient();
  const fetchMeasureEventsForBuilding = async (
    buildingId: string,
    tz: string | undefined,
  ) => {
    const energyUnitPreference = {
      ELECTRICITY_USAGE: unitPreferences?.ELECTRICITY_USAGE,
      NATURAL_GAS_ENERGY: unitPreferences?.NATURAL_GAS_ENERGY,
    };
    const { data, errors } = await apolloClient.query({
      query: GetMeasuresForBuildingsInExplorerDocument,
      variables: { buildingId, unitPreferences: energyUnitPreference },
    });
    if (errors) {
      console.error(
        "failed to receive measures data for building %s",
        buildingId,
      );
      return;
    } else if (data.getBuildingById?.measures) {
      const payload = {
        response: data as BuildingMeasuresResponse,
        timezone: tz,
        timeRange: {
          startTime: timeRange.startTime,
          endTime: timeRange.endTime,
        },
      };
      chartDataDispatch({
        type: "RECEIVE_MEASURE_EVENT_DATA",
        payload,
      });
    }
  };

  const fetchCoordinatesForBuilding = async (buildingId: string) => {
    const { data, errors } = await apolloClient.query({
      query: GetLatLongForBuildingDocument,
      variables: { buildingId },
    });
    if (errors) {
      console.error(
        "failed to retrieve latitude/longitude for building %s",
        buildingId,
      );
      return;
    } else if (
      data.getBuildingById?.location.latitude &&
      data.getBuildingById?.location.longitude
    ) {
      setLatitude(data.getBuildingById?.location.latitude);
      setLongitude(data.getBuildingById?.location.longitude);
    }
  };

  const fetchScheduleExceptions = async (
    buildingId: string,
    tz: string | undefined,
  ) => {
    const { data, errors } = await apolloClient.query({
      query: LoadScheduleExceptionsForBuildingInExplorerDocument,
      variables: {
        buildingId,
      },
    });

    if (errors) {
      console.error(
        "failed to receive schedule exceptions for building %s",
        buildingId,
      );
      return;
    } else if (data.getBuildingById?.scheduleExceptions) {
      chartDataDispatch({
        type: "RECEIVE_SCHEDULE_EXCEPTION_EVENT_DATA",
        payload: {
          response: data as BuildingScheduleExceptionsResponse,
          timezone: tz,
          timeRange: {
            startTime: timeRange.startTime,
            endTime: timeRange.endTime,
          },
        },
      });
    }
  };

  const fetchThresholds = async (buildingId: string) => {
    const { data, errors } = await apolloClient.query({
      query: LoadThresholdsForBuildingInExplorerDocument,
      variables: {
        buildingId,
      },
    });

    if (errors) {
      console.error("failed to receive thresholds for building %s", buildingId);
      return;
    } else if (data.getBuildingById) {
      chartDataDispatch({
        type: "RECEIVE_THRESHOLD_EVENT_DATA",
        payload: data,
      });
    }
  };

  const fetchAnnotations = async (
    buildingId: string,
    type: BuildingAnnotationType,
    tz: string | undefined,
  ) => {
    const { data, errors } = await apolloClient.query({
      query: LoadAnnotationsForBuildingInExplorerDocument,
      variables: {
        buildingId,
      },
    });

    if (errors) {
      console.error(
        "failed to receive annotations for building %s",
        buildingId,
      );
      return;
    } else if (data.getBuildingById) {
      const dataByType: BuildingAnnotationsResponse = {
        getBuildingById: {
          ...data.getBuildingById,
          annotations: (data.getBuildingById
            ?.annotations as BuildingAnnotation[]).filter(a => {
            return a.type === type;
          }),
        },
      };

      chartDataDispatch({
        type: "RECEIVE_ANNOTATION_EVENT_DATA",
        payload: {
          response: dataByType,
          timezone: tz,
          timeRange: {
            startTime: timeRange.startTime,
            endTime: timeRange.endTime,
          },
        },
      });
    }
  };

  const fetchOperatingSchedule = async (
    buildingId: string,
    timeRange: ITimeRange,
  ) => {
    if (buildingId) {
      const { data, errors } = await apolloClient.query({
        query: LoadOperatingScheduleForBuildingDocument,
        variables: {
          buildingId,
          timeRange,
        },
      });

      if (errors) {
        console.error(`failed to retrieve schedule for building ${buildingId}`);
      } else if (data.getBuildingById) {
        chartDataDispatch({
          type: "RECEIVE_BUILDING_OPERATING_SCHEDULE",
          payload: data,
        });
      }
    }
  };

  /**
   * Is the user looking at overlays of some sort?
   *
   * @returns boolean - is there an active overlay thing
   */
  const isOverlayActive = () => Object.values(overlayState).includes(true);

  /**
   * How many charts are being drawn for this chart right now?
   *
   * @param buildingId - the building ID you are interested in
   * @returns number - the number of currently drawn commodity charts for that building
   */
  const numberOfBuildingDataPoints = (buildingId: string) => {
    const b = buildingsWithChartData(chartDataState.queueItems);
    return b.get(buildingId)?.count ?? 0;
  };
  /**
   * Given a newly selected building ID, should we fetch Measure Events for said building?
   *
   * @param buildingId the building ID the user picked
   * @returns
   */
  const shouldFetchMeasureOverlayEventsForBuilding = (buildingId: string) => {
    return (
      (overlayState.IDENTIFIED || overlayState.IMPLEMENTED) &&
      numberOfBuildingDataPoints(buildingId) === 0
    );
  };

  /**
   * Given a newly selected building ID, should we fetch Schedule Exceptions for said building?
   *
   * @param buildingId the building ID the user picked
   * @returns
   */
  const shouldFetchScheduleExceptionOverlayEventsForBuilding = (
    buildingId: string,
  ) => {
    return (
      overlayState.SCHEDULE_EXCEPTIONS &&
      numberOfBuildingDataPoints(buildingId) === 0
    );
  };

  /**
   * Given a newly selected building ID, should we fetch Thresholds for said building?
   *
   * @param buildingId the building ID the user picked
   * @returns
   */
  const shouldFetchThresholdEventsForBuilding = (buildingId: string) => {
    return (
      overlayState.THRESHOLDS && numberOfBuildingDataPoints(buildingId) === 0
    );
  };

  /**
   * Given a newly selected building ID, should we fetch Peak Demand Events for said building?
   *
   * @param buildingId the building ID the user picked
   * @returns
   */
  const shouldFetchPeakDemandEventsForBuilding = (buildingId: string) => {
    return (
      overlayState.PEAK_DEMAND && numberOfBuildingDataPoints(buildingId) === 0
    );
  };

  /**
   * Given a newly selected building ID, should we fetch High Usage Events for said building?
   *
   * @param buildingId the building ID the user picked
   * @returns
   */
  const shouldFetchHighUsageEventsForBuilding = (buildingId: string) => {
    return (
      overlayState.HIGH_USAGE && numberOfBuildingDataPoints(buildingId) === 0
    );
  };

  /**
   * Given a newly selected building ID, should we fetch Custom Events for said building?
   *
   * @param buildingId the building ID the user picked
   * @returns
   */
  const shouldFetchCustomEventsForBuilding = (buildingId: string) => {
    return overlayState.CUSTOM && numberOfBuildingDataPoints(buildingId) === 0;
  };

  /**
   * Returns true if the `asset` being added isn't a building/meter/point or
   * if the building associated with the asset isn't one of the
   * buildings already selected.
   * @param asset
   * @returns
   */
  const shouldDisableOperatingScheduleOverlay = (
    asset: {
      assetId: string;
      assetType: AssetType;
    },
    queueItems: DataQueueItem[],
  ): boolean => {
    const assetRollsUpToBuilding =
      asset.assetType === AssetType.BUILDING ||
      asset.assetType === AssetType.METER ||
      asset.assetType === AssetType.POINT ||
      asset.assetType === AssetType.EQUIPMENT_POINT;

    const selectedBuildings = allSelectedParentBuildings(queueItems, assetData);

    const buildingId = getBuildingForAsset(asset, assetData);

    return (
      !assetRollsUpToBuilding ||
      (selectedBuildings.size > 0 && !selectedBuildings.has(buildingId))
    );
  };

  /**
   * Returns true if after removing the `asset` there is no more
   * than one building selected.
   * @param asset
   * @returns
   */
  const shouldEnableOperatingScheduleOverlay = (asset: {
    assetId: string;
    assetType: AssetType;
  }): boolean => {
    // If there are any queue items that are groups after removing this asset,
    // we know we don't want to enable operating schedules
    if (
      chartDataState.queueItems.some(
        item =>
          item.assetType === AssetType.GROUP && item.assetId !== asset.assetId,
      )
    ) {
      return false;
    }

    const selectedBuildings = allSelectedParentBuildings(
      chartDataState.queueItems,
      assetData,
    );

    const buildingId = getBuildingForAsset(asset, assetData);

    return (
      selectedBuildings.size === 1 ||
      (selectedBuildings.size === 2 && selectedBuildings.get(buildingId) === 1)
    );
  };

  /**
   * Returns true if the asset rolls up to a building, operating schedule
   * overlay is turned on, and there isn't already a building selected (we
   * don't want to double fetch the building if already loaded the schedule
   * based on a child of the building).
   * @param asset
   * @returns
   */
  const shouldFetchOperatingScheduleEventsForBuilding = (asset: {
    assetId: string;
    assetType: AssetType;
  }) => {
    const isBuildingOrPointOrMeter =
      asset.assetType === AssetType.BUILDING ||
      asset.assetType === AssetType.METER ||
      asset.assetType === AssetType.POINT ||
      asset.assetType === AssetType.EQUIPMENT_POINT;

    const selectedBuildings = allSelectedParentBuildings(
      chartDataState.queueItems,
      assetData,
    );

    return (
      overlayState.OPERATING_SCHEDULE &&
      isBuildingOrPointOrMeter &&
      selectedBuildings.size === 0
    );
  };

  /**
   * If I just deselected a building commodity in the asset selector, should I remove overlays?
   *
   * @param buildingId - the building in question
   * @returns
   */
  const shouldRemoveEventsForBuilding = (buildingId: string) => {
    return numberOfBuildingDataPoints(buildingId) < 2;
  };

  /**
   * called when we enter a state when overlays become active.
   * It will kick off requesting data for every active building.
   */
  const handleOverlayActivation = (
    activated:
      | "schedules"
      | "measures"
      | "thresholds"
      | "operatingSchedule"
      | "peakDemand"
      | "highUsage"
      | "custom",
  ) => {
    // TODO: the timer thing
    const b = buildingsWithChartData(chartDataState.queueItems);
    b.forEach((value, key) => {
      const firstItem = value.items[0];
      requestQ.push(c => {
        if (activated === "measures") {
          fetchMeasureEventsForBuilding(key, firstItem.timezone);
        }

        if (activated === "schedules") {
          fetchScheduleExceptions(key, firstItem.timezone);
        }

        if (activated === "thresholds") {
          fetchThresholds(key);
        }

        if (activated === "peakDemand") {
          fetchAnnotations(
            key,
            BuildingAnnotationType.PEAK_DEMAND,
            firstItem.timezone,
          );
        }

        if (activated === "highUsage") {
          fetchAnnotations(
            key,
            BuildingAnnotationType.HIGH_USAGE,
            firstItem.timezone,
          );
        }

        if (activated === "custom") {
          fetchAnnotations(
            key,
            BuildingAnnotationType.CUSTOM,
            firstItem.timezone,
          );
        }

        if (c) {
          c();
        }
      });
    });

    // Operating schedules are only displayed for one building
    // (or child of a building) so this has to be checked outside
    // of the regular flow for overlays.
    if (activated === "operatingSchedule") {
      // We can assume if the user was able to activate the operating
      // schedule all of the items in the queue relate back to the same
      // building so we can just grab the first one.
      const asset = chartDataState.queueItems[0];
      if (asset) {
        const buildingId = getBuildingForAsset(asset, assetData);
        fetchOperatingSchedule(buildingId, timeRange);
      }
    }

    // If an overlay is defaulted to true, we don't want
    // to start a timer when there's nothing to fetch for.
    if (b.size > 0) {
      startOverlayDataCheckTimer();
    }
  };

  /**
   * called when all measure events have been toggled off. This will call the action
   * to remove them all from state.
   */
  const handleOverlayDeactivation = () => {
    if (overlayCheckTime.current !== null) {
      clearTimeout(overlayCheckTime.current);
      overlayCheckTime.current = null;
      setLookAtEventState(false);
    }
    chartDataDispatch({
      type: "REMOVE_OVERLAY_EVENTS",
    });
  };

  /**
   * Called when a building is no longer displaying data in charts so we should
   * remove it from the overlay events.
   * @param buildingId - building ID you want to remove overlay events for
   */
  const removeOverlayEventsForBuilding = (buildingId: string) => {
    chartDataDispatch({
      type: "REMOVE_OVERLAY_EVENTS_FOR_ASSET",
      payload: {
        buildingId,
      },
    });
  };

  /**
   * handles overlay event fetching or removal en masse when the overall state changes
   */
  useEffect(() => {
    if (
      Object.values(overlayActive.current).includes(true) &&
      !Object.values(overlayState).includes(true)
    ) {
      overlayActive.current = {
        measures: false,
        schedules: false,
        thresholds: false,
        peakDemand: false,
        highUsage: false,
        custom: false,
        operatingSchedules: false,
      };

      handleOverlayDeactivation();
    } else if (
      overlayActive.current.operatingSchedules &&
      !overlayState.OPERATING_SCHEDULE
    ) {
      // We always want to remove the operating schedule when
      // it's deactivated since we can only display for
      // one building at a time.
      overlayActive.current.operatingSchedules = false;
      chartDataDispatch({
        type: "REMOVE_OPERATING_SCHEDULE_OVERLAYS",
        payload: {},
      });
    } else {
      if (
        !overlayActive.current.schedules &&
        overlayState.SCHEDULE_EXCEPTIONS
      ) {
        handleOverlayActivation("schedules");
        overlayActive.current.schedules = true;
      } else if (!overlayActive.current.thresholds && overlayState.THRESHOLDS) {
        handleOverlayActivation("thresholds");
        overlayActive.current.thresholds = true;
      } else if (
        !overlayActive.current.operatingSchedules &&
        overlayState.OPERATING_SCHEDULE
      ) {
        handleOverlayActivation("operatingSchedule");
        overlayActive.current.operatingSchedules = true;
      } else if (
        !overlayActive.current.measures &&
        (overlayState.IDENTIFIED || overlayState.IMPLEMENTED)
      ) {
        overlayActive.current.measures = true;
        handleOverlayActivation("measures");
      } else {
        if (!overlayActive.current.peakDemand && overlayState.PEAK_DEMAND) {
          handleOverlayActivation("peakDemand");
          overlayActive.current.peakDemand = true;
        }
        if (!overlayActive.current.highUsage && overlayState.HIGH_USAGE) {
          handleOverlayActivation("highUsage");
          overlayActive.current.highUsage = true;
        }
        if (!overlayActive.current.custom && overlayState.CUSTOM) {
          handleOverlayActivation("custom");
          overlayActive.current.custom = true;
        }
      }
    }
  }, [overlayState]);

  const { setLatestSelection } = useLatestSelectionContext();

  /**
   * This makes the selection available outside of the current component
   * so other things can tell what's going on.
   *
   * It has some clean-up code to undo the translations from meter to point that
   * are probably in the wrong place. TODO: That cleanup can be removed if we move
   *  the intelligence for selection out of the selector and into the selection consumers
   * @param asset
   */
  const handleSelectionCallback = (asset: CommoditySelection) => {
    if (asset.assetType === AssetType.POINT) {
      setLatestSelection({
        asset: {
          assetId: asset.queryId,
          assetType: AssetType.METER,
          name: asset.assetName,
        },
      });
    } else {
      setLatestSelection({
        asset,
      });
    }
  };

  /**
   * Do we fetch the operating schedule for this asset or do we disable
   * them for all buildings? Tune in to find out!
   * @param asset
   */
  const handleOperatingScheduleOverlay = (asset: {
    assetId: string;
    assetType: AssetType;
  }) => {
    if (shouldFetchOperatingScheduleEventsForBuilding(asset)) {
      requestQ.push(c => {
        const buildingId = getBuildingForAsset(asset, assetData);
        fetchOperatingSchedule(buildingId, timeRange);
        if (c) {
          c();
        }
      });
    }

    // If the operating schedule overlay should be disabled, we need to disable
    // it and also remove any existing schedules.
    if (
      shouldDisableOperatingScheduleOverlay(asset, chartDataState.queueItems)
    ) {
      chartDataDispatch({
        type: "REMOVE_OPERATING_SCHEDULE_OVERLAYS",
        payload: {},
      });

      chartDataDispatch({
        type: "TOGGLE_OPERATING_SCHEDULE_OVERLAYS_FEATURE",
        payload: {
          enabled: false,
        },
      });
    }
  };

  /**
   * Handles a user clicking on a "thing" in the sidebar.
   * Will fetch data for previously unselected (now selected) things, will remove things
   * that were previously selected (now unselected).
   *
   * @param asset the thing the user clicked on in the sidebar
   */
  const handleAssetClick = (asset: CommoditySelection) => {
    handleSelectionCallback(asset);
    const _assetKey = helpers.assetKeyForCommoditySelection(asset);
    const _stateKey = `${asset.assetId}.${asset.commodity}`;
    const _currentState = currentAssetState(asset, _stateKey);
    if (_currentState === AssetState.NOT_AVAILABLE) {
      InfoToast("charts.explorer.noRealTimeData");
      return;
    }

    // Replace the default timezone with one from the asset that was selected
    if (overrideTimezone) {
      if (asset.timezone !== undefined) {
        setTimezone(asset.timezone);
      } else {
        setTimezone(defaultTimeZone);
      }

      setOverrideTimezone(false);
    }

    // if we were not selected, queue up fetching data!
    if (_currentState === AssetState.UNSELECTED) {
      // Add Event to MixPanel
      tracking.fireEvent(ExplorerPageEvents.SELECT_POINT, {
        assetId: asset.assetId,
        assetType: asset.assetType,
      });

      // TODO: look up the asset timezone if it not present??
      // this is in try / catch because queryFunctionForAsset
      // will throw
      try {
        const qItem: DataQueueItem = {
          ...asset,
          assetKey: _assetKey,
          timeseriesKey: helpers.keyFromTimeRange(timeRange),
          timeRange: {
            startTime: timeRange.startTime,
            endTime: timeRange.endTime,
          },
          granularity: granularity,
        };
        requestQ.push(c => {
          fetchCommodityData(qItem);
          if (c) {
            c();
          }
        });
        // if we're comparing, we have to get the past data as well
        if (compareToPast) {
          //if (compareToPast) {
          const pastQItem: DataQueueItem = {
            ...asset,
            assetKey: _assetKey,
            timeseriesKey: helpers.keyFromTimeRange(secondaryTimeRange),
            timeRange: {
              startTime: secondaryTimeRange.startTime,
              endTime: secondaryTimeRange.endTime,
            },
            granularity: granularity,
          };
          requestQ.push(c => {
            fetchCommodityData(pastQItem);
            if (c) {
              c();
            }
          });
        }
        if (asset.assetType === AssetType.BUILDING) {
          // Make sure we have latitude and longitude
          if (!asset.latitude || !asset.longitude) {
            requestQ.push(c => {
              fetchCoordinatesForBuilding(asset.assetId);
              if (c) {
                c();
              }
            });
            // fetch them
            // put in a setState
            // figure out what/when to update when the setState changes
          }
          if (shouldFetchMeasureOverlayEventsForBuilding(asset.assetId)) {
            // TODO: do we want to run the "no data" timer here as well? I dunno.
            requestQ.push(c => {
              fetchMeasureEventsForBuilding(asset.assetId, asset.timezone);
              if (c) {
                c();
              }
            });
          }

          if (
            shouldFetchScheduleExceptionOverlayEventsForBuilding(asset.assetId)
          ) {
            requestQ.push(c => {
              fetchScheduleExceptions(asset.assetId, asset.timezone);
              if (c) {
                c();
              }
            });
          }

          if (shouldFetchThresholdEventsForBuilding(asset.assetId)) {
            requestQ.push(c => {
              fetchThresholds(asset.assetId);
              if (c) {
                c();
              }
            });
          }

          if (shouldFetchPeakDemandEventsForBuilding(asset.assetId)) {
            requestQ.push(c => {
              fetchAnnotations(
                asset.assetId,
                BuildingAnnotationType.PEAK_DEMAND,
                asset.timezone,
              );
              if (c) {
                c();
              }
            });
          }

          if (shouldFetchHighUsageEventsForBuilding(asset.assetId)) {
            requestQ.push(c => {
              fetchAnnotations(
                asset.assetId,
                BuildingAnnotationType.HIGH_USAGE,
                asset.timezone,
              );
              if (c) {
                c();
              }
            });
          }

          if (shouldFetchCustomEventsForBuilding(asset.assetId)) {
            requestQ.push(c => {
              fetchAnnotations(
                asset.assetId,
                BuildingAnnotationType.CUSTOM,
                asset.timezone,
              );
              if (c) {
                c();
              }
            });
          }
        }

        handleOperatingScheduleOverlay(asset);
      } catch (e) {
        console.error(
          "Could not find query function for %s (%s)",
          asset.assetName,
          asset.assetId,
        );
      }
    } else if (_currentState === AssetState.SELECTED) {
      const keyPath = `${helpers.keyFromTimeRange(timeRange)}.${
        asset.commodity
      }.${helpers.assetKeyForCommoditySelection(asset)}`;
      const actionType = updateActionType(asset);
      assetDispatch({
        type: actionType,
        payload: {
          id: _stateKey,
          assetState: AssetState.UNSELECTED,
        },
      });
      // GET DA UNIT
      // TODO: This unit should be derived from the response from fetching commodity data, not calculated based on pointType
      try {
        const unit =
          asset.commodity === PointType.TEMPERATURE
            ? unitPrefForTemperature
            : asset.commodity === PointType.HUMIDITY
            ? Unit.PERCENT
            : unitPrefForPoint(asset.commodity);
        if (!unit) {
          console.error(
            `Unit preference missing for commodity: ${asset.commodity}`,
          );
          throw new Error(
            `Unit preference missing for commodity: ${asset.commodity}`,
          );
        }
        const unitName = getSymbolForUnit(unit);
        chartDataDispatch({
          type: "REMOVE_ASSET",
          payload: {
            keyPath,
            unitName,
          },
        });
        // if we were comparing, remove our partner chart!
        if (compareToPast) {
          const secondaryKeyPath = `${helpers.keyFromTimeRange(
            secondaryTimeRange,
          )}.${asset.commodity}.${helpers.assetKeyForCommoditySelection(
            asset,
          )}`;
          chartDataDispatch({
            type: "REMOVE_ASSET",
            payload: {
              keyPath: secondaryKeyPath,
              unitName,
            },
          });
        }

        if (asset.assetType === AssetType.BUILDING) {
          if (asset.latitude && asset.longitude) {
          }
          if (shouldRemoveEventsForBuilding(asset.assetId)) {
            removeOverlayEventsForBuilding(asset.assetId);
          }
        }

        if (shouldEnableOperatingScheduleOverlay(asset)) {
          chartDataDispatch({
            type: "TOGGLE_OPERATING_SCHEDULE_OVERLAYS_FEATURE",
            payload: {
              enabled: true,
            },
          });
        }
      } catch (e) {
        console.log(
          "unable to remove asset because no unit found for %s",
          asset.commodity,
        );
      }
    } else {
      // NO OP!
      return;
    }
    // send current-ish view state to mixpanel
    logUserView();
    updateURL(_currentState, asset);
  };

  /* =============================================================== *\
     LEGEND ITEM CLICK
  \* =============================================================== */
  const handleLegendItemClick = (dataKey: DataKey<string>) => {
    const _keyStr = dataKey.toString();
    // NOTE: we don't update the asset list because they didn't
    // de-select the asset, just hid the chart
    chartDataDispatch({
      type: "TOGGLE_CHART_VISIBILITY",
      payload: {
        keyPath: _keyStr,
      },
    });

    tracking.fireEvent(ExplorerPageEvents.TOOGLE_LEGEND_ITEM, {
      assetKey: _keyStr,
    });
  };

  /* =============================================================== *\
     Give a "clear all" function, but only if things are picked!
     Otherwise, it is just undefined
  \* =============================================================== */
  const clearAllHandler = () => {
    // find out if anything is in progress or whatever?
    if (chartDataState.queueItems.length > 0) {
      return () => {
        chartDataDispatch({
          type: "RESET_STATE",
        });
        assetDispatch({
          type: "REPLACE_BUILDING_STATE",
          payload: {
            newState: initialBuildingStates,
          },
        });
        assetDispatch({
          type: "REPLACE_POINT_STATE",
          payload: {
            newState: initialPointStates,
          },
        });
        assetDispatch({
          type: "REPLACE_GROUP_STATE",
          payload: {
            newState: initialGroupStates,
          },
        });
        if (currentOrganizationId) {
          navigate(`/explorer/${currentOrganizationId}`);
        }
      };
    }
    return undefined;
  };

  /* =============================================================== *\
     ASSET SELECTOR HIDE SHOW STUFF
  \* =============================================================== */
  const showAssetPickerDefault =
    // @ts-ignore
    location?.state?.showAssetPicker ?? window.innerWidth > 600; // only show when not in mobile view and not navigated to

  const [showAssetPicker, setShowAssetPicker] = useState(
    showAssetPickerDefault,
  );
  const toggleAssetPicker = () => setShowAssetPicker(!showAssetPicker);

  /* =============================================================== *\
       EFFECT THAT STOPS QUEUE ON UNMOUNT AND ENQUEUES THINGS FROM 
       THE URL TO LOAD
    \* =============================================================== */
  useEffect(() => {
    if (location) {
      // enqueue items from URL
      const qItems = extractQueueItemsFromURL(location, timeRange, assetData);
      enqueueRequests(qItems);

      // Refreshes the URL based on items from the queue. This will eliminate any
      // assets from the URL that don't exist or the user doesn't have access to.
      location.search = "";
      qItems.forEach(item => {
        const asset: CommoditySelection = {
          assetId: item.assetId,
          assetType: item.assetType,
          assetName: item.assetName,
          commodity: item.commodity,
          timezone: item.timezone,
          queryId: item.queryId,
        };

        updateURL(AssetState.LOADING, asset);
      });
      if (isOverlayActive()) {
        fetchOverlayDataForQueueItems(qItems);
      }

      // Turn off the operating schedule overlay feature if there are more
      // than one building selected at default.
      const selectedBuildings = allSelectedParentBuildings(qItems, assetData);
      if (
        selectedBuildings.size > 1 ||
        qItems.some(item => item.assetType === AssetType.GROUP)
      ) {
        chartDataDispatch({
          type: "TOGGLE_OPERATING_SCHEDULE_OVERLAYS_FEATURE",
          payload: {
            enabled: false,
          },
        });
      }
    }

    return () => {
      // nuke the overlay timer if running
      if (overlayCheckTime.current !== null) {
        clearTimeout(overlayCheckTime.current);
      }
      // tell useCommodityDataFetching not to update state anymore
      unmount();
      // TODO: Cancel requests if possible
      requestQ.end();
    };
  }, []);

  /**
   * resets the state if user had an error.
   */
  const onErrorBoundaryReset = () => {
    const clr = clearAllHandler(); // this returns a function (or undefined)
    if (clr) {
      clr();
    }
    setOverlayState(initialOverlayState);
    setCompareData({
      comparePeriod: ComparePeriod.NONE,
      selectedDates: {
        from: new Date(secondaryTimeRange.startTime),
        to: new Date(secondaryTimeRange.endTime),
      },
    });
  };
  const noDataContent = () =>
    hasAssetSelections(assetState) ? (
      <CenteredLoadingIndicator />
    ) : (
      <MakeSelection />
    );

  /* =============================================================== *\
     RENDER SOMETHING ALREADY!

     // TODO: think about moving the "controls" (range, granularity)
     etc. up here and making the card dumber. Or maybe moving more 
     thing down into the card! There are far too many props here
     for this to be the bestest way to do this.
  \* =============================================================== */
  return (
    <SidebarPage>
      {/*<LatestSelectionContext.Provider value={latestSelected} />*/}
      <SideBarContainer>
        {!showAssetPicker && (
          <AppToolbar>
            <AppToolbarItem onClick={toggleAssetPicker}>
              <MxReactIcon
                Icon={MapPin}
                size="l"
                color={AppColors.neutral["light-navy-9"]}
              />
            </AppToolbarItem>
            <Body onClick={toggleAssetPicker}>
              <FormattedMessage id="assetSelector.title" />
            </Body>
          </AppToolbar>
        )}
        {showAssetPicker && (
          <MultiCommoditySelector
            buildingStates={assetState.buildingStates}
            pointStates={assetState.pointStates}
            groupStates={assetState.groupStates}
            selectionHandler={handleAssetClick}
            activeTimezone={timezone}
            data={assetData}
            closeHandler={toggleAssetPicker}
            initialSelectedAssets={initialSelectedAssets}
            clearSelectionsHandler={clearAllHandler()}
          />
        )}
      </SideBarContainer>
      <SidebarPageContentArea>
        {!timezone || chartDataState.queueItems.length < 1 ? (
          noDataContent()
        ) : (
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={onErrorBoundaryReset}
          >
            <ExplorerCard
              timezone={timezone}
              timeRange={timeRange}
              secondaryTimeRange={secondaryTimeRange}
              chartDataRange={chartDataRange}
              chartDataState={chartDataState}
              granularity={granularity}
              compareToPast={compareToPast}
              compareType={compareData.comparePeriod}
              handleRangeAccept={handleRangeAccept}
              handleGranularityChange={handleGranularityChange}
              handleLegendItemClick={handleLegendItemClick}
              handleCompareChange={handleCompareRangeChange}
              handleCompareCancel={turnOffCompare}
              zoomFunctions={{
                in: zoomIn,
                out: zoomOut,
              }}
              userMode={userMode}
              setUserMode={setUserMode}
              overlayProps={{
                overlayState,
                setOverlayState,
              }}
              latitude={latitude}
              longitude={longitude}
              chartDataDispatch={chartDataDispatch}
            />
          </ErrorBoundary>
        )}
      </SidebarPageContentArea>
    </SidebarPage>
  );
};
export default ExplorerPage;
