import { EquipmentPoint, Maybe, Point, PointDiagnostics } from "../../../../types/graphql";
import ReactTooltip from "react-tooltip";
import { tracking } from "../../../../tracking";
import { Box, VerticalLayout } from "../../../common/Layout";
import { InfoText2 } from "src/components/common/Typography";
import styled from "styled-components/macro";
import { PageErrorBoundary } from "../../../common/ApplicationError/PageErrorBoundary";
import { FloatingAdminToolsEvents } from "../FloatingAdminToolsEvents";

const ToolTipContent = styled(Box)`
  td {
    padding-left: 8px;
  }

  dt {
    float: left;
    margin-right: 4px;
  }
`;

const PointDiagnosticsAttributes = (props: {
  diagnostics?: Maybe<PointDiagnostics>;
}) => {
  const { diagnostics } = props;
  return diagnostics ? (
    <VerticalLayout childSpacing={4}>
      <dl>
        <dt>
          <InfoText2>Non-Zero:</InfoText2>
        </dt>
        <dl>
          <InfoText2>{diagnostics?.lastNonZeroTime || ""}</InfoText2>
        </dl>
        <dt>
          <InfoText2>Defined: </InfoText2>
        </dt>
        <dl>
          <InfoText2>{diagnostics?.lastDefinedTime || ""}</InfoText2>
        </dl>
        <dt>
          <InfoText2>Received: </InfoText2>
        </dt>
        <dl>
          <InfoText2>{diagnostics?.lastReceiveTime || ""}</InfoText2>
        </dl>
        <dt>
          <InfoText2>Updated: </InfoText2>
        </dt>
        <dl>
          <InfoText2>{diagnostics?.lastUpdateTime || ""}</InfoText2>
        </dl>
      </dl>
    </VerticalLayout>
  ) : (
    <div/>
  );
};

export const PointsToolTip = (points: Array<Maybe<{ __typename?: string }
  & Pick<EquipmentPoint | Point, "id" | "name" | "customerPointId" | "ingestPointId">
  & {
  diagnostics?: Maybe<{ __typename?: "PointDiagnostics" }
    & Pick<PointDiagnostics, "lastDefinedTime" | "lastNonZeroTime" | "lastReceiveTime" | "lastUpdateTime">>
}>>) => {

  return <PageErrorBoundary><ReactTooltip
    id="point-tooltip"
    globalEventOff="scroll mousewheel"
    delayHide={100}
    type="light"
    clickable={true}
    border={true}
    effect="solid"
    afterShow={() => {
      tracking.fireEvent(FloatingAdminToolsEvents.POINT_HOVER, {
        pointCount: points.length,
      });
    }}
    getContent={content => {
      if (!content) {
        return;
      }

      // TODO: currently this could be an equipment point, but since they share
      //  mostly common fields (even if they don't actually share a common interface)
      //  this works. That could change if the point types diverge so this is fragile

      const point: Point = JSON.parse(content);

      const { lowerSpikeThreshold, spikeThreshold } = point as Point;
      return (
        <ToolTipContent>
          <VerticalLayout
            key={`point-tooltip-content`}
            childSpacing={2}
            style={{
              overflowY: "auto",
              overflowX: "clip",
              maxHeight: "180px",
              width: "250px",
            }}
          >
            <VerticalLayout childSpacing={4}>
              <dl>
                <dt>
                  <InfoText2>Lower Threshold: </InfoText2>
                </dt>
                <dl>
                  <InfoText2>
                    {lowerSpikeThreshold || "default"}
                  </InfoText2>
                </dl>
                <dt>
                  <InfoText2>Upper Threshold: </InfoText2>
                </dt>
                <dl>
                  <InfoText2>{spikeThreshold || "default"}</InfoText2>
                </dl>
              </dl>
            </VerticalLayout>
            <PointDiagnosticsAttributes diagnostics={point.diagnostics}/>
          </VerticalLayout>
        </ToolTipContent>
      );
    }}
  /></PageErrorBoundary>;
};
