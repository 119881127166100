import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { ModalRow, TintedBGModal } from "src/components/common/ModalParts";
import { SmallSpindicator } from "src/components/common/LoadingIndicator";
import { ButtonContainer } from "src/components/common/Button";
import { useMutation } from "@apollo/client";
import { ErrorToast, SuccessToast } from "src/components/common/Toast";
import { Body } from "src/components/common/Typography";
import { tracking } from "src/tracking";
import { MxSecondaryReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";
import { OverlayFormAction } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { useUserContext } from "src/auth/UserContext";
import { ChartDataAction } from "src/components/app/ExplorerPage/chartDataReducer";
import { DELETE_SCHEDULE_EXCEPTION } from "src/components/app/BuildingSettingsPage/queries";

type DeleteScheduleExceptionModalProps = {
  exception: {
    id: string;
    title: string;
  };
  onClose: () => void;
  buildingId: string;
  dispatch: (arg0: OverlayFormAction) => void;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
};

export const DeleteScheduleExceptionModal: React.FC<DeleteScheduleExceptionModalProps> = ({
  exception,
  buildingId,
  onClose,
  dispatch,
  chartDataDispatch,
}) => {
  const { userData } = useUserContext();
  const userId = userData.id;

  const cancelClick = () => {
    onClose();
  };

  const deleteClick = () => {
    deleteScheduleException({
      variables: {
        id: exception.id,
      },
    });

    dispatch({
      type: "CLOSE_OVERLAY",
      payload: {
        userId,
      },
    });

    dispatch({ type: "RESET_OVERLAY_PANEL" });
  };

  const [deleteScheduleException, { loading }] = useMutation(
    DELETE_SCHEDULE_EXCEPTION,
    {
      onError: () => {
        ErrorToast("charts.explorer.overlays.deleteScheduleException.error");
      },
      onCompleted: () => {
        tracking.fireEvent("ExplorerDeleteScheduleException", { buildingId });
        SuccessToast(
          "charts.explorer.overlays.deleteScheduleException.success",
        );
        chartDataDispatch({
          type: "REMOVE_OVERLAY_EVENT_FROM_CHART",
          payload: {
            deletedOverlayId: exception.id,
          },
        });
        onClose();
      },
    },
  );

  return (
    <TintedBGModal>
      <ModalContent>
        <ModalRow>
          <Body>
            <FormattedMessage
              id="settings.building.operatingScheduleExceptions.modal.delete.confirm"
              data-testid="confirm-text"
              values={{ exceptionTitle: exception.title }}
            />
          </Body>
        </ModalRow>

        <ModalRow>
          <FormattedMessage
            id="charts.explorer.overlays.modal.delete.message"
            tagName="div"
            values={{
              span: chunks => <span style={{ fontWeight: 500 }}>{chunks}</span>,
            }}
          />
        </ModalRow>

        <ModalRow>
          <ButtonContainer>
            {loading && <SmallSpindicator />}
            <MxSecondaryReactButton
              onClick={cancelClick}
              disabled={loading}
              intlTextId="common.button.labels.cancel"
            />
            <MxSecondaryReactButton
              onClick={deleteClick}
              disabled={loading}
              data-testid="delete-button"
              intlTextId="common.button.labels.delete"
              color={"danger"}
            />
          </ButtonContainer>
        </ModalRow>
      </ModalContent>
    </TintedBGModal>
  );
};

const ModalContent = styled.div`
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;
