import React, { SetStateAction, useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateTime } from "luxon";
import { VariablesOf } from "@graphql-typed-document-node/core";
import { useMutation, useApolloClient } from "@apollo/client";

import { getLocalDateFormat } from "src/helpers/dates";
import { dateToLocaleString } from "@hatchdata/intl-formatter";
import TimePicker from "src/components/common/TimePicker";
import { ChartDataAction } from "../../chartDataReducer";
import {
  OverlayFormData,
  OverlayFormAction,
  OverlayType,
} from "../overlayReducer";
import { ITimeRange } from "../../../../../types/charting";
import CreateProgress from "./CreateProgress";
import { FormattedMessage } from "react-intl";
import {
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import { BodyLabel } from "src/components/common/BodyCopy";
import DatePickerOverlay, {
  CalendarIcon,
  NewDatePickerInputStyles,
} from "src/components/common/TimeRangePicker/DatePickerOverlay";
import {
  BuildingAnnotationReason,
  BuildingAnnotationType,
} from "src/types/graphql";
import {
  ExplorerCreateBuildingAnnotationDocument,
  LoadAnnotationsForBuildingInExplorerDocument,
} from "src/queries/typed";
import { ErrorToast, SuccessToast } from "src/components/common/Toast";
import { useUserContext } from "src/auth/UserContext";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import { tracking } from "src/tracking";
import {
  BuildingAnnotationsResponse,
  BuildingAnnotation,
  OverlayEventState,
} from "src/components/app/ExplorerPage/types";
import {
  MxPrimaryReactButton,
  MxSecondaryReactButton,
} from "src/componentLibrary/react/mx-button/MxReactButton";

type AnnotationMutationVariables = VariablesOf<
  typeof ExplorerCreateBuildingAnnotationDocument
>;
/*
  FORM DATA HAS:
   startDate: date ? new Date(date) : null - a Date (boo, hiss)
      startTime: number || null -- an HOUR value
      endTime: number || null -- an HOUR value

  */
const ScheduleStepThreeAnnotations = (props: {
  dispatch: (arg0: OverlayFormAction) => void;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
  state: { formData: OverlayFormData };
  timeRange: ITimeRange;
  overlayProps: {
    overlayState: OverlayEventState;
    setOverlayState: React.Dispatch<SetStateAction<OverlayEventState>>;
  };
}) => {
  function createDefaultStartDateAndTime() {
    const hourToUse = props.state.formData.startTime ?? 8;
    if (props.state.formData.startDate !== null) {
      return DateTime.fromJSDate(props.state.formData.startDate).set({
        hour: hourToUse,
      });
    }
    return DateTime.local()
      .startOf("day")
      .set({ hour: hourToUse });
  }
  function createDefaultEndDateAndTime() {
    const hourToUse = props.state.formData.endTime ?? 17;

    // use the end date if provided, otherwise default to start date
    const endDate =
      props.state.formData.endDate ?? props.state.formData.startDate;
    if (endDate) {
      return DateTime.fromJSDate(endDate).set({
        hour: hourToUse,
      });
    }
    return DateTime.local()
      .startOf("day")
      .set({ hour: hourToUse });
  }

  const apolloClient = useApolloClient();
  const { userData } = useUserContext();
  const userId = userData.id;
  const [createAnnotation, { loading }] = useMutation(
    ExplorerCreateBuildingAnnotationDocument,
    {
      onError: e => {
        console.error(e.message, e);
        ErrorToast("charts.explorer.overlays.createAnnotation.error");
      },
      onCompleted: response => {
        if (response.createBuildingAnnotation.annotation) {
          tracking.fireEvent(ExplorerPageEvents.COMPLETE_ANNOTATION_CREATE, {
            userId,
            buildingID: props.state.formData.buildingId,
            type: props.state.formData.overlayType,
          });
          // hooray
          SuccessToast("charts.explorer.overlays.createAnnotation.success");
          fetchAnnotations(
            props.state.formData.buildingId!,
            mapOverlayTypeToAnnotationType(props.state.formData.overlayType!),
            props.state.formData.timezone!,
          );
        } else {
          console.error(response);
          ErrorToast("charts.explorer.overlays.createAnnotation.error");
        }
      },
    },
  );
  const fetchAnnotations = async (
    buildingId: string,
    type: BuildingAnnotationType,
    tz: string | undefined,
  ) => {
    const { data, errors } = await apolloClient.query({
      query: LoadAnnotationsForBuildingInExplorerDocument,
      variables: {
        buildingId,
      },
      fetchPolicy: "network-only", // forces the data to get refreshed
    });

    if (errors) {
      console.error(
        "failed to receive annotations for building %s",
        buildingId,
      );
      return;
    } else if (data.getBuildingById) {
      const dataByType: BuildingAnnotationsResponse = {
        getBuildingById: {
          ...data.getBuildingById,
          annotations: (data.getBuildingById
            ?.annotations as BuildingAnnotation[]).filter(a => {
            return a.type === type;
          }),
        },
      };

      props.chartDataDispatch({
        type: "RECEIVE_ANNOTATION_EVENT_DATA",
        payload: {
          response: dataByType,
          timezone: tz,
          timeRange: props.timeRange,
        },
      });
    }
  };

  // using react-hook-form would be a horrible pain because of TimePicker
  // so we have this slightly less horrible pain.
  const [localStartDate, setLocalStartDate] = useState<DateTime>(
    createDefaultStartDateAndTime(),
  );
  const [localEndDate, setLocalEndDate] = useState<DateTime>(
    createDefaultEndDateAndTime(),
  );
  const [errors, setErrors] = useState(false);

  function mapOverlayTypeToAnnotationType(
    b: OverlayType,
  ): BuildingAnnotationType {
    switch (b) {
      case OverlayType.HighUsage:
        return BuildingAnnotationType.HIGH_USAGE;
      case OverlayType.PeakDemand:
        return BuildingAnnotationType.PEAK_DEMAND;
      default:
        return BuildingAnnotationType.CUSTOM;
    }
  }
  function mapFormDataToGraphQLData() {
    const tz = props.state.formData.timezone;
    if (
      props.state.formData.annotationCategories.length > 0 &&
      props.state.formData.overlayType !== null
    ) {
      const vars: AnnotationMutationVariables = {
        buildingId: props.state.formData.buildingId ?? "",
        endTime: localEndDate
          .setZone(tz, { keepLocalTime: true })
          .toUTC()
          .toISO() as string,
        notes: props.state.formData.note,
        reasons: props.state.formData.annotationCategories,
        startTime: localStartDate
          .setZone(tz, { keepLocalTime: true })
          .toUTC()
          .toISO() as string,
        title: props.state.formData.title,
        type: mapOverlayTypeToAnnotationType(props.state.formData.overlayType),
      };
      return vars;
    }
    return false;
  }
  function handleNextStep() {
    const vars = mapFormDataToGraphQLData();
    if (vars !== false) {
      props.dispatch({
        type: "SAVE_STEP_THREE",
      });
      createAnnotation({
        variables: vars,
      });

      props.dispatch({ type: "RESET_OVERLAY_PANEL" });

      props.overlayProps.setOverlayState({
        ...props.overlayProps.overlayState,
        CUSTOM: true,
        PEAK_DEMAND: true,
        HIGH_USAGE: true,
      });
    } else {
      ErrorToast("charts.explorer.overlays.createAnnotation.error");
    }
  }
  function handleBackButton() {
    props.dispatch({ type: "GO_STEP_TWO" });
  }
  // COPY AND PASTE ZONE!!! :lolsob:
  const dateFormat = getLocalDateFormat();
  const isHoliday = props.state.formData?.annotationCategories.includes(
    BuildingAnnotationReason.HOLIDAY,
  );
  const parseDate = (input: string) => {
    const date = DateTime.fromFormat(input, dateFormat);

    return date.isValid ? date.toJSDate() : undefined;
  };
  const formatDate = (date: Date) => {
    return dateToLocaleString(date);
  };
  // END COPY AND PASTE ZONE

  function handleStartDateChange(d: Date) {
    if (DateTime.fromJSDate(d).isValid) {
      setLocalStartDate(DateTime.fromJSDate(d));
    }
  }
  function handleEndDateChange(d: Date) {
    if (DateTime.fromJSDate(d).isValid) {
      setLocalEndDate(DateTime.fromJSDate(d));
    }
  }
  function handleStartTimeChange(d: Date | null) {
    // just this just set the time on the existing date?
    if (d !== null) {
      // see if this is legal?
      setLocalStartDate(DateTime.fromJSDate(d));
    }
  }
  function handleEndTimeChange(d: Date | null) {
    // just this just set the time on the existing date?
    if (d !== null) {
      // see if this is legal?
      const newEnd = DateTime.fromJSDate(d);
      if (newEnd > localStartDate) {
        setLocalEndDate(newEnd);
      }
    }
  }

  function validateStepThree() {
    if (localEndDate <= localStartDate) {
      setErrors(true);
      return false;
    }
    setErrors(false);
    return true;
  }

  return (
    <>
      <CreateProgress step={3} />
      <NewDatePickerInputStyles />
      <Box margin={[6, 0, 8, 0]}>
        <VerticalLayout childSpacing={4}>
          {/* THE FIRST DATE ROW (start date and time) */}
          <HorizontalLayout childSpacing={2}>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="startDate">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.startDate" />
              </BodyLabel>
              <HorizontalLayout>
                <CalendarIcon />
                <DayPickerInput
                  inputProps={{
                    disabled: isHoliday,
                    required: true,
                    id: "startDate",
                    className: errors ? "invalid" : "",
                    onChange: (e: Event) =>
                      handleStartDateChange(
                        new Date(
                          (e?.currentTarget as HTMLInputElement).value ?? "",
                        ),
                      ),
                  }}
                  dayPickerProps={{
                    modifiers: {
                      disabled: { after: localEndDate.toJSDate() },
                    },
                  }}
                  placeholder={dateFormat}
                  parseDate={parseDate}
                  formatDate={formatDate}
                  overlayComponent={DatePickerOverlay}
                  onDayChange={handleStartDateChange}
                  value={localStartDate.toJSDate()}
                />
              </HorizontalLayout>
            </VerticalLayout>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="startTime">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.startTime" />
              </BodyLabel>
              <TimePicker
                disabled={isHoliday}
                handleOnChange={handleStartTimeChange}
                keyName="startTime"
                defaultTime={localStartDate.toJSDate()}
              />
            </VerticalLayout>
          </HorizontalLayout>
          {/* THE SECOND DATE ROW (start date and time) */}
          <HorizontalLayout childSpacing={2}>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="endDate">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.endDate" />
              </BodyLabel>
              <HorizontalLayout>
                <CalendarIcon />
                <DayPickerInput
                  inputProps={{
                    disabled: isHoliday,
                    required: true,
                    id: "endDate",
                    className: errors ? "invalid" : "",
                    onChange: (e: Event) =>
                      handleEndDateChange(
                        new Date(
                          (e?.currentTarget as HTMLInputElement).value ?? "",
                        ),
                      ),
                  }}
                  dayPickerProps={{
                    modifiers: {
                      disabled: { before: localStartDate.toJSDate() },
                    },
                  }}
                  placeholder={dateFormat}
                  parseDate={parseDate}
                  formatDate={formatDate}
                  overlayComponent={DatePickerOverlay}
                  value={localEndDate.toJSDate()}
                  onDayChange={handleEndDateChange}
                />
              </HorizontalLayout>
            </VerticalLayout>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="endTime">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.endTime" />
              </BodyLabel>
              <TimePicker
                disabled={isHoliday}
                keyName="endTime"
                handleOnChange={handleEndTimeChange}
                defaultTime={localEndDate.toJSDate()}
              />
            </VerticalLayout>
          </HorizontalLayout>
        </VerticalLayout>
      </Box>
      {/* THE NEXT AND BACK BUTTONS */}
      <HorizontalLayout childSpacing={2}>
        <MxSecondaryReactButton
          fullWidthOfParent
          onClick={handleBackButton}
          disabled={loading}
          intlTextId="common.button.labels.back"
        />
        <MxPrimaryReactButton
          fullWidthOfParent
          onClick={handleNextStep}
          disabled={errors || loading}
          intlTextId="common.button.labels.save"
        />
      </HorizontalLayout>
    </>
  );
};

export default ScheduleStepThreeAnnotations;
