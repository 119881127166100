import { ResultOf } from "@graphql-typed-document-node/core";
import { DateTime } from "luxon";
import { exceptionTimeBlockExtendsIntoNextDay } from "src/components/app/BuildingSettingsPage/helpers";
import { AppColors } from "src/components/common/Styling";
import { LoadOperatingScheduleForBuildingDocument } from "src/queries/typed";
import { ITimeRange } from "src/types/charting";
import { BuildingAnnotationType } from "src/types/graphql";
import {
  AnnotationEventType,
  AnnotationsBuilding,
  Measure,
  MeasureBuilding,
  MeasureEvent,
  MeasureEventType,
  OverlayEvents,
  OverlayEventType,
  OverlayTimeSpans,
  ScheduleEventType,
  ScheduleException,
  ScheduleExceptionBuilding,
} from "../types";

const mapMeasureToExplorerMeasureEvent = (
  measure: Measure,
  building: MeasureBuilding,
  eventType: MeasureEventType,
): MeasureEvent => {
  return {
    eventType,
    name: measure.name,
    id: measure.id,
    buildingId: measure.asset.id,
    friendlyId: measure.friendlyId,
    avoidableCost: measure.avoidableCost,
    found: measure.found,
    implemented: measure.implemented,
    buildingName: building.name,
  };
};

/**
 * Returns the date associated with the type of event on
 * a measure or undefined if it does not exist.
 * @param measure
 * @param eventType
 * @returns the date associated with the type of event on
 * a measure or undefined if it does not exist
 */
const dateOfMeasureEvent = (
  measure: Measure,
  eventType: MeasureEventType,
): Date | undefined | null => {
  switch (eventType) {
    case MeasureEventType.Implemented:
      return measure.implemented ? new Date(measure.implemented) : null;
    case MeasureEventType.Identified:
      return measure.found ? new Date(measure.found) : null;
    default:
      return undefined;
  }
};

/**
 * Returns an array of measure events whose found or implemented date occurs
 * within the time range provided. All dates are converted to the start of the
 * day for the timezone. If both a measure's found and implemented dates are
 * on the same day, two events will be returned.
 * @param measures an array of measures.
 * @param timeRange the start and end dates the measures should occur within
 * @param timezone the timezone to base all dates off of
 * @returns an array of measure events whose found or implemented date occurs
 * within the time range provided. All dates are converted to the start of the
 * day for the timezone.
 */
export const addMeasureEventsForDateRange = (
  building: MeasureBuilding,
  timeRange: ITimeRange,
  timezone: string = "UTC",
  eventMap: OverlayEvents = new Map(),
): OverlayEvents => {
  const startTime = DateTime.fromJSDate(new Date(timeRange.startTime), {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();
  const endTime = DateTime.fromJSDate(new Date(timeRange.endTime), {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  const addEventIfWithinRange = (
    measure: Measure,
    building: MeasureBuilding,
    eventType: MeasureEventType,
  ): void => {
    const date = dateOfMeasureEvent(measure, eventType);
    if (!date) {
      return;
    }

    const startOfDay = DateTime.fromJSDate(date, {
      zone: timezone,
    })
      .startOf("day")
      .toMillis();

    if (startOfDay >= startTime && startOfDay < endTime) {
      if (!eventMap.has(startOfDay)) {
        eventMap.set(startOfDay, []);
      }

      const measureEvent = mapMeasureToExplorerMeasureEvent(
        measure,
        building,
        eventType,
      );

      const events = eventMap.get(startOfDay);
      // only push if it doesn't already exist
      if (
        !events?.find(
          event => event.id === measure.id && event.eventType === eventType,
        )
      ) {
        eventMap.get(startOfDay)?.push(measureEvent);
      }
    }
  };

  building.measures.forEach(measure => {
    addEventIfWithinRange(measure, building, MeasureEventType.Identified);
    addEventIfWithinRange(measure, building, MeasureEventType.Implemented);
  });

  return eventMap;
};

/**
 * Adds building schedule exceptions to the `eventMap` passed into the function. Any
 * exceptions that fall within the `timeRange` based on the `timezone` are added.
 * The keys are based on the start date and time of the exception. If no time is present,
 * the assumption is the exception lasts all day.
 * @param building a building containing an array of schedule exceptions
 * @param timeRange a start and end time for the exceptions to occur between
 * @param timezone the timezone associated with the building
 * @param eventMap a map containing a date/time in ms as a key with an
 * array of schedule exceptions as the values
 * @returns an updated `eventMap` containing schedule exceptions found
 * on the building
 */
export const addScheduleExceptionsForDateRange = (
  building: ScheduleExceptionBuilding,
  timeRange: ITimeRange,
  timezone: string = "UTC",
  eventMap: OverlayEvents = new Map(),
): OverlayEvents => {
  const startTime = DateTime.fromISO(timeRange.startTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  const endTime = DateTime.fromISO(timeRange.endTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  building.scheduleExceptions.forEach(exception => {
    const { startHour, startMinute } = startTimeFromException(exception);

    let exceptionDateStart = DateTime.fromJSDate(
      new Date(exception.exceptionDate),
      {
        zone: timezone,
      },
    )
      .startOf("day")
      .set({
        hour: startHour,
        minute: startMinute,
      })
      .toMillis();

    if (exceptionDateStart >= startTime && exceptionDateStart < endTime) {
      const key = generateXAxisValueFromEventStartTime(
        DateTime.fromMillis(exceptionDateStart),
      );

      if (!eventMap.has(key)) {
        eventMap.set(key, []);
      }

      const events = eventMap.get(key);
      if (!events?.find(event => event.id === exception.id)) {
        eventMap.get(key)?.push({
          timeBlock: exception.timeBlock,
          name: exception.title,
          id: exception.id,
          exceptionDate: exception.exceptionDate,
          exceptionEndDate: exception.exceptionEndDate,
          buildingId: building.id,
          buildingName: building.name,
          eventType: ScheduleEventType.ScheduleExceptions,
          reasons: exception.reasons,
          notes: exception.notes ?? "",
          exceptionTimeRanges: exception.exceptionTimeRanges ?? [],
          xAxisStart: key,
        });
      }
    }
  });

  return eventMap;
};

/**
 * Returns the start hour and minute for a schedule exception. The new
 * workflow uses the `exceptionTimeRange` object. If it doesn't exist,
 * we fall back to the old `timeBlock`. If there are no values for either,
 * it's assumed the building is running for 24 hours and "0" is returned
 * for each value.
 * @param exception
 * @returns the start hour and minute of an exception
 */
const startTimeFromException = (
  exception: ScheduleException,
): { startHour: number; startMinute: number } => {
  let startHour = 0,
    startMinute = 0;

  if (
    exception.exceptionTimeRanges &&
    exception.exceptionTimeRanges.length > 0
  ) {
    startHour = exception.exceptionTimeRanges[0].startHour;
    startMinute = exception.exceptionTimeRanges[0].startMinute;
  } else if (exception.timeBlock) {
    startHour = exception.timeBlock.startHour;
    startMinute = exception.timeBlock.startMinute;
  }

  return {
    startHour,
    startMinute,
  };
};

/**
 * Returns an object containing exception time spans that fall within
 * the `timeRange` and `timezone` for a given building.
 *
 * TODO, maybe? The return value from this could eventually roll into
 * `addScheduleExceptionsForDateRange`. We'll have to see what other
 * kind of ranges get added to explorer to see if it makes sense.
 * @param building a building and its exceptions
 * @param timeRange a time period the exceptions must occur within
 * @param timezone timezone used for calculating date ranges
 * @param overlaySpans a map of existing ranges
 * @returns `overlaySpans` updated to include the exceptions within
 * the `timeRange`
 */
export const addExceptionTimeSpan = (
  building: ScheduleExceptionBuilding,
  timeRange: ITimeRange,
  timezone: string = "UTC",
  overlaySpans: OverlayTimeSpans = new Map(),
): OverlayTimeSpans => {
  const startTime = DateTime.fromISO(timeRange.startTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  const endTime = DateTime.fromISO(timeRange.endTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  building.scheduleExceptions.forEach(exception => {
    if (
      exception.exceptionTimeRanges &&
      exception.exceptionTimeRanges.length > 0
    ) {
      let exceptionStartTime = DateTime.fromJSDate(
        new Date(exception.exceptionDate),
        {
          zone: timezone,
        },
      )
        .startOf("day")
        .set({
          hour: exception.exceptionTimeRanges[0].startHour || 0, // no time block implies 24 hours
          minute: exception.exceptionTimeRanges[0].startMinute || 0, // no time block implies 24 hours
        })
        .toMillis();

      const daySpan = DateTime.fromISO(exception.exceptionEndDate)
        .diff(DateTime.fromISO(exception.exceptionDate))
        .as("days");

      /**
       * OK this is all confusing so I'm leaving this note so I (or whomever) working on this in the future
       * will understand WTF is going on. The end date is assumed to be exclusive so we actually want the
       * previous day when building the end time. HOWEVER, this technically doesn't apply in the case
       * of exceptions that were entered as being a single day exception, but the end time actually spills over
       * into the next day. In THAT case, we actually do want to use the end date and not the day prior. We
       * also want to use the end date if it's an exception that lasts all day.
       * */
      const endOffset =
        (daySpan === 1 &&
          exceptionTimeBlockExtendsIntoNextDay(
            exception.exceptionTimeRanges[0],
          )) ||
        (exception.exceptionTimeRanges[0].endHour === 0 &&
          exception.exceptionTimeRanges[0].endMinute === 0)
          ? 0
          : 1;

      let exceptionEndTime = DateTime.fromJSDate(
        new Date(exception.exceptionEndDate),
        {
          zone: timezone,
        },
      )
        .startOf("day")
        .set({
          hour: exception.exceptionTimeRanges[0].endHour || 0, // no time block implies 24 hours
          minute: exception.exceptionTimeRanges[0].endMinute || 0, // no time block implies 24 hours
        })
        .minus({
          days: endOffset,
        });

      if (exceptionStartTime >= startTime && exceptionStartTime < endTime) {
        const key = generateXAxisValueFromEventStartTime(
          DateTime.fromMillis(exceptionStartTime),
        );
        if (!overlaySpans.has(key)) {
          overlaySpans.set(key, []);
        }
        const spans = overlaySpans.get(key);
        if (!spans?.find(span => span.eventId === exception.id)) {
          overlaySpans.get(key)?.push({
            // xAxisStart is where it should render
            xAxisStart: key,
            eventId: exception.id,
            buildingId: building.id,
            startTime: DateTime.fromMillis(exceptionStartTime).toJSDate(),
            endTime: exceptionEndTime.toJSDate(),
            fillColor: AppColors.semantic.lightPurple["light-purple-1"],
            eventType: ScheduleEventType.ScheduleExceptions,
            visible: true,
          });
        }
      }
    }
  });

  return overlaySpans;
};

// TODO: This probably needs to call generateXAxisFromEventStartTime?
export const addOperatingScheduleSpans = (
  payload: ResultOf<typeof LoadOperatingScheduleForBuildingDocument>,
  overlaySpans: OverlayTimeSpans = new Map(),
) => {
  if (payload.getBuildingById?.buildingNonOperatingTimeRanges) {
    payload.getBuildingById?.buildingNonOperatingTimeRanges.forEach(
      timeRange => {
        const key = DateTime.fromISO(timeRange!.startTime!).toMillis();
        const span = overlaySpans.get(key);

        if (!span) {
          overlaySpans.set(key, []);
        }

        overlaySpans.get(key)?.push({
          eventType: ScheduleEventType.OperatingSchedule,
          startTime: new Date(timeRange!.startTime!),
          endTime: new Date(timeRange!.endTime!),
          buildingId: payload.getBuildingById!.id!,
          fillColor: "rgba(145,171,233,0.2)",
          xAxisStart: key,
          visible: true,
        });
      },
    );
  }

  return overlaySpans;
};

/**
 * Adds building annotations to the `eventMap` passed into the function. Any
 * annotations that fall within the `timeRange` based on the `timezone` are added.
 * The keys are based on the start date and time of the annotation.
 * @param building a building containing an array of annotations
 * @param timeRange a start and end time for the exceptions to occur between
 * @param timezone the timezone associated with the building
 * @param eventMap a map containing a date/time in ms as a key with an array of
 * schedule exceptions as the values
 * @returns an updated `eventMap` containing annotations found on the building
 */
export const addAnnotationsForDateRange = (
  building: AnnotationsBuilding,
  timeRange: ITimeRange,
  timezone: string = "UTC",
  eventMap: OverlayEvents = new Map(),
): OverlayEvents => {
  const startTime = DateTime.fromISO(timeRange.startTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  const endTime = DateTime.fromISO(timeRange.endTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  building.annotations.forEach(annotation => {
    const annotationStartDate = DateTime.fromJSDate(
      new Date(annotation.startTime),
      {
        zone: timezone,
      },
    );

    const annotationStartTime = annotationStartDate
      .startOf("day")
      .set({
        hour: annotationStartDate.hour,
        minute: annotationStartDate.minute,
      })
      .toMillis();

    const eventType = mapBuildingAnnotationTypeToAnnotationEventType(
      annotation.type,
    );

    if (annotationStartTime >= startTime && annotationStartTime < endTime) {
      const key = generateXAxisValueFromEventStartTime(
        DateTime.fromMillis(annotationStartTime),
      );

      if (!eventMap.has(key)) {
        eventMap.set(key, []);
      }

      const events = eventMap.get(key);
      if (!events?.find(event => event.id === annotation.id)) {
        eventMap.get(key)?.push({
          eventType,
          id: annotation.id,
          buildingId: building.id,
          buildingName: building.name,
          createdTime: annotation.createdTime,
          startTime: annotation.startTime,
          endTime: annotation.endTime,
          reasons: annotation.reasons,
          title: annotation.title,
          notes: annotation.notes,
          type: annotation.type,
          createdBy: {
            firstName: annotation.createdBy?.firstName,
            lastName: annotation.createdBy?.lastName,
          },
          xAxisStart: key,
        });
      }
    }
  });

  return eventMap;
};

/**
 * Returns an object containing annotation time spans that fall within
 * the `timeRange` and `timezone` for a given building.
 *
 * @param building a building and its annotations
 * @param timeRange a time period the annotations must occur within
 * @param timezone timezone used for calculating date ranges
 * @param overlaySpans a map of existing ranges
 * @returns `overlaySpans` updated to include the annotations within
 * the `timeRange`
 */
export const addAnnotationsTimeSpan = (
  building: AnnotationsBuilding,
  timeRange: ITimeRange,
  timezone: string = "UTC",
  overlaySpans: OverlayTimeSpans = new Map(),
): OverlayTimeSpans => {
  const startTime = DateTime.fromISO(timeRange.startTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  const endTime = DateTime.fromISO(timeRange.endTime, {
    zone: timezone,
  })
    .startOf("day")
    .toMillis();

  building.annotations.forEach(annotation => {
    const annotationStartDate = DateTime.fromJSDate(
      new Date(annotation.startTime),
      {
        zone: timezone,
      },
    );

    const annotationEndDate = DateTime.fromJSDate(
      new Date(annotation.endTime),
      {
        zone: timezone,
      },
    );

    const annotationStartTime = annotationStartDate
      .startOf("day")
      .set({
        hour: annotationStartDate.hour,
        minute: annotationStartDate.minute,
      })
      .toMillis();

    const annotationEndTime = annotationEndDate
      .startOf("day")
      .set({
        hour: annotationEndDate.hour,
        minute: annotationEndDate.minute,
      })
      .toMillis();

    const eventType: OverlayEventType = (() => {
      switch (building.annotations[0].type) {
        case BuildingAnnotationType.PEAK_DEMAND:
          return AnnotationEventType.PeakDemand;
        case BuildingAnnotationType.HIGH_USAGE:
          return AnnotationEventType.HighUsage;
        default:
          return AnnotationEventType.Custom;
      }
    })();

    if (annotationStartTime >= startTime && annotationStartTime < endTime) {
      const key = generateXAxisValueFromEventStartTime(annotationStartDate);

      if (!overlaySpans.has(key)) {
        overlaySpans.set(key, []);
      }

      const spans = overlaySpans.get(key);
      if (!spans?.find(span => span.eventId === annotation.id)) {
        spans?.push({
          // key will be generated, but startTime/endTime will retain original time
          xAxisStart: key,
          eventId: annotation.id,
          buildingId: building.id,
          startTime: DateTime.fromMillis(annotationStartTime).toJSDate(),
          endTime: DateTime.fromMillis(annotationEndTime).toJSDate(),
          fillColor: AppColors.semantic.yellow["light-yellow-4"],
          eventType: eventType,
          visible: true,
        });
      }
    }
  });
  return overlaySpans;
};

/**
 * This is a very simple function but it encapsulates how we determine where on
 * the x axis the overlay should start. This allows us to better consolidate
 * overlapping events.
 * Currently it has been decided that the start of the overlay should be the
 * timestamp at the start of the hour, rather than the exact start timestamp.
 */
const generateXAxisValueFromEventStartTime = (eventStartDate: DateTime) => {
  return eventStartDate.startOf("hour").toMillis();
};

/**
 * Called to remove all events for a given asset ID
 *
 * @param events - existing measure events.
 * @param assetId - asset ID you wish events to be removed for
 * @returns - a new copy of the measure events with any related to that asset id removed.
 */
export const removeEventsForAssetId = <T>(
  events: Map<number, (T & { buildingId: string })[]>,
  assetId: string,
): Map<number, T[]> => {
  const clonedEvents = new Map();
  events.forEach((value, key) => {
    const remaining = value.filter(e => e.buildingId !== assetId);
    if (remaining.length > 0) {
      clonedEvents.set(key, remaining);
    }
  });
  return clonedEvents;
};

/**
 * Called to remove a specified event given the event ID
 *
 * @param events - existing overlay events.
 * @param removedEventId - specific event you wish to remove.
 * @returns - a new copy of the overlay events with the specified event removed.
 */
export const removeOverlayEventByEventId = (
  events: OverlayEvents,
  removedEventId: string,
): OverlayEvents => {
  const clonedEvents: OverlayEvents = new Map();
  // Yep, there's a reason it has to be done this way. events has an array of stuff for each key
  // and you need to find the one corresponding to the event ID you want to remove, then you replace
  // the key with the remainder of the array, or nothing at all if it becomes empty.
  events.forEach((value, key) => {
    const remaining = value.filter(e => e.id !== removedEventId);
    if (remaining.length > 0) {
      clonedEvents.set(key, remaining);
    }
  });
  return clonedEvents;
};

/**
 * Removes span for the specified event ID from the overlaySpans Map
 * and returns a new Map without the removed span
 *
 * @param overlaySpans The list of overlay spans
 * @param eventIdToRemove The event ID of the span to remove
 */
export const removeOverlaySpanByEventId = (
  overlaySpans: OverlayTimeSpans,
  eventIdToRemove: string,
): OverlayTimeSpans => {
  const clonedSpans: OverlayTimeSpans = new Map();
  // Yep, there's a reason it has to be done this way. overlaySpans has an array of stuff for each key
  // and you need to find the one corresponding to the event ID you want to remove, then you replace
  // the key with the remainder of the array, or nothing at all if it becomes empty.
  overlaySpans.forEach((value, key) => {
    const remaining = value.filter(s => s.eventId !== eventIdToRemove);
    if (remaining.length > 0) {
      clonedSpans.set(key, remaining);
    }
  });
  return clonedSpans;
};

/**
 * Called to remove all events for a given asset ID
 *
 * @param events - existing measure events.
 * @param assetId - asset ID you wish events to be removed for
 * @returns - a new copy of the measure events with any related to that asset id removed.
 */
export const removeSpanForAssetId = <T>(
  spans: Map<number, (T & { buildingId: string })[]>,
  assetId: string,
): Map<number, T[]> => {
  const clonedEvents = new Map();
  spans.forEach((value, key) => {
    const remaining = value.filter(e => e.buildingId !== assetId);
    if (remaining.length > 0) {
      clonedEvents.set(key, remaining);
    }
  });
  return clonedEvents;
};

export const mapBuildingAnnotationTypeToAnnotationEventType = (
  type: BuildingAnnotationType,
) => {
  switch (type) {
    case BuildingAnnotationType.PEAK_DEMAND:
      return AnnotationEventType.PeakDemand;
    case BuildingAnnotationType.HIGH_USAGE:
      return AnnotationEventType.HighUsage;
    case BuildingAnnotationType.CUSTOM:
      return AnnotationEventType.Custom;
  }
};
