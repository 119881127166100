import {
  DailyOperatingTimeBlock,
  OperatingSchedule,
  ScheduleException,
} from "src/types/schedules";

import React from "react";

import { FormattedMessage } from "react-intl";

import {
  daysOfWeekInLocaleOrder,
  isAllDay,
  timeExtendsIntoNextDay,
} from "./helpers";
import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";
import { dateToLocaleString, FormatOptions } from "@hatchdata/intl-formatter";

/**
 * Returns the hour and minute to the correct format for display and appends the timezone if supplied
 * @param hour
 * @param minute
 * @param timezone
 */
const formatTime = (
  hour: number,
  minute: number,
  timezone?: string,
): string => {
  const time = new Date(0, 0, 0, hour, minute);

  return `${dateToLocaleString(
    time,
    undefined,
    undefined,
    FormatOptions.TIME_SIMPLE,
  )} ${timezone || ""}`.trim();
};

const startTimesMatch = (
  schedule1: DailyOperatingTimeBlock | null,
  schedule2: DailyOperatingTimeBlock | null,
): boolean => {
  return (
    schedule1?.startHour === schedule2?.startHour &&
    schedule1?.startMinute === schedule2?.startMinute
  );
};

const endTimesMatch = (
  schedule1: DailyOperatingTimeBlock | null,
  schedule2: DailyOperatingTimeBlock | null,
): boolean => {
  return (
    schedule1?.endHour === schedule2?.endHour &&
    schedule1?.endMinute === schedule2?.endMinute
  );
};

export const formatExceptionTimeDisplay = (
  { exceptionTimeRanges, exceptionDate }: ScheduleException,
  schedule: OperatingSchedule,
) => {
  const dayOfWeek = new Date(exceptionDate).getDay();
  const scheduleForDay = schedule[daysOfWeekInLocaleOrder()[dayOfWeek]];

  if (exceptionTimeRanges) {
    if (
      isAllDay(exceptionTimeRanges[0]) &&
      exceptionTimeRanges[0].isOperating
    ) {
      return (
        <FormattedMessage id="settings.building.operatingSchedule.24Hour" />
      );
    }

    if (
      isAllDay(exceptionTimeRanges[0]) &&
      !exceptionTimeRanges[0].isOperating
    ) {
      return (
        <FormattedMessage id="settings.building.operatingSchedule.noHours" />
      );
    }

    const start = new Date(
      0,
      0,
      0,
      exceptionTimeRanges[0].startHour,
      exceptionTimeRanges[0].startMinute,
    );
    const end = new Date(
      0,
      0,
      0,
      exceptionTimeRanges[0].endHour,
      exceptionTimeRanges[0].endMinute,
    );
    return (
      <>
        <TimeText
          isDifferent={!startTimesMatch(exceptionTimeRanges[0], scheduleForDay)}
        >
          {formatTime(
            exceptionTimeRanges[0].startHour,
            exceptionTimeRanges[0].startMinute,
          )}
        </TimeText>{" "}
        -{" "}
        <TimeText
          isDifferent={!endTimesMatch(exceptionTimeRanges[0], scheduleForDay)}
        >
          {formatTime(
            exceptionTimeRanges[0].endHour,
            exceptionTimeRanges[0].endMinute,
          )}
        </TimeText>
        {timeExtendsIntoNextDay(start, end) && (
          <>
            <NextDayHighlight>+1</NextDayHighlight>
            <FormattedMessage id="settings.building.operatingSchedule.endTimeExtends" />
          </>
        )}
      </>
    );
  }

  return <FormattedMessage id="settings.building.operatingSchedule.noHours" />;
};

/**
 * Returns the operating hours text for a daily schedule and timezone
 * @param schedule
 * @param timezone
 */
export const formatTimeDisplay = (
  schedule: DailyOperatingTimeBlock | null | undefined,
  timezone: string,
  includeTimeExtension: boolean = true,
) => {
  if (schedule) {
    const start = new Date(0, 0, 0, schedule.startHour, schedule.startMinute);
    const end = new Date(0, 0, 0, schedule.endHour, schedule.endMinute);
    return (
      <React.Fragment>
        <FormattedMessage
          id="settings.building.operatingSchedule.operatingHours"
          values={{
            startTime: formatTime(schedule.startHour, schedule.startMinute),
            endTime: formatTime(schedule.endHour, schedule.endMinute, timezone),
          }}
        />

        {includeTimeExtension && timeExtendsIntoNextDay(start, end) && (
          <React.Fragment>
            <NextDayHighlight>+1</NextDayHighlight>
            <FormattedMessage id="settings.building.operatingSchedule.endTimeExtends" />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return <FormattedMessage id="settings.building.operatingSchedule.noHours" />;
};

const NextDayHighlight = styled.span`
  color: ${AppColors.semantic.red.red};
  padding-left: 10px;
  padding-right: 5px;
`;

const TimeText = styled.span<{ isDifferent: boolean }>`
  display: inline-block;
  margin-right: 4px;
  font-style: ${props => (props.isDifferent ? "italic" : "inherit")};
`;
