import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  MxReactIcon,
  ChevronDown,
  ChevronUp,
} from "src/componentLibrary/react/mx-icon-react";
import { InlineH4 } from "src/components/common/Typography";
import styled from "styled-components/macro";
import { AppColors } from "../Styling";

const InfoAccordionHeader = styled.div`
  min-height: 45px;
  background-color: ${AppColors.neutral.white};
  border-top: solid 1px ${AppColors.neutral["light-navy-9"]};
  border-bottom: solid 1px ${AppColors.neutral["light-navy-9"]};
  color: ${AppColors.neutral.navy};
  display: flex;
  align-items: center;
  justify-content: center;
`;
const InfoAccordionHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
`;
const InfoAccordionContent = styled.div`
  padding: 40px 30px;
  background-color: ${AppColors.neutral.white};
`;

const InfoAccordionNoPadding = styled.div`
  padding: 0;
  background-color: ${AppColors.neutral.white};
`;

interface IInfoAccordion {
  titleId: string;
  children: React.ReactNode;
  startOpen?: boolean;
  hasPadding?: boolean;
  onClick?: () => void;
}

const InfoAccordion: React.FC<IInfoAccordion> = ({
  titleId,
  children,
  startOpen = true,
  hasPadding = true,
  // This was added to facilitate mix panel events
  onClick = () => null,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    <div>
      <InfoAccordionHeader>
        <InfoAccordionHeaderContent
          onClick={() => {
            onClick();
            toggleOpen();
          }}
        >
          <InlineH4>
            <FormattedMessage id={titleId} />
          </InlineH4>
          <MxReactIcon
            Icon={isOpen ? ChevronUp : ChevronDown}
            size="s"
            color={AppColors.neutral.navy}
            style={{ marginLeft: "16px" }}
          />
        </InfoAccordionHeaderContent>
      </InfoAccordionHeader>
      {isOpen && hasPadding && (
        <InfoAccordionContent>{children}</InfoAccordionContent>
      )}
      {isOpen && !hasPadding && (
        <InfoAccordionNoPadding>{children}</InfoAccordionNoPadding>
      )}
    </div>
  );
};

export default InfoAccordion;
