import React, { useState } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateTime } from "luxon";
import { useApolloClient, useMutation } from "@apollo/client";

import { getLocalDateFormat } from "src/helpers/dates";
import { dateToLocaleString } from "@hatchdata/intl-formatter";
import TimePicker from "src/components/common/TimePicker";
import { ChartDataAction } from "../../chartDataReducer";
import { OverlayFormData, OverlayFormAction } from "../overlayReducer";
import { ITimeRange } from "../../../../../types/charting";
import { FormattedMessage } from "react-intl";
import {
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import { BodyLabel } from "src/components/common/BodyCopy";
import DatePickerOverlay, {
  CalendarIcon,
  NewDatePickerInputStyles,
} from "src/components/common/TimeRangePicker/DatePickerOverlay";
import {
  BuildingAnnotationReason,
  BuildingAnnotationType,
} from "src/types/graphql";
import {
  ExplorerCreateBuildingAnnotationDocument,
  ExplorerDeleteBuildingAnnotationDocument,
  LoadAnnotationsForBuildingInExplorerDocument,
} from "src/queries/typed";
import { ErrorToast, SuccessToast } from "src/components/common/Toast";
import { useUserContext } from "src/auth/UserContext";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import { tracking } from "src/tracking";
import {
  BuildingAnnotation,
  BuildingAnnotationsResponse,
} from "src/components/app/ExplorerPage/types";
import {
  MxPrimaryReactButton,
  MxSecondaryReactButton,
} from "src/componentLibrary/react/mx-button/MxReactButton";
import CreateProgress from "src/components/app/ExplorerPage/OverlayStuff/CreateOverlayStuff/CreateProgress";
import { mapFormDataToGraphQLVariablesForCreateAnnotation } from "src/components/app/ExplorerPage/OverlayStuff/EditOverlayStuff/MapFormDataToGraphQLVariablesForCreateAnnotation";

function createDefaultStartDateAndTime(input: OverlayFormData): Date {
  const hourToUse = input.startTime ?? 8;
  if (input.startDate !== null) {
    return DateTime.fromJSDate(input.startDate)
      .set({
        hour: hourToUse,
      })
      .toJSDate();
  }
  return DateTime.local()
    .startOf("day")
    .set({ hour: hourToUse })
    .toJSDate();
}

function createDefaultEndDateAndTime(input: OverlayFormData): Date {
  const hourToUse = input.endTime ?? 17;

  // use the end date if provided, otherwise default to start date
  const endDate = input.endDate ?? input.startDate;
  if (endDate) {
    return DateTime.fromJSDate(endDate)
      .set({
        hour: hourToUse,
      })
      .toJSDate();
  }
  return DateTime.local()
    .startOf("day")
    .set({ hour: hourToUse })
    .toJSDate();
}

const EditStepThreeAnnotations = (props: {
  dispatch: (arg0: OverlayFormAction) => void;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
  state: { formData: OverlayFormData };
  timeRange: ITimeRange;
}) => {
  const apolloClient = useApolloClient();
  const { userData } = useUserContext();
  const userId = userData.id;

  const [deleteAnnotation] = useMutation(
    ExplorerDeleteBuildingAnnotationDocument,
  );

  const [createAnnotation, { loading }] = useMutation(
    ExplorerCreateBuildingAnnotationDocument,
    {
      onError: e => {
        console.error(e.message, e);
        ErrorToast("charts.explorer.overlays.editAnnotation.error");
      },
      onCompleted: response => {
        if (response.createBuildingAnnotation.annotation) {
          tracking.fireEvent(ExplorerPageEvents.COMPLETE_ANNOTATION_UPDATE, {
            userId,
            buildingID: props.state.formData.buildingId,
            type: props.state.formData.overlayType,
          });
          // hooray
          SuccessToast("charts.explorer.overlays.editAnnotation.success");
          fetchAnnotations(
            props.state.formData.buildingId!,
            props.state.formData.annotationType!,
            props.state.formData.timezone!,
          );
        } else {
          console.error(response);
          ErrorToast("charts.explorer.overlays.createAnnotation.error");
        }
      },
    },
  );
  const fetchAnnotations = async (
    buildingId: string,
    type: BuildingAnnotationType,
    tz: string | undefined,
  ) => {
    const { data, errors } = await apolloClient.query({
      query: LoadAnnotationsForBuildingInExplorerDocument,
      variables: {
        buildingId,
      },
      fetchPolicy: "network-only", // forces the data to get refreshed
    });

    if (errors) {
      console.error(
        "failed to receive annotations for building %s",
        buildingId,
      );
      return;
    } else if (data.getBuildingById) {
      const dataByType: BuildingAnnotationsResponse = {
        getBuildingById: {
          ...data.getBuildingById,
          annotations: data.getBuildingById
            ?.annotations as BuildingAnnotation[],
        },
      };

      if (props.state.formData.overlayId) {
        props.chartDataDispatch({
          type: "REMOVE_OVERLAY_EVENT_FROM_CHART",
          payload: {
            deletedOverlayId: props.state.formData.overlayId,
          },
        });
      }

      props.chartDataDispatch({
        type: "RECEIVE_ANNOTATION_EVENT_DATA",
        payload: {
          response: dataByType,
          timezone: tz,
          timeRange: props.timeRange,
        },
      });
    }
  };

  // using react-hook-form would be a horrible pain because of TimePicker
  // so we have this slightly less horrible pain.
  const [localStartDate, setLocalStartDate] = useState<Date>(
    createDefaultStartDateAndTime(props.state.formData),
  );
  const [localEndDate, setLocalEndDate] = useState<Date>(
    createDefaultEndDateAndTime(props.state.formData),
  );
  const [errors, setErrors] = useState(false);

  function handleSaveStep() {
    // TODO: Figure out how to just send the updated formData in to this fn
    //   instead of passing the localStartDate and localEndDate state variables'
    //   current values
    const vars = mapFormDataToGraphQLVariablesForCreateAnnotation(
      { ...props.state.formData },
      localEndDate,
      localStartDate,
    );
    if (vars !== false) {
      props.dispatch({
        type: "SAVE_STEP_THREE",
      });

      if (props.state.formData.overlayId) {
        deleteAnnotation({
          variables: {
            annotationId: props.state.formData.overlayId,
          },
        });
      }

      createAnnotation({
        variables: vars,
      });

      props.dispatch({ type: "RESET_OVERLAY_PANEL" });
    } else {
      ErrorToast("charts.explorer.overlays.createAnnotation.error");
    }
  }

  function handleBackButton() {
    props.dispatch({ type: "GO_EDIT_STEP_TWO" });
  }

  // COPY AND PASTE ZONE!!! :lolsob:
  const dateFormat = getLocalDateFormat();
  const isHoliday = props.state.formData?.annotationCategories.includes(
    BuildingAnnotationReason.HOLIDAY,
  );
  const parseDate = (input: string) => {
    const date = DateTime.fromFormat(input, dateFormat);

    return date.isValid ? date.toJSDate() : undefined;
  };
  const formatDate = (date: Date) => {
    return dateToLocaleString(date);
  };

  // END COPY AND PASTE ZONE

  function handleStartDateChange(d: Date) {
    if (DateTime.fromJSDate(d).isValid) {
      setLocalStartDate(d);
    }
  }

  function handleEndDateChange(d: Date) {
    if (DateTime.fromJSDate(d).isValid) {
      setLocalEndDate(d);
    }
  }

  function handleStartTimeChange(d: Date | null) {
    // just this just set the time on the existing date?
    if (d !== null) {
      // see if this is legal?
      setLocalStartDate(d);
    }
  }

  function handleEndTimeChange(d: Date | null) {
    // just this just set the time on the existing date?
    if (d !== null) {
      // see if this is legal?
      const newEnd = DateTime.fromJSDate(d);
      if (newEnd > DateTime.fromJSDate(localStartDate)) {
        setLocalEndDate(newEnd.toJSDate());
      }
    }
  }

  return (
    <>
      <CreateProgress step={3} />
      <NewDatePickerInputStyles />
      <Box margin={[6, 0, 8, 0]}>
        <VerticalLayout childSpacing={4}>
          {/* THE FIRST DATE ROW (start date and time) */}
          <HorizontalLayout childSpacing={2}>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="startDate">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.startDate" />
              </BodyLabel>
              <HorizontalLayout>
                <CalendarIcon />
                <DayPickerInput
                  inputProps={{
                    disabled: isHoliday,
                    required: true,
                    id: "startDate",
                    className: errors ? "invalid" : "",
                    onChange: (e: Event) =>
                      handleStartDateChange(
                        new Date(
                          (e?.currentTarget as HTMLInputElement).value ?? "",
                        ),
                      ),
                  }}
                  dayPickerProps={{
                    modifiers: {
                      disabled: { after: localEndDate },
                    },
                  }}
                  placeholder={dateFormat}
                  parseDate={parseDate}
                  formatDate={formatDate}
                  overlayComponent={DatePickerOverlay}
                  onDayChange={handleStartDateChange}
                  value={localStartDate}
                />
              </HorizontalLayout>
            </VerticalLayout>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="startTime">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.startTime" />
              </BodyLabel>
              <TimePicker
                disabled={isHoliday}
                handleOnChange={handleStartTimeChange}
                keyName="startTime"
                defaultTime={localStartDate}
              />
            </VerticalLayout>
          </HorizontalLayout>
          {/* THE SECOND DATE ROW (start date and time) */}
          <HorizontalLayout childSpacing={2}>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="endDate">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.endDate" />
              </BodyLabel>
              <HorizontalLayout>
                <CalendarIcon />
                <DayPickerInput
                  inputProps={{
                    disabled: isHoliday,
                    required: true,
                    id: "endDate",
                    className: errors ? "invalid" : "",
                    onChange: (e: Event) =>
                      handleEndDateChange(
                        new Date(
                          (e?.currentTarget as HTMLInputElement).value ?? "",
                        ),
                      ),
                  }}
                  dayPickerProps={{
                    modifiers: {
                      disabled: { before: localStartDate },
                    },
                  }}
                  placeholder={dateFormat}
                  parseDate={parseDate}
                  formatDate={formatDate}
                  overlayComponent={DatePickerOverlay}
                  value={localEndDate}
                  onDayChange={handleEndDateChange}
                />
              </HorizontalLayout>
            </VerticalLayout>
            <VerticalLayout childSpacing={2}>
              <BodyLabel htmlFor="endTime">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.endTime" />
              </BodyLabel>
              <TimePicker
                disabled={isHoliday}
                keyName="endTime"
                handleOnChange={handleEndTimeChange}
                defaultTime={localEndDate}
              />
            </VerticalLayout>
          </HorizontalLayout>
        </VerticalLayout>
      </Box>
      {/* THE NEXT AND BACK BUTTONS */}
      <HorizontalLayout childSpacing={2}>
        <MxSecondaryReactButton
          fullWidthOfParent
          onClick={handleBackButton}
          disabled={loading}
          intlTextId="common.button.labels.back"
        />
        <MxPrimaryReactButton
          fullWidthOfParent
          onClick={handleSaveStep}
          disabled={errors || loading}
          intlTextId="common.button.labels.save"
        />
      </HorizontalLayout>
    </>
  );
};

export default EditStepThreeAnnotations;
