import { useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { EventTooltip } from "src/components/app/ExplorerPage/ChartFactory/EventTooltip";
import { VerticalLayout } from "src/components/common/Layout";
import { BuildingAnnotationReason } from "src/types/graphql";

type MultiTooltipProps = {
  timezone?: string;
  currentOrganizationId?: string;
  userId: string;
};

export const MultiTooltip = ({
  timezone = "UTC",
  currentOrganizationId,
  userId,
}: MultiTooltipProps) => {
  const translate = useIntl();

  // There are complications when using hooks within ReactTooltip so we're
  // using useIntl here to pre-translate, creating a translated object, then
  // passing to AnnotationEventTooltip.
  const translateReasons = () => {
    const reasons = Object.values(BuildingAnnotationReason);
    const translated: {
      [key: string]: string;
    } = {};
    reasons.forEach(reason => {
      translated[reason] = translate.formatMessage({
        id: `charts.explorer.overlays.annotationCategory.${reason}`,
      });
    });
    return translated;
  };

  return (
    <ReactTooltip
      id="multiTooltip"
      globalEventOff="scroll mousewheel"
      delayHide={200}
      type="light"
      clickable={true}
      border={true}
      effect="solid"
      getContent={content => {
        if (!content) {
          return;
        }

        const event = JSON.parse(content);
        return (
          <VerticalLayout
            key={`tooltip-content-${event.buildingAnnotationId}`}
            childSpacing={4}
            style={{
              overflowY: "auto",
              overflowX: "clip",
              height: "360px",
              maxWidth: "360px",
            }}
          >
            {EventTooltip(
              event,
              currentOrganizationId ?? "",
              userId,
              timezone,
              translateReasons(),
            )}
          </VerticalLayout>
        );
      }}
    />
  );
};
