import styled from "styled-components/macro";

/**
 * Used for insetting the contents of a Card a little from
 * the outsides. Charts have their own baked in so they don't
 * need this generally.
 */
export const CardContent = styled.div`
  padding: 30px;

  @media (max-width: 1100px) {
    padding-top: 8px;
  }
`;
