import styled from "styled-components/macro";
import { Box } from "src/components/common/Layout/Box";
import { AppColors } from "src/components/common/Styling";
import { useQuery } from "@apollo/client";
import { IngestInformationDocument } from "../../../queries/typed";
import { VerticalLayout } from "src/components/common/Layout/VerticalLayout";
import { Body, InlineH4, InfoText2 } from "src/components/common/Typography";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import * as Types from "../../../types/graphql";
import { PageErrorBoundary } from "src/components/common/ApplicationError/PageErrorBoundary";

const IngestMetaViewContent = styled(Box)`
  position: absolute;
  right: 10px;
  top: 100%;
  background-color: ${AppColors.primary["msr-green"]};
  border-color: black;
  border-width: 2px;
  border-style: solid;
`;

const DialogTitle = styled.div`
  display: flex;
  background-color: ${AppColors.primary["msr-green"]};
  color: ${AppColors.neutral.white};
  height: 36px;
  align-items: center;
  padding: 4px;
`;

const DialogContent = styled(Box)`
  background-color: ${AppColors.primary["light-msr-green"]};
  min-height: 15em;
  min-width: 25em;
  padding: 5px;
`;

const IngestPathwayTable = (props: {
  ingestPathway?: Types.Maybe<
    { __typename?: "IngestPathway" } & Pick<
      Types.IngestPathway,
      "id" | "name" | "createdAt" | "modifiedAt"
    >
  >;
}) => {
  if (props.ingestPathway) {
    const { id, name } = props.ingestPathway;
    return (
      <>
        <Body>Pathway</Body>
        <table>
          <thead>
            <tr>
              <td>
                <InfoText2>Id</InfoText2>
              </td>
              <td>
                <InfoText2>Name</InfoText2>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr key={name}>
              <td>
                <InlineH4>{id}</InlineH4>
              </td>
              <td>
                <InlineH4>{name}</InlineH4>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  } else {
    return null;
  }
};

const ViewExpanded = (props: { ingestPointId: string; showing: boolean }) => {
  const { loading, data, error } = useQuery(IngestInformationDocument, {
    variables: {
      ingestPointId: props.ingestPointId,
    },
  });

  if (loading) {
    return <CenteredLoadingIndicator />;
  } else if (error) {
    return <div>Loading failed.</div>;
  } else if (data && data.ingestPointById) {
    return (
      <>
        <IngestMetaViewContent padding={0}>
          <DialogTitle>
            <InlineH4>Ingest Metadata</InlineH4>
          </DialogTitle>
          <DialogContent>
            <Body>Point</Body>
            <VerticalLayout childSpacing={1}>
              <dl>
                <dt>
                  <InfoText2>Ingest Point ID:</InfoText2>
                </dt>
                <dl>
                  <InlineH4>{data.ingestPointById.id}</InlineH4>
                </dl>
              </dl>
              <dl>
                <dt>
                  <InfoText2>Pathway point ID:</InfoText2>
                </dt>
                <dl>
                  <InlineH4>{data.ingestPointById.pathwayPointId}</InlineH4>
                </dl>
              </dl>
              <dl>
                <dt>
                  <InfoText2>Interval:</InfoText2>
                </dt>
                <dl>
                  <InlineH4>{data.ingestPointById.interval}</InlineH4>
                </dl>
              </dl>
              <dl>
                <dt>
                  <InfoText2>Cumulative:</InfoText2>
                </dt>
                <dl>
                  <InlineH4>
                    {data.ingestPointById.cumulative ? "true" : "false"}
                  </InlineH4>
                </dl>
              </dl>
              {/* TODO: Make created and modified into tooltips*/}
              {data.ingestPointById.cumulative ? (
                <dl>
                  <dt>
                    <InfoText2>Multiplier</InfoText2>
                  </dt>
                  <dl>
                    <InlineH4>{data.ingestPointById.multiplier}</InlineH4>
                  </dl>
                </dl>
              ) : (
                <div />
              )}
              {data.ingestPointById.pathwayPointSet ? (
                <>
                  <dl>
                    <dt>
                      <InfoText2>Point set id</InfoText2>
                    </dt>

                    <dl>
                      <InlineH4>
                        {data.ingestPointById.pathwayPointSet.id}
                      </InlineH4>
                    </dl>
                  </dl>
                  <dl>
                    <dt>
                      <InfoText2>Point set name</InfoText2>
                    </dt>
                    <dl>
                      <InlineH4>
                        {data.ingestPointById.pathwayPointSet.name}
                      </InlineH4>
                    </dl>
                  </dl>
                  <dl>
                    <dt>
                      <InfoText2>Last delivered</InfoText2>
                    </dt>
                    <dl>
                      <InlineH4>
                        {data.ingestPointById.pathwayPointSet.diagnostics.lastReceiveTime}
                      </InlineH4>
                    </dl>
                  </dl>
                  <IngestPathwayTable
                    ingestPathway={
                      data.ingestPointById.pathwayPointSet.ingestPathway
                    }
                  />
                </>
              ) : (
                <div />
              )}
            </VerticalLayout>
          </DialogContent>
        </IngestMetaViewContent>
      </>
    );
  } else {
    return null;
  }
};

export const IngestMetadataView = (props: {
  ingestPointId: string;
  showing: boolean;
}) => {
  return (
    <PageErrorBoundary>
      {props.showing && ViewExpanded(props)}
    </PageErrorBoundary>
  );
};
