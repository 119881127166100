import React from "react";
import { Box, VerticalLayout } from "../Layout";

type MetricProps = {
  value: JSX.Element;
  description: JSX.Element;
};

export const ComposedMetric = (props: MetricProps) => {
  return (
    <Box margin={[0, 4, 0, 0]}>
      <VerticalLayout childSpacing={1}>
        {props.value}
        {props.description}
      </VerticalLayout>
    </Box>
  );
};

export default ComposedMetric;
