import { useEffect, useState } from "react";
import throttle from "lodash.throttle";
/**
 * Events that will reset the timer. We could listen to scroll and so on
 * but our timeout is so long these will do fine and not fire all the darn time.
 *
 * Also note that while the timer is running, it will NOT actually change what it
 * outputs until the alert time threshold is reached by default. This is to avoid
 * causing renders needlessly in components that use it if the values are not
 * watched smartly.
 */
const interestingEvents = ["click", "keydown", "touchstart"];

// const SESSION_TIMEOUT = 10e3; // twenty seconds for testing
// const ALERT_TIME = 5e3; // five seconds for testing
// actual values
const SESSION_TIMEOUT = 43200e3; // twelve HOURS (12 * 60 * 60)e3
const ALERT_TIME = 300e3; // five MINUTES

const EVENT_THROTTLE_TIME = 5e3; // only reset the timer every X seconds

interface IIdleTimerOutput {
  timeRemaining: number; // number of milliseconds until session timeout
  isAlerting: boolean; // are we in the "tell the user" period
  isDone: boolean; // did the timer hit zero (technically you could just check if time remaining is zero and you are alerting);
}
/**
 * Runs a timer and tells you things about it if it's close to running out.
 * Will reset itself when a user does anything.
 */
const useSessionTimeout = (
  timeoutAfter: number = SESSION_TIMEOUT,
  alertAt: number = ALERT_TIME,
): IIdleTimerOutput => {
  const [isAlerting, setIsAlerting] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [countdownTime, setCountdownTime] = useState(ALERT_TIME);
  const initialDelay = timeoutAfter - alertAt; // we don't need to do anything until it's time to alert
  // we need to keep a "local" copy because we don't get the state values inside the effect
  // there is probably a smarter way to do this which I will figure out someday.
  let internalCount = ALERT_TIME;
  let internalAlerting = false;
  let internalDone = false;

  useEffect(() => {
    // hold the timer that is running
    let timeout: number | null = null;
    // since it's possible we could have a timer fire while we are unmounting, handle the edge case
    // (otherwise it will happen often!)
    let mounted = true;
    // handle a user doing a thing
    // TODO: throttle or debounce or something
    /*
    const userInteraction = () => {
      resetTimer();
    };
    */
    // call this during app usage to reset and on init to start
    const resetTimer = throttle(() => {
      if (internalDone) {
        return;
      }
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      if (internalAlerting) {
        internalAlerting = false;
        setIsAlerting(false);
        internalCount = ALERT_TIME;
        setCountdownTime(ALERT_TIME);
      }
      if (mounted) {
        timeout = window.setTimeout(() => {
          if (!internalAlerting) {
            setIsAlerting(true);
            internalAlerting = true;
          }
          timeout = window.setTimeout(tick, 1000);
        }, initialDelay);
      }
    }, EVENT_THROTTLE_TIME);
    // when we are alerting, use this guy to tick the countdown
    // eventually do it with times maybe, but the user isn't looking
    // at their screen if this is running, so this is fine.
    const tick = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      const newCountdown = internalCount - 1000; // remember: ms!
      if (newCountdown <= 0) {
        internalDone = true;
        setIsDone(true);
        setCountdownTime(0);
      } else {
        internalCount = newCountdown;
        setCountdownTime(newCountdown);
        if (mounted) {
          timeout = window.setTimeout(tick, 1000);
        }
      }
    };

    resetTimer();
    for (let i = 0; i < interestingEvents.length; i++) {
      window.addEventListener(interestingEvents[i], resetTimer);
    }

    // return a function that will get called on unmount so we can clean up!
    return () => {
      mounted = false;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      for (let i = 0; i < interestingEvents.length; i++) {
        window.removeEventListener(interestingEvents[i], resetTimer);
      }
    };
  }, []); // only run once!

  return { timeRemaining: countdownTime, isAlerting, isDone };
};
export default useSessionTimeout;
