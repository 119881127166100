import {
  buildChartPropertiesForChart,
  buildDataForChart,
  buildMetadataForChart,
  buildXAxisForChart,
  buildYAxisForChart,
  extractMinimaMaximaValueFromMetadata,
  shouldCalculateCommodityAverage,
  shouldCalculateCommoditySum,
  chartDataTimedReducer,
  buildMetricsForChart,
} from "src/components/app/ExplorerPage/parsers/commonBuildChart";
import {
  BuildingResponse,
  ChartDataGranularity,
  ChartDataRange,
  PointType,
  ChartMetadata,
  CommodityChartData,
  IIntervalData,
  IMeasurement,
  IWeatherData,
  IWeatherMeasurement,
  ParsedChartData,
} from "src/types/charting";

import {
  averageReducer,
  sumReducer,
  setLastIntervalWithDataToNull,
  isFifteenMinOrHourGranularity,
} from "src/helpers/charting";
import {
  DataQueueItem,
  OverlayTimeSpans,
  ScheduleEventType,
  TimeseriesNode,
} from "src/components/app/ExplorerPage/types";
import {
  keyFromQueueItem,
  timeRangeFromTimeseriesKey,
} from "src/components/app/ExplorerPage/helpers";
import { PresentationProps, ChartDataPayload } from "../chartDataReducer";
import { isFutureDate } from "src/helpers/dates";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";

export enum MEASUREMENT_TYPE {
  ELECTRICITY_USAGE = "electricityUsageData",
  ELECTRICITY_DEMAND = "electricityDemandData",
  WEATHER = "weatherData",
  GENERIC_COMMODITY_USAGE = "commodityUsageData",
}

export type TypedBuildingResponse = {
  data: BuildingResponse;
  type: MEASUREMENT_TYPE;
};

export const parseBuildingData = (
  payload: ChartDataPayload,
  parsedData: ParsedChartData,
): ParsedChartData => {
  const combinedResponse = {
    data: payload.response as BuildingResponse,
    type: extractMeasurementTypeFromCommodity(payload.queueItem.commodity),
  };

  // parsedData.queueItems.push(payload.queueItem);
  const newQ = [...parsedData.queueItems, payload.queueItem];

  let _newYAxisMap = undefined;
  let _newXAxisMap = undefined;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return parsedData;
  }

  const unitName = extractUnitName(
    combinedResponse,
    payload.queueItem.commodity,
  );
  const oldMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    parsedData.metadata,
    unitName,
    // payload.queueItem.commodity,
  );

  // Parse metadata
  const { _newMetadata, _newMetrics } = parseMetadataAndMetrics(
    parsedData,
    combinedResponse,
    payload.queueItem,
  );
  // Derive metrics
  // Parse data
  const _newData = parseData(
    parsedData,
    combinedResponse,
    payload.queueItem.commodity,
    payload.queueItem.timeseriesKey,
    payload.queueItem.assetKey,
  );

  const newMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    _newMetadata,
    unitName,
  );

  // Parse XAxis
  if (!parsedData.xAxes.get(payload.queueItem.timeseriesKey)) {
    const xAxisProps = parseXAxis(
      parsedData,
      combinedResponse,
      payload.rangeSize,
      payload.queueItem.timeseriesKey,
      payload.chartWidth,
    );

    if (xAxisProps) {
      // Clone the map
      _newXAxisMap = new Map(parsedData.xAxes);
      _newXAxisMap.set(payload.queueItem.timeseriesKey, xAxisProps);
    }
  }

  // Parse YAxis
  if (oldMixMaxYAxisValues !== newMixMaxYAxisValues) {
    const yAxisProps = parseYAxis(
      parsedData,
      combinedResponse,
      payload.queueItem.commodity,
      _newMetadata,
    );

    if (yAxisProps) {
      // Clone the map
      _newYAxisMap = new Map(parsedData.yAxes);
      _newYAxisMap.set(unitName, yAxisProps);
    }
  }

  // Parse Chart data
  const chartProps = parseChart(
    parsedData,
    combinedResponse,
    payload.queueItem,
    payload.presentationProps,
    payload.isPastComparison,
  );

  const _newChartMap = new Map(parsedData.charts);
  if (chartProps) {
    _newChartMap.set(keyFromQueueItem(payload.queueItem), chartProps);
  }

  return {
    ...parsedData,
    queueItems: newQ,
    metrics: _newMetrics,
    metadata: _newMetadata,
    data: _newData,
    xAxes: _newXAxisMap ? _newXAxisMap : parsedData.xAxes,
    yAxes: _newYAxisMap ? _newYAxisMap : parsedData.yAxes,
    charts: _newChartMap,
  } as ParsedChartData;
};

const parseChart = (
  currentState: ParsedChartData,
  combinedResponse: TypedBuildingResponse,
  queueItem: DataQueueItem,
  presentationProps: PresentationProps,
  isPastComparison?: boolean,
): CommodityChartData | undefined => {
  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.charts.get(keyFromQueueItem(queueItem));
  }

  const unitName = extractUnitName(combinedResponse, queueItem.commodity);

  return buildChartPropertiesForChart(
    queueItem,
    unitName,
    presentationProps,
    isPastComparison,
  );
};

// this needs to take the timeRange, not the rangeSize
const parseXAxis = (
  currentState: ParsedChartData,
  combinedResponse: TypedBuildingResponse,
  rangeSize: ChartDataRange,
  timestampKey: string,
  width: number,
) => {
  const response = combinedResponse.data;
  const responseKey = combinedResponse.type;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.xAxes.get(timestampKey);
  }

  const timezone = response.getBuildingById.location.timezone;
  const values = response.getBuildingById[responseKey]!.values;

  const timeRange = timeRangeFromTimeseriesKey(timestampKey);
  const granularity = extractGranularity(combinedResponse);

  return buildXAxisForChart(
    width,
    values,
    timeRange,
    rangeSize,
    timezone,
    timestampKey,
    granularity,
  );
};

const parseYAxis = (
  currentState: ParsedChartData,
  combinedResponse: TypedBuildingResponse,
  commodityName: PointType,
  metadata: ChartMetadata,
) => {
  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.yAxes.get(commodityName);
  }

  const unitName = extractUnitName(combinedResponse, commodityName);

  const orientation =
    combinedResponse.type === MEASUREMENT_TYPE.WEATHER ? "right" : "left";

  return buildYAxisForChart(metadata, commodityName, unitName, orientation);
};

const parseData = (
  currentState: ParsedChartData,
  combinedResponse: TypedBuildingResponse,
  commodityName: PointType,
  timestampKey: string,
  assetKey: string,
): TimeseriesNode[] => {
  const response = combinedResponse.data;
  const responseKey = combinedResponse.type;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.data;
  }

  const values: IMeasurement[] | IWeatherMeasurement[] = response
    .getBuildingById[responseKey]!.values;

  const timeRange = timeRangeFromTimeseriesKey(timestampKey);
  const granularity = extractGranularity(combinedResponse);

  return buildDataForChart(
    currentState.data,
    timestampKey,
    commodityName,
    assetKey,
    // Only hide last data point for commodity data, not weather data.
    isFifteenMinOrHourGranularity(granularity) &&
      isFutureDate(timeRange.endTime) &&
      values[0].hasOwnProperty("value")
      ? setLastIntervalWithDataToNull(values as IMeasurement[])
      : values,
  );
};

const parseMetadataAndMetrics = (
  currentState: ParsedChartData,
  combinedResponse: TypedBuildingResponse,
  queueItem: DataQueueItem,
): { _newMetadata: ChartMetadata; _newMetrics: ChartMetadata } => {
  const response = combinedResponse.data;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return {
      _newMetadata: currentState.metadata,
      _newMetrics: currentState.metrics,
    };
  }

  const responseKey = combinedResponse.type as MEASUREMENT_TYPE;

  const values: (IMeasurement | IWeatherMeasurement)[] = response
    .getBuildingById[responseKey]!.values;

  let minMaxValues;
  let commoditySum;
  let commodityAverage;
  const unitName = extractUnitName(combinedResponse, queueItem.commodity);
  if (combinedResponse.type === MEASUREMENT_TYPE.WEATHER) {
    const key =
      PointType.TEMPERATURE === queueItem.commodity
        ? "temperature"
        : "relativeHumidity";
    minMaxValues = chartDataTimedReducer(values as IWeatherMeasurement[], key);
    commodityAverage = extractAverageFromAggregateWeatherData(
      response.getBuildingById[responseKey] as IWeatherData,
      key,
    );
  } else {
    minMaxValues = chartDataTimedReducer(values as IMeasurement[], "value");
    commoditySum = sumReducer(values as IMeasurement[], "value");
    commodityAverage = averageReducer(values as IMeasurement[], "value");
  }

  const _newMetadata = buildMetadataForChart(
    currentState.metadata,
    queueItem,
    minMaxValues,
    shouldCalculateCommoditySum(queueItem.commodity) ? commoditySum : undefined,
    shouldCalculateCommodityAverage(queueItem.commodity)
      ? commodityAverage
      : undefined,
    unitName,
  );
  const _newMetrics = buildMetricsForChart(
    currentState.metrics,
    queueItem,
    minMaxValues,
    shouldCalculateCommoditySum(queueItem.commodity) ? commoditySum : undefined,
    shouldCalculateCommodityAverage(queueItem.commodity)
      ? commodityAverage
      : undefined,
  );
  return { _newMetadata, _newMetrics };
};

const extractMeasurementTypeFromCommodity = (
  commodity: PointType,
): MEASUREMENT_TYPE => {
  switch (commodity) {
    case PointType.TEMPERATURE:
    case PointType.HUMIDITY:
      return MEASUREMENT_TYPE.WEATHER;
    default:
      return MEASUREMENT_TYPE.GENERIC_COMMODITY_USAGE;
  }
};

const extractUnitName = (
  combinedResponse: TypedBuildingResponse,
  commodity: PointType,
): string => {
  const response = combinedResponse.data;
  const responseKey = combinedResponse.type;

  const buildingResponse: IWeatherData | IIntervalData | undefined =
    response.getBuildingById[responseKey];

  return buildingResponse
    ? combinedResponse.type === MEASUREMENT_TYPE.WEATHER
      ? extractTemperatureUnit(buildingResponse as IWeatherData, commodity)
      : getSymbolForUnit((buildingResponse as IIntervalData).unit)
    : "";
};

const extractTemperatureUnit = (
  weatherData: IWeatherData,
  commodity: PointType,
): string => {
  return PointType.HUMIDITY === commodity
    ? "%"
    : weatherData.temperatureUnit === "FAHRENHEIT"
    ? "ºF"
    : "ºC";
};

const extractAverageFromAggregateWeatherData = (
  weatherData: IWeatherData,
  key: "temperature" | "relativeHumidity",
): number | undefined => {
  let avgValue;

  if (key === "temperature") {
    avgValue = weatherData.aggregateMeasurements.averageTemperature;
  } else {
    avgValue = weatherData.aggregateMeasurements.averageHumidity;
  }

  return avgValue ? avgValue : undefined;
};

const extractGranularity = (
  combinedResponse: TypedBuildingResponse,
): ChartDataGranularity => {
  const response = combinedResponse.data;
  const responseKey = combinedResponse.type;

  const buildingResponse: IWeatherData | IIntervalData | undefined =
    response.getBuildingById[responseKey];

  return buildingResponse!.granularity;
};

const isValidData = (combinedResponse: TypedBuildingResponse): boolean => {
  const response = combinedResponse.data;
  const responseKey = combinedResponse.type;

  const buildingResponse = response.getBuildingById[responseKey];

  return !!(
    buildingResponse &&
    buildingResponse.values &&
    buildingResponse.values.length > 0
  );
};
