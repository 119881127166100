import {
  PointType,
  ITimeRange,
  ChartDataGranularity,
} from "src/types/charting";
import {
  ExceptionOperatingTimeBlock,
  ExceptionType,
  BuildingAnnotationType,
  BuildingAnnotationReason,
} from "src/types/graphql";
import { DailyOperatingTimeBlock } from "src/types/schedules";

/** What is the state of a building or meter? */
export enum AssetState {
  SELECTED = "SELECTED",
  LOADING = "LOADING",
  ERROR = "ERROR",
  UNSELECTED = "UNSELECTED",
  NOT_AVAILABLE = "NOT_AVAILABLE", // can't be picked for REASONS
}

export interface SelectedAssetsMap {
  groups: Set<string>;
  buildings: Set<string>;
  meters: Set<string>;
  meterGroups: Set<string>;
  equipment: Set<string>;
}

export interface LocalMeter {
  label: string;
  id: string;
}

export interface LocalBuilding {
  label: string;
  id: string;
  group: string;
  timezone: string;
  meters: LocalMeter[];
}

export interface LocalBuildingState {
  buildings: LocalBuilding[];
  currentBuildingId: string;
  currentMeterId: string;
  currentBuildingGroupId: string;
}

export interface AssetStateIndicator {
  [key: string]: AssetState;
}

export enum AssetType {
  BUILDING = "BUILDING",
  METER = "METER",
  POINT = "POINT",
  GROUP = "GROUP",
  EQUIPMENT = "EQUIPMENT",
  EQUIPMENT_POINT = "EQUIPMENT_POINT",
  ORGANIZATION = "ORGANIZATION",
}

interface AssetInfo {
  assetId: string;
  assetName?: string;
  assetType: AssetType;
  queryId: string; // because points query by METER id for now
  timezone: string | undefined;
  latitude?: number | undefined;
  longitude?: number | undefined;
}
export interface CommoditySelection extends AssetInfo {
  commodity: PointType;
}

export type AvoidableCost = {
  monetary?: {
    value: number;
    currency: "CAD" | "USD";
  };
  emissions?: {
    gramCo2e?: number;
  };
};

export type Measure = {
  found: string;
  implemented: string | null | undefined;
  name: string;
  id: string;
  asset: { id: string };
  friendlyId: string;
  avoidableCost?: AvoidableCost;
};

export type BuildingMeasuresResponse = {
  getBuildingById?: MeasureBuilding;
};

export type ScheduleException = {
  id: string;
  exceptionDate: string;
  exceptionEndDate: string;
  reasons: BuildingAnnotationReason[];
  title: string;
  notes: string | null;
  timeBlock: DailyOperatingTimeBlock | null;
  exceptionTimeRanges: ExceptionOperatingTimeBlock[] | null;
};

export type BuildingAnnotation = {
  id: string;
  createdTime: string;
  startTime: string;
  endTime: string;
  reasons: BuildingAnnotationReason[];
  title?: string;
  notes?: string;
  type: BuildingAnnotationType;
  createdBy: {
    firstName: string;
    lastName: string;
  };
};

export type ScheduleExceptionBuilding = {
  id: string;
  name: string;
  scheduleExceptions: ScheduleException[];
};

export type BuildingScheduleExceptionsResponse = {
  getBuildingById?: ScheduleExceptionBuilding;
};

export type AnnotationsBuilding = {
  id: string;
  name: string;
  annotations: BuildingAnnotation[];
};

export type BuildingAnnotationsResponse = {
  getBuildingById?: AnnotationsBuilding;
};

export type MeasureBuilding = {
  id: string;
  name: string;
  organizationId: string;
  measures: Measure[];
};

export enum MeasureEventType {
  Implemented = "IMPLEMENTED",
  Identified = "IDENTIFIED",
}

export enum ScheduleEventType {
  ScheduleExceptions = "SCHEDULE_EXCEPTIONS",
  OperatingSchedule = "OPERATING_SCHEDULE",
}

export enum ThresholdEventType {
  Thresholds = "THRESHOLDS",
}

export enum AnnotationEventType {
  PeakDemand = "PEAK_DEMAND",
  HighUsage = "HIGH_USAGE",
  Custom = "CUSTOM",
}

export type MeasureEvent = {
  id: string;
  buildingId: string;
  eventType: MeasureEventType;
  name: string;
  friendlyId: string;
  avoidableCost?: AvoidableCost;
  found: string;
  implemented?: string | null;
  buildingName: string;
};

export type ScheduleExceptionEvent = {
  id: string;
  buildingId: string;
  buildingName: string;
  name: string;
  exceptionDate: string;
  exceptionEndDate: string;
  timeBlock: DailyOperatingTimeBlock | null;
  eventType: ScheduleEventType;
  notes: string;
  reasons: BuildingAnnotationReason[];
  exceptionTimeRanges: ExceptionOperatingTimeBlock[];
  xAxisStart: number;
};

type BaseAnnotationEvent = {
  id: string;
  buildingId: string;
  buildingName: string;
  createdTime: string;
  startTime: string;
  endTime: string;
  reasons: BuildingAnnotationReason[];
  title?: string;
  notes?: string;
  type: BuildingAnnotationType;
  createdBy: { firstName: string; lastName: string };
  eventType: AnnotationEventType;
  xAxisStart: number;
};

export type AnnotationEvent = PeakDemandEvent | HighUsageEvent | CustomEvent;

type PeakDemandEvent = BaseAnnotationEvent & {
  eventType: AnnotationEventType.PeakDemand;
};

type HighUsageEvent = BaseAnnotationEvent & {
  eventType: AnnotationEventType.HighUsage;
};

type CustomEvent = BaseAnnotationEvent & {
  eventType: AnnotationEventType.Custom;
};

export type OperatingScheduleEvent = {
  id?: string;
  eventType: ScheduleEventType;
  startTime: string;
  endTime: string;
  buildingId: string;
};

export type OverlayEventType =
  | MeasureEventType
  | ScheduleEventType
  | ThresholdEventType
  | AnnotationEventType;

export type OverlayEventState = {
  [key in OverlayEventType]: boolean;
};

export type OverlayEvents = Map<
  number,
  (
    | MeasureEvent
    | ScheduleExceptionEvent
    | AnnotationEvent
    | OperatingScheduleEvent
  )[]
>;

export type OverlayTimeSpan = {
  // TODO: This is optional only because OperatingSchedules don't have an id.
  // Other OverlayTimeSpans need to know the eventId.
  eventId?: string;
  buildingId: string;
  fillColor: string;
  startTime: Date;
  endTime: Date;
  eventType: OverlayEventType;
  xAxisStart: number;
  visible: boolean;
};

export type ScheduleExceptionSpan = OverlayTimeSpan & {
  eventType: ScheduleEventType;
};

export type OverlayTimeSpans = Map<number, OverlayTimeSpan[]>;

export type EventPayload<T> = {
  response: T;
  timeRange: ITimeRange;
  timezone: string | undefined;
};

export type ExplorerMeasureEvent = {
  /**
   * Timestamp
   */
  date: number;
  events: MeasureEvent[];
};

// used for fetching line chart data for all points buildings, groups, etc.
export interface DataQueueItem extends CommoditySelection {
  assetKey: string; // an assetKey is a TYPE__ID thing. ex: BUILDING__abc123-DEF456
  timeseriesKey: string; // unique ID for a time range in startTS_endTS. ex: 42134213_42232131
  timeRange: ITimeRange;
  granularity: ChartDataGranularity;
  startHidden?: boolean;
}

/* =============================================================== *\
   This models something typescript sucks at, or at least did. I think
   maybe Records could improve this, but at the time of writing, this
   was the only way to do it. It describes a data structure like so:

   {
      "1573441200000_1576033200000": {
        timestamp: 1576213200000,
        ELECTRICITY_DEMAND: {
          "BUILDING__95e7e0e1-5229-40db-9ca7-83b1d3ebb8ac": 208.98000240325928,
          "BUILDING__7118e6cb-a945-4a47-a28f-36e26cc4a77a": 601.5600061416626,
        },
        TEMPERATURE: {
          "BUILDING__95e7e0e1-5229-40db-9ca7-83b1d3ebb8ac": 20,
          "BUILDING__7118e6cb-a945-4a47-a28f-36e26cc4a77a": 64,
        },
      },
      "1570441200000_1573033200000": {
        timestamp: 1573213200000,
        ELECTRICITY_DEMAND: {
          "BUILDING__95e7e0e1-5229-40db-9ca7-83b1d3ebb8ac": 48.40325928,
          "BUILDING__7118e6cb-a945-4a47-a28f-36e26cc4a77a": 331.416626,
        },
        TEMPERATURE: {
          "BUILDING__95e7e0e1-5229-40db-9ca7-83b1d3ebb8ac": 31,
          "BUILDING__7118e6cb-a945-4a47-a28f-36e26cc4a77a": 74,
        },
      },
    },
\* =============================================================== */
type AssetReading = {
  [assetKey: string]: number | null;
};
type CommodityCollection = {
  [commodity: string]: AssetReading;
} & {
  timestamp: number;
};

/**
 * NOTE! If you want to make a timeseries node, you have to do it this way:
 *
 * const someNode: TimeseriesNode = {
 *   [someKey]: Object.assign(
 *      {[commodity]: {[assetKey]: 12321}},
 *      {timestamp: 542314}
 *   )
 * }
 *
 * because Typescript does not like "one known key of a type and then n keys
 * that have a different type" structures, so the Union was abused to make
 * that happen, and turns out you can't _make_ things directly in that shape.
 *
 * So doing this:
 *
 * const someNode: TimeseriesNode = {
 *   [someKey]: {
 *    timestamp: 098123,
 *    [commodity]: {[assetKey]: 12321},
 *   }
 * }
 *
 * will cause the compiler to complain, even though it is "correct". Why, I
 * do not know. But there you have it.
 */
export type TimeseriesNode = {
  [timeseriesKey: string]: CommodityCollection;
};
/*
// OLD SCHOOL
export interface TimeseriesNode {
  [key: string]: {
    // ^^ time series key "[startTimestamp]_[endTimestamp]"
    [key: string]: // a commodity name: "ELECTRICITY_DEMAND"
    | {
          [key: string]: number | null; // an assetKey and a value: "BUILDING_abc123: 3123"
        }
      | number; // this is NEVER a number, but we had to put it here because typescript!
    timestamp: number;
  };
}
*/

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export type ScheduleStorage = {
  startDate: string;
  endDate?: string;
  title?: string;
  note?: string;
  annotationCategories: BuildingAnnotationReason[];
  startHour: number;
  endHour: number;
  isOccupied: boolean;
};
