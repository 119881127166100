import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";
import { CollapseIcon } from "src/components/common/SidebarParts";

/* =============================================================== *\
   NOTE: Most of these are not used in MultiCommoditySelector any
   more. However, AssetSelector2 does use them, so you can't get rid
   of them until we see if we can simplify that as well.

   TODO: see if we can use the simple `Group` and `Header` elements
   that are in MCS for AssetSelector2

\* =============================================================== */
export const GroupHeader = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  margin-left: -8px;
`;
export const GroupWrapper = styled.section`
         border-top: 1px solid ${AppColors.neutral["light-gray-9"]};
         padding-left: 8px;
         .groupWrapper > &.groupWrapper {
           padding-left: 8px;
           border-top: none;
         }
       `;

export const UngroupedBuildingsWrapper = styled.div``;

// TODO: this is pretty much the same as the building stuff, so
// maybe combine them at some point.
export const MeterWrapper = styled.div`
  margin-left: 16px;
  padding-top: 16px;
  padding-bottom: 0;
  margin-right: 0;
  ${GroupWrapper} & {
    border: 0;
    padding-top: 16px;
    margin-left: 32px;
  }
  ${UngroupedBuildingsWrapper} & {
    margin-left: 48px;
  }
`;
export const MeterHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;

/* =============================================================== *\
   BUILDING RELATED STUFF
  :first-child {
    border-top: none;
  }
\* =============================================================== */

export const BuildingHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 4px;
`;
export const BuildingWrapper = styled.section`
         border-top: 1px solid ${AppColors.neutral["light-gray-9"]};
         padding-top: 8px;
         padding-bottom: 8px;
         margin-right: 0;
         ${GroupWrapper} & {
           border: 0;
           padding-top: 0;
           padding-bottom: 16px;
           margin-left: 8px;
         }
         /* This is gross but it needs to follow: Indent should inherit the 
  indentation of the group/subgroup level’s title it resides in (title,
  not arrow) */
         ${GroupWrapper} ${GroupWrapper} & {
           margin-left: 22px;
         }
       `;

/* generic font stuff, maybe this goes away? */
export const AssetListWrapper = styled.div`
  font-size: 15px;
  font-weight: 300;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const AssetLabel = styled.div<{
  isClickable?: boolean;
  isSelected?: boolean;
}>`
  min-width: 0px; /* stops us from growing out past parent */
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: ${props => (props.isClickable ? "pointer" : "default")};
  background-color: ${props =>
    props.isSelected ? AppColors.primary["msr-green"] : "inherit"};
  border-radius: ${props => (props.isSelected ? "15px" : "inherit")};
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: ${props => (props.isSelected ? "16px" : "4px")};
`;

export const OrgHeader = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  margin-left: -8px;
`;
