import { FeatureType, FeatureEnablement } from "src/types/charting";
import { useAuthorization } from "src/hooks/useAuthorization";
import { UserRole } from "src/types/roles";
/**
 * Give it an enablements array and the enablement you are checking for
 * and it will tell you if it has it!
 */
export const useFeatureEnablement = () => {
  const { hasRole } = useAuthorization();

  const isFeatureEnabled = (
    enablements: FeatureEnablement[],
    feature: FeatureType,
    requiresAdmin: boolean = true, // TODO: flip this to FALSE?
  ): boolean => {
    if (requiresAdmin) {
      const _admin = hasRole(UserRole.ADMIN);
      if (!_admin) {
        return false;
      }
    }
    // loop through the things and see if we got one!
    for (let i = 0; i < enablements.length; i += 1) {
      const f = enablements[i];
      if (f.name === feature && f.enabled === true) {
        return true;
      }
    }
    return false;
  };

  return { isFeatureEnabled };
};
