import { Unit as GeneratedUnit } from "src/types/graphql";
import { Unit } from "src/types/charting";

// TODO: does this need to be a hook? Being a hook limits our ability to use it in
// conditionals and so on. It is probably fine as-is, but making it a hook adds
// complexity internally to React, when this could just be a vanilla TS module.
export const useGraphqlMappings = () => {
  /**
   * For a given Unit enum, returns the equivalent Unit enum generated by the Graphql mapper.
   * If the unit cannot be found, an error is thrown.
   * @param unit
   * @returns the equivalent enum generated by Graphql.
   */
  const generatedUnitForUnit = (unit: Unit): GeneratedUnit => {
    const maybeUnit = (unit as unknown) as GeneratedUnit;
    if (Object.values(GeneratedUnit).includes(maybeUnit)) {
      return maybeUnit;
    }
    throw new Error(`Invalid unit conversion for unit: ${unit}`);
  };

  return {
    generatedUnitForUnit,
  };
};
