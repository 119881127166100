import React, { FC } from "react";
import styled from "styled-components/macro";
import { MxReactIcon } from "src/componentLibrary/react/mx-icon-react";
import { FormattedMessage } from "react-intl";
import { Body } from "src/components/common/Typography";

export const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const Icon = styled.svg`
  stroke: white;
  stroke-width: 2px;
  width: 24px;
  height: 24px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 1px;
`;

export const Title = styled(Body)`
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 40px;
`;

type SidebarHeadingProps = {
  closeHandler: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
  icon: FC;
};

export const SidebarHeading: React.FC<SidebarHeadingProps> = ({
  closeHandler,
  title,
  icon,
}) => {
  return (
    <Header>
      <MxReactIcon Icon={icon} size={"m"} color="white" />
      <Title>
        <FormattedMessage id={title} />
      </Title>
      <IconButton
        onClick={closeHandler}
        data-testid="asset-selector-close-button"
      >
        <Icon viewBox="0 0 24 24">
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </Icon>
      </IconButton>
    </Header>
  );
};
