import { ApolloProvider as ApolloHooksProvider } from "@apollo/client";
import ReactDOM from "react-dom";
import "./index.css";
import AuthProvider from "./auth";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as dataDog from "./dataDogSetup";
import client from "src/apollo/client";
import { ApolloProvider } from "@apollo/client";
import { LocaleProvider } from "./hooks/useLocale";

// const locale = navigator.language || "en";

/* ------------------------------------------------ *\
  WARNING! This is hard coded and it should not be.
  Doing it now this way because it's easy and fast.
  When we add other locales, we will need to code in
  the ability to check for other language files and
  then set the locale to "en" if they are not there.

  We should also only load the language file we need
  async like so we don't load 7497243721 strings into
  memory. Also note that the `defaultLocale` doesn't
  actually load "en" strings if there's no `messages`;
  it just uses "en" if it cannot determine a locale.

  The other alternative is to add `default={}` to all
  instances of `FormattedMessage` but that seems like
  a lot of work and will be fragile when we change
  copy in the app.
\* ------------------------------------------------ */

ReactDOM.render(
  <AuthProvider>
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <LocaleProvider>
          <App />
        </LocaleProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  </AuthProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

dataDog.init();
