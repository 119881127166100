import styled from "styled-components/macro";
import { ChartDataRange, ComparePeriod } from "src/types/charting";
import { AppColors } from "../Styling";

type TimePickerHolderProps = {
  left?: boolean;
};

export type DateUtilRange = {
  from: Date | undefined;
  to: Date | undefined;
};

export interface ITimeRangeChange {
  selectedRange?: ChartDataRange;
  selectedDates: DateUtilRange;
}
export interface ICompareRangeChange {
  comparePeriod: ComparePeriod;
  selectedDates: DateUtilRange;
}

export const TimePickerHolder = styled.div<TimePickerHolderProps>`
  position: absolute;
  right: ${props => (props.left ? "auto" : 0)};
  left: ${props => (props.left ? 0 : "auto")};
  top: 0;
  display: inline-block;
  background-color: ${AppColors.neutral.white};
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;

  /* this is the breakpoint from the app header */
  @media (max-width: 1100px) {
    left: 0;
    right: auto;
  }
  /* this is to look modal on tiny screens */
  @media (max-width: 460px) {
    position: fixed;
    left: 0;
    bottom: 0;
    overflow: auto;
  }
`;

export const PickerCalendar = styled.div`
         margin: 8px;
         width: 272px;

         .DayPicker-Day {
           border-radius: 0 !important;
           width: 42px !important;
           font-size: 14px;
         }
         .DayPicker-Day--today {
           color: ${AppColors.neutral.black};
           font-weight: 700;
         }

         .DayPicker {
           background-color: ${AppColors.neutral.white};
           border: 1px solid ${AppColors.neutral["light-navy-1"]};
           font-weight: 300;
         }
         .DayPicker-Month {
           margin: 0 !important;
         }
         .DayPicker-Day--highlighted:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
           background-color: ${AppColors.semantic.green["light-msr-green-6"]} !important;
           color: ${AppColors.primary["msr-green"]};
         }
         /* the month name header */
         .DayPicker-Caption {
           margin-top: 12px;
           text-align: center;
           font-size: 14px;
         }
         /* the previous month nav button */
         .DayPicker-NavButton {
           top: 8px;
         }
         .DayPicker-NavButton--prev {
           left: 4px;
         }
         /* days that are outside the month on the calendar, but are still shown */
         /* (like the 30th and 31st of Aug showing up on the Sept. calendar */
         .DayPicker-Day--outside {
           color: ${AppColors.neutral["light-navy-3"]};
           font-weight: 300;
         }
         .DayPicker-Day--insideLimit {
           background-color: ${AppColors.neutral.white};
         }
         .DayPicker-Day--disabled {
           color: ${AppColors.neutral["light-navy-9"]} !important;
           font-weight: 300;
         }
         /* the next month nav button */
         .DayPicker-NavButton--next {
           right: 4px;
         }
         /* the weeday labels */
         .DayPicker-WeekdayRow {
           height: 48px;
         }
         .DayPicker-Weekday {
           color: ${AppColors.neutral.navy};
           font-weight: 400;
           text-transform: uppercase;
           margin-bottom: 24px;
         }
         .DayPicker-Day--today {
           font-weight: 700;
         }

         .DayPicker-Day:not(.DayPicker-Day--outside).DayPicker-Day--selected {
           background-color: ${AppColors.primary["msr-green"]} !important;
           font-weight: 500;
           border-radius: 14px !important;
           &.DayPicker-Day--start {
             border-top-right-radius: 0 !important;
             border-bottom-right-radius: 0 !important;
             border-top-left-radius: 14px !important;
             border-bottom-left-radius: 14px !important;
           }
           &.DayPicker-Day--end {
             border-top-left-radius: 0 !important;
             border-bottom-left-radius: 0 !important;
             border-top-right-radius: 14px !important;
             border-bottom-right-radius: 14px !important;
           }
           &.DayPicker-Day--start.DayPicker-Day--end {
             border-top-right-radius: 14px !important;
             border-bottom-right-radius: 14px !important;
             border-top-left-radius: 14px !important;
             border-bottom-left-radius: 14px !important;
           }
         }
       `;
