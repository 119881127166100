import React from "react";
import styled from "styled-components/macro";
import { Divider } from "../Layout";

const StyledDivider = styled(Divider)`
  @media (max-width: 900px) {
    display: none;
  }
`;

export const MetricDivider = () => <StyledDivider width={0.5} />;
