import { useMutation, useQuery } from "@apollo/client";
import { SetStateAction, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useUserContext } from "src/auth/UserContext";
import { ChartDataAction } from "src/components/app/ExplorerPage/chartDataReducer";
import {
  PointTypeSelector,
  BuildingSelector,
} from "src/components/app/ExplorerPage/OverlayStuff/Dropdowns";
import {
  AssetBuilding,
  OverlayFormAction,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";

import { Box, VerticalLayout } from "src/components/common/Layout";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import NumericInput from "src/components/common/NumericInput";
import { ErrorToast, SuccessToast } from "src/components/common/Toast";
import { BodyLabel } from "src/components/common/Typography";
import {
  LoadThresholdsForBuildingInExplorerDocument,
  SaveThresholdForBuildingInExplorerDocument,
} from "src/queries/typed";
import { tracking } from "src/tracking";
import { PointType } from "src/types/charting";
import { OverlayEventState } from "src/components/app/ExplorerPage/types";
import { MxPrimaryReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";

type ThresholdOverlayConfigurationProps = {
  buildings: AssetBuilding[];
  overlayDispatch: React.Dispatch<OverlayFormAction>;
  overlayProps: {
    overlayState: OverlayEventState;
    setOverlayState: React.Dispatch<SetStateAction<OverlayEventState>>;
  };
  chartDispatch: React.Dispatch<ChartDataAction>;
  initialBuildingId?: string;
};

type FormFields = {
  buildingId: string;
  pointType: PointType;
  value: number;
  isEditing: boolean;
};

export const ThresholdOverlayConfiguration = (
  props: ThresholdOverlayConfigurationProps,
) => {
  // We're only allowing electricity demand for now. I assume
  // eventually we'll need to load whatever point types are associated
  // with the building.
  const pointTypes = [PointType.ELECTRICITY_DEMAND];

  const { userData } = useUserContext();

  const [formFields, setFormFields] = useState<FormFields>({
    buildingId: props.initialBuildingId || props.buildings[0].id,
    value: 0,
    pointType: PointType.ELECTRICITY_DEMAND,
    isEditing: false,
  });

  const [saveThreshold, { loading }] = useMutation(
    SaveThresholdForBuildingInExplorerDocument,
    {
      onError: e => {
        ErrorToast("charts.explorer.overlays.create.threshold.error");
      },
      onCompleted: async response => {
        if (response.setBuildingThreshold?.threshold) {
          SuccessToast("charts.explorer.overlays.create.threshold.saved");
        } else {
          ErrorToast("charts.explorer.overlays.create.threshold.error");
        }

        // Without this, if you reload this screen it'll still
        // use the OG value instead of the updated one
        const refetchResult = await refetch?.();

        /* This action updates the thresholds array values in state only if
        the threshold was pre-existing */
        props.chartDispatch({
          type: "REFRESH_THRESHOLD_EVENT_DATA",
          payload: {
            buildingId: formFields.buildingId,
            pointType: formFields.pointType,
            threshold: formFields.value,
          },
        });

        /* This action updates the thresholds array in state with a new item when
        adding a threshold that didn't previously exist */
        if (refetchResult.data) {
          props.chartDispatch({
            type: "RECEIVE_THRESHOLD_EVENT_DATA",
            payload: refetchResult.data,
          });
        }

        props.overlayProps.setOverlayState({
          ...props.overlayProps.overlayState,
          THRESHOLDS: true,
        });
        props.overlayDispatch({
          type: "CLOSE_OVERLAY",
          payload: { userId: userData.id },
        });
      },
    },
  );

  const { loading: thresholdLoading, refetch } = useQuery(
    LoadThresholdsForBuildingInExplorerDocument,
    {
      variables: {
        buildingId: formFields.buildingId,
      },
      onCompleted(data) {
        if (data?.getBuildingById?.threshold) {
          setFormFields({
            ...formFields,
            pointType: data.getBuildingById.threshold.type,
            value: data.getBuildingById.threshold.value,
            isEditing: true,
          });
        } else {
          setFormFields({
            ...formFields,
            value: 0,
            pointType: PointType.ELECTRICITY_DEMAND,
            isEditing: false,
          });
        }
      },
    },
  );

  const handleSave = () => {
    tracking.fireEvent(
      formFields.isEditing ? "EDIT_THRESHOLD" : "ADD_THRESHOLD",
      { buildingId: formFields.buildingId },
    );

    saveThreshold({
      variables: {
        buildingId: formFields.buildingId!,
        type: formFields.pointType!,
        value: formFields.value,
      },
    });
  };

  return (
    <Box margin={[6, 0, 8, 0]}>
      <VerticalLayout childSpacing={8}>
        <VerticalLayout childSpacing={2}>
          <BodyLabel className="labels" htmlFor="buildingId">
            <FormattedMessage id="charts.explorer.overlays.create.building" />
          </BodyLabel>
          <BuildingSelector
            defaultValue={props.buildings.find(
              x => x.id === formFields.buildingId,
            )}
            items={props.buildings}
            onChange={building => {
              if (building?.id) {
                setFormFields({ ...formFields, buildingId: building?.id });
              }
            }}
          />
        </VerticalLayout>

        {thresholdLoading && <CenteredLoadingIndicator />}

        {!thresholdLoading && (
          <>
            <VerticalLayout childSpacing={2}>
              <BodyLabel className="labels" htmlFor="pointType">
                <FormattedMessage id="charts.explorer.overlays.create.point" />
              </BodyLabel>
              <PointTypeSelector
                defaultValue={formFields.pointType}
                items={pointTypes}
                onChange={pt => setFormFields({ ...formFields, pointType: pt })}
                locale={userData.preferences.locale}
              />
            </VerticalLayout>
            <VerticalLayout childSpacing={2}>
              <BodyLabel className="labels" htmlFor="thresholdValue">
                <FormattedMessage id="charts.explorer.overlays.create.thresholdValue" />
              </BodyLabel>
              <NumericInput
                value={formFields.value}
                onChange={value => setFormFields({ ...formFields, value })}
              />
            </VerticalLayout>

            <MxPrimaryReactButton
              fullWidthOfParent
              onClick={handleSave}
              disabled={
                formFields.value === undefined || loading || thresholdLoading
              }
              intlTextId="common.button.labels.save"
            />
          </>
        )}
      </VerticalLayout>
    </Box>
  );
};
