import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components/macro";
import { AppColors } from "../Styling";
import "react-datepicker/dist/react-datepicker.css";

/* NOTE

if these components explode for seemingly no reason, add these imports to the top of your file

import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";

it is some sort of voodoo curse or something.

*/
export interface TimePickerProps {
  defaultTime?: Date;
  keyName: string;
  required?: boolean;
  disabled?: boolean;
  handleOnChange: (date: Date | null) => void;
  interval?: number;
  showArrow?: boolean;
}

export const TimePicker: React.FC<TimePickerProps> = props => {
  const {
    defaultTime,
    keyName,
    required,
    disabled,
    handleOnChange,
    interval = 60,
    showArrow = false,
  } = props;
  return (
    <Wrapper>
      <StyledDatePicker
        name={keyName}
        selected={defaultTime}
        onChange={handleOnChange}
        showTimeSelectOnly
        showTimeSelect
        timeIntervals={interval}
        showPopperArrow={showArrow}
        dateFormat={"p"}
        timeFormat={"p"}
        required={required}
        disabled={disabled}
      />
    </Wrapper>
  );
};

export const StyledDatePicker = styled(DatePicker)`
  height: 37px;
  border-radius: 3px;
  border: 1px solid ${AppColors.neutral["light-navy-3"]};
  padding-left: 10px;
  font-size: 15px;
  width: 90px;
  :disabled {
    color: ${AppColors.neutral["light-navy-3"]};
    background-color: ${AppColors.neutral["light-navy-9"]};
  }
`;

export const Wrapper = styled.div`
  display: inline-block;

  .react-datepicker__time-container {
    width: 88px;
  }

  .react-datepicker-wrapper {
    width: initial;
  }

  .react-datepicker-popper[data-placement^="top"] {
    margin: 0;
    top: 20px !important;

    .react-datepicker--time-only {
      border-bottom: none;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    margin-top: -16px;

    .react-datepicker--time-only {
      border-top: none;
    }
  }

  .react-datepicker__header--time {
    display: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__time-box {
    width: 100% !important;
    text-align: left !important;
  }

  .react-datepicker__time-list-item {
    font-size: 15px;
    padding: 0 7px !important;
    color: ${AppColors.neutral["light-navy-3"]};
    :hover {
      background-color: ${AppColors.neutral.white} !important;
    }
  }

  .react-datepicker__time-list-item--selected {
    color: ${AppColors.neutral.navy} !important;
    background-color: ${AppColors.neutral.white} !important;
  }
`;
export default TimePicker;
