import React, { useEffect } from "react";

type OutsideClickHandler = () => void;
type InnerRef = React.RefObject<Element>;
export const useOutsideClickEvent = (
  onOutsideClick: OutsideClickHandler,
  innerRef: InnerRef,
): void => {
  useEffect(() => {
    // note that since we are attached to document, this is a "real" MouseEvent
    // not a React (synthetic) event.
    // Also note that this is inside useEffect because we need to be recreated when innerRef changes
    const handleClick = (event: MouseEvent) => {
      if (
        innerRef.current &&
        !innerRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    };
    if (innerRef.current) {
      document.addEventListener("click", handleClick);
    }
    // clean up our event handler, otherwise bad stuff happens!
    return () => document.removeEventListener("click", handleClick);
  }, [onOutsideClick, innerRef]);
};
