import ReactTooltip from "react-tooltip";
import ScheduleEventTooltip from "src/components/app/ExplorerPage/ChartFactory/ScheduleEventTooltip";
import { ScheduleExceptionEvent } from "src/components/app/ExplorerPage/types";
import { VerticalLayout } from "src/components/common/Layout";

type ScheduleExceptionsTooltipProps = {
  timezone?: string;
  userId: string;
  organizationId?: string;
};

export const ScheduleExceptionsTooltip = ({
  timezone = "UTC",
  userId,
  organizationId,
}: ScheduleExceptionsTooltipProps) => {
  return (
    <ReactTooltip
      id="scheduleExceptionsTooltip"
      globalEventOff="scroll mousewheel"
      delayHide={200}
      type="light"
      clickable={true}
      border={true}
      effect="solid"
      getContent={content => {
        if (!content) {
          return;
        }

        const events: ScheduleExceptionEvent[] = JSON.parse(content);
        return (
          <VerticalLayout
            key={`tooltip-content-${events[0].id}`}
            childSpacing={2}
            style={{
              overflowY: "auto",
              overflowX: "clip",
              height: events.length > 1 ? "360px" : "180px",
              maxWidth: "360px",
            }}
          >
            {events.map(events =>
              ScheduleEventTooltip(
                events,
                organizationId ?? "",
                userId,
                timezone,
              ),
            )}
          </VerticalLayout>
        );
      }}
    />
  );
};
