import {
  BuildingAnnotationReason,
  BuildingAnnotationType,
} from "src/types/graphql";

export enum OverlayType {
  MeasureIdentified = "MeasureIdentified",
  MeasureImplemented = "MeasureImplemented",
  ScheduleException = "ScheduleException",
  PeakDemand = "PeakDemand",
  HighUsage = "HighUsage",
  Custom = "Custom",
}

// These have to be allowed to be null so we can initialize our state
export interface OverlayFormData {
  buildingId: string | null;
  overlayType: OverlayType | null;
  overlayId: string | null; // only used when editing/deleting annotations and schedule exceptions
  annotationType?: BuildingAnnotationType | null; // only used by Annotations
  annotationCategories: BuildingAnnotationReason[];
  startDate: Date | null;
  startTime: number | null;
  endTime: number | null;
  endDate: Date | null; // only used by Annotations! Exceptions start and end come from startDate and the two times
  isOccupied: boolean;
  title: string | undefined;
  note: string | undefined;
  timezone: string;
  latitude: number | null;
  longitude: number | null;
}

const emptyFormData: OverlayFormData = {
  buildingId: null, // "b1", "b2"
  overlayType: null,
  overlayId: null,
  annotationType: null, // BuildingAnnotationType
  annotationCategories: [], // BuildingAnnotationReason[]
  startDate: null, // new Date("2022-09-06");
  startTime: null, // 0-23
  endTime: null, // 1-24
  endDate: null,
  isOccupied: true,
  title: undefined, // string
  note: undefined, // string
  timezone: "UTC", // string
  latitude: null, // number
  longitude: null, // number
};

export type AssetBuilding = {
  name: string;
  id: string;
  timezone: string;
  latitude: number;
  longitude: number;
};

export interface OverlayFormState {
  ui: {
    overlayOpen: boolean;
    buildingsSelected: AssetBuilding[];
  };
  thresholdConfig?: {
    initialBuildingId?: string;
  };
  overlayPanel: {
    state:
      | "CREATE_1"
      | "CREATE_2"
      | "CREATE_3"
      | "CREATE_THRESHOLD"
      | "EDIT_1"
      | "EDIT_2"
      | "EDIT_3";
  };
  formData: OverlayFormData;
  userId?: string;
}

const initialState: OverlayFormState = {
  ui: {
    overlayOpen: false,
    buildingsSelected: [],
  },
  overlayPanel: {
    state: "CREATE_1",
  },
  formData: emptyFormData,
};

type OpenOverlay = {
  type: "OPEN_OVERLAY";
};

type CloseOverlay = {
  type: "CLOSE_OVERLAY";
  payload: { userId: string };
};

type StartCreate = {
  type: "START_CREATE";
  payload: {
    startDate: Date;
    startTime: number;
    endDate: Date;
    endTime: number;
    buildings: AssetBuilding[];
  };
};

type StartEdit = {
  type: "START_EDIT";
};

type GoEditStepOne = {
  type: "GO_EDIT_STEP_ONE";
  payload: {
    overlayId: string;
    title?: string;
    notes?: string;
    annotationCategories: BuildingAnnotationReason[];
    annotationType: BuildingAnnotationType;
    startDate: Date;
    startTime: number;
    endDate: Date;
    endTime: number;
  };
};

type ResetOverlayPanel = {
  type: "RESET_OVERLAY_PANEL";
};

type PrefillFormData = {
  type: "PRE_FILL_FORM_DATA";
  payload: Partial<OverlayFormData>;
};

type SaveStepOne = {
  type: "SAVE_STEP_ONE";
  payload: Partial<OverlayFormData>;
};

type SaveEditStepOne = {
  type: "SAVE_EDIT_STEP_ONE";
  payload: Partial<OverlayFormData>;
};

type SaveStepTwo = {
  type: "SAVE_STEP_TWO";
  payload: Partial<OverlayFormData>;
};

type SaveEditStepTwo = {
  type: "SAVE_EDIT_STEP_TWO";
  payload: Partial<OverlayFormData>;
};

type SaveStepThree = {
  type: "SAVE_STEP_THREE";
};

type GoStepOne = {
  type: "GO_STEP_ONE";
};

type GoStepTwo = {
  type: "GO_STEP_TWO";
};

type GoEditStepTwo = {
  type: "GO_EDIT_STEP_TWO";
};

type GoStepThree = {
  type: "GO_STEP_THREE";
};

type UpdateSelectedBuildings = {
  type: "UPDATE_SELECTED_BUILDINGS";
  payload: {
    buildings: AssetBuilding[];
  };
};

type GoToThreshold = {
  type: "GO_TO_THRESHOLD";
  payload: {
    initialBuildingId?: string;
  };
};

type BeginEditScheduleException = {
  type: "BEGIN_EDIT_SCHEDULE_EXCEPTION";
  payload: Partial<OverlayFormData>;
};

type BeginEditAnnotation = {
  type: "BEGIN_EDIT_ANNOTATION";
  payload: Partial<OverlayFormData>;
};

export type OverlayFormAction =
  | OpenOverlay
  | CloseOverlay
  | PrefillFormData
  | StartCreate
  | StartEdit
  | GoEditStepOne
  | ResetOverlayPanel
  | SaveStepOne
  | SaveEditStepOne
  | SaveStepTwo
  | SaveEditStepTwo
  | SaveStepThree
  | GoStepOne
  | GoStepTwo
  | GoEditStepTwo
  | GoStepThree
  | UpdateSelectedBuildings
  | GoToThreshold
  | BeginEditScheduleException
  | BeginEditAnnotation;

const overlayFormReducer = (
  state: OverlayFormState,
  action: OverlayFormAction,
): OverlayFormState => {
  if (action === null) {
    return state;
  } else {
    switch (action.type) {
      case "GO_STEP_ONE":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_1",
          },
        };
      case "PRE_FILL_FORM_DATA":
        return {
          ...state,
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "GO_STEP_TWO":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_2",
          },
        };
      case "GO_EDIT_STEP_TWO":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "EDIT_2",
          },
        };
      case "SAVE_STEP_ONE":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_2",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "SAVE_EDIT_STEP_ONE":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "EDIT_2",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "SAVE_STEP_TWO":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_3",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "SAVE_EDIT_STEP_TWO":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "EDIT_3",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "SAVE_STEP_THREE":
        return {
          ...state,
          formData: emptyFormData,
          ui: {
            ...state.ui,
            overlayOpen: false,
          },
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_1",
          },
        };
      case "CLOSE_OVERLAY":
        return {
          ...state,
          ui: {
            ...state.ui,
            overlayOpen: false,
          },
        };
      case "OPEN_OVERLAY":
        return {
          ...state,
          ui: {
            ...state.ui,
            overlayOpen: true,
          },
        };
      case "START_CREATE":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_1",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "START_EDIT":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "EDIT_1",
          },
        };
      case "GO_EDIT_STEP_ONE":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "EDIT_1",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "RESET_OVERLAY_PANEL":
        return {
          ...state,
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_1",
          },
          formData: emptyFormData,
        };
      case "UPDATE_SELECTED_BUILDINGS": {
        return {
          ...state,
          ui: {
            ...state.ui,
            buildingsSelected: action.payload.buildings,
          },
        };
      }
      case "GO_TO_THRESHOLD": {
        return {
          ...state,
          thresholdConfig: {
            ...state.thresholdConfig,
            ...(action.payload.initialBuildingId
              ? { initialBuildingId: action.payload.initialBuildingId }
              : {}),
          },
          overlayPanel: {
            ...state.overlayPanel,
            state: "CREATE_THRESHOLD",
          },
        };
      }
      // TODO: Combine these next two actions into one for general overlays. Right
      //   now they only *look* identical but the type of the payload is different.
      case "BEGIN_EDIT_SCHEDULE_EXCEPTION":
        return {
          ...state,
          ui: { ...state.ui, overlayOpen: true },
          overlayPanel: {
            ...state.overlayPanel,
            state: "EDIT_1",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      case "BEGIN_EDIT_ANNOTATION":
        return {
          ...state,
          ui: { ...state.ui, overlayOpen: true },
          overlayPanel: {
            ...state.overlayPanel,
            state: "EDIT_1",
          },
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };
      default:
        return state;
    }
  }
};

export { overlayFormReducer, initialState };
