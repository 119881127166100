export const AppColors = {
  primary: {
    "msr-green": "#2DB892",
    "light-msr-green": "#32CCA2",
    "light-msr-green-1": "#46D2AC",
    backgroundGreen: "#e1e9e1",
  },
  semantic: {
    yellow: {
      yellow: "#FFC84D",
      "light-yellow-1": "#FFD880",
      "light-yellow-2": "#FFE9B7",
      "light-yellow-3": "#FFE8B3",
      "light-yellow-4": "#FFEFCD",
    },
    lightBlue: {
      seafoam: "#91D8D5",
      "light-seafoam": "#A4DFDC",
      "light-seafoam-1": "#B7E5E3",
      "light-seafoam-2": "#D3EFEE",
      "light-seafoam-3": "#DCF3F2",
      "light-seafoam-4": "#EFF9F9",
    },
    red: {
      red: "#D33336",
      "dark-red-3": "#8A2227",
      "light-red-1": "#D6565D",
      "light-red-3": "#E59398",
      "light-red-7": "#F9E5E6",
    },
    orange: {
      orange: "#F65C18",
    },
    green: {
      "dark-msr-green-1": "#238F71",
      "dark-msr-green-2": "#1E7B61",
      "light-msr-green": "#32CCA2",
      "light-msr-green-3": "#6FDCBE",
      "light-msr-green-4": "#84E1C7",
      "light-msr-green-6": "#ADEBDA",
      "light-msr-green-8": "#D6F5EC",
      "light-msr-green-9": "#EAF8F4",
    },
    blue: {
      sky: "#2D8EB8",
      "dark-sky": "#287EA4",
      "light-sky-1": "#46A8D2",
      "light-sky-3": "#6FBBDC",
      "light-sky-8": "#D6EBF5",
    },
    purple: {
      purple: "#53568E",
      purple45: "#ED72D4",
    },
    lightPurple: {
      "light-purple": "#9B9EDA",
      lightPurple45: "#d9beef",
      "light-purple-1": "#DEDEE9",
    },
    lightGreen: {
      "lime-green": "#C0C011",
      "light-lime-green-1": "#EBEB19",
      "light-lime-green-3": "#EFEF48",
      "light-lime-green-8": "#F9F9BD",
    },
  },
  neutral: {
    navy: "#303D51",
    "dark-navy-2": "#141921",
    "light-navy-1": "#435571",
    "light-navy-3": "#516789",
    "light-navy-5": "#7087AA",
    "light-navy-9": "#B9C2CF",
    "light-navy-10": "#C6D0DE",
    "light-navy-11": "#E8ECF2",
    "light-gray-9": "#F6F6F6",
    white: "#FFFFFF",
    black: "#303D51",
  },
  explorer: {
    green: "#2DB892",
    lightBlue: "#56adff",
    purple: "#7739ae",
    orange: "#f5a623",
    lime: "#98d950",
    blue: "#2357d3",
    lightPurple: "#b37ddf",
    pink: "#ed72d4",
    rust: "#f86207",
    yellow: "#f5e300",
  },
} as const;
