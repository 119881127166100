import React, { FC } from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import {
  MxReactIcon,
  ErrorTriangle,
} from "src/componentLibrary/react/mx-icon-react";
import { HyperLink } from "src/components/common/Link";
import { H3, Body } from "src/components/common/Typography";
import { AppColors } from "../Styling";
import config from "src/configuration";

interface IUserInfoMessage {
  messageId: string;
  titleId?: string;
  icon?: FC;
}

const UserInfoWrapper = styled.div``;
const CenterWrapper = styled.div`
  max-width: 30em;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UserInfoContentWrapper = styled.div`
  padding: 32px;
  max-width: 30em;
  margin: 0 auto;
`;

const IconWrapper = styled.div`
  background-color: ${AppColors.semantic.yellow["light-yellow-3"]};
  margin-bottom: 8px;
  padding: 8px;
`;

export const UserInfoMessage: React.FC<IUserInfoMessage> = ({
         messageId,
         icon = ErrorTriangle,
         titleId,
       }) => {
         return (
           <UserInfoWrapper>
             <IconWrapper>
               <CenterWrapper>
                 <MxReactIcon
                   Icon={icon}
                   size="m"
                   color={AppColors.semantic.yellow.yellow}
                   style={{ marginRight: "16px" }}
                 />
                 {titleId && (
                   <H3>
                     <FormattedMessage id={titleId} />
                   </H3>
                 )}
               </CenterWrapper>
             </IconWrapper>
             <UserInfoContentWrapper>
               <Body>
                 <FormattedMessage id={messageId} />
                 {": "}
                 <HyperLink href={"mailto:" + config.SUPPORT_EMAIL}>
                   <FormattedMessage id="support.url" />
                 </HyperLink>
               </Body>
             </UserInfoContentWrapper>
           </UserInfoWrapper>
         );
       };
