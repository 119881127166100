import * as React from "react";
import styled from "styled-components/macro";
import aoLogoMark from "./ao__logo-mark.svg";
import aoLogoText from "./ao__logo-text.svg";

export enum LogoSize {
  small = "42px",
  medium = "72px",
  large = "98px",
}

// The icon will be smaller than LogoSize
const getIconSizeFromLogoSize = (size: LogoSize) => {
  switch (size) {
    case LogoSize.small:
      return "20px";
    case LogoSize.medium:
      return "36px";
    case LogoSize.large:
      return "52px";
  }
};

const LogoContainer = styled.div<{ size: LogoSize }>`
  height: ${props => props.size};
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    height: ${props =>
      props.size === LogoSize.large ? LogoSize.medium : props.size};
  }
  /* this is needed to fix an IE11 bug. Sorry about that. */
  @media all and (-ms-high-contrast: none) {
    flex-basis: 180px;
  }
  @media (max-width: ${props =>
      props.size === LogoSize.small ? "900px" : "300px"}) {
    flex-basis: auto;
  }
`;

const LogoIconContainer = styled.div<{ size: LogoSize }>`
  height: ${props => getIconSizeFromLogoSize(props.size)};
  padding-right: ${props => {
    switch (props.size) {
      case LogoSize.small:
        return "10px";
      case LogoSize.medium:
        return "16px";
      case LogoSize.large:
        return "24px";
    }
  }};
  @media (max-width: 600px) {
    height: ${props =>
      props.size === LogoSize.large
        ? getIconSizeFromLogoSize(LogoSize.medium)
        : getIconSizeFromLogoSize(props.size)};
  }
`;

export const LogoIcon = (props: { size: LogoSize }) => {
  const { size } = props;
  return (
    <LogoIconContainer size={size}>
      <LogoIconSvg src={aoLogoMark} alt="measurabl logo icon" />
    </LogoIconContainer>
  );
};

const LogoIconSvg = styled.img`
  height: 100%;
`;

const LogoTextSvg = styled.img<{ size: LogoSize }>`
  height: ${props => props.size};
  @media (max-width: ${props =>
      props.size === LogoSize.small ? "900px" : "300px"}) {
    display: none;
  }

  @media (max-width: 600px) {
    height: ${props =>
      props.size === LogoSize.large ? LogoSize.medium : props.size};
  }
`;

type AOLogotypeProps = {
  size?: LogoSize;
};

const AOLogotype: React.FC<AOLogotypeProps> = ({
  size = LogoSize.small,
}: AOLogotypeProps) => (
  <LogoContainer size={size}>
    <LogoIcon size={size} />
    <LogoTextSvg src={aoLogoText} size={size} alt="measurabl logo text" />
  </LogoContainer>
);

export default AOLogotype;
