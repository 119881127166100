import * as React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { AppColors } from "src/components/common/Styling";
export interface INavigationItemProps {
  children: React.ReactNode;
  active?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const colors = {
  active: AppColors.neutral.navy,
  hover: AppColors.primary["light-msr-green-1"],
  link: AppColors.neutral["light-navy-1"],
  underline: AppColors.primary["msr-green"],
} as const;

export const ActiveBorderContainer = styled.div<INavigationItemProps>`
  // using a box-shadow instead of border so it doesn't shift children
  box-shadow: ${props =>
    props.active === "true"
      ? `inset 0px -4px 0px 0px ${colors.underline}`
      : "none"};
`;

const NavigationItem = styled.a<INavigationItemProps>`
  text-decoration: none;
  height: 100%;
  background-color: inherit;
  padding-top: 20px;
  display: inline-block;
  font-size: 15px;
  list-style: none;
  cursor: pointer;
  border-bottom: ${(props: INavigationItemProps) =>
    props.active === "true" ? `4px solid ${colors.underline}` : "none"};
  font-weight: ${(props: INavigationItemProps) =>
    props.active === "true" ? 600 : 400};

  :not(:first-child) {
    margin-left: 50px;
    @media (max-width: 800px) {
      margin-left: 16px;
    }
  }

  &:link {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.link};
  }
  &:visited {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.link};
  }
  &:visited:hover {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.hover};
  }
  &:hover {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.hover};
  }
  &:active {
    color: "${colors.active}";
  }
  &:focus {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.link};
  }
`;

export const NavigationLink = styled(Link)`
  text-decoration: none;
  height: 100%;
  background-color: inherit;
  padding-top: 20px;
  display: inline-block;
  font-size: 15px;
  list-style: none;
  cursor: pointer;
  border-bottom: ${(props: INavigationItemProps) =>
    props.active === "true"
      ? `4px solid ${AppColors.primary["msr-green"]}`
      : "none"};
  font-weight: ${(props: INavigationItemProps) =>
    props.active === "true" ? 600 : 400};

  :not(:first-child) {
    margin-left: 50px;
    @media (max-width: 800px) {
      margin-left: 16px;
    }
  }

  &:link {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.link};
  }
  &:visited {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.link};
  }
  &:visited:hover {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.hover};
  }
  &:hover {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.hover};
  }
  &:active {
    color: "${colors.active}";
  }
  &:focus {
    color: ${(props: INavigationItemProps) =>
      props.active === "true" ? colors.active : colors.link};
  }
`;
export default NavigationItem;
