import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Auth from "src/auth/Auth";
import { SpinnerIndicator } from "src/components/common/LoadingIndicator";
import CenteredContentWrapper from "../CenteredContentWrapper";
import { FormattedMessage } from "react-intl";
import { H1 } from "src/components/common/Typography";
import CenteredBox from "src/components/app/CenteredBox";
import Footer from "../Footer";
import { tracking } from "../../../tracking";

const InitPage: React.FC = () => {
  /**
   * Directs a user to the navPath. Bill management URL now handled in Auth page.
   * @param navPath the url to direct the user to in the platform
   */
  const navigate = useNavigate();

  const redirectUser = (navPath: string) => {
    navigate(navPath);
  };

  useEffect(() => {
    const storedRedirectUri = localStorage.getItem("redirectUri");
    localStorage.removeItem("redirectUri");
    const navPath =
      storedRedirectUri === null ? `/performance` : storedRedirectUri;

    Auth.handleAuthentication()
      .then(() => {
        tracking.fireLoggedIn(
          Auth.getUserId(),
          Auth.shouldRememberCredentials(),
        );

        redirectUser(navPath);
      })
      .catch(() => {
        if (!Auth.isAuthenticated()) {
          Auth.logout();
        } else {
          redirectUser(navPath);
        }
      });
  });

  return (
    <CenteredContentWrapper>
      <CenteredBox>
        <SpinnerIndicator />
        <H1>
          <FormattedMessage id="login.loading" />
        </H1>
      </CenteredBox>
      <Footer />
    </CenteredContentWrapper>
  );
};

export default InitPage;
