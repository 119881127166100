export interface LayoutProps {
    childSpacing?: number; /** number of grid units between layout children */
}

export interface ResponsiveLayoutProps extends LayoutProps {
  respondsAt?: string; /** max-width value for media query where the layout will swtich to vertical */
  responsiveChildSpacing?: number; /** if you want different child spacing when responding */
}

const GRID_UNIT = 4;
const gridToPx = (gridSize: number) => `${gridSize * GRID_UNIT}px`;
/**
 *  Turns a value or array of values to grid measurements 
 * @param val 
 */
const propsToPx = (val: number | number[] = 0) : string => {
  if(typeof val === 'number') {
    return gridToPx(val);
  } else {
    return val.map(gridToPx).join(" ")
  }
}

export {
    propsToPx,
}