import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";

interface MxReactToggleProps {
  isEnabled: boolean;
}
export const MxReactToggle = styled.button<MxReactToggleProps>`
  border: 1px solid ${AppColors.neutral["light-navy-5"]};
  cursor: pointer;
  background-color: ${props =>
    props.isEnabled ? AppColors.neutral["light-navy-5"] : "transparent"};
  color: ${props =>
    props.isEnabled
      ? AppColors.neutral["light-gray-9"]
      : AppColors.neutral["light-navy-5"]};
  border-radius: 5px;
  padding: 8px 16px;
  text-align: center;
  height: 35px;
  line-height: 0;
  /* body typography */
  font-size: 16px;
  font-weight: 300;
`;
