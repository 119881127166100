import { LineProps, XAxisProps, YAxisProps } from "recharts";
import {
  TimeseriesNode,
  DataQueueItem,
  OverlayEvents,
  OverlayTimeSpans,
} from "src/components/app/ExplorerPage/types";

import * as gqlTypes from "src/types/graphql";
import { BaselineEvaluationGranularity } from "src/types/graphql";

export const PointType = gqlTypes.PointType;
export type PointType = typeof PointType[keyof typeof PointType];
export const Unit = gqlTypes.Unit;
export type Unit = typeof Unit[keyof typeof Unit];

export enum FeatureType {
  HOURLY_BASELINES = "HOURLY_BASELINES",
  NO_OP = "NO_OP",
  BE_INTEGRATION = "BE_INTEGRATION",
  UTILITY_DATA_MANAGEMENT = "UTILITY_DATA_MANAGEMENT",
  READ_ONLY_UTILITY_DATA_MANAGEMENT = "READ_ONLY_UTILITY_DATA_MANAGEMENT",
  PLATFORM_NO_REAL_TIME = "PLATFORM_NO_REAL_TIME",
  REALTIME_CARBON = "REALTIME_CARBON",
}

export enum ComparePeriod {
  PAST_YEAR = "PAST_YEAR",
  PREVIOUS_PERIOD = "PREVIOUS_PERIOD",
  CUSTOM = "CUSTOM",
  NONE = "NONE",
}

export interface FeatureEnablement {
  name: FeatureType;
  enabled: boolean;
}

export enum ChartDataRange {
  Today = 1,
  SevenDays = 7,
  ThirtyDays = 30,
  FortyFiveDays = 45,
  NinetyDays = 90,
  Year = 365,
}

export enum ChartDataGranularity {
  // OneMinute = 1,
  FiveMinute = "FIVE_MINUTE",
  FifteenMinute = "FIFTEEN_MINUTE",
  Hour = "HOUR",
  Day = "DAY",
  Month = "MONTH",
}

// in case things change, we only have to update in one spot.
export type XAxisData = Map<string, XAxisProps>;
export type YAxisData = Map<string, YAxisProps>;
export type ChartRenderData = Map<string, CommodityChartData>;

export interface ParsedChartData {
  xAxes: XAxisData;
  yAxes: YAxisData;
  charts: ChartRenderData;
  data: TimeseriesNode[];
  events: OverlayEvents;
  overlaySpans: OverlayTimeSpans;
  metadata: ChartMetadata; // keyed off of UNIT
  metrics: ChartMetadata; // keyed off of COMMODITY
  queueItems: DataQueueItem[];
  thresholds: BuildingThreshold[];
  overlays?: {
    operatingSchedule?: {
      enabled: boolean;
    };
  };
}

export type BuildingThreshold = {
  value: number;
  type: PointType;
  assetId: string;
  assetName: string;
};

type TimedMinMax = {
  value: number | null | undefined;
  timestamp: string | Date | number | undefined;
};
export type MetaInfo = {
  min: TimedMinMax;
  max: TimedMinMax;
  average: number | undefined;
  sum: number | undefined;
  timezone: string | undefined;
};

//TODO check the best way to type the metadata
//      this is an area where typescript really sucks.
export interface ChartMetadata {
  [key: string]: {
    // timestamp key
    [key: string]:
      | {
          // commodity _or_ unit symbol key
          [key: string]: MetaInfo;
        }
      | string
      | undefined;
    timezone: string | undefined;
  };
}

export interface CommodityChartData {
  type: string;
  visible: boolean;
  queueItem: DataQueueItem;
  chartProps: LineProps;
  isPastComparison: boolean | undefined;
}

export enum ChartSummaryType {
  Weather = "Weather",
  Commodity = "Commodity",
  Baseline = "Baseline",
  Hdd = "HDD",
}

export const TemperatureUnit = gqlTypes.TemperatureUnit;
export type TemperatureUnit = typeof TemperatureUnit[keyof typeof TemperatureUnit];

// TODO: whut? This seems not wise
export enum ChartType {
  electricity = "electricity",
  TEMPERATURE = "TEMPERATURE",
  naturalGas = "natural_gas",
  HUMIDITY = "HUMIDITY",
  coolingDegreeDays = "cdd",
  heatingDegreeDays = "hdd",
  value = "value",
}

export enum DataStatus {
  INVALID_MODEL = "INVALID_MODEL",
  OK = "OK",
}

export interface IMinMaxVals<T> {
  minima: T | undefined;
  maxima: T | undefined;
}

// TODO: think about a move to the generated `TimeRange` type
// the strings here are ISO 8601
export interface ITimeRange {
  startTime: string;
  endTime: string;
}

export interface IUnitOfMeasurement {
  name: string;
  symbol: string;
}

// these come in as strings, but are converted to Date
// when the query returns.
export interface IMeasurement {
  timestamp: string | Date;
  value: number | null;
  TEMPERATURE?: number | null;
  HUMIDITY?: number | null;
}

export interface IIntervalData {
  name: string;
  unit: Unit;
  range: ITimeRange;
  granularity: ChartDataGranularity;
  values: IMeasurement[];
  // these are only on baselines as of Sept. 2019
  message?: string;
  status?: DataStatus;
  trainingPeriod?: ITimeRange;
}

export const WeatherPointType = {
  HUMIDITY: "HUMIDITY",
  TEMPERATURE: "TEMPERATURE",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type WeatherPointType = typeof WeatherPointType[keyof typeof WeatherPointType];

export interface IPoint {
  id: string;
  name?: string;
  type?: PointType;
  customerPointId?: string;
  pointData: IIntervalData;
}

export interface IMeter {
  name: string;
  commodityName: string;
  customerMeterId: string;
  meterData: IIntervalData;
}

export interface IMeterPoints {
  id: string;
  name: string;
  commodityName: string;
  customerMeterId: string;
  points: IPoint[];
}

export interface IBuildingInfo {
  id: string;
  name: string;
  location: {
    timezone: string;
  };
  weatherData?: IWeatherData;
  electricityUsageData?: IIntervalData;
  electricityDemandData?: IIntervalData;
  commodityUsageData?: IIntervalData;
  totalElectricityUsage?: IAggregateData;
  baselineUsage?: IIntervalData;
  degreeDayData?: IDegreeDayData;
  buildingNonOperatingTimeRanges?: ITimeRange[];
  scheduleExceptions?: gqlTypes.ScheduleException[];
  annotations?: gqlTypes.BuildingAnnotation[];
}

export type GetBaselineUsageResponse = {
  getBuildingById: {
    id: string;
    location: {
      timezone: string;
    };
    weatherData: {
      temperatureUnit: TemperatureUnit;
      values: IWeatherMeasurement[];
    };
    commodityUsageData: {
      name: string;
      unit: Unit;
      // TODO: Replace with IMeasurement?
      values: {
        value: number | null;
        timestamp: string | Date;
      }[];
    };
    baselineUsage: {
      name: string;
      unit: Unit;
      status: DataStatus;
      message: string;
      // TODO: Replace with IMeasurement?
      values: {
        timestamp: string | Date;
        value: number | null;
      }[];
      trainingPeriod:
        | ITimeRange
        | {
            startTime: undefined;
            endTime: undefined;
          };
      metrics: {
        overallSMAE?: number;
      };
    };
    buildingNonOperatingTimeRanges: ITimeRange[];
  };
};

export type BaselineModelResponse = {
  evaluationGranularity: BaselineEvaluationGranularity;
  id: string;
  isGlobalDefault: boolean;
  modelName: string;
  pointType: PointType;
  valid: boolean;
};

export interface IWeatherData {
  granularity: ChartDataGranularity;
  temperatureUnit:
    | TemperatureUnit
    | typeof Unit.CELSIUS
    | typeof Unit.FAHRENHEIT;
  values: IWeatherMeasurement[];
  aggregateMeasurements: IWeatherAggregate;
}

export interface IWeatherMeasurement {
  timestamp: string;
  temperature: number | null;
  relativeHumidity: number | null;
}

export interface IWeatherAggregate {
  averageTemperature: number | null;
  averageHumidity: number | null;
}

export interface IIntervalQueryParams {
  granularity: ChartDataGranularity;
  range: ITimeRange;
}

export interface BuildingVariables {
  granularity: ChartDataGranularity;
  startTime: string;
  endTime: string;
  includeWeather: boolean;
  includeUsage?: boolean;
  includeDemand?: boolean;
}

export interface SpaceGroupResponse {
  spaceGroup: {
    id: string;
    name: string;
    commodityUsageData: {
      timezone: string; // timezone the data was normailzied to since it's many buildings
      commodityUsage: IIntervalData;
    };
  };
}

export interface BuildingResponse {
  getBuildingById: IBuildingInfo;
}

export interface MeterDataResponse extends BuildingResponse {
  getMeterById: IMeterPoints;
}

// response of the POINT_COMMODITY_DATA query
// that is in src/queries/commodity
export type EquipmentPointDataResponse = {
  equipmentPointById: {
    id: string;
    name: string;
    pointData: IIntervalData;
  };
};

export interface IDegreeDayData {
  timeRange: ITimeRange;
  temperatureUnit: TemperatureUnit;
  totalHeatingDegreeDays: number;
  totalCoolingDegreeDays: number;
  totalHDDElectricityUsage: IAggregateData;
  totalCDDElectricityUsage: IAggregateData;
  heatingDegreeDayData: IDegreeMeasurement[];
  coolingDegreeDayData: IDegreeMeasurement[];
}

export interface IAggregateData {
  name: string;
  measuredIn: IUnitOfMeasurement;
  range: ITimeRange;
  value: number;
}

export interface IDegreeMeasurement {
  timestamp: string;
  value: number;
}

export interface ICombinedMeasurement {
  timestamp: number;
  [key: string]: number | null;
}

export interface ICombinedIntervalData {
  range: ITimeRange;
  granularity: ChartDataGranularity;
  values: ICombinedMeasurement[];
  units: {
    [key: string]: string;
  };
  labels: {
    [key: string]: string;
  };
}

export interface ProfileSeries {
  values: IMeasurement[];
  unit: Unit;
  range: ITimeRange;
  timezone?: string;
}

export type PointDataType = { [key: string]: string };

export enum ExportType {
  Csv = "CSV",
  Pdf = "PDF",
}

export type MapTooltipContent = {
  name?: string | null;
  groupName?: string | null;
  usage: number;
  eui: number;
  latitude: number;
  longitude: number;
};

export type CombinedMeasurement<T extends string> = { timestamp: number } & {
  [key in T]: number | null;
};

export type CombinedIntervalData<T extends string> = {
  range: ITimeRange;
  granularity: ChartDataGranularity;
  values: CombinedMeasurement<T>[];
  units: {
    [key in T]: string;
  };
  labels: {
    [key in T]: string;
  };
};

export type VisibilityFlags<T extends string> = {
  [key in T]: boolean;
};
