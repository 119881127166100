import { createContext, PropsWithChildren, useEffect, useMemo } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enGB, enUS, de } from "date-fns/esm/locale";

type LocaleContextProps = {
  locale: string;
};

const LocaleContext = createContext<Partial<LocaleContextProps>>({});

const LocaleProvider: React.FC<PropsWithChildren<unknown>> = props => {
  const locale = useMemo(() => {
    return navigator.language || "en";
  }, []);

  /**
   * react-datepicker doesn't automatically pick up the locale; you have to
   * register any and all locales you support and then assign one at either
   * the component level or at the top level as we do here. If one is not set,
   * it defaults to "en".
   * We'll probably want to handle this a prettier way as we implement more
   * localization stuff, but for now this will have to do.
   */
  useEffect(() => {
    registerLocale("de", de);
    registerLocale("gb", enGB);
    registerLocale("en", enUS);

    if (locale === "en-GB") {
      setDefaultLocale("gb");
    } else if (locale === "de-DE") {
      setDefaultLocale("de");
    }
  }, []);

  return (
    <LocaleContext.Provider value={{ locale }}>
      {props.children}
    </LocaleContext.Provider>
  );
};

export { LocaleProvider };
