import {
  AssetType,
  DataQueueItem,
  MeasureEventType,
  OverlayEventState,
  ScheduleEventType,
  ThresholdEventType,
  AnnotationEventType,
} from "src/components/app/ExplorerPage/types";
import * as helpers from "src/components/app/ExplorerPage/helpers";
import { makeAssetKeyForDataQueueItem } from "src/components/app/ExplorerPage/helpers";
import {
  ChartDataGranularity,
  ChartDataRange,
  ITimeRange,
  PointType,
} from "src/types/charting";
import {
  chartDataRangeFromInterval,
  createOneDayInAdvanceTimeRange,
  createTimeRange,
} from "src/helpers/charting";
import {
  SelectorAsset,
  SelectorData,
  SelectorPoint,
} from "../MultiCommoditySelector/types";

type SelectorDataKeysToMappedThings =
  | "buildings"
  | "groups"
  | "points"
  | "equipmentPoints"
  | "meters"
  | "organizations"
  | "equipment";

/**
 * Grab a specific asset from the SelectorData object given its assetType and assetId
 * @param parsedAssetData
 * @param assetType
 * @param assetId
 */
function getAssetFromParsedAssetData(
  parsedAssetData: SelectorData,
  assetType: AssetType,
  assetId: string,
) {
  const assetTypeKeyMapper: Record<
    keyof typeof AssetType,
    SelectorDataKeysToMappedThings
  > = {
    [AssetType.BUILDING]: "buildings",
    [AssetType.GROUP]: "groups",
    [AssetType.POINT]: "points",
    [AssetType.EQUIPMENT_POINT]: "equipmentPoints",
    [AssetType.METER]: "meters",
    [AssetType.ORGANIZATION]: "organizations",
    [AssetType.EQUIPMENT]: "equipment",
  };
  return parsedAssetData[assetTypeKeyMapper[assetType]].get(assetId);
}

type AssetQueueItemInput = {
  asset: SelectorAsset & {
    timezone?: string;
    latitude?: number;
    longitude?: number;
    meterId?: string;
    meterName?: string;
  };
  assetType: AssetType;
  commodity: PointType;
  timeRange: ITimeRange;
  granularity: ChartDataGranularity;
};

/**
 * Make a DataQueueItem for specified asset of any assetType
 * @param asset
 * @param assetType
 * @param commodity
 * @param timeRange
 * @param granularity
 */
function makeAssetQueueItem({
  asset,
  assetType,
  commodity,
  timeRange,
  granularity,
}: AssetQueueItemInput): DataQueueItem {
  return {
    assetId: asset.id,
    timezone: asset.timezone, // will be undefined for groups, that's OK
    latitude: asset.latitude,
    longitude: asset.longitude,
    timeseriesKey: helpers.keyFromTimeRange(timeRange),
    assetType,
    commodity,
    timeRange,
    granularity,
    queryId:
      assetType === AssetType.POINT ? asset.meterId || asset.id : asset.id,
    assetName:
      assetType === AssetType.POINT
        ? (asset as SelectorPoint).meterName
        : asset.name,
    assetKey: makeAssetKeyForDataQueueItem(assetType, asset.id),
  };
}

/**
 * Take a commodityList as a comma-separated string of commodity names and
 * return a list of only those that are valid ChartDataTypes
 * @param commodityList List of commodity names as comma-separated string
 */
function validatedCommodityList(commodityList: string): PointType[] {
  const validCommodityList: PointType[] = [];
  if (commodityList) {
    commodityList.split(",").forEach(commodityName => {
      try {
        validCommodityList.push(helpers.commodityFromString(commodityName));
      } catch (e) {
        console.error("Invalid commodity type %s from URL", commodityName);
      }
    });
  }
  return validCommodityList;
}

/**
 * Create a flat list of DataQueueItems of the specified assetType from the URL string
 * @param queryParamsValues Query params from the URL string
 * @param assetType Asset type to extract from the URL string
 * @param timeRange Time range for the data fetch
 * @param parsedAssetData SelectorData object from the asset selector
 * @param granularity Granularity for the data fetch
 */
const createQItemsFromURL = (
  queryParamsValues: string,
  assetType: AssetType,
  timeRange: ITimeRange,
  parsedAssetData: SelectorData,
  granularity: ChartDataGranularity,
): DataQueueItem[] => {
  const valuesForAssetType = queryParamsValues.split(";");

  const allAssetQueueItems = valuesForAssetType.map(
    (_value): DataQueueItem[] => {
      const [assetId, commodityList] = _value.split(":");

      // If there's nothing to process return empty []
      if (!assetId || !commodityList) {
        return [];
      }

      // Keep only valid commodity names from the commodityList
      const validCommodityList: PointType[] = validatedCommodityList(
        commodityList,
      );

      // Coerce asset types other than BUILDING, GROUP, and EQUIPMENT_POINT to be POINT
      const assetTypeForQueueItem =
        assetType === AssetType.BUILDING ||
        assetType === AssetType.GROUP ||
        assetType === AssetType.EQUIPMENT_POINT
          ? assetType
          : AssetType.POINT;

      // Get the specified asset from the SelectorData object
      const asset = getAssetFromParsedAssetData(
        parsedAssetData,
        assetTypeForQueueItem,
        assetId,
      );

      // If we got it, make queue items for each commodity in the list, otherwise return empty []
      return asset
        ? validCommodityList.map(
            (commodity): DataQueueItem => {
              return makeAssetQueueItem({
                asset,
                assetType: assetTypeForQueueItem,
                commodity,
                timeRange,
                granularity,
              });
            },
          )
        : [];
    },
  );

  // Give back the flattened list of all queue items for the specified asset type
  return allAssetQueueItems.flat();
};

const initialOverlayState: OverlayEventState = {
  IMPLEMENTED: false,
  IDENTIFIED: false,
  SCHEDULE_EXCEPTIONS: true,
  THRESHOLDS: false,
  PEAK_DEMAND: false,
  HIGH_USAGE: false,
  CUSTOM: false,
  OPERATING_SCHEDULE: false,
};

// TODO1522: This was supposed to be binary but 0's just kept being added.
// It's currently following an inadvertent pattern of doubling the previous
// mask, which is still a pattern.
const overlayStateMasks = {
  [MeasureEventType.Identified]: 0b01,
  [MeasureEventType.Implemented]: 0b10,
  [ScheduleEventType.ScheduleExceptions]: 0b100,
  [ThresholdEventType.Thresholds]: 0b1000,
  [AnnotationEventType.PeakDemand]: 0b10000,
  [AnnotationEventType.HighUsage]: 0b100000,
  [AnnotationEventType.Custom]: 0b1000000,
  [ScheduleEventType.OperatingSchedule]: 0b10000000,
};

const compareMask = (value: number, mask: number): boolean =>
  (value & mask) === mask;

// the possible params we expect from our query string
type ExplorerQueryParams = {
  start?: string;
  end?: string;
  granularity?: string;
  timezone?: string;
  buildings?: string;
  points?: string;
  overlays?: string;
};

/** is passed an "exploded" URL query string and builds some values from it */
const getInitialParamsFromURL = ({
  start,
  end,
  granularity,
  timezone,
  overlays,
}: ExplorerQueryParams): {
  _chartDataRange: ChartDataRange;
  _chartTimeRange: ITimeRange;
  _chartGranularity: ChartDataGranularity;
  _overlayEventState: OverlayEventState;
} => {
  // Check if url is valid!
  if (!start || !end || !granularity) {
    return getDefaultValues(timezone);
  }

  //Graularity
  const _chartGranularity = getEnumMemberByStringValue(
    ChartDataGranularity,
    granularity,
  );

  // Build Interval
  const interval: ITimeRange = { startTime: start, endTime: end };

  //ChartRange
  const _chartDataRange: ChartDataRange = chartDataRangeFromInterval(interval);

  // If any is null/undefined we got something wrong from the url!
  // set some defaults values.
  if (!_chartDataRange || !_chartGranularity) {
    return getDefaultValues(timezone);
  }

  //Time Range
  let _chartTimeRange: ITimeRange = interval;
  if (
    isNaN(new Date(interval.startTime).getTime()) ||
    isNaN(new Date(interval.endTime).getTime())
  ) {
    _chartTimeRange = createTimeRange(
      _chartDataRange,
      timezone,
      _chartGranularity,
    );
  }

  /* =============================================================== *\
     turn on or off overlays based on URL state
  \* =============================================================== */
  const overlayValue = (overlays && parseInt(overlays)) || 0;
  const _overlayEventState = {
    [MeasureEventType.Identified]: compareMask(
      overlayValue,
      overlayStateMasks.IDENTIFIED,
    ),
    [MeasureEventType.Implemented]: compareMask(
      overlayValue,
      overlayStateMasks.IMPLEMENTED,
    ),
    [ScheduleEventType.ScheduleExceptions]: compareMask(
      overlayValue,
      overlayStateMasks.SCHEDULE_EXCEPTIONS,
    ),
    [ThresholdEventType.Thresholds]: compareMask(
      overlayValue,
      overlayStateMasks.THRESHOLDS,
    ),
    [AnnotationEventType.PeakDemand]: compareMask(
      overlayValue,
      overlayStateMasks.PEAK_DEMAND,
    ),
    [AnnotationEventType.HighUsage]: compareMask(
      overlayValue,
      overlayStateMasks.HIGH_USAGE,
    ),
    [AnnotationEventType.Custom]: compareMask(
      overlayValue,
      overlayStateMasks.CUSTOM,
    ),
    [ScheduleEventType.OperatingSchedule]: compareMask(
      overlayValue,
      overlayStateMasks.OPERATING_SCHEDULE,
    ),
  };

  return {
    _chartDataRange,
    _chartTimeRange,
    _chartGranularity,
    _overlayEventState,
  };
};

const getDefaultValues = (
  timezone?: string,
): {
  _chartDataRange: ChartDataRange;
  _chartTimeRange: ITimeRange;
  _chartGranularity: ChartDataGranularity;
  _chartDataType: PointType;
  _overlayEventState: OverlayEventState;
} => {
  const _chartGranularity = ChartDataGranularity.Hour;
  const _chartDataRange = ChartDataRange.ThirtyDays;
  const _chartDataType = PointType.ELECTRICITY_DEMAND;
  const _overlayEventState = initialOverlayState;

  let _chartTimeRange;

  if (timezone) {
    _chartTimeRange = createTimeRange(
      _chartDataRange,
      timezone,
      _chartGranularity,
    );
  } else {
    _chartTimeRange = createOneDayInAdvanceTimeRange(
      _chartDataRange,
      _chartGranularity,
    );
  }

  return {
    _chartDataRange,
    _chartTimeRange,
    _chartGranularity,
    _chartDataType,
    _overlayEventState,
  };
};

const getEnumMemberByStringValue = <T extends Record<string, string>>(
  _enum: T,
  val: string,
) => {
  const enumName = (Object.keys(_enum) as Array<keyof T>).find(
    k => _enum[k] === val,
  );
  if (!enumName) {
    return undefined;
  }
  return _enum[enumName];
};

export {
  createQItemsFromURL,
  getInitialParamsFromURL,
  getDefaultValues,
  getEnumMemberByStringValue,
};
