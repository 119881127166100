import React from "react";
import {
  MxReactIcon,
  ErrorTriangle,
  Info,
} from "src/componentLibrary/react/mx-icon-react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { AppColors } from "../Styling";

export enum GenericDataErrorType {
  Warning,
  Info,
}

type GenericDataErrorProps = {
  messageId?: string;
  type?: GenericDataErrorType;
};

export const GenericDataError: React.FC<GenericDataErrorProps> = props => {
  return (
    <Container>
      <MxReactIcon
        Icon={props.type === GenericDataErrorType.Info ? Info : ErrorTriangle}
        color={
          props.type === GenericDataErrorType.Info
            ? AppColors.semantic.blue.sky
            : AppColors.semantic.red.red
        }
        size="l"
      />
      <Text>
        <FormattedMessage
          id={props.messageId ?? "common.error.dataUnavailable"}
        />
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  margin: 48px 0;
`;

const Text = styled.div`
  width: 270px;
  margin: 48px 0;
  text-align: center;
`;

export default GenericDataError;
