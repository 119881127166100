import React, { MouseEventHandler, ReactElement, SetStateAction } from "react";
import {
  MeasureEvent,
  MeasureEventType,
  OverlayEvents,
  OverlayEventState,
  ScheduleEventType,
  ScheduleExceptionEvent,
  AnnotationEventType,
  AnnotationEvent,
} from "../types";
import { ReferenceLine, ReferenceLineProps } from "recharts";
import { AppColors } from "src/components/common/Styling";
import {
  includesDifferentEventTypes,
  mapEventTypeToOverlayType,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";
import {
  AnnotationMarker,
  MeasureIdentifiedMarker,
  MeasureImplementedMarker,
  MultiAnnotationMarker,
  MultiMarker,
  MultiMeasureMarker,
  MultiScheduleExceptionMarker,
  ScheduleExceptionMarker,
} from "src/components/app/ExplorerPage/ChartFactory/Markers";

export const eventReferenceLines = (
  events: OverlayEvents,
  yAxisId: string | number | undefined,
  xAxisId: string | number | undefined,
  overlayState: OverlayEventState,
  markersOnly?: boolean,
  handleAnnotationMarkerClick?: (annotationEvent: AnnotationEvent) => void,
  handleScheduleExceptionMarkerClick?: (
    scheduleExceptionEvent: ScheduleExceptionEvent,
  ) => void,
) => {
  /**
   * Returns the stroke color associated with the measure events. Defaults to a lovely
   * shade of msr-green if an appropriate match is not found.
   * @param events
   * @returns the stroke color for these events
   */
  const strokeColor = (
    events: MeasureEvent[] | ScheduleExceptionEvent[] | AnnotationEvent[],
  ) => {
    if (includesDifferentEventTypes(events)) {
      return AppColors.primary["msr-green"];
    }

    const eventType = events[0].eventType;

    if (mapEventTypeToOverlayType(eventType) === MeasureEventType) {
      if (events.length > 1) {
        return AppColors.semantic.blue.sky;
      }
      if (eventType === MeasureEventType.Identified) {
        return AppColors.semantic.blue.sky;
      }
      if (eventType === MeasureEventType.Implemented) {
        return AppColors.semantic.lightBlue.seafoam;
      }
    }

    if (mapEventTypeToOverlayType(eventType) === ScheduleEventType) {
      if (events.length > 1) {
        return AppColors.semantic.blue.sky;
      }
      return AppColors.semantic.lightPurple["light-purple"];
    }

    if (mapEventTypeToOverlayType(eventType) === AnnotationEventType) {
      return AppColors.semantic.yellow.yellow;
    }

    return AppColors.primary["msr-green"];
  };

  /**
   * Returns the fill color associated with the measure events. Defaults to a lovely
   * shade of hatch green if an appropriate match is not found.
   * @param events
   * @returns the fill color for these events
   */
  const fillColor = (
    events: MeasureEvent[] | ScheduleExceptionEvent[] | AnnotationEvent[],
  ) => {
    if (includesDifferentEventTypes(events)) {
      return AppColors.primary.backgroundGreen;
    }

    const eventType = events[0].eventType;

    if (mapEventTypeToOverlayType(eventType) === MeasureEventType) {
      if (events.length > 1) {
        return AppColors.semantic.blue["light-sky-3"];
      }
      if (eventType === MeasureEventType.Identified) {
        return AppColors.semantic.blue["light-sky-3"];
      }
      if (eventType === MeasureEventType.Implemented) {
        return AppColors.semantic.lightBlue["light-seafoam-3"];
      }
    }

    if (mapEventTypeToOverlayType(eventType) === ScheduleEventType) {
      if (events.length > 1) {
        return AppColors.semantic.blue["light-sky-3"];
      }
      return AppColors.semantic.lightPurple["light-purple-1"];
    }

    if (mapEventTypeToOverlayType(eventType) === AnnotationEventType) {
      return AppColors.semantic.yellow["light-yellow-4"];
    }

    return AppColors.primary["light-msr-green"];
  };

  /**
   * Returns the appropriate marker for the event type. If one cannot be found an empty element is returned.
   * @param props Props inherited from the reference line. used to calculate marker location
   * @param events an array of measure, schedule exception, or annotation events
   * @param stroke color used for marker border
   * @param fill color used to fill in the marker
   * @returns the appropriate marker for the event type or an empty element.
   */
  const markerLabel = (
    props: ReferenceLineProps,
    events:
      | MeasureEvent[]
      | ScheduleExceptionEvent[]
      | AnnotationEvent[]
      | undefined,
    stroke: string,
    fill: string,
  ): ReactElement<SVGElement> => {
    if (!events || events.length === 0) {
      return <></>;
    }

    if (includesDifferentEventTypes(events)) {
      return MultiMarker(props, stroke, fill, events);
    }

    const [event] = events;

    // Handle multi/single measure events
    if (mapEventTypeToOverlayType(event.eventType) === MeasureEventType) {
      if (events.length > 1) {
        return MultiMeasureMarker(
          props,
          stroke,
          fill,
          events as MeasureEvent[],
        );
      }
      if (event.eventType === MeasureEventType.Identified) {
        return MeasureIdentifiedMarker(props, stroke, fill, event);
      }
      if (event.eventType === MeasureEventType.Implemented) {
        return MeasureImplementedMarker(props, stroke, fill, event);
      }
    }

    // Handle multi/single schedule exception events
    if (mapEventTypeToOverlayType(event.eventType) === ScheduleEventType) {
      if (events.length > 1) {
        return MultiScheduleExceptionMarker(
          props,
          stroke,
          fill,
          events as ScheduleExceptionEvent[],
        );
      }
      return ScheduleExceptionMarker(
        props,
        stroke,
        fill,
        event as ScheduleExceptionEvent,
        handleScheduleExceptionMarkerClick,
      );
    }

    // Handle multi/single annotation events
    if (mapEventTypeToOverlayType(event.eventType) === AnnotationEventType) {
      if (events.length > 1) {
        return MultiAnnotationMarker(
          props,
          stroke,
          fill,
          events as AnnotationEvent[],
        );
      }
      return AnnotationMarker(
        props,
        stroke,
        fill,
        event as AnnotationEvent,
        handleAnnotationMarkerClick,
      );
    }

    return <></>;
  };

  const referenceLines: React.ReactNode[] = [];
  const keys = Array.from(events.keys());

  keys.forEach(key => {
    let overlayEvents = events.get(key);
    const enabledEvents = overlayEvents
      ? (overlayEvents.filter(
          e =>
            overlayState[e.eventType] &&
            (e.eventType === MeasureEventType.Identified ||
              e.eventType === MeasureEventType.Implemented ||
              e.eventType === ScheduleEventType.ScheduleExceptions ||
              e.eventType === AnnotationEventType.PeakDemand ||
              e.eventType === AnnotationEventType.HighUsage ||
              e.eventType === AnnotationEventType.Custom),
        ) as MeasureEvent[] | ScheduleExceptionEvent[] | AnnotationEvent[])
      : [];

    if (enabledEvents.length > 0) {
      const stroke = strokeColor(enabledEvents);
      const fill = fillColor(enabledEvents);

      referenceLines.push(
        <ReferenceLine
          key={`measure_` + key}
          ifOverflow={"hidden"}
          x={key}
          stroke={stroke}
          fill={fill}
          strokeDasharray="8 8"
          strokeWidth={markersOnly ? 0 : 2}
          yAxisId={yAxisId}
          xAxisId={xAxisId}
          label={(props: ReferenceLineProps) =>
            markerLabel(props, enabledEvents, stroke, fill)
          }
        />,
      );
    }
  });

  return referenceLines;
};
