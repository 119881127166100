import React, { FC } from "react";
import { BuildingThreshold } from "src/types/charting";
import OverlaysInfo from "src/components/app/ExplorerPage/OverlayStuff/OverlaysInfo";
import { Locale } from "@hatchdata/intl-formatter";
import Box from "src/components/common/Layout/Box";
import { FormattedMessage } from "react-intl";

export type BuildingThresholdWithColor = BuildingThreshold & { color?: string };

interface OverlaysTabProps {
  thresholds: BuildingThresholdWithColor[];
  userLocale: Locale;
  onEditThreshold: (assetId: string) => void;
}

const OverlaysTab: FC<OverlaysTabProps> = ({
  userLocale,
  thresholds,
  onEditThreshold,
}) => {
  return thresholds.length && thresholds.length > 0 ? (
    <OverlaysInfo
      thresholds={thresholds}
      userLocale={userLocale}
      onEditThreshold={onEditThreshold}
    />
  ) : (
    <Box padding={[0, 0, 0, 8]}>
      <FormattedMessage
        id={"charts.explorer.tabs.overlays.noThresholdsDisplayed"}
      />
    </Box>
  );
};

OverlaysTab.defaultProps = {
  userLocale: Locale.EN_US,
  thresholds: ([] as unknown) as BuildingThreshold[],
};

export default OverlaysTab;
