import React, { SetStateAction } from "react";
import ScheduleStepOne from "./CreateOverlayStuff/StepOne";
import ScheduleStepTwo from "./CreateOverlayStuff/StepTwo";
import ScheduleStepThreeExceptions from "./CreateOverlayStuff/StepThree_Exceptions";
import ScheduleStepThreeAnnotations from "./CreateOverlayStuff/StepThree_Annotations";
import { FormattedMessage } from "react-intl";
import { MxReactIcon, X } from "src/componentLibrary/react/mx-icon-react";
import {
  OverlayFormAction,
  OverlayFormState,
  OverlayType,
} from "./overlayReducer";
import { H3 } from "src/components/common/Typography";
import { OverlaysMenuContainer } from "../OverlaysMenuWrapper";
import { BookendLayout, Box } from "src/components/common/Layout";
import { IconButton } from "src/components/common/Button";
import { useUserContext } from "src/auth/UserContext";
import { ChartDataAction } from "../chartDataReducer";
import { ITimeRange } from "src/types/charting";
import { tracking } from "src/tracking";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import { ThresholdOverlayConfiguration } from "src/components/app/ExplorerPage/OverlayStuff/Thresholds/ThresholdConfiguration";
import { OverlayEventState } from "src/components/app/ExplorerPage/types";
import ScheduleStepThreeExceptionsMulti from "src/components/app/ExplorerPage/OverlayStuff/CreateOverlayStuff/StepThree_MultiDay";
import EditStepOne from "src/components/app/ExplorerPage/OverlayStuff/EditOverlayStuff/EditStepOne";
import EditStepThreeAnnotations from "src/components/app/ExplorerPage/OverlayStuff/EditOverlayStuff/EditStepThree_Annotations";

type OverlayContainerProps = {
  dispatch: (arg0: OverlayFormAction) => void;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
  state: OverlayFormState;
  timeRange: ITimeRange;
  overlayProps: {
    overlayState: OverlayEventState;
    setOverlayState: React.Dispatch<SetStateAction<OverlayEventState>>;
  };
  latitude: number;
  longitude: number;
};

const CreateOverlayContainer = (props: OverlayContainerProps) => {
  const headerTitle = () => {
    switch (props.state?.overlayPanel?.state) {
      case "EDIT_1":
      case "EDIT_2":
      case "EDIT_3":
        return <FormattedMessage id="charts.explorer.overlays.edit.heading" />;
      default:
        return (
          <FormattedMessage id="charts.explorer.overlays.create.heading" />
        );
    }
  };

  const isAnnotation = (overlayType: OverlayType | null) => {
    return (
      overlayType === OverlayType.PeakDemand ||
      overlayType === OverlayType.HighUsage ||
      overlayType === OverlayType.Custom
    );
  };

  return (
    <OverlaysMenuContainer>
      <OverlayHeader title={headerTitle()} {...props} />
      <hr />
      <Box padding={5}>
        {props.state.ui.buildingsSelected.length === 0 ? (
          <FormattedMessage id="charts.explorer.overlays.create.noBuilding" />
        ) : (
          <>
            {props.state?.overlayPanel?.state === "CREATE_1" && (
              <ScheduleStepOne {...props} />
            )}
            {props.state?.overlayPanel?.state === "CREATE_2" && (
              <ScheduleStepTwo
                {...props}
                overlayState={props.state?.overlayPanel.state}
              />
            )}
            {props.state?.overlayPanel?.state === "EDIT_2" && (
              <ScheduleStepTwo
                {...props}
                overlayState={props.state?.overlayPanel.state}
              />
            )}
            {(props.state?.overlayPanel?.state === "CREATE_3" ||
              props.state?.overlayPanel?.state === "EDIT_3") &&
              props.state?.formData.overlayType ===
                OverlayType.ScheduleException &&
              props.state?.formData.startDate?.getDate() !==
                props.state?.formData.endDate?.getDate() && (
                <ScheduleStepThreeExceptionsMulti {...props} />
              )}
            {(props.state?.overlayPanel?.state === "CREATE_3" ||
              props.state?.overlayPanel?.state === "EDIT_3") &&
              props.state?.formData.overlayType ===
                OverlayType.ScheduleException &&
              props.state?.formData.startDate?.getDate() ===
                props.state?.formData.endDate?.getDate() && (
                <ScheduleStepThreeExceptions {...props} />
              )}
            {props.state?.overlayPanel?.state === "CREATE_3" &&
              props.state?.formData.annotationCategories.length > 0 &&
              isAnnotation(props.state.formData.overlayType) && (
                <ScheduleStepThreeAnnotations {...props} />
              )}
            {props.state?.overlayPanel?.state === "EDIT_3" &&
              props.state?.formData.annotationCategories.length > 0 &&
              props.state?.formData.annotationType !== null && (
                <EditStepThreeAnnotations {...props} />
              )}
            {props.state.overlayPanel.state === "CREATE_THRESHOLD" && (
              <ThresholdOverlayConfiguration
                initialBuildingId={
                  props.state.thresholdConfig?.initialBuildingId
                }
                buildings={props.state.ui.buildingsSelected}
                overlayDispatch={props.dispatch}
                chartDispatch={props.chartDataDispatch}
                overlayProps={props.overlayProps}
              />
            )}
            {props.state?.overlayPanel?.state === "EDIT_1" && (
              <EditStepOne {...props} />
            )}
          </>
        )}
      </Box>
    </OverlaysMenuContainer>
  );
};

type OverlayHeaderProps = {
  title: JSX.Element;
  dispatch: (arg0: OverlayFormAction) => void;
};

const OverlayHeader = (props: OverlayHeaderProps) => {
  const { userData } = useUserContext();
  const userId = userData.id;
  const closeMe = () => {
    tracking.fireEvent(ExplorerPageEvents.CANCEL_ANNOTATION_CREATE, { userId });
    props.dispatch({ type: "CLOSE_OVERLAY", payload: { userId } });
    props.dispatch({ type: "RESET_OVERLAY_PANEL" });
  };

  return (
    <Box padding={5}>
      <BookendLayout>
        <H3>{props.title}</H3>
        <IconButton>
          <MxReactIcon Icon={X} onClick={() => closeMe()} />
        </IconButton>
      </BookendLayout>
    </Box>
  );
};

export default CreateOverlayContainer;
