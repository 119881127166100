import { MxReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";
import styled from "styled-components/macro";
import { SelectButton } from "../Button";

/**
 * Defines the standard layout and size for the top-most content
 * in a CardHeaderBar. By default, lays things out in a space-between
 * flex layout. It also contains a special rule for `SelectButton` elements
 * inside of it to make chart controls sane on smaller screens.
 */
export const CardHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  position: relative;

  /* THis is a compromise rule because the actual breakpoint will vary wildly based on if the 
sidebar is open or not. */

  /* if you change this width, you probably also want to change the rule in HeatingCoolingWithQuery for the TimePickerHolder component */
  @media (max-width: 1200px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    padding-top: 16px;

    & select {
      display: block;
      margin-bottom: 0px;
      width: 49%;
      margin-left: 1%;
    }
    & > * {
      margin-bottom: 8px;
    }

    ${SelectButton}, ${MxReactButton} {
      width: 49%;
      margin-left: 1%;
      /* margin-bottom: 8px; */
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    padding-top: 16px;

    & select {
      display: block;
      margin-bottom: 0px;
      width: 99%;
      margin-left: 1%;
    }
    & > * {
      margin-bottom: 8px;
    }

    ${SelectButton}, ${MxReactButton} {
      width: 99%;
      margin-left: 1%;
      /* margin-bottom: 8px; */
    }
  }
`;
