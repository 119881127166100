import React, { useState, useRef } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components/macro";
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from "downshift";
import {
  MxReactIcon,
  MagnifyingGlass,
  X,
} from "src/componentLibrary/react/mx-icon-react";

import { controlText1_style } from "src/components/common/ControlText";
import { AppColors } from "../Styling";

// map your items to this format!
// having hourly baselines in here makes one wonder if this is really a "common" component.
export type AutoCompleteItem = {
  name: string;
  id: string;
  hasHourlyBaselines?: boolean;
};

// NOTE: it seems that it might be possible for downshift to call this with
// null or undefined or something, so that's why there is a guard even though
// the function states that `item` must be present.
const itemToString: (item: AutoCompleteItem) => string = item =>
  item?.name ?? "";

type AutoCompleteProps = {
  items: AutoCompleteItem[];
  selectionHandler: (item: AutoCompleteItem) => void;
  listMaxHeight?: string /** max-height prop for the matching options list. Default to 200px */;
};

/**
 * Reducer to extend the default one so we can leave the input filled with what the user
 * typed in and leave it open when they select.
 *
 * @param state
 * @param actionAndChanges
 */
const stateReducer: (
  state: UseComboboxState<AutoCompleteItem>,
  actionAndChanges: UseComboboxStateChangeOptions<AutoCompleteItem>,
) => UseComboboxState<AutoCompleteItem> = (state, actionAndChanges) => {
  const { type, changes } = actionAndChanges;
  switch (type) {
    // on selection.
    case useCombobox.stateChangeTypes.ItemClick:
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.InputBlur:
      // put the user input back in the box, and leave the input open
      return {
        ...changes,
        ...(state.highlightedIndex > -1 && {
          inputValue: state.inputValue,
          isOpen: true,
        }),
      };
    default:
      return changes; // otherwise business as usual.
  }
};

/**
 * A sort of generic autocomplete that is probably a bit on the not generic enough side of things.
 *
 * @param props
 * @param props.items - A set of items to match against that conforms to AutoCompleteItem
 * @param props.selectionHandler - function to call if user selects an item.
 * @param props.listMaxHeight - CSS max-height prop for the list of matching items. Defaults to 200px
 */
const AutoComplete: React.FC<AutoCompleteProps> = props => {
  const { items, selectionHandler, listMaxHeight } = props;
  const [acItems, setAcItems] = useState(items);
  const acInput = useRef<HTMLInputElement>(null);
  const {
    getComboboxProps,
    getInputProps,
    isOpen,
    highlightedIndex,
    getItemProps,
    getMenuProps,
    inputValue,
    closeMenu,
    setInputValue,
    openMenu,
  } = useCombobox<AutoCompleteItem>({
    items: acItems,
    itemToString: itemToString,
    stateReducer: stateReducer,
    onInputValueChange: ({ inputValue }) => {
      if (!inputValue) {
        setAcItems(items);
      } else {
        setAcItems(
          items.filter(item =>
            item.name.toLowerCase().includes(inputValue.toLowerCase()),
          ),
        );
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        selectionHandler(selectedItem);
      }
    },
  });
  const handleClearButton = () => {
    setInputValue("");
    closeMenu();
    if (acInput.current) {
      acInput.current.focus();
    }
  };
  const translate = useIntl();
  const searchPlaceholderString = translate.formatMessage({
    id: "assetSelector.searchPlaceholder",
  });
  return (
    <AutoCompleteWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        {...getComboboxProps()}
      >
        <MxReactIcon
          Icon={MagnifyingGlass}
          color={
            inputValue
              ? AppColors.neutral.black
              : AppColors.neutral["light-navy-3"]
          }
        />
        <ACInputField
          {...getInputProps({
            ref: acInput,
            placeholder: searchPlaceholderString,
            onFocus: openMenu,
          })}
        />
        {inputValue && (
          <MxReactIcon
            Icon={X}
            color={AppColors.neutral.navy}
            onClick={handleClearButton}
          />
        )}
      </div>
      <OptionsWrapper maxHeight={listMaxHeight} {...getMenuProps()}>
        {isOpen &&
          acItems.map((item, index) => (
            <li
              style={
                highlightedIndex === index
                  ? { backgroundColor: AppColors.neutral["light-navy-9"] }
                  : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {item.name}
            </li>
          ))}
      </OptionsWrapper>
    </AutoCompleteWrapper>
  );
};

export default AutoComplete;

const AutoCompleteWrapper = styled.div`
  background-color: ${AppColors.neutral["light-gray-9"]};
  border-radius: 4px;
  margin: 5px 0px;
  padding: 4px 8px;
`;

type OptionsWrapperProps = {
  maxHeight?: string;
};
const OptionsWrapper = styled.ul<OptionsWrapperProps>`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${AppColors.neutral["light-gray-9"]};
  border-radius: 4px;
  border: none;
  color: ${AppColors.neutral.navy};
  cursor: pointer;
  font-size: ${controlText1_style.fontSize};
  line-height: 1.5;
  list-style: none;
  max-height: ${props => props.maxHeight || "200px"};
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
`;

const ACInputField = styled.input`
  background-color: transparent;
  color: ${AppColors.neutral.navy};
  border: none;
  font-size: 16px;
  flex: 1;
  padding: 0px;
  margin-left: 8px;
  margin-right: 8px;
  &::placeholder {
    color: ${AppColors.neutral["light-navy-3"]};
  }
  &:focus {
    outline: none;
  }
`;
