import {
  MxReactIcon,
  Circle,
  CircleSolid,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "../Styling";
import { TooltipProps } from "recharts";

export interface DottedTooltipProps extends TooltipProps<number, string> {
  strokeDataKeys?: { [key: string]: boolean };
  exempt?: { [key: string]: boolean };
  footer?: (props: DottedTooltipProps) => JSX.Element | null;
}
/**
 * Renders  a box with tootip dots. Additional props you can pass
 * in that are not "standard" ReChart tooltip props are:
 *
 * `footer` - a function that will be called with all `props` that can render
 * things at the bottom of the box.
 *
 * `strokeDataKeys` - an object that has data keys of things that should get
 * a ring icon in the tooltip instead of a solid one. Ex:
 *    ` <ToolipWithDots strokeDataKeys={{ baseline: true }} /> `
 * would render the baseline dot as a ring and the rest solid.
 *
 * @param props -  the props that come in with Tooltop, plus more!
 */
export const TooltipWithDots = (props: DottedTooltipProps) => {
  // we have to copy the array because `payload` is a readonly prop
  const _sorted = props.payload
    ? // @ts-ignore
      props.payload.slice().sort(props.itemSorter)
    : [];
  const _exempt = props.exempt || {};
  const _ringIcons = props.strokeDataKeys || {};
  return (
    <div
      style={{
        padding: "10px",
        backgroundColor: AppColors.neutral.white,
        border: `1px solid ${AppColors.neutral["light-navy-9"]}`,
        fontSize: "14px",
        color: AppColors.neutral.navy,
      }}
    >
      {props.labelFormatter && props.label && (
        <p style={{ fontSize: "16px", marginBottom: "8px" }}>
          {props.labelFormatter(props.label, props.payload || [])}
        </p>
      )}
      {_sorted.map(_p => {
        if (_p.dataKey && _exempt[_p.dataKey as string]) {
          return null;
        }
        return (
          <div key={_p.dataKey as string} style={{ marginTop: "4px" }}>
            <MxReactIcon
              Icon={_ringIcons[_p.dataKey as string] ? Circle : CircleSolid}
              color={_p.color}
              style={{ marginRight: "8px", height: "12px", width: "12px" }}
              size="xs"
            />
            <span>
              {/* note that for some reason TS does not like calling this with only
              two args like we do eveywhere else so the last two are just there but unused */}
              {props.formatter && props.formatter(_p.value as number, _p.dataKey as string, _p, 0, _sorted)}
              <span style={{ fontWeight: "bold" }}>{_p.unit}</span>
            </span>
          </div>
        );
      })}
      {props.footer && props.footer(props)}
    </div>
  );
};
