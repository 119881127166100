import { 
  propsToPx, 
  ResponsiveLayoutProps
} from "src/components/common/Layout/Layout";
import styled from "styled-components/macro";

/**
 * Component that will spread its direct children out as far away from each
 * other as possible. With one element, this produces a left-aligned child. With two,
 * the children will be at the extreme left and right sides of the layout. With three,
 * they will be left, center, and right horizontally in the layout.
 */
export const BookendLayout = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
`;

export const ResponsiveBookendLayout = styled(BookendLayout)<ResponsiveLayoutProps>`
  @media (max-width: ${props => props.respondsAt || "1200px"}) {
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      & > * {
        margin-right: 0;
        margin-bottom: ${props => propsToPx(props.responsiveChildSpacing || props.childSpacing)};
        &:last-child {
          margin-bottom: 0;
        }
      }
  }
`;

export default BookendLayout;