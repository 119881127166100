import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";

// This is the same as an H4
// however, it is different in that this is a SPAN and an H4 is,
// you guessed it, an h4 (inline vs. block)
export const InlineH4 = styled.div`
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
`;

// STAYS
export const InfoText2 = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${AppColors.neutral["light-navy-1"]};
`;

// whut?
export const NavItemActive = styled.span`
  font-weight: 500;
  font-size: 15px;
`;
