import React, { CSSProperties, LegacyRef } from "react";
import { Range, useThumbOverlap, getTrackBackground } from "react-range";
import { decimalToHourFormatter } from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";
import { AppColors } from "src/components/common/Styling";

const STEP = 0.25;
const MIN = 0;
const MAX = 24;
const COLORS = [
  AppColors.neutral["light-navy-9"],
  AppColors.semantic.blue.sky,
  AppColors.neutral["light-navy-9"],
];
const THUMB_SIZE = 24;

// the little boxes that show the values the slider is on
type ThumbLabelProps = {
  rangeRef: Range;
  values: number[];
  index: number;
};

const ThumbLabel = (props: ThumbLabelProps) => {
  const { rangeRef, values, index } = props;
  const [labelValue, style] = useThumbOverlap(
    rangeRef,
    values,
    index,
    0.25,
    " - ",
    decimalToHourFormatter,
  );
  return (
    <div
      data-label={index}
      style={{
        display: "block",
        position: "absolute",
        top: "32px",
        color: AppColors.neutral.white,
        fontWeight: "bold",
        fontSize: "14px",
        fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
        padding: "4px",
        borderRadius: "4px",
        backgroundColor: AppColors.semantic.lightBlue.seafoam,
        whiteSpace: "nowrap",
        ...(style as CSSProperties),
      }}
    >
      {labelValue as string}
    </div>
  );
};
// this is the range slider. It should go somewhere else
type RangeSliderProps = {
  values: number[];
  setValues: React.Dispatch<React.SetStateAction<number[]>>;
  disabled?: boolean;
};
const RangeSlider = (props: RangeSliderProps) => {
  const { values, setValues } = props;
  const rangeRef = React.useRef<Range>();
  return (
    <Range
      allowOverlap
      values={values}
      ref={rangeRef as LegacyRef<Range>}
      step={STEP}
      min={MIN}
      max={MAX}
      disabled={props.disabled}
      onChange={values => setValues(values)}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          style={{
            ...props.style,
            height: "36px",
            display: "flex",
            width: "100%",
          }}
        >
          <div
            ref={props.ref}
            style={{
              height: "5px",
              width: "100%",
              borderRadius: "4px",
              background: getTrackBackground({
                values,
                colors: COLORS,
                min: MIN,
                max: MAX,
              }),
              alignSelf: "center",
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props, index, isDragged }) => {
        return (
          <div
            {...props}
            style={{
              ...props.style,
              height: `${THUMB_SIZE}px`,
              width: `${THUMB_SIZE / 2}px`,
              borderRadius: "4px",
              backgroundColor: AppColors.neutral.white,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: `0px 2px 6px ${AppColors.neutral["light-navy-9"]}`,
            }}
          >
            <ThumbLabel
              rangeRef={rangeRef.current as Range}
              values={values}
              index={index}
            />
            <div
              style={{
                height: "16px",
                width: "5px",
                backgroundColor: isDragged
                  ? AppColors.semantic.lightBlue.seafoam
                  : AppColors.neutral["light-navy-9"],
              }}
            />
          </div>
        );
      }}
    />
  );
};
export default RangeSlider;
