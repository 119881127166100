import {
  ChartDataGranularity,
  ChartDataRange,
  ChartMetadata,
  CommodityChartData,
  IMeasurement,
  IPoint,
  MeterDataResponse,
  ParsedChartData,
  PointType,
} from "src/types/charting";

import {
  averageReducer,
  sumReducer,
  isFifteenMinOrHourGranularity,
  setLastIntervalWithDataToNull,
} from "src/helpers/charting";
import {
  buildChartPropertiesForChart,
  buildDataForChart,
  buildMetadataForChart,
  buildXAxisForChart,
  buildYAxisForChart,
  extractMinimaMaximaValueFromMetadata,
  shouldCalculateCommodityAverage,
  shouldCalculateCommoditySum,
  chartDataTimedReducer,
  buildMetricsForChart,
} from "src/components/app/ExplorerPage/parsers/commonBuildChart";
import {
  DataQueueItem,
  TimeseriesNode,
} from "src/components/app/ExplorerPage/types";
import {
  keyFromQueueItem,
  timeRangeFromTimeseriesKey,
} from "src/components/app/ExplorerPage/helpers";
import { PresentationProps, ChartDataPayload } from "../chartDataReducer";
import { isFutureDate } from "src/helpers/dates";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";

type TypedMeterResponse = {
  data: MeterDataResponse;
  type: PointType;
};

export const parseMeterData = (
  payload: ChartDataPayload,
  parsedData: ParsedChartData,
): ParsedChartData => {
  let combinedResponse: {
    data: MeterDataResponse;
    type: PointType;
  };
  // extract will throw if it can't figure out a commodity
  try {
    combinedResponse = {
      data: payload.response as MeterDataResponse,
      type: payload.queueItem.commodity,
    };
  } catch {
    console.error("parseMeterData unable to determine commodity");
    return parsedData;
  }

  // TODO: do we do this even if we have invalid data for a reason??
  parsedData.queueItems.push(payload.queueItem);

  if (!isValidPointData(combinedResponse)) {
    return parsedData;
  }

  const response = combinedResponse.data;
  const pointType = combinedResponse.type;
  const point: IPoint | null = getPoint(
    response.getMeterById.points,
    pointType,
  );
  // if we don't have a valid point, it seems silly to continue
  if (!point) {
    console.error("parseMeterData unable to determine valid point");
    return parsedData;
  }
  const unitName = getSymbolForUnit(point.pointData.unit);

  let _newYAxisMap = undefined;
  let _newXAxisMap = undefined;

  const oldMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    parsedData.metadata,
    unitName,
  );

  // Parse metadata
  const { _newMetadata, _newMetrics } = parseMetadataAndMetrics(
    parsedData,
    payload.queueItem,
    point,
    unitName,
  );
  // Parse data
  const _newData = parseData(
    parsedData,
    combinedResponse,
    payload.queueItem.commodity,
    payload.queueItem.timeseriesKey,
    payload.queueItem.assetKey,
    point,
  );

  const newMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    _newMetadata,
    unitName,
  );

  // Parse XAxis
  if (!parsedData.xAxes.get(payload.queueItem.timeseriesKey)) {
    const xAxisProps = parseXAxis(
      parsedData,
      combinedResponse,
      payload.rangeSize,
      payload.queueItem.timeseriesKey,
      payload.timezone,
      payload.chartWidth,
    );

    if (xAxisProps) {
      // Clone the map
      _newXAxisMap = new Map(parsedData.xAxes);
      _newXAxisMap.set(payload.queueItem.timeseriesKey, xAxisProps);
    }
  }

  // Parse YAxis
  if (oldMixMaxYAxisValues !== newMixMaxYAxisValues) {
    const yAxisProps = parseYAxis(
      payload.queueItem.commodity,
      _newMetadata,
      point,
    );

    if (yAxisProps) {
      // Clone the map
      _newYAxisMap = new Map(parsedData.yAxes);
      _newYAxisMap.set(unitName, yAxisProps);
    }
  }

  // Parse Chart data
  const chartProps = parseChart(
    parsedData,
    combinedResponse,
    payload.queueItem,
    payload.presentationProps,
    payload.isPastComparison,
  );

  const _newChartMap = new Map(parsedData.charts);
  if (chartProps) {
    _newChartMap.set(keyFromQueueItem(payload.queueItem), chartProps);
  }

  return {
    ...parsedData,
    metrics: _newMetrics,
    metadata: _newMetadata,
    data: _newData,
    xAxes: _newXAxisMap ? _newXAxisMap : parsedData.xAxes,
    yAxes: _newYAxisMap ? _newYAxisMap : parsedData.yAxes,
    charts: _newChartMap,
  };
};

const parseXAxis = (
  currentState: ParsedChartData,
  combinedResponse: TypedMeterResponse,
  rangeSize: ChartDataRange,
  timestampKey: string,
  timezone: string | undefined,
  width: number,
) => {
  const response = combinedResponse.data;
  const pointType = combinedResponse.type;

  const point: IPoint | null = getPoint(
    response.getMeterById.points,
    pointType,
  );

  // This guards against bad data!
  if (!point) {
    return currentState.xAxes.get(timestampKey);
  }

  const values = point.pointData.values;
  const timeRange = timeRangeFromTimeseriesKey(timestampKey);
  const granularity = extractGranularity(combinedResponse);

  return buildXAxisForChart(
    width,
    values,
    timeRange,
    rangeSize,
    timezone,
    timestampKey,
    granularity,
  );
};

const parseYAxis = (
  commodityName: PointType,
  metadata: ChartMetadata,
  point: IPoint,
) => {
  const unitName = getSymbolForUnit(point.pointData.unit);

  return buildYAxisForChart(metadata, commodityName, unitName);
};

const parseMetadataAndMetrics = (
  currentState: ParsedChartData,
  queueItem: DataQueueItem,
  point: IPoint,
  unitName: string,
) => {
  const minMaxValues = chartDataTimedReducer(point.pointData.values, "value");
  const _sumVal = shouldCalculateCommoditySum(queueItem.commodity)
    ? sumReducer(point.pointData.values, "value")
    : undefined;
  const _avgVal = shouldCalculateCommodityAverage(queueItem.commodity)
    ? averageReducer(point.pointData.values, "value")
    : undefined;

  const _newMetadata = buildMetadataForChart(
    currentState.metadata,
    queueItem,
    minMaxValues,
    _sumVal,
    _avgVal,
    unitName,
  );
  const _newMetrics = buildMetricsForChart(
    currentState.metrics,
    queueItem,
    minMaxValues,
    _sumVal,
    _avgVal,
  );
  return { _newMetadata, _newMetrics };
};

const parseData = (
  currentState: ParsedChartData,
  combinedResponse: TypedMeterResponse,
  commodityName: PointType,
  timestampKey: string,
  assetKey: string,
  point: IPoint,
): TimeseriesNode[] => {
  const values = point.pointData.values;

  const timeRange = timeRangeFromTimeseriesKey(timestampKey);
  const granularity = extractGranularity(combinedResponse);

  return buildDataForChart(
    currentState.data,
    timestampKey,
    commodityName,
    assetKey,
    isFifteenMinOrHourGranularity(granularity) &&
      isFutureDate(timeRange.endTime)
      ? setLastIntervalWithDataToNull(values as IMeasurement[])
      : values,
  );
};

const parseChart = (
  currentState: ParsedChartData,
  combinedResponse: TypedMeterResponse,
  queueItem: DataQueueItem,
  presentationProps: PresentationProps,
  isPastComparison?: boolean,
): CommodityChartData | undefined => {
  const response = combinedResponse.data;
  const pointType = combinedResponse.type;

  const point: IPoint | null = getPoint(
    response.getMeterById.points,
    pointType,
  );

  // This guards against bad data!
  if (!point) {
    return currentState.charts.get(keyFromQueueItem(queueItem));
  }

  const unitName = getSymbolForUnit(point.pointData.unit);

  return buildChartPropertiesForChart(
    queueItem,
    unitName,
    presentationProps,
    isPastComparison,
  );
};

const getPoint = (points: IPoint[], type: PointType): IPoint | null => {
  const point = points.find(point => point.type === type);
  return point ? point : null;
};

const allowedMeterDataPointTypes: Set<PointType> = new Set([
  PointType.CHILLED_WATER_USAGE,
  PointType.ELECTRICITY_USAGE,
  PointType.ELECTRICITY_DEMAND,
  PointType.HEATING_OIL_USAGE,
  PointType.HOT_WATER_USAGE,
  PointType.NATURAL_GAS_USAGE,
  PointType.PROPANE_USAGE,
  PointType.STEAM_MASS,
  PointType.STEAM_USAGE,
  PointType.TEMPERATURE,
  PointType.WATER_USAGE,
]);

const extractGranularity = (
  combinedResponse: TypedMeterResponse,
): ChartDataGranularity => {
  const response = combinedResponse.data;
  const pointType = combinedResponse.type;

  const point: IPoint | null = getPoint(
    response.getMeterById.points,
    pointType,
  );

  return point!.pointData.granularity;
};

const isValidPointData = (combinedResponse: TypedMeterResponse): boolean => {
  const response = combinedResponse.data;
  const pointType = combinedResponse.type;

  const point: IPoint | null = getPoint(
    response.getMeterById.points,
    pointType,
  );

  return !(
    !point ||
    (point.pointData.values && point.pointData.values.length === 0)
  );
};
