import React from "react";
import styled from "styled-components/macro";
import { Box, HorizontalLayout } from "../Layout";

const HorizontalResizeLayout = styled(HorizontalLayout)`
  /* this is to allow the metrics to stack and get very tall when we are small */
  @media (max-width: 900px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    & div {
      margin-bottom: 8px;
    }
  }
`;

export const MetricLayout = ({ children }: { children: React.ReactNode }) => (
  <Box margin={[2, 0, 4, 0]}>
    <HorizontalResizeLayout
      childSpacing={6}
      style={{ alignItems: "flex-start" }}
    >
      {children}
    </HorizontalResizeLayout>
  </Box>
);
