// NOTE: update  TestIntlWrapper.tsx if you add new files
export const messagesLoader = (locale: string) => {
  const supportedLocales = ["en", "de"];
  const useLocale = supportedLocales.includes(locale) ? locale : "en";
  return {
    ui: () => import(`./string-files/${useLocale}.json`),
    equipment: () =>
      import(
        `@hatchdata/equipment-types-package/dist/src/i18n/${useLocale}.json`
      ),
  };
};
