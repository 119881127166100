import styled from "styled-components/macro";
import { AppColors } from "../Styling";

export const controlText1_style = {
  fontSize: "14px;",
  activeColor: AppColors.neutral.navy,
  inactiveColor: AppColors.neutral["light-navy-1"],
  activeWeight: 500,
  inactiveWeight: 400,
};

// note that this can / will vary based on stuff, so you
// probably won't use this component directly. Instead, import
// the things above
export const ControlText1 = styled.span`
  font-size: ${controlText1_style["fontSize"]};
  font-weight: ${controlText1_style.activeWeight};
  color: ${controlText1_style.activeColor};
`;
