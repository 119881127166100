import styled from "styled-components/macro";
import { controlText1_style } from "src/components/common/ControlText";
import { AppColors } from "../Styling";
import { InlineH4 } from "../InfoText";

// A button that looks like a select, but isn't!
export const SelectButton = styled.button`
  font-size: ${controlText1_style.fontSize};
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  line-height: 1;
  padding: 8px 8px 8px 8px;
  border: 1px solid ${AppColors.neutral["light-navy-3"]};
  border-radius: 4px;
  border-style: solid;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${AppColors.neutral.white};
  min-width: fit-content;
  color: ${controlText1_style.activeColor};
  font-weight: ${controlText1_style.activeWeight};

  &:disabled {
    color: ${controlText1_style.inactiveColor};
    font-weight: ${controlText1_style.inactiveWeight};
  }
`;

export const IconButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
export const ButtonContainer = styled.span`
  display: flex;
  align-items: center;

  > * {
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    width: 100%;
    button {
      width: 100%;
      margin: 0;
      margin-bottom: 16px;
    }
  }
`;

export const ToggleButton = styled(InlineH4)<{ isEnabled: boolean }>`
  cursor: pointer;
  background-color: ${props =>
    props.isEnabled
      ? AppColors.primary["msr-green"]
      : AppColors.neutral["light-navy-9"]};
  color: ${props => (props.isEnabled ? AppColors.neutral.white : "inherit")};
  border-radius: 15px;
  padding: 8px 12px 8px 12px;
  text-align: center;
  width: 60px;
`;
