import React from "react";
import { HorizontalLayout } from "../Layout";
import { ComposedMetric } from "./ComposedMetric";

type MetricWithIconProps = {
  value: JSX.Element;
  description: JSX.Element;
  icon: JSX.Element;
};

export const MetricWithIcon = ({
  value,
  description,
  icon,
}: MetricWithIconProps) => {
  return (
    <>
      <ComposedMetric
        value={
          <HorizontalLayout childSpacing={2} style={{ alignItems: "baseline" }}>
            {value}
            {icon}
          </HorizontalLayout>
        }
        description={description}
      />
    </>
  );
};

export default MetricWithIcon;
