import { DateUtilRange } from "./TimePickerHolder";
import { daysBetweenDates } from "src/helpers/dates";
/**
 * Since the owner of the react-day-picker isn't merging in the
 * pull requests that make the DateUtil stuff usable in Typescript,
 * I am just copying the functions here and typing them.
 *
 * code is from here:
 * https://github.com/gpbl/react-day-picker/blob/master/src/DateUtils.js
 */
/**
 * Add a day to a range and return a new range. A range is an object with
 * `from` and `to` days.
 *
 * NOTE: This is modified from the original, so if they do add Typescript
 * stuff to their lib, you still should use this code.
 *
 * @export
 * @param {Date} day
 * @param {Object} range
 * @return {Object} Returns a new range object
 */
export function addDayToRange(
  day: Date,
  range: { from: Date | undefined; to: Date | undefined } = {
    from: undefined,
    to: undefined,
  },
): DateUtilRange {
  let { from, to } = range;
  // if nothing is set, set both to the day
  if (!from || !to) {
    from = day;
    to = day;
    // if the day is before "from", make it the new "from" to expand the range
  } else if (isDayBefore(day, from)) {
    from = day;
    // if the day is after "to", make it the new "to" to expand the range
  } else if (isDayBefore(to, day)) {
    to = day;
  } else {
    const _toStart = daysBetweenDates(from, day);
    const _toEnd = daysBetweenDates(to, day);
    if (_toEnd < _toStart) {
      to = day;
    } else {
      from = day;
    }
  }
  return { from, to };
}

/**
 * Return `true` if two dates are the same month, ignoring the day and time.
 *
 * @param  {Date}  d1
 * @param  {Date}  d2
 * @return {Boolean}
 */
export function isSameMonth(d1: Date | undefined, d2: Date | undefined) {
  if (!d1 || !d2) {
    return false;
  }
  return (
    d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()
  );
}
/**
 * Return `true` if two dates are the same day, ignoring the time.
 *
 * @param  {Date}  d1
 * @param  {Date}  d2
 * @return {Boolean}
 */
export function isSameDay(d1: Date | undefined, d2: Date | undefined) {
  if (!d1 || !d2) {
    return false;
  }
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getFullYear() === d2.getFullYear()
  );
}
/**
 * Returns `true` if the first day is before the second day.
 *
 * @param {Date} d1
 * @param {Date} d2
 * @returns {Boolean}
 */
export function isDayBefore(d1: Date, d2: Date) {
  const day1 = clone(d1).setHours(0, 0, 0, 0);
  const day2 = clone(d2).setHours(0, 0, 0, 0);
  return day1 < day2;
}
/**
 * Clone a date object.
 *
 * @param  {Date} d The date to clone
 * @return {Date} The cloned date
 */
export function clone(d: Date) {
  return new Date(d.getTime());
}
