import React from "react";
import { FallbackProps } from "react-error-boundary";
import styled from "styled-components/macro";
import { H2, Body } from "src/components/common/Typography";
import { tracking } from "src/tracking";
import CenteredContentWrapper from "src/components/app/CenteredContentWrapper";
import { FormattedMessage } from "react-intl";

export const ApplicationErrorWrapper = styled.div`
  background-color: white;
  padding: 48px;
  display: flex;
  max-width: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > * {
    margin-bottom: 12px;
  }
`;
const ApplicationError: React.FC<FallbackProps> = ({ error }) => {
  tracking.fireErrorEvent("ERROR_BOUNDARY_TRIGGER", {}, error);
  return (
    <CenteredContentWrapper>
      <ApplicationErrorWrapper>
        <H2>
          <FormattedMessage id="applicationError.heading" />
        </H2>
        <Body>
          <FormattedMessage
            tagName="span"
            id="applicationError.text"
            values={{
              a: chunk => <a href="mailto:support@measurabl.com">{chunk}</a>,
            }}
          />
        </Body>
      </ApplicationErrorWrapper>
    </CenteredContentWrapper>
  );
};

export default ApplicationError;
