import styled from "styled-components/macro";
import { Body } from "src/components/common/Typography";

// TODO: should this be a Layout instead?
export const DotMenuItem = styled(Body)`
  margin-bottom: 8px;
  cursor: pointer;
  white-space: nowrap;
  &:last-child {
    margin-bottom: 0px;
  }
`;
