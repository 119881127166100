import React from "react";
import { Line } from "recharts";
import { ChartRenderData } from "src/types/charting";

export const charts = (chartData: ChartRenderData): React.ReactNode[] => {
  const chartCollection: React.ReactNode[] = [];
  chartData.forEach((val, key) => {
    if (val.visible) {
      const props = val.chartProps;
      chartCollection.push(
        // @ts-ignore; it's yelling about prop spreading; it doesn't think the types match?
        <Line key={key} {...props} />,
      );
    }
  });
  return chartCollection;
};
