import { gql } from "@apollo/client";
import { Month } from "../components/app/UtilityDataVariance/UtilityDataUtils";

/* =============================================================== *\
   FRAGMENTS ====
\* =============================================================== */

// There are a couple fields we could add here: `status:DataStatus` and `message:String`
const CommodityIntervalFragment = gql`
  fragment CommodityIntervalFields on IntervalData {
    name
    range {
      startTime
      endTime
    }
    granularity
    unit
    values {
      timestamp
      value
    }
  }
`;
const BuildingFieldsFragment = gql`
  fragment BuildingInfoFields on Building {
    id
    name
    location {
      timezone
    }
  }
`;
const WeatherFieldsFragment = gql`
  fragment WeatherFields on WeatherData {
    temperatureUnit
    granularity
    values {
      timestamp
      temperature
      relativeHumidity
    }
    aggregateMeasurements {
      averageTemperature
      averageHumidity
    }
  }
`;

/* =============================================================== *\
   QUERIES ====
\* =============================================================== */
export const POINT_COMMODITY_DATA = gql`
  query fetchEquipmentPointData(
    $pointId: String!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $unitPreferences: PreferredUnitsInput
  ) {
    equipmentPointById(id: $pointId) {
      id
      name
      pointData(
        granularity: $granularity
        range: { startTime: $startTime, endTime: $endTime }
        unitPreferences: $unitPreferences
      ) {
        ...CommodityIntervalFields
      }
    }
  }
  ${CommodityIntervalFragment}
`;
export const GROUP_COMMODITY_DATA = gql`
  query fetchGroupCommodityData(
    $groupId: ID!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $type: PointType!
    $unit: Unit
  ) {
    spaceGroup(id: $groupId) {
      id
      name
      commodityUsageData(
        range: { startTime: $startTime, endTime: $endTime }
        granularity: $granularity
        type: $type
        unit: $unit
      ) {
        timezone
        commodityUsage {
          ...CommodityIntervalFields
        }
      }
    }
  }
  ${CommodityIntervalFragment}
`;

export const BUILDING_ELECTRICITY_USAGE = gql`
  query fetchBuildingElectricityUsage(
    $buildingId: String!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $unit: Unit
  ) {
    getBuildingById(id: $buildingId) {
      ...BuildingInfoFields
      electricityUsageData(
        range: { startTime: $startTime, endTime: $endTime }
        granularity: $granularity
        unit: $unit
      ) {
        ...CommodityIntervalFields
      }
      totalElectricityUsage(
        range: { startTime: $startTime, endTime: $endTime }
        unit: $unit
      ) {
        name
        value
        measuredIn {
          name
          symbol
        }
      }
    }
  }
  ${BuildingFieldsFragment}
  ${CommodityIntervalFragment}
`;

export const BUILDING_COMMODITY_DATA = gql`
  query fetchBuildingElectricityDemand(
    $buildingId: String!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $type: PointType!
    $unit: Unit
  ) {
    getBuildingById(id: $buildingId) {
      ...BuildingInfoFields
      commodityUsageData(
        range: { startTime: $startTime, endTime: $endTime }
        granularity: $granularity
        type: $type
        unit: $unit
      ) {
        ...CommodityIntervalFields
      }
    }
  }
  ${BuildingFieldsFragment}
  ${CommodityIntervalFragment}
`;

export const BUILDING_ELECTRICITY_DEMAND = gql`
  query fetchBuildingElectricityDemand(
    $buildingId: String!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $unit: Unit
  ) {
    getBuildingById(id: $buildingId) {
      ...BuildingInfoFields
      electricityDemandData(
        range: { startTime: $startTime, endTime: $endTime }
        granularity: $granularity
        unit: $unit
      ) {
        ...CommodityIntervalFields
      }
    }
  }
  ${BuildingFieldsFragment}
  ${CommodityIntervalFragment}
`;

export const BUILDING_WEATHER_DATA = gql`
  query fetchBuildingWeatherData(
    $buildingId: String!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $temperatureUnit: TemperatureUnit!
  ) {
    getBuildingById(id: $buildingId) {
      ...BuildingInfoFields
      weatherData(
        timeRange: { startTime: $startTime, endTime: $endTime }
        granularity: $granularity
        temperatureUnit: $temperatureUnit
      ) {
        ...WeatherFields
      }
    }
  }
  ${BuildingFieldsFragment}
  ${WeatherFieldsFragment}
`;

// TODO: this does not belong here :sadpanda:
export const SET_BILLING_NOTE = gql`
  mutation sendBillingNote(
    $buildingId: String!
    $commodityName: CommodityName!
    $year: Int!
    $comparisonYear: Int!
    $month: Month!
    $reasons: [Reason!]
    $note: String!
  ) {
    setVarianceNote(
      input: {
        buildingId: $buildingId
        commodityName: $commodityName
        year: $year
        comparisonYear: $comparisonYear
        month: $month
        reasons: $reasons
        note: $note
      }
    ) {
      buildingId
      commodityName
      year
      comparisonYear
      month
      reasons
      note
      createdAt
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const DELETE_BILLING_NOTE = gql`
  mutation deleteBillingNote(
    $buildingId: String!
    $commodityName: CommodityName!
    $year: Int!
    $comparisonYear: Int!
    $month: Month!
  ) {
    deleteVarianceNote(
      input: {
        buildingId: $buildingId
        commodityName: $commodityName
        year: $year
        comparisonYear: $comparisonYear
        month: $month
      }
    ) {
      buildingId
      commodityName
      year
      comparisonYear
      month
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const GET_BUILDING_BILLING = gql`
  query fetchBuildingBilling(
    $buildingId: String!
    $commodityNames: [CommodityName!]
    $years: [Int!]
  ) {
    getBuildingById(id: $buildingId) {
      id
      name
      location {
        timezone
      }
      billing {
        commodityBilling(commodityNames: $commodityNames) {
          commodityName
          yearlyBilling(years: $years) {
            year
          }
        }
      }
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const GET_BILLING_COMMODITY_MONTHS = gql`
  query fetchBillingCommodityMonths(
    $buildingId: String!
    $commodityNames: [CommodityName!]
    $years: [Int!]
  ) {
    getBuildingById(id: $buildingId) {
      id
      billing {
        commodityBilling(commodityNames: $commodityNames) {
          commodityName
          yearlyBilling(years: $years) {
            year
            monthlyBilling {
              month
            }
          }
        }
      }
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const GET_YEARS_WITH_BILLS = gql`
  query fetchBillingCommodityMonths(
    $buildingId: String!
    $commodityNames: [CommodityName!]
  ) {
    getBuildingById(id: $buildingId) {
      id
      billing {
        commodityBilling(commodityNames: $commodityNames) {
          commodityName
          yearlyBilling(years: []) {
            year
          }
        }
      }
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const GET_BILLS_AND_MISSING_BILLS = gql`
  query fetchBillsAndMissingBills(
    $buildingId: String!
    $startDate: String!
    $endDate: String!
    $serviceAccounts: [String]!
  ) {
    buildingBills: getBuildingById(id: $buildingId) {
      id
      billing {
        getMissingBills(
          startDate: $startDate
          endDate: $endDate
          serviceAccounts: $serviceAccounts
        ) {
          missingBills {
            endDate
            startDate
            serviceAccountId
          }
        }
        getBills(startDate: $startDate, endDate: $endDate) {
          consolidatedBillId
          consolidatedBillNumber
          serviceAccountNumber
          serviceAccountId
          organizationId
          providerName
          bills {
            billId
            buildingId
            commodityName
            billingPeriod {
              start
              end
            }
            cost
            currency
            estimatedBill
            usage
            unit
            demand
            demandUnit
            billLines {
              billLineType
            }
          }
        }
      }
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const GET_BUILDING_SERVICE_ACCOUNTS = gql`
  query fetchBuildingServiceAccounts($buildingId: String!) {
    getBuildingById(id: $buildingId) {
      id
      name
      serviceAccounts {
        id
        utilityProviderName
        accountNumber
        vendorType
        commodityNames
        startDate
        endDate
        meterAssignments {
          id
          addedTimestamp
          removedTimestamp
          meter {
            id
            name
            commodityName
            points {
              type
            }
          }
        }
      }
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const GET_KPIS = gql`
  query fetchKPIs(
    $buildingId: String!
    $startDate: String!
    $endDate: String!
    $serviceAccounts: [String]!
  ) {
    buildingKPIs: getBuildingById(id: $buildingId) {
      id
      billing {
        getKPIs(
          startDate: $startDate
          endDate: $endDate
          serviceAccounts: $serviceAccounts
        ) {
          dataCompleteness {
            commodityName
            value
          }
        }
      }
    }
  }
`;

export const FETCH_ALL_BUILDING_DATA = gql`
  query fetchBuildingPointData(
    $buildingId: String!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $includeWeather: Boolean!
    $temperatureUnit: TemperatureUnit
    $unit: Unit
    $dataType: PointType!
  ) {
    getBuildingById(id: $buildingId) {
      ...BuildingInfoFields
      commodityUsageData(
        range: { startTime: $startTime, endTime: $endTime }
        granularity: $granularity
        type: $dataType
        unit: $unit
      ) {
        name
        values {
          timestamp
          value
        }
        unit
      }
      weatherData(
        timeRange: { startTime: $startTime, endTime: $endTime }
        granularity: $granularity
        temperatureUnit: $temperatureUnit
      ) @include(if: $includeWeather) {
        ...WeatherFields
      }
      buildingNonOperatingTimeRanges(
        range: { startTime: $startTime, endTime: $endTime }
      ) {
        startTime
        endTime
      }
      scheduleExceptions {
        id
        exceptionDate
        exceptionEndDate
        title
        notes
        exceptionType
        exceptionTimeRanges {
          startHour
          endHour
          startMinute
          endMinute
        }
        timeBlock {
          startHour
          startMinute
          endHour
          endMinute
        }
      }
      annotations {
        id
        createdTime
        startTime
        endTime
        reasons
        title
        notes
        type
        createdBy {
          firstName
          lastName
        }
      }
    }
  }
  ${BuildingFieldsFragment}
  ${WeatherFieldsFragment}
`;

export const FETCH_ALL_POINT_DATA = gql`
  query fetchPointData(
    $meterId: String!
    $granularity: Granularity!
    $startTime: DateTime!
    $endTime: DateTime!
    $unitPreferences: PreferredUnitsInput
  ) {
    getMeterById(id: $meterId) {
      id
      name
      commodityName
      customerMeterId
      points(includeVirtualPoints: true) {
        id
        customerPointId
        name
        type
        pointData(
          granularity: $granularity
          range: { startTime: $startTime, endTime: $endTime }
          unitPreferences: $unitPreferences
        ) {
          ...CommodityIntervalFields
        }
      }
    }
  }
  ${CommodityIntervalFragment}
`;

// TODO: this does not belong here :sadpanda:
export const ADD_BILL = gql`
  mutation addBill(
    $consolidatedBillNumber: String
    $organizationId: String!
    $providerName: String!
    $serviceAccountId: String!
    $serviceAccountNumber: String!
    $issueDate: String!
    $bills: [BillInput!]!
  ) {
    addBill(
      input: {
        consolidatedBillNumber: $consolidatedBillNumber
        organizationId: $organizationId
        providerName: $providerName
        serviceAccountId: $serviceAccountId
        serviceAccountNumber: $serviceAccountNumber
        issueDate: $issueDate
        bills: $bills
      }
    ) {
      consolidatedBillId
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const UPDATE_BILL = gql`
  mutation updateBill(
    $organizationId: String!
    $consolidatedBillId: String!
    $consolidatedBillNumber: String
    $providerName: String!
    $serviceAccountId: String!
    $serviceAccountNumber: String!
    $issueDate: String!
    $bills: [BillInput!]!
  ) {
    updateBill(
      input: {
        organizationId: $organizationId
        consolidatedBillId: $consolidatedBillId
        consolidatedBillNumber: $consolidatedBillNumber
        providerName: $providerName
        serviceAccountId: $serviceAccountId
        serviceAccountNumber: $serviceAccountNumber
        issueDate: $issueDate
        bills: $bills
      }
    ) {
      consolidatedBillId
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const DELETE_BILL = gql`
  mutation deleteBill($organizationId: String!, $consolidatedBillId: String!) {
    deleteBill(
      input: {
        organizationId: $organizationId
        consolidatedBillId: $consolidatedBillId
      }
    ) {
      consolidatedBillId
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const GET_BILL_BY_ID = gql`
  query fetchBill($buildingId: String!, $consolidatedBillId: String!) {
    getBuildingById(id: $buildingId) {
      id
      name
      billing {
        getBillById(id: $consolidatedBillId) {
          consolidatedBillId
          consolidatedBillNumber
          providerName
          organizationId
          serviceAccountId
          serviceAccountNumber
          issueDate
          bills {
            buildingId
            commodityName
            billingPeriod {
              start
              end
            }
            cost
            currency
            estimatedBill
            usage
            unit
            demand
            demandUnit
            billLines {
              billLineType
              cost
              currency
              value
            }
            readings {
              meterId
              currentReadingDate
              previousReadingDate
              usage
              demand
            }
          }
        }
      }
    }
  }
`;

export const TOTAL_HEATING_COOLING_DEGREE_DAYS_CURRENT_YEAR = gql`
  query fetchTotalHeatingCoolingDegreeDays(
    $buildingId: String!
    $startTime: DateTime!
    $endTime: DateTime!
    $temperatureUnit: TemperatureUnit!
    $unit: Unit
  ) {
    getBuildingById(id: $buildingId) {
      id
      currentYear: degreeDayData(
        timeRange: { startTime: $startTime, endTime: $endTime }
        temperatureUnit: $temperatureUnit
        unit: $unit
      ) {
        totalHeatingDegreeDays
        totalCoolingDegreeDays
      }
    }
  }
`;

export const TOTAL_HEATING_COOLING_DEGREE_DAYS = gql`
  query fetchTotalHeatingCoolingDegreeDays(
    $buildingId: String!
    $startTime: DateTime!
    $endTime: DateTime!
    $startTimeComparison: DateTime!
    $endTimeComparison: DateTime!
    $temperatureUnit: TemperatureUnit!
    $unit: Unit
  ) {
    getBuildingById(id: $buildingId) {
      id
      currentYear: degreeDayData(
        timeRange: { startTime: $startTime, endTime: $endTime }
        temperatureUnit: $temperatureUnit
        unit: $unit
      ) {
        totalHeatingDegreeDays
        totalCoolingDegreeDays
      }
      comparisonYear: degreeDayData(
        timeRange: {
          startTime: $startTimeComparison
          endTime: $endTimeComparison
        }
        temperatureUnit: $temperatureUnit
        unit: $unit
      ) {
        totalHeatingDegreeDays
        totalCoolingDegreeDays
      }
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const BUILDING_OCCUPANCY = gql`
  query fetchBuildingOccupancy($buildingId: String!, $year: Int!) {
    getBuildingById(id: $buildingId) {
      id
      name
      location {
        timezone
      }
      occupancy(year: $year) {
        occupancyId
        month
        occupancy
        tenantUsage
        tenantBilled
        estimatedGarageUsage
        overtimeHVACHours
        overtimeHVACUsage
      }
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export const SET_BUILDING_OCCUPANCY = gql`
  mutation setBuildingOccupancy(
    $organizationId: String!
    $buildingId: String!
    $year: Int!
    $occupancy: [OccupancyInput!]!
  ) {
    setBuildingOccupancy(
      input: {
        organizationId: $organizationId
        buildingId: $buildingId
        year: $year
        occupancy: $occupancy
      }
    ) {
      occupancyId
      month
      occupancy
      tenantUsage
      tenantBilled
      estimatedGarageUsage
      overtimeHVACHours
      overtimeHVACUsage
    }
  }
`;

// TODO: this does not belong here :sadpanda:
export type Occupancy = {
  occupancyId: string | undefined;
  month: Month;
  occupancy?: number | "null";
  tenantUsage?: number | "null";
  tenantBilled?: number | "null";
  estimatedGarageUsage?: number | "null";
  overtimeHVACHours?: number | "null";
  overtimeHVACUsage?: number | "null";
};
