import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import { DotsIcon } from "./DotsIcon";

import { useOutsideClickEvent } from "src/hooks/useOutsideClickEvent";
import { AppColors } from "../Styling";
const DotMenuWrapper = styled.div<{ isRelative?: boolean }>`
  position: ${props => (props.isRelative ? "relative" : "absolute")};
  top: ${props => (props.isRelative ? "0px" : "14px")};
  right: ${props => (props.isRelative ? "0px" : "8px")};
`;
const DotMenuItems = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: white;
  min-width: 240px;
  padding: 10px 25px;
  border: 1px solid ${AppColors.neutral["light-navy-9"]};
  display: inline-block;
`;

// TODO: get rid of wait / loading. Just render something else instead of the menu if you can't use it
interface IDotMenu {
  children: React.ReactNode;
  color?: string;
  isLoading?: boolean;
  waitForLoading?: boolean;
  isRelative?: boolean; // to escape absolute positioning
}
const DotMenu: React.FC<IDotMenu> = ({
  children,
  color,
  isLoading,
  waitForLoading,
  isRelative,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const openMenu = () => setShowMenu(true);

  const closeMenu = () => {
    if (!waitForLoading) {
      setShowMenu(false);
    }
  };

  const menuItemsRef = useRef(null);

  useEffect(() => {
    if (!isLoading) {
      setShowMenu(false);
    }
  }, [isLoading]);

  const handleOutsideClick = () => {
    if (showMenu && !isLoading) {
      closeMenu();
    }
  };
  useOutsideClickEvent(handleOutsideClick, menuItemsRef);

  return (
    <DotMenuWrapper isRelative={isRelative}>
      <DotsIcon onClick={openMenu} color={color} />
      {showMenu && (
        <DotMenuItems ref={menuItemsRef} onClick={closeMenu}>
          {children}
        </DotMenuItems>
      )}
    </DotMenuWrapper>
  );
};

export default DotMenu;
