import styled from "styled-components/macro";
import React, { PropsWithChildren } from "react";
import { MxReactIcon, X } from "src/componentLibrary/react/mx-icon-react";
import { InlineH4 } from "src/components/common/Typography";
import { IconButton } from "src/components/common/Button";
import { AppColors } from "../Styling";

const PillWrapper = styled.div`
  height: 30px;
  border-radius: 15px;
  background-color: ${AppColors.primary["light-msr-green"]};
  display: inline-flex;
  align-items: center;
  padding: 0px 15px;
  color: ${AppColors.neutral.white};
  cursor: pointer;
  margin-right: 4px;
  margin-bottom: 2px;
  :first-of-type {
    margin-right: none;
  }
`;

type PillProps = {
  buttonHandler?: () => void;
};

const Pill: React.FC<PropsWithChildren<PillProps>> = props => {
  const { children, buttonHandler } = props;
  return (
    <PillWrapper onClick={buttonHandler}>
      <InlineH4>{children}</InlineH4>
      {buttonHandler && (
        <IconButton>
          <MxReactIcon
            Icon={X}
            style={{ marginLeft: "8px" }}
            size="xs"
            color={AppColors.neutral.white}
          />
        </IconButton>
      )}
    </PillWrapper>
  );
};
export default Pill;
