import { MouseEventHandler, ReactElement } from "react";
import { ReferenceLineProps } from "recharts";
import {
  AnnotationEvent,
  MeasureEvent,
  ScheduleExceptionEvent,
} from "../types";
import { Box } from "src/components/common/Layout";

export const MeasureIdentifiedMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  measureEvent: MeasureEvent,
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <svg
      width="20"
      height="28"
      viewBox="0 0 20 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.viewBox.x - 10}
      y={props.viewBox.height + 30}
      data-for="measuresTooltip"
      data-tip={JSON.stringify([measureEvent])}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M13.9057 21.7894L10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67931 25.6512 7.51523 23.9 6.0926 21.7868C4.35646 19.1617 3.2189 17.4059 2.67184 16.5084C1.88761 15.2195 1.38451 14.1764 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97767 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081C16.7812 17.4057 15.6432 19.1622 13.9063 21.7885C13.9061 21.7888 13.9059 21.7891 13.9057 21.7894Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M11.5625 15.6875C11.5625 15.2578 11.4062 14.9062 11.0938 14.5938C10.7812 14.2812 10.4297 14.125 10 14.125C9.57031 14.125 9.19922 14.2812 8.88672 14.5938C8.57422 14.9062 8.4375 15.2578 8.4375 15.6875C8.4375 16.1172 8.57422 16.4883 8.88672 16.8008C9.19922 17.1133 9.57031 17.25 10 17.25C10.4297 17.25 10.7812 17.1133 11.0938 16.8008C11.4062 16.4883 11.5625 16.1172 11.5625 15.6875ZM8.61328 7.73828C8.61328 7.62109 8.65234 7.50391 8.75 7.40625C8.82812 7.30859 8.94531 7.25 9.08203 7.25H10.918C11.0352 7.25 11.1523 7.30859 11.25 7.40625C11.3281 7.50391 11.3867 7.62109 11.3867 7.73828L11.1133 13.0508C11.1133 13.1875 11.0547 13.3047 10.9766 13.3828C10.8789 13.4609 10.7617 13.5 10.6445 13.5H9.35547C9.21875 13.5 9.10156 13.4609 9.02344 13.3828C8.92578 13.3047 8.88672 13.1875 8.88672 13.0508L8.61328 7.73828Z"
        fill={stroke}
      />
    </svg>
  );
};

export const MeasureImplementedMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  measureEvent: MeasureEvent,
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <svg
      width="20"
      height="28"
      viewBox="0 0 20 28"
      fill="none"
      xmlns=""
      x={props.viewBox.x - 10}
      y={props.viewBox.height + 30}
      data-for="measuresTooltip"
      data-tip={JSON.stringify([measureEvent])}
      style={{ cursor: "pointer" }}
    >
      <path
        d="M13.9057 21.7894L10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67931 25.6512 7.51523 23.9 6.0926 21.7868C4.35646 19.1617 3.2189 17.4059 2.67184 16.5084C1.88761 15.2195 1.38451 14.1764 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97767 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081C16.7812 17.4057 15.6432 19.1622 13.9063 21.7885C13.9061 21.7888 13.9059 21.7891 13.9057 21.7894Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M8.39844 15.8242C8.47656 15.9414 8.59375 15.9805 8.75 15.9805C8.88672 15.9805 9.00391 15.9414 9.10156 15.8242L14.8633 10.082C14.9414 10.0039 15 9.88672 15 9.73047C15 9.59375 14.9414 9.47656 14.8633 9.37891L14.1406 8.67578C14.043 8.57812 13.9258 8.51953 13.7891 8.51953C13.6523 8.51953 13.5352 8.57812 13.4375 8.67578L8.75 13.3633L6.5625 11.1758C6.44531 11.0781 6.32812 11.0195 6.19141 11.0195C6.05469 11.0195 5.9375 11.0781 5.85938 11.1758L5.13672 11.8789C5.03906 11.9766 5 12.0938 5 12.2305C5 12.3867 5.03906 12.5039 5.13672 12.582L8.39844 15.8242Z"
        fill={stroke}
      />
    </svg>
  );
};

export const MultiMeasureMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  measureEvents: MeasureEvent[],
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <>
      <svg
        width="20"
        height="28"
        viewBox="0 0 20 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x={props.viewBox.x - 10}
        y={props.viewBox.height + 30}
        data-for="measuresTooltip"
        data-tip={JSON.stringify(measureEvents)}
        style={{ cursor: "pointer" }}
      >
        <path
          d="M13.9057 21.7894L10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67931 25.6512 7.51523 23.9 6.0926 21.7868C4.35646 19.1617 3.2189 17.4059 2.67184 16.5084C1.88761 15.2195 1.38451 14.1764 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97767 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081C16.7812 17.4057 15.6432 19.1622 13.9063 21.7885C13.9061 21.7888 13.9059 21.7891 13.9057 21.7894Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
        />
        <text y={17} x={6} fill={stroke} fontSize={14}>
          {measureEvents.length}
        </text>
      </svg>
    </>
  );
};

export const ScheduleExceptionMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  event: ScheduleExceptionEvent,
  handleScheduleExceptionMarkerClick?: (
    scheduleExceptionEvent: ScheduleExceptionEvent,
  ) => void,
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <svg
      width="20"
      height="28"
      viewBox="0 0 20 28"
      fill="none"
      xmlns=""
      x={props.viewBox.x - 10}
      y={props.viewBox.height + 30}
      data-for="scheduleExceptionsTooltip"
      data-tip={JSON.stringify([event])}
      style={{ cursor: "pointer" }}
      onClick={
        handleScheduleExceptionMarkerClick
          ? () => handleScheduleExceptionMarkerClick(event)
          : undefined
      }
    >
      <path
        d="M13.9057 21.7894L10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67931 25.6512 7.51523 23.9 6.0926 21.7868C4.35646 19.1617 3.2189 17.4059 2.67184 16.5084C1.88761 15.2195 1.38451 14.1764 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97767 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081C16.7812 17.4057 15.6432 19.1622 13.9063 21.7885C13.9061 21.7888 13.9059 21.7891 13.9057 21.7894Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="1.5"
      />
      <svg width="10" height="18" y={2} x={5}></svg>
    </svg>
  );
};

export const MultiScheduleExceptionMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  scheduleExceptionEvents: ScheduleExceptionEvent[],
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <>
      <svg
        width="20"
        height="28"
        viewBox="0 0 20 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x={props.viewBox.x - 10}
        y={props.viewBox.height + 30}
        data-for="scheduleExceptionsTooltip"
        data-tip={JSON.stringify(scheduleExceptionEvents)}
        style={{ cursor: "pointer" }}
      >
        <path
          d="M13.9057 21.7894L10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67931 25.6512 7.51523 23.9 6.0926 21.7868C4.35646 19.1617 3.2189 17.4059 2.67184 16.5084C1.88761 15.2195 1.38451 14.1764 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97767 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081C16.7812 17.4057 15.6432 19.1622 13.9063 21.7885C13.9061 21.7888 13.9059 21.7891 13.9057 21.7894Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
        />
        <text y={17} x={6} fill={stroke} fontSize={14}>
          {scheduleExceptionEvents.length}
        </text>
      </svg>
    </>
  );
};

export const MultiMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  events: MeasureEvent[] | ScheduleExceptionEvent[] | AnnotationEvent[],
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <>
      <svg
        width="20"
        height="28"
        viewBox="0 0 20 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x={props.viewBox.x - 10}
        y={props.viewBox.height + 30}
        data-for="multiTooltip"
        data-tip={JSON.stringify(events)}
        style={{ cursor: "pointer" }}
      >
        <path
          d="M13.9057 21.7894L10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67931 25.6512 7.51523 23.9 6.0926 21.7868C4.35646 19.1617 3.2189 17.4059 2.67184 16.5084C1.88761 15.2195 1.38451 14.1764 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97767 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081C16.7812 17.4057 15.6432 19.1622 13.9063 21.7885C13.9061 21.7888 13.9059 21.7891 13.9057 21.7894Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
        />
        <text y={17} x={6} fill={stroke} fontSize={12}>
          {events?.length}
        </text>
      </svg>
    </>
  );
};

export const AnnotationMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  annotationEvent: AnnotationEvent,
  handleAnnotationMarkerClick?: (annotation: AnnotationEvent) => void,
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <>
      <svg
        width="20"
        height="28"
        viewBox="0 0 20 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x={props.viewBox.x - 10}
        y={props.viewBox.height + 30}
        data-for="annotationsTooltip"
        data-tip={JSON.stringify([annotationEvent])}
        style={{ cursor: "pointer" }}
        onClick={
          handleAnnotationMarkerClick
            ? () => handleAnnotationMarkerClick(annotationEvent)
            : undefined
        }
      >
        <path
          d="M10.4176 27.0371L13.9057 21.7894C13.9059 21.7891 13.9061 21.7888 13.9063 21.7885C15.6429 19.1627 16.7808 17.4063 17.328 16.5086L10.4176 27.0371ZM10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25M10.4176 27.0371L10 27.25M10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67932 25.6513 7.51525 23.9 6.09262 21.7868C4.35661 19.162 3.21908 17.4062 2.67196 16.5086C1.88766 15.2196 1.38453 14.1765 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97766 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081L10 27.25Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
        />
        <path
          d="M10.3984 10.5C10.2812 10.4062 10.1406 10.3359 10 10.3359C9.83594 10.3359 9.69531 10.4062 9.60156 10.5L6.41406 13.6875C6.29688 13.8047 6.25 13.9453 6.25 14.0859C6.25 14.25 6.29688 14.3906 6.41406 14.4844L6.95312 15C7.04688 15.1172 7.1875 15.1641 7.35156 15.1641C7.49219 15.1641 7.63281 15.1172 7.75 15L10 12.75L12.2734 15C12.3672 15.1172 12.4844 15.1641 12.6484 15.1641C12.7891 15.1641 12.9297 15.1172 13.0469 15L13.5859 14.4844C13.6797 14.3906 13.75 14.25 13.75 14.0859C13.75 13.9453 13.6797 13.8047 13.5859 13.6875L10.3984 10.5ZM9.60156 6C9.69531 5.90625 9.83594 5.83594 10 5.83594C10.1406 5.83594 10.2812 5.90625 10.3984 6L13.5859 9.1875C13.6797 9.30469 13.75 9.44531 13.75 9.58594C13.75 9.75 13.6797 9.89062 13.5859 9.98438L13.0469 10.5C12.9297 10.6172 12.7891 10.6641 12.6484 10.6641C12.4844 10.6641 12.3438 10.6172 12.25 10.5L10 8.25L7.75 10.5C7.63281 10.6172 7.49219 10.6641 7.35156 10.6641C7.1875 10.6641 7.04688 10.6172 6.95312 10.5L6.41406 9.98438C6.29688 9.89062 6.25 9.75 6.25 9.58594C6.25 9.44531 6.29688 9.30469 6.41406 9.1875L9.60156 6Z"
          fill={stroke}
        />
      </svg>
    </>
  );
};

export const MultiAnnotationMarker = (
  props: ReferenceLineProps,
  stroke: string,
  fill: string,
  annotationEvents: AnnotationEvent[],
): ReactElement<SVGElement> => {
  if (!props.viewBox || !props.viewBox.x || !props.viewBox.height) {
    return <></>;
  }

  return (
    <>
      <svg
        width="20"
        height="28"
        viewBox="0 0 20 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        x={props.viewBox.x - 10}
        y={props.viewBox.height + 30}
        data-for="annotationsTooltip"
        data-tip={JSON.stringify(annotationEvents)}
        style={{ cursor: "pointer" }}
      >
        <path
          d="M13.9057 21.7894L10.4176 27.0371C10.3186 27.1856 10.2097 27.25 10 27.25C9.79159 27.25 9.68271 27.1864 9.5842 27.0398C8.67931 25.6512 7.51523 23.9 6.0926 21.7868C4.35646 19.1617 3.2189 17.4059 2.67184 16.5084C1.88761 15.2195 1.38451 14.1764 1.13284 13.3671C0.882226 12.5613 0.75 11.609 0.75 10.5C0.75 8.729 1.17014 7.10625 2.00777 5.61783C2.8501 4.12107 3.97767 2.93882 5.39541 2.05917C6.80393 1.18525 8.33355 0.75 10 0.75C11.6665 0.75 13.1961 1.18525 14.6046 2.05917C16.0223 2.93882 17.1499 4.12107 17.9922 5.61783C18.8299 7.10625 19.25 8.729 19.25 10.5C19.25 11.609 19.1178 12.5613 18.8672 13.3671C18.6155 14.1764 18.1125 15.2193 17.3283 16.5081C16.7812 17.4057 15.6432 19.1622 13.9063 21.7885C13.9061 21.7888 13.9059 21.7891 13.9057 21.7894Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1.5"
        />
        <text y={17} x={6} fill={stroke} fontSize={14}>
          {annotationEvents.length}
        </text>
      </svg>
    </>
  );
};
