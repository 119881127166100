import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import AutoComplete, { AutoCompleteItem } from "../AutoComplete";
import Pill from "../Pill";
import { FormattedMessage } from "react-intl";
import { ModalRow } from "../ModalParts";
import { Body } from "src/components/common/Typography";
import { AppColors } from "../Styling";
import Checkbox from "../Checkbox";

type SubscriptionLocationSelectorProps = {
  buildingList: AutoCompleteItem[];
  defaultSelected?: AutoCompleteItem;
  onSelectedChange: (items: AutoCompleteItem[]) => void;
};

const ItemHeader = styled(Body)`
  color: ${AppColors.neutral["light-navy-3"]};
`;

export const SubscriptionLocationSelector = ({
  buildingList,
  defaultSelected,
  onSelectedChange,
}: SubscriptionLocationSelectorProps) => {
  const [selectedBuildings, setSelectedBuildings] = useState<
    AutoCompleteItem[]
  >(defaultSelected ? [defaultSelected] : []);
  const [allSelected, setAllSelected] = useState(false);

  const handleAllLocationsCheck: React.ChangeEventHandler<HTMLInputElement> = e => {
    const isChecked = e.target.checked;
    setAllSelected(!allSelected);
    setSelectedBuildings(isChecked ? buildingList : []);
  };

  const autoCompleteSelectionHandler = (item: AutoCompleteItem) => {
    // avoid picking the same buidling twice!
    if (!selectedBuildings.some(_b => _b.id === item.id)) {
      setSelectedBuildings([...selectedBuildings, item]);
    }
  };

  const handleAllPillX = () => {
    setAllSelected(false);
    setSelectedBuildings([]);
  };

  const clearBuilding = (id: string) => {
    setSelectedBuildings(selectedBuildings.filter(b => b.id !== id));
  };

  useEffect(() => {
    onSelectedChange(selectedBuildings);
  }, [selectedBuildings]);

  return (
    <ModalRow style={{ marginBottom: "10px" }}>
      <ItemHeader>
        <FormattedMessage id="measures.labels.location" />
      </ItemHeader>
      <div
        style={{
          marginBottom: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Checkbox
          defaultChecked={allSelected}
          labelId="settings.subscriptions.allLocationsLabel"
          name="selectAllLocations"
          fontSize="small"
          onChange={handleAllLocationsCheck}
        />
        {selectedBuildings.length > 0 && (
          <Pill buttonHandler={handleAllPillX}>
            <FormattedMessage id="measures.filters.clearAll" />
          </Pill>
        )}
      </div>
      <div style={{ maxWidth: "650px", maxHeight: "100px", overflowY: "auto" }}>
        {!allSelected &&
          selectedBuildings.map(b => (
            <Pill key={b.id} buttonHandler={() => clearBuilding(b.id)}>
              {b.name}
            </Pill>
          ))}
        {allSelected && (
          <Pill buttonHandler={handleAllPillX}>
            <FormattedMessage
              id="settings.subscriptions.allLocationsSelectedLabel"
              values={{ num: selectedBuildings.length }}
            />
          </Pill>
        )}
      </div>
      {!allSelected && (
        <AutoComplete
          items={buildingList}
          selectionHandler={autoCompleteSelectionHandler}
          listMaxHeight="100px"
        />
      )}
    </ModalRow>
  );
};
