import { useLayoutEffect } from 'react';

/**
 * Use this hook to update the page title. It is fun and easy! (well, maybe not _fun_ per se) 
 * Just pass in a string, and it will add " | Measurabl" at the end because all our page titles
 * should have that at the end! See this doc for info of what page titles should look like:
 * 
 * https://hatch-data.atlassian.net/wiki/spaces/UED/pages/1337622566/Tab+Titling
 * 
 * 
 * @param title string you want to display before the " | Measurabl" bit of the title
 */
export function usePageTitle(title: string) {
  // useLayoutEffect will happen before DOM painting so we get our title in there
  // 0.002312 seconds faster! 
  useLayoutEffect(() => {
    if (typeof title === 'string' && title.trim().length > 0) {
      document.title = `${title.trim()} | Measurabl`;
    }
  }, [title]);
}