import React, { useState } from "react";
import { ChartDataGranularity, ChartRenderData } from "src/types/charting";
import {
  TintedBGModal,
  ModalRow,
  ModalHeader,
  ButtonSpacer,
  RowItem,
} from "src/components/common/ModalParts";
import { FormattedMessage, useIntl } from "react-intl";
import { SmallSpindicator } from "src/components/common/LoadingIndicator";
import { daysBetweenDates } from "src/helpers/dates";
import { getExportGranularityOptions } from "src/helpers/charting";
import GenericDataError from "src/components/common/GenericDataError";
import { GenericDataErrorType } from "src/components/common/GenericDataError/GenericDataError";
import {
  MxReactIcon,
  MapPin,
  X,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "src/components/common/Styling";
import { H3, Body } from "src/components/common/Typography";
import { MxPrimaryReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";
import { BasicSelect } from "src/components/common/Dropdown/BasicSelect";

type ExplorerExportModalProps = {
  defaultGranularity: ChartDataGranularity;
  startDate: string;
  endDate: string;
  inProgress: boolean;
  charts: ChartRenderData;
  exportClicked: (selectedGranularity: ChartDataGranularity) => void;
  handleClose: () => void;
};

const ExplorerExportModal: React.FC<ExplorerExportModalProps> = ({
  defaultGranularity,
  startDate,
  endDate,
  inProgress,
  exportClicked,
  handleClose,
  charts,
}: ExplorerExportModalProps) => {
  const [selectedGranularity, setSelectedGranularity] = useState(
    defaultGranularity,
  );
  const translate = useIntl();

  const handleExport = () => {
    exportClicked(selectedGranularity);
  };

  const range = daysBetweenDates(startDate, endDate);

  const exportGranularityOptions = getExportGranularityOptions(
    range,
    translate.formatMessage,
  );
  const handleGranularityChange = (val: string) => {
    setSelectedGranularity(val as ChartDataGranularity);
  };

  const closeBtn = () => (
    <div style={{ display: "inline-block" }} onClick={handleClose}>
      <MxReactIcon
        Icon={X}
        color={
          inProgress
            ? AppColors.neutral["light-navy-9"]
            : AppColors.neutral.navy
        }
        style={{ marginLeft: "30px" }}
      />
    </div>
  );

  if (charts.size > 10) {
    return (
      <TintedBGModal>
        <ModalHeader>
          <H3>
            <FormattedMessage id="common.menuItem.labels.exportCSV" />
          </H3>
          <ButtonSpacer>{closeBtn()}</ButtonSpacer>
        </ModalHeader>
        <ModalRow>
          <GenericDataError
            type={GenericDataErrorType.Info}
            messageId="explorer.exportModal.warning"
          />
        </ModalRow>
      </TintedBGModal>
    );
  }

  const assets: string[] = [];

  charts.forEach(a => {
    if (a.queueItem.assetName) {
      assets.push(`${a.queueItem.assetName} (${a.chartProps.unit})`);
    }
  });

  return (
    <TintedBGModal>
      <ModalHeader>
        <H3>
          <FormattedMessage id="common.menuItem.labels.exportCSV" />
        </H3>
        <ButtonSpacer>
          {inProgress && <SmallSpindicator />}
          <MxPrimaryReactButton
            disabled={inProgress}
            onClick={handleExport}
            intlTextId="common.button.labels.download"
          />
          {closeBtn()}
        </ButtonSpacer>
      </ModalHeader>
      <ModalRow>
        {assets.map((asset, index) => (
          <Body key={`asset-${index}`}>
            <MxReactIcon
              Icon={MapPin}
              color={AppColors.neutral.navy}
              style={{ marginRight: "8px" }}
            />{" "}
            {asset}
          </Body>
        ))}
      </ModalRow>
      <table>
        <thead>
          <tr>
            <RowItem>
              <Body>
                <b>
                  <FormattedMessage id="dataGranularity.interval" />
                </b>
              </Body>
            </RowItem>
            <RowItem>
              <Body>
                <b>
                  <FormattedMessage id="subscriptions.period" />
                </b>
              </Body>
            </RowItem>
          </tr>
        </thead>
        <tbody>
          <tr>
            <RowItem>
              <BasicSelect
                values={exportGranularityOptions}
                value={selectedGranularity}
                onChange={handleGranularityChange}
              />
            </RowItem>
            <RowItem>
              <Body>
                {startDate} - {endDate}
              </Body>
            </RowItem>
          </tr>
        </tbody>
      </table>
    </TintedBGModal>
  );
};

export default ExplorerExportModal;
