import { ScheduleExceptionEvent } from "../types";
import { Body } from "src/components/common/Typography";
import {
  MxReactIcon,
  Calendar,
  LinkOut,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "src/components/common/Styling";
import { tracking } from "src/tracking";
import { ExplorerPageEvents } from "../ExplorerPage";
import { BodyNoWrap, VerticalLayout } from "src/components/common/Layout";
import { FormattedMessage } from "react-intl";
import { formatTimeDisplay } from "../../BuildingSettingsPage/formatTimeDisplay";
import { DateTime } from "luxon";
import { timeExtendsIntoNextDay } from "../../BuildingSettingsPage/helpers";
import { OverlayType } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { dateToLocaleString, FormatOptions } from "@hatchdata/intl-formatter";

const ScheduleEventTooltip = (
  event: ScheduleExceptionEvent,
  currentOrganizationId: string,
  userId: string,
  timezone: string,
) => {
  const formatter = (date: Date) =>
    dateToLocaleString(date, undefined, timezone, FormatOptions.DATE_SHORT);

  if (!event) {
    return <></>;
  }

  const daySpan = DateTime.fromISO(event.exceptionEndDate)
    .diff(DateTime.fromISO(event.exceptionDate))
    .as("days");

  const navigateToLink = () => {
    const url = `/settings/${currentOrganizationId}/building/${event.buildingId}/operating-schedule`;
    tracking.fireEvent(ExplorerPageEvents.OVERLAY_NEW_TAB, {
      userId,
      overlay: OverlayType.ScheduleException,
    });
    tracking.fireEvent(ExplorerPageEvents.OPEN_SCHEDULES, {
      url,
    });
  };

  /**
   * Returns true if the exception was entered for a single day, but the
   * time range actually spans into the following day. This is how exceptions
   * were created before we allowed users to enter in for multiple days at
   * a time.
   * @returns
   */
  const singleDayExceptionOverlaps = (): boolean => {
    if (event.exceptionTimeRanges.length === 0) {
      return false;
    }

    const startTime = new Date(
      0,
      0,
      0,
      event.exceptionTimeRanges[0].startHour,
      event.exceptionTimeRanges[0].startMinute,
    );

    const endTime = new Date(
      0,
      0,
      0,
      event.exceptionTimeRanges[0].endHour,
      event.exceptionTimeRanges[0].endMinute,
    );

    return daySpan === 1 && timeExtendsIntoNextDay(startTime, endTime);
  };

  return (
    <div key={`schedule-exception-tooltip-${event.id}`}>
      <VerticalLayout childSpacing={1}>
        <Body>
          <MxReactIcon
            Icon={Calendar}
            color={AppColors.semantic.lightPurple["light-purple"]}
          />{" "}
          <FormattedMessage id={`charts.explorer.overlays.ScheduleException`} />
        </Body>
        <BodyNoWrap title={event.name}>{event.name}</BodyNoWrap>
      </VerticalLayout>
      <BodyNoWrap title={event.buildingName}>{event.buildingName}</BodyNoWrap>
      <BodyNoWrap title={formatter(new Date(event.exceptionDate))}>
        {formatter(new Date(event.exceptionDate))}
        {(daySpan > 1 || singleDayExceptionOverlaps()) && (
          <>
            {" "}
            -{" "}
            {formatter(
              DateTime.fromISO(event.exceptionEndDate)
                .minus({ days: 1 })
                .toJSDate(),
            )}
          </>
        )}
      </BodyNoWrap>
      <BodyNoWrap>
        {formatTimeDisplay(
          event.exceptionTimeRanges.length > 0
            ? event.exceptionTimeRanges[0]
            : event.timeBlock,
          "",
          false,
        )}
      </BodyNoWrap>
      <Body title={event.notes}>{event.notes}</Body>
      <BodyNoWrap>
        <a
          style={{ fontWeight: 500 }}
          href={`/settings/${currentOrganizationId}/building/${event.buildingId}/operating-schedule`}
          target="_blank"
          rel="noreferrer noopener"
          onClick={navigateToLink}
        >
          <FormattedMessage id="charts.explorer.overlays.measureTooltip.viewInNewTab" />{" "}
          <MxReactIcon Icon={LinkOut} />
        </a>
      </BodyNoWrap>
    </div>
  );
};

export default ScheduleEventTooltip;
