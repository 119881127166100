import {
  buildChartPropertiesForChart,
  buildDataForChart,
  buildMetadataForChart,
  buildXAxisForChart,
  buildYAxisForChart,
  extractMinimaMaximaValueFromMetadata,
  shouldCalculateCommodityAverage,
  shouldCalculateCommoditySum,
  chartDataTimedReducer,
  buildMetricsForChart,
} from "src/components/app/ExplorerPage/parsers/commonBuildChart";
import {
  ChartDataGranularity,
  ChartDataRange,
  PointType,
  ChartMetadata,
  CommodityChartData,
  EquipmentPointDataResponse,
  IMeasurement,
  ParsedChartData,
} from "src/types/charting";
import {
  averageReducer,
  sumReducer,
} from "src/helpers/charting";
import {
  DataQueueItem,
  TimeseriesNode,
} from "src/components/app/ExplorerPage/types";
import {
  keyFromQueueItem,
  timeRangeFromTimeseriesKey,
} from "src/components/app/ExplorerPage/helpers";
import { PresentationProps, ChartDataPayload } from "../chartDataReducer";
import { MEASUREMENT_TYPE } from "./buildingDataParser";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";

export type TypedPointResponse = {
  data: EquipmentPointDataResponse;
  type: MEASUREMENT_TYPE;
};

// TODO: TESTS!!!! :cry:
export const parsePointData = (
  payload: ChartDataPayload,
  parsedData: ParsedChartData,
): ParsedChartData => {
  const combinedResponse = {
    data: payload.response as EquipmentPointDataResponse,
    type: MEASUREMENT_TYPE.GENERIC_COMMODITY_USAGE,
  };
  parsedData.queueItems.push(payload.queueItem);

  let _newYAxisMap = undefined;
  let _newXAxisMap = undefined;

  if (!isValidData(combinedResponse)) {
    return parsedData;
  }
  const unitName = extractUnitName(combinedResponse);
  const oldMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    parsedData.metadata,
    unitName,
  );
  // Parse metadata
  const { _newMetadata, _newMetrics } = parseMetadataAndMetrics(
    parsedData,
    combinedResponse,
    payload.queueItem,
  );
  // Parse data
  const _newData = parseData(
    parsedData,
    combinedResponse,
    payload.queueItem.commodity,
    payload.queueItem.timeseriesKey,
    payload.queueItem.assetKey,
  );

  const newMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    _newMetadata,
    unitName,
  );
  // Parse XAxis
  if (!parsedData.xAxes.get(payload.queueItem.timeseriesKey)) {
    const xAxisProps = parseXAxis(
      parsedData,
      combinedResponse,
      payload.rangeSize,
      payload.queueItem.timeseriesKey,
      payload.chartWidth,
      payload.queueItem.timezone || "America/New_York",
    );

    if (xAxisProps) {
      // Clone the map
      _newXAxisMap = new Map(parsedData.xAxes);
      _newXAxisMap.set(payload.queueItem.timeseriesKey, xAxisProps);
    }
  }
  // Parse YAxis
  if (oldMixMaxYAxisValues !== newMixMaxYAxisValues) {
    const yAxisProps = parseYAxis(
      parsedData,
      combinedResponse,
      payload.queueItem.commodity,
      _newMetadata,
    );

    if (yAxisProps) {
      // Clone the map
      _newYAxisMap = new Map(parsedData.yAxes);
      _newYAxisMap.set(unitName, yAxisProps);
    }
  }
  // Parse Chart data
  const chartProps = parseChart(
    parsedData,
    combinedResponse,
    payload.queueItem,
    payload.presentationProps,
    payload.isPastComparison,
  );
  const _newChartMap = new Map(parsedData.charts);
  if (chartProps) {
    _newChartMap.set(keyFromQueueItem(payload.queueItem), chartProps);
  }

  return {
    ...parsedData,
    metrics: _newMetrics,
    metadata: _newMetadata,
    data: _newData,
    xAxes: _newXAxisMap ? _newXAxisMap : parsedData.xAxes,
    yAxes: _newYAxisMap ? _newYAxisMap : parsedData.yAxes,
    charts: _newChartMap,
  } as ParsedChartData;
};
const isValidData = (combinedResponse: TypedPointResponse): boolean => {
  const response = combinedResponse.data;
  const pointResponse = response.equipmentPointById;

  return !!(
    pointResponse &&
    pointResponse.pointData &&
    pointResponse.pointData.values.length > 0
  );
};

const parseMetadataAndMetrics = (
  currentState: ParsedChartData,
  combinedResponse: TypedPointResponse,
  queueItem: DataQueueItem,
): { _newMetadata: ChartMetadata; _newMetrics: ChartMetadata } => {
  const response = combinedResponse.data;
  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return {
      _newMetadata: currentState.metadata,
      _newMetrics: currentState.metrics,
    };
  }

  const unitName = extractUnitName(combinedResponse);
  const values: IMeasurement[] = response.equipmentPointById.pointData.values;

  const minMaxValues = chartDataTimedReducer(values as IMeasurement[], "value");
  const commoditySum = shouldCalculateCommoditySum(queueItem.commodity)
    ? sumReducer(values as IMeasurement[], "value")
    : undefined;
  const commodityAverage = shouldCalculateCommodityAverage(queueItem.commodity)
    ? averageReducer(values as IMeasurement[], "value")
    : undefined;

  const _newMetadata = buildMetadataForChart(
    currentState.metadata,
    queueItem,
    minMaxValues,
    commoditySum,
    commodityAverage,
    unitName,
  );
  const _newMetrics = buildMetricsForChart(
    currentState.metrics,
    queueItem,
    minMaxValues,
    commoditySum,
    commodityAverage,
  );
  return { _newMetadata, _newMetrics };
};

const parseData = (
  currentState: ParsedChartData,
  combinedResponse: TypedPointResponse,
  commodityName: PointType,
  timestampKey: string,
  assetKey: string,
): TimeseriesNode[] => {
  const response = combinedResponse.data;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.data;
  }

  const values: IMeasurement[] = response.equipmentPointById.pointData.values;

  return buildDataForChart(
    currentState.data,
    timestampKey,
    commodityName,
    assetKey,
    values,
  );
};

const parseXAxis = (
  currentState: ParsedChartData,
  combinedResponse: TypedPointResponse,
  rangeSize: ChartDataRange,
  timestampKey: string,
  width: number,
  timezone: string,
) => {
  const response = combinedResponse.data;
  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.xAxes.get(timestampKey);
  }

  const values = response.equipmentPointById.pointData.values;

  const timeRange = timeRangeFromTimeseriesKey(timestampKey);
  const granularity = extractGranularity(combinedResponse);

  return buildXAxisForChart(
    width,
    values,
    timeRange,
    rangeSize,
    timezone,
    timestampKey,
    granularity,
  );
};

const parseYAxis = (
  currentState: ParsedChartData,
  combinedResponse: TypedPointResponse,
  commodityName: PointType,
  metadata: ChartMetadata,
) => {
  // This guards against bad data! -- but we already did this so do we need to do it here as well?
  const unitName = extractUnitName(combinedResponse);
  if (!isValidData(combinedResponse)) {
    return currentState.yAxes.get(unitName);
  }

  const orientation = "left";

  return buildYAxisForChart(metadata, commodityName, unitName, orientation);
};

const extractGranularity = (
  combinedResponse: TypedPointResponse,
): ChartDataGranularity => {
  return combinedResponse.data.equipmentPointById.pointData.granularity;
};

const extractUnitName = (combinedResponse: TypedPointResponse): string => {
  return getSymbolForUnit(
    combinedResponse.data.equipmentPointById.pointData.unit,
  );
};

const parseChart = (
  currentState: ParsedChartData,
  combinedResponse: TypedPointResponse,
  queueItem: DataQueueItem,
  presentationProps: PresentationProps,
  isPastComparison?: boolean,
): CommodityChartData | undefined => {
  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.charts.get(keyFromQueueItem(queueItem));
  }

  const unitName = extractUnitName(combinedResponse);

  return buildChartPropertiesForChart(
    queueItem,
    unitName,
    presentationProps,
    isPastComparison,
  );
};
