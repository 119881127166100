import React from "react";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import NavigationItem from "./NavigationItem";
import { BillManagementEnablementsDocument } from "../../../queries/typed";
import {
  Enablement,
  EnablementType,
  EnelIntegration,
  GobyIntegration,
} from "../../../types/graphql";
import { tracking } from "src/tracking";

type BillManagementItemProps = {
  organizationId: string;
};

export type BillManagementEnablementConfig = Partial<
  EnelIntegration | GobyIntegration
>;

type EnablementResponse = Partial<Enablement>;

const isEnabledBillManagementEnablement = (
  enablement: EnablementResponse,
): boolean =>
  (enablement.type === EnablementType.ENEL ||
    enablement.type === EnablementType.GOBY) &&
  (enablement.enabled || false);

export function getBillManagement(
  enablements: EnablementResponse[],
): EnablementResponse | undefined {
  return enablements
    ? enablements.find(isEnabledBillManagementEnablement)
    : undefined;
}

export const BillManagementItem: React.FC<BillManagementItemProps> = props => {
  const { data } = useQuery(BillManagementEnablementsDocument, {
    variables: {
      organizationId: props.organizationId,
    },
  });

  const billManagement = getBillManagement(
    data?.getOrganizationById?.enablements || [],
  );

  if (billManagement?.enabled) {
    return (
      <NavigationItem
        href={
          (billManagement.configuration as BillManagementEnablementConfig).url
        }
        target="_blank"
        rel="noreferrer noopener"
        onClick={() => {
          tracking.fireEvent("BillMgmtLoadBillMgmtPage", {});
        }}
      >
        <FormattedMessage id="navigation.bill_management" />
      </NavigationItem>
    );
  }
  return null;
};

export default BillManagementItem;
