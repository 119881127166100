import React from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import CenteredContentWrapper from "src/components/app/CenteredContentWrapper";
import { H1, Body } from "src/components/common/Typography";
import CenteredBox, { PlainCenteredBox } from "src/components/app/CenteredBox";
import Footer from "src/components/app/Footer";
import { ButtonLink } from "src/components/common/Link";

// both pages have the same block of "stuff" in them
const NotFoundContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpacedButtonLink = styled(ButtonLink)`
  margin-top: 64px;
`;
const SpacedHeading = styled(H1)`
  margin-bottom: 48px;
`;

const NotFound: React.FC = () => {
  return (
    <CenteredContentWrapper>
      <CenteredBox>
        <SpacedHeading>
          <FormattedMessage id="notFound.title" />
        </SpacedHeading>
        <Body>
          <FormattedMessage id="notFound.message" />
        </Body>
        <SpacedButtonLink to="/login">
          <FormattedMessage id="notFound.button" />
        </SpacedButtonLink>
      </CenteredBox>
      <Footer />
    </CenteredContentWrapper>
  );
};

export const AuthNotFound: React.FC = () => (
  <NotFoundContent>
    <PlainCenteredBox>
      <SpacedHeading>
        <FormattedMessage id="notFound.title" />
      </SpacedHeading>
      <Body>
        <FormattedMessage id="notFound.message" />
      </Body>
      <SpacedButtonLink to="/app/building-summary">
        <FormattedMessage id="notFound.button.authed" />
      </SpacedButtonLink>
    </PlainCenteredBox>
  </NotFoundContent>
);
export default NotFound;
