import React from "react";
import {
  MxReactIcon,
  Circle,
  Square,
  CircleSolid,
  SquareSolid,
  SquareCheck,
  SquareCheckSolid,
} from "src/componentLibrary/react/mx-icon-react";
import styled from "styled-components/macro";
import { InlineH4 } from "src/components/common/Typography";
import { AppColors } from "../Styling";
export const LegendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 4px;
  padding-bottom: 16px;
  margin-left: 16px;
  height: auto;
  align-items: center;

  @media (max-width: 1100px) {
    justify-content: left;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
    margin-left: 24px;
  }
`;

export const WrappingLegendContainer = styled(LegendContainer)`
  flex-wrap: wrap;
  height: auto;
  & > span {
    margin-bottom: 8px;
  }
`;
export const LegendLabel = styled(InlineH4)<{ inactive: boolean }>`
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 2px;
  cursor: pointer;
  display: flex;
  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
  color: ${props =>
    props.inactive
      ? AppColors.neutral["light-navy-3"]
      : AppColors.neutral.navy};
`;

interface ILegendItemProps {
  color: string;
  text: string;
  onClick?: () => void;
  inactive?: boolean;
  stroked?: boolean; // should we be a stroked white dot instead of filled when active
  title?: string; // will show on hover
}

export const LegendCheckboxItem: React.FC<ILegendItemProps> = ({
  color,
  onClick,
  text,
  inactive = false,
  stroked = false,
  title = "",
}) => {
  const icon = () => {
    if (inactive) {
      return Square;
    }
    return stroked ? SquareCheck : SquareCheckSolid;
  };
  return (
    <LegendLabel title={title} inactive={inactive} onClick={onClick}>
      <MxReactIcon Icon={icon()} style={{ marginRight: "8px" }} color={color} />
      {text}
    </LegendLabel>
  );
};

export const LegendDotItem: React.FC<ILegendItemProps> = ({
  color,
  onClick,
  text,
  inactive = false,
  stroked = false,
}) => {
  return (
    <LegendLabel inactive={inactive} onClick={onClick}>
      <MxReactIcon
        Icon={inactive || stroked ? Circle : CircleSolid}
        style={{ marginRight: "14px" }}
        color={color}
      />
      {text}
    </LegendLabel>
  );
};

export const LegendSquareItem: React.FC<ILegendItemProps> = ({
  color,
  onClick,
  text,
  inactive = false,
  stroked = false,
  title,
}) => {
  return (
    <LegendLabel inactive={inactive} onClick={onClick} title={title}>
      <MxReactIcon
        Icon={inactive || stroked ? Square : SquareSolid}
        style={{ marginRight: "14px" }}
        color={color}
      />
      {text}
    </LegendLabel>
  );
};
