import { useState, ReactNode } from "react";
import styled from "styled-components/macro";
import { HorizontalLayout, Box } from "src/components/common/Layout";
import { AppColors } from "src/components/common/Styling";

interface TabProps {
  content: ReactNode;
  tabTitleItem: ReactNode;
  id: string;
}

interface SimpleTabsPops {
  tabs: TabProps[];
  defaultSelected?: string;
  ariaLabel?: string;
}

const TabHeaderBox = styled(Box)`
  border-bottom: 1px solid ${AppColors.neutral["light-gray-9"]};
`;

const SimpleTabs = (props: SimpleTabsPops) => {
  const [selectedTabId, setSelectedTabId] = useState(
    props.defaultSelected || props.tabs[0].id,
  );
  const selectedTabContent = props.tabs.find(tab => tab.id === selectedTabId);
  return (
    <>
      {/* generate the header */}
      <TabHeaderBox padding={[0, 0, 0, 4]}>
        <HorizontalLayout
          childSpacing={4}
          role="tablist"
          aria-label={props.ariaLabel ?? ""}
        >
          {props.tabs.map(tab => (
            <TabItem
              role="tab"
              aria-controls={`tabcontent-${tab.id}`}
              aria-selected={tab.id === selectedTabId}
              onClick={() => setSelectedTabId(tab.id)}
              key={tab.id}
              id={tab.id}
              active={tab.id === selectedTabId}
            >
              {tab.tabTitleItem}
            </TabItem>
          ))}
        </HorizontalLayout>
      </TabHeaderBox>
      {/* show the selected content  */}
      <Box
        padding={[0, 0, 0, 0]}
        aria-labelledby={selectedTabId}
        id={`tabcontent-${selectedTabId}`}
      >
        {selectedTabContent?.content}
      </Box>
    </>
  );
};

interface TabItemProps {
  active: boolean;
}

const TabItem = styled.div<TabItemProps>`
  box-sizing: border-box;
  font-weight: ${props => (props.active ? 500 : 300)};
  border-bottom: ${props =>
    props.active
      ? `2px solid ${AppColors.primary["msr-green"]}`
      : "2px solid transparent"};
  cursor: default;
  &:hover {
    border-bottom: ${`2px solid ${AppColors.primary["light-msr-green"]}`};
  }
`;

export default SimpleTabs;
