import { ParsedChartData } from "src/types/charting";
import {
  generateFile,
  csvDataToString,
  formatISOTimestamp,
  formatSimpleTimestamp,
  CSVData,
} from "src/helpers/csvExporter";
import { formatNumber } from "@hatchdata/intl-formatter";

export const exportToCsv = (
  chartData: ParsedChartData,
  timezone: string | undefined,
) => {
  if (!chartData || !chartData.data.length) {
    return;
  }

  const csvContent = buildCSVContent(chartData, timezone);
  const filename = `Explorer_${new Date().getTime()}.csv`;

  generateFile(csvContent, filename);
};

export const buildCSVContent = (
  chartData: ParsedChartData,
  timezone: string | undefined,
): string => {
  const rows: CSVData[] = objectsArraysFromParsedChartData(chartData, timezone);
  const frontKeys = ["ISO Date", "Timezone", "Simple Date"];
  return csvDataToString(rows, timezone, ",", frontKeys);
};

const objectsArraysFromParsedChartData = (
  chartDataState: ParsedChartData,
  timezone: string | undefined,
): CSVData[] => {
  // an array of objects, apparently!
  let returnedCSVDAta: CSVData[] = [];

  let useTimezone = timezone;

  // if we didnt' get a TZ, grab one off the first chart.
  if (timezone === undefined) {
    // FYI: values().next().value gets the first element of a Mad
    useTimezone = chartDataState.charts.values().next().value.queueItem
      .timezone;
  }

  // build a map of NAMES for asset keys
  // <assetKey, name>
  const assetNames: Map<string, string | undefined> = new Map();
  chartDataState.charts.forEach(chart => {
    assetNames.set(chart.queueItem.assetKey, chart.queueItem.assetName);
  });

  /* =============================================================== *\
     ITERATE OVER EACH TimeSeriesNode
  \* =============================================================== */
  chartDataState.data.forEach(timeseriesNode => {
    /* =============================================================== *\
    chartDataState.data is an array of objects, so
    "dataNodes" are TimeSeriesNode objhects, which have the structure of

    timeSeriesKey: {
      commodity_name: {
        assetId: 12.0
        otherAssetId: 343.2312
      }
      other_commodity: {
        assetId: 52.0
        otherAssetId: 343.2312
      }
      timestamp: 12310238123123
    },
    maybeAnotherTimeseriesKey: {
      ...
    }
 \* =============================================================== */

    // array of the timestamp keys for this node
    const timeseriesKeys = Object.keys(timeseriesNode);
    timeseriesKeys.forEach(timeseriesKey => {
      const csvData: CSVData = {}; // will hold the keys and values of our data
      const timeseriesNodeValue = timeseriesNode[timeseriesKey];
      /* =============================================================== *\
         timeseriesNodeValue holds something like:

          timestamp: 1576213200000,
          ELECTRICITY_DEMAND: {
            "BUILDING__95e7e0e1-5229-40db-9ca7-83b1d3ebb8ac": 208.98000240325928,
            "BUILDING__7118e6cb-a945-4a47-a28f-36e26cc4a77a": 601.5600061416626,
          },
          TEMPERATURE: {
            "BUILDING__95e7e0e1-5229-40db-9ca7-83b1d3ebb8ac": 20,
            "BUILDING__7118e6cb-a945-4a47-a28f-36e26cc4a77a": 64,
          },
      \* =============================================================== */
      const nodeValueKeys = Object.keys(timeseriesNodeValue);
      // nodeValueKeys holds something like: [ 'timestamp', 'ELECTRICITY_DEMAND', 'ELECTRICITY_USAGE', 'TEMPERATURE']
      nodeValueKeys.forEach(nodeValueKey => {
        // timestamp holds a value directly.
        if (nodeValueKey === "timestamp") {
          csvData["ISO Date"] = formatISOTimestamp(
            timeseriesNodeValue.timestamp,
          );
          csvData["Timezone"] = useTimezone;
          csvData["Simple Date"] = formatSimpleTimestamp(
            timeseriesNodeValue.timestamp,
            useTimezone,
          );
        } else {
          // The others do not, so we have to iterate through the data inside
          const commodityData = timeseriesNodeValue[nodeValueKey];
          const assetKeyList = Object.keys(commodityData);
          assetKeyList.forEach(assetKey => {
            // make a key with the name and commodity
            const _name = assetNames.get(assetKey) || "";
            let _key = `"${_name}_${nodeValueKey}"`;
            // removes non-printable / wierd characters and spaces
            _key = _key.replace(/[^\x20-\x7E]/g, "");
            _key = _key.replace(/ /g, "_");
            csvData[_key] = commodityData[assetKey]
              ? formatNumber(commodityData[assetKey] as number)
              : null;
          });
        }
      });
      returnedCSVDAta.push(csvData);
    });
  });
  returnedCSVDAta.sort((a, b) => {
    if (!a["ISO Date"] || !b["ISO Date"]) {
      return 0;
    }
    return a["ISO Date"] < b["ISO Date"] ? -1 : 1;
  });

  return returnedCSVDAta;
};
