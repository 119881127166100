/* This file is autogenerated from the Public API schema. Refer to README.md for more information */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CurrencyValue: number;
  DateTime: string;
  JSON: { [key: string]: unknown };
  LocalDate: string;
  UTCDate: any;
  Upload: any;
};

export enum ActivationStatus {
  ACTIVATED = 'ACTIVATED',
  AWAITING_CONNECTION = 'AWAITING_CONNECTION',
  ERROR = 'ERROR',
  PROVISIONING = 'PROVISIONING'
}

export type Activity = {
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export type ActivityResponse = {
  __typename?: 'ActivityResponse';
  activity?: Maybe<Activity>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum ActivityType {
  ASSIGNED = 'ASSIGNED',
  CLOSED = 'CLOSED',
  COMMENT = 'COMMENT',
  CREATED = 'CREATED',
  INTERNAL_COMMENT = 'INTERNAL_COMMENT',
  LABEL_ADDED = 'LABEL_ADDED',
  LABEL_REMOVED = 'LABEL_REMOVED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  SUPPRESSED = 'SUPPRESSED',
  UPDATED_FIELD = 'UPDATED_FIELD'
}

export type AddBuildingIntegrationMappingInput = {
  buildingId: Scalars['ID'];
  enabled?: InputMaybe<Scalars['Boolean']>;
  integrationId: Scalars['ID'];
  integrationName: IntegrationName;
  organizationId: Scalars['ID'];
};

export type AddBuildingIntegrationMappingResponse = {
  __typename?: 'AddBuildingIntegrationMappingResponse';
  buildingId: Scalars['ID'];
  integration: IntegrationMapping;
};

export type AddLabelInput = {
  domain: LabelDomain;
  value: Scalars['String'];
};

export type AddLabelToMeasureResponse = {
  __typename?: 'AddLabelToMeasureResponse';
  measure?: Maybe<Measure>;
};

export type AggregateData = {
  __typename?: 'AggregateData';
  measuredIn: UnitOfMeasurement;
  /** @deprecated Unit of Measurement will soon not be a point field, as a point's unit of measurement is always the same for a given PointType. */
  name: Scalars['String'];
  range: TimeRange;
  value: Scalars['Float'];
};

export type AggregatedBaselineUsage = {
  __typename?: 'AggregatedBaselineUsage';
  range: TimeRange;
  unit: Unit;
  value?: Maybe<Scalars['Float']>;
};

export type AggregatedUsageData = {
  __typename?: 'AggregatedUsageData';
  area?: Maybe<Scalars['Float']>;
  areaUnit: AreaUnit;
  energyUsageIntensity?: Maybe<Scalars['Float']>;
  range: TimeRange;
  /** @deprecated Use 'area' instead, for clarity when supporting en_ca units */
  sqft?: Maybe<Scalars['Float']>;
  unit: Unit;
  value?: Maybe<Scalars['Float']>;
};

export type Alert = {
  __typename?: 'Alert';
  asset: AlertAsset;
  buildingOperatingScheduleId: Scalars['String'];
  created: Scalars['DateTime'];
  id: Scalars['String'];
  isDuringOccupiedHours?: Maybe<Scalars['Boolean']>;
  modified?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  pointType: PointType;
  subscriptions?: Maybe<Array<Maybe<AlertSubscription>>>;
  threshold: Threshold;
  unitOfMeasurement: Scalars['String'];
  userId: Scalars['String'];
};

export type AlertAsset = {
  __typename?: 'AlertAsset';
  id: Scalars['String'];
  type: AssetType;
};

export type AlertAssetInput = {
  id: Scalars['String'];
  type: AssetType;
};

export enum AlertNotificationType {
  EMAIL = 'EMAIL'
}

export type AlertPayload = {
  __typename?: 'AlertPayload';
  alert?: Maybe<Alert>;
  code: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AlertSubscription = {
  __typename?: 'AlertSubscription';
  alertId: Scalars['String'];
  id: Scalars['String'];
  notificationType: AlertNotificationType;
  subscribedUserId?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type AllIngestPointsInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum AlwaysEnabledUnits {
  BINARY = 'BINARY'
}

export enum ApiUsagePlanLevel {
  BASE = 'BASE'
}

export enum AreaUnit {
  SQFT = 'SQFT',
  SQM = 'SQM'
}

export type Asset = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum AssetType {
  BUILDING = 'BUILDING',
  METER = 'METER'
}

export type AssignBuildingsToUserInput = {
  buildingIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type AssignBuildingsToUserPayload = {
  __typename?: 'AssignBuildingsToUserPayload';
  assignedBuildingIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type AssignEquipmentToBuildingInput = {
  buildingId: Scalars['String'];
  equipmentId: Scalars['String'];
};

export type AssignEquipmentToBuildingResponse = {
  __typename?: 'AssignEquipmentToBuildingResponse';
  equipment?: Maybe<Equipment>;
};

export type AssignRoleToUserInput = {
  role: Role;
  userId: Scalars['String'];
};

export type AssignRoleToUserPayload = {
  __typename?: 'AssignRoleToUserPayload';
  assignedRole: Role;
  userId: Scalars['String'];
};

export type AssignSpaceGroupToParentInput = {
  parentGroupId: Scalars['String'];
  spaceGroupId: Scalars['String'];
};

export type AssignSpaceGroupToParentResponse = {
  __typename?: 'AssignSpaceGroupToParentResponse';
  spaceGroup?: Maybe<SpaceGroup>;
};

export type Assignment = {
  __typename?: 'Assignment';
  addedTimestamp: Scalars['DateTime'];
  addedUserId: Scalars['String'];
  id: Scalars['String'];
  meter: Meter;
  removedTimestamp?: Maybe<Scalars['DateTime']>;
  removedUserId?: Maybe<Scalars['String']>;
  serviceAccount: ServiceAccount;
};

export type AssignmentActivity = Activity & {
  __typename?: 'AssignmentActivity';
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['String'];
  newOwner?: Maybe<User>;
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export type AttachFileToBillResponse = {
  __typename?: 'AttachFileToBillResponse';
  attachment?: Maybe<BillAttachment>;
};

export type Attachment = {
  __typename?: 'Attachment';
  attachedDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AttachmentInput = {
  customerMeasureAttachmentId?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  name?: InputMaybe<Scalars['String']>;
};

export type AttachmentResponse = {
  __typename?: 'AttachmentResponse';
  attachment?: Maybe<Attachment>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AvailableUtilityDataResponse = {
  __typename?: 'AvailableUtilityDataResponse';
  commodities: Array<Maybe<CommodityName>>;
  years: Array<Maybe<Scalars['Int']>>;
};

export type AvoidableCost = {
  __typename?: 'AvoidableCost';
  electricity?: Maybe<ElectricityAmount>;
  emissions?: Maybe<EmissionsAmount>;
  gas?: Maybe<GasAmount>;
  monetary?: Maybe<CurrencyAmount>;
};

export type AvoidableCostInput = {
  electricityKwh?: InputMaybe<Scalars['Float']>;
  emissionsGramsCo2e?: InputMaybe<Scalars['Float']>;
  gasTherms?: InputMaybe<Scalars['Float']>;
  monetary?: InputMaybe<CurrencyAmountInput>;
};

export type AvoidableEnergies = {
  __typename?: 'AvoidableEnergies';
  ELECTRICITY_USAGE?: Maybe<AvoidableEnergy>;
  NATURAL_GAS_ENERGY?: Maybe<AvoidableEnergy>;
};

export type AvoidableEnergy = {
  __typename?: 'AvoidableEnergy';
  unit: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type AvoidedCost = {
  __typename?: 'AvoidedCost';
  electricity?: Maybe<CurrencyAmount>;
};

export type Baseline = {
  __typename?: 'Baseline';
  measuredIn: UnitOfMeasurement;
  /** @deprecated Unit of Measurement will soon not be a point field, as a point's unit of measurement is always the same for a given PointType. */
  name: Scalars['String'];
  range: TimeRange;
  values: Array<Maybe<BaselineValue>>;
};

export enum BaselineEvaluationGranularity {
  DAILY = 'DAILY',
  HOURLY = 'HOURLY'
}

export type BaselineIntervalData = {
  __typename?: 'BaselineIntervalData';
  granularity: Granularity;
  measuredIn: UnitOfMeasurement;
  message?: Maybe<Scalars['String']>;
  metrics?: Maybe<ModelMetrics>;
  /** @deprecated Unit of Measurement will soon not be a point field, as a point's unit of measurement is always the same for a given PointType. */
  name: Scalars['String'];
  range: TimeRange;
  status?: Maybe<DataStatus>;
  trainingPeriod?: Maybe<TrainingPeriod>;
  unit: Unit;
  values: Array<Maybe<Measurement>>;
};

export type BaselineJob = {
  __typename?: 'BaselineJob';
  buildingId: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  countrySubDiv?: Maybe<Scalars['String']>;
  error?: Maybe<BaselineJobError>;
  excludeFeature?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['String']>;
  isGlobalDefault?: Maybe<Scalars['Boolean']>;
  modelName?: Maybe<Scalars['String']>;
  pointType: PointType;
  queuedAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  trainingPeriodEnd?: Maybe<Scalars['String']>;
  trainingPeriodStart?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BaselineJobError = {
  __typename?: 'BaselineJobError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type BaselineModel = {
  __typename?: 'BaselineModel';
  buildingId: Scalars['String'];
  creationTime: Scalars['String'];
  creationTimeISO: Scalars['String'];
  /** @deprecated it is always hourly */
  evaluationGranularity: BaselineEvaluationGranularity;
  id: Scalars['String'];
  isGlobalDefault: Scalars['Boolean'];
  /** @deprecated it is no longer populated */
  jobId?: Maybe<Scalars['Int']>;
  /** @deprecated This API no longer provides access to model data. This has been kept here for query backwards compat. */
  model: Scalars['JSON'];
  modelName?: Maybe<Scalars['String']>;
  overallSmae?: Maybe<Scalars['Float']>;
  pointType: PointType;
  /** @deprecated This API no longer provides access to training data. This has been kept here for query backwards compat. */
  trainingData: Scalars['JSON'];
  trainingPeriod: TrainingPeriod;
  updated: Scalars['String'];
  updatedISO: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type BaselineModelDynamo = {
  __typename?: 'BaselineModelDynamo';
  creationTime?: Maybe<Scalars['String']>;
  creationTimeISO?: Maybe<Scalars['String']>;
  evaluationGranularity?: Maybe<BaselineEvaluationGranularity>;
  model?: Maybe<Scalars['JSON']>;
  pointType?: Maybe<PointType>;
  result?: Maybe<Scalars['String']>;
  trainingPeriod?: Maybe<TrainingPeriod>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type BaselineValue = {
  __typename?: 'BaselineValue';
  data: Array<Maybe<Measurement>>;
  meterId: Scalars['String'];
};

export type BaselineVariance = {
  __typename?: 'BaselineVariance';
  baselineUsageAggregate: Scalars['Float'];
  /** @deprecated Unit of Measurement will soon not be a point field, as a point's unit of measurement is always the same for a given PointType. */
  electricityUsageAggregate: Scalars['Float'];
  measuredIn: UnitOfMeasurement;
  status: DataStatus;
  varianceFromBaselineElectricityUsage: Scalars['Float'];
};

export type BillAttachment = {
  __typename?: 'BillAttachment';
  attachedDate: Scalars['DateTime'];
  fileType: Scalars['String'];
  fileUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BillAttachmentInput = {
  file: Scalars['Upload'];
  name?: InputMaybe<Scalars['String']>;
};

export type BillInput = {
  billLines?: InputMaybe<Array<InputMaybe<BillLineInput>>>;
  billingPeriod: PeriodInput;
  buildingId: Scalars['String'];
  commodityName: CommodityName;
  cost: Scalars['Float'];
  currency: Currency;
  demand?: InputMaybe<Scalars['Float']>;
  demandUnit?: InputMaybe<Unit>;
  estimatedBill?: InputMaybe<Scalars['Boolean']>;
  readings: Array<InputMaybe<ReadingInput>>;
  tax?: InputMaybe<Scalars['Float']>;
  unit: Unit;
  usage: Scalars['Float'];
};

export type BillLineInput = {
  billLineType?: InputMaybe<BillLineType>;
  cost?: InputMaybe<Scalars['Float']>;
  currency: Currency;
  value?: InputMaybe<Scalars['Float']>;
};

export type BillLineResponse = {
  __typename?: 'BillLineResponse';
  billLineId: Scalars['String'];
  billLineType: BillLineType;
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  currency: Currency;
  enteredBy?: Maybe<User>;
  value?: Maybe<Scalars['Float']>;
};

export enum BillLineType {
  DELIVERY = 'DELIVERY',
  SEWER = 'SEWER',
  SUPPLY = 'SUPPLY',
  WATER = 'WATER'
}

export type BillManagementUpdateInput = {
  billManagementOnly?: InputMaybe<Scalars['Boolean']>;
  billManagementUsername?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type BillManagementUpdateResponse = {
  __typename?: 'BillManagementUpdateResponse';
  billManagementOnly?: Maybe<Scalars['Boolean']>;
  billManagementUsername?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type BillResponse = {
  __typename?: 'BillResponse';
  billId: Scalars['String'];
  billLines?: Maybe<Array<Maybe<BillLineResponse>>>;
  billingPeriod: Period;
  buildingId: Scalars['String'];
  carbonEmission?: Maybe<Scalars['Float']>;
  commodityName: CommodityName;
  cost: Scalars['Float'];
  createdAt?: Maybe<Scalars['String']>;
  currency: Currency;
  demand?: Maybe<Scalars['Float']>;
  demandUnit?: Maybe<Unit>;
  enteredBy?: Maybe<User>;
  estimatedBill?: Maybe<Scalars['Boolean']>;
  /** @deprecated No longer exists */
  localAccountNumber?: Maybe<Scalars['String']>;
  readings: Array<Maybe<ReadingResponse>>;
  tax?: Maybe<Scalars['Float']>;
  unit: Unit;
  usage: Scalars['Float'];
};

export type Billing = {
  __typename?: 'Billing';
  commodityBilling: Array<Maybe<CommodityBilling>>;
  getBillById?: Maybe<ConsolidatedBillResponse>;
  getBills: Array<Maybe<ConsolidatedBillResponse>>;
  getKPIs?: Maybe<KpIsResponse>;
  getMissingBills?: Maybe<MissingBillsResponse>;
};


export type BillingCommodityBillingArgs = {
  commodityNames?: InputMaybe<Array<CommodityName>>;
};


export type BillingGetBillByIdArgs = {
  id: Scalars['String'];
};


export type BillingGetBillsArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type BillingGetKpIsArgs = {
  endDate: Scalars['String'];
  serviceAccounts: Array<InputMaybe<Scalars['String']>>;
  startDate: Scalars['String'];
};


export type BillingGetMissingBillsArgs = {
  endDate: Scalars['String'];
  serviceAccounts: Array<InputMaybe<Scalars['String']>>;
  startDate: Scalars['String'];
};

export type BillingReport = {
  __typename?: 'BillingReport';
  billingDays: Scalars['Int'];
  cost: Scalars['Float'];
  currency: Currency;
  mostSignificantBillPeriod: Period;
  unit: Unit;
  usage: Scalars['Float'];
};

export type BlendedRate = {
  __typename?: 'BlendedRate';
  currency: Currency;
  unit: Unit;
  unitPrice: Scalars['Float'];
};

export type BlendedRateInput = {
  currency: Currency;
  unit: Unit;
  unitPrice: Scalars['Float'];
};

/**
 *     Keys are the same as CommodityNames omitting non-purchased commodities
 *     CHILLED_WATER
 * DIESEL
 * ELECTRICITY
 * GAS
 * HEATING_OIL
 * HOT_WATER
 * IRRIGATION
 * LIGHTING
 * PROPANE
 * SEWER
 * STEAM
 * WASTE
 * WATER
 */
export type BlendedRates = {
  __typename?: 'BlendedRates';
  CHILLED_WATER?: Maybe<BlendedRate>;
  DIESEL?: Maybe<BlendedRate>;
  ELECTRICITY?: Maybe<BlendedRate>;
  GAS?: Maybe<BlendedRate>;
  HEATING_OIL?: Maybe<BlendedRate>;
  HOT_WATER?: Maybe<BlendedRate>;
  IRRIGATION?: Maybe<BlendedRate>;
  LIGHTING?: Maybe<BlendedRate>;
  PROPANE?: Maybe<BlendedRate>;
  SEWER?: Maybe<BlendedRate>;
  STEAM?: Maybe<BlendedRate>;
  WASTE?: Maybe<BlendedRate>;
  WATER?: Maybe<BlendedRate>;
};

export type BlendedRatesInput = {
  CHILLED_WATER?: InputMaybe<BlendedRateInput>;
  DIESEL?: InputMaybe<BlendedRateInput>;
  ELECTRICITY?: InputMaybe<BlendedRateInput>;
  GAS?: InputMaybe<BlendedRateInput>;
  HEATING_OIL?: InputMaybe<BlendedRateInput>;
  HOT_WATER?: InputMaybe<BlendedRateInput>;
  IRRIGATION?: InputMaybe<BlendedRateInput>;
  LIGHTING?: InputMaybe<BlendedRateInput>;
  PROPANE?: InputMaybe<BlendedRateInput>;
  SEWER?: InputMaybe<BlendedRateInput>;
  STEAM?: InputMaybe<BlendedRateInput>;
  WASTE?: InputMaybe<BlendedRateInput>;
  WATER?: InputMaybe<BlendedRateInput>;
};

export enum BoilerEfficiencyUnits {
  PERCENT = 'PERCENT'
}

export enum BoilerLoadUnits {
  PERCENT = 'PERCENT'
}

export enum BoilerPlantEnergyUsageUnits {
  BRITISH_THERMAL_UNITS = 'BRITISH_THERMAL_UNITS',
  KILOJOULES = 'KILOJOULES'
}

export type BudgetedAmount = {
  __typename?: 'BudgetedAmount';
  cost: CurrencyAmount;
  usage: CommodityUsageAmount;
};

export type Building = Asset & {
  __typename?: 'Building';
  aggregatedBaselineUsage: AggregatedBaselineUsage;
  aggregatedUsageData: AggregatedUsageData;
  allBaselineModels?: Maybe<Array<Maybe<BaselineModel>>>;
  annotations: Array<BuildingAnnotation>;
  availableAggregates?: Maybe<Array<Maybe<MeterAggregateType>>>;
  baselineJobs?: Maybe<Array<Maybe<BaselineJob>>>;
  /** @deprecated This retrieves data from a Dynamo database that will soon be deprecated. Please use `allBaselineModels` instead. */
  baselineModels?: Maybe<Array<Maybe<BaselineModelDynamo>>>;
  baselineUsage: BaselineIntervalData;
  baselineVariance: BaselineVariance;
  billing?: Maybe<Billing>;
  blendedRates?: Maybe<BlendedRates>;
  buildingNonOperatingTimeRanges: Array<Maybe<TimeRange>>;
  calendarYearCommodityBudget?: Maybe<CalendarYearCommodityBudget>;
  carbonEmissions?: Maybe<CarbonEmissions>;
  carbonFreeEnergy?: Maybe<CarbonFreeEnergy>;
  commoditiesWithCalendarYearBudget: Array<CalendarYearBudgetedCommodity>;
  commodityUsageData: IntervalData;
  customerBuildingId?: Maybe<Scalars['String']>;
  defaultMeasureAssignee?: Maybe<User>;
  degreeDayData: DegreeDayData;
  electricityDemandData: IntervalData;
  electricityUsageData: IntervalData;
  enablements?: Maybe<Array<Maybe<FeatureEnablement>>>;
  equipment?: Maybe<Array<Maybe<Equipment>>>;
  equipmentGroups?: Maybe<Array<BuildingEquipmentGroup>>;
  group?: Maybe<SpaceGroup>;
  id: Scalars['String'];
  includeInGroupAggregate: Scalars['Boolean'];
  integrationMappings?: Maybe<Array<IntegrationMapping>>;
  location: Location;
  measures: Array<Maybe<Measure>>;
  meterGroups?: Maybe<Array<MeterGroup>>;
  meters: Array<Maybe<Meter>>;
  name: Scalars['String'];
  occupancy: Array<Maybe<OccupancyResponse>>;
  operatingSchedule?: Maybe<OperatingSchedule>;
  /** @deprecated Since implementing multi-org, use relationships instead */
  organizationId: Scalars['String'];
  relationships: Array<Maybe<BuildingOrganizationRelationship>>;
  scheduleExceptions?: Maybe<Array<Maybe<ScheduleException>>>;
  serviceAccounts?: Maybe<Array<ServiceAccount>>;
  sqft?: Maybe<Scalars['Float']>;
  threshold?: Maybe<BuildingThreshold>;
  totalElectricityUsage: AggregateData;
  usageByEnergySource?: Maybe<UsageByEnergySource>;
  usageVsBaselineForPeriod?: Maybe<UsageVsBaselineReportData>;
  usageVsBaselineTotalsForPeriod?: Maybe<UsageVsBaselineTotalsReportData>;
  weatherData: WeatherData;
  yearlyCarbonReporting: CarbonReportingResponse;
  ytdCarbon?: Maybe<YtdCarbon>;
};


export type BuildingAggregatedBaselineUsageArgs = {
  pointType?: InputMaybe<PointType>;
  timeRange: TimeRangeInput;
  unit?: InputMaybe<Unit>;
};


export type BuildingAggregatedUsageDataArgs = {
  aggregateType?: InputMaybe<MeterAggregateType>;
  pointType: PointType;
  range: TimeRangeInput;
  unit?: InputMaybe<Unit>;
};


export type BuildingBaselineJobsArgs = {
  isLastModelJob?: InputMaybe<Scalars['Boolean']>;
};


export type BuildingBaselineUsageArgs = {
  granularity?: InputMaybe<Granularity>;
  modelId?: InputMaybe<Scalars['String']>;
  pointType?: InputMaybe<PointType>;
  range: TimeRangeInput;
  unit?: InputMaybe<Unit>;
};


export type BuildingBaselineVarianceArgs = {
  granularity: Granularity;
  timeRange: TimeRangeOptionInput;
};


export type BuildingBuildingNonOperatingTimeRangesArgs = {
  range: TimeRangeInput;
};


export type BuildingCalendarYearCommodityBudgetArgs = {
  commodity: CommodityName;
  year: Scalars['Int'];
};


export type BuildingCarbonEmissionsArgs = {
  granularity?: InputMaybe<Granularity>;
  range?: InputMaybe<TimeRangeInput>;
};


export type BuildingCarbonFreeEnergyArgs = {
  range: TimeRangeInput;
};


export type BuildingCommodityUsageDataArgs = {
  aggregateType?: InputMaybe<MeterAggregateType>;
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  type: PointType;
  unit?: InputMaybe<Unit>;
};


export type BuildingDegreeDayDataArgs = {
  temperatureUnit?: InputMaybe<TemperatureUnit>;
  timeRange: TimeRangeInput;
  unit?: InputMaybe<Unit>;
};


export type BuildingElectricityDemandDataArgs = {
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  unit?: InputMaybe<Unit>;
};


export type BuildingElectricityUsageDataArgs = {
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  unit?: InputMaybe<Unit>;
};


export type BuildingMeasuresArgs = {
  unitPreferences?: InputMaybe<PreferredUnitsInput>;
};


export type BuildingOccupancyArgs = {
  year: Scalars['Int'];
};


export type BuildingTotalElectricityUsageArgs = {
  range: TimeRangeInput;
  unit?: InputMaybe<Unit>;
};


export type BuildingUsageByEnergySourceArgs = {
  granularity?: InputMaybe<Granularity>;
  range?: InputMaybe<TimeRangeInput>;
};


export type BuildingUsageVsBaselineForPeriodArgs = {
  period: UsageVsBaselineReportPeriod;
  pointType: PointType;
  timezone: Scalars['String'];
  unit?: InputMaybe<Unit>;
};


export type BuildingUsageVsBaselineTotalsForPeriodArgs = {
  period: UsageVsBaselineReportPeriod;
  pointType: PointType;
  unit?: InputMaybe<Unit>;
};


export type BuildingWeatherDataArgs = {
  granularity?: InputMaybe<Granularity>;
  temperatureUnit?: InputMaybe<TemperatureUnit>;
  timeRange: TimeRangeInput;
};


export type BuildingYearlyCarbonReportingArgs = {
  commodityName: CommodityName;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type BuildingYtdCarbonArgs = {
  range: TimeRangeInput;
};

export type BuildingAnnotation = {
  __typename?: 'BuildingAnnotation';
  createdBy: User;
  createdTime: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  reasons: Array<BuildingAnnotationReason>;
  startTime: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  type: BuildingAnnotationType;
};

export enum BuildingAnnotationReason {
  CHANGES_TO_OPERATION = 'CHANGES_TO_OPERATION',
  CONSTRUCTION = 'CONSTRUCTION',
  EXTREME_WEATHER = 'EXTREME_WEATHER',
  HOLIDAY = 'HOLIDAY',
  OCCUPANCY = 'OCCUPANCY',
  OTHER = 'OTHER',
  PRE_HEATING_COOLING = 'PRE_HEATING_COOLING',
  TENANT_REQUEST = 'TENANT_REQUEST',
  VENTILATION = 'VENTILATION'
}

export enum BuildingAnnotationType {
  CUSTOM = 'CUSTOM',
  HIGH_USAGE = 'HIGH_USAGE',
  PEAK_DEMAND = 'PEAK_DEMAND'
}

export type BuildingEquipmentGroup = {
  __typename?: 'BuildingEquipmentGroup';
  building: Building;
  equipment?: Maybe<Array<Equipment>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BuildingEquipmentGroupResponse = {
  __typename?: 'BuildingEquipmentGroupResponse';
  buildingEquipmentGroup?: Maybe<BuildingEquipmentGroup>;
};

export type BuildingFacet = {
  __typename?: 'BuildingFacet';
  building: Building;
  measures: Scalars['Int'];
};

export type BuildingFeatureEnablementInput = {
  buildingId: Scalars['String'];
  enabled: Scalars['Boolean'];
  featureName: FeatureType;
};

export type BuildingFeatureEnablementPayload = {
  __typename?: 'BuildingFeatureEnablementPayload';
  building?: Maybe<Building>;
};

export type BuildingOccupancyInput = {
  buildingId: Scalars['String'];
  occupancy: Array<OccupancyInput>;
  organizationId: Scalars['String'];
  year: Scalars['Int'];
};

export type BuildingOperatingSchedule = {
  __typename?: 'BuildingOperatingSchedule';
  applicability?: Maybe<TimeRange>;
  buildingId: Scalars['String'];
  exceptionOperatingPeriods?: Maybe<Array<Maybe<ExceptionOperatingTimeBlock>>>;
  exceptionType?: Maybe<ExceptionType>;
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  operatingPeriods: Array<OperatingPeriod>;
  reasons?: Maybe<Array<Maybe<BuildingAnnotationReason>>>;
  title?: Maybe<Scalars['String']>;
  type: ScheduleType;
};

export type BuildingOperatingScheduleResponse = {
  __typename?: 'BuildingOperatingScheduleResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  operatingSchedule?: Maybe<BuildingOperatingSchedule>;
  success: Scalars['Boolean'];
};

export type BuildingOrganizationRelationship = {
  __typename?: 'BuildingOrganizationRelationship';
  building: Building;
  id: Scalars['String'];
  organization: Organization;
  relationshipType?: Maybe<BuildingOrganizationRelationshipType>;
};

export enum BuildingOrganizationRelationshipType {
  OPERATOR = 'OPERATOR',
  OWNER = 'OWNER'
}

export type BuildingSpaceGroup = {
  __typename?: 'BuildingSpaceGroup';
  buildingId: Scalars['String'];
  spaceGroupId: Scalars['String'];
};

export type BuildingThreshold = {
  __typename?: 'BuildingThreshold';
  type: PointType;
  value: Scalars['Int'];
};

export type BuildingUpdateInput = {
  customerBuildingId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  includeInGroupAggregate?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<LocationInput>;
  name?: InputMaybe<Scalars['String']>;
  sqft?: InputMaybe<Scalars['Float']>;
};

export type BuildingUpdatePayload = {
  __typename?: 'BuildingUpdatePayload';
  building?: Maybe<Building>;
  code: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export enum BypassCommandBinaryUnits {
  BINARY = 'BINARY'
}

export enum BypassPositionCommandUnits {
  PERCENT = 'PERCENT'
}

export enum BypassPositionUnits {
  PERCENT = 'PERCENT'
}

export enum BypassStatusBinaryUnits {
  BINARY = 'BINARY'
}

export type CalendarYearBudgetedCommodity = {
  __typename?: 'CalendarYearBudgetedCommodity';
  budgetedYears: Array<Scalars['Int']>;
  commodity: CommodityName;
};

export type CalendarYearCommodityBudget = {
  __typename?: 'CalendarYearCommodityBudget';
  apr: BudgetedAmount;
  aug: BudgetedAmount;
  commodity: CommodityName;
  dec: BudgetedAmount;
  feb: BudgetedAmount;
  id: Scalars['ID'];
  jan: BudgetedAmount;
  jul: BudgetedAmount;
  jun: BudgetedAmount;
  mar: BudgetedAmount;
  may: BudgetedAmount;
  nov: BudgetedAmount;
  oct: BudgetedAmount;
  sep: BudgetedAmount;
  year: Scalars['Int'];
  yearTotalAmount: BudgetedAmount;
};

export type CancelScheduledCurtailmentEventResponse = {
  __typename?: 'CancelScheduledCurtailmentEventResponse';
  eventId: Scalars['String'];
};

export type CarbonEmissions = {
  __typename?: 'CarbonEmissions';
  availableGranularities: Array<Granularity>;
  carbonEmissionUnit: Scalars['String'];
  carbonIntensityUnit: Scalars['String'];
  granularity: Granularity;
  regionCode: Scalars['String'];
  values: Array<Maybe<CarbonMeasurement>>;
};

export type CarbonFreeEnergy = {
  __typename?: 'CarbonFreeEnergy';
  carbonFreeEnergyPercent: Scalars['Float'];
  unit: Unit;
};

export type CarbonMeasurement = {
  __typename?: 'CarbonMeasurement';
  carbonEmissions?: Maybe<Scalars['Float']>;
  carbonIntensity?: Maybe<Scalars['Float']>;
  timestamp: Scalars['DateTime'];
};

export enum CarbonMonoxideSetpointUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export enum CarbonMonoxideUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export type CarbonReport = {
  __typename?: 'CarbonReport';
  billingDays: Scalars['Int'];
  carbonEmission: Scalars['Float'];
  carbonEmissionUnit: Unit;
  carbonIntensity: Scalars['Float'];
  cost: Scalars['Float'];
  intensityUnit: Unit;
  mostSignificantBillPeriod: Period;
  unit: Unit;
  usage: Scalars['Float'];
};

export type CarbonReportingResponse = {
  __typename?: 'CarbonReportingResponse';
  commodityName: Scalars['String'];
  yearlyReporting: Array<Maybe<YearlyReporting>>;
};

export enum ChilledWaterUsageUnits {
  BRITISH_THERMAL_UNITS = 'BRITISH_THERMAL_UNITS',
  TON_HOURS = 'TON_HOURS'
}

export enum ChillerEfficiencyUnits {
  PERCENT = 'PERCENT'
}

export enum ChillerLoadUnits {
  PERCENT = 'PERCENT'
}

export type CloseMeasureInput = {
  comment?: InputMaybe<Scalars['String']>;
  measureId: Scalars['ID'];
  reason?: InputMaybe<CloseMeasureReason>;
  resolution: ClosedResolution;
};

export enum CloseMeasureReason {
  ADJUSTED_SEQUENCES = 'ADJUSTED_SEQUENCES',
  BUDGET_CONSTRAINTS = 'BUDGET_CONSTRAINTS',
  INACCURATE_SCHEDULE = 'INACCURATE_SCHEDULE',
  OPTIMAL_START = 'OPTIMAL_START',
  OTHER = 'OTHER',
  REPAIRED_EQUIPMENT = 'REPAIRED_EQUIPMENT',
  TENANT_REQUEST = 'TENANT_REQUEST',
  UPDATED_BAS_SCHEDULES = 'UPDATED_BAS_SCHEDULES',
  WEATHER_RELATED_OPERATIONS = 'WEATHER_RELATED_OPERATIONS'
}

export type CloseMeasureResponse = {
  __typename?: 'CloseMeasureResponse';
  measure: Measure;
};

export type CloseStatusActivityDetails = {
  __typename?: 'CloseStatusActivityDetails';
  comment?: Maybe<Scalars['String']>;
  reason?: Maybe<CloseMeasureReason>;
  resolution: ClosedResolution;
};

export type ClosedActivity = Activity & {
  __typename?: 'ClosedActivity';
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export enum ClosedResolution {
  IMPLEMENTED = 'IMPLEMENTED',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED'
}

export enum CombustionAirPressureUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum CombustionTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum CommandBinaryUnits {
  BINARY = 'BINARY'
}

export type CommentActivity = Activity & {
  __typename?: 'CommentActivity';
  comment: Scalars['String'];
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export type Commodity = {
  __typename?: 'Commodity';
  type: Scalars['String'];
  unit: Scalars['String'];
  years?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type CommodityBilling = {
  __typename?: 'CommodityBilling';
  commodityName: CommodityName;
  yearlyBilling?: Maybe<Array<Maybe<YearBilling>>>;
};


export type CommodityBillingYearlyBillingArgs = {
  years?: InputMaybe<Array<Scalars['Int']>>;
};

export type CommodityBudgetAmountInput = {
  cost: Scalars['CurrencyValue'];
  usage: Scalars['Float'];
};

export enum CommodityName {
  CHILLED_WATER = 'CHILLED_WATER',
  DIESEL = 'DIESEL',
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  HEATING_OIL = 'HEATING_OIL',
  HOT_WATER = 'HOT_WATER',
  HUMIDITY = 'HUMIDITY',
  IRRIGATION = 'IRRIGATION',
  LIGHTING = 'LIGHTING',
  PROPANE = 'PROPANE',
  SEWER = 'SEWER',
  STEAM = 'STEAM',
  TEMPERATURE = 'TEMPERATURE',
  WASTE = 'WASTE',
  WATER = 'WATER'
}

export type CommodityUsageAmount = {
  __typename?: 'CommodityUsageAmount';
  unit: Unit;
  value: Scalars['Float'];
};

export enum CondenserTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type ConfigureEnelIntegrationInput = {
  enablementConfiguration: EnelIntegrationConfigInput;
  organizationId: Scalars['String'];
};

export type ConfigureEnelIntegrationResponse = {
  __typename?: 'ConfigureEnelIntegrationResponse';
  enablement?: Maybe<Enablement>;
};

export type ConfigureGobyIntegrationInput = {
  enablementConfiguration: GobyIntegrationConfigInput;
  organizationId: Scalars['String'];
};

export type ConfigureGobyIntegrationResponse = {
  __typename?: 'ConfigureGobyIntegrationResponse';
  enablement?: Maybe<Enablement>;
};

export type ConfigureSourcesInput = {
  enablementConfiguration: SourcesInput;
  organizationId: Scalars['String'];
};

export type ConfigureSourcesResponse = {
  __typename?: 'ConfigureSourcesResponse';
  enablement?: Maybe<Enablement>;
};

export type ConsolidatedBillDeleteInput = {
  consolidatedBillId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type ConsolidatedBillInput = {
  bills: Array<BillInput>;
  consolidatedBillNumber?: InputMaybe<Scalars['String']>;
  issueDate: Scalars['String'];
  organizationId: Scalars['String'];
  providerName: Scalars['String'];
  serviceAccountId: Scalars['String'];
  serviceAccountNumber?: InputMaybe<Scalars['String']>;
};

export type ConsolidatedBillResponse = {
  __typename?: 'ConsolidatedBillResponse';
  attachments: Array<BillAttachment>;
  bills: Array<BillResponse>;
  consolidatedBillId: Scalars['String'];
  consolidatedBillNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  enteredBy: User;
  issueDate: Scalars['String'];
  organizationId: Scalars['String'];
  providerName: Scalars['String'];
  serviceAccountId: Scalars['String'];
  serviceAccountNumber?: Maybe<Scalars['String']>;
};

export type ConsolidatedBillUpdateInput = {
  bills: Array<BillInput>;
  consolidatedBillId?: InputMaybe<Scalars['String']>;
  consolidatedBillNumber?: InputMaybe<Scalars['String']>;
  issueDate: Scalars['String'];
  organizationId: Scalars['String'];
  providerName: Scalars['String'];
  serviceAccountId: Scalars['String'];
  serviceAccountNumber?: InputMaybe<Scalars['String']>;
};

export type ConvertConsolidatedAccountToDeliveryAccountInput = {
  deliveryServiceAccount: ElectricityServiceAccountInput;
  serviceAccountId: Scalars['ID'];
};

export type ConvertConsolidatedAccountToSeperateAccountsInput = {
  deliveryServiceAccount: ElectricityServiceAccountInput;
  serviceAccountId: Scalars['ID'];
  supplyServiceAccount: ElectricityServiceAccountInput;
};

export type ConvertConsolidatedAccountToSeperateAccountsResponse = {
  __typename?: 'ConvertConsolidatedAccountToSeperateAccountsResponse';
  deliveryServiceAccount: ServiceAccount;
  supplyServiceAccount: ServiceAccount;
};

export type ConvertConsolidatedAccountToSupplyAccountInput = {
  serviceAccountId: Scalars['ID'];
  supplyServiceAccount: ElectricityServiceAccountInput;
};

export enum CooldownBinaryUnits {
  BINARY = 'BINARY'
}

export enum CoolingCapacityUnits {
  PERCENT = 'PERCENT'
}

export enum CoolingPidLoopOutputUnits {
  PERCENT = 'PERCENT'
}

export enum CoolingPidOutputUnits {
  PERCENT = 'PERCENT'
}

export type CreateAlertInput = {
  asset: AlertAssetInput;
  isDuringOccupiedHours?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  pointType: PointType;
  threshold: ThresholdInput;
  unitOfMeasurement: Scalars['String'];
  userId: Scalars['String'];
};

export type CreateBuildingAnnotationInput = {
  buildingId: Scalars['String'];
  endTime: Scalars['DateTime'];
  notes?: InputMaybe<Scalars['String']>;
  reasons: Array<BuildingAnnotationReason>;
  startTime: Scalars['DateTime'];
  title?: InputMaybe<Scalars['String']>;
  type: BuildingAnnotationType;
};

export type CreateBuildingAnnotationResponse = {
  __typename?: 'CreateBuildingAnnotationResponse';
  annotation?: Maybe<BuildingAnnotation>;
};

export type CreateClosedMeasureInput = {
  measureToCreate: CreateMeasureInput;
  reason: CloseMeasureReason;
};

export type CreateMeasureInput = {
  avoidableCost?: InputMaybe<AvoidableCostInput>;
  building: Scalars['ID'];
  created?: InputMaybe<Scalars['DateTime']>;
  found: Scalars['DateTime'];
  implemented?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  observation: Scalars['String'];
  owner?: InputMaybe<Scalars['ID']>;
  recommendation: Scalars['String'];
  staticVisualization?: InputMaybe<Scalars['Upload']>;
  status: MeasureStatus;
};

export type CreatedActivity = Activity & {
  __typename?: 'CreatedActivity';
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export type CreatedByFacet = {
  __typename?: 'CreatedByFacet';
  /** createdBy may be null (unknown) */
  createdBy?: Maybe<User>;
  measures: Scalars['Int'];
};

export enum CumulativeMaxValue {
  ONE_HUNDRED_THOUSAND = 'ONE_HUNDRED_THOUSAND',
  ONE_MILLION = 'ONE_MILLION',
  TEN_THOUSAND = 'TEN_THOUSAND',
  UNSIGNED_INT_32 = 'UNSIGNED_INT_32'
}

export enum Currency {
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD'
}

export type CurrencyAmount = {
  __typename?: 'CurrencyAmount';
  currency: Currency;
  valuationDate: Scalars['DateTime'];
  value: Scalars['CurrencyValue'];
};

export type CurrencyAmountInput = {
  currency?: InputMaybe<Currency>;
  value?: InputMaybe<Scalars['CurrencyValue']>;
};

export type CurtailmentEvent = {
  __typename?: 'CurtailmentEvent';
  controlPointIds: Array<Scalars['String']>;
  controlPointsCurtailed: Array<Scalars['String']>;
  controlPointsRestored: Array<Scalars['String']>;
  eventStatus: CurtailmentEventStatusType;
  eventType: CurtailmentEventType;
  id: Scalars['String'];
  minuteDuration: Scalars['Int'];
  startTime: Scalars['DateTime'];
};

export enum CurtailmentEventStatusType {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  FAILED_TO_RESTORE = 'FAILED_TO_RESTORE',
  RESTORING = 'RESTORING',
  SCHEDULED = 'SCHEDULED'
}

export enum CurtailmentEventType {
  OTHER = 'OTHER',
  SYSTEM_PEAK_DEMAND = 'SYSTEM_PEAK_DEMAND'
}

export type DailyOperatingTimeBlock = {
  __typename?: 'DailyOperatingTimeBlock';
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type DataCompleteness = {
  __typename?: 'DataCompleteness';
  commodityName: CommodityName;
  value: Scalars['Float'];
};

export enum DataStatus {
  INVALID_COMMODITY = 'INVALID_COMMODITY',
  INVALID_MODEL = 'INVALID_MODEL',
  OK = 'OK'
}

export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY'
}

export type DeactivateUserInput = {
  userId: Scalars['String'];
};

export type DeactivateUserPayload = {
  __typename?: 'DeactivateUserPayload';
  userId: Scalars['String'];
  userStatus: UserStatus;
};

export type DegreeDayData = {
  __typename?: 'DegreeDayData';
  coolingDegreeDayData: Array<Maybe<Measurement>>;
  heatingDegreeDayData: Array<Maybe<Measurement>>;
  temperatureUnit: TemperatureUnit;
  timeRange: TimeRange;
  totalCDDElectricityUsage: AggregateData;
  totalCoolingDegreeDays: Scalars['Float'];
  totalHDDElectricityUsage: AggregateData;
  totalHeatingDegreeDays: Scalars['Float'];
};

export type DeleteAlertResponse = {
  __typename?: 'DeleteAlertResponse';
  id: Scalars['String'];
};

export type DeleteAlertsByAssetIdResponse = {
  __typename?: 'DeleteAlertsByAssetIdResponse';
  assetId: Scalars['String'];
};

export type DeleteBillAttachmentResponse = {
  __typename?: 'DeleteBillAttachmentResponse';
  attachmentId?: Maybe<Scalars['ID']>;
};

export type DeleteBuildingAnnotationResponse = {
  __typename?: 'DeleteBuildingAnnotationResponse';
  id: Scalars['String'];
};

export type DeleteConsolidatedBillResponse = {
  __typename?: 'DeleteConsolidatedBillResponse';
  consolidatedBillId: Scalars['String'];
};

export type DeleteMeasureActivityResponse = {
  __typename?: 'DeleteMeasureActivityResponse';
  success: Scalars['Boolean'];
};

export type DeleteScheduleExceptionResponse = {
  __typename?: 'DeleteScheduleExceptionResponse';
  id?: Maybe<Scalars['String']>;
};

export type DeleteVarianceNoteInput = {
  buildingId: Scalars['String'];
  commodityName: CommodityName;
  comparisonYear: Scalars['Int'];
  month: Month;
  year: Scalars['Int'];
};

export type DeleteVarianceNoteResponse = {
  __typename?: 'DeleteVarianceNoteResponse';
  buildingId: Scalars['String'];
  commodityName: CommodityName;
  comparisonYear: Scalars['Int'];
  enteredBy: User;
  month: Month;
  year: Scalars['Int'];
};

export type Diagnostics = {
  __typename?: 'Diagnostics';
  lastReceiveTime?: Maybe<Scalars['DateTime']>;
};

export enum DifferentialPressureSetpointUnits {
  BARS = 'BARS',
  POUNDS_PER_SQUARE_INCH = 'POUNDS_PER_SQUARE_INCH'
}

export enum DifferentialPressureUnits {
  BARS = 'BARS',
  POUNDS_PER_SQUARE_INCH = 'POUNDS_PER_SQUARE_INCH'
}

export type DisableFeatureForOrganizationInput = {
  featureType: EnablementType;
  organizationId: Scalars['String'];
};

export type DisableFeatureForOrganizationResponse = {
  __typename?: 'DisableFeatureForOrganizationResponse';
  enablement?: Maybe<Enablement>;
};

export enum DischargeAirFlowRateCoolingModeMaxSetpointUnits {
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum DischargeAirFlowRateCoolingModeMinSetpointUnits {
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum DischargeAirFlowRateMaxSetpointUnits {
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum DischargeAirFlowRateMinSetpointUnits {
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum DischargeAirTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum DischargeAirTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type EarlyStartEventData = EventData & {
  __typename?: 'EarlyStartEventData';
  actualEnergies: Array<Maybe<Measurement>>;
  actualRampUpStartTime?: Maybe<Scalars['DateTime']>;
  blendedRate: BlendedRates;
  detectedInPeriod: TimeRange;
  emissionsConversionRate: EmissionsConversionRate;
  nonOperatingTimeRanges: Array<TimeRange>;
  occupancyStartTime?: Maybe<Scalars['DateTime']>;
  optimumEnergies: Array<Measurement>;
  optimumRampUpStartTime?: Maybe<Scalars['DateTime']>;
  persistDays: Scalars['Float'];
};

export enum EconomizerMinimumLockoutTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum EconomizerPidOutputUnits {
  PERCENT = 'PERCENT'
}

export enum ElectricCurrentUnits {
  AMPERES = 'AMPERES'
}

export type ElectricityAmount = {
  __typename?: 'ElectricityAmount';
  kwh?: Maybe<Scalars['Float']>;
};

export enum ElectricityDemandUnits {
  KILOWATTS = 'KILOWATTS'
}

export type ElectricityServiceAccountInput = {
  accountNumber: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  utilityProviderName: Scalars['String'];
};

export type ElectricityServiceAccountPairCreationResponse = {
  __typename?: 'ElectricityServiceAccountPairCreationResponse';
  serviceAccounts: Array<Maybe<ServiceAccount>>;
};

export enum ElectricityUsageUnits {
  KILOWATT_HOURS = 'KILOWATT_HOURS'
}

export type EmissionsAmount = {
  __typename?: 'EmissionsAmount';
  gramsCo2e?: Maybe<Scalars['Float']>;
};

export type EmissionsConversionRate = {
  __typename?: 'EmissionsConversionRate';
  gramsPerKwh?: Maybe<Scalars['Float']>;
};

export type EnableFeatureForOrganizationInput = {
  featureType: EnablementType;
  organizationId: Scalars['String'];
};

export type EnableFeatureForOrganizationResponse = {
  __typename?: 'EnableFeatureForOrganizationResponse';
  enablement?: Maybe<Enablement>;
};

export type EnableSourcesForBuildingResponse = {
  __typename?: 'EnableSourcesForBuildingResponse';
  sourcesReady: Scalars['Boolean'];
};

export type EnableSourcesInput = {
  buildingId: Scalars['ID'];
};

export type Enablement = {
  __typename?: 'Enablement';
  configuration: EnablementConfiguration;
  enabled: Scalars['Boolean'];
  type: EnablementType;
};

export type EnablementConfiguration = EnelIntegration | GobyIntegration | Sources;

export enum EnablementType {
  ENEL = 'ENEL',
  GOBY = 'GOBY',
  SOURCES = 'SOURCES'
}

export type EnelIntegration = {
  __typename?: 'EnelIntegration';
  url: Scalars['String'];
};

export type EnelIntegrationConfigInput = {
  url: Scalars['String'];
};

export type EnergySourceUsage = {
  __typename?: 'EnergySourceUsage';
  batteries?: Maybe<Scalars['Float']>;
  biogas?: Maybe<Scalars['Float']>;
  biomass?: Maybe<Scalars['Float']>;
  coal?: Maybe<Scalars['Float']>;
  dualFuel?: Maybe<Scalars['Float']>;
  fossilAndBiomass?: Maybe<Scalars['Float']>;
  fossilFuel?: Maybe<Scalars['Float']>;
  geothermal?: Maybe<Scalars['Float']>;
  hydro?: Maybe<Scalars['Float']>;
  landfillGas?: Maybe<Scalars['Float']>;
  multiFuel?: Maybe<Scalars['Float']>;
  naturalGas?: Maybe<Scalars['Float']>;
  nuclear?: Maybe<Scalars['Float']>;
  oil?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
  otherFossil?: Maybe<Scalars['Float']>;
  otherRenewables?: Maybe<Scalars['Float']>;
  petroleum?: Maybe<Scalars['Float']>;
  refuse?: Maybe<Scalars['Float']>;
  solar?: Maybe<Scalars['Float']>;
  storage?: Maybe<Scalars['Float']>;
  timestamp: Scalars['DateTime'];
  waste?: Maybe<Scalars['Float']>;
  wind?: Maybe<Scalars['Float']>;
  wood?: Maybe<Scalars['Float']>;
};

export enum EnteringTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum EnteringWaterTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type EnterpriseUserRegistrationInput = {
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  locale?: InputMaybe<Locale>;
  organizationId: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Role>>>;
  sendWelcomeEmail?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};

export type EnterpriseUserRegistrationResponse = {
  __typename?: 'EnterpriseUserRegistrationResponse';
  user?: Maybe<User>;
};

export type Equipment = {
  __typename?: 'Equipment';
  buildings?: Maybe<Array<Maybe<Building>>>;
  childEquipment?: Maybe<Array<Maybe<Equipment>>>;
  customerId?: Maybe<Scalars['String']>;
  equipmentType: EquipmentType;
  id: Scalars['String'];
  name: Scalars['String'];
  parentEquipmentId?: Maybe<Scalars['String']>;
  points?: Maybe<Array<Maybe<EquipmentPoint>>>;
};

export type EquipmentPoint = {
  __typename?: 'EquipmentPoint';
  customerPointId?: Maybe<Scalars['String']>;
  diagnostics?: Maybe<PointDiagnostics>;
  equipmentId: Scalars['String'];
  id: Scalars['String'];
  ingestPointId?: Maybe<Scalars['String']>;
  /** @deprecated This will be replaced in favor of the lastReceiveTime in the point diagnostics type */
  lastReceiveTime?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  pointData: IntervalData;
  pointType: PointType;
};


export type EquipmentPointPointDataArgs = {
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  unitPreferences?: InputMaybe<PreferredUnitsInput>;
};

export enum EquipmentType {
  AHU = 'AHU',
  AHU_CUSTOM = 'AHU_CUSTOM',
  AHU_DUAL_DUCT = 'AHU_DUAL_DUCT',
  AHU_INDUCTION = 'AHU_INDUCTION',
  AHU_LABORATORY = 'AHU_LABORATORY',
  AHU_MULTI_DUCT_REHEAT = 'AHU_MULTI_DUCT_REHEAT',
  AHU_MULTI_DUCT_SINGLE_ZONE = 'AHU_MULTI_DUCT_SINGLE_ZONE',
  AHU_MULTI_ZONE = 'AHU_MULTI_ZONE',
  AHU_REHEAT = 'AHU_REHEAT',
  AHU_SINGLE_ZONE = 'AHU_SINGLE_ZONE',
  AIRFLOW_DAMPER = 'AIRFLOW_DAMPER',
  AIR_HANDLER_ZONE = 'AIR_HANDLER_ZONE',
  BACKUP_LOOP_PUMP = 'BACKUP_LOOP_PUMP',
  BASEBOARD_RADIATOR = 'BASEBOARD_RADIATOR',
  BASIC_VENTILATION_SYSTEM = 'BASIC_VENTILATION_SYSTEM',
  BLOWER = 'BLOWER',
  BLOW_DOWN = 'BLOW_DOWN',
  BUILDING_EXHAUST_FAN = 'BUILDING_EXHAUST_FAN',
  BYPASS_DAMPER = 'BYPASS_DAMPER',
  BYPASS_VALVE = 'BYPASS_VALVE',
  CHILLED_WATER_BYPASS = 'CHILLED_WATER_BYPASS',
  CHILLED_WATER_LOOP = 'CHILLED_WATER_LOOP',
  CHILLED_WATER_PUMP_DEDICATED = 'CHILLED_WATER_PUMP_DEDICATED',
  CHILLED_WATER_PUMP_HEADERED = 'CHILLED_WATER_PUMP_HEADERED',
  CHILLED_WATER_SIDE = 'CHILLED_WATER_SIDE',
  CHILLER = 'CHILLER',
  CHILLER_COMMON = 'CHILLER_COMMON',
  CHILLER_PLANT_PARALLEL = 'CHILLER_PLANT_PARALLEL',
  CHILLER_PLANT_SERIES = 'CHILLER_PLANT_SERIES',
  CIRCULATION_PUMP = 'CIRCULATION_PUMP',
  COLD_DECK = 'COLD_DECK',
  COLD_DUCT_AIRFLOW_DAMPER = 'COLD_DUCT_AIRFLOW_DAMPER',
  COLD_DUCT_SUPPLY_AIR_ISOLATION_DAMPER = 'COLD_DUCT_SUPPLY_AIR_ISOLATION_DAMPER',
  COLD_DUCT_SUPPLY_FAN = 'COLD_DUCT_SUPPLY_FAN',
  COMPRESSOR = 'COMPRESSOR',
  CONDENSATE_RETURN_PUMP = 'CONDENSATE_RETURN_PUMP',
  CONDENSATE_TANK = 'CONDENSATE_TANK',
  CONDENSATE_VACUUM_PUMP = 'CONDENSATE_VACUUM_PUMP',
  CONDENSER = 'CONDENSER',
  CONDENSER_SIDE = 'CONDENSER_SIDE',
  CONDENSER_WATER_BYPASS = 'CONDENSER_WATER_BYPASS',
  CONDENSER_WATER_LOOP = 'CONDENSER_WATER_LOOP',
  CONDENSER_WATER_PUMP = 'CONDENSER_WATER_PUMP',
  COOLING_COIL = 'COOLING_COIL',
  COOLING_COIL_CHW = 'COOLING_COIL_CHW',
  COOLING_COIL_DX = 'COOLING_COIL_DX',
  COOLING_STAGE = 'COOLING_STAGE',
  COOLING_TOWER = 'COOLING_TOWER',
  COOLING_TOWER_COMMON = 'COOLING_TOWER_COMMON',
  CRITICAL_CHILLED_WATER_LOOP = 'CRITICAL_CHILLED_WATER_LOOP',
  DAMPER = 'DAMPER',
  DEAERATOR_SURGE_TANK_LOOP = 'DEAERATOR_SURGE_TANK_LOOP',
  DEAERATOR_SYSTEM = 'DEAERATOR_SYSTEM',
  DEAERATOR_TANK = 'DEAERATOR_TANK',
  DEDICATED_PUMP = 'DEDICATED_PUMP',
  DOMESTIC_WATER_LOOP = 'DOMESTIC_WATER_LOOP',
  DOMESTIC_WATER_SIDE = 'DOMESTIC_WATER_SIDE',
  DOMESTIC_WATER_SYSTEM = 'DOMESTIC_WATER_SYSTEM',
  ECONOMIZER = 'ECONOMIZER',
  ENTERING_SIDE = 'ENTERING_SIDE',
  ENTERING_SIDE_VALVE = 'ENTERING_SIDE_VALVE',
  EVAPORATOR = 'EVAPORATOR',
  EVAPORATOR_SIDE = 'EVAPORATOR_SIDE',
  EXHAUST_AIR_DAMPER = 'EXHAUST_AIR_DAMPER',
  EXHAUST_DAMPER = 'EXHAUST_DAMPER',
  EXHAUST_FAN = 'EXHAUST_FAN',
  EXHAUST_PLENUM = 'EXHAUST_PLENUM',
  EXHAUST_SIDE = 'EXHAUST_SIDE',
  FACE_DAMPER = 'FACE_DAMPER',
  FAN_COIL_UNIT = 'FAN_COIL_UNIT',
  FAN_WALL = 'FAN_WALL',
  FEEDWATER = 'FEEDWATER',
  FEEDWATER_LOOP = 'FEEDWATER_LOOP',
  FEEDWATER_PUMP = 'FEEDWATER_PUMP',
  FEED_WATER_LOOP = 'FEED_WATER_LOOP',
  FLOW_SWITCH = 'FLOW_SWITCH',
  FUME_HOOD = 'FUME_HOOD',
  GENERAL_EXHAUST = 'GENERAL_EXHAUST',
  GLYCOL_LOOP = 'GLYCOL_LOOP',
  GLYCOL_SIDE = 'GLYCOL_SIDE',
  HEADERED_PUMP = 'HEADERED_PUMP',
  HEATING_COIL_ELECTRIC = 'HEATING_COIL_ELECTRIC',
  HEATING_COIL_GAS = 'HEATING_COIL_GAS',
  HEATING_COIL_HOT_WATER = 'HEATING_COIL_HOT_WATER',
  HEATING_COIL_STEAM = 'HEATING_COIL_STEAM',
  HEATING_STAGE = 'HEATING_STAGE',
  HEAT_EXCHANGER = 'HEAT_EXCHANGER',
  HEAT_EXCHANGER_COMMON = 'HEAT_EXCHANGER_COMMON',
  HEAT_PUMP = 'HEAT_PUMP',
  HEAT_RECOVERY_COIL_EXHAUST = 'HEAT_RECOVERY_COIL_EXHAUST',
  HEAT_RECOVERY_COIL_SUPPLY = 'HEAT_RECOVERY_COIL_SUPPLY',
  HI_TEMP_SIDE = 'HI_TEMP_SIDE',
  HOT_DECK = 'HOT_DECK',
  HOT_DUCT_AIRFLOW_DAMPER = 'HOT_DUCT_AIRFLOW_DAMPER',
  HOT_DUCT_SUPPLY_AIR_ISOLATION_DAMPER = 'HOT_DUCT_SUPPLY_AIR_ISOLATION_DAMPER',
  HOT_DUCT_SUPPLY_FAN = 'HOT_DUCT_SUPPLY_FAN',
  HOT_WATER_BOILER = 'HOT_WATER_BOILER',
  HOT_WATER_BOILER_PLANT = 'HOT_WATER_BOILER_PLANT',
  HOT_WATER_BYPASS = 'HOT_WATER_BYPASS',
  HOT_WATER_BYPASS_LOOP = 'HOT_WATER_BYPASS_LOOP',
  HOT_WATER_LOOP = 'HOT_WATER_LOOP',
  HOT_WATER_PUMP_DEDICATED = 'HOT_WATER_PUMP_DEDICATED',
  HOT_WATER_PUMP_HEADERED = 'HOT_WATER_PUMP_HEADERED',
  HOT_WATER_SIDE = 'HOT_WATER_SIDE',
  HOT_WATER_SYSTEM = 'HOT_WATER_SYSTEM',
  HUMIDIFIER = 'HUMIDIFIER',
  HX_HIGH_TEMP_LOOP = 'HX_HIGH_TEMP_LOOP',
  INJECTION_VALVE = 'INJECTION_VALVE',
  IN_DUCT_CHANGE_OVER_COIL = 'IN_DUCT_CHANGE_OVER_COIL',
  IN_DUCT_COOLING_COIL = 'IN_DUCT_COOLING_COIL',
  IN_DUCT_HEATING_COIL = 'IN_DUCT_HEATING_COIL',
  LAB_SUPPLY = 'LAB_SUPPLY',
  LAB_ZONE = 'LAB_ZONE',
  LEAVING_SIDE = 'LEAVING_SIDE',
  LEAVING_SIDE_VALVE = 'LEAVING_SIDE_VALVE',
  LOOP = 'LOOP',
  LOOP_SIDE = 'LOOP_SIDE',
  LO_TEMP_SIDE = 'LO_TEMP_SIDE',
  MINIMUM_OUTSIDE_AIR_DAMPER = 'MINIMUM_OUTSIDE_AIR_DAMPER',
  MIXED_AIR_DAMPER = 'MIXED_AIR_DAMPER',
  MIXING_PLENUM = 'MIXING_PLENUM',
  NEUTRAL_DECK = 'NEUTRAL_DECK',
  OUTSIDE_AIR_DAMPER = 'OUTSIDE_AIR_DAMPER',
  PHASE = 'PHASE',
  PLANT_SIDE = 'PLANT_SIDE',
  PREHEAT_COIL = 'PREHEAT_COIL',
  PREHEAT_COIL_ELECTRIC = 'PREHEAT_COIL_ELECTRIC',
  PREHEAT_COIL_HHW = 'PREHEAT_COIL_HHW',
  PRIMARY_CHILLED_WATER_LOOP = 'PRIMARY_CHILLED_WATER_LOOP',
  PRIMARY_HOT_WATER_LOOP = 'PRIMARY_HOT_WATER_LOOP',
  PRIMARY_LOOP = 'PRIMARY_LOOP',
  PRIMARY_LOOP_PUMP = 'PRIMARY_LOOP_PUMP',
  PRIMARY_STEAM_LOOP = 'PRIMARY_STEAM_LOOP',
  PUMP = 'PUMP',
  RADIANT_FLOOR_SYSTEM = 'RADIANT_FLOOR_SYSTEM',
  RADIANT_LOOP = 'RADIANT_LOOP',
  REHEAT_COIL = 'REHEAT_COIL',
  REHEAT_COIL_ELECTRIC = 'REHEAT_COIL_ELECTRIC',
  REHEAT_COIL_GAS = 'REHEAT_COIL_GAS',
  REHEAT_COIL_HHW = 'REHEAT_COIL_HHW',
  RETURN_AIR_DAMPER = 'RETURN_AIR_DAMPER',
  RETURN_AIR_DUCT = 'RETURN_AIR_DUCT',
  RETURN_AIR_ISOLATION_DAMPER = 'RETURN_AIR_ISOLATION_DAMPER',
  RETURN_FAN = 'RETURN_FAN',
  S2_WEIS_RELAY_CONTROL_POINT = 'S2_WEIS_RELAY_CONTROL_POINT',
  SECONDARY_CHILLED_WATER_LOOP = 'SECONDARY_CHILLED_WATER_LOOP',
  SECONDARY_HOT_WATER_LOOP = 'SECONDARY_HOT_WATER_LOOP',
  SECONDARY_LOOP = 'SECONDARY_LOOP',
  SECONDARY_WATER_LOOP_COMMON_PIPING = 'SECONDARY_WATER_LOOP_COMMON_PIPING',
  SECONDARY_WATER_SYSTEM = 'SECONDARY_WATER_SYSTEM',
  SMOKE_DAMPER = 'SMOKE_DAMPER',
  SPLIT_AC_SYSTEM = 'SPLIT_AC_SYSTEM',
  STACK_ECONOMIZER = 'STACK_ECONOMIZER',
  STAGE = 'STAGE',
  STAND_ALONE_CONDENSER_WATER_SYSTEM = 'STAND_ALONE_CONDENSER_WATER_SYSTEM',
  STAND_ALONE_FAN_SYSTEM = 'STAND_ALONE_FAN_SYSTEM',
  STEAM_BOILER = 'STEAM_BOILER',
  STEAM_BOILER_PLANT = 'STEAM_BOILER_PLANT',
  STEAM_SIDE = 'STEAM_SIDE',
  STEAM_SUPPLY = 'STEAM_SUPPLY',
  STORAGE_TANK = 'STORAGE_TANK',
  SUPPLY_AIR_DAMPER = 'SUPPLY_AIR_DAMPER',
  SUPPLY_AIR_DUCT = 'SUPPLY_AIR_DUCT',
  SUPPLY_AIR_ISOLATION_DAMPER = 'SUPPLY_AIR_ISOLATION_DAMPER',
  SUPPLY_DUCT = 'SUPPLY_DUCT',
  SUPPLY_FAN = 'SUPPLY_FAN',
  SUPPLY_SIDE = 'SUPPLY_SIDE',
  SURGE_TANK = 'SURGE_TANK',
  SYSTEM_BYPASS = 'SYSTEM_BYPASS',
  TANK = 'TANK',
  TANK_LOOP = 'TANK_LOOP',
  TERMINAL_UNIT_DUAL_DUCT = 'TERMINAL_UNIT_DUAL_DUCT',
  TERMINAL_UNIT_FAN = 'TERMINAL_UNIT_FAN',
  TERMINAL_UNIT_INDUCTION = 'TERMINAL_UNIT_INDUCTION',
  TERMINAL_UNIT_IN_DUCT_COOLING_COIL = 'TERMINAL_UNIT_IN_DUCT_COOLING_COIL',
  TERMINAL_UNIT_IN_DUCT_HEATING_COIL = 'TERMINAL_UNIT_IN_DUCT_HEATING_COIL',
  TERMINAL_UNIT_IN_DUCT_HEATING_COOLING_COILS = 'TERMINAL_UNIT_IN_DUCT_HEATING_COOLING_COILS',
  TERMINAL_UNIT_SINGLE_DUCT = 'TERMINAL_UNIT_SINGLE_DUCT',
  TERTIARY_CHILLED_WATER_LOOP = 'TERTIARY_CHILLED_WATER_LOOP',
  TERTIARY_HOT_WATER_LOOP = 'TERTIARY_HOT_WATER_LOOP',
  THERMAL_ENERGY_STORAGE_LOOP = 'THERMAL_ENERGY_STORAGE_LOOP',
  THERMAL_ENERGY_STORAGE_LOOP_SIDE = 'THERMAL_ENERGY_STORAGE_LOOP_SIDE',
  THERMAL_ENERGY_STORAGE_SYSTEM = 'THERMAL_ENERGY_STORAGE_SYSTEM',
  THERMAL_ENERGY_STORAGE_TANK = 'THERMAL_ENERGY_STORAGE_TANK',
  TOWER_CELL = 'TOWER_CELL',
  TRANSFER_PUMP = 'TRANSFER_PUMP',
  UNIT_FAN = 'UNIT_FAN',
  UNIT_VENTILATOR = 'UNIT_VENTILATOR',
  VALVE = 'VALVE',
  WATER_HEAT_PUMP = 'WATER_HEAT_PUMP',
  WATER_LOOP = 'WATER_LOOP',
  ZONE = 'ZONE'
}

export enum EvaporatorTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type EventData = {
  actualEnergies: Array<Maybe<Measurement>>;
  blendedRate: BlendedRates;
  detectedInPeriod: TimeRange;
  emissionsConversionRate: EmissionsConversionRate;
  nonOperatingTimeRanges: Array<TimeRange>;
  optimumEnergies: Array<Measurement>;
};

export type ExceptionOperatingPeriodInput = {
  /** Exclusive end time */
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  /** Indicates whether the building will be operating during the exception */
  isOperating: Scalars['Boolean'];
  /** Inclusive start time */
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export type ExceptionOperatingTimeBlock = {
  __typename?: 'ExceptionOperatingTimeBlock';
  endHour: Scalars['Int'];
  endMinute: Scalars['Int'];
  isOperating: Scalars['Boolean'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
};

export enum ExceptionType {
  CONSTRUCTION = 'CONSTRUCTION',
  EXTREME_WEATHER = 'EXTREME_WEATHER',
  HOLIDAY = 'HOLIDAY',
  OTHER = 'OTHER',
  PRE_HEATING_COOLING = 'PRE_HEATING_COOLING',
  TENANT_REQUEST = 'TENANT_REQUEST',
  VENTILATION = 'VENTILATION'
}

export enum ExhaustAirTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum ExteriorSpaceTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum FanSpeedSetpointUnits {
  PERCENT = 'PERCENT'
}

export type FeatureEnablement = {
  __typename?: 'FeatureEnablement';
  enabled: Scalars['Boolean'];
  name: FeatureType;
};

export enum FeatureType {
  AUTO_EQUIPMENT_MEASURES = 'AUTO_EQUIPMENT_MEASURES',
  AUTO_METER_MEASURES = 'AUTO_METER_MEASURES',
  BE_INTEGRATION = 'BE_INTEGRATION',
  DELAYED_DATA = 'DELAYED_DATA',
  HOURLY_BASELINES = 'HOURLY_BASELINES',
  PLATFORM_NO_REAL_TIME = 'PLATFORM_NO_REAL_TIME',
  READ_ONLY_UTILITY_DATA_MANAGEMENT = 'READ_ONLY_UTILITY_DATA_MANAGEMENT',
  REALTIME_CARBON = 'REALTIME_CARBON',
  SOURCES_CARBON_EMISSIONS_REPORTING = 'SOURCES_CARBON_EMISSIONS_REPORTING',
  UTILITY_DATA_MANAGEMENT = 'UTILITY_DATA_MANAGEMENT'
}

export enum FiringPercentUnits {
  PERCENT = 'PERCENT'
}

export enum FlowRateSetpointUnits {
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum FlowRateUnits {
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum FreeCoolingCommandBinaryUnits {
  BINARY = 'BINARY'
}

export enum FreeCoolingStatusBinaryUnits {
  BINARY = 'BINARY'
}

export type GasAmount = {
  __typename?: 'GasAmount';
  therms?: Maybe<Scalars['Float']>;
};

export type GeneratePathwayApiKeyInput = {
  apiKeyName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  pathwayId: Scalars['String'];
  usagePlanLevel: ApiUsagePlanLevel;
};

export type GeneratePathwayApiKeyResponse = {
  __typename?: 'GeneratePathwayApiKeyResponse';
  apiKeyValue: Scalars['String'];
};

export type GeneratedReport = {
  __typename?: 'GeneratedReport';
  asset?: Maybe<Building>;
  assets?: Maybe<Array<Maybe<Building>>>;
  id: ReportIdentifier;
  name?: Maybe<Scalars['String']>;
  pointTypes?: Maybe<Array<Maybe<PointType>>>;
};

export type GetCurtailmentEventByIdResponse = {
  __typename?: 'GetCurtailmentEventByIdResponse';
  event: CurtailmentEvent;
};

export type GetCurtailmentEventsResponse = {
  __typename?: 'GetCurtailmentEventsResponse';
  events: Array<CurtailmentEvent>;
};

export type GobyIntegration = {
  __typename?: 'GobyIntegration';
  url: Scalars['String'];
};

export type GobyIntegrationConfigInput = {
  url: Scalars['String'];
};

export enum Granularity {
  DAY = 'DAY',
  FIFTEEN_MINUTE = 'FIFTEEN_MINUTE',
  FIVE_MINUTE = 'FIVE_MINUTE',
  HOUR = 'HOUR',
  MONTH = 'MONTH'
}

export enum HeatingOilUsageUnits {
  CUBIC_METERS = 'CUBIC_METERS',
  GALLONS = 'GALLONS'
}

export enum HeatingPidLoopOutputUnits {
  PERCENT = 'PERCENT'
}

export enum HeatingPidOutputUnits {
  PERCENT = 'PERCENT'
}

export type HighBaseLoadEventData = EventData & {
  __typename?: 'HighBaseLoadEventData';
  actualEnergies: Array<Maybe<Measurement>>;
  actualRampDownStartTime?: Maybe<Scalars['DateTime']>;
  actualRampUpStartTime?: Maybe<Scalars['DateTime']>;
  baseloadEndTime?: Maybe<Scalars['DateTime']>;
  baseloadStartTime?: Maybe<Scalars['DateTime']>;
  blendedRate: BlendedRates;
  demandDelta?: Maybe<Scalars['Float']>;
  detectedInPeriod: TimeRange;
  emissionsConversionRate: EmissionsConversionRate;
  /** @deprecated Used by v1 of High Baseload Event */
  nonOccupancyEndTime?: Maybe<Scalars['DateTime']>;
  /** @deprecated Used by v1 of High Baseload Event */
  nonOccupancyStartTime?: Maybe<Scalars['DateTime']>;
  nonOperatingTimeRanges: Array<TimeRange>;
  optimumEnergies: Array<Measurement>;
  percentExcess?: Maybe<Scalars['Float']>;
  persistDays?: Maybe<Scalars['Float']>;
};

export type Holiday = {
  __typename?: 'Holiday';
  id: Scalars['String'];
  name: Scalars['String'];
  observed: Scalars['LocalDate'];
};

export enum HolidayActiveBinaryUnits {
  BINARY = 'BINARY'
}

export type Holidays = {
  __typename?: 'Holidays';
  holidays: Array<Holiday>;
  id: Scalars['String'];
};

export enum HotWaterUsageUnits {
  BRITISH_THERMAL_UNITS = 'BRITISH_THERMAL_UNITS',
  KILOJOULES = 'KILOJOULES'
}

export enum HumidityUnits {
  PERCENT = 'PERCENT'
}

export type ImportMeasureAssignedActivityInput = {
  createdBy: Scalars['ID'];
  customerMeasureActivityId?: InputMaybe<Scalars['String']>;
  measureId: Scalars['ID'];
  newOwner: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type ImportMeasureCommentActivityInput = {
  comment: Scalars['String'];
  createdBy?: InputMaybe<Scalars['ID']>;
  customerMeasureActivityId?: InputMaybe<Scalars['String']>;
  measureId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type ImportMeasureCreatedActivityInput = {
  createdBy?: InputMaybe<Scalars['ID']>;
  customerMeasureActivityId?: InputMaybe<Scalars['String']>;
  measureId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type ImportMeasureInput = {
  asset: Scalars['ID'];
  assetType: AssetType;
  avoidableCost?: InputMaybe<AvoidableCostInput>;
  /** createdByUser will only be accepted for measures of type `CUSTOMER_CREATED` */
  createdByUser?: InputMaybe<Scalars['ID']>;
  /** customerEquipmentId and temporaryEquipmentName will only be accepted for measure type `BAS_FROM_MAP` */
  customerEquipmentId?: InputMaybe<Scalars['String']>;
  customerMeasureId?: InputMaybe<Scalars['String']>;
  found: Scalars['DateTime'];
  /** If omitted, friendly IDs are generated based on incrementing the highest existing ID (per org). Must be unique per org. */
  friendlyId?: InputMaybe<Scalars['String']>;
  implemented?: InputMaybe<Scalars['DateTime']>;
  /** If omitted, name will be generated from the supplied measure type. */
  name?: InputMaybe<Scalars['String']>;
  observation: Scalars['String'];
  owner?: InputMaybe<Scalars['ID']>;
  publishStatus?: InputMaybe<MeasurePublishStatus>;
  recommendation: Scalars['String'];
  relatedBuildingId: Scalars['ID'];
  staticVisualization?: InputMaybe<Scalars['Upload']>;
  status: MeasureStatus;
  temporaryEquipmentName?: InputMaybe<Scalars['String']>;
  type: MeasureType;
  vegaVisualization?: InputMaybe<Scalars['String']>;
};

export type ImportMeasureStatusTransitionActivityInput = {
  createdBy?: InputMaybe<Scalars['ID']>;
  customerMeasureActivityId?: InputMaybe<Scalars['String']>;
  measureId: Scalars['ID'];
  status: MeasureStatus;
  timestamp: Scalars['DateTime'];
};

export type IngestPathway = {
  __typename?: 'IngestPathway';
  apiKeys: Array<PathwayApiKey>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  pathwayPointSets?: Maybe<PathwayPointSets>;
};


export type IngestPathwayPathwayPointSetsArgs = {
  input: PaginationInput;
};

export type IngestPathwayRegistrationInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type IngestPathwayRegistrationResponse = {
  __typename?: 'IngestPathwayRegistrationResponse';
  ingestPathway?: Maybe<IngestPathway>;
};

export type IngestPathways = {
  __typename?: 'IngestPathways';
  hasMorePages: Scalars['Boolean'];
  page: Array<IngestPathway>;
};

export type IngestPoint = {
  __typename?: 'IngestPoint';
  createdAt?: Maybe<Scalars['DateTime']>;
  cumulative: Scalars['Boolean'];
  cumulativeMaxValue?: Maybe<CumulativeMaxValue>;
  id: Scalars['String'];
  interval?: Maybe<Interval>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  multiplier?: Maybe<Scalars['Float']>;
  pathwayPointId?: Maybe<Scalars['String']>;
  pathwayPointSet?: Maybe<PathwayPointSet>;
  unitOfMeasurement: IngestUnitOfMeasurement;
};

export type IngestPointRegistrationInput = {
  cumulative: Scalars['Boolean'];
  cumulativeMaxValue?: InputMaybe<CumulativeMaxValue>;
  interval: Interval;
  multiplier?: InputMaybe<Scalars['Float']>;
  pathwayPointId: Scalars['String'];
  pathwayPointSetId: Scalars['String'];
  unit: IngestUnit;
  unitOverride?: InputMaybe<Scalars['String']>;
};

export type IngestPointRegistrationResponse = {
  __typename?: 'IngestPointRegistrationResponse';
  ingestPoint?: Maybe<IngestPoint>;
};

export type IngestPointUpdateInput = {
  cumulative?: InputMaybe<Scalars['Boolean']>;
  cumulativeMaxValue?: InputMaybe<CumulativeMaxValue>;
  id: Scalars['String'];
  interval?: InputMaybe<Interval>;
  multiplier?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<IngestUnit>;
  unitOverride?: InputMaybe<Scalars['String']>;
};

export type IngestPointUpdateResponse = {
  __typename?: 'IngestPointUpdateResponse';
  ingestPoint?: Maybe<IngestPoint>;
};

export type IngestPoints = {
  __typename?: 'IngestPoints';
  hasMorePages: Scalars['Boolean'];
  page: Array<IngestPoint>;
};

export enum IngestUnit {
  A__AMPERES = 'A__AMPERES',
  L__LITER = 'L__LITER',
  USE_UNIT_OVERRIDE = 'USE_UNIT_OVERRIDE',
  bi__BINARY = 'bi__BINARY',
  btu__BRITISH_THERMAL_UNIT = 'btu__BRITISH_THERMAL_UNIT',
  ccf__CENTUM_CUBIC_FOOT = 'ccf__CENTUM_CUBIC_FOOT',
  cfm__CUBIC_FEET_PER_MINUTE = 'cfm__CUBIC_FEET_PER_MINUTE',
  degC__CELSIUS = 'degC__CELSIUS',
  degF__FAHRENHEIT = 'degF__FAHRENHEIT',
  gal__US_GALLON = 'gal__US_GALLON',
  h__HOURS = 'h__HOURS',
  inH2O__INCHES_WATER_COLUMN = 'inH2O__INCHES_WATER_COLUMN',
  kW__KILOWATTS = 'kW__KILOWATTS',
  kWh__KILOWATT_HOUR = 'kWh__KILOWATT_HOUR',
  lb__POUND = 'lb__POUND',
  m3__CUBIC_METER = 'm3__CUBIC_METER',
  m__MINUTES = 'm__MINUTES',
  pct__PERCENTAGE = 'pct__PERCENTAGE',
  ppm__PARTS_PER_MILLION = 'ppm__PARTS_PER_MILLION',
  psi__POUNDS_PER_SQUARE_INCH = 'psi__POUNDS_PER_SQUARE_INCH',
  s__SECONDS = 's__SECONDS',
  tonrefh__TONS_REFRIGERATION_HOUR = 'tonrefh__TONS_REFRIGERATION_HOUR'
}

export type IngestUnitOfMeasurement = {
  __typename?: 'IngestUnitOfMeasurement';
  unit: IngestUnit;
  unitOverride?: Maybe<Scalars['String']>;
};

export enum InletIsolationCommandBinaryUnits {
  BINARY = 'BINARY'
}

export enum InletIsolationStatusBinaryUnits {
  BINARY = 'BINARY'
}

export type IntegrationMapping = {
  __typename?: 'IntegrationMapping';
  enabled: Scalars['Boolean'];
  externalId: Scalars['String'];
  mappingId: Scalars['String'];
  name: IntegrationName;
  organizationId: Scalars['String'];
};

export enum IntegrationName {
  BE_PRISM = 'BE_PRISM'
}

export enum InteriorSpaceTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum Interval {
  FIFTEEN_MINUTE = 'FIFTEEN_MINUTE',
  FIVE_MINUTE = 'FIVE_MINUTE',
  ONE_HOUR = 'ONE_HOUR',
  ONE_MINUTE = 'ONE_MINUTE',
  THIRTY_MINUTE = 'THIRTY_MINUTE'
}

export type IntervalData = {
  __typename?: 'IntervalData';
  granularity: Granularity;
  measuredIn: UnitOfMeasurement;
  message?: Maybe<Scalars['String']>;
  /** @deprecated Unit of Measurement will soon not be a point field, as a point's unit of measurement is always the same for a given PointType. */
  name: Scalars['String'];
  range: TimeRange;
  status?: Maybe<DataStatus>;
  unit: Unit;
  values: Array<Maybe<Measurement>>;
};

export enum IsolationCommandBinaryUnits {
  BINARY = 'BINARY'
}

export enum IsolationStatusBinaryUnits {
  BINARY = 'BINARY'
}

export type KpIsResponse = {
  __typename?: 'KPIsResponse';
  dataCompleteness?: Maybe<Array<Maybe<DataCompleteness>>>;
};

export type Label = {
  __typename?: 'Label';
  domain: LabelDomain;
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type LabelActivity = Activity & {
  __typename?: 'LabelActivity';
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['String'];
  label?: Maybe<Label>;
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export enum LabelDomain {
  MEASURES = 'MEASURES'
}

export type LateStopEventData = EventData & {
  __typename?: 'LateStopEventData';
  actualEnergies: Array<Maybe<Measurement>>;
  actualRampDownStartTime?: Maybe<Scalars['DateTime']>;
  blendedRate: BlendedRates;
  detectedInPeriod: TimeRange;
  emissionsConversionRate: EmissionsConversionRate;
  nonOperatingTimeRanges: Array<TimeRange>;
  occupancyEndTime?: Maybe<Scalars['DateTime']>;
  optimumEnergies: Array<Measurement>;
  optimumRampDownEndTime?: Maybe<Scalars['DateTime']>;
  persistDays: Scalars['Float'];
};

export enum LeavingTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum LeavingTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum LeavingWaterTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum LeavingWaterTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type LocalDateRange = {
  endDate?: InputMaybe<Scalars['LocalDate']>;
  startDate?: InputMaybe<Scalars['LocalDate']>;
};

export enum Locale {
  DE_DE = 'DE_DE',
  EN_CA = 'EN_CA',
  EN_GB = 'EN_GB',
  EN_IE = 'EN_IE',
  EN_US = 'EN_US'
}

export type Location = {
  __typename?: 'Location';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  timezone: Scalars['String'];
  weatherStationId?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  timezone: Scalars['String'];
  weatherStationId?: InputMaybe<Scalars['String']>;
};

export enum LowLevelSetpointUnits {
  PERCENT = 'PERCENT'
}

export enum MakeupCommandBinaryUnits {
  BINARY = 'BINARY'
}

export enum MakeupPositionCommandUnits {
  PERCENT = 'PERCENT'
}

export enum MakeupPositionUnits {
  PERCENT = 'PERCENT'
}

export enum MakeupStatusBinaryUnits {
  BINARY = 'BINARY'
}

export enum MaxSpeedCommandUnits {
  PERCENT = 'PERCENT'
}

export type Measure = {
  __typename?: 'Measure';
  activities: Array<Activity>;
  /** @deprecated Use avoidableCost instead */
  annualAvoidedCost?: Maybe<AvoidedCost>;
  asset: Asset;
  /** @deprecated Use owner instead */
  assignee?: Maybe<User>;
  attachments: Array<Attachment>;
  avoidableCost?: Maybe<AvoidableCost>;
  avoidableEnergy?: Maybe<AvoidableEnergies>;
  created: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  customerEquipmentId?: Maybe<Scalars['String']>;
  customerMeasureId?: Maybe<Scalars['String']>;
  declined?: Maybe<Scalars['DateTime']>;
  equipment?: Maybe<Equipment>;
  eventData?: Maybe<EventData>;
  found?: Maybe<Scalars['DateTime']>;
  friendlyId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  implemented?: Maybe<Scalars['DateTime']>;
  internalAnalyticId?: Maybe<Scalars['String']>;
  labels?: Maybe<Array<Maybe<Label>>>;
  measureType: MeasureType;
  name: Scalars['String'];
  observation: Scalars['String'];
  owner?: Maybe<User>;
  publishStatus: MeasurePublishStatus;
  published?: Maybe<Scalars['DateTime']>;
  recommendation: Scalars['String'];
  sequences?: Maybe<Scalars['String']>;
  status: MeasureStatus;
  statusDetails?: Maybe<StatusChangedActivityDetails>;
  suppressed?: Maybe<Scalars['DateTime']>;
  temporaryEquipmentName?: Maybe<Scalars['String']>;
  vegaVisualization?: Maybe<Scalars['JSON']>;
};

export type MeasureAction = {
  actionType?: InputMaybe<MeasureActionType>;
};

export enum MeasureActionType {
  ACCEPT = 'ACCEPT',
  ACCEPT_FOR_REVIEW = 'ACCEPT_FOR_REVIEW',
  DECLINE = 'DECLINE',
  IMPLEMENT = 'IMPLEMENT',
  INVALIDATE = 'INVALIDATE',
  MARK_AS_IMPLEMENTED = 'MARK_AS_IMPLEMENTED',
  REQUEST_REVIEW = 'REQUEST_REVIEW',
  REVISE = 'REVISE'
}

export type MeasureAssignmentInput = {
  measureId: Scalars['ID'];
  ownerId: Scalars['ID'];
};

export type MeasureAssignmentResponse = MeasureResponse & {
  __typename?: 'MeasureAssignmentResponse';
  measure: Measure;
};

export type MeasureFilterFacets = {
  __typename?: 'MeasureFilterFacets';
  building: Array<BuildingFacet>;
  createdBy: Array<CreatedByFacet>;
  owner: Array<OwnerFacet>;
  status: Array<StatusFacet>;
};

export type MeasureFilters = {
  assetId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  closedDate?: InputMaybe<TimeRangeInput>;
  createdByIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  foundDate?: InputMaybe<TimeRangeInput>;
  friendlyId?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  publishStatus?: InputMaybe<Array<InputMaybe<MeasurePublishStatus>>>;
  status?: InputMaybe<Array<InputMaybe<MeasureStatus>>>;
};

export type MeasureLabelInput = {
  labelId: Scalars['ID'];
  measureId: Scalars['ID'];
};

export enum MeasurePublishStatus {
  PUBLISHED = 'PUBLISHED',
  SUPPRESSED = 'SUPPRESSED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export type MeasureResponse = {
  measure?: Maybe<Measure>;
};

export enum MeasureSortField {
  AVOIDED_COST = 'AVOIDED_COST',
  ID = 'ID',
  IDENTIFIED_DATE = 'IDENTIFIED_DATE',
  NAME = 'NAME',
  STATUS = 'STATUS'
}

export enum MeasureStatus {
  IMPLEMENTED = 'IMPLEMENTED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  UNDER_REVIEW = 'UNDER_REVIEW',
  WAITING_FOR_REVIEW = 'WAITING_FOR_REVIEW'
}

export enum MeasureType {
  BAS_FROM_MAP = 'BAS_FROM_MAP',
  CUSTOM = 'CUSTOM',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  EARLY_START = 'EARLY_START',
  HOLIDAY_OPERATION = 'HOLIDAY_OPERATION',
  LATE_STOP = 'LATE_STOP',
  LOAD_CYCLING = 'LOAD_CYCLING',
  NIGHT_BASELOAD = 'NIGHT_BASELOAD',
  SIGNIFICANT_OCCUPIED_BASELINE_DEVIATION = 'SIGNIFICANT_OCCUPIED_BASELINE_DEVIATION',
  WEEKEND_OPERATION = 'WEEKEND_OPERATION'
}

export type Measurement = {
  __typename?: 'Measurement';
  timestamp: Scalars['DateTime'];
  value?: Maybe<Scalars['Float']>;
};

export type Measures = {
  __typename?: 'Measures';
  aggregates?: Maybe<MeasuresAggregate>;
  cursor?: Maybe<Scalars['String']>;
  hasMorePages: Scalars['Boolean'];
  page: Array<Maybe<Measure>>;
  total: Scalars['Int'];
};

export type MeasuresAggregate = {
  __typename?: 'MeasuresAggregate';
  avoidableCost?: Maybe<AvoidableCost>;
  avoidableEnergy?: Maybe<AvoidableEnergies>;
};

export type MeasuresSort = {
  field?: InputMaybe<MeasureSortField>;
  order?: InputMaybe<SortOrder>;
};

export type Meter = Asset & {
  __typename?: 'Meter';
  commodityName: CommodityName;
  customerMeterId: Scalars['String'];
  group?: Maybe<MeterGroup>;
  id: Scalars['String'];
  /** @deprecated Use meterAggregateTypes instead */
  includeInBuildingAggregate?: Maybe<Scalars['Boolean']>;
  meterAggregateTypes: Array<Maybe<MeterAggregateType>>;
  name: Scalars['String'];
  points: Array<Maybe<PointInterface>>;
};


export type MeterPointsArgs = {
  includeVirtualPoints?: InputMaybe<Scalars['Boolean']>;
};

export enum MeterAggregateType {
  BUILDING_CONSUMED = 'BUILDING_CONSUMED',
  ONSITE_SUPPLIED = 'ONSITE_SUPPLIED',
  UTILITY_INTERACTION = 'UTILITY_INTERACTION'
}

export type MeterAssignment = {
  __typename?: 'MeterAssignment';
  addedTimestamp: Scalars['DateTime'];
  addedUserId: Scalars['String'];
  id: Scalars['String'];
  meter: Meter;
  removedTimestamp?: Maybe<Scalars['DateTime']>;
  removedUserId?: Maybe<Scalars['String']>;
};

export type MeterAssignmentPayload = {
  __typename?: 'MeterAssignmentPayload';
  assignment?: Maybe<Assignment>;
};

export type MeterDecommissionInput = {
  id: Scalars['String'];
};

export type MeterGroup = {
  __typename?: 'MeterGroup';
  building?: Maybe<Building>;
  customerMeterGroupId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  meters?: Maybe<Array<Meter>>;
  name: Scalars['String'];
};

export type MeterGroupRegistrationInput = {
  buildingId: Scalars['String'];
  customerMeterGroupId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MeterGroupResponse = {
  __typename?: 'MeterGroupResponse';
  group?: Maybe<MeterGroup>;
};

export type MeterGroupUpdateInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type MeterGroupUpdateResponse = {
  __typename?: 'MeterGroupUpdateResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  meterGroup?: Maybe<MeterGroup>;
  success: Scalars['Boolean'];
};

export type MeterMoveInput = {
  destinationBuildingId: Scalars['String'];
  id: Scalars['String'];
  sourceBuildingId?: InputMaybe<Scalars['String']>;
};

export type MeterUpdateInput = {
  commodityName?: InputMaybe<CommodityName>;
  id: Scalars['String'];
  includeInBuildingAggregate?: InputMaybe<Scalars['Boolean']>;
  meterAggregateTypes?: InputMaybe<Array<InputMaybe<MeterAggregateType>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type MeterUpdatePayload = {
  __typename?: 'MeterUpdatePayload';
  meter?: Maybe<Meter>;
};

export enum MinSpeedCommandUnits {
  PERCENT = 'PERCENT'
}

export enum MinimumLockoutTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum MinimumOutsideAirFlowRateSetpointUnits {
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum MinimumPositionSetpointUnits {
  PERCENT = 'PERCENT'
}

export type MissingBill = {
  __typename?: 'MissingBill';
  endDate: Scalars['String'];
  serviceAccountId: Scalars['String'];
  startDate: Scalars['String'];
};

export type MissingBillsResponse = {
  __typename?: 'MissingBillsResponse';
  missingBills: Array<Maybe<MissingBill>>;
};

export enum MixedTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum MixedTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type ModelMetrics = {
  __typename?: 'ModelMetrics';
  overallSMAE?: Maybe<Scalars['Float']>;
};

export type ModifyScheduleExceptionInput = {
  exceptionType?: InputMaybe<ExceptionType>;
  notes?: InputMaybe<Scalars['String']>;
  occupancySchedule?: InputMaybe<OccupancyScheduleInput>;
  reasons?: InputMaybe<Array<InputMaybe<BuildingAnnotationReason>>>;
  scheduleExceptionId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export enum Month {
  APR = 'APR',
  AUG = 'AUG',
  DEC = 'DEC',
  FEB = 'FEB',
  JAN = 'JAN',
  JUL = 'JUL',
  JUN = 'JUN',
  MAR = 'MAR',
  MAY = 'MAY',
  NOV = 'NOV',
  OCT = 'OCT',
  SEP = 'SEP'
}

export type MonthBilling = {
  __typename?: 'MonthBilling';
  actual?: Maybe<BillingReport>;
  calendarized?: Maybe<BillingReport>;
  month: Month;
  varianceNotes?: Maybe<Array<Maybe<VarianceNote>>>;
};


export type MonthBillingVarianceNotesArgs = {
  comparisonYear: Scalars['Int'];
};

export type MonthlyReporting = {
  __typename?: 'MonthlyReporting';
  actual?: Maybe<CarbonReport>;
  calendarized: CarbonReport;
  month: Month;
};

export type MonthlyUtilityData = {
  __typename?: 'MonthlyUtilityData';
  month: Month;
  totals?: Maybe<Totals>;
};

export type MoveBillToBuildingInput = {
  buildingId: Scalars['String'];
  consolidatedBillId: Scalars['String'];
  destinationBuildingId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type MoveBillToBuildingResponse = {
  __typename?: 'MoveBillToBuildingResponse';
  destinationBuildingId: Scalars['String'];
};

export type MoveBuildingInput = {
  buildingId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MoveEquipmentToBuildingInput = {
  destinationBuildingId: Scalars['String'];
  equipmentId: Scalars['String'];
  sourceBuildingId: Scalars['String'];
};

export type MoveEquipmentToBuildingResponse = {
  __typename?: 'MoveEquipmentToBuildingResponse';
  equipment?: Maybe<Equipment>;
};

export type MoveMeasuresForBuildingToOrganizationInput = {
  buildingId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type MoveMeasuresForBuildingToOrganizationResponse = {
  __typename?: 'MoveMeasuresForBuildingToOrganizationResponse';
  measures: Array<Measure>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * # Create a new Billing
   * ## Input requirements:
   * - Must specify unitOverride if unit set to USE_UNIT_OVERRIDE
   * ## Access requirements:
   * - Admin role or Organization user
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  addBill?: Maybe<ConsolidatedBillResponse>;
  /**
   * # Creates a building integration mapping for a given building and integration
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  addBuildingIntegrationMapping: AddBuildingIntegrationMappingResponse;
  addCommentToMeasure: ActivityResponse;
  /**
   * # Adds a piece of equipment to a group
   * ## Possible error codes:
   * NOT_FOUND
   * INSUFFICIENT_RIGHTS
   * SERVICE_UNAVAILABLE
   * BAD_USER_INPUT
   */
  addEquipmentToBuildingEquipmentGroup: BuildingEquipmentGroupResponse;
  /**
   * # Creates a new label. If the label already exists, the matching label is returned.
   * ## Access rights:
   * - Only ADMIN for now
   * ## Error Codes:
   * - INSUFFICIENT_RIGHTS
   */
  addLabel?: Maybe<Label>;
  addLabelToMeasure: AddLabelToMeasureResponse;
  assignBuildingsToUser?: Maybe<AssignBuildingsToUserPayload>;
  /**
   * # Assign a piece of equipment to a building
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "buildingId|equipmentId" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * - PARENT_EQUIPMENT_FOUND (returned in the case of specified equipment having parent equipment)
   * - SERVICE_UNAVAILABLE
   */
  assignEquipmentToBuilding?: Maybe<AssignEquipmentToBuildingResponse>;
  /**
   * # Assign measure to user
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * {
   * inputField: "measureId"
   * }
   * ```
   * - INVALID_ASSIGNMENT
   * - INSUFFICIENT_RIGHTS
   * Extensions contain information on which entity could not be accessed:
   * ```
   * {
   * inputField: "ownerId"
   * }
   * ```
   * - SERVICE_UNAVAILABLE
   */
  assignMeasure?: Maybe<MeasureAssignmentResponse>;
  /**
   * # Assigns a meter to a meter group
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "groupId" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * - BAD_USER_INPUT (thrown in the case of a mismatch between the meter's building and that of the group)
   * - SERVICE_UNAVAILABLE
   */
  assignMeterToMeterGroup?: Maybe<MeterGroupResponse>;
  assignRoleToUser: AssignRoleToUserPayload;
  assignSpaceGroupToParent?: Maybe<AssignSpaceGroupToParentResponse>;
  /**
   * # Attach a file to an existing bill
   * ## Input requirements:
   * - Upload filesize must be less than 5mb
   * - Only PNG, JPG, and PDF files are supported
   * ## Access requirements:
   * - Has write access to the provided bill
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  attachFileToBill?: Maybe<AttachFileToBillResponse>;
  attachFileToMeasure: AttachmentResponse;
  /**
   * # Cancel a SCHEDULED curtailment event, deleting it from the database
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  cancelScheduledCurtailmentEvent?: Maybe<CancelScheduledCurtailmentEventResponse>;
  /**
   * # Clones a user's metadata to an admin user
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  cloneUser: User;
  /**
   * # Closes a measure
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  closeMeasure: CloseMeasureResponse;
  /**
   * # Allows an admin to configure Enel integration for an organization; will create the
   * # integration if it does not exist.
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   */
  configureEnelIntegrationForOrganization: ConfigureGobyIntegrationResponse;
  /**
   * # Allows an admin to configure Goby integration for an organization; will create the
   * # integration if it does not exist.
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   */
  configureGobyIntegrationForOrganization: ConfigureGobyIntegrationResponse;
  /**
   * # Allows an admin to configure Sources for an organization; will create the
   * # Sources enablement if it does not exist.
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   */
  configureSourcesForOrganization: ConfigureSourcesResponse;
  /**
   * # Converts a consolidated account into a delivery account and migrates any
   * # existing data to the new format.
   * ## Error codes:
   * - NOT_FOUND
   * - BAD_USER_INPUT (serviceAccountId is not a consolidated account)
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  convertConsolidatedAccountToDeliveryAccount: ConvertConsolidatedAccountToSeperateAccountsResponse;
  /**
   * # Splits a consolidated account into supply and delivery accounts and migrates any
   * # existing data to the new format.
   * ## Error codes:
   * - NOT_FOUND
   * - BAD_USER_INPUT (serviceAccountId is not a consolidated account)
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  convertConsolidatedAccountToSeperateAccounts: ConvertConsolidatedAccountToSeperateAccountsResponse;
  /**
   * # Converts a consolidated account into a supply account and migrates any
   * # existing data to the new format.
   * ## Error codes:
   * - NOT_FOUND
   * - BAD_USER_INPUT (serviceAccountId is not a consolidated account)
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  convertConsolidatedAccountToSupplyAccount: ConvertConsolidatedAccountToSeperateAccountsResponse;
  /** Alert Management APIs */
  createAlert?: Maybe<AlertPayload>;
  /**
   * # Create a customer created measure
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "owner" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * Extensions contain information on which entity could not be accessed:
   * ```
   * { inputField: "building" }
   * ```
   * - SERVICE_UNAVAILABLE
   */
  createAndCloseMeasure?: Maybe<MeasureResponse>;
  createBuildingAnnotation: CreateBuildingAnnotationResponse;
  createBuildingOperatingSchedule: BuildingOperatingScheduleResponse;
  createBuildingOperatingScheduleException: BuildingOperatingScheduleResponse;
  /**
   * # Create a new Electricity Service Account Pair
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  createElectricityServiceAccountPair: ElectricityServiceAccountPairCreationResponse;
  /**
   * # Create a customer created measure
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "owner" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * Extensions contain information on which entity could not be accessed:
   * ```
   * { inputField: "building" }
   * ```
   * - SERVICE_UNAVAILABLE
   */
  createMeasure?: Maybe<MeasureResponse>;
  /**
   * # Create a new Service account
   * ## Input requirements:
   * - Must specify the utilityProviderName
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  createServiceAccount: ServiceAccountCreationResponse;
  deactivateUser: DeactivateUserPayload;
  /**
   * # Moves a meter to the "Retired Meters" building in the Sudden Valley organization
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - DATABASE_ERROR
   */
  decommissionMeter: MeterUpdatePayload;
  deleteAlertById: DeleteAlertResponse;
  deleteAlertsByAssetId: DeleteAlertsByAssetIdResponse;
  /**
   * # Delete an existing Billing
   * ## Input requirements:
   * - Must specify unitOverride if unit set to USE_UNIT_OVERRIDE
   * ## Access requirements:
   * - Admin role or Organization user
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  deleteBill?: Maybe<DeleteConsolidatedBillResponse>;
  /**
   * # Remove an existing bill attachment
   * ## Access requirements:
   * - Has write access to the provided bill
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - NOT_FOUND
   */
  deleteBillAttachment?: Maybe<DeleteBillAttachmentResponse>;
  deleteBuildingAnnotation: DeleteBuildingAnnotationResponse;
  /**
   * # This mutation is only used by the UI to delete building schedule exceptions
   * @deprecated Use deleteBuildingScheduleException instead
   */
  deleteBuildingOperatingSchedule: BuildingOperatingScheduleResponse;
  deleteMeasureActivityById: DeleteMeasureActivityResponse;
  deleteScheduleException: DeleteScheduleExceptionResponse;
  deleteSubscriptionsByAssetId: DeleteSubscriptionsByAssetIdPayload;
  /**
   * # Delete an existing Variance Note
   * ## Input requirements:
   * - Must specify unitOverride if unit set to USE_UNIT_OVERRIDE
   * ## Access requirements:
   * - Admin role or Organization user
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  deleteVarianceNote?: Maybe<DeleteVarianceNoteResponse>;
  /**
   * # Allows an admin to enable features for an organization; the feature must
   * # already be configured (see configure<feature>ForOrganization mutations to enable it).
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   */
  disableFeatureForOrganization: DisableFeatureForOrganizationResponse;
  /**
   * # Allows admin to disable sources for a building if it exists.
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   */
  disableSourcesForBuilding: EnableSourcesForBuildingResponse;
  /**
   * # Allows an admin to enable features for an organization; the feature must
   * # already be configured (see configure<feature>ForOrganization mutations to enable it).
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   */
  enableFeatureForOrganization: EnableFeatureForOrganizationResponse;
  /**
   * # Allows admin to enable sources for a building if it exists.
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   */
  enableSourcesForBuilding: EnableSourcesForBuildingResponse;
  /**
   * # Generate an API key for an ingest pathway.
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  generatePathwayApiKey?: Maybe<GeneratePathwayApiKeyResponse>;
  /**
   * # Import a measure
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "relatedBuildingID" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * Extensions contain information on which entity could not be accessed:
   * ```
   * { inputField: "asset" }
   * ```
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT (thrown in the case of `createdByUser` specified for a measure type other than `CUSTOMER_CREATED`)
   */
  importMeasure?: Maybe<MeasureResponse>;
  importMeasureAssignedActivity: ActivityResponse;
  importMeasureCommentActivity?: Maybe<ActivityResponse>;
  importMeasureCreatedActivity: ActivityResponse;
  importMeasureStatusTransitionActivity: ActivityResponse;
  /**
   * # Modifies a schedule exception
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  modifyScheduleException: ScheduleException;
  /**
   * # Moves a bill from a building to another.
   * ## Access rights:
   * - Only ADMIN for now
   * ## Error Codes:
   * - INSUFFICIENT_RIGHTS
   */
  moveBillToBuilding?: Maybe<MoveBillToBuildingResponse>;
  /**
   * # Moves meter to a different building
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - DATABASE_ERROR
   */
  moveBuilding: BuildingUpdatePayload;
  /**
   * # moves a piece of equipment to a different building
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "buildingId|equipmentId" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * - PARENT_EQUIPMENT_FOUND (returned in the case of specified equipment having parent equipment)
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_REQUEST
   */
  moveEquipmentToBuilding?: Maybe<MoveEquipmentToBuildingResponse>;
  /**
   * # Move measures for building to new organization
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * What should error code be if building doesn't have a relationship to target organization?
   * - SERVICE_UNAVAILABLE
   */
  moveMeasuresForBuildingToOrganization: MoveMeasuresForBuildingToOrganizationResponse;
  /**
   * # Moves a meter from one building to another, within an organization
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  moveMeter: MeterUpdatePayload;
  moveMeterToServiceAccount: MeterAssignmentPayload;
  /**
   * # Publish a measure
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  publishMeasure?: Maybe<PublishMeasureResponse>;
  /**
   * # Re-register a previously registered S2.
   * Re-provisions an S2 with it's existing devices / points configuration
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  reRegisterS2?: Maybe<S2>;
  registerBuildingOrganizationRelationship?: Maybe<RegisterBuildingOrganizationRelationshipResponse>;
  registerBuildingWithSpaceGroup?: Maybe<BuildingSpaceGroup>;
  /**
   * # Register a new ingest pathway
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - BAD_USER_INPUT
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - SERVICE_UNAVAILABLE
   */
  registerIngestPathway?: Maybe<IngestPathwayRegistrationResponse>;
  /**
   * # Registers a new ingest point.
   * ## Input requirements:
   * - Must specify unitOverride if unit set to USE_UNIT_OVERRIDE
   * ## Access requirements:
   * - Admin role
   * ## Error codes:
   * - BAD_USER_INPUT
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - SERVICE_UNAVAILABLE
   */
  registerIngestPoint?: Maybe<IngestPointRegistrationResponse>;
  /**
   * # Creates a new equipment group on a building
   * ## Possible error codes:
   * NOT_FOUND
   * INSUFFICIENT_RIGHTS
   * SERVICE_UNAVAILABLE
   */
  registerNewBuildingEquipmentGroup: BuildingEquipmentGroupResponse;
  registerNewBuildingWithOrganization: RegisterNewBuildingMutationResponse;
  /**
   * # Registers a new enterprise user. Use this mutation when Hatch is not the IdP for the user or when you need to specify the new user's ID.
   * ## Input requirements:
   * - Unique user ID
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - BAD_USER_INPUT
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  registerNewEnterpriseUser?: Maybe<EnterpriseUserRegistrationResponse>;
  /**
   * # Create a new equipment
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  registerNewEquipment: RegisterNewEquipmentResponse;
  /**
   * # Inserts a new Equipment attached to a Building
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - BAD_USER_INPUT
   * - NOT_FOUND
   * - SERVICE_UNAVAILABLE
   */
  registerNewEquipmentWithBuilding?: Maybe<RegisterNewEquipmentWithBuildingResponse>;
  /**
   * # Creates a new meter group
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "buildingId" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  registerNewMeterGroupWithBuilding?: Maybe<MeterGroupResponse>;
  registerNewMeterWithBuilding: Meter;
  /**
   * # Inserts a new equipment point attached to a parent equipment
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   * (thrown in the case of a duplicate assignment to a single ingest point id)
   * Extensions contain information on which field contained an error:
   * ```
   * { inputField: "ingestPointId" }
   * ```
   */
  registerNewPointWithEquipment: RegisterNewPointWithEquipmentResponse;
  /**
   * # Create a new point
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND (thrown in the case of an invalid meter id)
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   * (thrown in the case of a duplicate customer point id within an organization,
   * or in the case of a duplicate assignment to a single ingest point id)
   * Extensions contain information on which field contained an error:
   * ```
   * { inputField: "ingestPointId" }
   * ```
   */
  registerNewPointWithMeter: Point;
  registerNewSpaceGroup?: Maybe<SpaceGroup>;
  /** User Management APIs */
  registerNewUser: UserRegistrationPayload;
  registerNewVirtualPointWithMeter: VirtualPoint;
  /**
   * # Register ingest points on an existing ODAS
   * ## Access requirements:
   * - Admin role
   *
   * ## Possible error codes:
   * #### NOT_FOUND
   * No ODAS found for the specified ODAS id
   *
   * #### BAD_USER_INPUT
   * Returned in the following cases:
   * - An invalid modbus device number is specified
   * - Invalid point configuration
   * - An invalid or duplicate point number is specified
   * - A cumulative point is indicated without specifying a multiplier
   * - A cumulative point is indicated with an unsupported interval (>15m)
   * - unit `USE_UNIT_OVERRIDE` is set without specifying `unitOverride`
   * - unit is not set to `USE_UNIT_OVERRIDE` while specifying a `unitOverride`
   *
   * Extensions contain information on which field contains an error, eg:
   * ```
   * { inputField: "modbusDevices[0].ingestPointsToRegister[0]" }
   * ```
   *
   * #### INSUFFICIENT_RIGHTS
   *
   * #### SERVICE_UNAVAILABLE
   */
  registerOdasIngestPoints?: Maybe<RegisterOdasIngestPointsResponse>;
  registerOrganization: RegisterOrganizationMutationResponse;
  /**
   * # Register a new pathway point set
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  registerPathwayPointSet?: Maybe<RegisterPathwayPointSetResponse>;
  /**
   * # Stores Prism login credentials for an organization. If the organization already has credentials,
   * # the credentials are overwritten.
   */
  registerPrismCredentialsForOrganization: RegisterPrismCredentialsForOrganizationResponse;
  /**
   * # Register a new S2.
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  registerS2?: Maybe<S2>;
  removeBuildingFromAllUsers?: Maybe<RemoveBuildingFromAllUsersPayload>;
  removeBuildingFromGroup: BuildingUpdatePayload;
  /**
   * # Removes completely an existing relationship between a building and organization.
   * # Use this if an organization or any of its users should not be able to see a building any more.
   * #
   * ## Possible error codes:
   * NOT_FOUND
   * INSUFFICIENT_RIGHTS
   * SERVICE_UNAVAILABLE
   */
  removeBuildingOrganizationRelationship?: Maybe<RemoveBuildingOrganizationRelationshipResponse>;
  removeBuildingsFromUser?: Maybe<RemoveBuildingsFromUserPayload>;
  /**
   * # removes a piece of equipment to a building
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "buildingId|equipmentId" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * - PARENT_EQUIPMENT_FOUND (returned in the case of specified equipment having parent equipment)
   * - SERVICE_UNAVAILABLE
   */
  removeEquipmentFromBuilding?: Maybe<RemoveEquipmentFromBuildingResponse>;
  /**
   * # Adds a piece of equipment to a group
   * ## Possible error codes:
   * NOT_FOUND
   * INSUFFICIENT_RIGHTS
   * SERVICE_UNAVAILABLE
   * BAD_USER_INPUT
   */
  removeEquipmentFromBuildingEquipmentGroup: BuildingEquipmentGroupResponse;
  /**
   * # Removes child equipment from its parent
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "buildingId|equipmentId" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * - NO_PARENT_EQUIPMENT_FOUND (returned in the case of specified equipment having parent equipment)
   * - SERVICE_UNAVAILABLE
   */
  removeEquipmentFromParent: RemoveEquipmentFromParentResponse;
  /** # Removes an integration mapping from a building */
  removeIntegrationMapping?: Maybe<RemoveIntegrationMappingResponse>;
  removeLabelFromMeasure: RemoveMeasureLabelResponse;
  /**
   * # Removes a meter from a meter group
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "groupId" }
   * ```
   * - INSUFFICIENT_RIGHTS
   * - BAD_USER_INPUT (thrown in the case of the meter not being a member of the specified group)
   * - SERVICE_UNAVAILABLE
   */
  removeMeterFromMeterGroup?: Maybe<MeterGroupResponse>;
  removeMeterFromServiceAccount: MeterAssignmentPayload;
  removeRoleFromUser: RemoveRoleFromUserPayload;
  removeSpaceGroupFromParent?: Maybe<RemoveSpaceGroupFromParentResponse>;
  removeSubscription: RemoveSubscriptionPayload;
  /**
   * # Modifies an existing equipment group on a building
   * ## Possible error codes:
   * NOT_FOUND
   * INSUFFICIENT_RIGHTS
   * SERVICE_UNAVAILABLE
   */
  renameBuildingEquipmentGroup: BuildingEquipmentGroupResponse;
  /**
   * # Send a RESTORE signal to a control point of an ACTIVE event
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  restoreDemandManagementRelay?: Maybe<RestoreDemandManagementRelayResponse>;
  /**
   * # Revoke a pathway API key
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  revokePathwayApiKey?: Maybe<RevokePathwayApiKeyResponse>;
  scheduleBaselineJob?: Maybe<ScheduleBaselineJobResponse>;
  /**
   * ## Error codes:
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  scheduleNewCurtailmentEvent?: Maybe<ScheduleNewCurtailmentEventResponse>;
  sendWelcomeEmail: SendWelcomeEmailResponse;
  setBuildingBlendedRates: BuildingUpdatePayload;
  /**
   * # Create a new Occupancy
   * ## Access requirements:
   * - Admin role or Organization user
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  setBuildingOccupancy: Array<Maybe<OccupancyResponse>>;
  setBuildingThreshold?: Maybe<SetBuildingThresholdResponse>;
  /**
   * # Sets the calendar year budget for a given commodity, year, and building
   * ## Possible error codes:
   * - BAD_REQUEST
   * - INVALID_YEAR
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  setCalendarYearCommodityBudgetForBuilding?: Maybe<SetCalendarYearCommodityBudgetForBuildingResponse>;
  setOperatingPeriodsForBuildingOperatingSchedule: BuildingOperatingScheduleResponse;
  /**
   * # Set the password for an ingest pathway. Replaces any previous password
   * ## Input requirements:
   * - Password must be between 8 - 64 characters in length
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  setPasswordForIngestPathway?: Maybe<SetPasswordForIngestPathwayResponse>;
  /**
   * # Create a new Variance Billing Note
   * ## Input requirements:
   * - Must specify unitOverride if unit set to USE_UNIT_OVERRIDE
   * ## Access requirements:
   * - Admin role or Organization user
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  setVarianceNote?: Maybe<SetVarianceNoteResponse>;
  /**
   * # Allows an admin to start the relationship between supply and delivery service accounts.
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT (invalid delivery or supply account, start date overlaps an existing relationship)
   */
  startSupplyService: SupplyServiceResponse;
  subscribeUserToConsolidatedReport: SubscribeUserPayload;
  subscribeUserToMultipleReports: SubscribeUserMultiplePayload;
  subscribeUserToReport: SubscribeUserPayload;
  /**
   * # Suppress a measure
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  suppressMeasure?: Maybe<SuppressMeasureResponse>;
  /**
   * # Allows an admin to terminate the relationship between supply and delivery service accounts.
   * # If the service was already terminated, the termination date will be updated to the
   * # date provided.
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT (account is not a Supply account, terminationDate overlaps an existing relationship or is prior to the start date)
   */
  terminateSupplyService: SupplyServiceResponse;
  /**
   * # Transition the measure from one state to another
   * ## Error codes:
   * - NOT_FOUND
   * Extensions contain information on which entity could not be found:
   * ```
   * { inputField: "measureId" }
   * ```
   * - INVALID_TRANSITION
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  transitionMeasure?: Maybe<TransitionMeasureResponse>;
  updateBaselineModel?: Maybe<UpdateBaselineModelResponse>;
  /**
   * # Update an existing Billing
   * ## Input requirements:
   * - Must specify unitOverride if unit set to USE_UNIT_OVERRIDE
   * ## Access requirements:
   * - Admin role or Organization user
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  updateBill?: Maybe<ConsolidatedBillResponse>;
  updateBillManagement: BillManagementUpdateResponse;
  updateBuilding: BuildingUpdatePayload;
  /**
   * # Renames equipment
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  updateEquipment: UpdateEquipmentResponse;
  /**
   * # Updates equipment point
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * (thrown in the case of a duplicate assignment to a single ingest point id)
   * Extensions contain information on which field contained an error:
   * ```
   * { inputField: "ingestPointId" }
   * ```
   * - SERVICE_UNAVAILABLE
   */
  updateEquipmentPoint: UpdateEquipmentPointResponse;
  updateFeatureEnablement: BuildingFeatureEnablementPayload;
  /**
   * # Updates an ingest point.
   * ## Input requirements:
   * - Must specify unitOverride if unit set to USE_UNIT_OVERRIDE
   * - A multiplier value must be specified if the point is cumulative
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - BAD_USER_INPUT
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - SERVICE_UNAVAILABLE
   */
  updateIngestPoint?: Maybe<IngestPointUpdateResponse>;
  /**
   * # Updates the default measure assignee for a building
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  updateMeasureDefaultAssigneeForBuilding: UpdateMeasureDefaultAssigneeForBuildingResponse;
  /**
   * # Update fields of a measure
   * ## Note: Any changes to avoidableCost will completely overwrite previous values (there is no partial update support)
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  updateMeasureDetails?: Maybe<MeasureResponse>;
  /**
   * # Update implemented date field of a measure
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  updateMeasureImplementedDate?: Maybe<MeasureResponse>;
  updateMeter: MeterUpdatePayload;
  updateMeterGroup: MeterGroupUpdateResponse;
  updateOrganization: OrganizationUpdatePayload;
  /**
   * # Updates a pathway point set.
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - SERVICE_UNAVAILABLE
   */
  updatePathwayPointSet?: Maybe<UpdatePathwayPointSetResponse>;
  /**
   * # Updates point
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - NOT_FOUND
   * - BAD_USER_INPUT
   * (thrown in the case of a duplicate assignment to a single ingest point id)
   * Extensions contain information on which field contained an error:
   * ```
   * { inputField: "ingestPointId" }
   * ```
   * - SERVICE_UNAVAILABLE
   */
  updatePoint: PointUpdatePayload;
  updatePointThreshold: PointInterface;
  /**
   * # Update an existing Service Account
   * ## Input requirements:
   * - Must specify the utilityProviderName
   * - Must specify the Account Number
   * ## Access requirements:
   * - Admin role
   * ## Possible error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   * - BAD_USER_INPUT
   */
  updateServiceAccount: ServiceAccountCreationResponse;
  updateSpaceGroup: SpaceGroupUpdateResponse;
  /**
   * # Updates user attributes
   * ## Error codes:
   * - USER_MISCONFIGURATION
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  updateUserAttributes: UserUpdatePayload;
  updateUserPreferences?: Maybe<UpdateUserPreferencesResponse>;
};


export type MutationAddBillArgs = {
  input?: InputMaybe<ConsolidatedBillInput>;
};


export type MutationAddBuildingIntegrationMappingArgs = {
  input: AddBuildingIntegrationMappingInput;
};


export type MutationAddCommentToMeasureArgs = {
  comment: Scalars['String'];
  customerMeasureCommentId?: InputMaybe<Scalars['String']>;
  isInternal?: InputMaybe<Scalars['Boolean']>;
  measureId: Scalars['ID'];
};


export type MutationAddEquipmentToBuildingEquipmentGroupArgs = {
  equipmentId: Scalars['String'];
  groupId: Scalars['String'];
};


export type MutationAddLabelArgs = {
  input?: InputMaybe<AddLabelInput>;
};


export type MutationAddLabelToMeasureArgs = {
  input: MeasureLabelInput;
};


export type MutationAssignBuildingsToUserArgs = {
  input?: InputMaybe<AssignBuildingsToUserInput>;
};


export type MutationAssignEquipmentToBuildingArgs = {
  input: AssignEquipmentToBuildingInput;
};


export type MutationAssignMeasureArgs = {
  input: MeasureAssignmentInput;
};


export type MutationAssignMeterToMeterGroupArgs = {
  groupId: Scalars['String'];
  meterId: Scalars['String'];
};


export type MutationAssignRoleToUserArgs = {
  input: AssignRoleToUserInput;
};


export type MutationAssignSpaceGroupToParentArgs = {
  input: AssignSpaceGroupToParentInput;
};


export type MutationAttachFileToBillArgs = {
  attachment: BillAttachmentInput;
  billId: Scalars['ID'];
};


export type MutationAttachFileToMeasureArgs = {
  attachment: AttachmentInput;
  measureId: Scalars['ID'];
};


export type MutationCancelScheduledCurtailmentEventArgs = {
  eventId: Scalars['String'];
};


export type MutationCloneUserArgs = {
  destinationUserId: Scalars['String'];
  sourceUserId: Scalars['String'];
};


export type MutationCloseMeasureArgs = {
  input: CloseMeasureInput;
};


export type MutationConfigureEnelIntegrationForOrganizationArgs = {
  input: ConfigureEnelIntegrationInput;
};


export type MutationConfigureGobyIntegrationForOrganizationArgs = {
  input: ConfigureGobyIntegrationInput;
};


export type MutationConfigureSourcesForOrganizationArgs = {
  input: ConfigureSourcesInput;
};


export type MutationConvertConsolidatedAccountToDeliveryAccountArgs = {
  input: ConvertConsolidatedAccountToDeliveryAccountInput;
};


export type MutationConvertConsolidatedAccountToSeperateAccountsArgs = {
  input: ConvertConsolidatedAccountToSeperateAccountsInput;
};


export type MutationConvertConsolidatedAccountToSupplyAccountArgs = {
  input: ConvertConsolidatedAccountToSupplyAccountInput;
};


export type MutationCreateAlertArgs = {
  input: CreateAlertInput;
};


export type MutationCreateAndCloseMeasureArgs = {
  closedReason: CloseMeasureReason;
  measure: CreateMeasureInput;
};


export type MutationCreateBuildingAnnotationArgs = {
  input?: InputMaybe<CreateBuildingAnnotationInput>;
};


export type MutationCreateBuildingOperatingScheduleArgs = {
  buildingId: Scalars['String'];
  operatingPeriods: Array<OperatingPeriodInput>;
};


export type MutationCreateBuildingOperatingScheduleExceptionArgs = {
  applicability: TimeRangeInput;
  buildingId: Scalars['String'];
  exceptionOperatingPeriods?: InputMaybe<Array<InputMaybe<ExceptionOperatingPeriodInput>>>;
  exceptionType?: InputMaybe<ExceptionType>;
  notes?: InputMaybe<Scalars['String']>;
  operatingPeriods: Array<OperatingPeriodInput>;
  reasons?: InputMaybe<Array<InputMaybe<BuildingAnnotationReason>>>;
  recurrence?: InputMaybe<RecurrenceInput>;
  title: Scalars['String'];
};


export type MutationCreateElectricityServiceAccountPairArgs = {
  deliveryServiceAccount: ElectricityServiceAccountInput;
  supplyServiceAccount: ElectricityServiceAccountInput;
};


export type MutationCreateMeasureArgs = {
  input: CreateMeasureInput;
};


export type MutationCreateServiceAccountArgs = {
  serviceAccount: ServiceAccountInput;
};


export type MutationDeactivateUserArgs = {
  input: DeactivateUserInput;
};


export type MutationDecommissionMeterArgs = {
  input: MeterDecommissionInput;
};


export type MutationDeleteAlertByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAlertsByAssetIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBillArgs = {
  input?: InputMaybe<ConsolidatedBillDeleteInput>;
};


export type MutationDeleteBillAttachmentArgs = {
  attachmentId: Scalars['ID'];
  billId: Scalars['ID'];
};


export type MutationDeleteBuildingAnnotationArgs = {
  id: Scalars['String'];
};


export type MutationDeleteBuildingOperatingScheduleArgs = {
  scheduleId: Scalars['String'];
};


export type MutationDeleteMeasureActivityByIdArgs = {
  measureActivityId: Scalars['String'];
};


export type MutationDeleteScheduleExceptionArgs = {
  scheduleId: Scalars['String'];
};


export type MutationDeleteSubscriptionsByAssetIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteVarianceNoteArgs = {
  input?: InputMaybe<DeleteVarianceNoteInput>;
};


export type MutationDisableFeatureForOrganizationArgs = {
  input: DisableFeatureForOrganizationInput;
};


export type MutationDisableSourcesForBuildingArgs = {
  input: EnableSourcesInput;
};


export type MutationEnableFeatureForOrganizationArgs = {
  input: EnableFeatureForOrganizationInput;
};


export type MutationEnableSourcesForBuildingArgs = {
  input: EnableSourcesInput;
};


export type MutationGeneratePathwayApiKeyArgs = {
  input: GeneratePathwayApiKeyInput;
};


export type MutationImportMeasureArgs = {
  input: ImportMeasureInput;
};


export type MutationImportMeasureAssignedActivityArgs = {
  input: ImportMeasureAssignedActivityInput;
};


export type MutationImportMeasureCommentActivityArgs = {
  input: ImportMeasureCommentActivityInput;
};


export type MutationImportMeasureCreatedActivityArgs = {
  input: ImportMeasureCreatedActivityInput;
};


export type MutationImportMeasureStatusTransitionActivityArgs = {
  input: ImportMeasureStatusTransitionActivityInput;
};


export type MutationModifyScheduleExceptionArgs = {
  input: ModifyScheduleExceptionInput;
};


export type MutationMoveBillToBuildingArgs = {
  input?: InputMaybe<MoveBillToBuildingInput>;
};


export type MutationMoveBuildingArgs = {
  input: MoveBuildingInput;
};


export type MutationMoveEquipmentToBuildingArgs = {
  input: MoveEquipmentToBuildingInput;
};


export type MutationMoveMeasuresForBuildingToOrganizationArgs = {
  input: MoveMeasuresForBuildingToOrganizationInput;
};


export type MutationMoveMeterArgs = {
  input: MeterMoveInput;
};


export type MutationMoveMeterToServiceAccountArgs = {
  meterId: Scalars['String'];
  serviceAccountId: Scalars['String'];
};


export type MutationPublishMeasureArgs = {
  id: Scalars['ID'];
};


export type MutationReRegisterS2Args = {
  id: Scalars['String'];
};


export type MutationRegisterBuildingOrganizationRelationshipArgs = {
  buildingId: Scalars['String'];
  organizationId: Scalars['String'];
  relationshipType: BuildingOrganizationRelationshipType;
};


export type MutationRegisterBuildingWithSpaceGroupArgs = {
  buildingId: Scalars['String'];
  spaceGroupId: Scalars['String'];
};


export type MutationRegisterIngestPathwayArgs = {
  input: IngestPathwayRegistrationInput;
};


export type MutationRegisterIngestPointArgs = {
  input: IngestPointRegistrationInput;
};


export type MutationRegisterNewBuildingEquipmentGroupArgs = {
  buildingId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRegisterNewBuildingWithOrganizationArgs = {
  includeInGroupAggregate?: InputMaybe<Scalars['Boolean']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  relationshipType?: InputMaybe<BuildingOrganizationRelationshipType>;
  sqft?: InputMaybe<Scalars['Float']>;
  timezone: Scalars['String'];
  weatherStationId?: InputMaybe<Scalars['String']>;
};


export type MutationRegisterNewEnterpriseUserArgs = {
  input: EnterpriseUserRegistrationInput;
};


export type MutationRegisterNewEquipmentArgs = {
  input: RegisterNewEquipmentInput;
};


export type MutationRegisterNewEquipmentWithBuildingArgs = {
  input: RegisterNewEquipmentWithBuildingInput;
};


export type MutationRegisterNewMeterGroupWithBuildingArgs = {
  input: MeterGroupRegistrationInput;
};


export type MutationRegisterNewMeterWithBuildingArgs = {
  buildingId: Scalars['String'];
  commodityName: CommodityName;
  customerMeterId: Scalars['String'];
  meterAggregateTypes?: InputMaybe<Array<InputMaybe<MeterAggregateType>>>;
  name: Scalars['String'];
};


export type MutationRegisterNewPointWithEquipmentArgs = {
  input: RegisterNewPointWithEquipmentInput;
};


export type MutationRegisterNewPointWithMeterArgs = {
  customerPointId: Scalars['String'];
  ingestPointId?: InputMaybe<Scalars['String']>;
  lowerSpikeThreshold?: InputMaybe<Scalars['Int']>;
  meterId: Scalars['String'];
  name: Scalars['String'];
  spikeThreshold?: InputMaybe<Scalars['Int']>;
  type: PointType;
};


export type MutationRegisterNewSpaceGroupArgs = {
  customerSpaceGroupId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['String'];
  type?: InputMaybe<SpaceGroupType>;
};


export type MutationRegisterNewUserArgs = {
  input: UserRegistrationInput;
};


export type MutationRegisterNewVirtualPointWithMeterArgs = {
  customerPointId?: InputMaybe<Scalars['String']>;
  lowerSpikeThreshold?: InputMaybe<Scalars['Int']>;
  meterId: Scalars['String'];
  name: Scalars['String'];
  sourcePointId: Scalars['String'];
  spikeThreshold?: InputMaybe<Scalars['Int']>;
  transformationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: PointType;
};


export type MutationRegisterOdasIngestPointsArgs = {
  input: RegisterOdasIngestPointsInput;
};


export type MutationRegisterOrganizationArgs = {
  customerOrganizationId?: InputMaybe<Scalars['String']>;
  hasGobyIntegration?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationRegisterPathwayPointSetArgs = {
  input: RegisterPathwayPointSetInput;
};


export type MutationRegisterPrismCredentialsForOrganizationArgs = {
  organizationId: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRegisterS2Args = {
  input: S2RegistrationInput;
};


export type MutationRemoveBuildingFromAllUsersArgs = {
  buildingId: Scalars['String'];
};


export type MutationRemoveBuildingFromGroupArgs = {
  input: RemoveBuildingFromGroupInput;
};


export type MutationRemoveBuildingOrganizationRelationshipArgs = {
  id: Scalars['String'];
};


export type MutationRemoveBuildingsFromUserArgs = {
  input?: InputMaybe<RemoveBuildingsFromUserInput>;
};


export type MutationRemoveEquipmentFromBuildingArgs = {
  input: RemoveEquipmentFromBuildingInput;
};


export type MutationRemoveEquipmentFromBuildingEquipmentGroupArgs = {
  equipmentId: Scalars['String'];
  groupId: Scalars['String'];
};


export type MutationRemoveEquipmentFromParentArgs = {
  input?: InputMaybe<RemoveEquipmentFromParentInput>;
};


export type MutationRemoveIntegrationMappingArgs = {
  integrationMappingId: Scalars['ID'];
};


export type MutationRemoveLabelFromMeasureArgs = {
  input: MeasureLabelInput;
};


export type MutationRemoveMeterFromMeterGroupArgs = {
  groupId: Scalars['String'];
  meterId: Scalars['String'];
};


export type MutationRemoveMeterFromServiceAccountArgs = {
  meterId: Scalars['String'];
  serviceAccountId: Scalars['String'];
};


export type MutationRemoveRoleFromUserArgs = {
  input: RemoveRoleFromUserInput;
};


export type MutationRemoveSpaceGroupFromParentArgs = {
  input: RemoveSpaceGroupFromParentInput;
};


export type MutationRemoveSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationRenameBuildingEquipmentGroupArgs = {
  groupId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationRestoreDemandManagementRelayArgs = {
  input?: InputMaybe<RestoreDemandManagementRelayInput>;
};


export type MutationRevokePathwayApiKeyArgs = {
  input: RevokePathwayApiKeyInput;
};


export type MutationScheduleBaselineJobArgs = {
  input: ScheduleBaselineJobInput;
};


export type MutationScheduleNewCurtailmentEventArgs = {
  event?: InputMaybe<ScheduleNewCurtailmentEventInput>;
};


export type MutationSendWelcomeEmailArgs = {
  input: SendWelcomeEmailInput;
};


export type MutationSetBuildingBlendedRatesArgs = {
  input: SetBuildingBlendedRatesInput;
};


export type MutationSetBuildingOccupancyArgs = {
  input?: InputMaybe<BuildingOccupancyInput>;
};


export type MutationSetBuildingThresholdArgs = {
  input: SetBuildingThresholdInput;
};


export type MutationSetCalendarYearCommodityBudgetForBuildingArgs = {
  input: SetCalendarYearCommodityBudgetForBuildingInput;
};


export type MutationSetOperatingPeriodsForBuildingOperatingScheduleArgs = {
  operatingPeriods: Array<OperatingPeriodInput>;
  scheduleId: Scalars['String'];
};


export type MutationSetPasswordForIngestPathwayArgs = {
  input: SetPasswordForIngestPathwayInput;
};


export type MutationSetVarianceNoteArgs = {
  input?: InputMaybe<SetVarianceNoteInput>;
};


export type MutationStartSupplyServiceArgs = {
  input: StartSupplyServiceInput;
};


export type MutationSubscribeUserToConsolidatedReportArgs = {
  input: SubscribeUserToConsolidatedReportInput;
};


export type MutationSubscribeUserToMultipleReportsArgs = {
  input: SubscribeUserMultipleInput;
};


export type MutationSubscribeUserToReportArgs = {
  input: SubscribeUserInput;
};


export type MutationSuppressMeasureArgs = {
  id: Scalars['ID'];
};


export type MutationTerminateSupplyServiceArgs = {
  input: TerminateSupplyServiceInput;
};


export type MutationTransitionMeasureArgs = {
  input: TransitionMeasureInput;
};


export type MutationUpdateBaselineModelArgs = {
  input: UpdateBaselineModelInput;
};


export type MutationUpdateBillArgs = {
  input?: InputMaybe<ConsolidatedBillUpdateInput>;
};


export type MutationUpdateBillManagementArgs = {
  input: BillManagementUpdateInput;
};


export type MutationUpdateBuildingArgs = {
  input: BuildingUpdateInput;
};


export type MutationUpdateEquipmentArgs = {
  input?: InputMaybe<UpdateEquipmentInput>;
};


export type MutationUpdateEquipmentPointArgs = {
  input: UpdateEquipmentPointInput;
};


export type MutationUpdateFeatureEnablementArgs = {
  input: BuildingFeatureEnablementInput;
};


export type MutationUpdateIngestPointArgs = {
  input: IngestPointUpdateInput;
};


export type MutationUpdateMeasureDefaultAssigneeForBuildingArgs = {
  input: UpdateMeasureDefaultAssigneeForBuildingInput;
};


export type MutationUpdateMeasureDetailsArgs = {
  input: UpdateMeasureDetailsInput;
};


export type MutationUpdateMeasureImplementedDateArgs = {
  input: UpdateMeasureImplementedDateInput;
};


export type MutationUpdateMeterArgs = {
  input: MeterUpdateInput;
};


export type MutationUpdateMeterGroupArgs = {
  input: MeterGroupUpdateInput;
};


export type MutationUpdateOrganizationArgs = {
  input: OrganizationUpdateInput;
};


export type MutationUpdatePathwayPointSetArgs = {
  input: UpdatePathwayPointSetInput;
};


export type MutationUpdatePointArgs = {
  input: PointUpdateInput;
};


export type MutationUpdatePointThresholdArgs = {
  lowerSpikeThreshold?: InputMaybe<Scalars['Int']>;
  pointId: Scalars['String'];
  spikeThreshold?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateServiceAccountArgs = {
  serviceAccount: UpdateServiceAccountInput;
};


export type MutationUpdateSpaceGroupArgs = {
  input: SpaceGroupUpdateInput;
};


export type MutationUpdateUserAttributesArgs = {
  input: UserUpdateInput;
};


export type MutationUpdateUserPreferencesArgs = {
  preferences: UserPreferencesInput;
  userId: Scalars['String'];
};

export enum NaturalGasEnergyUnits {
  BRITISH_THERMAL_UNITS = 'BRITISH_THERMAL_UNITS',
  KILOJOULES = 'KILOJOULES'
}

export enum NaturalGasUnits {
  CENTUM_CUBIC_FEET = 'CENTUM_CUBIC_FEET',
  CUBIC_FEET = 'CUBIC_FEET',
  CUBIC_METERS = 'CUBIC_METERS'
}

export enum OadPositionCommandAtFanSpeedUnits {
  PERCENT = 'PERCENT'
}

export enum OadPositionCommandAtMaxFanSpeedUnits {
  PERCENT = 'PERCENT'
}

export enum OadPositionCommandAtMinFanSpeedUnits {
  PERCENT = 'PERCENT'
}

export type OccupancyInput = {
  estimatedGarageUsage?: InputMaybe<Scalars['Float']>;
  month: Month;
  occupancy?: InputMaybe<Scalars['Float']>;
  occupancyId?: InputMaybe<Scalars['String']>;
  overtimeHVACHours?: InputMaybe<Scalars['Float']>;
  overtimeHVACUsage?: InputMaybe<Scalars['Float']>;
  tenantBilled?: InputMaybe<Scalars['Float']>;
  tenantUsage?: InputMaybe<Scalars['Float']>;
};

export type OccupancyResponse = {
  __typename?: 'OccupancyResponse';
  createdAt: Scalars['String'];
  enteredBy: User;
  estimatedGarageUsage?: Maybe<Scalars['Float']>;
  month: Month;
  occupancy?: Maybe<Scalars['Float']>;
  occupancyId: Scalars['String'];
  overtimeHVACHours?: Maybe<Scalars['Float']>;
  overtimeHVACUsage?: Maybe<Scalars['Float']>;
  tenantBilled?: Maybe<Scalars['Float']>;
  tenantUsage?: Maybe<Scalars['Float']>;
};

export type OccupancyScheduleInput = {
  applicability: TimeRangeInput;
  exceptionOperatingPeriods: Array<ExceptionOperatingPeriodInput>;
  operatingPeriods: Array<InputMaybe<OperatingPeriodInput>>;
};

export type Odas = {
  __typename?: 'Odas';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  modbusDevices: Array<Maybe<OdasModbusDevice>>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  passwordHash?: Maybe<Scalars['String']>;
};

export type OdasIngestPoint = {
  __typename?: 'OdasIngestPoint';
  createdAt?: Maybe<Scalars['DateTime']>;
  cumulative: Scalars['Boolean'];
  cumulativeMaxValue?: Maybe<CumulativeMaxValue>;
  id: Scalars['String'];
  interval?: Maybe<Interval>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  multiplier?: Maybe<Scalars['Float']>;
  pointNum: Scalars['Int'];
  unit: IngestUnitOfMeasurement;
};

export type OdasIngestPointInput = {
  cumulative: Scalars['Boolean'];
  cumulativeMaxValue?: InputMaybe<CumulativeMaxValue>;
  interval: Interval;
  multiplier?: InputMaybe<Scalars['Float']>;
  pointNum: Scalars['Int'];
  unit: IngestUnit;
  unitOverride?: InputMaybe<Scalars['String']>;
};

export type OdasModbusDevice = {
  __typename?: 'OdasModbusDevice';
  deviceNum: Scalars['Int'];
  id: Scalars['String'];
  ingestPoints: Array<Maybe<OdasIngestPoint>>;
  name: Scalars['String'];
};

export type OdasModbusDeviceInput = {
  deviceNum: Scalars['Int'];
  ingestPointsToRegister: Array<OdasIngestPointInput>;
};

export enum OffPidLimitUnits {
  PERCENT = 'PERCENT'
}

export enum OilTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum OnPidLimitUnits {
  PERCENT = 'PERCENT'
}

export enum OneThirdPositionCommandUnits {
  PERCENT = 'PERCENT'
}

export enum OneThirdPositionUnits {
  PERCENT = 'PERCENT'
}

export type OperatingPeriod = {
  __typename?: 'OperatingPeriod';
  endDay: DayOfWeek;
  /** Exclusive end time in HH:MM format */
  endTime: Scalars['String'];
  startDay: DayOfWeek;
  /** Inclusive start time in HH:MM format */
  startTime: Scalars['String'];
};

export type OperatingPeriodInput = {
  endDay: DayOfWeek;
  /** Exclusive end time in HH:MM format */
  endTime: Scalars['String'];
  startDay: DayOfWeek;
  /** Inclusive start time in HH:MM format */
  startTime: Scalars['String'];
};

export type OperatingSchedule = {
  __typename?: 'OperatingSchedule';
  friday?: Maybe<DailyOperatingTimeBlock>;
  monday?: Maybe<DailyOperatingTimeBlock>;
  saturday?: Maybe<DailyOperatingTimeBlock>;
  scheduleId?: Maybe<Scalars['String']>;
  sunday?: Maybe<DailyOperatingTimeBlock>;
  thursday?: Maybe<DailyOperatingTimeBlock>;
  tuesday?: Maybe<DailyOperatingTimeBlock>;
  wednesday?: Maybe<DailyOperatingTimeBlock>;
};

export type Organization = {
  __typename?: 'Organization';
  availableUtilityData: AvailableUtilityDataResponse;
  /** @deprecated Use enablements instead */
  billingIntegrationUrl?: Maybe<Scalars['String']>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  buildingsForSources?: Maybe<Array<Maybe<Building>>>;
  customerOrganizationId?: Maybe<Scalars['String']>;
  enablements?: Maybe<Array<Enablement>>;
  /** @deprecated Use enablements instead */
  hasGobyIntegration?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  measures?: Maybe<Measures>;
  name: Scalars['String'];
  usageVsBaselineForPeriod?: Maybe<UsageVsBaselineReportData>;
  users: Array<User>;
  utilityData?: Maybe<YearlyUtilityData>;
};


export type OrganizationMeasuresArgs = {
  cursor?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<MeasureFilters>;
  sort?: InputMaybe<MeasuresSort>;
  unitPreferences?: InputMaybe<PreferredUnitsInput>;
};


export type OrganizationUsageVsBaselineForPeriodArgs = {
  period: UsageVsBaselineReportPeriod;
  pointType: PointType;
  timezone: Scalars['String'];
  unit?: InputMaybe<Unit>;
};


export type OrganizationUtilityDataArgs = {
  commodityName: CommodityName;
  year: Scalars['Int'];
};

export type OrganizationUpdateInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type OrganizationUpdatePayload = {
  __typename?: 'OrganizationUpdatePayload';
  code: Scalars['String'];
  message: Scalars['String'];
  organization?: Maybe<Organization>;
  success: Scalars['Boolean'];
};

export enum OutdoorAirTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum OutletIsolationCommandBinaryUnits {
  BINARY = 'BINARY'
}

export enum OutletIsolationStatusBinaryUnits {
  BINARY = 'BINARY'
}

export enum OutsideAirCo2ConcentrationUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export enum OutsideAirEnthalpyUnits {
  BRITISH_THERMAL_UNITS_PER_POUND = 'BRITISH_THERMAL_UNITS_PER_POUND',
  KILOJOULES_PER_KILOGRAM = 'KILOJOULES_PER_KILOGRAM'
}

export enum OutsideAirPercentageUnits {
  PERCENT = 'PERCENT'
}

export enum OutsideAirTemperatureHighLockoutCommandUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum OutsideAirTemperatureLockoutLimitUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum OutsideAirTemperatureLockoutSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum OutsideRelativeHumidityUnits {
  PERCENT = 'PERCENT'
}

export type OwnerFacet = {
  __typename?: 'OwnerFacet';
  measures: Scalars['Int'];
  /** Owner may be null (unassigned / unknown) */
  owner?: Maybe<User>;
};

export type PaginationInput = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type PathwayApiKey = {
  __typename?: 'PathwayApiKey';
  endingWith: Scalars['String'];
  generatedAt: Scalars['DateTime'];
  hash: Scalars['String'];
  id: Scalars['String'];
  issuedToOrganizationId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  prefix: Scalars['String'];
  usagePlanLevel: ApiUsagePlanLevel;
};

export type PathwayPointSet = {
  __typename?: 'PathwayPointSet';
  createdAt?: Maybe<Scalars['DateTime']>;
  diagnostics: Diagnostics;
  id: Scalars['String'];
  ingestPathway?: Maybe<IngestPathway>;
  ingestPoints?: Maybe<IngestPoints>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};


export type PathwayPointSetIngestPointsArgs = {
  input: PaginationInput;
};

export type PathwayPointSets = {
  __typename?: 'PathwayPointSets';
  hasMorePages: Scalars['Boolean'];
  page: Array<PathwayPointSet>;
};

export type Period = {
  __typename?: 'Period';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type PeriodInput = {
  end: Scalars['String'];
  start: Scalars['String'];
};

export enum PidLoopOutputUnits {
  PERCENT = 'PERCENT'
}

export type Point = PointInterface & {
  __typename?: 'Point';
  customerPointId: Scalars['String'];
  diagnostics?: Maybe<PointDiagnostics>;
  id: Scalars['String'];
  ingestPointId?: Maybe<Scalars['String']>;
  /** @deprecated This will be replaced in favor of the lastReceiveTime in the point diagnostics type */
  lastReceiveTime?: Maybe<Scalars['DateTime']>;
  lowerSpikeThreshold?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pointData: IntervalData;
  spikeThreshold?: Maybe<Scalars['Int']>;
  transformations: Array<Maybe<PointTransformation>>;
  type: PointType;
};


export type PointPointDataArgs = {
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  unitPreferences?: InputMaybe<PreferredUnitsInput>;
};

export type PointDiagnostics = {
  __typename?: 'PointDiagnostics';
  lastDefinedTime?: Maybe<Scalars['DateTime']>;
  lastNonZeroTime?: Maybe<Scalars['DateTime']>;
  lastReceiveTime?: Maybe<Scalars['DateTime']>;
  lastUpdateTime?: Maybe<Scalars['DateTime']>;
};

export type PointInterface = {
  customerPointId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ingestPointId?: Maybe<Scalars['String']>;
  /** @deprecated This will be replaced in favor of the lastReceiveTime in the point diagnostics type */
  lastReceiveTime?: Maybe<Scalars['DateTime']>;
  lowerSpikeThreshold?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pointData: IntervalData;
  spikeThreshold?: Maybe<Scalars['Int']>;
  transformations: Array<Maybe<PointTransformation>>;
  type: PointType;
};


export type PointInterfacePointDataArgs = {
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  unitPreferences?: InputMaybe<PreferredUnitsInput>;
};

export type PointTransformation = {
  __typename?: 'PointTransformation';
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum PointType {
  ALWAYS_ENABLED = 'ALWAYS_ENABLED',
  BOILER_EFFICIENCY = 'BOILER_EFFICIENCY',
  BOILER_LOAD = 'BOILER_LOAD',
  BOILER_PLANT_ENERGY_USAGE = 'BOILER_PLANT_ENERGY_USAGE',
  BYPASS_COMMAND_BINARY = 'BYPASS_COMMAND_BINARY',
  BYPASS_POSITION = 'BYPASS_POSITION',
  BYPASS_POSITION_COMMAND = 'BYPASS_POSITION_COMMAND',
  BYPASS_STATUS_BINARY = 'BYPASS_STATUS_BINARY',
  CARBON_MONOXIDE = 'CARBON_MONOXIDE',
  CARBON_MONOXIDE_SETPOINT = 'CARBON_MONOXIDE_SETPOINT',
  CHILLED_WATER_USAGE = 'CHILLED_WATER_USAGE',
  CHILLER_EFFICIENCY = 'CHILLER_EFFICIENCY',
  CHILLER_LOAD = 'CHILLER_LOAD',
  COMBUSTION_AIR_PRESSURE = 'COMBUSTION_AIR_PRESSURE',
  COMBUSTION_TEMPERATURE = 'COMBUSTION_TEMPERATURE',
  COMMAND_BINARY = 'COMMAND_BINARY',
  CONDENSER_TEMPERATURE = 'CONDENSER_TEMPERATURE',
  COOLDOWN_BINARY = 'COOLDOWN_BINARY',
  COOLING_CAPACITY = 'COOLING_CAPACITY',
  COOLING_PID_LOOP_OUTPUT = 'COOLING_PID_LOOP_OUTPUT',
  COOLING_PID_OUTPUT = 'COOLING_PID_OUTPUT',
  DIFFERENTIAL_PRESSURE = 'DIFFERENTIAL_PRESSURE',
  DIFFERENTIAL_PRESSURE_SETPOINT = 'DIFFERENTIAL_PRESSURE_SETPOINT',
  DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MAX_SETPOINT = 'DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MAX_SETPOINT',
  DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MIN_SETPOINT = 'DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MIN_SETPOINT',
  DISCHARGE_AIR_FLOW_RATE_MAX_SETPOINT = 'DISCHARGE_AIR_FLOW_RATE_MAX_SETPOINT',
  DISCHARGE_AIR_FLOW_RATE_MIN_SETPOINT = 'DISCHARGE_AIR_FLOW_RATE_MIN_SETPOINT',
  DISCHARGE_AIR_TEMPERATURE = 'DISCHARGE_AIR_TEMPERATURE',
  DISCHARGE_AIR_TEMPERATURE_SETPOINT = 'DISCHARGE_AIR_TEMPERATURE_SETPOINT',
  ECONOMIZER_MINIMUM_LOCKOUT_TEMPERATURE = 'ECONOMIZER_MINIMUM_LOCKOUT_TEMPERATURE',
  ECONOMIZER_PID_OUTPUT = 'ECONOMIZER_PID_OUTPUT',
  ELECTRICITY_DEMAND = 'ELECTRICITY_DEMAND',
  ELECTRICITY_USAGE = 'ELECTRICITY_USAGE',
  ELECTRIC_CURRENT = 'ELECTRIC_CURRENT',
  ENTERING_TEMPERATURE = 'ENTERING_TEMPERATURE',
  ENTERING_WATER_TEMPERATURE = 'ENTERING_WATER_TEMPERATURE',
  EVAPORATOR_TEMPERATURE = 'EVAPORATOR_TEMPERATURE',
  EXHAUST_AIR_TEMPERATURE = 'EXHAUST_AIR_TEMPERATURE',
  EXTERIOR_SPACE_TEMPERATURE = 'EXTERIOR_SPACE_TEMPERATURE',
  FAN_SPEED_SETPOINT = 'FAN_SPEED_SETPOINT',
  FIRING_PERCENT = 'FIRING_PERCENT',
  FLOW_RATE = 'FLOW_RATE',
  FLOW_RATE_SETPOINT = 'FLOW_RATE_SETPOINT',
  FREE_COOLING_COMMAND_BINARY = 'FREE_COOLING_COMMAND_BINARY',
  FREE_COOLING_STATUS_BINARY = 'FREE_COOLING_STATUS_BINARY',
  HEATING_OIL_USAGE = 'HEATING_OIL_USAGE',
  HEATING_PID_LOOP_OUTPUT = 'HEATING_PID_LOOP_OUTPUT',
  HEATING_PID_OUTPUT = 'HEATING_PID_OUTPUT',
  HOLIDAY_ACTIVE_BINARY = 'HOLIDAY_ACTIVE_BINARY',
  HOT_WATER_USAGE = 'HOT_WATER_USAGE',
  HUMIDITY = 'HUMIDITY',
  INLET_ISOLATION_COMMAND_BINARY = 'INLET_ISOLATION_COMMAND_BINARY',
  INLET_ISOLATION_STATUS_BINARY = 'INLET_ISOLATION_STATUS_BINARY',
  INTERIOR_SPACE_TEMPERATURE = 'INTERIOR_SPACE_TEMPERATURE',
  ISOLATION_COMMAND_BINARY = 'ISOLATION_COMMAND_BINARY',
  ISOLATION_STATUS_BINARY = 'ISOLATION_STATUS_BINARY',
  LEAVING_TEMPERATURE = 'LEAVING_TEMPERATURE',
  LEAVING_TEMPERATURE_SETPOINT = 'LEAVING_TEMPERATURE_SETPOINT',
  LEAVING_WATER_TEMPERATURE = 'LEAVING_WATER_TEMPERATURE',
  LEAVING_WATER_TEMPERATURE_SETPOINT = 'LEAVING_WATER_TEMPERATURE_SETPOINT',
  LOW_LEVEL_SETPOINT = 'LOW_LEVEL_SETPOINT',
  MAKEUP_COMMAND_BINARY = 'MAKEUP_COMMAND_BINARY',
  MAKEUP_POSITION = 'MAKEUP_POSITION',
  MAKEUP_POSITION_COMMAND = 'MAKEUP_POSITION_COMMAND',
  MAKEUP_STATUS_BINARY = 'MAKEUP_STATUS_BINARY',
  MAX_SPEED_COMMAND = 'MAX_SPEED_COMMAND',
  MINIMUM_LOCKOUT_TEMPERATURE = 'MINIMUM_LOCKOUT_TEMPERATURE',
  MINIMUM_OUTSIDE_AIR_FLOW_RATE_SETPOINT = 'MINIMUM_OUTSIDE_AIR_FLOW_RATE_SETPOINT',
  MINIMUM_POSITION_SETPOINT = 'MINIMUM_POSITION_SETPOINT',
  MIN_SPEED_COMMAND = 'MIN_SPEED_COMMAND',
  MIXED_TEMPERATURE = 'MIXED_TEMPERATURE',
  MIXED_TEMPERATURE_SETPOINT = 'MIXED_TEMPERATURE_SETPOINT',
  NATURAL_GAS_ENERGY = 'NATURAL_GAS_ENERGY',
  NATURAL_GAS_USAGE = 'NATURAL_GAS_USAGE',
  OAD_POSITION_COMMAND_AT_FAN_SPEED = 'OAD_POSITION_COMMAND_AT_FAN_SPEED',
  OAD_POSITION_COMMAND_AT_MAX_FAN_SPEED = 'OAD_POSITION_COMMAND_AT_MAX_FAN_SPEED',
  OAD_POSITION_COMMAND_AT_MIN_FAN_SPEED = 'OAD_POSITION_COMMAND_AT_MIN_FAN_SPEED',
  OFF_PID_LIMIT = 'OFF_PID_LIMIT',
  OIL_TEMPERATURE = 'OIL_TEMPERATURE',
  ONE_THIRD_POSITION = 'ONE_THIRD_POSITION',
  ONE_THIRD_POSITION_COMMAND = 'ONE_THIRD_POSITION_COMMAND',
  ON_PID_LIMIT = 'ON_PID_LIMIT',
  OUTDOOR_AIR_TEMPERATURE = 'OUTDOOR_AIR_TEMPERATURE',
  OUTLET_ISOLATION_COMMAND_BINARY = 'OUTLET_ISOLATION_COMMAND_BINARY',
  OUTLET_ISOLATION_STATUS_BINARY = 'OUTLET_ISOLATION_STATUS_BINARY',
  OUTSIDE_AIR_CO2_CONCENTRATION = 'OUTSIDE_AIR_CO2_CONCENTRATION',
  OUTSIDE_AIR_ENTHALPY = 'OUTSIDE_AIR_ENTHALPY',
  OUTSIDE_AIR_PERCENTAGE = 'OUTSIDE_AIR_PERCENTAGE',
  OUTSIDE_AIR_TEMPERATURE_HIGH_LOCKOUT_COMMAND = 'OUTSIDE_AIR_TEMPERATURE_HIGH_LOCKOUT_COMMAND',
  OUTSIDE_AIR_TEMPERATURE_LOCKOUT_LIMIT = 'OUTSIDE_AIR_TEMPERATURE_LOCKOUT_LIMIT',
  OUTSIDE_AIR_TEMPERATURE_LOCKOUT_SETPOINT = 'OUTSIDE_AIR_TEMPERATURE_LOCKOUT_SETPOINT',
  OUTSIDE_RELATIVE_HUMIDITY = 'OUTSIDE_RELATIVE_HUMIDITY',
  PID_LOOP_OUTPUT = 'PID_LOOP_OUTPUT',
  POSITION = 'POSITION',
  POSITION_COMMAND = 'POSITION_COMMAND',
  PROPANE_USAGE = 'PROPANE_USAGE',
  PURGE_BINARY = 'PURGE_BINARY',
  RELATIVE_HUMIDITY = 'RELATIVE_HUMIDITY',
  RELATIVE_HUMIDITY_SETPOINT = 'RELATIVE_HUMIDITY_SETPOINT',
  RETURN_AIR_CO2_CONCENTRATION = 'RETURN_AIR_CO2_CONCENTRATION',
  RETURN_AIR_CO2_CONCENTRATION_SETPOINT = 'RETURN_AIR_CO2_CONCENTRATION_SETPOINT',
  RETURN_AIR_ENTHALPY = 'RETURN_AIR_ENTHALPY',
  RETURN_AIR_RELATIVE_HUMIDITY = 'RETURN_AIR_RELATIVE_HUMIDITY',
  RETURN_AIR_RELATIVE_HUMIDITY_SETPOINT = 'RETURN_AIR_RELATIVE_HUMIDITY_SETPOINT',
  RETURN_AIR_TEMPERATURE = 'RETURN_AIR_TEMPERATURE',
  RETURN_AIR_TEMPERATURE_SETPOINT = 'RETURN_AIR_TEMPERATURE_SETPOINT',
  RETURN_AIR_TEMPERATURE_SETPOINT_HIGH_LIMIT = 'RETURN_AIR_TEMPERATURE_SETPOINT_HIGH_LIMIT',
  RETURN_AIR_TEMPERATURE_SETPOINT_LOW_LIMIT = 'RETURN_AIR_TEMPERATURE_SETPOINT_LOW_LIMIT',
  RETURN_CONDENSATE_TEMPERATURE = 'RETURN_CONDENSATE_TEMPERATURE',
  RETURN_WATER_FLOW_RATE = 'RETURN_WATER_FLOW_RATE',
  RETURN_WATER_TEMPERATURE = 'RETURN_WATER_TEMPERATURE',
  RETURN_WATER_TEMPERATURE_SETPOINT = 'RETURN_WATER_TEMPERATURE_SETPOINT',
  RUNTIME_LIFETIME_CUMULATIVE = 'RUNTIME_LIFETIME_CUMULATIVE',
  RUNTIME_YEARLY_CUMULATIVE = 'RUNTIME_YEARLY_CUMULATIVE',
  SCHEDULE_ACTIVE_BINARY = 'SCHEDULE_ACTIVE_BINARY',
  SCHEDULE_BINARY = 'SCHEDULE_BINARY',
  SPACE_AIR_CO2_CONCENTRATION = 'SPACE_AIR_CO2_CONCENTRATION',
  SPACE_AIR_CO2_CONCENTRATION_SETPOINT = 'SPACE_AIR_CO2_CONCENTRATION_SETPOINT',
  SPACE_RELATIVE_HUMIDITY = 'SPACE_RELATIVE_HUMIDITY',
  SPACE_RELATIVE_HUMIDITY_SETPOINT = 'SPACE_RELATIVE_HUMIDITY_SETPOINT',
  SPACE_STATIC_PRESSURE = 'SPACE_STATIC_PRESSURE',
  SPACE_STATIC_PRESSURE_SETPOINT = 'SPACE_STATIC_PRESSURE_SETPOINT',
  SPACE_TEMPERATURE = 'SPACE_TEMPERATURE',
  SPACE_TEMPERATURE_SETPOINT = 'SPACE_TEMPERATURE_SETPOINT',
  SPACE_TEMPERATURE_SETPOINT_COOLING = 'SPACE_TEMPERATURE_SETPOINT_COOLING',
  SPACE_TEMPERATURE_SETPOINT_HEATING = 'SPACE_TEMPERATURE_SETPOINT_HEATING',
  SPACE_TEMPERATURE_SETPOINT_RESET_INCREMENT_LIMIT = 'SPACE_TEMPERATURE_SETPOINT_RESET_INCREMENT_LIMIT',
  SPEED = 'SPEED',
  SPEED_COMMAND = 'SPEED_COMMAND',
  STATIC_PRESSURE = 'STATIC_PRESSURE',
  STATIC_PRESSURE_SETPOINT = 'STATIC_PRESSURE_SETPOINT',
  STATIC_PRESSURE_SETPOINT_HIGH_LIMIT = 'STATIC_PRESSURE_SETPOINT_HIGH_LIMIT',
  STATIC_PRESSURE_SETPOINT_LOW_LIMIT = 'STATIC_PRESSURE_SETPOINT_LOW_LIMIT',
  STATUS_BINARY = 'STATUS_BINARY',
  STEAM_MASS = 'STEAM_MASS',
  STEAM_PRESSURE = 'STEAM_PRESSURE',
  STEAM_PRESSURE_SETPOINT = 'STEAM_PRESSURE_SETPOINT',
  STEAM_TEMPERATURE = 'STEAM_TEMPERATURE',
  STEAM_USAGE = 'STEAM_USAGE',
  SUPPLY_AIR_CO2_CONCENTRATION = 'SUPPLY_AIR_CO2_CONCENTRATION',
  SUPPLY_AIR_CO2_CONCENTRATION_SETPOINT = 'SUPPLY_AIR_CO2_CONCENTRATION_SETPOINT',
  SUPPLY_AIR_RELATIVE_HUMIDITY = 'SUPPLY_AIR_RELATIVE_HUMIDITY',
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_HIGH_LIMIT = 'SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_HIGH_LIMIT',
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_LOW_LIMIT = 'SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_LOW_LIMIT',
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT = 'SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT',
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT = 'SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT',
  SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT = 'SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT',
  SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT = 'SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT',
  SUPPLY_TEMPERATURE = 'SUPPLY_TEMPERATURE',
  SUPPLY_TEMPERATURE_SETPOINT = 'SUPPLY_TEMPERATURE_SETPOINT',
  SUPPLY_TEMPERATURE_SETPOINT_DELTA = 'SUPPLY_TEMPERATURE_SETPOINT_DELTA',
  SUPPLY_TEMPERATURE_SETPOINT_HIGH_LIMIT = 'SUPPLY_TEMPERATURE_SETPOINT_HIGH_LIMIT',
  SUPPLY_TEMPERATURE_SETPOINT_LOW_LIMIT = 'SUPPLY_TEMPERATURE_SETPOINT_LOW_LIMIT',
  SUPPLY_WATER_FLOW_RATE = 'SUPPLY_WATER_FLOW_RATE',
  SUPPLY_WATER_TEMPERATURE = 'SUPPLY_WATER_TEMPERATURE',
  SUPPLY_WATER_TEMPERATURE_SETPOINT = 'SUPPLY_WATER_TEMPERATURE_SETPOINT',
  SWITCH_STATUS_BINARY = 'SWITCH_STATUS_BINARY',
  SYSTEM_ENABLE_OUTSIDE_AIR_TEMPERATURE_SETPOINT = 'SYSTEM_ENABLE_OUTSIDE_AIR_TEMPERATURE_SETPOINT',
  TANK_CAPACITY = 'TANK_CAPACITY',
  TEMPERATURE = 'TEMPERATURE',
  TWO_THIRD_POSITION = 'TWO_THIRD_POSITION',
  TWO_THIRD_POSITION_COMMAND = 'TWO_THIRD_POSITION_COMMAND',
  UNOCCUPIED_COOLING_SETPOINT = 'UNOCCUPIED_COOLING_SETPOINT',
  UNOCCUPIED_HEATING_SETPOINT = 'UNOCCUPIED_HEATING_SETPOINT',
  WARMUP_BINARY = 'WARMUP_BINARY',
  WATER_FLOW_RATE = 'WATER_FLOW_RATE',
  WATER_USAGE = 'WATER_USAGE',
  WET_BULB = 'WET_BULB',
  ZONE_OVERRIDE_COMMAND = 'ZONE_OVERRIDE_COMMAND',
  ZONE_RETURN_AIR_TEMPERATURE = 'ZONE_RETURN_AIR_TEMPERATURE'
}

export type PointUpdateInput = {
  customerPointId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  ingestPointId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PointUpdatePayload = {
  __typename?: 'PointUpdatePayload';
  point?: Maybe<PointInterface>;
};

export enum PositionCommandUnits {
  PERCENT = 'PERCENT'
}

export enum PositionUnits {
  PERCENT = 'PERCENT'
}

export type PreferredUnitsInput = {
  ALWAYS_ENABLED?: InputMaybe<AlwaysEnabledUnits>;
  BOILER_EFFICIENCY?: InputMaybe<BoilerEfficiencyUnits>;
  BOILER_LOAD?: InputMaybe<BoilerLoadUnits>;
  BOILER_PLANT_ENERGY_USAGE?: InputMaybe<BoilerPlantEnergyUsageUnits>;
  BYPASS_COMMAND_BINARY?: InputMaybe<BypassCommandBinaryUnits>;
  BYPASS_POSITION?: InputMaybe<BypassPositionUnits>;
  BYPASS_POSITION_COMMAND?: InputMaybe<BypassPositionCommandUnits>;
  BYPASS_STATUS_BINARY?: InputMaybe<BypassStatusBinaryUnits>;
  CARBON_MONOXIDE?: InputMaybe<CarbonMonoxideUnits>;
  CARBON_MONOXIDE_SETPOINT?: InputMaybe<CarbonMonoxideSetpointUnits>;
  CHILLED_WATER_USAGE?: InputMaybe<ChilledWaterUsageUnits>;
  CHILLER_EFFICIENCY?: InputMaybe<ChillerEfficiencyUnits>;
  CHILLER_LOAD?: InputMaybe<ChillerLoadUnits>;
  COMBUSTION_AIR_PRESSURE?: InputMaybe<CombustionAirPressureUnits>;
  COMBUSTION_TEMPERATURE?: InputMaybe<CombustionTemperatureUnits>;
  COMMAND_BINARY?: InputMaybe<CommandBinaryUnits>;
  CONDENSER_TEMPERATURE?: InputMaybe<CondenserTemperatureUnits>;
  COOLDOWN_BINARY?: InputMaybe<CooldownBinaryUnits>;
  COOLING_CAPACITY?: InputMaybe<CoolingCapacityUnits>;
  COOLING_PID_LOOP_OUTPUT?: InputMaybe<CoolingPidLoopOutputUnits>;
  COOLING_PID_OUTPUT?: InputMaybe<CoolingPidOutputUnits>;
  DIFFERENTIAL_PRESSURE?: InputMaybe<DifferentialPressureUnits>;
  DIFFERENTIAL_PRESSURE_SETPOINT?: InputMaybe<DifferentialPressureSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MAX_SETPOINT?: InputMaybe<DischargeAirFlowRateCoolingModeMaxSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MIN_SETPOINT?: InputMaybe<DischargeAirFlowRateCoolingModeMinSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_MAX_SETPOINT?: InputMaybe<DischargeAirFlowRateMaxSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_MIN_SETPOINT?: InputMaybe<DischargeAirFlowRateMinSetpointUnits>;
  DISCHARGE_AIR_TEMPERATURE?: InputMaybe<DischargeAirTemperatureUnits>;
  DISCHARGE_AIR_TEMPERATURE_SETPOINT?: InputMaybe<DischargeAirTemperatureSetpointUnits>;
  ECONOMIZER_MINIMUM_LOCKOUT_TEMPERATURE?: InputMaybe<EconomizerMinimumLockoutTemperatureUnits>;
  ECONOMIZER_PID_OUTPUT?: InputMaybe<EconomizerPidOutputUnits>;
  ELECTRICITY_DEMAND?: InputMaybe<ElectricityDemandUnits>;
  ELECTRICITY_USAGE?: InputMaybe<ElectricityUsageUnits>;
  ELECTRIC_CURRENT?: InputMaybe<ElectricCurrentUnits>;
  ENTERING_TEMPERATURE?: InputMaybe<EnteringTemperatureUnits>;
  ENTERING_WATER_TEMPERATURE?: InputMaybe<EnteringWaterTemperatureUnits>;
  EVAPORATOR_TEMPERATURE?: InputMaybe<EvaporatorTemperatureUnits>;
  EXHAUST_AIR_TEMPERATURE?: InputMaybe<ExhaustAirTemperatureUnits>;
  EXTERIOR_SPACE_TEMPERATURE?: InputMaybe<ExteriorSpaceTemperatureUnits>;
  FAN_SPEED_SETPOINT?: InputMaybe<FanSpeedSetpointUnits>;
  FIRING_PERCENT?: InputMaybe<FiringPercentUnits>;
  FLOW_RATE?: InputMaybe<FlowRateUnits>;
  FLOW_RATE_SETPOINT?: InputMaybe<FlowRateSetpointUnits>;
  FREE_COOLING_COMMAND_BINARY?: InputMaybe<FreeCoolingCommandBinaryUnits>;
  FREE_COOLING_STATUS_BINARY?: InputMaybe<FreeCoolingStatusBinaryUnits>;
  HEATING_OIL_USAGE?: InputMaybe<HeatingOilUsageUnits>;
  HEATING_PID_LOOP_OUTPUT?: InputMaybe<HeatingPidLoopOutputUnits>;
  HEATING_PID_OUTPUT?: InputMaybe<HeatingPidOutputUnits>;
  HOLIDAY_ACTIVE_BINARY?: InputMaybe<HolidayActiveBinaryUnits>;
  HOT_WATER_USAGE?: InputMaybe<HotWaterUsageUnits>;
  HUMIDITY?: InputMaybe<HumidityUnits>;
  INLET_ISOLATION_COMMAND_BINARY?: InputMaybe<InletIsolationCommandBinaryUnits>;
  INLET_ISOLATION_STATUS_BINARY?: InputMaybe<InletIsolationStatusBinaryUnits>;
  INTERIOR_SPACE_TEMPERATURE?: InputMaybe<InteriorSpaceTemperatureUnits>;
  ISOLATION_COMMAND_BINARY?: InputMaybe<IsolationCommandBinaryUnits>;
  ISOLATION_STATUS_BINARY?: InputMaybe<IsolationStatusBinaryUnits>;
  LEAVING_TEMPERATURE?: InputMaybe<LeavingTemperatureUnits>;
  LEAVING_TEMPERATURE_SETPOINT?: InputMaybe<LeavingTemperatureSetpointUnits>;
  LEAVING_WATER_TEMPERATURE?: InputMaybe<LeavingWaterTemperatureUnits>;
  LEAVING_WATER_TEMPERATURE_SETPOINT?: InputMaybe<LeavingWaterTemperatureSetpointUnits>;
  LOW_LEVEL_SETPOINT?: InputMaybe<LowLevelSetpointUnits>;
  MAKEUP_COMMAND_BINARY?: InputMaybe<MakeupCommandBinaryUnits>;
  MAKEUP_POSITION?: InputMaybe<MakeupPositionUnits>;
  MAKEUP_POSITION_COMMAND?: InputMaybe<MakeupPositionCommandUnits>;
  MAKEUP_STATUS_BINARY?: InputMaybe<MakeupStatusBinaryUnits>;
  MAX_SPEED_COMMAND?: InputMaybe<MaxSpeedCommandUnits>;
  MINIMUM_LOCKOUT_TEMPERATURE?: InputMaybe<MinimumLockoutTemperatureUnits>;
  MINIMUM_OUTSIDE_AIR_FLOW_RATE_SETPOINT?: InputMaybe<MinimumOutsideAirFlowRateSetpointUnits>;
  MINIMUM_POSITION_SETPOINT?: InputMaybe<MinimumPositionSetpointUnits>;
  MIN_SPEED_COMMAND?: InputMaybe<MinSpeedCommandUnits>;
  MIXED_TEMPERATURE?: InputMaybe<MixedTemperatureUnits>;
  MIXED_TEMPERATURE_SETPOINT?: InputMaybe<MixedTemperatureSetpointUnits>;
  NATURAL_GAS_ENERGY?: InputMaybe<NaturalGasEnergyUnits>;
  NATURAL_GAS_USAGE?: InputMaybe<NaturalGasUnits>;
  OAD_POSITION_COMMAND_AT_FAN_SPEED?: InputMaybe<OadPositionCommandAtFanSpeedUnits>;
  OAD_POSITION_COMMAND_AT_MAX_FAN_SPEED?: InputMaybe<OadPositionCommandAtMaxFanSpeedUnits>;
  OAD_POSITION_COMMAND_AT_MIN_FAN_SPEED?: InputMaybe<OadPositionCommandAtMinFanSpeedUnits>;
  OFF_PID_LIMIT?: InputMaybe<OffPidLimitUnits>;
  OIL_TEMPERATURE?: InputMaybe<OilTemperatureUnits>;
  ONE_THIRD_POSITION?: InputMaybe<OneThirdPositionUnits>;
  ONE_THIRD_POSITION_COMMAND?: InputMaybe<OneThirdPositionCommandUnits>;
  ON_PID_LIMIT?: InputMaybe<OnPidLimitUnits>;
  OUTDOOR_AIR_TEMPERATURE?: InputMaybe<OutdoorAirTemperatureUnits>;
  OUTLET_ISOLATION_COMMAND_BINARY?: InputMaybe<OutletIsolationCommandBinaryUnits>;
  OUTLET_ISOLATION_STATUS_BINARY?: InputMaybe<OutletIsolationStatusBinaryUnits>;
  OUTSIDE_AIR_CO2_CONCENTRATION?: InputMaybe<OutsideAirCo2ConcentrationUnits>;
  OUTSIDE_AIR_ENTHALPY?: InputMaybe<OutsideAirEnthalpyUnits>;
  OUTSIDE_AIR_PERCENTAGE?: InputMaybe<OutsideAirPercentageUnits>;
  OUTSIDE_AIR_TEMPERATURE_HIGH_LOCKOUT_COMMAND?: InputMaybe<OutsideAirTemperatureHighLockoutCommandUnits>;
  OUTSIDE_AIR_TEMPERATURE_LOCKOUT_LIMIT?: InputMaybe<OutsideAirTemperatureLockoutLimitUnits>;
  OUTSIDE_AIR_TEMPERATURE_LOCKOUT_SETPOINT?: InputMaybe<OutsideAirTemperatureLockoutSetpointUnits>;
  OUTSIDE_RELATIVE_HUMIDITY?: InputMaybe<OutsideRelativeHumidityUnits>;
  PID_LOOP_OUTPUT?: InputMaybe<PidLoopOutputUnits>;
  POSITION?: InputMaybe<PositionUnits>;
  POSITION_COMMAND?: InputMaybe<PositionCommandUnits>;
  PROPANE_USAGE?: InputMaybe<NaturalGasUnits>;
  PURGE_BINARY?: InputMaybe<PurgeBinaryUnits>;
  RELATIVE_HUMIDITY?: InputMaybe<RelativeHumidityUnits>;
  RELATIVE_HUMIDITY_SETPOINT?: InputMaybe<RelativeHumiditySetpointUnits>;
  RETURN_AIR_CO2_CONCENTRATION?: InputMaybe<ReturnAirCo2ConcentrationUnits>;
  RETURN_AIR_CO2_CONCENTRATION_SETPOINT?: InputMaybe<ReturnAirCo2ConcentrationSetpointUnits>;
  RETURN_AIR_ENTHALPY?: InputMaybe<ReturnAirEnthalpyUnits>;
  RETURN_AIR_RELATIVE_HUMIDITY?: InputMaybe<ReturnAirRelativeHumidityUnits>;
  RETURN_AIR_RELATIVE_HUMIDITY_SETPOINT?: InputMaybe<ReturnAirRelativeHumiditySetpointUnits>;
  RETURN_AIR_TEMPERATURE?: InputMaybe<ReturnAirTemperatureUnits>;
  RETURN_AIR_TEMPERATURE_SETPOINT?: InputMaybe<ReturnAirTemperatureSetpointUnits>;
  RETURN_AIR_TEMPERATURE_SETPOINT_HIGH_LIMIT?: InputMaybe<ReturnAirTemperatureSetpointHighLimitUnits>;
  RETURN_AIR_TEMPERATURE_SETPOINT_LOW_LIMIT?: InputMaybe<ReturnAirTemperatureSetpointLowLimitUnits>;
  RETURN_CONDENSATE_TEMPERATURE?: InputMaybe<ReturnCondensateTemperatureUnits>;
  RETURN_WATER_FLOW_RATE?: InputMaybe<ReturnWaterFlowRateUnits>;
  RETURN_WATER_TEMPERATURE?: InputMaybe<ReturnWaterTemperatureUnits>;
  RETURN_WATER_TEMPERATURE_SETPOINT?: InputMaybe<ReturnWaterTemperatureSetpointUnits>;
  RUNTIME_LIFETIME_CUMULATIVE?: InputMaybe<RuntimeLifetimeCumulativeUnits>;
  RUNTIME_YEARLY_CUMULATIVE?: InputMaybe<RuntimeYearlyCumulativeUnits>;
  SCHEDULE_ACTIVE_BINARY?: InputMaybe<ScheduleActiveBinaryUnits>;
  SCHEDULE_BINARY?: InputMaybe<ScheduleBinaryUnits>;
  SPACE_AIR_CO2_CONCENTRATION?: InputMaybe<SpaceAirCo2ConcentrationUnits>;
  SPACE_AIR_CO2_CONCENTRATION_SETPOINT?: InputMaybe<SpaceAirCo2ConcentrationSetpointUnits>;
  SPACE_RELATIVE_HUMIDITY?: InputMaybe<SpaceRelativeHumidityUnits>;
  SPACE_RELATIVE_HUMIDITY_SETPOINT?: InputMaybe<SpaceRelativeHumiditySetpointUnits>;
  SPACE_STATIC_PRESSURE?: InputMaybe<SpaceStaticPressureUnits>;
  SPACE_STATIC_PRESSURE_SETPOINT?: InputMaybe<SpaceStaticPressureSetpointUnits>;
  SPACE_TEMPERATURE?: InputMaybe<SpaceTemperatureUnits>;
  SPACE_TEMPERATURE_SETPOINT?: InputMaybe<SpaceTemperatureSetpointUnits>;
  SPACE_TEMPERATURE_SETPOINT_COOLING?: InputMaybe<SpaceTemperatureSetpointCoolingUnits>;
  SPACE_TEMPERATURE_SETPOINT_HEATING?: InputMaybe<SpaceTemperatureSetpointHeatingUnits>;
  SPACE_TEMPERATURE_SETPOINT_RESET_INCREMENT_LIMIT?: InputMaybe<SpaceTemperatureSetpointResetIncrementLimitUnits>;
  SPEED?: InputMaybe<SpeedUnits>;
  SPEED_COMMAND?: InputMaybe<SpeedCommandUnits>;
  STATIC_PRESSURE?: InputMaybe<StaticPressureUnits>;
  STATIC_PRESSURE_SETPOINT?: InputMaybe<StaticPressureSetpointUnits>;
  STATIC_PRESSURE_SETPOINT_HIGH_LIMIT?: InputMaybe<StaticPressureSetpointHighLimitUnits>;
  STATIC_PRESSURE_SETPOINT_LOW_LIMIT?: InputMaybe<StaticPressureSetpointLowLimitUnits>;
  STATUS_BINARY?: InputMaybe<StatusBinaryUnits>;
  STEAM_MASS?: InputMaybe<SteamMassUnits>;
  STEAM_PRESSURE?: InputMaybe<SteamPressureUnits>;
  STEAM_PRESSURE_SETPOINT?: InputMaybe<SteamPressureSetpointUnits>;
  STEAM_TEMPERATURE?: InputMaybe<SteamTemperatureUnits>;
  STEAM_USAGE?: InputMaybe<SteamUsageUnits>;
  SUPPLY_AIR_CO2_CONCENTRATION?: InputMaybe<SupplyAirCo2ConcentrationUnits>;
  SUPPLY_AIR_CO2_CONCENTRATION_SETPOINT?: InputMaybe<SupplyAirCo2ConcentrationSetpointUnits>;
  SUPPLY_AIR_RELATIVE_HUMIDITY?: InputMaybe<SupplyAirRelativeHumidityUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_HIGH_LIMIT?: InputMaybe<SupplyAirStaticPressureSetpointHighLimitUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_LOW_LIMIT?: InputMaybe<SupplyAirStaticPressureSetpointLowLimitUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT?: InputMaybe<SupplyAirStaticPressureSetpointResetFanSpeedHighLimitUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT?: InputMaybe<SupplyAirStaticPressureSetpointResetFanSpeedLowLimitUnits>;
  SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT?: InputMaybe<SupplyAirTemperatureSetpointResetFanSpeedHighLimitUnits>;
  SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT?: InputMaybe<SupplyAirTemperatureSetpointResetFanSpeedLowLimitUnits>;
  SUPPLY_TEMPERATURE?: InputMaybe<SupplyTemperatureUnits>;
  SUPPLY_TEMPERATURE_SETPOINT?: InputMaybe<SupplyTemperatureSetpointUnits>;
  SUPPLY_TEMPERATURE_SETPOINT_DELTA?: InputMaybe<SupplyTemperatureSetpointDeltaUnits>;
  SUPPLY_TEMPERATURE_SETPOINT_HIGH_LIMIT?: InputMaybe<SupplyTemperatureSetpointHighLimitUnits>;
  SUPPLY_TEMPERATURE_SETPOINT_LOW_LIMIT?: InputMaybe<SupplyTemperatureSetpointLowLimitUnits>;
  SUPPLY_WATER_FLOW_RATE?: InputMaybe<SupplyWaterFlowRateUnits>;
  SUPPLY_WATER_TEMPERATURE?: InputMaybe<SupplyWaterTemperatureUnits>;
  SUPPLY_WATER_TEMPERATURE_SETPOINT?: InputMaybe<SupplyWaterTemperatureSetpointUnits>;
  SWITCH_STATUS_BINARY?: InputMaybe<SwitchStatusBinaryUnits>;
  SYSTEM_ENABLE_OUTSIDE_AIR_TEMPERATURE_SETPOINT?: InputMaybe<SystemEnableOutsideAirTemperatureSetpointUnits>;
  TANK_CAPACITY?: InputMaybe<TankCapacityUnits>;
  TEMPERATURE?: InputMaybe<TemperatureUnit>;
  TWO_THIRD_POSITION?: InputMaybe<TwoThirdPositionUnits>;
  TWO_THIRD_POSITION_COMMAND?: InputMaybe<TwoThirdPositionCommandUnits>;
  UNOCCUPIED_COOLING_SETPOINT?: InputMaybe<UnoccupiedCoolingSetpointUnits>;
  UNOCCUPIED_HEATING_SETPOINT?: InputMaybe<UnoccupiedHeatingSetpointUnits>;
  WARMUP_BINARY?: InputMaybe<WarmupBinaryUnits>;
  WATER_FLOW_RATE?: InputMaybe<WaterFlowRateUnits>;
  WATER_USAGE?: InputMaybe<WaterUsageUnits>;
  WET_BULB?: InputMaybe<WetBulbUnits>;
  ZONE_OVERRIDE_COMMAND?: InputMaybe<ZoneOverrideCommandUnits>;
  ZONE_RETURN_AIR_TEMPERATURE?: InputMaybe<ZoneReturnAirTemperatureUnits>;
};

export type PrismBuilding = {
  __typename?: 'PrismBuilding';
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  state: Scalars['String'];
  streetAddress: Scalars['String'];
  streetAddress2: Scalars['String'];
  zipCode: Scalars['String'];
};

export type PublishMeasureResponse = MeasureResponse & {
  __typename?: 'PublishMeasureResponse';
  measure: Measure;
};

export enum PurgeBinaryUnits {
  BINARY = 'BINARY'
}

export type Query = {
  __typename?: 'Query';
  /** @deprecated 'ingestPoints' should be used instead */
  allIngestPoints?: Maybe<Array<Maybe<IngestPoint>>>;
  allOrganizations?: Maybe<Array<Maybe<Organization>>>;
  /**
   * # Gets the available actions for a given measure
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  availableMeasureActions: Array<Maybe<MeasureActionType>>;
  baselineJobs: Array<Maybe<BaselineJob>>;
  buildingOrganizationRelationshipById?: Maybe<BuildingOrganizationRelationship>;
  /** # Get the user profile of the requesting user. */
  currentUser?: Maybe<User>;
  defaultUnitForPointType?: Maybe<Unit>;
  equipmentById?: Maybe<Equipment>;
  equipmentPointById?: Maybe<EquipmentPoint>;
  getAlertById?: Maybe<Alert>;
  /**
   * # Returns and array of Consolidated Bills for a given organization, building,
   * startDate and endDate
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  getBills: Array<Maybe<ConsolidatedBillResponse>>;
  getBuildingById?: Maybe<Building>;
  getBuildingByMeterId?: Maybe<Building>;
  getCurtailmentEventById?: Maybe<GetCurtailmentEventByIdResponse>;
  /**
   * # Gets curtailment events sorted by startTime
   * ## Error codes:
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  getCurtailmentEvents?: Maybe<GetCurtailmentEventsResponse>;
  getMeterById?: Maybe<Meter>;
  getMeterGroups?: Maybe<Array<Maybe<MeterGroup>>>;
  getOrganizationById?: Maybe<Organization>;
  getPointById?: Maybe<Point>;
  getSpaceGroups?: Maybe<Array<Maybe<SpaceGroup>>>;
  /**
   * # Gets a user by id
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  getUserById?: Maybe<User>;
  /**
   * # Gets a user by an identity provider identifier
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  getUserByIdentityProviderId?: Maybe<User>;
  /**
   * # Returns US holidays unless you include the optional country argument in  ISO 3166-1 alpha-2 or ISO 3166-1 alpha-3 format.
   * # Returns only country-level holidays unless you include the optional "subdivisions: true" argument.
   */
  holidays?: Maybe<Holidays>;
  ingestPathwayById?: Maybe<IngestPathway>;
  ingestPathways?: Maybe<IngestPathways>;
  ingestPointById?: Maybe<IngestPoint>;
  ingestPoints?: Maybe<IngestPoints>;
  labels?: Maybe<Array<Maybe<Label>>>;
  measure?: Maybe<Measure>;
  /**
   * # Gets the available filter facets and the number of measures for each
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  measureFilterFacetsByOrganization?: Maybe<MeasureFilterFacets>;
  meterGroup?: Maybe<MeterGroup>;
  /** # Returns all organizations that have login credentials for Prism */
  organizationsForPrism?: Maybe<Array<Maybe<Organization>>>;
  pathwayPointSetById?: Maybe<PathwayPointSet>;
  pathwayPointSets?: Maybe<PathwayPointSets>;
  pendingSubscriptions: Array<Maybe<Subscription>>;
  /**
   * # Returns buildings from the Building Engines Prism API that can be associated
   * # with buildings from the specified AO organizationId
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   */
  prismBuildingsByOrganization?: Maybe<Array<Maybe<PrismBuilding>>>;
  s2ById?: Maybe<S2>;
  searchUsers?: Maybe<Array<Maybe<User>>>;
  serviceAccount?: Maybe<ServiceAccount>;
  /**
   * # Returns an array of buildings for the requested buildingIds
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  spaceGroup?: Maybe<SpaceGroup>;
  unitsForCommodity: Array<Maybe<Unit>>;
  /**
   * # Gets the users which have access to a given building
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - SERVICE_UNAVAILABLE
   */
  usersByBuilding?: Maybe<Array<Maybe<User>>>;
  /**
   * # Gets an external work order ID corresponding to a given measure
   * ## Error codes:
   * - NOT_FOUND
   * - INSUFFICIENT_RIGHTS
   * - BAD_USER_INPUT
   * - SERVICE_UNAVAILABLE
   */
  workOrderForMeasure: WorkOrderForMeasureResponse;
};


export type QueryAllIngestPointsArgs = {
  input: AllIngestPointsInput;
};


export type QueryAvailableMeasureActionsArgs = {
  measureId: Scalars['String'];
};


export type QueryBaselineJobsArgs = {
  range?: InputMaybe<UtcDateRange>;
};


export type QueryBuildingOrganizationRelationshipByIdArgs = {
  id: Scalars['String'];
};


export type QueryDefaultUnitForPointTypeArgs = {
  pointType: PointType;
};


export type QueryEquipmentByIdArgs = {
  id: Scalars['String'];
};


export type QueryEquipmentPointByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetAlertByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetBillsArgs = {
  buildingId: Scalars['String'];
  endDate: Scalars['String'];
  organizationId: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetBuildingByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetBuildingByMeterIdArgs = {
  meterId: Scalars['String'];
};


export type QueryGetCurtailmentEventByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetMeterByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetOrganizationByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPointByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetUserByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetUserByIdentityProviderIdArgs = {
  id: Scalars['String'];
};


export type QueryHolidaysArgs = {
  country?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  subdivisions?: InputMaybe<Scalars['Boolean']>;
  year: Scalars['Int'];
};


export type QueryIngestPathwayByIdArgs = {
  id: Scalars['String'];
};


export type QueryIngestPathwaysArgs = {
  input: PaginationInput;
};


export type QueryIngestPointByIdArgs = {
  id: Scalars['String'];
};


export type QueryIngestPointsArgs = {
  input: PaginationInput;
};


export type QueryLabelsArgs = {
  domain: LabelDomain;
};


export type QueryMeasureArgs = {
  id: Scalars['String'];
  unitPreferences?: InputMaybe<PreferredUnitsInput>;
};


export type QueryMeasureFilterFacetsByOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryMeterGroupArgs = {
  id: Scalars['ID'];
};


export type QueryPathwayPointSetByIdArgs = {
  id: Scalars['String'];
};


export type QueryPathwayPointSetsArgs = {
  input: PaginationInput;
};


export type QueryPrismBuildingsByOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type QueryS2ByIdArgs = {
  id: Scalars['String'];
};


export type QuerySearchUsersArgs = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatusCriteria>;
};


export type QueryServiceAccountArgs = {
  id: Scalars['ID'];
};


export type QuerySpaceGroupArgs = {
  id: Scalars['ID'];
};


export type QueryUnitsForCommodityArgs = {
  commodityName: CommodityName;
};


export type QueryUsersByBuildingArgs = {
  buildingId: Scalars['String'];
};


export type QueryWorkOrderForMeasureArgs = {
  measureId: Scalars['String'];
};

export type ReadingInput = {
  cost?: InputMaybe<Scalars['Float']>;
  currentReadingDate?: InputMaybe<Scalars['String']>;
  currentValue?: InputMaybe<Scalars['Float']>;
  demand?: InputMaybe<Scalars['Float']>;
  meterId: Scalars['String'];
  multiplier?: InputMaybe<Scalars['Float']>;
  previousReadingDate?: InputMaybe<Scalars['String']>;
  previousValue?: InputMaybe<Scalars['Float']>;
  usage?: InputMaybe<Scalars['Float']>;
};

export type ReadingResponse = {
  __typename?: 'ReadingResponse';
  cost?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  currentReadingDate?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['Float']>;
  demand?: Maybe<Scalars['Float']>;
  enteredBy?: Maybe<User>;
  meterId: Scalars['String'];
  multiplier?: Maybe<Scalars['Float']>;
  previousReadingDate?: Maybe<Scalars['String']>;
  previousValue?: Maybe<Scalars['Float']>;
  readingId: Scalars['String'];
  usage?: Maybe<Scalars['Float']>;
};

export enum Reason {
  BILLING_DAYS = 'BILLING_DAYS',
  COMPLETED_PROJECTS = 'COMPLETED_PROJECTS',
  OCCUPANCY = 'OCCUPANCY',
  OTHER = 'OTHER',
  TENANT_CONSUMPTION = 'TENANT_CONSUMPTION',
  WEATHER = 'WEATHER'
}

export enum RecurrenceFrequency {
  DAILY = 'DAILY'
}

export type RecurrenceInput = {
  frequency: RecurrenceFrequency;
  recurrences: Scalars['Int'];
};

export enum RecurringPeriod {
  DAILY_AM = 'DAILY_AM',
  MONTHLY_FIRST_AM = 'MONTHLY_FIRST_AM',
  NOTIFICATION = 'NOTIFICATION',
  QUARTERLY_FIRST_AM = 'QUARTERLY_FIRST_AM',
  WEEKDAYS_AM = 'WEEKDAYS_AM',
  WEEKLY_MONDAY_AM = 'WEEKLY_MONDAY_AM'
}

export type RegisterBuildingOrganizationRelationshipResponse = {
  __typename?: 'RegisterBuildingOrganizationRelationshipResponse';
  relationship?: Maybe<BuildingOrganizationRelationship>;
};

export type RegisterNewBuildingMutationResponse = {
  __typename?: 'RegisterNewBuildingMutationResponse';
  building?: Maybe<Building>;
  code: Scalars['String'];
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RegisterNewEquipmentInput = {
  customerId?: InputMaybe<Scalars['String']>;
  equipmentType: EquipmentType;
  name: Scalars['String'];
  parentEquipmentId?: InputMaybe<Scalars['String']>;
};

export type RegisterNewEquipmentResponse = {
  __typename?: 'RegisterNewEquipmentResponse';
  equipment?: Maybe<Equipment>;
};

export type RegisterNewEquipmentWithBuildingInput = {
  buildingId: Scalars['String'];
  customerId?: InputMaybe<Scalars['String']>;
  equipmentType: EquipmentType;
  name: Scalars['String'];
};

export type RegisterNewEquipmentWithBuildingResponse = {
  __typename?: 'RegisterNewEquipmentWithBuildingResponse';
  equipment?: Maybe<Equipment>;
};

export type RegisterNewPointWithEquipmentInput = {
  customerPointId?: InputMaybe<Scalars['String']>;
  equipmentId: Scalars['String'];
  ingestPointId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pointType: PointType;
};

export type RegisterNewPointWithEquipmentResponse = {
  __typename?: 'RegisterNewPointWithEquipmentResponse';
  point?: Maybe<EquipmentPoint>;
};

export type RegisterOdasIngestPointsInput = {
  modbusDevices: Array<OdasModbusDeviceInput>;
  odasId: Scalars['String'];
};

export type RegisterOdasIngestPointsResponse = {
  __typename?: 'RegisterOdasIngestPointsResponse';
  odas?: Maybe<Odas>;
};

export type RegisterOrganizationMutationResponse = {
  __typename?: 'RegisterOrganizationMutationResponse';
  organization?: Maybe<Organization>;
};

export type RegisterPathwayPointSetInput = {
  id: Scalars['String'];
  ingestPathwayId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type RegisterPathwayPointSetResponse = {
  __typename?: 'RegisterPathwayPointSetResponse';
  pathwayPointSet?: Maybe<PathwayPointSet>;
};

export type RegisterPrismCredentialsForOrganizationResponse = {
  __typename?: 'RegisterPrismCredentialsForOrganizationResponse';
  organizationId: Scalars['String'];
  username: Scalars['String'];
};

export enum RelativeHumiditySetpointUnits {
  PERCENT = 'PERCENT'
}

export enum RelativeHumidityUnits {
  PERCENT = 'PERCENT'
}

export type RemoveBuildingFromAllUsersPayload = {
  __typename?: 'RemoveBuildingFromAllUsersPayload';
  removedBuildingId: Scalars['String'];
};

export type RemoveBuildingFromGroupInput = {
  buildingId: Scalars['String'];
  groupId: Scalars['String'];
};

export type RemoveBuildingOrganizationRelationshipResponse = {
  __typename?: 'RemoveBuildingOrganizationRelationshipResponse';
  buildingOrganizationRelationshipId: Scalars['String'];
};

export type RemoveBuildingsFromUserInput = {
  buildingIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type RemoveBuildingsFromUserPayload = {
  __typename?: 'RemoveBuildingsFromUserPayload';
  removedBuildingIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type RemoveEquipmentFromBuildingInput = {
  buildingId: Scalars['String'];
  equipmentId: Scalars['String'];
};

export type RemoveEquipmentFromBuildingResponse = {
  __typename?: 'RemoveEquipmentFromBuildingResponse';
  equipment?: Maybe<Equipment>;
};

export type RemoveEquipmentFromParentInput = {
  equipmentId: Scalars['String'];
};

export type RemoveEquipmentFromParentResponse = {
  __typename?: 'RemoveEquipmentFromParentResponse';
  equipment?: Maybe<Equipment>;
};

export type RemoveIntegrationMappingResponse = {
  __typename?: 'RemoveIntegrationMappingResponse';
  integrationMappingId: Scalars['String'];
};

export type RemoveMeasureLabelResponse = {
  __typename?: 'RemoveMeasureLabelResponse';
  measure?: Maybe<Measure>;
};

export type RemoveRoleFromUserInput = {
  role: Role;
  userId: Scalars['String'];
};

export type RemoveRoleFromUserPayload = {
  __typename?: 'RemoveRoleFromUserPayload';
  removedRole: Role;
  userId: Scalars['String'];
};

export type RemoveSpaceGroupFromParentInput = {
  id: Scalars['String'];
  parentGroupId: Scalars['String'];
};

export type RemoveSpaceGroupFromParentResponse = {
  __typename?: 'RemoveSpaceGroupFromParentResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  spaceGroup?: Maybe<SpaceGroup>;
  success: Scalars['Boolean'];
};

export type RemoveSubscriptionPayload = {
  __typename?: 'RemoveSubscriptionPayload';
  subscriptionId: Scalars['String'];
};

export enum ReportIdentifier {
  BUILDING_DAILY_BASELINES = 'BUILDING_DAILY_BASELINES',
  BUILDING_ELECTRICITY_DAILY_DEMAND_PROFILE = 'BUILDING_ELECTRICITY_DAILY_DEMAND_PROFILE',
  BUILDING_HOURLY_BASELINES = 'BUILDING_HOURLY_BASELINES',
  COMMODITY_CHART = 'COMMODITY_CHART',
  DAILY_MEASURE_SUMMARY = 'DAILY_MEASURE_SUMMARY',
  MEASURES_ASSIGNMENT = 'MEASURES_ASSIGNMENT',
  MISSING_BILLS = 'MISSING_BILLS',
  OPEN_MEASURES_SUMMARY = 'OPEN_MEASURES_SUMMARY'
}

export type RestoreDemandManagementRelayInput = {
  controlPointId: Scalars['String'];
  curtailmentEventId: Scalars['String'];
};

export type RestoreDemandManagementRelayResponse = {
  __typename?: 'RestoreDemandManagementRelayResponse';
  curtailmentEvent: CurtailmentEvent;
  success: Scalars['Boolean'];
};

export enum ReturnAirCo2ConcentrationSetpointUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export enum ReturnAirCo2ConcentrationUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export enum ReturnAirEnthalpyUnits {
  BRITISH_THERMAL_UNITS_PER_POUND = 'BRITISH_THERMAL_UNITS_PER_POUND',
  KILOJOULES_PER_KILOGRAM = 'KILOJOULES_PER_KILOGRAM'
}

export enum ReturnAirRelativeHumiditySetpointUnits {
  PERCENT = 'PERCENT'
}

export enum ReturnAirRelativeHumidityUnits {
  PERCENT = 'PERCENT'
}

export enum ReturnAirTemperatureSetpointHighLimitUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum ReturnAirTemperatureSetpointLowLimitUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum ReturnAirTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum ReturnAirTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum ReturnCondensateTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum ReturnWaterFlowRateUnits {
  GALLONS_PER_MINUTE = 'GALLONS_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum ReturnWaterTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum ReturnWaterTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type RevokePathwayApiKeyInput = {
  apiKeyId: Scalars['String'];
};

export type RevokePathwayApiKeyResponse = {
  __typename?: 'RevokePathwayApiKeyResponse';
  ingestPathway?: Maybe<IngestPathway>;
};

export enum Role {
  /** Hatch Uber-administrator */
  ADMIN = 'ADMIN',
  /** Access to all buildings for their organization */
  ALL_BUILDINGS_FOR_ORGANIZATION = 'ALL_BUILDINGS_FOR_ORGANIZATION',
  /** Can access only support functionality available to customers (customer) */
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  /** Access to preview in progress features (customer) */
  CUSTOMER_PREVIEW = 'CUSTOMER_PREVIEW',
  /** Read only access to all orgs and buildings (internal) */
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  /**
   * Can do whatever a field operator does
   * Read only access to all orgs and buildings (internal)
   */
  FIELD_OPERATOR = 'FIELD_OPERATOR',
  /** Can only access financial (as opposed to building) data */
  FINANCIAL_ONLY = 'FINANCIAL_ONLY',
  /** Can access all support functionality */
  INTERNAL_SUPPORT = 'INTERNAL_SUPPORT',
  /** Access to preview in progress features (internal) */
  PREVIEW = 'PREVIEW',
  /** Can read... only */
  READ_ONLY = 'READ_ONLY',
  /** Can access developer only support features (internal) */
  SUPPORT_ADMIN = 'SUPPORT_ADMIN'
}

export enum RuntimeLifetimeCumulativeUnits {
  HOURS = 'HOURS'
}

export enum RuntimeYearlyCumulativeUnits {
  HOURS = 'HOURS'
}

export type S2 = {
  __typename?: 'S2';
  activationStatus: S2ActivationStatus;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  modbusDevices?: Maybe<Array<S2ModbusDevice>>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  onboardControlDevices?: Maybe<Array<S2OnboardControlDevice>>;
  onboardIngestDevices?: Maybe<Array<S2OnboardIngestDevice>>;
  recoilDevices?: Maybe<Array<S2RecoilDevice>>;
};

export type S2ActivationStatus = {
  __typename?: 'S2ActivationStatus';
  createdAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  status: ActivationStatus;
};

export enum S2ControlPointId {
  Relay1 = 'Relay1',
  Relay2 = 'Relay2'
}

export type S2ControlPointInput = {
  s2PointId: S2ControlPointId;
};

export enum S2IngestPointId {
  ApparentPower = 'ApparentPower',
  Demand = 'Demand',
  Pulse1 = 'Pulse1',
  Pulse2 = 'Pulse2',
  Pulse3 = 'Pulse3',
  Pulse4 = 'Pulse4',
  Pulse5 = 'Pulse5',
  Pulse6 = 'Pulse6',
  ReactivePower = 'ReactivePower',
  TempC = 'TempC',
  Usage = 'Usage'
}

export type S2IngestPointInput = {
  cumulative: Scalars['Boolean'];
  cumulativeMaxValue?: InputMaybe<CumulativeMaxValue>;
  interval?: InputMaybe<Interval>;
  multiplier?: InputMaybe<Scalars['Float']>;
  s2PointId: S2IngestPointId;
  unit: IngestUnit;
  unitOverride?: InputMaybe<Scalars['String']>;
};

export type S2ModbusDevice = {
  __typename?: 'S2ModbusDevice';
  controlPoints?: Maybe<Array<IngestPoint>>;
  id: Scalars['String'];
  ingestPoints?: Maybe<Array<IngestPoint>>;
  name: Scalars['String'];
};

export type S2ModbusDeviceInput = {
  controlPoints?: InputMaybe<Array<S2ControlPointInput>>;
  hexAddress: Scalars['String'];
  ingestPoints?: InputMaybe<Array<S2IngestPointInput>>;
  model: S2ModbusModel;
  name: Scalars['String'];
};

export enum S2ModbusModel {
  E50 = 'E50',
  FLEXIO_8 = 'FLEXIO_8',
  FLEXIO_23 = 'FLEXIO_23',
  H8036 = 'H8036',
  H8830 = 'H8830',
  H8923 = 'H8923'
}

export type S2OnboardControlDevice = {
  __typename?: 'S2OnboardControlDevice';
  controlPoint: IngestPoint;
  id: Scalars['String'];
};

export type S2OnboardControlDeviceInput = {
  controlPoint: S2ControlPointInput;
};

export type S2OnboardIngestDevice = {
  __typename?: 'S2OnboardIngestDevice';
  id: Scalars['String'];
  ingestPoint: IngestPoint;
};

export type S2OnboardIngestDeviceInput = {
  ingestPoint: S2IngestPointInput;
};

export type S2RecoilDevice = {
  __typename?: 'S2RecoilDevice';
  id: Scalars['String'];
  ingestPoints: Array<Maybe<IngestPoint>>;
  name: Scalars['String'];
};

export type S2RecoilDeviceInput = {
  ingestPoints?: InputMaybe<Array<S2IngestPointInput>>;
  name: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type S2RegistrationInput = {
  id: Scalars['String'];
  modbusDevices?: InputMaybe<Array<InputMaybe<S2ModbusDeviceInput>>>;
  name: Scalars['String'];
  onboardControlDevices?: InputMaybe<Array<InputMaybe<S2OnboardControlDeviceInput>>>;
  onboardIngestDevices?: InputMaybe<Array<InputMaybe<S2OnboardIngestDeviceInput>>>;
  recoilDevices?: InputMaybe<Array<InputMaybe<S2RecoilDeviceInput>>>;
};

export type SsoIntegration = {
  __typename?: 'SSOIntegration';
  product: Scalars['String'];
  type: SsoIntegrationType;
  usernameOverride?: Maybe<Scalars['String']>;
};

export enum SsoIntegrationType {
  BILL_MANAGEMENT = 'BILL_MANAGEMENT'
}

export enum ScheduleActiveBinaryUnits {
  BINARY = 'BINARY'
}

export type ScheduleBaselineJobInput = {
  buildingId: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  excludeFeature?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isGlobalDefault: Scalars['Boolean'];
  modelName?: InputMaybe<Scalars['String']>;
  pointType?: InputMaybe<PointType>;
  range?: InputMaybe<TimeRangeInput>;
  trainingEndTime?: InputMaybe<Scalars['String']>;
  trainingStartTime?: InputMaybe<Scalars['String']>;
};

export type ScheduleBaselineJobResponse = {
  __typename?: 'ScheduleBaselineJobResponse';
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export enum ScheduleBinaryUnits {
  BINARY = 'BINARY'
}

export type ScheduleException = {
  __typename?: 'ScheduleException';
  exceptionDate: Scalars['DateTime'];
  exceptionEndDate?: Maybe<Scalars['DateTime']>;
  exceptionTimeRanges?: Maybe<Array<Maybe<ExceptionOperatingTimeBlock>>>;
  exceptionType?: Maybe<ExceptionType>;
  id: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<BuildingAnnotationReason>>>;
  timeBlock?: Maybe<DailyOperatingTimeBlock>;
  title: Scalars['String'];
};

export type ScheduleNewCurtailmentEventInput = {
  controlPointIds: Array<Scalars['String']>;
  eventType: CurtailmentEventType;
  minuteDuration: Scalars['Int'];
  startTime: Scalars['DateTime'];
};

export type ScheduleNewCurtailmentEventResponse = {
  __typename?: 'ScheduleNewCurtailmentEventResponse';
  event: CurtailmentEvent;
};

export enum ScheduleType {
  BASE = 'BASE',
  EXCEPTION = 'EXCEPTION'
}

export type SendWelcomeEmailInput = {
  email: Scalars['String'];
};

export type SendWelcomeEmailResponse = {
  __typename?: 'SendWelcomeEmailResponse';
  email: Scalars['String'];
  message: Scalars['String'];
};

export type ServiceAccount = {
  __typename?: 'ServiceAccount';
  accountNumber?: Maybe<Scalars['String']>;
  commodityNames?: Maybe<Array<Maybe<CommodityName>>>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  meterAssignments: Array<Maybe<MeterAssignment>>;
  startDate?: Maybe<Scalars['DateTime']>;
  utilityProviderName: Scalars['String'];
  vendorType: VendorType;
};

export type ServiceAccountCreationResponse = {
  __typename?: 'ServiceAccountCreationResponse';
  serviceAccount?: Maybe<ServiceAccount>;
};

export type ServiceAccountInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  utilityProviderName: Scalars['String'];
  vendorType?: InputMaybe<VendorType>;
};

export type SetBuildingBlendedRatesInput = {
  blendedRates: BlendedRatesInput;
  id: Scalars['String'];
};

export type SetBuildingThresholdInput = {
  buildingId: Scalars['String'];
  type: PointType;
  value: Scalars['Int'];
};

export type SetBuildingThresholdResponse = {
  __typename?: 'SetBuildingThresholdResponse';
  threshold: BuildingThreshold;
};

export type SetCalendarYearCommodityBudgetForBuildingInput = {
  apr: CommodityBudgetAmountInput;
  aug: CommodityBudgetAmountInput;
  buildingId: Scalars['ID'];
  commodity: CommodityName;
  currency: Currency;
  dec: CommodityBudgetAmountInput;
  feb: CommodityBudgetAmountInput;
  jan: CommodityBudgetAmountInput;
  jul: CommodityBudgetAmountInput;
  jun: CommodityBudgetAmountInput;
  mar: CommodityBudgetAmountInput;
  may: CommodityBudgetAmountInput;
  nov: CommodityBudgetAmountInput;
  oct: CommodityBudgetAmountInput;
  sep: CommodityBudgetAmountInput;
  usageUnit: Unit;
  year: Scalars['Int'];
};

export type SetCalendarYearCommodityBudgetForBuildingResponse = {
  __typename?: 'SetCalendarYearCommodityBudgetForBuildingResponse';
  budget?: Maybe<CalendarYearCommodityBudget>;
  building?: Maybe<Building>;
};

export type SetPasswordForIngestPathwayInput = {
  password: Scalars['String'];
  pathwayId: Scalars['String'];
};

export type SetPasswordForIngestPathwayResponse = {
  __typename?: 'SetPasswordForIngestPathwayResponse';
  ingestPathway?: Maybe<IngestPathway>;
};

export type SetVarianceNoteInput = {
  buildingId: Scalars['String'];
  commodityName: CommodityName;
  comparisonYear: Scalars['Int'];
  month: Month;
  note: Scalars['String'];
  reasons?: InputMaybe<Array<Reason>>;
  year: Scalars['Int'];
};

export type SetVarianceNoteResponse = {
  __typename?: 'SetVarianceNoteResponse';
  buildingId: Scalars['String'];
  commodityName: CommodityName;
  comparisonYear: Scalars['Int'];
  createdAt: Scalars['String'];
  enteredBy: User;
  month: Month;
  note: Scalars['String'];
  reasons?: Maybe<Array<Reason>>;
  year: Scalars['Int'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Sources = {
  __typename?: 'Sources';
  mode: SourcesMode;
};

export type SourcesInput = {
  mode: SourcesMode;
};

export enum SourcesMode {
  READ_ONLY = 'READ_ONLY',
  READ_WRITE = 'READ_WRITE'
}

export enum SpaceAirCo2ConcentrationSetpointUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export enum SpaceAirCo2ConcentrationUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export type SpaceGroup = {
  __typename?: 'SpaceGroup';
  availableUtilityData: AvailableUtilityDataResponse;
  buildings?: Maybe<Array<Maybe<Building>>>;
  commodityUsageData: SpaceGroupCommodityUsage;
  customerSpaceGroupId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  parentGroup?: Maybe<SpaceGroup>;
  type?: Maybe<SpaceGroupType>;
  usageVsBaselineForPeriod?: Maybe<UsageVsBaselineReportData>;
  utilityData?: Maybe<YearlyUtilityData>;
};


export type SpaceGroupCommodityUsageDataArgs = {
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  type: PointType;
  unit?: InputMaybe<Unit>;
};


export type SpaceGroupUsageVsBaselineForPeriodArgs = {
  orgId?: InputMaybe<Scalars['String']>;
  period: UsageVsBaselineReportPeriod;
  pointType: PointType;
  timezone: Scalars['String'];
  unit?: InputMaybe<Unit>;
};


export type SpaceGroupUtilityDataArgs = {
  commodityName: CommodityName;
  year: Scalars['Int'];
};

export type SpaceGroupCommodityUsage = {
  __typename?: 'SpaceGroupCommodityUsage';
  commodityUsage: IntervalData;
  timezone: Scalars['String'];
};

export enum SpaceGroupType {
  BUILDING = 'BUILDING',
  METER = 'METER'
}

export type SpaceGroupUpdateInput = {
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type SpaceGroupUpdateResponse = {
  __typename?: 'SpaceGroupUpdateResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  spaceGroup?: Maybe<SpaceGroup>;
  success: Scalars['Boolean'];
};

export enum SpaceRelativeHumiditySetpointUnits {
  PERCENT = 'PERCENT'
}

export enum SpaceRelativeHumidityUnits {
  PERCENT = 'PERCENT'
}

export enum SpaceStaticPressureSetpointUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum SpaceStaticPressureUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum SpaceTemperatureSetpointCoolingUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SpaceTemperatureSetpointHeatingUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SpaceTemperatureSetpointResetIncrementLimitUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SpaceTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SpaceTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SpeedCommandUnits {
  PERCENT = 'PERCENT'
}

export enum SpeedUnits {
  PERCENT = 'PERCENT'
}

export type StartSupplyServiceInput = {
  deliveryServiceAccountId: Scalars['ID'];
  serviceAccountId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export enum StaticPressureSetpointHighLimitUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum StaticPressureSetpointLowLimitUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum StaticPressureSetpointUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum StaticPressureUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum StatusBinaryUnits {
  BINARY = 'BINARY'
}

export type StatusChangedActivity = Activity & {
  __typename?: 'StatusChangedActivity';
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  details?: Maybe<StatusChangedActivityDetails>;
  id: Scalars['String'];
  newStatus: MeasureStatus;
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export type StatusChangedActivityDetails = CloseStatusActivityDetails;

export type StatusFacet = {
  __typename?: 'StatusFacet';
  measures: Scalars['Int'];
  status: MeasureStatus;
};

export enum SteamMassUnits {
  KILOGRAMS = 'KILOGRAMS',
  POUNDS = 'POUNDS'
}

export enum SteamPressureSetpointUnits {
  BARS = 'BARS',
  POUNDS_PER_SQUARE_INCH = 'POUNDS_PER_SQUARE_INCH'
}

export enum SteamPressureUnits {
  BARS = 'BARS',
  POUNDS_PER_SQUARE_INCH = 'POUNDS_PER_SQUARE_INCH'
}

export enum SteamTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SteamUsageUnits {
  BRITISH_THERMAL_UNITS = 'BRITISH_THERMAL_UNITS',
  KILOJOULES = 'KILOJOULES'
}

export type SubscribeUserInput = {
  deliverySchedule: RecurringPeriod;
  pointTypes?: InputMaybe<Array<InputMaybe<PointType>>>;
  report: ReportIdentifier;
  reportAssetId: Scalars['String'];
  userId: Scalars['String'];
};

export type SubscribeUserMultipleInput = {
  deliverySchedule: RecurringPeriod;
  pointTypes?: InputMaybe<Array<InputMaybe<PointType>>>;
  report: ReportIdentifier;
  reportAssetIds: Array<Scalars['String']>;
  userId: Scalars['String'];
};

export type SubscribeUserMultiplePayload = {
  __typename?: 'SubscribeUserMultiplePayload';
  subscriptions: Array<Maybe<Subscription>>;
};

export type SubscribeUserPayload = {
  __typename?: 'SubscribeUserPayload';
  subscription?: Maybe<Subscription>;
};

export type SubscribeUserToConsolidatedReportInput = {
  deliverySchedule: RecurringPeriod;
  pointTypes?: InputMaybe<Array<InputMaybe<PointType>>>;
  report: ReportIdentifier;
  reportAssetIds: Array<Scalars['String']>;
  reportName?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  created: Scalars['DateTime'];
  deliverySchedule: RecurringPeriod;
  id: Scalars['String'];
  nextSend?: Maybe<Scalars['DateTime']>;
  report: GeneratedReport;
};

export enum SupplyAirCo2ConcentrationSetpointUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export enum SupplyAirCo2ConcentrationUnits {
  PARTS_PER_MILLION = 'PARTS_PER_MILLION'
}

export enum SupplyAirRelativeHumidityUnits {
  PERCENT = 'PERCENT'
}

export enum SupplyAirStaticPressureSetpointHighLimitUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum SupplyAirStaticPressureSetpointLowLimitUnits {
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  PASCALS = 'PASCALS'
}

export enum SupplyAirStaticPressureSetpointResetFanSpeedHighLimitUnits {
  PERCENT = 'PERCENT'
}

export enum SupplyAirStaticPressureSetpointResetFanSpeedLowLimitUnits {
  PERCENT = 'PERCENT'
}

export enum SupplyAirTemperatureSetpointResetFanSpeedHighLimitUnits {
  PERCENT = 'PERCENT'
}

export enum SupplyAirTemperatureSetpointResetFanSpeedLowLimitUnits {
  PERCENT = 'PERCENT'
}

export type SupplyServiceResponse = {
  __typename?: 'SupplyServiceResponse';
  serviceAccount?: Maybe<ServiceAccount>;
};

export enum SupplyTemperatureSetpointDeltaUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SupplyTemperatureSetpointHighLimitUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SupplyTemperatureSetpointLowLimitUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SupplyTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SupplyTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SupplyWaterFlowRateUnits {
  GALLONS_PER_MINUTE = 'GALLONS_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum SupplyWaterTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum SupplyWaterTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type SuppressMeasureResponse = MeasureResponse & {
  __typename?: 'SuppressMeasureResponse';
  measure: Measure;
};

export type SuppressedActivity = Activity & {
  __typename?: 'SuppressedActivity';
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export enum SwitchStatusBinaryUnits {
  BINARY = 'BINARY'
}

export enum SystemEnableOutsideAirTemperatureSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum TankCapacityUnits {
  PERCENT = 'PERCENT'
}

export enum TemperatureUnit {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type TerminateSupplyServiceInput = {
  serviceAccountId: Scalars['ID'];
  terminationDate: Scalars['DateTime'];
};

export type Threshold = {
  __typename?: 'Threshold';
  calculationType: ThresholdCalculationType;
  comparisonType: ThresholdComparisonType;
  intervalMinutes: Scalars['Int'];
  value: Scalars['Float'];
};

export enum ThresholdCalculationType {
  AVERAGE = 'AVERAGE',
  SUM = 'SUM'
}

export enum ThresholdComparisonType {
  GT = 'GT',
  LT = 'LT'
}

export type ThresholdInput = {
  calculationType: ThresholdCalculationType;
  comparisonType: ThresholdComparisonType;
  intervalMinutes: Scalars['Int'];
  value: Scalars['Float'];
};

export type TimeRange = {
  __typename?: 'TimeRange';
  endTime?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['DateTime']>;
};

export type TimeRangeInput = {
  endTime?: InputMaybe<Scalars['DateTime']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type TimeRangeOptionInput = {
  localDate?: InputMaybe<LocalDateRange>;
  timestamp?: InputMaybe<TimeRangeInput>;
};

export type TotalReport = {
  __typename?: 'TotalReport';
  actualCarbonEmission: Scalars['Float'];
  actualCost: Scalars['Float'];
  actualUsage: Scalars['Float'];
  calendarizedCarbonEmission: Scalars['Float'];
  calendarizedCost: Scalars['Float'];
  calendarizedUsage: Scalars['Float'];
};

export type Totals = {
  __typename?: 'Totals';
  actualCost: Scalars['Float'];
  actualReporting?: Maybe<Scalars['Float']>;
  actualUsage: Scalars['Float'];
  calendarizedCost: Scalars['Float'];
  calendarizedReporting?: Maybe<Scalars['Float']>;
  calendarizedUsage: Scalars['Float'];
};

export type TrainingPeriod = {
  __typename?: 'TrainingPeriod';
  endTime?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export type TransitionMeasureInput = {
  action: MeasureAction;
  measureId: Scalars['ID'];
};

export type TransitionMeasureResponse = MeasureResponse & {
  __typename?: 'TransitionMeasureResponse';
  measure: Measure;
};

export enum TwoThirdPositionCommandUnits {
  PERCENT = 'PERCENT'
}

export enum TwoThirdPositionUnits {
  PERCENT = 'PERCENT'
}

export type UtcDateRange = {
  endDate?: InputMaybe<Scalars['UTCDate']>;
  startDate?: InputMaybe<Scalars['UTCDate']>;
};

export enum Unit {
  AMPERES = 'AMPERES',
  BARS = 'BARS',
  BINARY = 'BINARY',
  BRITISH_THERMAL_UNITS = 'BRITISH_THERMAL_UNITS',
  BRITISH_THERMAL_UNITS_PER_POUND = 'BRITISH_THERMAL_UNITS_PER_POUND',
  CELSIUS = 'CELSIUS',
  CENTUM_CUBIC_FEET = 'CENTUM_CUBIC_FEET',
  CUBIC_FEET = 'CUBIC_FEET',
  CUBIC_FEET_PER_MINUTE = 'CUBIC_FEET_PER_MINUTE',
  CUBIC_METERS = 'CUBIC_METERS',
  CUBIC_YARDS = 'CUBIC_YARDS',
  DECA_THERM = 'DECA_THERM',
  FAHRENHEIT = 'FAHRENHEIT',
  GALLONS = 'GALLONS',
  GALLONS_PER_MINUTE = 'GALLONS_PER_MINUTE',
  GIGA_JOULE = 'GIGA_JOULE',
  GRAMS = 'GRAMS',
  HOURS = 'HOURS',
  HUNDRED_GALLONS = 'HUNDRED_GALLONS',
  INCHES_WATER_COLUMN = 'INCHES_WATER_COLUMN',
  KILOGRAMS = 'KILOGRAMS',
  KILOGRAMS_PER_MEGAWATT_HOUR = 'KILOGRAMS_PER_MEGAWATT_HOUR',
  KILOJOULES = 'KILOJOULES',
  KILOJOULES_PER_KILOGRAM = 'KILOJOULES_PER_KILOGRAM',
  KILOWATTS = 'KILOWATTS',
  KILOWATT_HOURS = 'KILOWATT_HOURS',
  LITERS = 'LITERS',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND',
  MEGAWATT_HOURS = 'MEGAWATT_HOURS',
  MEGA_JOULES = 'MEGA_JOULES',
  METRIC_TON = 'METRIC_TON',
  METRIC_TONS_PER_THERM = 'METRIC_TONS_PER_THERM',
  MILLION_BRITISH_THERMAL_UNITS = 'MILLION_BRITISH_THERMAL_UNITS',
  MINUTES = 'MINUTES',
  PARTS_PER_MILLION = 'PARTS_PER_MILLION',
  PASCALS = 'PASCALS',
  PERCENT = 'PERCENT',
  POUNDS = 'POUNDS',
  POUNDS_PER_MEGAWATT_HOUR = 'POUNDS_PER_MEGAWATT_HOUR',
  POUNDS_PER_SQUARE_INCH = 'POUNDS_PER_SQUARE_INCH',
  SECONDS = 'SECONDS',
  SHORT_TON = 'SHORT_TON',
  THERM = 'THERM',
  THOUSAND_BRITISH_THERMAL_UNITS = 'THOUSAND_BRITISH_THERMAL_UNITS',
  THOUSAND_CUBIC_FEET = 'THOUSAND_CUBIC_FEET',
  THOUSAND_GALLONS = 'THOUSAND_GALLONS',
  THOUSAND_POUNDS = 'THOUSAND_POUNDS',
  THOUSAND_POUNDS_PER_HOUR = 'THOUSAND_POUNDS_PER_HOUR',
  TON = 'TON',
  TONNE = 'TONNE',
  TON_HOURS = 'TON_HOURS'
}

export type UnitOfMeasurement = {
  __typename?: 'UnitOfMeasurement';
  name: Scalars['String'];
  /** @deprecated Unit of Measurement will soon not be a point field, as a point's unit of measurement is always the same for a given PointType. */
  symbol: Scalars['String'];
};

export type UnitPreference = {
  __typename?: 'UnitPreference';
  ALWAYS_ENABLED?: Maybe<AlwaysEnabledUnits>;
  BOILER_EFFICIENCY?: Maybe<BoilerEfficiencyUnits>;
  BOILER_LOAD?: Maybe<BoilerLoadUnits>;
  BOILER_PLANT_ENERGY_USAGE?: Maybe<BoilerPlantEnergyUsageUnits>;
  BYPASS_COMMAND_BINARY?: Maybe<BypassCommandBinaryUnits>;
  BYPASS_POSITION?: Maybe<BypassPositionUnits>;
  BYPASS_POSITION_COMMAND?: Maybe<BypassPositionCommandUnits>;
  BYPASS_STATUS_BINARY?: Maybe<BypassStatusBinaryUnits>;
  CARBON_MONOXIDE?: Maybe<CarbonMonoxideUnits>;
  CARBON_MONOXIDE_SETPOINT?: Maybe<CarbonMonoxideSetpointUnits>;
  CHILLED_WATER_USAGE?: Maybe<ChilledWaterUsageUnits>;
  CHILLER_EFFICIENCY?: Maybe<ChillerEfficiencyUnits>;
  CHILLER_LOAD?: Maybe<ChillerLoadUnits>;
  COMBUSTION_AIR_PRESSURE?: Maybe<CombustionAirPressureUnits>;
  COMBUSTION_TEMPERATURE?: Maybe<CombustionTemperatureUnits>;
  COMMAND_BINARY?: Maybe<CommandBinaryUnits>;
  CONDENSER_TEMPERATURE?: Maybe<CondenserTemperatureUnits>;
  COOLDOWN_BINARY?: Maybe<CooldownBinaryUnits>;
  COOLING_CAPACITY?: Maybe<CoolingCapacityUnits>;
  COOLING_PID_LOOP_OUTPUT?: Maybe<CoolingPidLoopOutputUnits>;
  COOLING_PID_OUTPUT?: Maybe<CoolingPidOutputUnits>;
  DIFFERENTIAL_PRESSURE?: Maybe<DifferentialPressureUnits>;
  DIFFERENTIAL_PRESSURE_SETPOINT?: Maybe<DifferentialPressureSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MAX_SETPOINT?: Maybe<DischargeAirFlowRateCoolingModeMaxSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_COOLING_MODE_MIN_SETPOINT?: Maybe<DischargeAirFlowRateCoolingModeMinSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_MAX_SETPOINT?: Maybe<DischargeAirFlowRateMaxSetpointUnits>;
  DISCHARGE_AIR_FLOW_RATE_MIN_SETPOINT?: Maybe<DischargeAirFlowRateMinSetpointUnits>;
  DISCHARGE_AIR_TEMPERATURE?: Maybe<DischargeAirTemperatureUnits>;
  DISCHARGE_AIR_TEMPERATURE_SETPOINT?: Maybe<DischargeAirTemperatureSetpointUnits>;
  ECONOMIZER_MINIMUM_LOCKOUT_TEMPERATURE?: Maybe<EconomizerMinimumLockoutTemperatureUnits>;
  ECONOMIZER_PID_OUTPUT?: Maybe<EconomizerPidOutputUnits>;
  ELECTRICITY_DEMAND?: Maybe<ElectricityDemandUnits>;
  ELECTRICITY_USAGE?: Maybe<ElectricityUsageUnits>;
  ELECTRIC_CURRENT?: Maybe<ElectricCurrentUnits>;
  ENTERING_TEMPERATURE?: Maybe<EnteringTemperatureUnits>;
  ENTERING_WATER_TEMPERATURE?: Maybe<EnteringWaterTemperatureUnits>;
  EVAPORATOR_TEMPERATURE?: Maybe<EvaporatorTemperatureUnits>;
  EXHAUST_AIR_TEMPERATURE?: Maybe<ExhaustAirTemperatureUnits>;
  EXTERIOR_SPACE_TEMPERATURE?: Maybe<ExteriorSpaceTemperatureUnits>;
  FAN_SPEED_SETPOINT?: Maybe<FanSpeedSetpointUnits>;
  FIRING_PERCENT?: Maybe<FiringPercentUnits>;
  FLOW_RATE?: Maybe<FlowRateUnits>;
  FLOW_RATE_SETPOINT?: Maybe<FlowRateSetpointUnits>;
  FREE_COOLING_COMMAND_BINARY?: Maybe<FreeCoolingCommandBinaryUnits>;
  FREE_COOLING_STATUS_BINARY?: Maybe<FreeCoolingStatusBinaryUnits>;
  HEATING_OIL_USAGE?: Maybe<HeatingOilUsageUnits>;
  HEATING_PID_LOOP_OUTPUT?: Maybe<HeatingPidLoopOutputUnits>;
  HEATING_PID_OUTPUT?: Maybe<HeatingPidOutputUnits>;
  HOLIDAY_ACTIVE_BINARY?: Maybe<HolidayActiveBinaryUnits>;
  HOT_WATER_USAGE?: Maybe<HotWaterUsageUnits>;
  HUMIDITY?: Maybe<HumidityUnits>;
  INLET_ISOLATION_COMMAND_BINARY?: Maybe<InletIsolationCommandBinaryUnits>;
  INLET_ISOLATION_STATUS_BINARY?: Maybe<InletIsolationStatusBinaryUnits>;
  INTERIOR_SPACE_TEMPERATURE?: Maybe<InteriorSpaceTemperatureUnits>;
  ISOLATION_COMMAND_BINARY?: Maybe<IsolationCommandBinaryUnits>;
  ISOLATION_STATUS_BINARY?: Maybe<IsolationStatusBinaryUnits>;
  LEAVING_TEMPERATURE?: Maybe<LeavingTemperatureUnits>;
  LEAVING_TEMPERATURE_SETPOINT?: Maybe<LeavingTemperatureSetpointUnits>;
  LEAVING_WATER_TEMPERATURE?: Maybe<LeavingWaterTemperatureUnits>;
  LEAVING_WATER_TEMPERATURE_SETPOINT?: Maybe<LeavingWaterTemperatureSetpointUnits>;
  LOW_LEVEL_SETPOINT?: Maybe<LowLevelSetpointUnits>;
  MAKEUP_COMMAND_BINARY?: Maybe<MakeupCommandBinaryUnits>;
  MAKEUP_POSITION?: Maybe<MakeupPositionUnits>;
  MAKEUP_POSITION_COMMAND?: Maybe<MakeupPositionCommandUnits>;
  MAKEUP_STATUS_BINARY?: Maybe<MakeupStatusBinaryUnits>;
  MAX_SPEED_COMMAND?: Maybe<MaxSpeedCommandUnits>;
  MINIMUM_LOCKOUT_TEMPERATURE?: Maybe<MinimumLockoutTemperatureUnits>;
  MINIMUM_OUTSIDE_AIR_FLOW_RATE_SETPOINT?: Maybe<MinimumOutsideAirFlowRateSetpointUnits>;
  MINIMUM_POSITION_SETPOINT?: Maybe<MinimumPositionSetpointUnits>;
  MIN_SPEED_COMMAND?: Maybe<MinSpeedCommandUnits>;
  MIXED_TEMPERATURE?: Maybe<MixedTemperatureUnits>;
  MIXED_TEMPERATURE_SETPOINT?: Maybe<MixedTemperatureSetpointUnits>;
  NATURAL_GAS_ENERGY?: Maybe<NaturalGasEnergyUnits>;
  NATURAL_GAS_USAGE?: Maybe<NaturalGasUnits>;
  OAD_POSITION_COMMAND_AT_FAN_SPEED?: Maybe<OadPositionCommandAtFanSpeedUnits>;
  OAD_POSITION_COMMAND_AT_MAX_FAN_SPEED?: Maybe<OadPositionCommandAtMaxFanSpeedUnits>;
  OAD_POSITION_COMMAND_AT_MIN_FAN_SPEED?: Maybe<OadPositionCommandAtMinFanSpeedUnits>;
  OFF_PID_LIMIT?: Maybe<OffPidLimitUnits>;
  OIL_TEMPERATURE?: Maybe<OilTemperatureUnits>;
  ONE_THIRD_POSITION?: Maybe<OneThirdPositionUnits>;
  ONE_THIRD_POSITION_COMMAND?: Maybe<OneThirdPositionCommandUnits>;
  ON_PID_LIMIT?: Maybe<OnPidLimitUnits>;
  OUTDOOR_AIR_TEMPERATURE?: Maybe<OutdoorAirTemperatureUnits>;
  OUTLET_ISOLATION_COMMAND_BINARY?: Maybe<OutletIsolationCommandBinaryUnits>;
  OUTLET_ISOLATION_STATUS_BINARY?: Maybe<OutletIsolationStatusBinaryUnits>;
  OUTSIDE_AIR_CO2_CONCENTRATION?: Maybe<OutsideAirCo2ConcentrationUnits>;
  OUTSIDE_AIR_ENTHALPY?: Maybe<OutsideAirEnthalpyUnits>;
  OUTSIDE_AIR_PERCENTAGE?: Maybe<OutsideAirPercentageUnits>;
  OUTSIDE_AIR_TEMPERATURE_HIGH_LOCKOUT_COMMAND?: Maybe<OutsideAirTemperatureHighLockoutCommandUnits>;
  OUTSIDE_AIR_TEMPERATURE_LOCKOUT_LIMIT?: Maybe<OutsideAirTemperatureLockoutLimitUnits>;
  OUTSIDE_AIR_TEMPERATURE_LOCKOUT_SETPOINT?: Maybe<OutsideAirTemperatureLockoutSetpointUnits>;
  OUTSIDE_RELATIVE_HUMIDITY?: Maybe<OutsideRelativeHumidityUnits>;
  PID_LOOP_OUTPUT?: Maybe<PidLoopOutputUnits>;
  POSITION?: Maybe<PositionUnits>;
  POSITION_COMMAND?: Maybe<PositionCommandUnits>;
  PROPANE_USAGE?: Maybe<NaturalGasUnits>;
  PURGE_BINARY?: Maybe<PurgeBinaryUnits>;
  RELATIVE_HUMIDITY?: Maybe<RelativeHumidityUnits>;
  RELATIVE_HUMIDITY_SETPOINT?: Maybe<RelativeHumiditySetpointUnits>;
  RETURN_AIR_CO2_CONCENTRATION?: Maybe<ReturnAirCo2ConcentrationUnits>;
  RETURN_AIR_CO2_CONCENTRATION_SETPOINT?: Maybe<ReturnAirCo2ConcentrationSetpointUnits>;
  RETURN_AIR_ENTHALPY?: Maybe<ReturnAirEnthalpyUnits>;
  RETURN_AIR_RELATIVE_HUMIDITY?: Maybe<ReturnAirRelativeHumidityUnits>;
  RETURN_AIR_RELATIVE_HUMIDITY_SETPOINT?: Maybe<ReturnAirRelativeHumiditySetpointUnits>;
  RETURN_AIR_TEMPERATURE?: Maybe<ReturnAirTemperatureUnits>;
  RETURN_AIR_TEMPERATURE_SETPOINT?: Maybe<ReturnAirTemperatureSetpointUnits>;
  RETURN_AIR_TEMPERATURE_SETPOINT_HIGH_LIMIT?: Maybe<ReturnAirTemperatureSetpointHighLimitUnits>;
  RETURN_AIR_TEMPERATURE_SETPOINT_LOW_LIMIT?: Maybe<ReturnAirTemperatureSetpointLowLimitUnits>;
  RETURN_CONDENSATE_TEMPERATURE?: Maybe<ReturnCondensateTemperatureUnits>;
  RETURN_WATER_FLOW_RATE?: Maybe<ReturnWaterFlowRateUnits>;
  RETURN_WATER_TEMPERATURE?: Maybe<ReturnWaterTemperatureUnits>;
  RETURN_WATER_TEMPERATURE_SETPOINT?: Maybe<ReturnWaterTemperatureSetpointUnits>;
  RUNTIME_LIFETIME_CUMULATIVE?: Maybe<RuntimeLifetimeCumulativeUnits>;
  RUNTIME_YEARLY_CUMULATIVE?: Maybe<RuntimeYearlyCumulativeUnits>;
  SCHEDULE_ACTIVE_BINARY?: Maybe<ScheduleActiveBinaryUnits>;
  SCHEDULE_BINARY?: Maybe<ScheduleBinaryUnits>;
  SPACE_AIR_CO2_CONCENTRATION?: Maybe<SpaceAirCo2ConcentrationUnits>;
  SPACE_AIR_CO2_CONCENTRATION_SETPOINT?: Maybe<SpaceAirCo2ConcentrationSetpointUnits>;
  SPACE_RELATIVE_HUMIDITY?: Maybe<SpaceRelativeHumidityUnits>;
  SPACE_RELATIVE_HUMIDITY_SETPOINT?: Maybe<SpaceRelativeHumiditySetpointUnits>;
  SPACE_STATIC_PRESSURE?: Maybe<SpaceStaticPressureUnits>;
  SPACE_STATIC_PRESSURE_SETPOINT?: Maybe<SpaceStaticPressureSetpointUnits>;
  SPACE_TEMPERATURE?: Maybe<SpaceTemperatureUnits>;
  SPACE_TEMPERATURE_SETPOINT?: Maybe<SpaceTemperatureSetpointUnits>;
  SPACE_TEMPERATURE_SETPOINT_COOLING?: Maybe<SpaceTemperatureSetpointCoolingUnits>;
  SPACE_TEMPERATURE_SETPOINT_HEATING?: Maybe<SpaceTemperatureSetpointHeatingUnits>;
  SPACE_TEMPERATURE_SETPOINT_RESET_INCREMENT_LIMIT?: Maybe<SpaceTemperatureSetpointResetIncrementLimitUnits>;
  SPEED?: Maybe<SpeedUnits>;
  SPEED_COMMAND?: Maybe<SpeedCommandUnits>;
  STATIC_PRESSURE?: Maybe<StaticPressureUnits>;
  STATIC_PRESSURE_SETPOINT?: Maybe<StaticPressureSetpointUnits>;
  STATIC_PRESSURE_SETPOINT_HIGH_LIMIT?: Maybe<StaticPressureSetpointHighLimitUnits>;
  STATIC_PRESSURE_SETPOINT_LOW_LIMIT?: Maybe<StaticPressureSetpointLowLimitUnits>;
  STATUS_BINARY?: Maybe<StatusBinaryUnits>;
  STEAM_MASS?: Maybe<SteamMassUnits>;
  STEAM_PRESSURE?: Maybe<SteamPressureUnits>;
  STEAM_PRESSURE_SETPOINT?: Maybe<SteamPressureSetpointUnits>;
  STEAM_TEMPERATURE?: Maybe<SteamTemperatureUnits>;
  STEAM_USAGE?: Maybe<SteamUsageUnits>;
  SUPPLY_AIR_CO2_CONCENTRATION?: Maybe<SupplyAirCo2ConcentrationUnits>;
  SUPPLY_AIR_CO2_CONCENTRATION_SETPOINT?: Maybe<SupplyAirCo2ConcentrationSetpointUnits>;
  SUPPLY_AIR_RELATIVE_HUMIDITY?: Maybe<SupplyAirRelativeHumidityUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_HIGH_LIMIT?: Maybe<SupplyAirStaticPressureSetpointHighLimitUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_LOW_LIMIT?: Maybe<SupplyAirStaticPressureSetpointLowLimitUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT?: Maybe<SupplyAirStaticPressureSetpointResetFanSpeedHighLimitUnits>;
  SUPPLY_AIR_STATIC_PRESSURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT?: Maybe<SupplyAirStaticPressureSetpointResetFanSpeedLowLimitUnits>;
  SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_HIGH_LIMIT?: Maybe<SupplyAirTemperatureSetpointResetFanSpeedHighLimitUnits>;
  SUPPLY_AIR_TEMPERATURE_SETPOINT_RESET_FAN_SPEED_LOW_LIMIT?: Maybe<SupplyAirTemperatureSetpointResetFanSpeedLowLimitUnits>;
  SUPPLY_TEMPERATURE?: Maybe<SupplyTemperatureUnits>;
  SUPPLY_TEMPERATURE_SETPOINT?: Maybe<SupplyTemperatureSetpointUnits>;
  SUPPLY_TEMPERATURE_SETPOINT_DELTA?: Maybe<SupplyTemperatureSetpointDeltaUnits>;
  SUPPLY_TEMPERATURE_SETPOINT_HIGH_LIMIT?: Maybe<SupplyTemperatureSetpointHighLimitUnits>;
  SUPPLY_TEMPERATURE_SETPOINT_LOW_LIMIT?: Maybe<SupplyTemperatureSetpointLowLimitUnits>;
  SUPPLY_WATER_FLOW_RATE?: Maybe<SupplyWaterFlowRateUnits>;
  SUPPLY_WATER_TEMPERATURE?: Maybe<SupplyWaterTemperatureUnits>;
  SUPPLY_WATER_TEMPERATURE_SETPOINT?: Maybe<SupplyWaterTemperatureSetpointUnits>;
  SWITCH_STATUS_BINARY?: Maybe<SwitchStatusBinaryUnits>;
  SYSTEM_ENABLE_OUTSIDE_AIR_TEMPERATURE_SETPOINT?: Maybe<SystemEnableOutsideAirTemperatureSetpointUnits>;
  TANK_CAPACITY?: Maybe<TankCapacityUnits>;
  TEMPERATURE?: Maybe<TemperatureUnit>;
  TWO_THIRD_POSITION?: Maybe<TwoThirdPositionUnits>;
  TWO_THIRD_POSITION_COMMAND?: Maybe<TwoThirdPositionCommandUnits>;
  UNOCCUPIED_COOLING_SETPOINT?: Maybe<UnoccupiedCoolingSetpointUnits>;
  UNOCCUPIED_HEATING_SETPOINT?: Maybe<UnoccupiedHeatingSetpointUnits>;
  WARMUP_BINARY?: Maybe<WarmupBinaryUnits>;
  WATER_FLOW_RATE?: Maybe<WaterFlowRateUnits>;
  WATER_USAGE?: Maybe<WaterUsageUnits>;
  WET_BULB?: Maybe<WetBulbUnits>;
  ZONE_OVERRIDE_COMMAND?: Maybe<ZoneOverrideCommandUnits>;
  ZONE_RETURN_AIR_TEMPERATURE?: Maybe<ZoneReturnAirTemperatureUnits>;
};

export enum UnoccupiedCoolingSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export enum UnoccupiedHeatingSetpointUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type UnoccupiedOperationEventData = EventData & {
  __typename?: 'UnoccupiedOperationEventData';
  actualEnergies: Array<Maybe<Measurement>>;
  blendedRate: BlendedRates;
  detectedInPeriod: TimeRange;
  emissionsConversionRate: EmissionsConversionRate;
  nonOperatingTimeRanges: Array<TimeRange>;
  optimumEnergies: Array<Measurement>;
  persistDays?: Maybe<Scalars['Float']>;
  rampDownEndTime?: Maybe<Scalars['DateTime']>;
  rampUpStartTime?: Maybe<Scalars['DateTime']>;
};

export type UpdateBaselineModelInput = {
  isGlobalDefault?: InputMaybe<Scalars['Boolean']>;
  isValid?: InputMaybe<Scalars['Boolean']>;
  modelId: Scalars['String'];
  modelName?: InputMaybe<Scalars['String']>;
};

export type UpdateBaselineModelResponse = {
  __typename?: 'UpdateBaselineModelResponse';
  message?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type UpdateEquipmentInput = {
  customerId?: InputMaybe<Scalars['String']>;
  equipmentType?: InputMaybe<EquipmentType>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateEquipmentPointInput = {
  customerPointId?: InputMaybe<Scalars['String']>;
  equipmentId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  ingestPointId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pointType?: InputMaybe<PointType>;
};

export type UpdateEquipmentPointResponse = {
  __typename?: 'UpdateEquipmentPointResponse';
  code: Scalars['String'];
  message: Scalars['String'];
  point?: Maybe<EquipmentPoint>;
  success: Scalars['Boolean'];
};

export type UpdateEquipmentResponse = {
  __typename?: 'UpdateEquipmentResponse';
  equipment?: Maybe<Equipment>;
};

export type UpdateMeasureDefaultAssigneeForBuildingInput = {
  buildingId: Scalars['String'];
  defaultAssigneeId: Scalars['String'];
};

export type UpdateMeasureDefaultAssigneeForBuildingResponse = {
  __typename?: 'UpdateMeasureDefaultAssigneeForBuildingResponse';
  buildingId: Scalars['String'];
  defaultAssigneeId?: Maybe<Scalars['String']>;
};

export type UpdateMeasureDetailsInput = {
  avoidableCost?: InputMaybe<AvoidableCostInput>;
  measureId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  observation?: InputMaybe<Scalars['String']>;
  recommendation?: InputMaybe<Scalars['String']>;
  temporaryEquipmentName?: InputMaybe<Scalars['String']>;
};

export type UpdateMeasureImplementedDateInput = {
  implementedDate: Scalars['DateTime'];
  measureId: Scalars['ID'];
};

export type UpdatePathwayPointSetInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UpdatePathwayPointSetResponse = {
  __typename?: 'UpdatePathwayPointSetResponse';
  pathwayPointSet?: Maybe<PathwayPointSet>;
};

export type UpdateServiceAccountInput = {
  accountNumber?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  startDate?: InputMaybe<Scalars['DateTime']>;
  utilityProviderName?: InputMaybe<Scalars['String']>;
};

export type UpdateUserPreferencesResponse = {
  __typename?: 'UpdateUserPreferencesResponse';
  preferences?: Maybe<UserPreferences>;
};

export type UpdatedFieldActivity = Activity & {
  __typename?: 'UpdatedFieldActivity';
  createdBy?: Maybe<User>;
  description: Scalars['String'];
  details?: Maybe<UpdatedFieldActivityDetails>;
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: ActivityType;
};

export type UpdatedFieldActivityDetails = {
  __typename?: 'UpdatedFieldActivityDetails';
  field?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
};

export type UsageByEnergySource = {
  __typename?: 'UsageByEnergySource';
  availableGranularities: Array<Granularity>;
  granularity: Granularity;
  unit: Unit;
  usageByFuelCategory: Array<Maybe<UsageByFuelCategory>>;
  values: Array<Maybe<EnergySourceUsage>>;
};

export type UsageByFuelCategory = {
  __typename?: 'UsageByFuelCategory';
  carbonFree?: Maybe<Scalars['Float']>;
  fossilFuels?: Maybe<Scalars['Float']>;
  timestamp: Scalars['DateTime'];
};

export type UsageVsBaselineIntervalData = {
  __typename?: 'UsageVsBaselineIntervalData';
  baselineUsage: Scalars['Float'];
  timestamp: Scalars['DateTime'];
  usage: Scalars['Float'];
};

export type UsageVsBaselineReportData = {
  __typename?: 'UsageVsBaselineReportData';
  period: UsageVsBaselineReportPeriod;
  unit: Unit;
  values: Array<Maybe<UsageVsBaselineIntervalData>>;
};

export enum UsageVsBaselineReportPeriod {
  LAST_30_DAYS = 'LAST_30_DAYS'
}

export type UsageVsBaselineTotalsReportData = {
  __typename?: 'UsageVsBaselineTotalsReportData';
  period: UsageVsBaselineReportPeriod;
  totalBaselineUsage: Scalars['Float'];
  totalUsage: Scalars['Float'];
  unit: Unit;
};

export type User = {
  __typename?: 'User';
  alerts?: Maybe<Array<Maybe<Alert>>>;
  buildings?: Maybe<Array<Maybe<Building>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastLoggedIn?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  lastStatusChange?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
  preferences: UserPreferences;
  roles: Array<Role>;
  ssoIntegrations: Array<SsoIntegration>;
  status: UserStatus;
  subscriptions?: Maybe<Array<Maybe<Subscription>>>;
  timezone: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  locale: Locale;
  preferredUnits: UnitPreference;
};

export type UserPreferencesInput = {
  locale: Locale;
};

export type UserRegistrationInput = {
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale?: InputMaybe<Locale>;
  organizationId: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Role>>>;
  sendPasswordResetEmail?: InputMaybe<Scalars['Boolean']>;
  timezone: Scalars['String'];
};

export type UserRegistrationPayload = {
  __typename?: 'UserRegistrationPayload';
  user: User;
};

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum UserStatusCriteria {
  ACTIVE = 'ACTIVE',
  BOTH_ACTIVE_INACTIVE = 'BOTH_ACTIVE_INACTIVE',
  INACTIVE = 'INACTIVE'
}

export type UserUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  user: User;
};

export type VarianceNote = {
  __typename?: 'VarianceNote';
  comparisonYear?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enteredBy?: Maybe<User>;
  note?: Maybe<Scalars['String']>;
  reasons?: Maybe<Array<Maybe<Reason>>>;
};

export enum VendorType {
  CONSOLIDATED = 'CONSOLIDATED',
  DELIVERY = 'DELIVERY',
  SUPPLY = 'SUPPLY'
}

export type VirtualPoint = PointInterface & {
  __typename?: 'VirtualPoint';
  customerPointId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  ingestPointId?: Maybe<Scalars['String']>;
  lastReceiveTime?: Maybe<Scalars['DateTime']>;
  lowerSpikeThreshold?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pointData: IntervalData;
  sourcePoint: Point;
  spikeThreshold?: Maybe<Scalars['Int']>;
  transformations: Array<Maybe<PointTransformation>>;
  type: PointType;
};


export type VirtualPointPointDataArgs = {
  granularity?: InputMaybe<Granularity>;
  range: TimeRangeInput;
  unitPreferences?: InputMaybe<PreferredUnitsInput>;
};

export enum WarmupBinaryUnits {
  BINARY = 'BINARY'
}

export enum WaterFlowRateUnits {
  GALLONS_PER_MINUTE = 'GALLONS_PER_MINUTE',
  LITERS_PER_SECOND = 'LITERS_PER_SECOND'
}

export enum WaterUsageUnits {
  CUBIC_METERS = 'CUBIC_METERS',
  GALLONS = 'GALLONS'
}

export type WeatherAggregate = {
  __typename?: 'WeatherAggregate';
  averageHumidity?: Maybe<Scalars['Float']>;
  averageTemperature?: Maybe<Scalars['Float']>;
};

export type WeatherData = {
  __typename?: 'WeatherData';
  aggregateMeasurements?: Maybe<WeatherAggregate>;
  granularity: Granularity;
  temperatureUnit: TemperatureUnit;
  timeRange: TimeRange;
  values: Array<Maybe<WeatherMeasurement>>;
};

export type WeatherMeasurement = {
  __typename?: 'WeatherMeasurement';
  relativeHumidity?: Maybe<Scalars['Float']>;
  temperature?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['DateTime']>;
};

export enum WetBulbUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type WorkOrderForMeasureResponse = {
  __typename?: 'WorkOrderForMeasureResponse';
  workOrderId: Scalars['String'];
};

export type YtdCarbon = {
  __typename?: 'YTDCarbon';
  carbonEmissionsUnit: Unit;
  totalCarbonEmissions: Scalars['Float'];
  totalUsage: Scalars['Float'];
  usageUnit: Unit;
};

export type YearBilling = {
  __typename?: 'YearBilling';
  monthlyBilling?: Maybe<Array<Maybe<MonthBilling>>>;
  totals?: Maybe<Totals>;
  totalsToDate?: Maybe<Totals>;
  year: Scalars['Int'];
};

export type YearlyReporting = {
  __typename?: 'YearlyReporting';
  monthlyReporting: Array<Maybe<MonthlyReporting>>;
  totals?: Maybe<TotalReport>;
  totalsToDate?: Maybe<TotalReport>;
  year: Scalars['Int'];
};

export type YearlyUtilityData = {
  __typename?: 'YearlyUtilityData';
  commodityName: CommodityName;
  currency?: Maybe<Currency>;
  monthlyBilling?: Maybe<Array<MonthlyUtilityData>>;
  totals?: Maybe<Totals>;
  totalsToDate?: Maybe<Totals>;
  unit?: Maybe<Unit>;
  year: Scalars['Int'];
};

export enum ZoneOverrideCommandUnits {
  BINARY = 'BINARY'
}

export enum ZoneReturnAirTemperatureUnits {
  CELSIUS = 'CELSIUS',
  FAHRENHEIT = 'FAHRENHEIT'
}

export type DeleteSubscriptionsByAssetIdPayload = {
  __typename?: 'deleteSubscriptionsByAssetIdPayload';
  assetId: Scalars['String'];
};
