import styled from "styled-components/macro";

const TextArea = styled.textarea<{ height?: string }>`
  width: 100%;
  height: ${props => (props.height ? props.height : "100px")};
  padding: 12px 16px;
  border-radius: 0;
`;

export default TextArea;
