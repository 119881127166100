import * as React from "react";
import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";

export interface ISidebarPageProps {
  sidebar?: React.ReactNode;
  children?: React.ReactNode;
}

export const SidebarPageContentArea = styled.div`
  height: 100%;
  flex: 1;
  background-color: ${AppColors.neutral["light-gray-9"]};
  padding: 14px 32px 24px 24px;
  min-width: 0;
  overflow-y: auto;
`;

const SidebarPage: React.FC<ISidebarPageProps> = styled.div`
  display: flex;
  height: calc(100vh - 60px);
  /* This makes the asset selector move to the top on small screens */
  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;
  }
`;

export const SideBarContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background: ${AppColors.neutral["light-navy-1"]};
  /* This makes the asset selector move to the top on small screens */
  @media (max-width: 600px) {
    height: auto;
    display: block;
  }
`;

export const StyledSidebarPage = ({ children, sidebar }: ISidebarPageProps) => (
  <SidebarPage>
    <SideBarContainer>{sidebar}</SideBarContainer>
    <SidebarPageContentArea>{children}</SidebarPageContentArea>
  </SidebarPage>
);

export default SidebarPage;
