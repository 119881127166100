import mixpanel from "mixpanel-browser";
import config from "src/configuration";
import Auth from "src/auth/Auth";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { UserData } from "./types/user";

mixpanel.init(config.MIXPANEL_TOKEN);

export const setDDUserContext = ({
                                   userId,
                                   organizationId,
                                 }: {
  userId: string;
  organizationId: string;
}) => {
  datadogLogs.setLoggerGlobalContext({
    id: userId,
    service: "hatch-ui",
  });
  datadogRum.addRumGlobalContext("usr", {
    id: userId,
    organizationId,
    service: "hatch-ui",
  });
  datadogRum.setUser({
    id: userId,
    organizationId,
  });
};

export const tracking = {
  fireEvent: (eventName: string, eventProperties: Record<string, any>) => {
    let userId = Auth.getUserId();
    if (userId) {
      eventProperties = eventProperties || {};
      eventProperties.userId = userId;
    }
    mixpanel.track(eventName, eventProperties);
    datadogRum.addUserAction(eventName, eventProperties);
  },
  timeEvent: (eventName: string) => {
    if (!mixpanel.has_opted_out_tracking()) {
      mixpanel.time_event(eventName);
    }
  },
  fireLoggedIn: (id: string, rememberMe: boolean) => {
    mixpanel.identify(id);
    tracking.fireEvent("LogInSucceeded", { rememberMe: rememberMe });
  },
  setDatadogContext: (userData: UserData) => {
    setDDUserContext({
      userId: userData.id,
      organizationId: userData.organizationId,
    });
  },
  fireErrorEvent: (
    eventName: string,
    eventProperties: Record<string, any> = {},
    error: unknown = {},
  ) => {
    let userId = Auth.getUserId();
    eventProperties = eventProperties || {};
    if (userId) {
      eventProperties.userId = userId;
    }
    datadogRum.addError(error, eventProperties, "custom");
    eventProperties.error = error;
    mixpanel.track(eventName, eventProperties);
  },
  identifyUser: (userId: string) => {
    mixpanel.identify(userId);
    mixpanel.clear_opt_in_out_tracking();
  },
  optOutTracking: () => {
    mixpanel.opt_out_tracking({ delete_user: true });
  },
};
