import { FeatureEnablement, Maybe } from "../../../../types/graphql";
import { Body, InlineH4, InfoText2 } from "src/components/common/Typography";
import React from "react";
import { useQuery } from "@apollo/client";
import { BuildingMetaDataDocument } from "../../../../queries/typed";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import { VerticalLayout } from "src/components/common/Layout/VerticalLayout";
import { Asset } from "../LastSelectionContext";
import { BasicData } from "../FloatingAdminTools";

const AdvancedData = (props: { asset: Asset }) => {
  const { loading, data, error } = useQuery(BuildingMetaDataDocument, {
    variables: {
      id: props.asset.assetId,
    },
  });

  if (loading) {
    return <CenteredLoadingIndicator />;
  } else if (error) {
    return <div>Loading failed.</div>;
  } else if (data && data.getBuildingById) {
    // NOTE: to change the layout of the DT/DLs, there is some commented out
    // code up in the
    return (
      <VerticalLayout childSpacing={4}>
        <dl>
          <dt>
            <InfoText2>Customer Building Id:</InfoText2>
          </dt>
          <dl>
            <InlineH4>
              {data.getBuildingById.customerBuildingId ?? "--"}
            </InlineH4>
          </dl>
          <dt>
            <InfoText2>Include in Aggregates:</InfoText2>
          </dt>
          <dl>
            <InlineH4>
              {data.getBuildingById.includeInGroupAggregate ? "true" : "false"}
            </InlineH4>
          </dl>
          <dt>
            <InfoText2>Square footage:</InfoText2>
          </dt>
          <dl>
            <InlineH4>{data.getBuildingById.sqft}</InlineH4>
          </dl>
        </dl>
        <EnablementsTable enablements={data.getBuildingById.enablements} />
      </VerticalLayout>
    );
  } else {
    return (
      <div>
        <Body>Unknown Selection Type</Body>
      </div>
    );
  }
};

const EnablementsTable = (props: {
  enablements:
    | Array<
        Maybe<
          { __typename?: "FeatureEnablement" } & Pick<
            FeatureEnablement,
            "name" | "enabled"
          >
        >
      >
    | null
    | undefined;
}) => {
  const { enablements } = props;
  if (enablements === undefined || enablements === null) {
    return <div>Enablements: Default</div>;
  }
  if (enablements.length > 0) {
    return (
      <div>
        <Body>Enablements</Body>
        <table>
          <thead>
            <tr>
              <td>
                <InfoText2>Name</InfoText2>
              </td>
              <td>
                <InfoText2>Enabled?</InfoText2>
              </td>
            </tr>
          </thead>
          <tbody>
            {enablements.map(enb => {
              if (enb === undefined || enb === null) {
                return null;
              }
              return (
                <tr key={enb.name}>
                  <td>
                    <InlineH4>{enb.name}</InlineH4>
                  </td>
                  <td>
                    <InlineH4>{enb.enabled ? "true" : "false"}</InlineH4>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>Enablements: Default</div>;
  }
};
export const LoadedBuilding = (props: {
  asset: Asset
}) => {
  return (
    <>
      <BasicData asset={props.asset}/>
      <AdvancedData asset={props.asset}/>
    </>
  );
};