import { useQuery } from "@apollo/client";
import { useSelect } from "downshift";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import {
  AdjustedSelectButton,
  DropdownMenu,
  DropdownOption,
  DropdownWrapper,
} from "src/components/common/Dropdown/Dropdown";
import { GetHolidaysForScheduleYearDocument } from "src/queries/typed";
import { Holiday } from "src/types/schedules";
import { iso1A3Code } from "@ideditor/country-coder";
// @ts-ignore
import * as codeConverter from "@unly/iso3166-1";
// @ts-ignore
import * as mun from "model-un";

export const getCountryCodeByLatLong = (lat: number | null, long: number | null): string => {
  if (!lat || !long) {
    return "USA";
  }
  const countryCode = iso1A3Code([long, lat]);
  return countryCode ? countryCode : "USA";
};

export const shouldIncludeStateHolidays = (countryCode: string): boolean => {
  // If the country needs to show state/provincial holidays in addition to national ones, add its ISO3166-1-Alpha-3 country code to this list
  const countriesWithStateHolidays = ["CAN"];
  return countriesWithStateHolidays.includes(countryCode) ? true : false;
};

export const getLanguageForCountry = (countryCode: string): string => {
  // Language-getter only accepts ISO3166-1-Alpha-2, so we have to convert it first
  const code = codeConverter.from(countryCode).to2();
  return mun.getOfficialLanguages(code)[0].slice(0, 2) ?? "en";
};

const HolidaySelect = React.forwardRef<{},
  {
    year: number;
    lat: number | null;
    long: number | null;
    onChange: (holiday?: Holiday) => void;
    width?: string;
    defaultSelected?: string;
  }>(({ year, onChange, width = "75%", defaultSelected, lat, long }, ref) => {
  const country = getCountryCodeByLatLong(lat, long);
  const subdivisions = shouldIncludeStateHolidays(country) || false;
  const language = getLanguageForCountry(country);

  const { data: holidayResponse, refetch } = useQuery(
    GetHolidaysForScheduleYearDocument,
    {
      variables: {
        year,
        country,
        subdivisions,
        language,
      },
    },
  );

  useEffect(() => {
    refetch();
  }, [lat, long]);

  const {
    selectedItem,
    isOpen,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
  } = useSelect<Holiday>({
    items: holidayResponse?.holidays?.holidays || [],
    defaultSelectedItem: holidayResponse?.holidays?.holidays.find(
      x => x.name === defaultSelected,
    ),
    onSelectedItemChange: changes => {
      onChange(changes.selectedItem);
    },
  });

  return (
    <>
      <DropdownWrapper maxWidth={width}>
        <AdjustedSelectButton type="button" {...getToggleButtonProps()}>
          {selectedItem ? (
            selectedItem.name
          ) : (
            <FormattedMessage id="settings.building.operatingScheduleExceptions.modal.title.header" />
          )}
        </AdjustedSelectButton>
        <DropdownMenu {...getMenuProps()}>
          {isOpen &&
            holidayResponse?.holidays?.holidays.map((item, index) => (
              <DropdownOption
                ref={ref}
                id="holiday"
                selected={highlightedIndex === index}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
              >
                {item.name}
              </DropdownOption>
            ))}
        </DropdownMenu>
      </DropdownWrapper>
    </>
  );
});

export default HolidaySelect;
