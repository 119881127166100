import { dateToLocaleString, FormatOptions } from "@hatchdata/intl-formatter";
import { DateTime } from "luxon";

declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, filename: string) => void;
  }
}

export interface CSVData {
  [key: string]: string | number | Date | null | undefined;
}
/**
 * Creates a file and downloads it to the user's PC
 * @param content contents of the file
 * @param filename the name of the file
 */
export const generateFile = (content: string, filename: string) => {
  if (content.length === 0) {
    return;
  }

  const blob = new Blob([content], { type: "text/csv;charset=utf-8;" });
  downloadBlobFile(blob, filename);
  /*
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  */
};
/**
 * Creates a file and downloads it to the user's PC
 * @param blob Blob contents of the file
 * @param filename the name of the file
 */
export const downloadBlobFile = (blob: Blob, filename: string) => {
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      //document.body.appendChild(link);
      link.click();
      //document.body.removeChild(link);
    }
  }
};

const cellToString = (
  cell: string | number | Date | null | undefined,
  timezone: string | undefined,
) => {
  if (cell === null || cell === undefined) {
    return "";
  }

  cell =
    cell instanceof Date
      ? dateToLocaleString(
          cell,
          undefined,
          timezone,
          FormatOptions.DATETIME_SHORT,
        )
      : cell.toString().replace(/"/g, '""');

  if (cell.toString().search(/("|,|\n)/g) >= 0) {
    return `"${cell}"`;
  }

  return cell;
};

/**
 * Processes a CSVData object and returns it as a string using the provided separator.
 * Due to a bug / change of behavior in the way a browser ordered keys (it was just the
 * order we put them in, but sometimes they now move around!) and a customer complaining,
 * you can also force certain keys to be in the front. I have made some effort to ensure
 * that it will handle you putting nonexistant keys in this optional param, but you
 * should try not to do that for obvious reasons.
 *
 *
 * @param rows records to process
 * @param timezone timezone used to format dates
 * @param separator separator used to separate values, default is ','
 * @param frontOrderKeys list of keys to be the first keys, in the order you want them.
 */
export const csvDataToString = (
  rows: CSVData[],
  timezone: string | undefined,
  separator: string = ",",
  frontOrderKeys?: string[],
): string => {
  const keys = Object.keys(rows[0]);

  if (frontOrderKeys && frontOrderKeys.length > 0) {
    const rest = keys.filter(k => !frontOrderKeys.includes(k));
    const orderedKeys = [...frontOrderKeys, ...rest];
    const _h = orderedKeys.join(separator);
    const dataStr = rows.reduce((str, row) => {
      str +=
        orderedKeys
          .map(key => cellToString(row[key], timezone))
          .join(separator) + "\n";
      return str;
    }, "");
    return _h + "\n" + dataStr;
  }

  const header = keys.join(separator);
  const lines = rows
    .map(row => {
      return keys
        .map(key => {
          return cellToString(row[key], timezone);
        })
        .join(separator);
    })
    .join("\n");

  return header + "\n" + lines;
};

/**
 * Returns a timestamp formatted as an ISO string. Kinda self explanatory, really.
 * @param timestamp
 */
export const formatISOTimestamp = (timestamp: number): string => {
  const _d = new Date(timestamp);
  return _d.toISOString();
};

/**
 * Returns a timestamp formatted as a date and time for the timezone provided.
 * @param timestamp
 * @param timezone
 */
export const formatSimpleTimestamp = (
  timestamp: number,
  timezone: string | undefined,
): string => {
  return DateTime.fromMillis(timestamp, { zone: timezone }).toFormat(
    "y-MM-dd t",
  );
};
