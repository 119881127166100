import { AnnotationEventTooltip } from "src/components/app/ExplorerPage/ChartFactory/AnnotationEventTooltip";
import MeasureEventTooltip from "src/components/app/ExplorerPage/ChartFactory/MeasureEventTooltip";
import ScheduleEventTooltip from "src/components/app/ExplorerPage/ChartFactory/ScheduleEventTooltip";
import { mapEventTypeToOverlayType } from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";
import {
  AnnotationEvent,
  AnnotationEventType,
  MeasureEvent,
  MeasureEventType,
  ScheduleEventType,
  ScheduleExceptionEvent,
} from "src/components/app/ExplorerPage/types";

// This handles overlap cases where a tooltip needs to display different types
// of event data.
export const EventTooltip = (
  events: MeasureEvent[] | ScheduleExceptionEvent[] | AnnotationEvent[],
  currentOrganizationId: string,
  userId: string,
  timezone: string,
  translatedReasons: { [key: string]: string },
) => {
  return events.map(
    (event: MeasureEvent | ScheduleExceptionEvent | AnnotationEvent) => {
      if (mapEventTypeToOverlayType(event.eventType) === MeasureEventType) {
        return MeasureEventTooltip(
          event as MeasureEvent,
          currentOrganizationId,
          userId,
        );
      }
      if (mapEventTypeToOverlayType(event.eventType) === ScheduleEventType) {
        return ScheduleEventTooltip(
          event as ScheduleExceptionEvent,
          currentOrganizationId,
          userId,
          timezone,
        );
      }
      if (mapEventTypeToOverlayType(event.eventType) === AnnotationEventType) {
        return AnnotationEventTooltip(
          event as AnnotationEvent,
          timezone,
          translatedReasons,
        );
      }
      return <></>;
    },
  );
};
