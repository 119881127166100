import React from "react";
import styled from "styled-components/macro";
import {
  MxReactIcon,
  ChevronRight,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "src/components/common/Styling";
/* =============================================================== *\
   ALL THE LITTLE COMPONENTS THAT MAKE THIS UP
   These could probalby move at some point, but for now, they
   are here. And that is okay.

   NOTE: because commodities have to have a different color BG
   that spans the whole width, but the borders on things don't
   this is horribly more complicated than it should be. =(
\* =============================================================== */

export const MultiIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export type CollapseIconProps = {
  open: boolean;
  onClick?: () => void;
  disabled: boolean;
};
export const CollapseIconWrapper = styled.div<CollapseIconProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
  transform: ${props => (props.open ? "rotate(90deg)" : "none")};
  margin-left: 8px;
  margin-right: 0;
  height: 24px;
  width: 24px;

  :hover {
    transform: ${props =>
      props.open ? "scale(1.3) rotate(90deg)" : "scale(1.3)"};
  }
`;

export const CollapseIcon: React.FC<CollapseIconProps> = (
  props: CollapseIconProps,
) => {
  return (
    <CollapseIconWrapper {...props}>
      <MxReactIcon
        Icon={ChevronRight}
        color={AppColors.neutral["light-gray-9"]}
      />
    </CollapseIconWrapper>
  );
};
