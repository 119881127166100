import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  RefObject,
} from "react";
import styled from "styled-components/macro";

const ResizeObject = styled.div`
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: -10;
  flex: 1;
`;

const useOnResize = (
  ref: React.RefObject<HTMLObjectElement>,
  onResize: () => void,
) => {
  useEffect(() => {
    const target =
      ref.current &&
      ref.current.contentDocument &&
      ref.current.contentDocument.defaultView;
    onResize();
    // attach event handler if we exist
    if (target) {
      target.addEventListener("resize", onResize);
    }
    // clean up on unmount
    return () => {
      const isListener =
        target && typeof target.removeEventListener === "function";
      if (target && isListener) {
        target.removeEventListener("resize", onResize);
      }
    };
  }, []);
};

interface IResizeListener {
  onResize: (ref: React.RefObject<HTMLObjectElement>) => void;
}
const ResizeListener: React.FC<IResizeListener> = ({ onResize }) => {
  const _r = useRef<HTMLObjectElement>(null);
  useOnResize(_r, () => onResize(_r));
  return (
    <ResizeObject
      ref={_r}
      aria-hidden={true}
      aria-label="resize-listener"
      tabIndex={-1}
    />
  );
};

interface IResizeSize {
  height: number | undefined;
  width: number | undefined;
}
type SizeReporter = (target?: HTMLObjectElement) => IResizeSize;
const getSize: SizeReporter = target => {
  if (target) {
    return {
      width: target.offsetWidth,
      height: target.offsetHeight,
    };
  }
  return { height: undefined, width: undefined };
};

const useElementResize = (
  reporter: SizeReporter = getSize,
): [JSX.Element, IResizeSize] => {
  const [sizes, setSizes] = useState<IResizeSize>({
    width: undefined,
    height: undefined,
  });
  const onResize = useCallback(
    (ref: RefObject<HTMLObjectElement>) => {
      return setSizes(reporter(ref.current ?? undefined));
    },
    [reporter],
  );
  const resizeListenerNode = useMemo(
    () => <ResizeListener onResize={onResize} />,
    [onResize],
  );
  return [resizeListenerNode, sizes];
};
export default useElementResize;
