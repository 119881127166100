import { useRef } from "react";

interface UseChartColorsOutput {
  getChartColor: () => string;
  colorForAssetKeyAndCommodity: (
    assetKey: string,
    commodity: string,
    alpha?: number,
  ) => string;
}

type HSLColorValues = {
  h: number;
  s: number;
  l: number;
};

// shamelessly copy / pasted from (and types added):
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

/**
 * Gives you a color to use in a chart. It will loop
 * through a set of hues and provide a variation on that
 * hue each time it is called.
 *
 * The colors returned are in HSL like so: 'hsl(40,50%,60%)'
 */
const useChartColors = (): UseChartColorsOutput => {
  // using useRef here like instance vars. I AM SO CLEVER
  const refIndex = useRef(0);
  const assetColors = useRef<{ [key: string]: HSLColorValues }>({});
  // these colors are returned in order the first `length - 1` times this function is called
  // MAKE THIS RETURN OPACITY SOMEHOW!!!!
  const fixedColors: HSLColorValues[] = [
    { h: 163, s: 61, l: 45 }, // msr-green
    { h: 198, s: 61, l: 45 }, // sky
    { h: 237, s: 26, l: 44 }, // purple
    { h: 18, s: 93, l: 53 }, // orange
    { h: 60, s: 84, l: 41 }, // lime-green
    { h: 177, s: 48, l: 71 }, // seafoam
    { h: 273, s: 46, l: 73 }, // light-purple
    { h: 357, s: 61, l: 64 }, // light-red-1
    { h: 41, s: 100, l: 65 }, // yellow
    { h: 217, s: 19, l: 60 }, // light-navy-6
  ];
  // after that, we cycle through these guys and make random variations on them.
  const hues = [163, 198, 237, 18, 60, 177, 273, 357, 41, 217];

  const sForHue = (h: number): number => {
    switch (h) {
      case 163:
        return getRandomInt(40, 101);
      case 198:
        return getRandomInt(60, 101);
      case 237:
        return getRandomInt(30, 101);
      case 18:
        return getRandomInt(50, 101);
      case 60:
        return getRandomInt(30, 101);
      case 177:
        return getRandomInt(25, 101);
      case 273:
        return getRandomInt(30, 101);
      case 357:
        return getRandomInt(35, 101);
      case 41:
        return getRandomInt(45, 101);
      case 217:
        return getRandomInt(40, 101);
      default:
        return 60;
    }
  };
  const lForHue = (h: number): number => {
    switch (h) {
      case 163:
        return getRandomInt(25, 76);
      case 198:
        return getRandomInt(30, 81);
      case 237:
        return getRandomInt(40, 76);
      case 18:
        return getRandomInt(45, 61);
      case 60:
        return getRandomInt(30, 66);
      case 177:
        return getRandomInt(45, 61);
      case 273:
        return getRandomInt(30, 71);
      case 357:
        return getRandomInt(30, 76);
      case 41:
        return getRandomInt(40, 61);
      case 217:
        return getRandomInt(35, 56);
      default:
        return 60;
    }
  };

  /**
   * Gives you a color object with HSL values. Will give you the same
   * 10 (or however big fixedColors is up there) colors the first
   * 10 times you call it, then will randomly give you varations on
   * those 10 hues in order.
   *
   */
  const colorParts = (): HSLColorValues => {
    if (refIndex.current < fixedColors.length) {
      const _c = fixedColors[refIndex.current];
      refIndex.current = refIndex.current + 1;
      return _c;
    }
    const _h = hues[refIndex.current % hues.length];
    const _s = sForHue(_h);
    const _l = lForHue(_h);
    refIndex.current = refIndex.current + 1;
    return { h: _h, l: _l, s: _s };
  };

  /**
   * Gives you a color in string format. Will give you the same
   * 10 (or however big fixedColors is up there) colors the first
   * 10 times you call it, then will randomly give you varations on
   * those 10 hues in order.
   *
   * @param alpha - you can be transparent if you so desire. Default = 1
   */
  const color = (alpha: number = 1): string => {
    const { h, s, l } = colorParts();
    return `hsla(${h},${s}%,${l}%,${alpha})`;
  };

  /**
   * Gets a color for a compound key made from the two parameters.
   * I will return the same color for the same inputs per instance
   * of the hook (so the same asset + commodity pair always sees
   * the same color while looking at explorer)
   *
   * @param assetKey Actually any key. This is badly named.
   * @param commodity Also any key. This is also badly named!
   */
  const colorForAssetKeyAndCommodity = (
    assetKey: string,
    commodity: string,
    alpha: number = 1,
  ): string => {
    const _key = `${assetKey}_${commodity}`;
    const _current = assetColors.current;
    if (_current[_key]) {
      const { h, s, l } = _current[_key];
      return `hsla(${h},${s}%,${l}%,${alpha})`;
    }
    const _color = colorParts();
    assetColors.current = {
      ..._current,
      [_key]: _color,
    };
    const { h, s, l } = _color;
    return `hsla(${h},${s}%,${l}%,${alpha})`;
  };

  return {
    getChartColor: color,
    colorForAssetKeyAndCommodity,
  };
};
export { useChartColors };
