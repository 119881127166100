import { FormattedMessage } from "react-intl";
import { useUserContext } from "src/auth/UserContext";
import {
  CirclePlus,
  MxReactIcon,
  Square,
  SquareCheck,
  SquareMinus,
} from "src/componentLibrary/react/mx-icon-react";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import {
  OverlayFormAction,
  OverlayFormState,
  OverlayType,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import {
  OverlaysMenuItem,
  OverlaysMenuWrapper,
} from "src/components/app/ExplorerPage/OverlaysMenuWrapper";
import { OverlayEventState } from "src/components/app/ExplorerPage/types";
import { InfoText2, InlineH4 } from "src/components/common/InfoText";
import {
  BookendLayout,
  Box,
  VerticalLayout,
} from "src/components/common/Layout";
import RoleBasedComponent from "src/components/common/RoleOnlyComponent";
import { AppColors } from "src/components/common/Styling";
import { tracking } from "src/tracking";
import { ParsedChartData } from "src/types/charting";
import { UserRole } from "src/types/roles";
import styled from "styled-components/macro";

const ItemLabel = styled(InlineH4)<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  color: ${props =>
    props.isActive
      ? AppColors.neutral.navy
      : AppColors.neutral["light-navy-3"]};
`;

const ClickableIcon = styled(MxReactIcon)`
  cursor: pointer;
`;

type OverlayMenuProps = {
  overlayProps: {
    overlayState: OverlayEventState;
    setOverlayState: React.Dispatch<React.SetStateAction<OverlayEventState>>;
  };
  overlayFormState: OverlayFormState;
  operatingScheduleOnClick: () => void;
  chartDataState: ParsedChartData;
  overlayFormDispatch: React.Dispatch<OverlayFormAction>;
};

export const OverlayMenu = ({
  overlayProps,
  overlayFormState,
  operatingScheduleOnClick,
  chartDataState,
  overlayFormDispatch,
}: OverlayMenuProps) => {
  const { userData } = useUserContext();
  const userId = userData.id;

  const anyAnnotationOn =
    overlayProps.overlayState.PEAK_DEMAND ||
    overlayProps.overlayState.HIGH_USAGE ||
    overlayProps.overlayState.CUSTOM;
  const allAnnotationsOn =
    overlayProps.overlayState.PEAK_DEMAND &&
    overlayProps.overlayState.HIGH_USAGE &&
    overlayProps.overlayState.CUSTOM;

  const handleCreateNewAnnotation = (kind: OverlayType) => {
    // tracking
    tracking.fireEvent(ExplorerPageEvents.BEGIN_ANNOTATION_CREATE, {
      userId,
      type: kind,
      method: "Add new annotation button",
    });
    overlayFormDispatch({
      type: "PRE_FILL_FORM_DATA",
      payload: {
        overlayType: kind,
      },
    });
    overlayFormDispatch({
      type: "OPEN_OVERLAY",
    });
    overlayFormDispatch({
      type: "GO_STEP_ONE",
    });
  };

  const handleCreateNewThreshold = () => {
    overlayFormDispatch({
      type: "OPEN_OVERLAY",
    });
    overlayFormDispatch({
      type: "GO_TO_THRESHOLD",
      payload: {},
    });
  };

  return (
    <OverlaysMenuWrapper
      isActive={Object.values(overlayProps.overlayState).includes(true)}
    >
      <>
        <OverlaysMenuItem
          color={AppColors.primary["msr-green"]}
          intlTextId="charts.explorer.overlays.MeasureIdentified"
          isActive={overlayProps.overlayState.IDENTIFIED}
          onClick={() => {
            tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
              overlay: "Measure Identified",
            });
            overlayProps.setOverlayState(s => ({
              ...s,
              IDENTIFIED: !s.IDENTIFIED,
            }));
          }}
        />
        <OverlaysMenuItem
          color={AppColors.primary["msr-green"]}
          intlTextId="charts.explorer.overlays.MeasureImplemented"
          isActive={overlayProps.overlayState.IMPLEMENTED}
          onClick={() => {
            tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
              overlay: "Measure Implemented",
            });
            overlayProps.setOverlayState(s => ({
              ...s,
              IMPLEMENTED: !s.IMPLEMENTED,
            }));
          }}
        />
        <BookendLayout>
          <OverlaysMenuItem
            color={AppColors.primary["msr-green"]}
            intlTextId="charts.explorer.overlays.ScheduleException"
            isActive={overlayProps.overlayState.SCHEDULE_EXCEPTIONS}
            onClick={() => {
              tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
                overlay: "Schedule Exceptions",
              });
              overlayProps.setOverlayState(s => ({
                ...s,
                SCHEDULE_EXCEPTIONS: !s.SCHEDULE_EXCEPTIONS,
              }));
            }}
          />
          <RoleBasedComponent exclude={[UserRole.READ_ONLY]}>
            <ClickableIcon
              Icon={CirclePlus}
              color={AppColors.primary["msr-green"]}
              onClick={() =>
                handleCreateNewAnnotation(OverlayType.ScheduleException)
              }
            />
          </RoleBasedComponent>
        </BookendLayout>
        <BookendLayout>
          <OverlaysMenuItem
            color={AppColors.primary["msr-green"]}
            intlTextId="charts.explorer.overlays.thresholds"
            isActive={overlayProps.overlayState.THRESHOLDS}
            onClick={() => {
              tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
                overlay: "THRESHOLDS",
              });
              overlayProps.setOverlayState(s => ({
                ...s,
                THRESHOLDS: !s.THRESHOLDS,
              }));
            }}
          />
          <RoleBasedComponent exclude={[UserRole.READ_ONLY]}>
            {/* Only display the plus icon if there are buildings without pre-existing thresholds.
                        If all selected buildings have thresholds already, you should be using the Edit link
                        in the Overlays tab to change them instead. */}
            {chartDataState.thresholds.length <
              overlayFormState.ui.buildingsSelected.length && (
              <ClickableIcon
                Icon={CirclePlus}
                color={AppColors.primary["msr-green"]}
                onClick={() => handleCreateNewThreshold()}
              />
            )}
          </RoleBasedComponent>
        </BookendLayout>
        <ItemLabel
          isActive={anyAnnotationOn}
          onClick={() => {
            tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
              overlay: "ANNOTATIONS",
            });
            // If all on, set all to off, else set all to off
            const newState = !allAnnotationsOn;
            overlayProps.setOverlayState(s => ({
              ...s,
              PEAK_DEMAND: newState,
              HIGH_USAGE: newState,
              CUSTOM: newState,
            }));
          }}
        >
          <MxReactIcon
            style={{
              marginRight: "8px",
            }}
            Icon={
              allAnnotationsOn
                ? SquareCheck
                : anyAnnotationOn
                ? SquareMinus
                : Square
            }
            size="s"
            color={AppColors.primary["msr-green"]}
          />
          <FormattedMessage id={"charts.explorer.overlays.annotations"} />
        </ItemLabel>
        <Box padding={[0, 0, 0, 2]}>
          <VerticalLayout childSpacing={4}>
            <BookendLayout>
              <OverlaysMenuItem
                color={AppColors.primary["msr-green"]}
                intlTextId="charts.explorer.overlays.PeakDemand"
                isActive={overlayProps.overlayState.PEAK_DEMAND}
                onClick={() => {
                  tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
                    overlay: "PEAK_DEMAND",
                  });
                  overlayProps.setOverlayState(s => ({
                    ...s,
                    PEAK_DEMAND: !s.PEAK_DEMAND,
                  }));
                }}
              />
              <RoleBasedComponent exclude={[UserRole.READ_ONLY]}>
                <ClickableIcon
                  Icon={CirclePlus}
                  color={AppColors.primary["msr-green"]}
                  onClick={() =>
                    handleCreateNewAnnotation(OverlayType.PeakDemand)
                  }
                />
              </RoleBasedComponent>
            </BookendLayout>
            <BookendLayout>
              <OverlaysMenuItem
                color={AppColors.primary["msr-green"]}
                intlTextId="charts.explorer.overlays.HighUsage"
                isActive={overlayProps.overlayState.HIGH_USAGE}
                onClick={() => {
                  tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
                    overlay: "HIGH_USAGE",
                  });
                  overlayProps.setOverlayState(s => ({
                    ...s,
                    HIGH_USAGE: !s.HIGH_USAGE,
                  }));
                }}
              />
              <RoleBasedComponent exclude={[UserRole.READ_ONLY]}>
                <ClickableIcon
                  Icon={CirclePlus}
                  color={AppColors.primary["msr-green"]}
                  onClick={() =>
                    handleCreateNewAnnotation(OverlayType.HighUsage)
                  }
                />
              </RoleBasedComponent>
            </BookendLayout>
            <BookendLayout>
              <OverlaysMenuItem
                color={AppColors.primary["msr-green"]}
                intlTextId="charts.explorer.overlays.Custom"
                isActive={overlayProps.overlayState.CUSTOM}
                onClick={() => {
                  tracking.fireEvent(ExplorerPageEvents.TOGGLE_OVERLAY, {
                    overlay: "CUSTOM",
                  });
                  overlayProps.setOverlayState(s => ({
                    ...s,
                    CUSTOM: !s.CUSTOM,
                  }));
                }}
              />
              <RoleBasedComponent exclude={[UserRole.READ_ONLY]}>
                <ClickableIcon
                  Icon={CirclePlus}
                  color={AppColors.primary["msr-green"]}
                  onClick={() => handleCreateNewAnnotation(OverlayType.Custom)}
                />
              </RoleBasedComponent>
            </BookendLayout>
          </VerticalLayout>
        </Box>
        <OverlaysMenuItem
          color={AppColors.primary["msr-green"]}
          intlTextId="charts.explorer.overlays.operatingSchedule"
          isActive={overlayProps.overlayState.OPERATING_SCHEDULE}
          onClick={operatingScheduleOnClick}
          disabled={!chartDataState.overlays?.operatingSchedule?.enabled}
        />
        {!chartDataState.overlays?.operatingSchedule?.enabled && (
          <Box padding={[0, 0, 0, 6]} margin={[-2.5, 0, 0, 0]}>
            <InfoText2 style={{ color: AppColors.neutral["light-navy-9"] }}>
              <FormattedMessage id="charts.explorer.overlays.operatingSchedule.disabled" />
            </InfoText2>
          </Box>
        )}
        {/*
                  <hr />
                  <HorizontalLayout childSpacing={2}>
                    <MxReactIcon
                      Icon={CirclePlus}
                      color={AppColors.primary["msr-green"]}
                    />
                    <HyperLink2 onClick={() => handleOverlayButton()}>
                      <FormattedMessage id="charts.explorer.overlays.create.title" />
                    </HyperLink2>
                  </HorizontalLayout>
                    */}
      </>
    </OverlaysMenuWrapper>
  );
};
