import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";

const CenteredContentWrapper = styled.div`
  background-color: ${AppColors.neutral["light-navy-9"]};
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  position: relative;
  flex-direction: column;
`;

export default CenteredContentWrapper;
