import React from "react";
import { YAxis } from "recharts";
import { YAxisData } from "src/types/charting";

// TODO: get the type Thiago made and use it!

export const yAxes = (axes: YAxisData) => {
  const axesCollection: React.ReactNode[] = [];
  axes.forEach((val, key) => {
    axesCollection.push(<YAxis key={key} yAxisId={key} {...val} />);
  });
  return axesCollection;
};
