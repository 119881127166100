import styled from "styled-components/macro";
import { MeasureEvent, MeasureEventType } from "../types";
import { Body } from "src/components/common/Typography";
import { MxReactIcon, LinkOut } from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "src/components/common/Styling";
import { tracking } from "src/tracking";
import { ExplorerPageEvents } from "../ExplorerPage";
import { BodyNoWrap, VerticalLayout } from "src/components/common/Layout";
import { FormattedMessage } from "react-intl";
import { OverlayType } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import {
  isoToLocaleString,
  formatCurrency,
  FormatOptions,
} from "@hatchdata/intl-formatter";

const Label = styled.span`
  font-weight: 500;
`;

const MeasureEventTooltip = (
  measureEvent: MeasureEvent,
  currentOrganizationId: string,
  userId: string,
) => {
  if (!measureEvent) {
    return <></>;
  }

  const navigateToMeasure = (overlayType: OverlayType) => {
    const url = `/measures/${currentOrganizationId}/measure/${measureEvent.id}`;
    tracking.fireEvent(ExplorerPageEvents.OVERLAY_NEW_TAB, {
      userId,
      overlay: overlayType,
    });
    tracking.fireEvent(ExplorerPageEvents.OPEN_IN_MEASURES, {
      url,
    });
  };

  return (
    <div key={`measure-tooltip-${measureEvent.id}-${measureEvent.eventType}`}>
      <VerticalLayout childSpacing={1}>
        <Body>
          {measureEvent.eventType === MeasureEventType.Implemented && (
            <span style={{ color: AppColors.semantic.lightBlue.seafoam }}>
              ✓
            </span>
          )}
          {measureEvent.eventType === MeasureEventType.Identified && (
            <span style={{ color: AppColors.semantic.blue.sky }}>!</span>
          )}{" "}
          <FormattedMessage
            id={`charts.explorer.overlays.${
              measureEvent.eventType === MeasureEventType.Implemented
                ? "MeasureImplemented"
                : "MeasureIdentified"
            }`}
          />
        </Body>
        <BodyNoWrap title={measureEvent.name}>{measureEvent.name}</BodyNoWrap>
      </VerticalLayout>
      <BodyNoWrap title={measureEvent.buildingName}>
        {measureEvent.buildingName}
      </BodyNoWrap>
      <BodyNoWrap>
        <Label>
          <FormattedMessage id="charts.explorer.overlays.measureTooltip.identified" />
          :{" "}
        </Label>
        {measureEvent.found
          ? isoToLocaleString(
              measureEvent.found,
              undefined,
              undefined,
              FormatOptions.DATE_SHORT,
            )
          : "-"}
      </BodyNoWrap>
      <BodyNoWrap>
        <Label>
          <FormattedMessage id="charts.explorer.overlays.measureTooltip.implemented" />
          :
        </Label>{" "}
        {measureEvent.implemented
          ? isoToLocaleString(
              measureEvent.implemented,
              undefined,
              undefined,
              FormatOptions.DATE_SHORT,
            )
          : "-"}
      </BodyNoWrap>
      <BodyNoWrap>
        <Label>
          <FormattedMessage id="charts.explorer.overlays.measureTooltip.avoidableCost" />
          :{" "}
        </Label>
        {measureEvent.avoidableCost?.monetary?.value &&
        measureEvent.avoidableCost?.monetary?.currency
          ? formatCurrency(
              measureEvent.avoidableCost.monetary.value,
              undefined,
              measureEvent.avoidableCost.monetary.currency,
              0,
            )
          : "-"}
      </BodyNoWrap>
      <BodyNoWrap>
        <a
          style={{ fontWeight: 500 }}
          href={`/measures/${currentOrganizationId}/measure/${measureEvent.id}`}
          target="_blank"
          rel="noreferrer noopener"
          onClick={() => {
            return measureEvent.implemented
              ? navigateToMeasure(OverlayType.MeasureImplemented)
              : navigateToMeasure(OverlayType.MeasureIdentified);
          }}
        >
          <FormattedMessage id="charts.explorer.overlays.measureTooltip.viewInNewTab" />{" "}
          <MxReactIcon Icon={LinkOut} />
        </a>
      </BodyNoWrap>
    </div>
  );
};

export default MeasureEventTooltip;
