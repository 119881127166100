import { useLocation } from "react-router-dom";
import { Redirect } from "src/components/common/Redirect";

export const RedirectToLogin = () => {
  const location = useLocation();

  if (location) {
    localStorage.setItem(
      "redirectUri",
      `${location.pathname}${location.search}`,
    );
  }
  return <Redirect to="/login" />;
};
