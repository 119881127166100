import React, { PropsWithChildren, useState } from "react";
import { AssetType } from "src/components/app/ExplorerPage/types";

export type LatestSelection = {
  asset?: Asset;
};

export type Asset = {
  assetType: AssetType;
  assetId: string;
  name?: string;
};

interface LatestSelectionContextProps {
  latestSelection?: LatestSelection;
  setLatestSelection: (newSelection: LatestSelection) => void;
}
const LatestSelectionContext = React.createContext<LatestSelectionContextProps>(
  {} as LatestSelectionContextProps,
);
const LatestSelectionProvider: React.FC<PropsWithChildren<{
  defaultSelection?: LatestSelection;
}>> = props => {
  const { defaultSelection = undefined } = props;
  const [latestSelection, setLatestSelection] = useState<
    LatestSelection | undefined
  >(defaultSelection);
  return (
    <LatestSelectionContext.Provider
      value={{ latestSelection, setLatestSelection }}
    >
      {props.children}
    </LatestSelectionContext.Provider>
  );
};

const useLatestSelectionContext = () => {
  const context = React.useContext(LatestSelectionContext);
  if (context === undefined || context.setLatestSelection === undefined) {
    throw new Error(
      "useLatestSelectionContext must be used in a valid LatestSelectionContext",
    );
  }
  return context;
};

export { LatestSelectionProvider, useLatestSelectionContext };
