import { AuthOptions } from "auth0-js";

type Configuration = {
  // Data Dog Monitoring Configuration
  DD_RUM_APP_ID: string;
  DD_RUM_TOKEN: string;
  DD_TOKEN: string;
  ENVIRONMENT: string;
  VERSION: string;

  // Service Configuration
  BUILDING_MODEL_API_URL: string;
  BUILDING_MODEL_API_KEY: string;
  AUTH0_CLAIMS_NAMESPACE: string;
  AUTH0_SETTINGS: AuthOptions;
  AUTH0_REALM: string;
  COMPANY_NAME: string;
  SUPPORT_EMAIL: string;
  MAPBOX_API_KEY: string;
  MIXPANEL_TOKEN: string;
  PDF_SERVICE_URL: string;
  UTILITY_SERVICE_URL: string;
};

// tells typescript about the global var declared in a script tag in public/index.html
declare global {
  interface Window {
    __reactAppEnv: Configuration;
  }
}

// Config is exported here, so the rest of the codebase can import and reference it easily!
// Exporting a declared global breaks react-scripts build somehow, so we instead create a new object and export that.
const config = {
  ...window.__reactAppEnv,
};

export default config;
