import {
  DailyOperatingTimeBlock,
  DayOfWeek,
  DAYS_OF_WEEK,
  OperatingScheduleInput,
} from "src/types/schedules";
import { DateTime, Interval } from "luxon";
import {
  OperatingSchedule,
  OperatingPeriodInput,
  DayOfWeek as GraphqlDayOfWeek,
  ExceptionOperatingTimeBlock,
} from "src/types/graphql";
import { dateToLocaleString, FormatOptions } from "@hatchdata/intl-formatter";

export const convertTimeBlockToStartTime = (
  day: DailyOperatingTimeBlock | null,
): Date => {
  const hour = day ? day.startHour : 6;
  const minute = day ? day.startMinute : 0;
  return new Date(0, 0, 0, hour, minute);
};

export const convertTimeBlockToEndTime = (
  day: DailyOperatingTimeBlock | null,
): Date => {
  const hour = day ? day.endHour : 18;
  const minute = day ? day.endMinute : 0;
  return new Date(0, 0, 0, hour, minute);
};

export const isAllDay = (day: DailyOperatingTimeBlock | null): boolean => {
  return (
    day?.startHour === 0 &&
    day?.endHour === 0 &&
    day?.startMinute === 0 &&
    day?.endMinute === 0
  );
};

export const formatDateAsTime = (date: Date): string => {
  return dateToLocaleString(
    date,
    undefined,
    undefined,
    FormatOptions.TIME_24_SIMPLE,
  );
};

/**
 * Returns true if the ending time extends into the following day. Because the time pickers only allow for a 24 hour date range,
 * any end times that are lesser than the start time are considered "the next day".
 * @param startTime
 * @param endTime
 */
export const timeExtendsIntoNextDay = (
  startTime: Date | undefined,
  endTime: Date | undefined,
): boolean => {
  if (!startTime || !endTime) {
    return false;
  }

  // End time is exclusive, so 12:00am is really 11:59:59 and not the next day
  if (endTime.getHours() === 0 && endTime.getMinutes() === 0) {
    return false;
  }

  return startTime.getTime() >= endTime.getTime();
};

/**
 * Returns true if the ending time extends into the following day. Because the time pickers only allow for a 24 hour date range,
 * any end times that are lesser than the start time are considered "the next day".
 * @param startTime
 * @param endTime
 */
export const exceptionExtendsIntoNextDay = (
  startTime: Date | undefined,
  endTime: Date | undefined,
): boolean => {
  if (!startTime || !endTime) {
    return false;
  }

  return startTime.getTime() >= endTime.getTime();
};

export const exceptionTimeBlockExtendsIntoNextDay = (
  timeBlock: ExceptionOperatingTimeBlock,
): boolean => {
  return (
    timeBlock.startHour > timeBlock.endHour ||
    (timeBlock.startHour === timeBlock.endHour &&
      timeBlock.startMinute >= timeBlock.endMinute)
  );
};

/**
 * Generates a daily scheduling object as expected for the save mutation
 * @param startDay day the schedule starts
 * @param followingDay the day to use if the schedule goes into the following day
 * @param startTime the time the schedule starts
 * @param endTime the time the schedule ends
 */
export const generateOperatingPeriodInput = (
  startDay: string,
  followingDay: string,
  startTime: Date,
  endTime: Date,
): OperatingPeriodInput => {
  return {
    startDay: startDay as GraphqlDayOfWeek,
    startTime:
      formatDateAsTime(startTime) === "24:00"
        ? "0:00"
        : formatDateAsTime(startTime),
    endDay:
      startTime >= endTime && formatDateAsTime(endTime) !== "24:00"
        ? (followingDay as GraphqlDayOfWeek)
        : (startDay as GraphqlDayOfWeek),
    endTime: formatDateAsTime(endTime),
  };
};

/**
 * Processes the start/end date times entered on the form and converts them into the
 * format used by the API for saving.
 */
export const generateOperatingPeriods = (
  values: OperatingScheduleInput,
): OperatingPeriodInput[] => {
  const operatingPeriods: OperatingPeriodInput[] = [];

  for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
    const day = DAYS_OF_WEEK[i];
    const followingDay =
      i === DAYS_OF_WEEK.length - 1 ? DAYS_OF_WEEK[0] : DAYS_OF_WEEK[i + 1];
    const { start, end, checked } = values[day];
    if (checked && start && end) {
      // Handle special case of sunday hours extending into monday
      if (
        followingDay === DayOfWeek.monday &&
        timeExtendsIntoNextDay(start, end)
      ) {
        const mondayPeriod = generateOperatingPeriodInput(
          DayOfWeek.monday.toUpperCase(),
          DayOfWeek.monday.toUpperCase(),
          new Date(0, 0, 0, 0, 0),
          end,
        );

        const sundayPeriod = generateOperatingPeriodInput(
          DayOfWeek.sunday.toUpperCase(),
          DayOfWeek.monday.toUpperCase(),
          start,
          new Date(0, 0, 0, 0, 0),
        );

        operatingPeriods.push(sundayPeriod);
        operatingPeriods.unshift(mondayPeriod);
      }
      // for all other cases, just create and append the period
      else {
        const operatingPeriodInput = generateOperatingPeriodInput(
          day.toUpperCase(),
          followingDay.toUpperCase(),
          start,
          end,
        );

        operatingPeriods.push(operatingPeriodInput);
      }
    }
  }

  return operatingPeriods;
};

/**
 * Note: This isn't actually locale aware!!
 * Once we can detect the first day of the week for the user's locale, we can
 * make this real. To do so, we may want to use this project (once released):
 * https://www.npmjs.com/package/spacetime-week
 * For now, it is hardcoded to having Sunday as the first day of the week.
 */
export const daysOfWeekInLocaleOrder = (): DayOfWeek[] => {
  const offset = DAYS_OF_WEEK.indexOf(DayOfWeek.sunday);
  const orderedDays = [];

  for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
    orderedDays.push(DAYS_OF_WEEK[(offset + i) % DAYS_OF_WEEK.length]);
  }
  return orderedDays;
};

export const getDayOfWeekName = (dayNumber: number): DayOfWeek => {
  if (dayNumber > 6 || dayNumber < 0) {
    throw Error(`Out of bounds: dayNumber = ${dayNumber}`);
  }

  const dayIndex = dayNumber === 0 ? 6 : dayNumber - 1;
  return DAYS_OF_WEEK[dayIndex];
};

/**
 * Returns the `end date` adjusted one day forward if the `start date`
 * is a later date than the `end date`
 * @param dates the start and end date time objects to be compared
 * @returns
 */
const adjustEndDateIfExtendsToNextDay = ({
  startTime,
  endTime,
}: {
  startTime: DateTime;
  endTime: DateTime;
}): DateTime => {
  if (exceptionExtendsIntoNextDay(startTime.toJSDate(), endTime.toJSDate())) {
    endTime = endTime.plus({
      days: 1,
    });
  }

  return endTime;
};

/**
 * Appends a schedule exception to a schedule. If the schedule and exception
 * do not overlap, two schedules are returned in chronological order.
 * @param dayOfWeek the day number (0-6) the exception takes place
 * @param current the current schedule for a given day
 * @param exception the time range to append to the schedule
 * @returns an array of schedules for a day of the week
 */
export const appendExceptionToSchedule = (
  dayOfWeek: number,
  current: {
    startTime: DateTime;
    endTime: DateTime;
  } | null,
  exception: {
    startTime: DateTime;
    endTime: DateTime;
  },
): OperatingPeriodInput[] => {
  const operatingPeriods: OperatingPeriodInput[] = [];

  // If the exception is on a day with no operating schedule, we can just use the exception start and end times
  if (!current) {
    const startDayOfWeek = getDayOfWeekName(dayOfWeek).toUpperCase();

    const nextDayNumber = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
    const nextDayOfWeek = getDayOfWeekName(nextDayNumber).toUpperCase();

    operatingPeriods.push(
      generateOperatingPeriodInput(
        startDayOfWeek,
        nextDayOfWeek,
        exception.startTime.toJSDate(),
        exception.endTime.toJSDate(),
      ),
    );

    return operatingPeriods;
  } else {
    // making the end date the following day for easier date comparison logic
    const modifiedCurrentEndTime = adjustEndDateIfExtendsToNextDay(current);
    const modifiedExceptionEndTime = adjustEndDateIfExtendsToNextDay(exception);

    const noOverlap =
      modifiedExceptionEndTime.toMillis() < current.startTime.toMillis() ||
      exception.startTime.toMillis() > modifiedCurrentEndTime.toMillis();

    const newStartTime =
      noOverlap || exception.startTime.toMillis() < current.startTime.toMillis()
        ? exception.startTime
        : current.startTime;

    const newEndTime =
      noOverlap ||
      modifiedExceptionEndTime.toMillis() > modifiedCurrentEndTime.toMillis()
        ? exception.endTime
        : current.endTime;

    const startDayOfWeek = getDayOfWeekName(dayOfWeek).toUpperCase();

    const nextDayNumber = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
    const nextDayOfWeek = getDayOfWeekName(nextDayNumber).toUpperCase();

    operatingPeriods.push(
      generateOperatingPeriodInput(
        startDayOfWeek,
        nextDayOfWeek,
        newStartTime.toJSDate(),
        newEndTime.toJSDate(),
      ),
    );

    if (noOverlap) {
      const originalOperatingPeriod = generateOperatingPeriodInput(
        startDayOfWeek,
        nextDayOfWeek,
        current.startTime.toJSDate(),
        current.endTime.toJSDate(),
      );

      // Insert or append depending on if it happens before/after the exception
      if (newStartTime.valueOf() < current.startTime.valueOf()) {
        operatingPeriods.push(originalOperatingPeriod);
      } else {
        operatingPeriods.splice(0, 0, originalOperatingPeriod);
      }
    }

    return operatingPeriods;
  }
};

/**
 * Removes the overlap between the schedule and the exception. If the exception
 * falls within the schedule boundaries, two schedules are returned.
 * @param dayOfWeek the day of week (0-6)
 * @param current the current schedule range
 * @param exception the exception range to remove from the schedule
 * @returns an array of schedules for the day of the week
 */
export const subtractExceptionFromSchedule = (
  dayOfWeek: number,
  current: {
    startTime: DateTime;
    endTime: DateTime;
  } | null,
  exception: {
    startTime: DateTime;
    endTime: DateTime;
  },
): OperatingPeriodInput[] => {
  const operatingPeriods: OperatingPeriodInput[] = [];

  // If there's no schedule that day and the exception is not operating, we don't need to do anything
  // TODO: It would be better if we did some error handling here to tell the user they can't create a
  //  "not operating" exception on a day when the building is already not operating
  if (!current) {
    return operatingPeriods;
  } else {
    // making the end date the following day for easier date comparison logic
    const modifiedCurrentEndTime = adjustEndDateIfExtendsToNextDay(current);
    const modifiedExceptionEndTime = adjustEndDateIfExtendsToNextDay(exception);

    const trimStart =
      exception.startTime.toMillis() <= current.startTime.toMillis() &&
      modifiedExceptionEndTime.toMillis() > current.startTime.toMillis();

    const trimEnd =
      exception.startTime.toMillis() < modifiedCurrentEndTime.toMillis() &&
      modifiedExceptionEndTime.toMillis() >= modifiedCurrentEndTime.toMillis();

    const trimMiddle =
      current.startTime.toMillis() < exception.startTime.toMillis() &&
      modifiedCurrentEndTime.toMillis() > modifiedExceptionEndTime.toMillis();

    const dayOfWeekName = getDayOfWeekName(dayOfWeek).toUpperCase();
    const nextDayNumber = dayOfWeek === 6 ? 0 : dayOfWeek + 1;
    const nextDayOfWeek = getDayOfWeekName(nextDayNumber).toUpperCase();

    if (trimStart && trimEnd) {
      // The schedule is completely overridden for the day
      return operatingPeriods;
    } else if (trimMiddle) {
      const startPeriod = generateOperatingPeriodInput(
        dayOfWeekName,
        nextDayOfWeek,
        current.startTime.toJSDate(),
        exception.startTime.toJSDate()!,
      );

      const endPeriod = generateOperatingPeriodInput(
        dayOfWeekName,
        nextDayOfWeek,
        exception.endTime.toJSDate(),
        current.endTime.toJSDate()!,
      );

      operatingPeriods.push(startPeriod, endPeriod);
    } else {
      operatingPeriods.push(
        generateOperatingPeriodInput(
          dayOfWeekName,
          nextDayOfWeek,
          trimStart
            ? exception.endTime.toJSDate()
            : current.startTime.toJSDate(),
          trimEnd
            ? exception.startTime.toJSDate()!
            : current.endTime.toJSDate(),
        ),
      );
    }

    return operatingPeriods;
  }
};

/**
 * Parses an operating schedule and returns the schedule
 * for the day of the week. If no operating schedule is found,
 * the default 6am-6pm time block is returned.
 * @param dayOfWeek
 * @param operatingSchedule
 * @returns the operating schedule for a day of the week.
 */
export const getStartAndEndTimesForDayOfWeek = (
  dayOfWeek: number,
  operatingSchedule: OperatingSchedule | undefined,
): { startTime: DateTime; endTime: DateTime } | null => {
  const currentSchedule = operatingSchedule
    ? operatingSchedule[daysOfWeekInLocaleOrder()[dayOfWeek]]
    : null;

  if (!currentSchedule) {
    return null;
  }

  const currentStart = DateTime.local()
    .startOf("day")
    .set({
      hour: currentSchedule?.startHour ?? 6,
      minute: currentSchedule?.startMinute ?? 0,
    });

  const currentEnd = DateTime.local()
    .startOf("day")
    .set({
      hour: currentSchedule?.endHour ?? 18,
      minute: currentSchedule?.endMinute ?? 0,
    });

  return {
    startTime: currentStart,
    endTime: currentEnd,
  };
};

/**
 * Converts the exception time range entered into the actual schedule
 * for each day based on the start and end dates and the building's schedule
 * @param startDate beginning date of the schedule exception
 * @param endDate ending date of the schedule exception
 * @param times start and end time of the exception. The date needs to be
 * the current date for comparisons to work
 * @returns
 */
export const convertExceptionToOperatingPeriods = (
  startDate: Date,
  endDate: Date,
  times: { startTime: DateTime; endTime: DateTime },
  operatingSchedule: OperatingSchedule | undefined,
  isAllDay: boolean,
  isOperating: boolean,
): OperatingPeriodInput[] => {
  const operatingPeriods: OperatingPeriodInput[] = [];

  const totalDays = totalDaysToProcess(startDate, endDate);

  let processDate = DateTime.fromJSDate(startDate);
  for (let dayIncrement = 0; dayIncrement < totalDays; dayIncrement++) {
    const weekDay = processDate
      .set({ day: processDate.day + dayIncrement })
      .toJSDate()
      .getDay();

    const isStartDate = dayIncrement === 0;
    const isEndDate = dayIncrement === totalDays - 1;

    const revisedTimes = getTimesForDay(times, isStartDate, isEndDate);

    operatingPeriods.push(
      ...processSchedule(
        weekDay,
        revisedTimes,
        operatingSchedule,
        isOperating,
        isAllDay,
      ),
    );
  }

  return operatingPeriods;
};

/**
 * Determines how many days of the week need to have
 * their schedules adjusted based on the date range
 * the user has selected.
 * @returns a value between 1 and 7 inclusive representing
 * how many days of the week are included in the date range.
 */
const totalDaysToProcess = (startDate: Date, endDate: Date): number => {
  const date1 = DateTime.fromJSDate(startDate);
  const date2 = DateTime.fromJSDate(endDate);

  const diff = Interval.fromDateTimes(date1, date2);

  const totalDays = Math.floor(diff.length("days"));

  return totalDays > 6 ? 7 : totalDays === 0 ? 1 : totalDays;
};

/**
 * Returns the start and end times to use for an exception. If the exception
 * spans more than one day, the start/end times for the start and end days
 * is updated to reflect the time either starting or ending at midnight. If
 * the exception is not on the start or end day, the exception time is set to
 * the 24 hour format.
 * @param times
 * @param isStartDate
 * @param isEndDate
 * @returns
 */
const getTimesForDay = (
  times: { startTime: DateTime; endTime: DateTime },
  isStartDate: boolean,
  isEndDate: boolean,
): { startTime: DateTime; endTime: DateTime } => {
  const crossesDayBoundary = timeExtendsIntoNextDay(
    times.startTime.toJSDate(),
    times.endTime.toJSDate(),
  );

  if (crossesDayBoundary || (isStartDate ? !isEndDate : isEndDate)) {
    return {
      startTime: isEndDate
        ? times.startTime.set({ hour: 0, minute: 0 })
        : times.startTime,
      endTime: isStartDate
        ? times.endTime.set({ hour: 0, minute: 0 })
        : times.endTime,
    };
  } else if (!isStartDate && !isEndDate) {
    return {
      startTime: times.startTime.set({ hour: 0, minute: 0 }),
      endTime: times.endTime.set({ hour: 0, minute: 0 }),
    };
  } else {
    return times;
  }
};

/**
 * Generates a new occupancy schedule for a day based on the exception
 * start and end times.
 * @param weekDay
 * @param exceptionTimes
 * @param operatingSchedule
 * @param isOperating true implies we're adding to the schedule, false means
 * we're subtracting time
 * @param isAllDay
 * @returns
 */
const processSchedule = (
  weekDay: number,
  exceptionTimes: { startTime: DateTime; endTime: DateTime },
  operatingSchedule: OperatingSchedule | undefined,
  isOperating: boolean,
  isAllDay: boolean,
) => {
  const operatingPeriods: OperatingPeriodInput[] = [];

  const currentSchedule = getStartAndEndTimesForDayOfWeek(
    weekDay,
    operatingSchedule,
  );

  if (isOperating) {
    const updated: OperatingPeriodInput[] = appendExceptionToSchedule(
      weekDay,
      currentSchedule,
      exceptionTimes,
    );
    operatingPeriods.push(...updated);
  } else {
    // Not operating means a schedule is not created for the day.
    if (!isAllDay) {
      const updated: OperatingPeriodInput[] = subtractExceptionFromSchedule(
        weekDay,
        currentSchedule,
        exceptionTimes,
      );

      operatingPeriods.push(...updated);
    }
  }

  return operatingPeriods;
};
