import React, { useEffect, useState } from "react";
import DayPicker, { DayModifiers } from "react-day-picker"; // https://react-day-picker.js.org/
import "react-day-picker/lib/style.css";
import { FormattedMessage } from "react-intl";
import { ComparePeriod } from "src/types/charting";
import { H5, InfoText2 } from "src/components/common/Typography";
import {
  MxReactIcon,
  Calendar,
} from "src/componentLibrary/react/mx-icon-react";
import {
  rangeToLimitForTimezone,
  daysBetweenDates,
  secondaryDateRange,
  getLocalDateFormat,
  parseDate,
} from "src/helpers/dates";
import {
  PickerCalendar,
  ICompareRangeChange,
  DateUtilRange,
} from "./TimePickerHolder";
import { DateTime } from "luxon";
import { SmallMessageBar } from "src/components/common/MessageBar";
import { isSameMonth } from "./helpers";
import {
  TimeRangePickerOuter,
  TimeRangePickerWrapper,
  PickerButtonContainer,
  PickerButton,
  PickerBorderButton,
  PickerGreyBorderButton,
  PickerCalendarContainer,
  PickerCalendarHeader,
  PickerDateDisplay,
  PickerDateInput,
} from "./RangePickerParts";
import { AppColors } from "../Styling";
import { dateToLocaleString } from "@hatchdata/intl-formatter";

interface PastRangePickerProps {
  /** What timezone should the dates picked be in */
  timezone: string;
  /** Handler for when user confirms selected dates */
  onChange: (data: ICompareRangeChange) => void;
  /** Handler for when user cancels selection */
  onCancel?: () => void;
  /** Primary range start date (used to calculate fixed options and allowable selections) */
  primaryRangeStart: Date;
  /** Primary range end date (used to calculate fixed options and allowable selections) */
  primaryRangeEnd: Date;
  /** Pre-selected start date for the picker */
  startDate?: Date;
  /** Pre-selected end date for the picker */
  endDate?: Date;
  /** Did the user have one of the predefined options picked? */
  selectedComparePeriod?: ComparePeriod;
}

export const CompareToPastRangePicker: React.FC<PastRangePickerProps> = props => {
  const {
    onCancel,
    onChange,
    primaryRangeStart,
    primaryRangeEnd,
    startDate,
    endDate,
    timezone,
    selectedComparePeriod,
  } = props;

  const [comparePeriod, setComparePeriod] = useState<ComparePeriod>(
    selectedComparePeriod || ComparePeriod.CUSTOM,
  );
  // how many days between the selected days in Explorer?
  const rangeSize = daysBetweenDates(primaryRangeStart, primaryRangeEnd);

  // limit the last date the user can pick to "yesterday" minus how many days
  // they have selected in Explorer (so we can't go into the future...)
  const defaultRangeLimitAfter = rangeToLimitForTimezone(timezone);
  // defaultRangeLimitAfter.setDate(defaultRangeLimitAfter.getDate() - rangeSize);

  // we allow users to go back three years and one day (for REASONS, okay?)
  const defaultRangeLimitBefore = new Date();
  defaultRangeLimitBefore.setFullYear(
    defaultRangeLimitBefore.getFullYear() - 3,
  );
  defaultRangeLimitBefore.setDate(defaultRangeLimitBefore.getDate() - 1);

  /** the actual dates that are picked */
  const [selectedDates, setSelectedDates] = useState<DateUtilRange>({
    from: startDate,
    to: endDate,
  });

  /** Dates for custom input */
  const [customDateRange, setCustomDateRange] = useState<{
    from: string;
    to: string;
  }>({
    from: selectedDates.from ? dateToLocaleString(selectedDates.from) : "",
    to: selectedDates.to ? dateToLocaleString(selectedDates.to) : "",
  });

  // called when user picks 'custom'
  const handleCustomSelect = (): void => {
    setComparePeriod(ComparePeriod.CUSTOM);
    setSelectedDates({ from: undefined, to: undefined });
    setCustomDateRange({
      from: "",
      to: "",
    });
  };
  // called when user picks a pre-defined Range
  const handleCompareSelect = (period: ComparePeriod): void => {
    if (period === ComparePeriod.CUSTOM) {
      // no op
      return;
    }
    setComparePeriod(period);
    const _r = secondaryDateRange(
      {
        startTime: primaryRangeStart.toISOString(),
        endTime: primaryRangeEnd.toISOString(),
      },
      period,
      timezone,
    );
    // set some dates!
    const from = new Date(_r.startTime);
    from.setHours(12);
    const to = new Date(_r.endTime);
    to.setHours(12);
    setSelectedDates({
      from,
      to,
    });
  };
  // called when the user clicks the 'Apply' button
  const handleChangeCommit = () => {
    if (selectedDates.from && selectedDates.to) {
      onChange({
        comparePeriod,
        selectedDates: { from: selectedDates.from, to: selectedDates.to },
      });
    }
  };
  // sets class names on the calendar so it can be pretty
  const modifiers = {
    highlighted:
      selectedDates.from && selectedDates.to
        ? { from: selectedDates.from, to: selectedDates.to }
        : [],
    start: selectedDates.from,
    end: selectedDates.to,
    insideLimit: { from: defaultRangeLimitBefore, to: defaultRangeLimitAfter },
  };

  /**
   * Called when the user interacts with the calendar OR when the user is
   * entering in a date in the input. When entering a date, we don't want
   * the date to be autocompleted, hence the "shouldSetCustomDate" param.
   */
  const calendarSelect = (
    day: Date,
    modifiers: DayModifiers,
    event?:
      | React.MouseEvent<HTMLDivElement>
      | React.FocusEvent<HTMLInputElement>,
    shouldSetCustomDate = true,
  ) => {
    if (event) {
      event.persist();
      event.nativeEvent.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
    // if there are limits, we want to handle "disabled" days
    // if they are inside the default boundaries so a user can
    // reset the range around a new date with a click
    if (modifiers.disabled) {
      return;
    }
    setComparePeriod(ComparePeriod.CUSTOM);

    // If entered from date is not valid, reset date state
    if (
      !shouldSetCustomDate &&
      (!parseDate(customDateRange.from) ||
        !customDateWithinRange(customDateRange.from))
    ) {
      setSelectedDates({
        from: undefined,
        to: undefined,
      });
      setCustomDateRange({
        from: customDateRange.from,
        to: "",
      });
    } else {
      // Don't "autocomplete" entered date if flag is not set
      const from = shouldSetCustomDate
        ? dateToLocaleString(day)
        : customDateRange.from;

      // make a date range from the dates!
      const to = new Date(day);
      to.setDate(to.getDate() + rangeSize);

      setSelectedDates({
        from: day,
        to,
      });

      // we're in custom mode, so update the custom entry fields
      setCustomDateRange({
        from,
        to: dateToLocaleString(to),
      });
    }
  };

  const lastMonth = (): Date => {
    const _m = DateTime.local()
      .setZone(timezone)
      .minus({ month: 1 });
    return _m.toJSDate();
  };

  const leftCalendarMonth = (): Date => {
    if (selectedDates.from && selectedDates.to) {
      if (!isSameMonth(selectedDates.from, selectedDates.to)) {
        return selectedDates.from;
      } else {
        const _m = DateTime.fromJSDate(selectedDates.from, {
          zone: timezone,
        }).minus({ month: 1 });
        return _m.toJSDate();
      }
    }
    return lastMonth();
  };

  const rightCalendarMonth = () => {
    if (selectedDates.to) {
      return selectedDates.to;
    }
    const _m = DateTime.local().setZone(timezone);
    return _m.toJSDate();
  };

  /**
   * Updates the customDateRange and selectedDate objects on value change for a date
   * entry box.
   * @param customDates
   */
  const updateCustomDates = (customDates: { from: string; to: string }) => {
    setComparePeriod(ComparePeriod.CUSTOM);
    setCustomDateRange({
      from: customDates.from,
      to: customDates.to,
    });

    const fromDate = parseDate(customDates.from);
    const toDate = parseDate(customDates.to);

    setSelectedDates({
      from: fromDate
        ? DateTime.fromJSDate(fromDate)
            .setZone(timezone)
            .startOf("day")
            .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            .toJSDate()
        : undefined,
      to: toDate
        ? DateTime.fromJSDate(toDate)
            .setZone(timezone)
            .startOf("day")
            .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            .toJSDate()
        : undefined,
    });
  };

  // Pick a valid date on the calendar as the user types
  useEffect(() => {
    calendarSelect(
      parseDate(customDateRange.from)!,
      {
        today: true,
        outside: false,
        insideLimit: customDateWithinLimit(customDateRange.from),
      },
      undefined,
      // Don't autocomplete date as user is typing
      false,
    );
  }, [customDateRange.from]);

  /**
   * Returns true if the value falls in the range of allowable dates.
   * @param value
   */
  const customDateWithinRange = (value: string): boolean => {
    const date = new Date(value);

    return (
      date.getTime() >= defaultRangeLimitBefore.getTime() &&
      date.getTime() <= defaultRangeLimitAfter.getTime()
    );
  };

  /**
   * Returns true if the value falls in the range of the inside limit.
   * @param value
   */
  const customDateWithinLimit = (value: string): boolean => {
    const date = new Date(value);

    return (
      date.getTime() >= modifiers.insideLimit.from.getTime() &&
      date.getTime() <= modifiers.insideLimit.to.getTime()
    );
  };

  /** Submits the form if the user hits the enter key */
  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 13) {
      handleChangeCommit();
    }
  };

  return (
    <TimeRangePickerOuter>
      <SmallMessageBar messageID="charts.explorer.compareToPast.rangeSizeText" />
      <TimeRangePickerWrapper>
        <PickerButtonContainer>
          <div>
            <H5>
              <FormattedMessage id="dataRange.dateRangeLabel" />
            </H5>
            <PickerButton
              onClick={handleCustomSelect}
              selected={
                comparePeriod === ComparePeriod.CUSTOM ||
                comparePeriod === ComparePeriod.NONE
              }
            >
              <FormattedMessage id="dataRange.custom" />
            </PickerButton>
            <PickerButton
              onClick={() => handleCompareSelect(ComparePeriod.PAST_YEAR)}
              selected={comparePeriod === ComparePeriod.PAST_YEAR}
            >
              <FormattedMessage id="charts.explorer.compareToPast.PAST_YEAR" />
            </PickerButton>
            <PickerButton
              onClick={() => handleCompareSelect(ComparePeriod.PREVIOUS_PERIOD)}
              selected={comparePeriod === ComparePeriod.PREVIOUS_PERIOD}
            >
              <FormattedMessage id="charts.explorer.compareToPast.PREVIOUS_PERIOD" />
            </PickerButton>
          </div>
          <div>
            <PickerBorderButton
              onClick={handleChangeCommit}
              data-testid="compare-picker-apply"
            >
              <FormattedMessage id="common.button.labels.apply" />
            </PickerBorderButton>
            <PickerGreyBorderButton onClick={onCancel}>
              <FormattedMessage id="common.button.labels.cancel" />
            </PickerGreyBorderButton>
          </div>
        </PickerButtonContainer>
        <PickerCalendarContainer>
          <PickerCalendar data-testid="calendar1">
            <div>
              <PickerCalendarHeader short={false}>
                <H5>
                  <FormattedMessage id="common.label.startDate" />
                </H5>
                <PickerDateDisplay focused={true}>
                  <MxReactIcon
                    Icon={Calendar}
                    color={AppColors.neutral.navy}
                    style={{ marginRight: "8px" }}
                  />
                  {comparePeriod !== ComparePeriod.CUSTOM &&
                  selectedDates.from ? (
                    dateToLocaleString(selectedDates.from)
                  ) : (
                    <PickerDateInput
                      maxLength={10}
                      placeholder={getLocalDateFormat()}
                      value={customDateRange.from}
                      onChange={e =>
                        updateCustomDates({
                          from: e.currentTarget.value,
                          to: customDateRange.to,
                        })
                      }
                      tabIndex={125}
                      onKeyDown={handleEnterKey}
                      onBlur={e =>
                        parseDate(customDateRange.from) &&
                        customDateWithinRange(customDateRange.from)
                          ? calendarSelect(
                              parseDate(customDateRange.from)!,
                              {
                                today: true,
                                outside: false,
                                insideLimit: customDateWithinLimit(
                                  customDateRange.from,
                                ),
                              },
                              e,
                            )
                          : setCustomDateRange({
                              from: "",
                              to: customDateRange.to,
                            })
                      }
                    />
                  )}
                </PickerDateDisplay>
              </PickerCalendarHeader>
            </div>
            <DayPicker
              month={leftCalendarMonth()}
              disabledDays={{
                after: defaultRangeLimitAfter,
                before: defaultRangeLimitBefore,
              }}
              fromMonth={defaultRangeLimitBefore}
              toMonth={defaultRangeLimitAfter}
              selectedDays={[selectedDates.from, selectedDates.to]}
              modifiers={modifiers}
              onDayClick={calendarSelect}
            />
          </PickerCalendar>
          <PickerCalendar data-testid="calendar2">
            <div>
              <PickerCalendarHeader short={false}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <H5>
                    <FormattedMessage id="common.label.endDate" />
                  </H5>
                  <p style={{ width: "8px" }}> </p>
                  <InfoText2>
                    <FormattedMessage id="common.label.calcFromStartDate" />
                  </InfoText2>
                </div>
                <PickerDateDisplay focused={false} disabled>
                  <MxReactIcon
                    Icon={Calendar}
                    color={AppColors.neutral.navy}
                    style={{ marginRight: "8px" }}
                  />
                  {comparePeriod !== ComparePeriod.CUSTOM &&
                  selectedDates.to ? (
                    dateToLocaleString(selectedDates.to)
                  ) : (
                    <PickerDateInput
                      disabled
                      maxLength={10}
                      placeholder={getLocalDateFormat()}
                      value={customDateRange.to}
                      onChange={e =>
                        updateCustomDates({
                          from: customDateRange.from,
                          to: e.currentTarget.value,
                        })
                      }
                      onBlur={e =>
                        parseDate(customDateRange.to) &&
                        customDateWithinRange(customDateRange.to)
                          ? calendarSelect(
                              parseDate(customDateRange.to)!,
                              {
                                today: true,
                                outside: false,
                                insideLimit: customDateWithinLimit(
                                  customDateRange.to,
                                ),
                              },
                              e,
                            )
                          : setCustomDateRange({
                              from: customDateRange.from,
                              to: "",
                            })
                      }
                    />
                  )}
                </PickerDateDisplay>
              </PickerCalendarHeader>
            </div>
            <DayPicker
              month={rightCalendarMonth()}
              disabledDays={{
                after: defaultRangeLimitAfter,
                before: defaultRangeLimitBefore,
              }}
              fromMonth={defaultRangeLimitBefore}
              toMonth={defaultRangeLimitAfter}
              selectedDays={[selectedDates.from, selectedDates.to]}
              modifiers={modifiers}
              onDayClick={calendarSelect}
            />
          </PickerCalendar>
        </PickerCalendarContainer>
      </TimeRangePickerWrapper>
    </TimeRangePickerOuter>
  );
};
