import styled from "styled-components/macro";
import { AppColors } from "../Styling";

// TODO: this should use an actual text style from the style guide.
export const ErrorMessage = styled.p.attrs(({ id }) => ({
         id: id,
       }))`
         font-size: 15px;
         color: ${AppColors.semantic.red.red};
         margin-bottom: 16px;
         margin-top: 8px;
         text-align: left;
       `;

export const ErrorSection = styled.section`
  text-align: center;
  background-color: ${AppColors.neutral.white};
  padding: 220px 0;
`;

export const LoadErrorMessage = styled.div`
  margin: 40px 0;
`;

export default ErrorMessage;
