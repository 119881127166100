import styled from "styled-components/macro";
import { useState, FC } from "react";
import {
  MxReactIcon,
  ErrorTriangle,
  Info,
  CircleCheck,
  X,
  LinkOut,
} from "src/componentLibrary/react/mx-icon-react";
import { FormattedMessage } from "react-intl";
import { AppColors } from "../Styling";
import { Body, HyperLink2 } from "src/components/common/Typography";

export enum MessageBarType {
  Alert,
  Warning,
  Neutral,
  Success,
}

interface IMessageBarProps {
  type: MessageBarType;
  textId: string;
  showClose: boolean;
  onClick?: () => void;
  marginBottom?: string;
  isCompact?: boolean;
  linkText?: string;
  linkUrl?: string;
}

export const MessageBar: React.FC<IMessageBarProps> = ({
  type,
  textId,
  showClose,
  onClick,
  marginBottom,
  isCompact,
  linkText,
  linkUrl,
}: IMessageBarProps) => {
  const [showMe, setShowMe] = useState(true);
  const defaultCloseHandler = () => setShowMe(false);
  const closeHandler = onClick || defaultCloseHandler;

  const getIcon = (): FC => {
    switch (type) {
      case MessageBarType.Neutral:
        return Info;
      case MessageBarType.Success:
        return CircleCheck;
      case MessageBarType.Alert:
      case MessageBarType.Warning:
        return ErrorTriangle;
      default:
        throw Error("Unrecognized icon");
    }
  };

  const getIconColor = (type: MessageBarType): string => {
    switch (type) {
      case MessageBarType.Alert:
        return AppColors.semantic.red.red;
      case MessageBarType.Success:
        return AppColors.primary["light-msr-green"];
      case MessageBarType.Warning:
        return AppColors.semantic.yellow.yellow;
      case MessageBarType.Neutral:
      default:
        return AppColors.semantic.blue.sky;
    }
  };

  if (showMe) {
    return (
      <Bar type={type} marginBottom={marginBottom} isCompact={isCompact}>
        <div style={{ display: "flex" }}>
          <MxReactIcon Icon={getIcon()} color={getIconColor(type)} size="m" />
          <Text>
            <FormattedMessage
              id={textId}
              values={{
                linkText:
                  linkText && linkUrl ? (
                    <LinkText>
                      <HyperLink2
                        href={linkUrl}
                        target={"_blank"}
                        rel="noopener"
                        style={{
                          color:
                            type === MessageBarType.Neutral
                              ? AppColors.neutral.navy
                              : getIconColor(type),
                        }}
                      >
                        {" "}
                        {linkText}{" "}
                      </HyperLink2>
                      <MxReactIcon
                        Icon={LinkOut}
                        color={getIconColor(type)}
                        size="s"
                      />
                    </LinkText>
                  ) : (
                    ""
                  ),
              }}
            />
          </Text>
        </div>
        {showClose && (
          <CloseArea onClick={closeHandler}>
            <MxReactIcon Icon={X} size="m" />
          </CloseArea>
        )}
      </Bar>
    );
  } else {
    return null;
  }
};

const getBackgroundColor = (type: MessageBarType): string => {
  switch (type) {
    case MessageBarType.Alert:
      return AppColors.semantic.red["light-red-3"];
    case MessageBarType.Success:
      return AppColors.semantic.green["light-msr-green-6"];
    case MessageBarType.Warning:
      return AppColors.semantic.yellow["light-yellow-3"];
    case MessageBarType.Neutral:
    default:
      return AppColors.semantic.blue["light-sky-3"];
  }
};

const Bar = styled.div<{
  type: MessageBarType;
  marginBottom?: string;
  isCompact?: boolean;
}>`
  background-color: ${props => getBackgroundColor(props.type)};
  /* padding: 24px 30px; */
  padding: ${props => (props.isCompact ? "12px 20px" : "24px 30px")};
  display: flex;
  /* margin-top: 16px; */
  margin-top: ${props => (props.isCompact ? "0" : "16px")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "auto")};
  justify-content: space-between;
  align-items: center;
`;

const Text = styled(Body)`
  display: inline;
  margin-left: 16px;
`;

const LinkText = styled(Body)`
  display: inline;
`;

const CloseArea = styled.span`
  cursor: pointer;
`;

export default MessageBar;
