import {
  AssetState,
  AssetStateIndicator,
  AssetType,
  CommoditySelection,
  DataQueueItem,
} from "./types";
import { FeatureType, ITimeRange, PointType } from "src/types/charting";
import {
  SelectorBuilding,
  SelectorData,
} from "../MultiCommoditySelector/types";
import { parse } from "query-string";
import { useFeatureEnablement } from "../../../helpers/enablements";
import { chartDataTypeFromString } from "../../../helpers/charting";

/**
 *
 * Make a key from a timeRange
 */
export const keyFromTimeRange = (timeRange: ITimeRange): string => {
  const _s = new Date(timeRange.startTime);
  const _e = new Date(timeRange.endTime);
  return `${_s.getTime()}_${_e.getTime()}`;
};

export const commodityFromString = (c: string): PointType => {
  const maybeCommodity = chartDataTypeFromString(c);
  if (maybeCommodity instanceof Error) {
    throw new Error(`commodityFromString called with invalid input: ${c}`);
  } else {
    return maybeCommodity;
  }
};

/**
 * takes a string timeseries key and give you startTime and endTime dates from it.
 *
 * @param key timeseries key a la "443123_442312"
 * @returns
 */
export const timeRangeFromTimeseriesKey = (key: string): ITimeRange => {
  const [_s, _e] = key.split("_");
  return {
    startTime: new Date(parseInt(_s)).toISOString(),
    endTime: new Date(parseInt(_e)).toISOString(),
  };
};

export const assetTypeFromString = (t: string): AssetType => {
  switch (t) {
    case "GROUP":
      return AssetType.GROUP;
    case "BUILDING":
      return AssetType.BUILDING;
    case "METER":
      return AssetType.METER;
    case "POINT":
      return AssetType.POINT;
    case "EQUIPMENT":
      return AssetType.EQUIPMENT;
    case "EQUIPMENT_POINT":
      return AssetType.EQUIPMENT_POINT;
    default:
      throw new Error("assetTypeFromString called with invalid input");
  }
};

/**
 *
 * Get a key for a commodity, timestamp, and so on to uniquely ID
 * this asset and so on.
 */
export const keyFromQueueItem = (item: DataQueueItem) => {
  const { timeseriesKey, commodity, assetKey } = item;
  return `${timeseriesKey}.${commodity}.${assetKey}`;
};

/**
 * These make Keys for buildings, points and meters. "But why!?" you ask. Because it is
 * the case that a building with one meter and point will have the same ID for both! So
 * we need to be able to tell them apart.
 *  */
export const meterKey = (meterId: string): string => `METER__${meterId}`;
export const pointKey = (pointId: string): string => `POINT__${pointId}`;
export const equipmentPointKey = (pointId: string) =>
  `EQUIPMENT_POINT__${pointId}`;
export const groupKey = (groupId: string): string => `GROUP__${groupId}`;
export const buildingKey = (buildingId: string): string =>
  `BUILDING__${buildingId}`;

/**
 * Make a key for the DataQueueItem from the assetType and assetId
 * @param assetType
 * @param assetId
 */
export function makeAssetKeyForDataQueueItem(
  assetType: AssetType,
  assetId: string,
): string {
  switch (assetType) {
    case AssetType.BUILDING:
      return buildingKey(assetId);
    case AssetType.GROUP:
      return groupKey(assetId);
    case AssetType.EQUIPMENT_POINT:
      return equipmentPointKey(assetId);
    default:
      return pointKey(assetId);
  }
}

export const assetKeyForCommoditySelection = (cs: CommoditySelection) =>
  `${cs.assetType}__${cs.assetId}`;

/**
 *
 * Makes all the asset states we will need for things to work with
 * changing loading / selected / error, etc. Gets the timezone from the
 * URL so that is maybe unexpected!
 *
 * @param sd The parsed multi-selector data
 */

// TODO: get the enablements out of here. They probably do not belong here
// and make things hard to test. This makes us have to mock Aut0 in places
// that don't make any sense when we test.
export const useAssetStatesFromSelectorData = (sd: SelectorData) => {
  const { isFeatureEnabled } = useFeatureEnablement();
  /*
  const _s = parse(location.search);
  const timezone = _s.timezone || undefined;
  */
  const groupStates: AssetStateIndicator = {};
  const buildingStates: AssetStateIndicator = {};
  const pointStates: AssetStateIndicator = {};
  // go through groups and make a state for each...

  // if a building is not available, your meters should also not be
  const metersNotAvailable: string[] = [];
  sd.groups.forEach((group, id) => {
    group.commodities.forEach(commodity => {
      groupStates[`${id}.${commodity}`] = AssetState.UNSELECTED;
    });
  });
  // go through the building -> commodity bits....
  sd.buildings.forEach((building, id) => {
    // TODO: get rid of this!!!!
    if (
      isFeatureEnabled(
        building.enablements,
        FeatureType.PLATFORM_NO_REAL_TIME,
        false,
      )
    ) {
      metersNotAvailable.push(...building.meters);
      building.commodities.forEach(commodity => {
        buildingStates[`${id}.${commodity}`] = AssetState.NOT_AVAILABLE;
      });
    } else {
      building.commodities.forEach(commodity => {
        buildingStates[`${id}.${commodity}`] = AssetState.UNSELECTED;
      });
    }
  });
  // and then the points!
  sd.points.forEach((point, id) => {
    if (metersNotAvailable.includes(point.meterId)) {
      pointStates[`${id}.${point.commodity}`] = AssetState.NOT_AVAILABLE;
    } else {
      pointStates[`${id}.${point.commodity}`] = AssetState.UNSELECTED;
    }
  });
  // oh, and the equipment points, because we have those now...
  sd.equipmentPoints.forEach((point, id) => {
    pointStates[`${id}.${point.commodity}`] = AssetState.UNSELECTED;
  });
  // and we are DUN! Errr... done.
  return { buildingStates, pointStates, groupStates };
};

export const getAssetsFromURL = (
  url: string,
): {
  buildingsFromURL: string[];
  pointsFromURL: string[];
  groupsFromURL: string[];
  equipmentPointsFromURL: string[];
} => {
  const _s = parse(url);
  const buildings: string[] = [];
  const points: string[] = [];
  const groups: string[] = [];
  const equipmentPoints: string[] = [];

  if (_s.buildings) {
    const buildingsAssets = (_s.buildings as string).split(";");
    for (const building of buildingsAssets) {
      const buildingId = building.split(":")[0];
      buildings.push(buildingId);
    }
  }

  if (_s.groups) {
    const groupAssets = (_s.groups as string).split(";");
    for (const group of groupAssets) {
      const groupId = group.split(":")[0];
      groups.push(groupId);
    }
  }
  if (_s.equipmentPoints) {
    const eqPointAssets = (_s.equipmentPoints as string).split(";");
    for (const eqp of eqPointAssets) {
      equipmentPoints.push(eqp.split(":")[0]);
    }
  }
  if (_s.points) {
    const metersAssets = (_s.points as string).split(";");
    for (const meter of metersAssets) {
      const meterId = meter.split(":")[0];
      points.push(meterId);
    }
  }

  return {
    buildingsFromURL: buildings,
    pointsFromURL: points,
    groupsFromURL: groups,
    equipmentPointsFromURL: equipmentPoints,
  };
};

const matchesFilter = (filterValue: string, input: string): boolean => {
  return input.toLowerCase().includes(filterValue.toLowerCase());
};

/**
 *
 * @param data - the asset SelectorData you want filtered
 * @param filterValue - the string value to filter on
 * @param includeAssets - what assets we should filter (not present == not looked at)
 * @returns
 */
export const searchFilteredData = (
  data: SelectorData,
  filterValue: string,
  includeAssets: AssetType[] = Object.values(AssetType),
) => {
  const filteredData: SelectorData = {
    organizations: data.organizations,
    groups: new Map(),
    buildings: new Map(),
    meterGroups: new Map(),
    meters: new Map(),
    points: new Map(),
    equipment: new Map(),
    equipmentPoints: new Map(),
    equipmentGroups: new Map(),
    ungroupedBuildings: [],
  };

  /* =============================================================== *\
     TOP PART IS ALL HELPER FUNCTIONS...
  \* =============================================================== */

  const insertIfNotAlreadyPresent = (collection: string[], thing: string) => {
    if (!collection.includes(thing)) {
      collection.push(thing);
    }
  };

  /**
   * Will recursively add child equipment and points to filtered data from something
   * that is being included as a part of a search.
   *
   * @param ids - array of child equipment IDs
   * @returns nothing
   */
  const addChildEquipment = (ids: string[]) => {
    ids.forEach(_eid => {
      const _e = data.equipment.get(_eid);
      if (_e) {
        filteredData.equipment.set(_eid, _e);
        _e.points.forEach(_pid => {
          filteredData.equipmentPoints.set(
            _pid,
            data.equipmentPoints.get(_pid)!,
          );
        });
        addChildEquipment(_e.equipment);
      }
    });
  };

  /**
   * Adds the equipment groups and all their child equipment
   * to the filtered data
   *
   * @param ids - array of equipment group IDs
   */
  const addEquipmetGroups = (ids: string[]) => {
    ids.forEach(_gid => {
      const _g = data.equipmentGroups.get(_gid);
      if (_g) {
        filteredData.equipmentGroups.set(_gid, _g);
        addChildEquipment(_g.equipment);
      }
    });
  };

  /**
   * Returns if any of the equipment you have or their kids match the filter.
   * The return has a boolean flag, and every ID that matched, and the parents of every ID that matched.
   *
   * @param ids - equipment IDs
   */
  type EquipmentSearchResults = {
    found: boolean; // did we find anything?
    ids: Set<string>; // trail of IDs that match
  };
  const hasMatchingChildEquipment = (
    ids: string[],
    results: EquipmentSearchResults = { found: false, ids: new Set() },
  ): EquipmentSearchResults => {
    for (let i = 0; i < ids.length; i += 1) {
      const _eid = ids[i];
      const _equip = data.equipment.get(_eid);
      if (_equip) {
        // if we matched, tell the world.
        if (_equip && matchesFilter(filterValue, _equip.name)) {
          results.found = true;
          results.ids.add(_eid);
        }
        // we do this to check kids and ensure we are part of the chain
        // if one of them does indeed match
        const _r = hasMatchingChildEquipment(_equip?.equipment ?? [], results);
        if (_r.found) {
          results.ids.add(_eid);
        }
      }
    }
    return results;
  };
  const hasMatchingChildEquipmentGroups = (
    ids: string[],
    results: EquipmentSearchResults = { found: false, ids: new Set() },
  ): EquipmentSearchResults => {
    for (let i = 0; i < ids.length; i += 1) {
      const _gid = ids[i];
      const _g = data.equipmentGroups.get(_gid);
      if (_g) {
        if (matchesFilter(filterValue, _g.name)) {
          results.found = true;
          results.ids.add(_gid);
        }
        // if a kid matches, we need to match to
        const _r = hasMatchingChildEquipment(_g?.equipment ?? [], results);
        if (_r.found) {
          results.ids.add(_gid);
        }
      }
    }
    return results;
  };

  const addGroupToResults = (groupId: string, buildings: string[] = []) => {
    const _cur = data.groups.get(groupId);
    if (_cur) {
      filteredData.groups.set(groupId, {
        name: _cur.name,
        id: _cur.id,
        buildings,
        commodities: _cur.commodities,
        groups: _cur.groups,
        parentId: _cur.parentId,
      });

      if (_cur.parentId) {
        addGroupToResults(_cur.parentId);
      }
    }
  };

  /** returns the filtered data group for a building. Will MAKE ONE if it does not exist */
  const filteredGroup = (building: SelectorBuilding): boolean => {
    const _g = building.groupId;
    if (_g) {
      // this is the FILTERED DATA exsiting one
      const _existing = filteredData.groups.get(_g);
      if (_existing) {
        insertIfNotAlreadyPresent(_existing.buildings, building.id);
      } else {
        addGroupToResults(_g, [building.id]);
      }
      return true;
    }
    return false;
  };

  const addCompleteBuldingToResults = (_b: SelectorBuilding) => {
    if (!filteredGroup(_b)) {
      insertIfNotAlreadyPresent(filteredData.ungroupedBuildings, _b.id);
    }
    // we want everything at this level, so just reference copy.
    filteredData.buildings.set(_b.id, _b);
    // add the meter groups...
    _b.meterGroups.forEach(_mgid => {
      const _mg = data.meterGroups.get(_mgid);
      if (_mg !== undefined) {
        _mg.meters.forEach(_mid => {
          const _meter = data.meters.get(_mid);
          if (_meter) {
            _meter.points.forEach((_pid: string) => {
              filteredData.points.set(_pid, data.points.get(_pid)!);
            });
            filteredData.meters.set(_mid, _meter);
          }
        });
        filteredData.meterGroups.set(_mgid, _mg);
      }
    });
    // copy the meters over since we need them as well
    _b.meters.forEach(_mid => {
      const _m = data.meters.get(_mid);
      if (_m) {
        filteredData.meters.set(_mid, _m);
        _m.points.forEach(_pid => {
          filteredData.points.set(_pid, data.points.get(_pid)!);
        });
      }
    });
    // and the equipment while we are at it
    addEquipmetGroups(_b.equipmentGroups);
    addChildEquipment(_b.equipment);
  };

  /* =============================================================== *\
     BEGIN ACTUAL FILTERING CODE....
  \* =============================================================== */
  // look for groups that match our filter (we now search subgroups...)
  data.groups.forEach(_g => {
    const groupMatch = matchesFilter(filterValue, _g.name);

    // if the parent group matches the filter, this group should be included as well
    const parentGroupMatch = _g.parentId
      ? matchesFilter(filterValue, data.groups.get(_g.parentId)?.name || "")
      : false;

    if (groupMatch || parentGroupMatch) {
      const _cur = data.groups.get(_g.id);
      const _existing = filteredData.groups.get(_g.id);
      // if we were already added, just add all our buildings....
      if (_existing && _cur) {
        _existing.buildings = _cur.buildings;
      } else {
        addGroupToResults(_g.id, _cur?.buildings);
      }
      // now we need to make sure our parent group exists, and either make it
      // or ensure this group is in it's child group list
      if (_cur?.parentId) {
        const _existingParent = filteredData.groups.get(_cur.parentId);
        if (_existingParent === undefined) {
          addGroupToResults(_cur.parentId, []);
        } else {
          insertIfNotAlreadyPresent(_existingParent.groups, _cur.id);
        }
      }
      // now we need to add all the buildings and all thier stuff....
      // (all child nodes of any matched node must be present!)
      _cur?.buildings.forEach(_bid => {
        const _b = data.buildings.get(_bid);
        if (_b) {
          addCompleteBuldingToResults(_b);
        }
      });
    }
  });

  // look through buildings and see if they, or any of the meters or
  // equipment (if they are included) match
  data.buildings.forEach(_b => {
    // BUILDING NAME MATCH...
    if (matchesFilter(filterValue, _b.name)) {
      addCompleteBuldingToResults(_b);
      // BUILDING DID NOT MATCH, SO CHECK ITS CHILD METERS AND EQUIPMENT
    } else {
      if (includeAssets.includes(AssetType.METER)) {
        // DO METER GROUPS if we are checking out meters...
        _b.meterGroups.forEach(_mgid => {
          const _mg = data.meterGroups.get(_mgid);

          // Meter group OR any of the meter group's meters match the search value
          const meterGroupMatch = matchesFilter(filterValue, _mg?.name || "");
          const meterMatch =
            _mg?.meters.some(meterId =>
              matchesFilter(filterValue, data.meters.get(meterId)?.name || ""),
            ) || false;
          if (_mg && (meterGroupMatch || meterMatch)) {
            // add the building and group and whatnot if we match...
            if (!filteredGroup(_b)) {
              insertIfNotAlreadyPresent(filteredData.ungroupedBuildings, _b.id);
            }
            const _existingB = filteredData.buildings.get(_b.id);
            //
            if (_existingB) {
              insertIfNotAlreadyPresent(_existingB.meterGroups, _mgid);
            } else {
              filteredData.buildings.set(_b.id, {
                id: _b.id,
                groupId: _b.groupId || undefined,
                name: _b.name,
                commodities: [], // building didn't match, so empty!
                timezone: _b.timezone,
                latitude: _b.latitude,
                longitude: _b.longitude,
                meters: [],
                meterGroups: [_mgid],
                enablements: [],
                equipmentGroups: [],
                equipment: [],
              });
            }
            // add all the meters for this group if the group name matches the search
            // input. Otherwise, only add the meter if it matches the search value.
            _mg.meters.forEach(_mid => {
              const _meter = data.meters.get(_mid);
              if (
                _meter &&
                (meterGroupMatch ||
                  (!meterGroupMatch && matchesFilter(filterValue, _meter.name)))
              ) {
                _meter.points.forEach((_pid: string) => {
                  filteredData.points.set(_pid, data.points.get(_pid)!);
                });
                filteredData.meters.set(_mid, _meter);
              }
            });
            // oh, we should probably add ourselves too..
            filteredData.meterGroups.set(_mgid, _mg);
          }
        });
        // this handles all the meters that are not in a group.
        _b.meters.forEach(_mid => {
          const _meter = data.meters.get(_mid);
          if (_meter && matchesFilter(filterValue, _meter.name)) {
            // oh hey, we exist and match
            // add the building we are in if we need to
            if (!filteredGroup(_b)) {
              insertIfNotAlreadyPresent(filteredData.ungroupedBuildings, _b.id);
            }
            // we need to see if the building exists
            // and if not, make a copy with ony this meter
            // otherwise push this ID
            const _existingB = filteredData.buildings.get(_b.id);
            if (_existingB) {
              insertIfNotAlreadyPresent(_existingB.meters, _mid);
            } else {
              filteredData.buildings.set(_b.id, {
                id: _b.id,
                groupId: _b.groupId || undefined,
                name: _b.name,
                commodities: [], // building didn't match, so empty!
                timezone: _b.timezone,
                latitude: _b.latitude,
                longitude: _b.longitude,
                meters: [_mid],
                meterGroups: [],
                enablements: [],
                equipmentGroups: [],
                equipment: [],
              });
            }
            // add the points
            _meter.points.forEach((_pid: string) => {
              filteredData.points.set(_pid, data.points.get(_pid)!);
            });
            filteredData.meters.set(_mid, _meter);
          }
        });
      }
      if (includeAssets.includes(AssetType.EQUIPMENT)) {
        // LOOK AT EQUIPMENT GROUPS.....
        const foundChildEquipmentGroups = hasMatchingChildEquipmentGroups(
          _b.equipmentGroups,
        );
        if (foundChildEquipmentGroups.found) {
          const foundGroups = Array.from(foundChildEquipmentGroups.ids);
          const _existingB = filteredData.buildings.get(_b.id);
          // get da equipment IDs from them there groups
          const equipIdsInGroups = foundGroups.flatMap(id => {
            const eg = data.equipmentGroups.get(id);
            return eg?.equipment ?? [];
          });

          // then add them down below there.
          if (_existingB === undefined) {
            filteredData.buildings.set(_b.id, {
              id: _b.id,
              groupId: _b.groupId || undefined,
              name: _b.name,
              commodities: [], // building didn't match, so empty!
              timezone: _b.timezone,
              latitude: _b.latitude,
              longitude: _b.longitude,
              meters: [],
              meterGroups: [],
              enablements: [],
              equipmentGroups: foundGroups,
              equipment: equipIdsInGroups,
            });
          }
          if (!filteredGroup(_b)) {
            insertIfNotAlreadyPresent(filteredData.ungroupedBuildings, _b.id);
          }
          addEquipmetGroups(foundGroups);
        }

        const foundChildEquipment = hasMatchingChildEquipment(_b.equipment);
        if (foundChildEquipment.found) {
          // all the IDs which includes children / granchildren...
          const foundChildIds = Array.from(foundChildEquipment.ids);
          // these are now the IDs that are direct children of the building
          const topLevelIds = foundChildIds.filter(_i =>
            _b.equipment.includes(_i),
          );
          const _existingB = filteredData.buildings.get(_b.id);
          if (_existingB === undefined) {
            filteredData.buildings.set(_b.id, {
              id: _b.id,
              groupId: _b.groupId || undefined,
              name: _b.name,
              commodities: [], // building didn't match, so empty!
              timezone: _b.timezone,
              latitude: _b.latitude,
              longitude: _b.longitude,
              meters: [],
              meterGroups: [],
              enablements: [],
              equipmentGroups: [],
              equipment: topLevelIds,
            });
          } else {
            _b.equipment = [..._b.equipment, ...topLevelIds];
          }
          if (!filteredGroup(_b)) {
            insertIfNotAlreadyPresent(filteredData.ungroupedBuildings, _b.id);
          }
          addChildEquipment(topLevelIds);
        }
      }
    }
  });

  return filteredData;
};
