import styled from "styled-components/macro";
import { propsToPx } from './Layout';
import {AppColors} from "src/components/common/Styling"

interface DividerProps {
  width: number;
  color?: string;
}
export const Divider = styled.div<DividerProps>`
  display: inline-block;
  width: ${props => propsToPx(props.width)};
  min-width: ${props => propsToPx(props.width)};
  background-color: ${props => props.color || AppColors.neutral["light-navy-9"]};
  align-self: stretch; /* This makes me fill the height of my parent! */
`
