import styled from "styled-components/macro";

const NavigationBar = styled.nav`
  height: 100%;
  background-color: inherit;
  display: flex;

  @media (max-width: 600px) {
    white-space: nowrap;
    width: 400px;
    overflow-y: scroll;
  }
`;

export default NavigationBar;
