import React from "react";
import { useQuery } from "@apollo/client";
import { FormattedMessage } from "react-intl";
import { NavigationLink } from "./NavigationItem";
import { BillManagementEnablementsDocument } from "../../../queries/typed";
import { EnablementType } from "src/types/graphql";
import { tracking } from "src/tracking";
import { SourcesPageEvents } from "../Sources/SourcesPage";

export const hasSources = (data?: any): boolean =>
  data?.getOrganizationById?.enablements?.find(
    (enablement: any) => enablement.type === EnablementType.SOURCES,
  )?.enabled || false;

type SourcesItemProps = {
  organizationId: string;
  active?: string;
};

export const SourcesItem: React.FC<SourcesItemProps> = props => {
  const { organizationId, active } = props;
  const { data } = useQuery(BillManagementEnablementsDocument, {
    variables: {
      organizationId: organizationId,
    },
  });

  if (hasSources(data)) {
    return (
      <NavigationLink
        active={active}
        to={organizationId ? `/sources/${organizationId}` : "/sources"}
        onClick={() => {
          tracking.fireEvent(SourcesPageEvents.LOAD_SOURCES_PAGE, {});
        }}
      >
        <FormattedMessage id="navigation.sources" />
      </NavigationLink>
    );
  }
  return null;
};

export default SourcesItem;
