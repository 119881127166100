import {
  PointType,
  ITimeRange,
  ChartDataGranularity,
} from "src/types/charting";
import config from "src/configuration";

export const availableBaselinePoints = (
  commodityList: PointType[],
): PointType[] => {
  const supportedBaselineChartTypes: Set<PointType> = new Set([
    PointType.CHILLED_WATER_USAGE,
    PointType.ELECTRICITY_USAGE,
    PointType.HOT_WATER_USAGE,
    PointType.NATURAL_GAS_USAGE,
    PointType.STEAM_MASS,
    PointType.STEAM_USAGE,
    PointType.WATER_USAGE,
  ]);

  const isSupportedBaselineChartDataType = (
    chartDataType: PointType,
  ): boolean => {
    return supportedBaselineChartTypes.has(chartDataType);
  };

  return commodityList.reduce(
    (_return: PointType[], _current: PointType) => {
      if (isSupportedBaselineChartDataType(_current)) {
        try {
          _return.push(_current);
        } catch (e) {
          console.error(
            "invalid commodity flagged as baseline enabled in availableBaselinePoints",
          );
        }
      }
      return _return;
    },
    [],
  );
};

export const baselineBuildPDFURL = (
  buildingID: string,
  range: ITimeRange,
  granularity: ChartDataGranularity,
  runDate: Date,
  modelId: string | undefined = undefined,
  pointType: PointType | undefined = undefined,
): string => {
  // NEEDS:
  // buildingID
  // time range
  const reportType =
    granularity === ChartDataGranularity.Hour
      ? "BUILDING_HOURLY_BASELINES"
      : "BUILDING_DAILY_BASELINES";
  console.log(
    `/report/${reportType}?building=${buildingID}&range=${range.startTime}_${range.endTime}&runDate=${runDate.toISOString()}&modelId=${modelId}&pointType=${pointType}`,
  );
  return `${
    config.PDF_SERVICE_URL
  }/report/${reportType}?building=${buildingID}&range=${range.startTime}_${
    range.endTime
  }&runDate=${runDate.toISOString()}&modelId=${modelId}&pointType=${pointType}`;
};
