import React, { useState } from "react";
import CreateProgress from "../CreateOverlayStuff/CreateProgress";
import {
  BuildingAnnotationReason,
  BuildingAnnotationType,
} from "src/types/graphql";
import {
  OverlayFormData,
  OverlayFormAction,
  OverlayFormState,
  OverlayType,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { FormattedMessage } from "react-intl";
import {
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import { BodyLabel } from "src/components/common/BodyCopy";
import AnnotationTypePicker from "../CreateOverlayStuff/AnnotationTypePicker";
import { BuildingSelector } from "src/components/app/ExplorerPage/OverlayStuff/Dropdowns";
import {
  MxPrimaryReactButton,
  MxSecondaryReactButton,
} from "src/componentLibrary/react/mx-button/MxReactButton";
import { DeleteAnnotationModal } from "src/components/app/ExplorerPage/OverlayStuff/EditOverlayStuff/DeleteAnnotationModal";
import { ChartDataAction } from "src/components/app/ExplorerPage/chartDataReducer";
import { ITimeRange } from "src/types/charting";
import { OverlayTypeSelector } from "src/components/app/ExplorerPage/OverlayStuff/CreateOverlayStuff/OverlayTypeSelector";
import {
  AnnotationTypeSelector,
  mapBuildingAnnotationTypeToOverlayType,
} from "src/components/app/ExplorerPage/OverlayStuff/EditOverlayStuff/AnnotationTypeSelector";
import { DeleteScheduleExceptionModal } from "src/components/app/ExplorerPage/OverlayStuff/EditOverlayStuff/DeleteScheduleExceptionModal";
import { defaultBuildingForOverlayForm } from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";

const EditStepOne = (props: {
  dispatch: (arg0: OverlayFormAction) => void;
  state: OverlayFormState;
  latitude: number;
  longitude: number;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
  timeRange: ITimeRange;
}) => {
  const { formData } = props.state;

  const [building, setSelectedBuilding] = useState(
    defaultBuildingForOverlayForm(props.state),
  );
  const [
    annotationType,
    setAnnotationType,
  ] = useState<BuildingAnnotationType | null>(formData.annotationType ?? null);
  const [annotationCategories, setAnnotationCategories] = useState<
    BuildingAnnotationReason[]
  >(formData.annotationCategories ?? []);
  const [showDeleteAnnotationModal, setShowDeleteAnnotationModal] = useState(
    false,
  );
  const [
    showDeleteScheduleExceptionModal,
    setShowDeleteScheduleExceptionModal,
  ] = useState(false);

  const isAnnotation = (annotationType: BuildingAnnotationType | null) =>
    annotationType !== null;
  const isScheduleException = (overlayType: OverlayType | null) =>
    overlayType === OverlayType.ScheduleException;
  const isAnnotationOrScheduleException = ({
    annotationType,
    overlayType,
  }: Pick<OverlayFormData, "annotationType" | "overlayType">) =>
    isAnnotation(annotationType ?? null) || isScheduleException(overlayType);

  const handleNextStep = () => {
    if (building !== undefined) {
      if (
        isAnnotationOrScheduleException({
          annotationType,
          overlayType: formData.overlayType,
        })
      ) {
        const payload: Partial<OverlayFormData> = isAnnotation(annotationType)
          ? {
              buildingId: building.id,
              annotationType,
              timezone: building.timezone,
              latitude: props.latitude,
              longitude: props.longitude,
              annotationCategories,
              // TODO: Revisit this to see if we can remove annotationType altogether
              overlayType: annotationType
                ? mapBuildingAnnotationTypeToOverlayType(annotationType)
                : formData.overlayType,
            }
          : {
              buildingId: building.id,
              timezone: building.timezone,
              latitude: props.latitude,
              longitude: props.longitude,
              annotationCategories,
              // TODO: Revisit this to see if we can remove annotationType altogether
              overlayType: formData.overlayType,
            };
        props.dispatch({
          type: "SAVE_EDIT_STEP_ONE",
          payload,
        });
        props.dispatch({
          type: "GO_EDIT_STEP_TWO",
        });
      }
    }
  };

  const handleDelete = () => {
    if (isAnnotation(annotationType)) {
      setShowDeleteAnnotationModal(true);
    } else if (isScheduleException(formData.overlayType)) {
      setShowDeleteScheduleExceptionModal(true);
    }
  };

  // TODO: Parameterize this / extract from bounding context
  function renderOverlayCategoryPicker() {
    // Allow picking categories if it's an annotation or a schedule exception
    if (
      isAnnotationOrScheduleException({
        annotationType,
        overlayType: formData.overlayType,
      })
    ) {
      return (
        <AnnotationTypePicker
          defaultSelected={annotationCategories ?? undefined}
          onChange={setAnnotationCategories}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <CreateProgress step={1} />
      <Box margin={[6, 0, 8, 0]}>
        <VerticalLayout childSpacing={8}>
          <VerticalLayout childSpacing={2}>
            <BodyLabel className="labels" htmlFor="buildingId">
              <FormattedMessage id="charts.explorer.overlays.create.building" />
            </BodyLabel>
            <BuildingSelector
              defaultValue={building}
              items={props.state.ui.buildingsSelected ?? []}
              onChange={building => {
                setSelectedBuilding(building);
              }}
            />
          </VerticalLayout>
          <VerticalLayout childSpacing={2}>
            <BodyLabel className="labels" htmlFor="overlayType">
              <FormattedMessage id="charts.explorer.overlays.create.overlayType" />
            </BodyLabel>
            {formData.overlayType === OverlayType.ScheduleException ? (
              <OverlayTypeSelector
                disabled={true}
                defaultSelected={OverlayType.ScheduleException}
                onChange={() => {}}
              />
            ) : (
              <AnnotationTypeSelector
                defaultSelected={annotationType ?? undefined}
                onChange={value => {
                  if (value) {
                    setAnnotationType(value);
                  }
                }}
              />
            )}
          </VerticalLayout>
          {isAnnotationOrScheduleException({
            annotationType,
            overlayType: formData.overlayType,
          }) && (
            <VerticalLayout childSpacing={2}>
              <BodyLabel className="labels" htmlFor="category">
                <FormattedMessage id="charts.explorer.overlays.annotationCategory" />
              </BodyLabel>
              {renderOverlayCategoryPicker()}
            </VerticalLayout>
          )}
        </VerticalLayout>
      </Box>
      <HorizontalLayout childSpacing={2}>
        <MxSecondaryReactButton
          fullWidthOfParent
          onClick={handleDelete}
          disabled={building === undefined}
          intlTextId="common.button.labels.delete"
          color={"danger"}
          size={"md"}
        />
        <MxPrimaryReactButton
          fullWidthOfParent
          onClick={handleNextStep}
          disabled={
            building === undefined ||
            !isAnnotationOrScheduleException({
              annotationType,
              overlayType: formData.overlayType,
            }) ||
            annotationCategories.length === 0
          }
          intlTextId="common.button.labels.next"
        />
      </HorizontalLayout>
      {showDeleteAnnotationModal && (
        <DeleteAnnotationModal
          annotation={{
            // TODO: Don't non-null assert these. Deal with the error case if necessary.
            id: formData.overlayId!,
            title: formData.title!,
          }}
          onClose={() => setShowDeleteAnnotationModal(false)}
          buildingId={building?.id!}
          dispatch={props.dispatch}
          chartDataDispatch={props.chartDataDispatch}
        />
      )}
      {showDeleteScheduleExceptionModal && (
        <DeleteScheduleExceptionModal
          exception={{
            // TODO: Don't non-null assert these. Deal with the error case if necessary.
            id: formData.overlayId!,
            title: formData.title!,
          }}
          onClose={() => setShowDeleteScheduleExceptionModal(false)}
          buildingId={building?.id!}
          dispatch={props.dispatch}
          chartDataDispatch={props.chartDataDispatch}
        />
      )}
    </>
  );
};

export default EditStepOne;
