import React, { useState, useEffect, PropsWithChildren } from "react";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import hackyAuthClient from "./Auth";

type AuthContextProps = {
  user: boolean;
};

const AuthContext = React.createContext<Partial<AuthContextProps>>({});

const AuthProvider: React.FC<PropsWithChildren<unknown>> = props => {
  const [performedCheck, setPerformedCheck] = useState(false);
  const [user, setUser] = useState(false);

  useEffect(() => {
    hackyAuthClient.renewSession().then(val => {
      if (val === true) {
        setUser(true);
        setPerformedCheck(true);
      } else {
        setPerformedCheck(true);
      }
    });
  }, []);

  if (!performedCheck) {
    return <CenteredLoadingIndicator />;
  }

  return <AuthContext.Provider value={{ user }} {...props} />;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be inside an AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
