import { AssetState, AssetStateIndicator } from "./types";

/* =============================================================== *\
   REDUCER STATE SHAPE

   But vedder you dummy; why don't you just keep everything in one
   big map instead of two? That would be much better!

   It would! Except that points inherit an ID oftentimes, so it can
   be the same as building IDs. Maybe we can change this soon when
   we stop queriying at the meter level! That would be cool.
\* =============================================================== */
export interface AssetReducerState {
  buildingStates: AssetStateIndicator;
  pointStates: AssetStateIndicator;
  groupStates: AssetStateIndicator;
}

/* =============================================================== *\
   ACTION TYPES (hooray for typescript?!)
\* =============================================================== */
type UpdateMeterStateAction = {
  type: "UPDATE_POINT_STATE";
  payload: {
    id: string;
    assetState: AssetState;
  };
};
type UpdateBuildingStateAction = {
  type: "UPDATE_BUILDING_STATE";
  payload: {
    id: string;
    assetState: AssetState;
  };
};
type UpdateGroupStateAction = {
  type: "UPDATE_GROUP_STATE";
  payload: {
    id: string;
    assetState: AssetState;
  };
};
type ClearBuildingStateAction = { type: "CLEAR_BUILDING_STATE" };
type ReplaceGroupStateAction = {
  type: "REPLACE_GROUP_STATE";
  payload: {
    newState: AssetStateIndicator;
  };
};
type ReplaceBuildingStateAction = {
  type: "REPLACE_BUILDING_STATE";
  payload: {
    newState: AssetStateIndicator;
  };
};
type ReplacePointStateAction = {
  type: "REPLACE_POINT_STATE";
  payload: {
    newState: AssetStateIndicator;
  };
};
export type AssetStateAction =
  | ReplaceBuildingStateAction
  | ReplacePointStateAction
  | ReplaceGroupStateAction
  | ClearBuildingStateAction
  | UpdateBuildingStateAction
  | UpdateGroupStateAction
  | UpdateMeterStateAction;

/* =============================================================== *\
   THE ACTUAL FUNCTION!
\* =============================================================== */
export const assetStateReducer = (
  state: AssetReducerState,
  action: AssetStateAction,
): AssetReducerState => {
  switch (action.type) {
    case "CLEAR_BUILDING_STATE":
      return {
        ...state,
        buildingStates: {},
      };
    case "REPLACE_BUILDING_STATE":
      return {
        ...state,
        buildingStates: action.payload.newState,
      };
    case "REPLACE_GROUP_STATE":
      return {
        ...state,
        groupStates: action.payload.newState,
      };
    case "REPLACE_POINT_STATE":
      return {
        ...state,
        pointStates: action.payload.newState,
      };

    case "UPDATE_GROUP_STATE":
      const _gs = {
        ...state,
        groupStates: {
          ...state.groupStates,
          [action.payload.id]: action.payload.assetState,
        },
      };
      return _gs;

    case "UPDATE_BUILDING_STATE": {
      const _bs = {
        ...state,
        buildingStates: {
          ...state.buildingStates,
          [action.payload.id]: action.payload.assetState,
        },
      };
      return _bs;
    }
    case "UPDATE_POINT_STATE": {
      return {
        ...state,
        pointStates: {
          ...state.pointStates,
          [action.payload.id]: action.payload.assetState,
        },
      };
    }
    default:
      throw new Error(`Unknown action sent to assetStateReducer`);
  }
};

/**
 * determines if any asset or group is loading or selected and
 * returns true if that is the case. Handy for showing something
 * other than "make a selection" if there are no charts on screen
 * because we are loading new data for existing selections.
 */
export const hasAssetSelections = (state: AssetReducerState): boolean => {
  for (const group in state.groupStates) {
    if (
      state.groupStates[group] === AssetState.LOADING ||
      state.groupStates[group] === AssetState.SELECTED
    ) {
      return true;
    }
  }
  for (const building in state.buildingStates) {
    if (
      state.buildingStates[building] === AssetState.LOADING ||
      state.buildingStates[building] === AssetState.SELECTED
    ) {
      return true;
    }
  }
  for (const point in state.pointStates) {
    if (
      state.pointStates[point] === AssetState.LOADING ||
      state.pointStates[point] === AssetState.SELECTED
    ) {
      return true;
    }
  }
  return false;
};
