import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { AppColors } from "../Styling";

const bounce = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(10px);
  }
`;

const Dot = styled.span<{ delay: number }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${AppColors.primary["msr-green"]};
  animation: 0.6s ${bounce} ease-in-out alternate infinite;
  animation-delay: ${props => props.delay}s;
  will-change: transform;
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 60px;
  height: 30px;
  padding-top: 5px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const CenteredWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  flex-direction: column;
`;

const spinFrames = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// TODO: have all the static sized spinner just return SizableSpindicators of the appropriate size
const SpinnerStyle = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 48px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 51px;
    height: 51px;
    margin: 6px;
    border: 3px solid ${AppColors.neutral.black};
    border-radius: 50%;
    animation: ${spinFrames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${AppColors.neutral.black} transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

const SmallSpindicatorStyle = styled.div`
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  /* margin: 48px; */
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 4px;
    border: 2px solid ${AppColors.neutral.black};
    border-radius: 50%;
    animation: ${spinFrames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${AppColors.neutral.black} transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;
//const TinySpindicatorStyle = styled(SmallSpindicatorStyle)`
const TinySpindicatorStyle = styled.div`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 1px solid ${AppColors.neutral["light-navy-9"]};
    border-radius: 50%;
    animation: ${spinFrames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${AppColors.neutral["light-navy-9"]} transparent transparent
      transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

//const Dot = styled.span<{ delay: number }>`
const VariableSpindicatorStyle = styled.div<{ size: number }>`
  display: inline-block;
  position: relative;
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${props => `${props.size - 8}px`};
    height: ${props => `${props.size - 8}px`};
    margin: ${props => `${Math.round(props.size / 8)}px`};
    border: ${props =>
      `${props.size >= 32 ? 2 : 1}px solid ${AppColors.neutral["light-navy-3"]}`};
    border-radius: 50%;
    animation: ${spinFrames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${AppColors.neutral["light-navy-3"]} transparent transparent
      transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;

export type LoadingIndicatorProps = {
  children?: React.ReactNode;
};

export const LoadingIndicator = (props: LoadingIndicatorProps) => (
  <Wrapper>
    <DotsContainer>
      <Dot delay={0} />
      <Dot delay={0.3} />
      <Dot delay={0.6} />
    </DotsContainer>
    {props.children}
  </Wrapper>
);

export const CenteredLoadingIndicator = (props: LoadingIndicatorProps) => (
  <CenteredWrapper data-testid="loading-indicator">
    <LoadingIndicator />
    <div>{props.children}</div>
  </CenteredWrapper>
);

export const SpinnerIndicator = (props: LoadingIndicatorProps) => (
  <SpinnerStyle>
    <div />
    <div />
    <div />
    <div />
    {props.children}
  </SpinnerStyle>
);

export const SmallSpindicator = () => (
  <SmallSpindicatorStyle>
    <div />
    <div />
    <div />
    <div />
  </SmallSpindicatorStyle>
);
export const TinySpindicator = () => (
  <TinySpindicatorStyle>
    <div />
    <div />
    <div />
    <div />
  </TinySpindicatorStyle>
);

type SizableSpindicatorProps = {
  size?: number;
};
export const SizableSpindicator = (props: SizableSpindicatorProps) => {
  return (
    <VariableSpindicatorStyle size={props.size || 32}>
      <div />
      <div />
      <div />
      <div />
    </VariableSpindicatorStyle>
  );
};
