import React from "react";
import { FallbackProps } from "react-error-boundary";
import CenteredContentWrapper from "src/components/app/CenteredContentWrapper";
import { ApplicationErrorWrapper } from "src/components/common/ApplicationError";
import { tracking } from "src/tracking";
import { Body, H2 } from "src/components/common/Typography";
import { MxPrimaryReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";
import { FormattedMessage } from "react-intl";

/**
 * Component that will let a user reset explorer state and start over.
 * @param props
 */
export const ErrorFallback: React.FC<FallbackProps> = props => {
  const { resetErrorBoundary, error } = props;
  tracking.fireErrorEvent("ERROR_BOUNDARY_TRIGGER", {}, error);
  return (
    <CenteredContentWrapper>
      <ApplicationErrorWrapper>
        <H2>
          <FormattedMessage id="explorer.errors.genericError" />
        </H2>
        <Body>
          <FormattedMessage id="explorer.errors.genericErrorMessage" />
        </Body>
        <div>
          <MxPrimaryReactButton
            onClick={resetErrorBoundary}
            intlTextId="explorer.errors.resetExplorer"
          />
        </div>
      </ApplicationErrorWrapper>
    </CenteredContentWrapper>
  );
};
