import { useEffect } from "react";
import { useNavigate, To } from "react-router-dom";

type RedirectProps = {
  to: To;
};

export const Redirect = (props: RedirectProps) => {
  const { to } = props;

  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
};
