import { parse, stringify } from "query-string";
import config from "src/configuration";
/* =============================================================== *\
  example INPUT URL

  "granularity=FIFTEEN_MINUTE" +
  "&buildings=c2cd6246-6e08-4400-88ee-e70b8839b1bb:STEAM_MASS;7570b924-5eb9-4293-9afc-591f7804b857:CHILLED_WATER_USAGE,TEMPERATURE" +
  "&points=c89ab08b-fb8b-4f45-9c84-dce3d4598dc8:HOT_WATER_USAGE" +
  "&timezone=America/New_York" +
  "&start=2020-03-28T04:00:00.000Z" +
  "&end=2020-04-28T01:00:00.000Z";

  
   Example OUTPUT URL:

   https://service.endpoint.com
   report/ON_DEMAND
   ?granularity=FIFTEEN_MINUTE
   &buildings=c2cd6246-6e08-4400-88ee-e70b8839b1bb:STEAM_MASS;7570b924-5eb9-4293-9afc-591f7804b857:CHILLED_WATER_USAGE,TEMPERATURE
   &points=c89ab08b-fb8b-4f45-9c84-dce3d4598dc8:HOT_WATER_USAGE
   &range=2020-04-22T04:00:00.000Z_2020-04-29T04:00:00.000Z
   &range=2019-04-22T04:00:00.000Z_2019-04-29T04:00:00.000Z
\* =============================================================== */

import {
  ITimeRange,
  ExportType,
  ChartDataGranularity,
} from "src/types/charting";

const urlPartsFromExplorerURL = (
  explorerState: string,
  removeGranularity: boolean,
): string => {
  const parts = parse(explorerState);
  if (
    !parts.start ||
    !parts.end ||
    (!parts.buildings && !parts.points && !parts.groups && !parts.equipmentPoints)
  ) {
    return "";
  }

  // timezone is optional in pdf-service, but if it receives "timezone=undefined" it blows up.
  if (parts.timezone === "undefined") {
    delete parts.timezone;
  }

  // add a range param made up of start and end
  const rangeParam = `${parts.start}_${parts.end}`;
  parts["range"] = rangeParam;
  // then nuke the start and end
  delete parts.start;
  delete parts.end;

  // rename groups to buildingGroups and remove the original
  parts["buildingGroups"] = parts.groups;
  delete parts.groups;

  if (removeGranularity) {
    delete parts.granularity;
  }

  // put it back together!
  return stringify(parts, { sort: false });
};

/**
 * Takes the search string part of an explorer URL and returns the URL
 * from which you can get a PDF of that same data set
 * @param explorerState - the "normal" explorer URL bits after the ?
 */
const buildExportUrl = (
  explorerState: string,
  secondaryTimeRange: ITimeRange,
  compareToPast: boolean,
  exportType: ExportType,
  granularity?: ChartDataGranularity,
): string => {
  // get the service URL
  // add the path for the report and the ?

  const existingULRParts = urlPartsFromExplorerURL(
    explorerState,
    granularity !== undefined,
  );
  let url = `${config.PDF_SERVICE_URL}/report/ON_DEMAND?${existingULRParts}`;
  if (compareToPast) {
    // encoded to match what is happening in the other parts
    const secondaryRangeStr = `&range=${encodeURIComponent(
      secondaryTimeRange.startTime + "_" + secondaryTimeRange.endTime,
    )}`;
    url += secondaryRangeStr;
  }

  if (granularity) {
    url += `&granularity=${encodeURIComponent(granularity)}`;
  }

  url += `&format=${exportType}`;

  return url;
};
export default buildExportUrl;
