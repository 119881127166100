import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable,
  Operation,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import Auth from "src/auth/Auth";
import config from "src/configuration";

import introspectionQueryResultData from "./introspection-result.json";

export const cache = new InMemoryCache({
  possibleTypes: introspectionQueryResultData.possibleTypes,
});

const request = async (operation: Operation) => {
  let validToken = Auth.isAuthenticated();
  if (!validToken) {
    Auth.logout(true);
    return;
  }

  const needsRefresh = Auth.isTokenReadyForRefresh();
  if (needsRefresh) {
    await Auth.renewSession();
  }
  // we get logged out if the above fails, FYI.
  operation.setContext({
    headers: {
      "x-api-key": config.BUILDING_MODEL_API_KEY,
      Authorization: `Bearer ${Auth.getAccessToken()}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      // Lame, I know. But this is what apollo boost does so ¯\_(ツ)_/¯
      let handle: any;
      Promise.resolve(operation)
        .then(op => request(op))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors && graphQLErrors.length) {
        for (let i = 0; i < graphQLErrors.length; i++) {
          const err = graphQLErrors[i];
          if (err.extensions && err.extensions.code) {
            if (err.extensions.code === "UNAUTHENTICATED") {
              if (err.message === "auth token not provided or invalid") {
                Auth.logout(true);
              }
            }
          }
        }
      }
      if (networkError) {
        console.error("GraphQL: a network error occurred", networkError);
      }
    }),
    requestLink,
    createUploadLink({
      uri: config.BUILDING_MODEL_API_URL,
      headers: { "Apollo-Require-Preflight": "true" },
    }),
  ]),
});

export default client;
