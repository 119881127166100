import React, { FC } from "react";
import { MxReactIcon } from "src/componentLibrary/react/mx-icon-react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import { AppColors } from "../Styling";
import { SizableSpindicator } from "../LoadingIndicator";

type DotMenuIconItemProps = {
  intlTextId: string;
  icon: FC;
  color?: string;
  showLoading?: boolean;
};

const DotMenuIconItem: React.FC<DotMenuIconItemProps> = ({
  intlTextId,
  icon,
  color,
  showLoading,
}) => {
  return (
    <>
      <MxReactIcon Icon={icon} />
      <DotMenuText color={color}>
        <FormattedMessage id={intlTextId} />
        {showLoading && <SizableSpindicator size={24} />}
      </DotMenuText>
    </>
  );
};

const DotMenuText = styled.span<{ color?: string }>`
  margin-left: 8px;
  display: inline-block;
  color: ${props => props.color || AppColors.neutral.black};
`;

export default DotMenuIconItem;
