import { SelectorData, SelectorGroup } from "./types";

/**
 * This is a really hacky function to get a "reference timezone" for
 * a group. Majority (plurality?) rule: it counts instances of
 * each timezone occurring in the group and returns the timezone with
 * the highest count.
 *
 * The only reason we need this is to display group-level data if the
 * user clicked on *only* a group.
 *
 * @param selectorData The selectorData object with the user's asset info
 * @param group The group for which you need a reference timezone
 */
export const findReferenceTimezoneForGroup = (
  selectorData: SelectorData,
  group: SelectorGroup,
): string => {
  const groupTimezoneCounts = getGroupTimezoneCounts(selectorData, group);
  return Object.keys(groupTimezoneCounts).reduce((acc, tz) => {
    return !groupTimezoneCounts[acc] ||
      groupTimezoneCounts[tz] > groupTimezoneCounts[acc]
      ? tz
      : acc;
  }, "America/New_York");
};

export function getGroupTimezoneCounts(
  selectorData: SelectorData,
  group: SelectorGroup,
): { [index: string]: number } {
  const groupTimezones = getGroupTimezones(selectorData, group);
  const uniqueTimezones = [...new Set(groupTimezones)];
  return uniqueTimezones.reduce((acc, tz) => {
    return { ...acc, [tz]: groupTimezones.filter(zone => tz === zone).length };
  }, {});
}

export function getGroupTimezones(
  selectorData: SelectorData,
  group: SelectorGroup,
): string[] {
  // Get the timezones for all the ungrouped buildings
  const buildingTimezones = group.buildings.reduce((acc: string[], b) => {
    const building = selectorData.buildings.get(b);
    return building ? [...acc, building.timezone] : acc;
  }, []);
  // Get the timezones for all the subgroups
  const subgroupTimezones = group.groups.reduce((acc: string[], g) => {
    const subgroup = selectorData.groups.get(g);
    return subgroup
      ? [...acc, ...getGroupTimezones(selectorData, subgroup)]
      : acc;
  }, []);
  // Merge the timezones from ungrouped buildings and from the subgroups
  return [...buildingTimezones, ...subgroupTimezones];
}
