import styled from "styled-components/macro";

export * from "src/components/common/Heading";
export * from "src/components/common/BodyCopy";
export * from "src/components/common/Link";
export * from "src/components/common/InfoText";

export const MultilineText = styled.span`
  white-space: pre-wrap;
`;
