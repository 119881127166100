import * as Types from "../../../../types/graphql";
import { Maybe, Point } from "../../../../types/graphql";
import { Body, InlineH4, InfoText2 } from "src/components/common/Typography";
import { useState } from "react";
import { IngestMetadataView } from "../IngestMetadataView";
import {
  MxReactIcon,
  ChevronDown,
  ChevronRight,
} from "src/componentLibrary/react/mx-icon-react";
import { PageErrorBoundary } from "src/components/common/ApplicationError/PageErrorBoundary";
import { PointsToolTip } from "../Point/PointTooltip";
import { AppColors } from "src/components/common/Styling";

const IngestPointCaret = (props: { ingestPointId: string }) => {
  const [ingestShowing, setIngestShowing] = useState<boolean>(false);
  return (
    <>
      <div
        onClick={() => {
          setIngestShowing(!ingestShowing);
        }}
      >
        <MxReactIcon
          Icon={ingestShowing ? ChevronDown : ChevronRight}
          color={AppColors.neutral.white}
          size="s"
        />
      </div>
      <IngestMetadataView
        ingestPointId={props.ingestPointId}
        showing={ingestShowing}
      />
    </>
  );
};

const IngestPointTableCell = (props: { ingestPointId?: Maybe<string> }) => {
  return (
    <>
      <InlineH4>
        {props.ingestPointId ? (
          <IngestPointCaret ingestPointId={props.ingestPointId} />
        ) : (
          ""
        )}
      </InlineH4>
    </>
  );
};

export const PointsTable = (props: {
  points: Array<
    Types.Maybe<
      | ({ __typename?: "Point" } & Pick<
          Types.Point,
          | "id"
          | "name"
          | "type"
          | "lowerSpikeThreshold"
          | "spikeThreshold"
          | "ingestPointId"
          | "customerPointId"
        > & {
            diagnostics?: Types.Maybe<
              { __typename?: "PointDiagnostics" } & Pick<
                Types.PointDiagnostics,
                "lastReceiveTime"
              >
            >;
          })
      | ({ __typename?: "VirtualPoint" } & Pick<
          Types.VirtualPoint,
          | "id"
          | "name"
          | "type"
          | "lowerSpikeThreshold"
          | "spikeThreshold"
          | "ingestPointId"
        >)
    >
  >;
}) => {
  const { points } = props;
  if (points === undefined || points === null) {
    return <div />;
  }
  if (points.length > 0) {
    return (
      <PageErrorBoundary>
        {PointsToolTip(points)}
        <div>
          <Body>Points</Body>
          <table>
            <thead>
              <tr>
                <td>
                  <InfoText2>Id</InfoText2>
                </td>
                <td>
                  <InfoText2>Name</InfoText2>
                </td>
                <td>
                  <InfoText2>Type</InfoText2>
                </td>
                <td>
                  <InfoText2>Customer Point id</InfoText2>
                </td>
                <td>
                  <InfoText2>Ingest</InfoText2>
                </td>
              </tr>
            </thead>
            <tbody>
              {points
                .filter(point => point && point.__typename === "Point")
                .map(point => {
                  if (point === undefined || point === null) {
                    return null;
                  }
                  const {
                    name,
                    id,
                    type,
                    ingestPointId,
                    customerPointId,
                  } = point as Point;
                  return (
                    <tr
                      key={name}
                      data-for={"point-tooltip"}
                      data-tip={JSON.stringify(point)}
                    >
                      <td>
                        <InlineH4>{id}</InlineH4>
                      </td>
                      <td>
                        <InlineH4>{name}</InlineH4>
                      </td>
                      <td>
                        <InlineH4>{type}</InlineH4>
                      </td>
                      <td>
                        <InlineH4>{customerPointId}</InlineH4>
                      </td>
                      <td>
                        <IngestPointTableCell ingestPointId={ingestPointId} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </PageErrorBoundary>
    );
  } else {
    return null;
  }
};
