import styled from "styled-components/macro";
import { Body } from "src/components/common/Typography";
import { AppColors } from "src/components/common/Styling";

const AppToolbar = styled.div`
  display: flex;
  flex-direction: column;
  width: 70px;
  background-color: ${AppColors.neutral["light-navy-1"]};

  ${Body} {
    display: none;
  }

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: row;
    align-items: center;

    ${Body} {
      display: flex;
      color: white;
    }
  }
`;

export default AppToolbar;
