import { BuildingAnnotationType } from "src/types/graphql";
import { OverlayType } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import CustomSelect from "src/components/common/Dropdown/CustomSelect";
import { FormattedMessage } from "react-intl";
import React from "react";

export const mapBuildingAnnotationTypeToOverlayType = (
  type: BuildingAnnotationType,
) => {
  switch (type) {
    case BuildingAnnotationType.PEAK_DEMAND:
      return OverlayType.PeakDemand;
    case BuildingAnnotationType.HIGH_USAGE:
      return OverlayType.HighUsage;
    case BuildingAnnotationType.CUSTOM:
      return OverlayType.Custom;
  }
};
export const AnnotationTypeSelector = ({
  onChange,
  defaultSelected,
  width = "100%",
}: {
  onChange: (value: BuildingAnnotationType) => void;
  defaultSelected?: BuildingAnnotationType;
  width?: string;
}) => {
  const items: BuildingAnnotationType[] = Object.values(BuildingAnnotationType);
  return (
    <CustomSelect
      values={items}
      value={defaultSelected}
      renderSelectedItem={selectedItem => {
        return selectedItem ? (
          <FormattedMessage
            id={`charts.explorer.overlays.${mapBuildingAnnotationTypeToOverlayType(
              selectedItem,
            )}`}
          />
        ) : (
          <FormattedMessage id="charts.explorer.overlays.create.selectType" />
        );
      }}
      renderItem={item => {
        return (
          <FormattedMessage
            id={`charts.explorer.overlays.${mapBuildingAnnotationTypeToOverlayType(
              item,
            )}`}
          />
        );
      }}
      onChange={onChange}
      displayPropsForButton={() => {
        return {
          width: width,
        };
      }}
    />
  );
};
