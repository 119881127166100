import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";

// note: position: relative needed for shadow to show up.

/* =============================================================== *\
    / \
   / ! \
  /_____\

   WARNING!!!


   The `SidebarPage` component uses the HEIGHT of this component
   in a `calc()` rule. If you change the height here, you will almost
   certainly want to change the height there.
\* =============================================================== */
const AppHeader = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${AppColors.neutral.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 8px;
  position: relative;
  padding-left: 16px;
`;

export default AppHeader;
