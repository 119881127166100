import styled from "styled-components/macro";
import { LayoutProps, ResponsiveLayoutProps, propsToPx } from './Layout';

/**
 * Component that will lay out its direct children in a horizontal line from the left to the right.
 */
export const HorizontalLayout = styled.div<LayoutProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: auto;
  & > * {
    margin-right: ${props => propsToPx(props.childSpacing)};
    &:last-child {
      margin-right: 0;
    }
  }
`
/**
 * Component that will lay out its direct children in a horizontal line from the left to the right.
 * Will make all children are stretched such that the cross-size of the item's margin box is the same as the line while respecting width and height constraints. 
 */
export const StretchHorizontalLayout = styled(HorizontalLayout)`
  align-items: stretch;
`

/**
 * Component that will place children in a horizontal line, but keep them in the horizontal center of the Layout
 */
export const CenteredHorizontalLayout = styled(HorizontalLayout)`
  justify-content: center;
`;

/**
 * Component that will lay out its direct children in a horizontal line from the left to the right. At the
 * given `respondsAt` width (default 1200px) the layout will switch to vertically stacked. An alternate spacing
 * for the children can be provided for this orientation.
 */
export const ResponsiveHorizontalLayout = styled(HorizontalLayout)<ResponsiveLayoutProps>`
  @media (max-width: ${props => props.respondsAt || "1200px"}) {
      flex-direction: column;
      height: auto;
      align-items: flex-start;
      & > * {
        margin-right: 0;
        margin-bottom: ${props => propsToPx(props.responsiveChildSpacing || props.childSpacing)};
        &:last-child {
          margin-bottom: 0;
        }
      }
  }
`;
export default HorizontalLayout;