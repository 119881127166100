import { ResultOf, VariablesOf } from "@graphql-typed-document-node/core";
import {
  CreateScheduleExceptionDocument,
  GetOperatingScheduleDocument,
} from "src/queries/typed";
import { OverlayFormData } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { DateTime } from "luxon";
import { convertExceptionToOperatingPeriods } from "src/components/app/BuildingSettingsPage/helpers";
import { TimeRangeInput } from "src/types/graphql";

type CreateExceptionMutationVariables = VariablesOf<
  typeof CreateScheduleExceptionDocument
>;

export type OperatingSchedule = NonNullable<
  ResultOf<typeof GetOperatingScheduleDocument>["getBuildingById"]
>["operatingSchedule"];

// TODO: We ought to be able to just have one function for this. But different
//   assumptions were made about the formData depending on whether we are in
//   the single day exception case vs. the multi-day case. As long as we're still
//   using StepThree_Exceptions and StepThree_MultiDay, these two functions should
//   probably stay separated, but when we get around to combining them, figure out
//   how to meld these functions into one.
export const convertStateToMutationVariablesForStepThreeMultiDay = (
  inputFormData: OverlayFormData,
  operatingSchedule: OperatingSchedule,
): CreateExceptionMutationVariables => {
  const startDate = DateTime.fromJSDate(inputFormData.startDate as Date)
    .setZone(inputFormData.timezone, {
      keepLocalTime: true,
    })
    .startOf("day");

  // end date is exclusive, so this will give us to the end of the date the user selected
  const endDate = DateTime.fromJSDate(inputFormData.endDate as Date)
    .setZone(inputFormData.timezone, {
      keepLocalTime: true,
    })
    .startOf("day")
    .plus({
      days: 1,
    });

  // To make things "easy", all the time comparisons are done using dates set to the current day

  const formStartTime = DateTime.fromJSDate(inputFormData.startDate as Date);
  const formEndTime = DateTime.fromJSDate(inputFormData.endDate as Date);

  const startTime = DateTime.local()
    .startOf("day")
    .set({ hour: formStartTime.hour, minute: formStartTime.minute });

  const endTime = DateTime.local()
    .startOf("day")
    .set({ hour: formEndTime.hour, minute: formEndTime.minute });

  const variables = {
    operatingPeriods: convertExceptionToOperatingPeriods(
      startDate.toJSDate(),
      endDate.toJSDate(),
      {
        startTime,
        endTime,
      },
      operatingSchedule || undefined,
      false,
      inputFormData.isOccupied,
    ),
    title: inputFormData.title!,
    buildingId: inputFormData.buildingId!,
    notes: inputFormData.note,
    reasons: inputFormData.annotationCategories,
    exceptionOperatingPeriods: [
      {
        startHour: startTime.get("hour"),
        startMinute: startTime.get("minute"),
        endHour: endTime.get("hour"),
        endMinute: endTime.get("minute"),
        isOperating: inputFormData.isOccupied,
      },
    ],
    applicability: {
      startTime: startDate.toUTC().toString(),
      endTime: endDate.toUTC().toString(),
    } as TimeRangeInput,
  };

  return variables;
};
const convertTimeToDateTime = (time: number) => {
  return DateTime.local()
    .startOf("day")
    .set({ second: time * 60 * 60 });
};

export const convertStateToMutationVariablesForStepThreeExceptions = (
  inputFormData: OverlayFormData,
  operatingSchedule: OperatingSchedule,
): CreateExceptionMutationVariables => {
  const startTime = convertTimeToDateTime(inputFormData.startTime!);
  const endTime = convertTimeToDateTime(inputFormData.endTime!);
  // const startDate = getValues("startDate");

  const variables = {
    operatingPeriods: convertExceptionToOperatingPeriods(
      inputFormData.startDate!,
      inputFormData.startDate!,
      {
        startTime,
        endTime,
      },
      operatingSchedule || undefined,
      inputFormData.startTime === 0 && inputFormData.endTime === 24,
      inputFormData.isOccupied,
    ),
    title: inputFormData.title!,
    buildingId: inputFormData.buildingId!,
    notes: inputFormData.note,
    reasons: inputFormData.annotationCategories,
    exceptionOperatingPeriods: [
      {
        startHour: startTime.get("hour") ?? 0,
        startMinute: startTime.get("minute") ?? 0,
        endHour: endTime.get("hour") ?? 0,
        endMinute: endTime.get("minute") ?? 0,
        isOperating: inputFormData.isOccupied,
      },
    ],
    applicability: {
      startTime: DateTime.fromJSDate(inputFormData.startDate!)
        .setZone(inputFormData.timezone, { keepLocalTime: true })
        .startOf("day")
        .toUTC()
        .toString(),
      // TODO: Fix this! It should really be endDate to reconcile with multi-day, but it
      //   broke creating single-day exceptions because of the way form controls were designed.
      endTime: DateTime.fromJSDate(inputFormData.startDate!)
        .setZone(inputFormData.timezone, { keepLocalTime: true })
        .startOf("day")
        .plus({ days: 1 }) // end date is exclusive, so this will give us to the end of the date the user selected
        .toUTC()
        .toString(),
    } as TimeRangeInput,
  };

  return variables;
};
