import React, { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ApplicationError from "./ApplicationError";

/**
 *  Error boundary for use at high level (page) parts of the app.
 *
 * Wrap a page with this, and if the page throws an unhandled exception, it will
 * display the ApplicationError component instead of a WSoD. See the ApplicationError
 * component, because it might do cool things like log the error or something.
 */
interface PageErrorBoundaryProps {
  resetKeys?: Array<unknown>;
}
export const PageErrorBoundary: React.FC<PropsWithChildren<
  PageErrorBoundaryProps
>> = props => (
  <ErrorBoundary
    FallbackComponent={ApplicationError}
    resetKeys={props.resetKeys}
  >
    {props.children}
  </ErrorBoundary>
);
