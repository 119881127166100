import {
  ChartDataGranularity,
  DataStatus,
  GetBaselineUsageResponse,
  ICombinedIntervalData,
  ICombinedMeasurement,
  IMeasurement,
  ITimeRange,
  Unit,
} from "src/types/charting";
import { setTrainingPeriodAdjustedForTZ } from "src/components/app/BaselineChart/baselineHelpers";
import {
  calculatePercentDiff,
  convertAndMergeCommodity,
} from "src/helpers/charting";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";

const valueSummer = (total: number, current: IMeasurement) =>
  (total += current.value || 0);

type BaselineCardDataChangeProcessorResponse = {
  trainingPeriod: ITimeRange | { startTime: undefined; endTime: undefined };
  smae: number | undefined;
  summedUsage: number | undefined;
  summedBaseline: number | undefined;
  percentDiff: number | undefined;
  consolidatedData: ICombinedIntervalData | false;
  nonOperatingTimeRange: ITimeRange[];
  baselineUnavailable: boolean;
};

// do we have what we need to render anything?
// TODO: we've been caught several times with the White Screen of Death
//  in this area; the logic is starting to get complicated and doesn't
//  anticipate broad cases that would cause "couldn't get (some property)
//  of undefined or null" which leads to WSOD. (Mark G 2020-02-06)
const hasViableData = (data: GetBaselineUsageResponse | undefined): boolean => {
  if (!data || !data.getBuildingById) {
    return false;
  }
  const _d = data.getBuildingById;
  // Add check for usage values is not empty. Otherwise, White Screen of Death. (Mark G 2020-02-05)
  return !!(
    _d.commodityUsageData &&
    _d.commodityUsageData.values.length > 0 &&
    _d.baselineUsage
  );
};

export type BLType = {
  name: string;
  unit: Unit;
  status: DataStatus;
  message: string;
  values: {
    timestamp: string | Date;
    value: number | null;
  }[];
};

/**
 * Calculates the sum of baseline interval data, but excludes intervals where
 * the corresponding usage value is null.
 */
const sumBaselineExcludingNullUsageIntervals = (
  baselineData: IMeasurement[],
  usageData: IMeasurement[],
) => {
  // Allows us to more easily find timestamps where usageData is null
  const usageMap: { [key: string]: number | null } = {};

  for (const usage of usageData) {
    usageMap[usage.timestamp.toLocaleString()] = usage.value;
  }

  // Filter baselines based on usageMap
  return baselineData
    .filter(b => usageMap[b.timestamp.toLocaleString()] !== null)
    .reduce(valueSummer, 0);
};

/**
 * For each item in the consolidatedData (cd) values list,
 * add a baseline item. If there is a corresponding index in the
 * baseline (bl) values list, use that value, otherwise use null.
 *
 * @param cd
 * @param bl
 */
export function mergeBaselineValuesIntoConsolidatedData(
  cd: ICombinedIntervalData,
  bl: BLType,
): ICombinedMeasurement[] {
  const _maxIdx = bl.values.length - 1;
  // TODO: There's gotta be a better (more "immutable") way to do this
  // Need to set baseline values - if a baseline value isn't available that
  // corresponds to the actual usage value, make it null instead.
  const _v = cd.values.map((_value, _idx) => {
    _value["baseline"] = _idx <= _maxIdx ? bl.values[_idx].value : null;
    return _value;
  });
  return _v;
}

// TODO: There's still significant refactoring to do here to clean up the logic, but
//   at least it's a pure function now
export function baselineCardDataChangeProcessor(
  data: GetBaselineUsageResponse | undefined,
  tz: string,
  granularity: ChartDataGranularity,
  myChartTimeRange: ITimeRange,
  labelsForCommodities: {
    temperature: string;
    humidity: string;
    noop: string;
  },
): BaselineCardDataChangeProcessorResponse {
  let response: BaselineCardDataChangeProcessorResponse;
  const initialTrainingPeriod = {
    startTime: undefined,
    endTime: undefined,
  };

  // hasViableData means commodityUsageData exists and has non-zero length and
  //   baselineUsage exists (but may have zero length)
  if (hasViableData(data)) {
    // Why the non-null assertions?
    // They're needed because the TS processor doesn't
    // comprehend the hasViableData fcn but if that returns true (which it
    // must've to get here) then we know data exists, getBuildingById exists,
    // and commodityUsageData and baselineUsage exist and have length > 0.
    const _usage = data!.getBuildingById.commodityUsageData!;
    const _baseline = data!.getBuildingById.baselineUsage!;

    // Non-operating time ranges just govern the parts of the chart that get shaded
    const _nonOperatingTimeRange =
      data!.getBuildingById.buildingNonOperatingTimeRanges || [];

    // trainingPeriod is for information only, so if it's undefined it's no biggie
    const _trainingPeriod = setTrainingPeriodAdjustedForTZ(
      data?.getBuildingById.baselineUsage?.trainingPeriod ||
        initialTrainingPeriod,
      tz,
    );

    // smae is for information only, so if it's undefined it's not a big deal
    const _smae = data!.getBuildingById.baselineUsage?.metrics?.overallSMAE;

    // TODO: Can this be combined into the hasViableData condition?
    //   This indicates that baseline status is OK, but not necessarily
    //   that the data length is non-zero. If the data length is zero, however,
    //   the merging function will just null out those entries in the combined
    //   data.
    if (_baseline.status && _baseline.status === DataStatus.OK) {
      // TODO: So basically we have the following things from the GraphQL query:
      //   commodityUsageData
      //   weatherData
      //   baselineUsage
      //   nonOperatingTimeRanges
      //   trainingPeriod
      //   smae
      //   and the idea is to create chartData, which is ICombinedIntervalData,
      //   by populating the different pieces of the IntervalData from commodity
      //   usage data, weather data, and baseline usage data. The rest
      //   (nonOperatingTimeRanges, trainingPeriod, and smae) are just used to
      //   update the BaselineCard state.
      //   -----
      //   The assumption is the GraphQL query returns commensurable timestamped
      //   lists for each of the data sets (weather, commodity, and baseline).

      // Mash together the commodity usage data and weather data into a combined measurement series
      const consolidatedData: ICombinedIntervalData = convertAndMergeCommodity({
        commodityData: _usage,
        weatherData: data?.getBuildingById.weatherData!,
        commodityGranularity: granularity,
        range: myChartTimeRange,
        // TODO: Create argument for extractability
        labels: labelsForCommodities,
      });

      // Now go through a bunch of gyrations to do the same with the baseline usage data
      // Add baseline units to the units map
      consolidatedData.units = {
        ...consolidatedData.units,
        baseline: getSymbolForUnit(_baseline.unit),
      };
      // there used to be comments about how these needed to get translated. These strings are now used as keys for that.
      // so technically, this is fine.
      const newLabels = {
        ...consolidatedData.labels,
        baseline: "Weather Adjusted Baseline Usage",
        value: "Actual Usage",
      };
      consolidatedData.labels = newLabels;

      // Merge baseline values into the combined measurement series
      consolidatedData.values = mergeBaselineValuesIntoConsolidatedData(
        consolidatedData,
        _baseline,
      );

      // Calculate the aggregate info for the KPIs
      const _summedBaseline = _baseline.values.reduce(valueSummer, 0);
      const _summedUsage = _usage.values.reduce(valueSummer, 0);

      // This value is not displayed as a kpi. It's only used to calculate the
      // percentDiff. If actual usage doesn't have any null data, this sum
      // should be the same as _summedBaseline
      const _adjustedSummedBaseline = sumBaselineExcludingNullUsageIntervals(
        _baseline.values,
        _usage.values,
      );

      // Calculated using _adjustedSummedBaseline, NOT _summedBaseline
      const _percentDiff = calculatePercentDiff(
        _adjustedSummedBaseline,
        _summedUsage,
      );

      response = {
        trainingPeriod: _trainingPeriod,
        smae: _smae,
        summedUsage: _summedUsage,
        summedBaseline: _summedBaseline,
        percentDiff: _percentDiff,
        consolidatedData,
        nonOperatingTimeRange: _nonOperatingTimeRange,
        baselineUnavailable: false,
      };
    } else {
      response = {
        trainingPeriod: initialTrainingPeriod,
        smae: undefined,
        summedUsage: undefined,
        summedBaseline: undefined,
        percentDiff: undefined,
        consolidatedData: false,
        nonOperatingTimeRange: [],
        baselineUnavailable: true,
      };
    }
  } else {
    response = {
      trainingPeriod: initialTrainingPeriod,
      smae: undefined,
      summedUsage: undefined,
      summedBaseline: undefined,
      percentDiff: undefined,
      consolidatedData: false,
      nonOperatingTimeRange: [],
      baselineUnavailable: true,
    };
  }
  return response;
}
