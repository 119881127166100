import React, { ReactNode, useEffect, useState } from "react";
import { Link, useLocation, Outlet } from "react-router-dom";
import Auth from "src/auth/Auth";
import AppHeader from "src/components/app/AppHeader";
import DefaultContentWrapper from "src/components/app/DefaultContentWrapper";
import AOLogotype from "src/components/common/AOLogotype";
import NavigationBar, {
  BillManagementItem,
  NavigationLink,
} from "src/components/app/NavigationBar";
import UserMenu from "../UserMenu";
import { FormattedMessage } from "react-intl";
import useSessionTimeout from "src/hooks/useSessionTimeout";
import SessionTimeoutModal from "./SessionTimeoutModal";
import { tracking } from "src/tracking";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import styled from "styled-components/macro";
import { controlText1_style } from "src/components/common/ControlText";
import { MxReactIcon, Bell } from "src/componentLibrary/react/mx-icon-react";

import { useUserContext } from "src/auth/UserContext";
import { useApolloClient } from "@apollo/client";
import {
  AuthenticatedPageEnablementsDocument,
  FetchUserDataDocument,
} from "src/queries/typed";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import SourcesItem from "../NavigationBar/SourcesItem";
import { AppColors } from "src/components/common/Styling";
import {
  BillManagementEnablementConfig,
  getBillManagement,
} from "../NavigationBar/BillManagementItem";
import { UserData } from "../../../types/user";
import { UserRole } from "src/types/roles";
import { useAuthorization } from "src/hooks/useAuthorization";
import { LatestSelectionProvider } from "src/components/app/AdminTools/LastSelectionContext";
import FloatingAdminTools from "src/components/app/AdminTools";
import ModifiedToastContainer from "src/components/common/Toast";
import { ActiveBorderContainer } from "src/components/app/NavigationBar/NavigationItem";
import { Locale } from "src/types/graphql";
import { PageErrorBoundary } from "src/components/common/ApplicationError/PageErrorBoundary";
import { useErrorBoundary } from "react-error-boundary";
import { useLinkTracking } from "src/hooks/useLinkTracking";

// events we want to send to mixpanel
enum AuthPageEvents {
  HATCH_USER_SESSION = "NewHatchSession",
  UBM_ONLY_SESSION = "NewUBMSession",
}

// TODO: This exists because sometimes components need the current orgId for
//  for various purposes, but since only certain routes actually specify the
//  orgId as a URL parameter, it is not always available via useParams().
//  Is exposing the setter for a piece of the AuthenticatedPage component's
//  state to any nested component via context a good idea though? Maybe there's
//  a better way these days? 🤷
//  See also: `useOrganizationFromRouteParamsAndUpdateContext` hook
export const CurrentOrganizationContext = React.createContext<{
  setCurrentOrganization: (orgId: string) => void;
  currentOrganizationId: string | undefined;
}>({
  setCurrentOrganization: () => {},
  currentOrganizationId: undefined,
});

export const AlertsMenuContainer = styled.div`
  height: 100%;
  border-left: 2px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
  font-size: ${controlText1_style.fontSize};
  color: ${controlText1_style.activeColor};
  font-weight: ${controlText1_style.activeWeight};
  max-width: 380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;

  /* this is needed to fix an IE11 bug. Sorry about that. */
  @media all and (-ms-high-contrast: none) {
    flex-basis: 380px;
  }
  @media (max-width: 800px) {
    flex-basis: auto;
    padding: 0px 16px;
  }
`;

type AuthenticatedPageProps = {
  children: ReactNode;
};

const AuthenticatedPage = (props: AuthenticatedPageProps) => {
  const location = useLocation();

  useLinkTracking();

  const authorization = useAuthorization();
  const [hasRedirectError, setHasRedirectError] = useState(false);

  const { userData, setUserData } = useUserContext();
  const apolloClient = useApolloClient();

  const [currentOrganizationId, setCurrentOrganization] = useState<
    string | undefined
  >(undefined);

  const [loading, setLoading] = useState(true);
  const { timeRemaining, isAlerting, isDone } = useSessionTimeout();

  const USER_SESSION_STORAGE_KEY = "user-data";

  // Used to allow error boundary to handle potential async errors
  const handleError = useErrorBoundary();

  useEffect(() => {
    /**
     * NOTE: this needs to live INSIDE this hook because of the async, otherwise things get... bad.
     *
     * Loads user metadata for the current logged in user and returns it. If the user is not found,
     * an error is logged and undefined is returned.
     * @returns the user metadata for the current logged in user or undefined if they were not found.
     */
    const loadUser = async (): Promise<UserData | undefined> => {
      const { data, error } = await apolloClient.query({
        query: FetchUserDataDocument,
      });

      if (data.currentUser?.id) {
        const currentUserData: UserData = {
          ...data.currentUser,
          buildings:
            data.currentUser.buildings?.map(building => building!.id) || [],
          roles: (data.currentUser.roles as unknown) as UserRole[],
        };

        tracking.setDatadogContext(currentUserData);

        const userLocaleAllowsTracking = (userLocale: Locale): boolean => {
          // We can't legally track any PII in mixpanel for users in the EU or GB.
          // When we add future locales that *do* allow tracking, they must be added to the localesThatAllowTracking array.
          // You will also have to add them to the same array in user-sync-service.
          const localesThatAllowTracking = [Locale.EN_CA, Locale.EN_US];
          return localesThatAllowTracking.includes(userLocale);
        };

        if (userLocaleAllowsTracking(data.currentUser.preferences?.locale)) {
          tracking.identifyUser(Auth.getUserId());
        } else {
          tracking.optOutTracking();
        }

        return currentUserData;
      } else {
        console.error("fetchUserData failed.", error);

        return undefined;
      }
    };

    /**
     * NOTE: this needs to live INSIDE this hook because of the async, otherwise things get... bad.
     *
     * This checks if a user has been stored in the context. If not, it checks if a user is stored in
     * the session storage (ie. a user is logged in and refreshed the page). If they're not in session
     * storage, then load the user data from the API and store it in the session and user context.
     */
    const fetchUserData = async () => {
      if (userData.id) {
        return;
      }

      const sessionStorageUser = sessionStorage.getItem(
        USER_SESSION_STORAGE_KEY,
      );

      const currentUser: UserData | undefined = sessionStorageUser
        ? JSON.parse(sessionStorageUser)
        : await loadUser();

      if (currentUser) {
        setUserData(currentUser);
        setCurrentOrganization(currentUser.organizationId);

        sessionStorage.setItem(
          USER_SESSION_STORAGE_KEY,
          JSON.stringify(currentUser),
        );
      } else {
        // We failed to load the user. Nothing is going to work in the app
        // without knowing their org, roles, etc. So I guess show an error?
        setHasRedirectError(true);
      }

      setLoading(false);
    };

    if (Auth.isAuthenticated()) {
      fetchUserData().catch(error => {
        console.error(error);
        // Renders ApplicationError fallback component. That component should
        // also log the error in datadog
        handleError.showBoundary(error);
      });
    } else {
      Auth.logout();
    }
  }, []);

  /**
   * This will run after the user has been updated in the state. If a user is financial
   * only, we'll redirect them from here.
   */
  useEffect(() => {
    if (userData.id) {
      /**
       * Loads a billing URL for an organization if it exists and redirects the user.
       * @param organizationId the user's organization to load the billing URL for
       */
      const handleFinancialOnlyUser = async (organizationId: string) => {
        const { data, errors } = await apolloClient.query({
          query: AuthenticatedPageEnablementsDocument,
          variables: { organizationId },
        });

        const billManagement = getBillManagement(
          data?.getOrganizationById?.enablements || [],
        );

        if (errors) {
          console.error("unable to load org", errors);
          setHasRedirectError(true);
        } else {
          if (billManagement?.enabled) {
            window.open(
              (billManagement?.configuration as BillManagementEnablementConfig)
                .url,
              "_self",
            );
          } else {
            // User is financial only but no url is set for their org. Umm... help?
            console.error("Financial only user found, but no URL set for org");
            setHasRedirectError(true);
          }
        }
      };

      if (authorization.hasRole(UserRole.FINANCIAL_ONLY)) {
        tracking.fireEvent(AuthPageEvents.UBM_ONLY_SESSION, {});
        handleFinancialOnlyUser(authorization.getOrganization());
      } else {
        tracking.fireEvent(AuthPageEvents.HATCH_USER_SESSION, {});
      }
    }
  }, [userData]);

  // listens for session timeout and boots us
  useEffect(() => {
    if (isDone) {
      Auth.logout(true);
    }
  }, [isDone]);

  const isPerformanceActive = (): boolean =>
    location?.pathname.indexOf("/performance") === 0 ?? false;

  const isExplorerActive = (): boolean =>
    location?.pathname.indexOf("/explorer") === 0 ?? false;

  const isMeasuresActive = (): boolean =>
    location?.pathname.indexOf("/measures") === 0 ?? false;

  const isSourceActive = (): boolean =>
    location?.pathname.indexOf("/sources") === 0 ?? false;

  const isAlertsActive = (): boolean =>
    location?.pathname.indexOf("/alerts") === 0 ?? false;

  const isSettingsActive = (): boolean =>
    (location?.pathname.indexOf("/demand-management") === 0 ||
      location?.pathname.indexOf("/settings") === 0 ||
      location?.pathname.indexOf("/help") === 0 ||
      location?.pathname.indexOf("/new-user") === 0) ??
    false;

  if (loading) {
    return <CenteredLoadingIndicator />;
  }

  if (authorization.hasRole(UserRole.FINANCIAL_ONLY)) {
    return <CenteredLoadingIndicator />;
  }

  if (hasRedirectError) {
    return <FormattedMessage id="common.error.dataUnavailable" />;
  }

  return (
    <CurrentOrganizationContext.Provider
      value={{ currentOrganizationId, setCurrentOrganization }}
    >
      <LatestSelectionProvider>
        <DefaultContentWrapper>
          {isAlerting && (
            <SessionTimeoutModal
              time={timeRemaining}
              logoutHandler={Auth.logout}
            />
          )}
          <AppHeader>
            <AOLogotype />
            <NavigationBar>
              <NavigationLink
                active={isPerformanceActive().toString()}
                to={
                  currentOrganizationId
                    ? `/performance/${currentOrganizationId}`
                    : "/performance"
                }
              >
                <FormattedMessage id="navigation.performance" />
              </NavigationLink>
              <NavigationLink
                active={isExplorerActive().toString()}
                onClick={() =>
                  tracking.fireEvent(
                    ExplorerPageEvents.CLICK_EXPLORER_HEADER_LINK,
                    {},
                  )
                }
                to={
                  currentOrganizationId
                    ? `/explorer/${currentOrganizationId}`
                    : "/explorer"
                }
              >
                <FormattedMessage id="navigation.explorer" />
              </NavigationLink>
              <NavigationLink
                active={isMeasuresActive().toString()}
                to={
                  currentOrganizationId
                    ? `/measures/${currentOrganizationId}?status=WAITING_FOR_REVIEW&status=IN_PROGRESS&status=UNDER_REVIEW`
                    : "/measures"
                }
              >
                <FormattedMessage id="navigation.measures" />
              </NavigationLink>
              {currentOrganizationId && (
                <SourcesItem
                  active={isSourceActive().toString()}
                  organizationId={currentOrganizationId}
                />
              )}
              {currentOrganizationId && (
                <BillManagementItem organizationId={currentOrganizationId} />
              )}
            </NavigationBar>
            <div style={{ height: "100%", display: "flex" }}>
              <ActiveBorderContainer active={isAlertsActive().toString()}>
                <Link
                  to={
                    currentOrganizationId
                      ? `/alerts/user/${currentOrganizationId}`
                      : `/alerts/user`
                  }
                >
                  <AlertsMenuContainer>
                    <MxReactIcon
                      Icon={Bell}
                      color={AppColors.neutral.navy}
                      size="s"
                    />
                  </AlertsMenuContainer>
                </Link>
              </ActiveBorderContainer>
              <ActiveBorderContainer active={isSettingsActive().toString()}>
                <UserMenu organizationId={currentOrganizationId} />
              </ActiveBorderContainer>
            </div>
          </AppHeader>
          {props.children}
          <FloatingAdminTools />
          <ModifiedToastContainer />
        </DefaultContentWrapper>
      </LatestSelectionProvider>
    </CurrentOrganizationContext.Provider>
  );
};

const AuthenticatedPageWrapper = () => {
  return (
    <PageErrorBoundary>
      <AuthenticatedPage>
        <Outlet />
      </AuthenticatedPage>
    </PageErrorBoundary>
  );
};

export default AuthenticatedPageWrapper;
