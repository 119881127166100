import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components/macro";
import { Body, InlineH4 } from "src/components/common/Typography";
import {
  MxReactIcon,
  X,
  MagnifyingGlass,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "../Styling";
import debounce from "lodash.debounce";

const InsetContainer = styled.div`
  padding-left: 27px;
  padding-right: 27px;
`;
// TODO: this is all duplicated from the other asset selector.
const SidebarWrapper = styled.div`
  padding: 20px 0 0 0;
  background: ${AppColors.neutral["light-navy-1"]};
  color: white;
  max-width: 350px;
  width: 350px;
  position: relative;
  height: 100%;

  /* This lets us get fill width on small screens */
  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
`;
const SidebarHeaderContainer = styled(InsetContainer)`
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 1px;
`;

const SidebarTitle = styled(Body)`
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 40px;
`;

const SidebarInputWrapper = styled(InsetContainer)`
  display: flex;
  align-items: center;
  color: ${AppColors.neutral.white};
  /* TODO: this doesn't work in IE/Edge, so a search component with focus state is in our future */
  &:focus-within {
    color: ${AppColors.neutral.white};
  }
`;
const SidebarSearchInput = styled.input.attrs({ type: "search" })`
  /* Safari sucks at rendering search inputs...
  * https://css-tricks.com/webkit-html5-search-inputs/
  * So render it as a textfield:
  */
  -webkit-appearance: textfield;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 16px;
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  flex: 1;
  &::placeholder {
    color: ${AppColors.neutral.white};
  }
  &:focus {
    outline: none;
  }
`;

const ClearableInputWrapper = styled.div`
  border: none;
  height: 24px;
  display: flex;
  align-items: center;
  width: 100%;
`;
const ClearableInputField = styled.input`
  background-color: transparent;
  color: ${AppColors.neutral.white};
  border: none;
  font-size: 16px;
  padding: 0px;
  margin-left: 8px;
  flex: 1;
  &::placeholder {
    color: ${AppColors.neutral.white};
  }
  &:focus {
    outline: none;
  }
`;
const ClearableInput: React.FC<Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange"
> & { onChange: (value: string) => void; debounceMs?: number }> = props => {
  const {
    onChange = () => {},
    value: incomingValue,
    debounceMs = 300,
    ...otherProps
  } = props;
  const [value, setValue] = useState(incomingValue);

  const onChangeDebounced = useCallback(
    debounce((val: string) => onChange(val), debounceMs),
    [],
  );

  const field = useRef() as React.MutableRefObject<HTMLInputElement>;
  // clears and dispatches a change event on the input so it behaves like a search
  // then re-focuses like a search as well.
  const clearInput = () => {
    if (field.current?.value) {
      setValue("");
      onChange("");
      field.current.focus();
    }
  };
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setValue(evt.currentTarget.value);
    onChangeDebounced(evt.currentTarget.value);
  };
  const hasValue = value !== "";
  return (
    <ClearableInputWrapper>
      <MxReactIcon Icon={MagnifyingGlass} color={AppColors.neutral.white} />
      <ClearableInputField
        ref={field}
        value={value}
        {...otherProps}
        onChange={handleChange}
      />
      {hasValue && (
        <MxReactIcon
          Icon={X}
          size="xs"
          onClick={clearInput}
          color={AppColors.neutral.white}
        />
      )}
    </ClearableInputWrapper>
  );
};

const NoSearchResultsLabel = styled(InlineH4)`
  color: ${AppColors.neutral["light-navy-3"]};
  width: 100%;
  text-align: center;
  margin-top: 16px;
  display: block;
`;
const SidebarButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${AppColors.neutral.navy};
  position: relative;
`;

const SidebarIconButton = styled.button<{ active?: boolean }>`
  border: none;
  background: transparent;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:focus {
    outline: none;
  }
  color: ${AppColors.neutral.white};
  background-color: ${props =>
    props.active ? AppColors.neutral["light-navy-1"] : AppColors.neutral.navy};
`;
export {
  SidebarHeaderContainer,
  SidebarTitle,
  SidebarWrapper,
  SidebarInputWrapper,
  SidebarSearchInput,
  ClearableInput,
  NoSearchResultsLabel,
  SidebarButtonBar,
  SidebarIconButton,
};
