/*
import React from "react";
import { XAxis } from "recharts";
import { XAxisData } from "src/types/charting";

export const xAxes = (axes: XAxisData) => {
  const axesCollection: React.ReactNode[] = [];
  axes.forEach((val, key) => {
    axesCollection.push(<XAxis key={key} xAxisId={key} {...val} />);
  });
  return axesCollection;
};
*/

import React from "react";
import { XAxis } from "recharts";
import { XAxisData } from "src/types/charting";
// TODO: move this to types charting probably???
import { ChartDomains } from "../ExplorerCard";
import { xTicksForRangeAndSize } from "src/helpers/charting";

export const xAxes = (
  axes: XAxisData,
  domains?: ChartDomains,
  primaryKey?: string,
) => {
  const axesCollection: React.ReactNode[] = [];
  // NEW -- sometimes we now get the axes upside down. This fixes that.
  // why they sometimes are now upaside down is a mystery!
  const keys = Array.from(axes.keys());
  keys.sort().reverse();
  keys.forEach(key => {
    let val = axes.get(key);
    let axisProps = val;
    if (domains && primaryKey) {
      const d = key === primaryKey ? domains.primary : domains.secondary;
      // get new ticks based on that domain I guess?
      const ticks = xTicksForRangeAndSize(1000, [], undefined, {
        startTime: new Date(d[0]).toISOString(),
        endTime: new Date(d[1]).toISOString(),
      });
      axisProps = { ...val, domain: d, ticks: ticks };
    }
    axesCollection.push(
      <XAxis key={key} xAxisId={key} {...axisProps} allowDataOverflow={true} />,
    );
  });
  // OLD
  /*
  axes.forEach((val, key) => {
    let axisProps = val;
    if (domains && primaryKey) {
      const d = key === primaryKey ? domains.primary : domains.secondary;
      // get new ticks based on that domain I guess?
      const ticks = xTicksForRangeAndSize(
        1000,
        [],
        undefined,
        { startTime: new Date(d[0]).toISOString(), endTime: new Date(d[1]).toISOString()}
      )
      axisProps = { ...val, domain: d, ticks: ticks };
    }
    axesCollection.push(
      <XAxis key={key} xAxisId={key} {...axisProps} allowDataOverflow={true} />,
    );
  });
  */
  return axesCollection;
};
