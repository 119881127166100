import { CSSProperties } from "react";
import { FormattedMessage } from "react-intl";
import { AppColors } from "src/components/common/Styling";

type CreateProgressProps = {
  step: number;
};

const CreateProgress = (props: CreateProgressProps) => {
  const common: CSSProperties = {
    display: "inline-block",
    width: "32%",
    marginRight: "2px",
    paddingBottom: "8px",
    textTransform: "uppercase",
    textAlign: "center",
    color: AppColors.neutral["light-navy-3"],
  };
  const activeStyle: CSSProperties = {
    ...common,
    borderBottom: `8px solid ${AppColors.primary["light-msr-green"]}`,
    color: AppColors.neutral.navy,
  };
  const notYetStyle: CSSProperties = {
    ...common,
    borderBottom: `8px solid ${AppColors.semantic.lightBlue["light-seafoam-3"]}`,
  };
  const pastStyle: CSSProperties = {
    ...common,
    borderBottom: `8px solid ${AppColors.semantic.green["light-msr-green-3"]}`,
  };
  return (
    <div
      style={{
        width: "100%",
        fontSize: "12px",
      }}
    >
      <span style={props.step === 1 ? activeStyle : pastStyle}>
        <FormattedMessage id="charts.explorer.overlays.create.step.details" />
      </span>
      <span
        style={
          props.step === 1
            ? notYetStyle
            : props.step === 2
            ? activeStyle
            : pastStyle
        }
      >
        <FormattedMessage id="charts.explorer.overlays.create.step.note" />
      </span>
      <span style={props.step === 3 ? activeStyle : notYetStyle}>
        <FormattedMessage id="charts.explorer.overlays.create.step.dateTime" />
      </span>
    </div>
  );
};
export default CreateProgress;
