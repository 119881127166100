import styled from "styled-components/macro";

export interface IAppToolbarItemProps {
  children: React.ReactNode;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const AppToolbarItem: React.FC<IAppToolbarItemProps> = styled.div`
  width: 70px;
  height: 70px;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  /* this is when we have been moved up to the top */
  @media (max-width: 600px) {
    width: 48px;
    height: 48px;
  }
`;

export default AppToolbarItem;
