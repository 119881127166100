import {
  MxReactIcon,
  Calendar,
} from "src/componentLibrary/react/mx-icon-react";
import React, { PropsWithChildren } from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import { PickerCalendar } from ".";
import { AppColors } from "../Styling";

export const DatePickerOverlay: React.FC<PropsWithChildren<unknown>> = ({
  children,
  ...props
}) => (
  <OverlayWrapper>
    <Overlay>
      <PickerCalendar {...props}>{children}</PickerCalendar>
    </Overlay>
  </OverlayWrapper>
);

const OverlayWrapper = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  z-index: 2;
`;

export const CalendarIcon = (props: { zindex?: number }) => {
  return (
    // TODO: Styles defined in "Icon" component above were not being applied
    // as expected, so using the style prop here. Hopefully temporarily.
    <MxReactIcon
      Icon={Calendar}
      color={AppColors.neutral.navy}
      style={{ position: "absolute", top: 8, left: 8 }}
    />
  );
};

// NOTE: This is duplicated in CreateMeasureForm, so you probably want to change that one as well.
export const NewDatePickerInputStyles = createGlobalStyle`
.DayPickerInput > input {
  padding: 8px 32px 8px 32px;
  color: ${AppColors.neutral["light-navy-1"]};
  font-size: 15px;
  border-radius: 4px;
  border: 1px solid ${AppColors.neutral["light-navy-1"]};
  min-width: 250px;
  width: 20%;
  max-width: 600px;
  :focus {
    outline: none;
  }
  :disabled {
    background: ${AppColors.neutral["light-navy-9"]};
    cursor: not-allowed;
  }
  ::placeholder {
    color: ${AppColors.neutral["light-navy-1"]};
  }
}

.DayPickerInput > input.invalid {
  border-color: ${AppColors.semantic.red.red};
}
`;

export default DatePickerOverlay;
