import styled from "styled-components/macro";

/* =============================================================== *\
   H1 : 300, 30px, L:34, P:29 - Verb, Regular
   H2 : 300, 24px, L:28, P:23 - Verb, Regular
   H3 : 300, 20px, L:24, P:19 - Verb, Regular
   H4 : 400, 14px, L:18, P:13 - Verb, Medium
   H5 : 600, 11px, L:15, P:12 - Verb, Bold
\* =============================================================== */
// Measurabl styleguide type
export const H1 = styled.h1`
  color: inherit;
  font-size: 30px;
  font-weight: 300;
`;
export const H2 = styled.h2`
  color: inherit;
  font-size: 24px;
  font-weight: 300;
`;
export const H3 = styled.h3`
  color: inherit;
  font-size: 20px;
  font-weight: 300;
`;
export const H4 = styled.h4`
  color: inherit;
  font-size: 14px;
  font-weight: 400;
`;
export const H5 = styled.h5`
  color: inherit;
  font-size: 11px;
  font-weight: 600;
`;

export default H1;
