import React from "react";
import styled from "styled-components/macro";
import { Body } from "src/components/common/Typography";
import { FormattedMessage } from "react-intl";
import {
  friendlyNumberFormat,
  getSymbolForPointType,
} from "src/helpers/charting";
import { AppColors } from "src/components/common/Styling";
import { Locale } from "@hatchdata/intl-formatter";
import { BuildingThresholdWithColor } from "src/components/app/ExplorerPage/OverlayStuff/OverlaysTab";

const OverlaysInfoTable = styled.table`
  table-layout: fixed;
  width: 100%;
  background-color: ${AppColors.neutral.white};
  border-left: 1px solid ${AppColors.neutral["light-navy-5"]};
  border-right: 1px solid ${AppColors.neutral["light-navy-5"]};
/*
  tbody tr:nth-child(even) {
    background-color: ${AppColors.neutral["light-gray-9"]};
  }
  */
`;
type OverlaysInfoHeaderProps = {
  multiSeries: boolean;
};
const OverlaysInfoHeader = styled.th<OverlaysInfoHeaderProps>`
  text-align: left;
  font-size: 16px;
  font-weight: 300;
  color: ${AppColors.neutral.navy};
  padding: 12px;
  border-top: 1px solid ${AppColors.neutral["light-navy-3"]};
  border-bottom: 1px solid ${AppColors.neutral["light-navy-3"]};
  background-color: ${AppColors.neutral["light-navy-11"]};
`;

const OverlaysInfoCell = styled.td`
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid ${AppColors.neutral["light-navy-3"]};
`;

const OverlaysInfoCellRightAligned = styled.td`
  text-align: right;
  padding: 12px;
  border-bottom: 1px solid ${AppColors.neutral["light-navy-3"]};
`;

const OverlaysInfoCellText = styled(Body)`
  min-width: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${AppColors.neutral["navy"]};
`;

const OverlaysInfoGroup = styled.div`
  padding-left: 16px;
  color: ${AppColors.neutral["light-navy-1"]};
`;

type ColorBlockometerProps = {
  color: string;
  isOutlined?: boolean;
};
const ColorBlockometer = styled.span<ColorBlockometerProps>`
  display: inline-block;
  height: 16px;
  width: 16px;
  background-color: ${props =>
    props.isOutlined ? "transparent" : props.color};
  margin-right: 8px;
  border-radius: 50%;
  border: ${props => (props.isOutlined ? `2px solid ${props.color}` : "none")};
  position: relative;
  top: 2px;
`;

// takes a thing that may not exist and returns a nicely formatted thing if it
// exists. It is its own function because a simple ternary treats zero as not there
const displayVal = (
  val: number | null | undefined,
  unit: string = "",
): string => {
  if (val === null || val === undefined) {
    return "--";
  }
  return `${friendlyNumberFormat(val)} ${unit}`;
};

type OverlaysInfoProps = {
  thresholds: BuildingThresholdWithColor[];
  userLocale: Locale;
  onEditThreshold: (assetId: string) => void;
};

const OverlaysInfo: React.FC<OverlaysInfoProps> = ({
  thresholds,
  userLocale,
  onEditThreshold,
}) => {
  function OverlaysInfoRow(props: {
    userLocale: Locale;
    threshold: BuildingThresholdWithColor;
  }) {
    return (
      <tr>
        <OverlaysInfoCell>
          <OverlaysInfoCellText title={"--"}>
            <ColorBlockometer
              color={props.threshold.color || ""}
              isOutlined={false}
            />
            {props.threshold.assetName || "--"}
          </OverlaysInfoCellText>
        </OverlaysInfoCell>
        <OverlaysInfoCell>
          <Body>
            <FormattedMessage id={`commodity.name.${props.threshold.type}`} />
          </Body>
        </OverlaysInfoCell>
        <OverlaysInfoCell>
          <Body>
            {displayVal(
              props.threshold.value,
              getSymbolForPointType(props.threshold.type, props.userLocale),
            )}
          </Body>
        </OverlaysInfoCell>
        <OverlaysInfoCellRightAligned>
          <Body
            onClick={() => {
              onEditThreshold(props.threshold.assetId);
            }}
            style={{
              color: `${AppColors.primary["msr-green"]}`,
              cursor: "pointer",
            }}
          >
            Edit
          </Body>
        </OverlaysInfoCellRightAligned>
      </tr>
    );
  }

  return (
    <section>
      <OverlaysInfoGroup>
        <Body>
          <FormattedMessage
            id={
              "charts.explorer.tabs.overlays.overlaysInfo.group.label.thresholds"
            }
          />
        </Body>
      </OverlaysInfoGroup>
      <OverlaysInfoTable cellSpacing={0}>
        <thead>
          <tr>
            <OverlaysInfoHeader multiSeries={false}>
              <FormattedMessage id="charts.explorer.tabs.overlays.overlaysInfo.label.selection" />
            </OverlaysInfoHeader>
            <OverlaysInfoHeader multiSeries={false}>
              <FormattedMessage id="charts.explorer.tabs.overlays.overlaysInfo.label.pointType" />
            </OverlaysInfoHeader>
            <OverlaysInfoHeader multiSeries={false}>
              <FormattedMessage id="charts.explorer.tabs.overlays.overlaysInfo.label.thresholdValue" />
            </OverlaysInfoHeader>
            <OverlaysInfoHeader multiSeries={false}>
              {/* No content here, just space for the "Edit" control */}
            </OverlaysInfoHeader>
          </tr>
        </thead>
        <tbody>
          {thresholds.map(threshold => {
            return (
              <OverlaysInfoRow
                key={threshold.assetId}
                threshold={threshold}
                userLocale={userLocale}
              />
            );
          })}
        </tbody>
      </OverlaysInfoTable>
    </section>
  );
};

export default OverlaysInfo;
