import { FormattedMessage } from "react-intl";
import { AssetBuilding } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import CustomSelect from "src/components/common/Dropdown/CustomSelect";
import { AppColors } from "src/components/common/Styling";

export const BuildingSelector = ({
  items,
  defaultValue,
  onChange,
  width = "100%",
}: {
  items: AssetBuilding[];
  defaultValue: AssetBuilding | undefined;
  onChange: (value: AssetBuilding) => void;
  width?: string;
}) => {
  return (
    <CustomSelect
      values={items}
      value={defaultValue ?? items[0]}
      renderSelectedItem={item => {
        if (item) {
          return item.name;
        } else {
          return (
            <FormattedMessage id="charts.explorer.overlays.create.selectBuilding" />
          );
        }
      }}
      renderItem={item => item?.name}
      onChange={onChange}
      displayPropsForButton={() => {
        return {
          width: width,
        };
      }}
    />
  );
};
