import { ICombinedIntervalData } from "src/types/charting";
import {
  CSVData,
  generateFile,
  csvDataToString,
  formatSimpleTimestamp,
  formatISOTimestamp,
} from "src/helpers/csvExporter";
import { formatNumber } from "@hatchdata/intl-formatter";

/**
 * Exports the data used for the chart to a CSV file and downloads it.
 * @param chartData Data used by the chart
 * @param timezone timezone to render the data in
 * @param commodity type of commodity found on the chart
 */
export const exportToCsv = (
  chartData: ICombinedIntervalData | false,
  timezone: string,
  commodity: string,
) => {
  if (!chartData || !chartData.values.length) {
    return;
  }

  const csvContent = buildCSVContent(chartData, timezone, commodity);
  const filename = `Energy_Baselines_${new Date().getTime()}.csv`;

  generateFile(csvContent, filename);
};

export const buildCSVContent = (
  chartData: ICombinedIntervalData,
  timezone: string,
  commodity: string,
): string => {
  const rows: CSVData[] = objectsArraysFromChartData(
    chartData,
    timezone,
    commodity,
  );
  return csvDataToString(rows, timezone);
};

const objectsArraysFromChartData = (
  chartData: ICombinedIntervalData,
  timezone: string,
  commodity: string,
): CSVData[] => {
  let objects = [];

  for (let dataNode of chartData.values) {
    let object: CSVData = {};

    object["ISO Date"] = Object.keys(dataNode).map(key =>
      formatISOTimestamp(dataNode.timestamp),
    )[0];

    object["Timezone"] = timezone;
    object["Simple Date"] = Object.keys(dataNode).map(key =>
      formatSimpleTimestamp(dataNode.timestamp, timezone),
    )[0];

    const baselineUsage = Object.keys(dataNode).map(
      key => dataNode["baseline"],
    )[0];

    object[
      `Baseline ${commodity} (${chartData.units["baseline"]})`
    ] = baselineUsage ? formatNumber(baselineUsage) : null;

    const actualUsage = Object.keys(dataNode).map(key => dataNode["value"])[0];
    object[`Actual ${commodity} (${chartData.units["value"]})`] = actualUsage
      ? formatNumber(actualUsage)
      : null;

    objects.push(object);
  }

  return objects;
};
