import { Outlet, useLocation, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { ErrorToast } from "src/components/common/Toast";
import { PageErrorBoundary } from "../../common/ApplicationError/PageErrorBoundary";
import { usePageTitle } from "src/hooks/usePageTitle";
import { useIntl } from "react-intl";

export enum SourcesPageEvents {
  LOAD_SOURCES_PAGE = "SourcesLoadSourcesPage",
}

type SourcesPageParams = {
  buildingId: string;
  organizationId: string;
};

export const SourcesPage: React.FC = () => {
  const { buildingId, organizationId } = useParams<SourcesPageParams>();

  const location = useLocation();
  const error = location?.state?.error;

  const translate = useIntl();
  const pageTitleString = translate.formatMessage({
    id: "navigation.sources",
  });
  usePageTitle(pageTitleString);
  useEffect(() => {
    if (error) {
      ErrorToast(error);
    }
  }, [error]);

  return (
    <PageErrorBoundary resetKeys={[buildingId]}>
      <Outlet />
    </PageErrorBoundary>
  );
};

// for code splitting we have to export as default
export default SourcesPage;
