import styled from "styled-components/macro";
import { AppColors } from "../Styling";

export const TimeRangePickerOuter = styled.div`
  display: inline-block;
  margin: 0 auto;
`;
export const TimeRangePickerWrapper = styled.div`
  display: flex;
  border: 1px solid ${AppColors.neutral["light-navy-9"]};

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const PickerButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 24px;
  width: 186px;
  background-color: ${AppColors.neutral.white};
  & button:first-of-type {
    margin-top: 16px;
  }
`;
export const PickerCalendarContainer = styled.div`
  background-color: ${AppColors.neutral["light-gray-9"]};
  padding: 8px;
  display: flex;
  border-left: 1px solid ${AppColors.neutral["light-navy-9"]};
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const PickerButton = styled.button<{ selected: boolean }>`
  display: block;
  border: none;
  background-color: inherit;
  text-align: left;
  font-size: 15px;
  margin-bottom: 24px;
  color: ${props =>
    props.selected
      ? AppColors.primary["msr-green"]
      : AppColors.neutral["light-navy-1"]};
  cursor: pointer;
`;
export const PickerBorderButton = styled.button`
  border: 2px solid ${AppColors.primary["msr-green"]};
  border-radius: 6px;
  padding: 8px 0px;
  color: ${AppColors.primary["msr-green"]};
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  width: 100%;
  background-color: inherit;
  cursor: pointer;
`;
export const PickerGreyBorderButton = styled(PickerBorderButton)`
         border-color: ${AppColors.neutral["light-navy-3"]};
         color: ${AppColors.neutral["light-navy-3"]};
         margin-top: 8px;
       `;

export const PickerCalendarHeader = styled.div<{ short: boolean }>`
  display: inline-block;
  width: ${props => (props.short ? "132px" : "272px")};
  &:last-child {
    margin-left: ${props => (props.short ? "8px" : "0px")};
  }
`;

type PickerDateDisplayProps = {
  focused?: boolean;
  disabled?: boolean;
};

export const PickerDateDisplay = styled.div<PickerDateDisplayProps>`
         border: ${props =>
           props.focused
             ? `2px solid ${AppColors.semantic.yellow.yellow}`
             : `2px solid ${AppColors.neutral["light-navy-9"]}`};
         font-size: 14px;
         border-radius: 6px;
         padding: 8px 16px;
         color: ${AppColors.neutral.navy};
         margin-bottom: 16px;
         background-color: ${props =>
           props.disabled
             ? AppColors.neutral["light-navy-9"]
             : AppColors.neutral.white};
         display: flex;
         align-items: center;
       `;

export const PickerDateInput = styled.input`
  border: none;

  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${AppColors.neutral["light-navy-1"]};
  }
  :disabled {
    background-color: ${AppColors.neutral["light-navy-9"]};
  }
`;
