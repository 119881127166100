import {
  Calendar,
  MxReactIcon,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "src/components/common/Styling";
import { SelectButton } from "src/components/common/Button";
import React from "react";
import { FormatOptions, dateToLocaleString } from "@hatchdata/intl-formatter";

interface TimePickerButtonProps {
  togglePicker: () => void;
  dataTestId?: string;
  startTime: Date;
  endTime: Date;
  timezone: string;
  formatOptions?: FormatOptions;
}
const TimePickerButton = ({
  togglePicker,
  dataTestId,
  startTime,
  endTime,
  timezone,
  formatOptions,
}: TimePickerButtonProps) => {
  return (
    <SelectButton onClick={togglePicker} data-testid={dataTestId}>
      <MxReactIcon
        Icon={Calendar}
        color={AppColors.neutral.navy}
        style={{ marginRight: "8px" }}
      />
      {dateToLocaleString(startTime, undefined, timezone, formatOptions)} -{" "}
      {dateToLocaleString(endTime, undefined, timezone, formatOptions)}
    </SelectButton>
  );
};

export default TimePickerButton;
