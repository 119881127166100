import {
  AnnotationsBuilding,
  AssetType,
  BuildingAnnotationsResponse,
  BuildingMeasuresResponse,
  BuildingScheduleExceptionsResponse,
  DataQueueItem,
  EventPayload,
  OverlayTimeSpans,
  ScheduleEventType,
  ScheduleExceptionBuilding,
} from "./types";
import {
  BuildingResponse,
  ChartDataRange,
  EquipmentPointDataResponse,
  MeterDataResponse,
  ParsedChartData,
  PointType,
  SpaceGroupResponse,
} from "src/types/charting";
import { parseGroupData } from "src/components/app/ExplorerPage/parsers/groupDataParser";
import { parseBuildingData } from "src/components/app/ExplorerPage/parsers/buildingDataParser";
import { parseMeterData } from "src/components/app/ExplorerPage/parsers/meterDataParser";
import { parsePointData } from "src/components/app/ExplorerPage/parsers/pointDataParser";
import {
  removeAssetFromState,
  removeTimeSeriesFromState,
} from "src/components/app/ExplorerPage/parsers/commonBuildChart";
import { keyFromQueueItem } from "./helpers";
import {
  addAnnotationsForDateRange,
  addAnnotationsTimeSpan,
  addExceptionTimeSpan,
  addMeasureEventsForDateRange,
  addOperatingScheduleSpans,
  addScheduleExceptionsForDateRange,
  removeEventsForAssetId,
  removeOverlayEventByEventId,
  removeOverlaySpanByEventId,
} from "./parsers/eventParser";
import { ResultOf } from "@graphql-typed-document-node/core";
import {
  LoadOperatingScheduleForBuildingDocument,
  LoadThresholdsForBuildingInExplorerDocument,
} from "src/queries/typed";

/* =============================================================== *\
   ACTION TYPES
\* =============================================================== */
export type PresentationProps = {
  stroke: string; // color of the line / bar
  strokeDasharray?: string; // you can make the line dashed like so: "3 2" (line 3px, gap 2px)
  strokeWidth?: number;
  opacity?: number; // chart opacity
};

export type ChartDataPayload = {
  response:
    | BuildingResponse
    | MeterDataResponse
    | SpaceGroupResponse
    | EquipmentPointDataResponse;
  rangeSize: ChartDataRange;
  chartWidth: number;
  timezone: string | undefined;
  queueItem: DataQueueItem;
  presentationProps: PresentationProps;
  isPastComparison?: boolean; // used if chart is a "compare to past" chart : legends and other things need to know about this
};
type ReceiveGroupData = {
  type: "RECEIVE_GROUP_DATA";
  payload: ChartDataPayload;
};
type ReceiveBuildingData = {
  type: "RECEIVE_BUILDING_DATA";
  payload: ChartDataPayload;
};
type ReceivePointData = {
  type: "RECEIVE_POINT_DATA";
  payload: ChartDataPayload;
};
type ReceiveMeterData = {
  type: "RECEIVE_METER_DATA";
  payload: ChartDataPayload;
};

type ReceiveMeasureEventData = {
  type: "RECEIVE_MEASURE_EVENT_DATA";
  payload: EventPayload<BuildingMeasuresResponse>;
};

type ReceiveThresholdEventData = {
  type: "RECEIVE_THRESHOLD_EVENT_DATA";
  payload: ResultOf<typeof LoadThresholdsForBuildingInExplorerDocument>;
};

type RefreshThresholdEventData = {
  type: "REFRESH_THRESHOLD_EVENT_DATA";
  payload: {
    buildingId: string;
    pointType: PointType;
    threshold: number;
  };
};

type ReceiveAnnotationEventData = {
  type: "RECEIVE_ANNOTATION_EVENT_DATA";
  payload: EventPayload<BuildingAnnotationsResponse>;
};

type RemoveOverlayEventFromChart = {
  type: "REMOVE_OVERLAY_EVENT_FROM_CHART";
  payload: {
    deletedOverlayId: string;
  };
};

type ReceiveOperatingScheduleEventData = {
  type: "RECEIVE_BUILDING_OPERATING_SCHEDULE";
  payload: ResultOf<typeof LoadOperatingScheduleForBuildingDocument>;
};

type ReceiveScheduleExceptionEventData = {
  type: "RECEIVE_SCHEDULE_EXCEPTION_EVENT_DATA";
  payload: EventPayload<BuildingScheduleExceptionsResponse>;
};

type RefreshScheduleExceptionEventData = {
  type: "REFRESH_SCHEDULE_EXCEPTION_EVENT_DATA";
  payload: EventPayload<BuildingScheduleExceptionsResponse>;
};

// removes all asset data
type RemoveAsset = {
  type: "REMOVE_ASSET";
  payload: {
    keyPath: string;
    unitName: string;
  };
};

// removes ALL overlay events.
// eventually we will want to add a payload to this so we can remove different types
// but for now, we just have one kind so we nuke them all.
type RemoveOverlyEvents = {
  type: "REMOVE_OVERLAY_EVENTS";
};

// TODO?: this is very specific to buildings right now
type RemoveOverlayEventsForAsset = {
  type: "REMOVE_OVERLAY_EVENTS_FOR_ASSET";
  payload: {
    buildingId: string;
  };
};

type RemoveTimeseries = {
  type: "REMOVE_TIMESERIES";
  payload: {
    timeseriesKey: string;
  };
};
// toggles the visibility of a chart
type ToggleChartVisibility = {
  type: "TOGGLE_CHART_VISIBILITY";
  payload: {
    keyPath: string;
  };
};
// set it directly
type SetChartVisibility = {
  type: "SET_CHART_VISIBILITY";
  payload: {
    keyPath: string;
    visible: boolean;
  };
};
// empties everything...
type ResetState = {
  type: "RESET_STATE";
};

type ToggleOperatingScheduleOverlaysFeature = {
  type: "TOGGLE_OPERATING_SCHEDULE_OVERLAYS_FEATURE";
  payload: {
    enabled: boolean;
  };
};

type RemoveOperatingScheduleOverlays = {
  type: "REMOVE_OPERATING_SCHEDULE_OVERLAYS";
  payload: {};
};

type ToggleOperatingHoursChartDisplay = {
  type: "TOGGLE_OPERATING_HOURS_CHART_DISPLAY";
  payload: {
    visible: boolean;
  };
};

export type ChartDataAction =
  | ReceiveBuildingData
  | ReceivePointData
  | ReceiveGroupData
  | ReceiveMeterData
  | ReceiveScheduleExceptionEventData
  | ReceiveThresholdEventData
  | RefreshThresholdEventData
  | ReceiveAnnotationEventData
  | RemoveOverlayEventFromChart
  | ReceiveOperatingScheduleEventData
  | ReceiveMeasureEventData
  | RemoveAsset
  | RemoveTimeseries
  | RemoveOverlyEvents
  | RemoveOverlayEventsForAsset
  | ToggleChartVisibility
  | SetChartVisibility
  | ResetState
  | RefreshScheduleExceptionEventData
  | ToggleOperatingScheduleOverlaysFeature
  | RemoveOperatingScheduleOverlays
  | ToggleOperatingHoursChartDisplay;

/* =============================================================== *\
   THE REDUCER ITSELF
\* =============================================================== */
function chartDataReducer(
  state: ParsedChartData,
  action: ChartDataAction,
): ParsedChartData {
  switch (action.type) {
    case "RESET_STATE":
      return {
        xAxes: new Map(),
        yAxes: new Map(),
        charts: new Map(),
        data: [],
        events: new Map(),
        overlaySpans: new Map(),
        metadata: {},
        metrics: {},
        queueItems: [],
        thresholds: [],
        annotations: [],
        overlays: {
          operatingSchedule: {
            enabled: true,
          },
        },
      } as ParsedChartData;
    case "RECEIVE_GROUP_DATA":
      return parseGroupData(action.payload, state);
    case "RECEIVE_BUILDING_DATA":
      return parseBuildingData(action.payload, state);
    case "RECEIVE_POINT_DATA":
      return parsePointData(action.payload, state);
    case "RECEIVE_METER_DATA":
      return parseMeterData(action.payload, state);
    case "RECEIVE_MEASURE_EVENT_DATA": {
      let updatedEvents = state.events;

      if (
        (action.payload.response as BuildingMeasuresResponse)
          .getBuildingById !== undefined
      ) {
        updatedEvents = addMeasureEventsForDateRange(
          (action.payload.response as BuildingMeasuresResponse)
            .getBuildingById!,
          action.payload.timeRange,
          action.payload.timezone,
          state.events,
        );
      }

      return {
        ...state,
        events: new Map(updatedEvents),
      } as ParsedChartData;
    }
    case "RECEIVE_SCHEDULE_EXCEPTION_EVENT_DATA": {
      let updatedEvents = state.events;
      let updatedOverlaySpans = state.overlaySpans;

      if (
        (action.payload.response as BuildingMeasuresResponse)
          .getBuildingById !== undefined
      ) {
        updatedEvents = addScheduleExceptionsForDateRange(
          action.payload.response.getBuildingById as ScheduleExceptionBuilding,
          action.payload.timeRange,
          action.payload.timezone,
          state.events,
        );

        updatedOverlaySpans = addExceptionTimeSpan(
          action.payload.response.getBuildingById as ScheduleExceptionBuilding,
          action.payload.timeRange,
          action.payload.timezone,
          state.overlaySpans,
        );
      }

      return {
        ...state,
        events: new Map(updatedEvents),
        overlaySpans: new Map(updatedOverlaySpans),
      } as ParsedChartData;
    }
    case "RECEIVE_THRESHOLD_EVENT_DATA": {
      const thresholds = state.thresholds;

      if (
        action.payload.getBuildingById?.threshold &&
        !thresholds.find(
          t =>
            t.assetId === action.payload.getBuildingById?.id &&
            t.type === action.payload.getBuildingById.threshold?.type,
        )
      ) {
        thresholds.push({
          value: action.payload.getBuildingById.threshold.value,
          assetId: action.payload.getBuildingById.id,
          type: action.payload.getBuildingById.threshold.type,
          assetName: action.payload.getBuildingById.name,
        });
      }

      return {
        ...state,
        thresholds: [...thresholds],
      } as ParsedChartData;
    }
    case "REFRESH_THRESHOLD_EVENT_DATA": {
      const thresholds = state.thresholds;

      const existing = thresholds.find(
        t =>
          t.assetId === action.payload.buildingId &&
          t.type === action.payload.pointType,
      );

      if (existing) {
        existing.value = action.payload.threshold;
      }

      return {
        ...state,
        thresholds: [...thresholds],
      };
    }
    case "RECEIVE_ANNOTATION_EVENT_DATA": {
      let updatedEvents = state.events;
      let updatedOverlaySpans = state.overlaySpans;

      if (
        (action.payload.response as BuildingAnnotationsResponse)
          .getBuildingById !== undefined
      ) {
        updatedEvents = addAnnotationsForDateRange(
          action.payload.response.getBuildingById as AnnotationsBuilding,
          action.payload.timeRange,
          action.payload.timezone,
          state.events,
        );

        updatedOverlaySpans = addAnnotationsTimeSpan(
          action.payload.response.getBuildingById as AnnotationsBuilding,
          action.payload.timeRange,
          action.payload.timezone,
          state.overlaySpans,
        );
      }

      return {
        ...state,
        events: new Map(updatedEvents),
        overlaySpans: new Map(updatedOverlaySpans),
      } as ParsedChartData;
    }
    case "REMOVE_OVERLAY_EVENT_FROM_CHART": {
      const updatedEvents = removeOverlayEventByEventId(
        state.events,
        action.payload.deletedOverlayId,
      );
      const updatedOverlaySpans = removeOverlaySpanByEventId(
        state.overlaySpans,
        action.payload.deletedOverlayId,
      );
      return {
        ...state,
        events: updatedEvents,
        overlaySpans: new Map(updatedOverlaySpans),
      } as ParsedChartData;
    }
    case "REFRESH_SCHEDULE_EXCEPTION_EVENT_DATA": {
      let updatedEvents = state.events;
      let updatedOverlaySpans = state.overlaySpans;

      if (action.payload.response.getBuildingById !== undefined) {
        const buildingId = action.payload.response!.getBuildingById!.id;

        // Remove the events for this building so they can be re-added
        const events = removeEventsForAssetId(state.events, buildingId);
        const spans = removeEventsForAssetId(state.overlaySpans, buildingId);

        updatedEvents = addScheduleExceptionsForDateRange(
          action.payload.response.getBuildingById as ScheduleExceptionBuilding,
          action.payload.timeRange,
          action.payload.timezone,
          events,
        );

        updatedOverlaySpans = addExceptionTimeSpan(
          action.payload.response.getBuildingById as ScheduleExceptionBuilding,
          action.payload.timeRange,
          action.payload.timezone,
          spans,
        );
      }
      return {
        ...state,
        events: new Map(updatedEvents),
        overlaySpans: new Map(updatedOverlaySpans),
      } as ParsedChartData;
    }
    case "RECEIVE_BUILDING_OPERATING_SCHEDULE": {
      let updatedOverlaySpans = addOperatingScheduleSpans(
        action.payload,
        state.overlaySpans,
      );

      return {
        ...state,
        overlaySpans: updatedOverlaySpans,
      };
    }
    case "REMOVE_ASSET":
      return removeAssetFromState(
        action.payload.keyPath,
        action.payload.unitName,
        state,
      );
    case "REMOVE_TIMESERIES":
      return removeTimeSeriesFromState(action.payload.timeseriesKey, state);
    case "REMOVE_OVERLAY_EVENTS":
      return {
        ...state,
        events: new Map(),
        overlaySpans: new Map(),
        thresholds: [],
      };
    case "REMOVE_OVERLAY_EVENTS_FOR_ASSET":
      return {
        ...state,
        events: removeEventsForAssetId(state.events, action.payload.buildingId),
        overlaySpans: removeEventsForAssetId(
          state.overlaySpans,
          action.payload.buildingId,
        ),
        thresholds: state.thresholds.filter(
          t => t.assetId !== action.payload.buildingId,
        ),
      };
    case "TOGGLE_CHART_VISIBILITY":
      if (state.charts.has(action.payload.keyPath)) {
        const _newCharts = new Map(state.charts);
        const _existingChart = _newCharts.get(action.payload.keyPath)!;
        const _updated = {
          ..._existingChart,
          visible: !_existingChart.visible,
        };
        _newCharts.set(action.payload.keyPath, _updated);
        const _newState = {
          ...state,
          charts: _newCharts,
        };
        return _newState;
      }
      return state;
    case "REMOVE_OPERATING_SCHEDULE_OVERLAYS": {
      // Removes any operating schedules from the span list
      const _newOverlaySpans: OverlayTimeSpans = new Map();
      state.overlaySpans.forEach((span, key) => {
        const filteredEvents = span.filter(
          events => events.eventType !== ScheduleEventType.OperatingSchedule,
        );

        if (filteredEvents.length > 0) {
          _newOverlaySpans.set(key, filteredEvents);
        }
      });

      return {
        ...state,
        overlaySpans: _newOverlaySpans,
      };
    }
    case "TOGGLE_OPERATING_SCHEDULE_OVERLAYS_FEATURE": {
      return {
        ...state,
        overlays: {
          operatingSchedule: {
            enabled: action.payload.enabled,
          },
        },
      };
    }
    case "TOGGLE_OPERATING_HOURS_CHART_DISPLAY": {
      const _newOverlaySpans: OverlayTimeSpans = new Map();
      state.overlaySpans.forEach((span, key) => {
        span.forEach(event => {
          if (event.eventType === ScheduleEventType.OperatingSchedule) {
            event.visible = action.payload.visible;
          }
        });

        _newOverlaySpans.set(key, span);
      });

      return {
        ...state,
        overlaySpans: _newOverlaySpans,
      };
    }
    default:
      throw new Error("Unknown or unhandled action sent to chartDataReducer");
  }
}

const chartExistsForQueueItem = (
  queueItem: DataQueueItem,
  state: ParsedChartData,
): boolean => {
  const _k = keyFromQueueItem(queueItem);
  if (state.charts.get(_k)) {
    return true;
  }
  return false;
};

const initialEmptyChartData: ParsedChartData = {
  xAxes: new Map(),
  yAxes: new Map(),
  charts: new Map(),
  data: [],
  events: new Map<number, []>(),
  overlaySpans: new Map<number, []>(),
  metadata: {},
  metrics: {},
  queueItems: [],
  thresholds: [],
};

type ExplorerViewState = {
  dateRange: string | false;
  compareToPastRange: string | false;
  viewedItems: string[];
};
/**
 * Gets a summary of what the user is looking at at the current moment.
 * Suitable for metrics and so on so we cna see how users actually use
 * Explorer over time.
 *
 * @param state the existing chart reducer state
 */
const userViewState = (state: ParsedChartData): ExplorerViewState => {
  let dateRange: string | false = false;
  let compareToPastRange: string | false = false;
  const viewedItems: string[] = [];

  state.charts.forEach(chart => {
    if (chart.isPastComparison) {
      compareToPastRange = chart.queueItem.timeseriesKey;
    } else {
      dateRange = chart.queueItem.timeseriesKey;
      viewedItems.push(keyFromQueueItem(chart.queueItem));
    }
  });

  return { dateRange, compareToPastRange, viewedItems };
};

/**
 * returns a map of building IDs and number of queueItems for which we have chart data
 */
const buildingsWithChartData = (
  queueItems: DataQueueItem[],
): Map<string, { count: number; items: DataQueueItem[] }> => {
  const ids: Map<string, { count: number; items: DataQueueItem[] }> = new Map();
  queueItems.forEach(item => {
    if (item.assetType === AssetType.BUILDING) {
      const cur = ids.get(item.assetId)?.count ?? 0;
      const i = ids.get(item.assetId)?.items ?? [];
      i.push(item);
      ids.set(item.assetId, { count: cur + 1, items: i });
    }
  });
  return ids;
};

export {
  userViewState,
  chartDataReducer,
  chartExistsForQueueItem,
  initialEmptyChartData,
  buildingsWithChartData,
};
