import { DateTime } from "luxon";
import { getNiceTickValues } from "recharts-scale";
import {
  ChartDataGranularity,
  ChartDataRange,
  ChartType,
  ICombinedIntervalData,
  ICombinedMeasurement,
  IDegreeDayData,
  IIntervalData,
  IMeasurement,
  IMinMaxVals,
  ITimeRange,
  IWeatherMeasurement,
  PointType,
  ProfileSeries,
  TemperatureUnit,
  Unit,
} from "src/types/charting";
import { daysBetweenDates, midnightInTimezone } from "src/helpers/dates";
import { AppColors } from "src/components/common/Styling";
import { AreaUnit } from "src/types/graphql";
import {
  getSymbolForUnit,
  getUnitForPointType,
} from "@hatchdata/equipment-types-package/dist/src";
import {
  dateToLocaleString,
  formatNumber,
  FormatOptions,
  formatPercentage,
  Locale,
} from "@hatchdata/intl-formatter";

export function sumReducer<T, K extends keyof T>(values: T[], key: K): number {
  const sumReducer = (_current: number, item: T) => {
    return _current + ((item[key] as unknown) as number);
  };

  return values.reduce(sumReducer, 0);
}

export function averageReducer<T, K extends keyof T>(
  values: T[],
  key: K,
): number {
  const valuesNotNull = values.filter(
    value => value?.[key] !== null && value?.[key] !== undefined,
  );
  const valuesSize = valuesNotNull.length;
  const valuesSum = sumReducer(valuesNotNull, key);
  return Math.round((valuesSum / valuesSize) * 100) / 100;
}

// returns the minima and maxima of the `values` contained in an array of
// objects based on the provided `key`.
export function minmaMaximaReducer<T, K extends keyof T>(
  values: T[],
  key: K,
): IMinMaxVals<T[K]> {
  const initialState: IMinMaxVals<T[K]> = {
    minima: undefined,
    maxima: undefined,
  };
  const cb = (_current: IMinMaxVals<T[K]>, _item: T, _idx: number) => {
    // set values direclty if they are first valid ones
    if (
      _current.minima === undefined &&
      _item[key] !== undefined &&
      _item[key] !== null
    ) {
      _current.minima = _item[key];
    }
    if (
      _current.maxima === undefined &&
      _item[key] !== undefined &&
      _item[key] !== null
    ) {
      _current.maxima = _item[key];
    }
    if (
      _current.minima !== undefined &&
      _current.minima !== null &&
      _item[key] < _current.minima
    ) {
      _current.minima = _item[key];
    }
    if (
      _current.maxima !== undefined &&
      _current.maxima !== null &&
      _item[key] > _current.maxima
    ) {
      _current.maxima = _item[key];
    }
    return _current;
  };
  return values.reduce(cb, initialState);
}

export interface ITimedMinMax {
  minima: ICombinedMeasurement | null;
  maxima: ICombinedMeasurement | null;
}

// these will be used as "round to the nearest..." values
const estimatedTickRangeForDomainSize = (size: number): number => {
  let estimatedTickRange;
  if (size <= 1) {
    estimatedTickRange = 0.1;
  } else if (size <= 10) {
    estimatedTickRange = 1;
  } else if (size <= 100) {
    estimatedTickRange = 10;
  } else if (size <= 1000) {
    estimatedTickRange = 100;
  } else if (size <= 3000) {
    estimatedTickRange = 500;
  } else if (size <= 10000) {
    estimatedTickRange = 1000;
  } else if (size <= 30000) {
    estimatedTickRange = 5000;
  } else if (size <= 100000) {
    estimatedTickRange = 10000;
  } else {
    estimatedTickRange = 50000;
  }
  return estimatedTickRange;
};

export const timedMinMaxReducer = (
  values: ICombinedMeasurement[],
  key: string,
): ITimedMinMax => {
  const _minMax: ITimedMinMax = {
    minima: null,
    maxima: null,
  };
  const _callback = (_current: ITimedMinMax, _item: ICombinedMeasurement) => {
    // don't do much if we're null...
    const _val = _item[key];
    if (_val !== null) {
      if (_current.minima === null) {
        _current.minima = _item;
      }
      if (_current.maxima === null) {
        _current.maxima = _item;
      }
      if (_current.minima !== null && _val <= _current.minima[key]!) {
        _current.minima = _item;
      }
      if (_current.maxima !== null && _val >= _current.maxima[key]!) {
        _current.maxima = _item;
      }
    }
    return _current;
  };
  return values.reduce(_callback, _minMax);
};

/**
 * Gets the domain size, accounting for negatives even!
 *
 * @param min min value of the domain
 * @param max  max value of the domain
 */
const properDomainSize = (min: number, max: number): number => {
  return Math.abs(max - min);
};
// gives us a nice set of tick marks for Y axis use.
// the first tick will ALWAYS be the min.
// the max will be adjusted to be a "nice" value based on the
// domain size.
export function tickMarkValues(max: number, min: number = 0): number[] {
  // get how big the domain is
  const domainSize = properDomainSize(min, max);
  // what should we round to the nearest of based on the domain size
  // i.e. round to the nearest 100, 10, 500, etc. when we make tick sizes
  const estimatedTickRange = estimatedTickRangeForDomainSize(domainSize);
  // given our max value and domain size, what should the "top tick" be?
  const roundedMaxTickValue = roundUpTo(max, estimatedTickRange);
  const roundTo = domainSize > 5 ? 5 : 1;
  const niceMax = getNextNumberDivisibleBy(roundedMaxTickValue, roundTo);
  const niceMin = getPreviousNumberDivisibleBy(min, roundTo);
  // Always 5 Steps
  return fiveTicks(niceMin, niceMax);
}

/**
 * Takes a first tick, a last tick, and fills in the middle with four
 * ticks. So you get a "axis value" which is the min, and then five ticks
 * inclusive of the max. There is no rounding or adjustments made, just
 * simple division.
 *
 * @param min the first tick value
 * @param max the last tick value
 */
const fiveTicks = (min: number, max: number): number[] => {
  const domainSize = properDomainSize(min, max);
  const step = domainSize / 5; // domainSize is always positive....
  const ticks = [min];
  for (let i = 1; i < 5; i += 1) {
    // if the tick is smaller than 5, we will probably be floats, so we should defeat JS
    const next = min + step * i;
    if (next < 5) {
      ticks.push(Math.round(next * 100) / 100);
    } else {
      ticks.push(Math.round(next));
    }
  }
  ticks.push(max);
  return ticks;
};

type DomainObject = {
  min: number;
  max: number;
  fixedMin?: boolean;
  fixedMax?: boolean;
  minTickGap?: number; // we have to be at least this big!
};

/**
 * Generates tick mark increments for multiple domains, but also returns
 * a domain, so the ticks are a prop. Sorry for the misleading name.
 * Each domain must have a min and max. The returned domain will adjust these
 * based on the domain size, so you will get back a rounded min and max by default
 * that covers the range of the tick marks.
 *
 * You can opt out of that behavior for either the min or max by providing the optional
 * fixedMin and fixedMax props. This will set the min and max of the returned domain
 * (and therefore the first / last tick mark repectively) to the input values.
 *
 * You can also ensure there will be a minimum of _n_ between your ticks by having the
 * minTickGap prop set. It will expand the domain if needed to ensure the smallest tick
 * produced is at least that value. If course, if you set fixedMin or fixedMax AND a
 * minTickGap, minTickGap wins and will override either / both of those.
 * Difficult choices must be made sometimes!
 *
 * @param domains - all the domains you care to generate ticks for.
 */
export function multiDomainYTickMarkValues(
  domains: DomainObject[],
): { min: number; max: number; ticks: number[] }[] {
  const getUsableMax = (domain: DomainObject): number => {
    if (domain.fixedMax) {
      return domain.max;
    }
    const size = properDomainSize(domain.min, domain.max);
    const estimatedTickRange = estimatedTickRangeForDomainSize(size);
    // given our max value and domain size, what should the "top tick" be?
    const roundedMaxTickValue = roundUpTo(domain.max, estimatedTickRange);
    //const roundTo = size > 5 ? 5 : 1;
    const roundTo = domain.minTickGap || size > 5 ? 5 : 1;
    return getNextNumberDivisibleBy(roundedMaxTickValue, roundTo);
  };
  const getUsableMin = (domain: DomainObject): number => {
    if (domain.fixedMin) {
      return domain.min;
    }
    const size = properDomainSize(domain.min, domain.max);
    // given our max value and domain size, what should the "top tick" be?
    const estimatedTickRange = estimatedTickRangeForDomainSize(size);
    const roundedMinTickValue = roundDownTo(domain.min, estimatedTickRange);
    const roundTo = domain.minTickGap || size > 5 ? 5 : 1;
    return getPreviousNumberDivisibleBy(roundedMinTickValue, roundTo);
  };
  return domains.map(domain => {
    let useMax: number;
    let useMin: number;
    const size = properDomainSize(domain.min, domain.max);
    if (domain.minTickGap && size < domain.minTickGap * 5) {
      const expandTo = domain.minTickGap * 5;
      const avg = Math.round((domain.min + domain.max) / 2);
      const expandedMax = roundUpTo(
        Math.ceil(avg + expandTo / 2),
        domain.minTickGap,
      );
      const expandedMin = roundUpTo(
        Math.floor(avg - expandTo / 2),
        domain.minTickGap,
      );
      const expandedDomain = {
        ...domain,
        min: expandedMin,
        max: expandedMax,
      };
      useMax = getUsableMax(expandedDomain);
      useMin = getUsableMin(expandedDomain);
    } else {
      useMax = getUsableMax(domain);
      useMin = getUsableMin(domain);
    }
    return {
      min: useMin,
      max: useMax,
      ticks: fiveTicks(useMin, useMax),
    };
  });
}

// some functions for formatting time-based tick marks
// SPECIAL SNOWFLAKES!!!!!!!!
const getDayNumberFormatter = (timezone?: string): ((d: Date) => string) => {
  return (d: Date) =>
    dateToLocaleString(d, undefined, timezone, FormatOptions.MONTH_AND_DAY);
};

const getDayNameFormatter = (timezone?: string): ((d: Date) => string) => {
  return (d: Date) =>
    dateToLocaleString(d, undefined, timezone, FormatOptions.WEEKDAY_LONG);
};

const getHAmPmFormatter = (timezone?: string): ((d: Date) => string) => {
  return (d: Date) =>
    dateToLocaleString(d, undefined, timezone, FormatOptions.HOUR);
};

const getMdyFormatter = (timezone?: string): ((d: Date) => string) => {
  return (d: Date) =>
    dateToLocaleString(
      d,
      undefined,
      timezone,
      FormatOptions.DATE_SHORT_2_DIGIT_YEAR,
    );
};

// returns a function that will format X-axis tick labels in a way
// that is appropriate for the data range
export function dateTickFormatterFunction(
  rangeSize: ChartDataRange,
  timezone?: string,
): (date: Date) => string {
  switch (rangeSize) {
    case ChartDataRange.Today:
      return getHAmPmFormatter(timezone);
    case ChartDataRange.SevenDays:
      return getDayNameFormatter(timezone);
    case ChartDataRange.ThirtyDays:
      return getDayNumberFormatter(timezone);
    case ChartDataRange.Year:
    default:
      return getDayNumberFormatter(timezone);
  }
}

// returns a function that will format X-axis tick labels in a way
// that is appropriate for explorer since they are all the same
export function explorerDateTickFormatter(
  timezone?: string,
): ((date: Date) => string) | undefined {
  return getMdyFormatter(timezone);
}

// returns a "nice" number of ticks to display for time-base axis
export function numTicksForDataRange(
  rangeSize: ChartDataRange,
  chartWidth: number,
): number | undefined {
  switch (rangeSize) {
    case ChartDataRange.Today:
      return chartWidth < 900 ? 6 : 12;
    case ChartDataRange.SevenDays:
      return chartWidth < 600 ? 3 : 7;
    case ChartDataRange.ThirtyDays:
      return chartWidth < 800 ? 7 : 15;
    case ChartDataRange.Year:
    default:
      return undefined;
  }
}

/**
 * TODO: break this up since range and custom should probalby be handled
 * differently because this is sort of a kludge. Also, the interface is terrible
 * (multiple optional ordered parameters which have dependencies on each other = 😞)
 *
 * For all timeseries charts, the x-axis (time) should have increment
 * tick labels which are appropriate for the selected time range of the
 * data being viewed.

 * For ranges of 24 hours or less: 12 tick marks should be rendered in
 * 2hr increments, formatted as a hour labels (eg: 12 PM)

 * For ranges from 1 - 7 days: 1 tick mark should be rendered for each day,
 * formatted as the day of the week (eg: Monday)

 * For ranges from 8 - 31 days: 1 tick mark should be rendered for every other
 * day, formatted as the day and month (eg: 12-07)

 * For ranges of more than 31 days: tick marks should be rendered at even
 * intervals, no more than 15 in total, and formatted as day and month (eg: 12-07)
 *
 * @param width - the width in pixels of the chart
 * @param values - the combined data with timestamps
 * @param dataRange  - the set ChartDataRange you need ticks for (optional)
 * @param timeRange - a custom ITimeRange you want ticks for
 */
export const xTicksForRangeAndSize = (
  width: number = 1000,
  values: ICombinedMeasurement[] = [],
  dataRange?: ChartDataRange,
  timeRange?: ITimeRange,
  alignDayTicksToNoon: boolean = true,
): number[] => {
  const _ticks: number[] = [];

  // helper functions!
  const hourTicks = (_count: number, _start: Date, _hoursToAdd = 2) => {
    // if we are not at the beginning of the hour, start on the next whole hour?
    if (_start.getMinutes() !== 0) {
      _start.setHours(_start.getHours() + 1);
    }
    _start.setMinutes(0);
    _start.setSeconds(0);
    _start.setMilliseconds(0);
    _ticks.push(_start.getTime());
    for (let i = 1; i < _count; i++) {
      _start.setHours(_start.getHours() + _hoursToAdd);
      _ticks.push(_start.getTime());
    }
  };
  const dayTicks = (_count: number, _start: Date, _daysToAdd = 1) => {
    const msOffset = alignDayTicksToNoon ? 43200000 : 0; // 43200000 = 12 hours in ms
    _ticks.push(_start.getTime() + msOffset);
    for (let i = 1; i < _count; i++) {
      _start.setDate(_start.getDate() + _daysToAdd);
      _ticks.push(_start.getTime() + msOffset);
    }
  };
  // handle the easy cases!
  if (dataRange) {
    const _count = numTicksForDataRange(dataRange, width) as number;
    const _start = new Date(values[0].timestamp);
    // 24 HOURS =======
    if (dataRange === ChartDataRange.Today) {
      const _hrs = Math.floor(24 / _count);
      hourTicks(_count, _start, _hrs);
    }
    // WEEK =======
    if (dataRange === ChartDataRange.SevenDays) {
      const daysToAdd = Math.floor(7 / _count);
      dayTicks(_count, _start, daysToAdd);
    }
    // MONTH =======
    if (dataRange === ChartDataRange.ThirtyDays) {
      const daysToAdd = Math.floor(30 / _count);
      dayTicks(_count, _start, daysToAdd);
    }
  }
  // now the hard part!
  if (timeRange) {
    // figure out how many days...
    // TODO: use the date helper for this!
    const s = DateTime.fromISO(timeRange.startTime);
    const e = DateTime.fromISO(timeRange.endTime);
    const diffDuration = s.diff(e, ["days", "hours"]);
    const dayDiff = Math.abs(diffDuration.days);
    const hourDiff = Math.abs(diffDuration.hours);
    if (dayDiff < 1) {
      hourTicks(Math.floor(hourDiff / 2), s.toJSDate(), 2);
    } else if (dayDiff < 8) {
      dayTicks(dayDiff, s.toJSDate(), 1);
    } else if (dayDiff < 32) {
      dayTicks(Math.floor(dayDiff / 2), s.toJSDate(), 2);
    } else {
      // Increase the range to include both ends,
      // because we don't mind having a tick on the first & last days
      const rangeSize = dayDiff + 1;
      // start with 15 ticks
      let count = 15;
      let _spacer = Math.ceil(rangeSize / count);
      // reduce the number of ticks until they fit within our range
      while (count * _spacer > rangeSize) {
        count--;
      }
      dayTicks(count, s.toJSDate(), _spacer);
    }
  }
  return _ticks;
};

export const dayBoundariesInRange = (
  range: ITimeRange,
  timezone: string = "UTC",
): Date[] => {
  const days: Date[] = [];
  const rangeDates = {
    start: new Date(range.startTime),
    end: new Date(range.endTime),
  };
  const endDate = DateTime.fromJSDate(rangeDates.end, {
    zone: timezone,
  }).startOf("day");
  let compareDate = DateTime.fromJSDate(rangeDates.start, {
    zone: timezone,
  }).startOf("day");

  while (compareDate <= endDate) {
    days.push(compareDate.toJSDate());
    compareDate = compareDate.plus({ days: 1 });
  }
  return days;
};

/**
 * Returns a range of monthly boundaries between a starting and end month
 * @param range
 * @param timezone
 * @returns
 */
export const monthBoundariesInRange = (
  range: ITimeRange,
  timezone: string = "UTC",
): Date[] => {
  const days: Date[] = [];
  const rangeDates = {
    start: DateTime.fromISO(range.startTime),
    end: DateTime.fromISO(range.endTime),
  };
  const endDate = rangeDates.end
    .setZone(timezone)
    .startOf("month")
    .plus({ days: 15 });

  let compareDate = rangeDates.start
    .setZone(timezone)
    .startOf("month")
    .plus({ days: 15 });

  while (compareDate <= endDate) {
    days.push(compareDate.toJSDate());
    compareDate = compareDate.plus({ month: 1 });
  }
  return days;
};

/**
 * returns the next number above the given value that is divisible by
 * another value, or the original value if it already is divisible by it.
 *
 * @param val start value
 * @param divisibleBy  number you want the return divisible by
 */
export const getNextNumberDivisibleBy = (val: number, divisibleBy: number) => {
  let counter = 0;
  let newVal = Math.ceil(val);
  while (newVal % divisibleBy !== 0 && counter !== 10) {
    newVal = newVal + 1;
    counter = counter + 1;
  }

  return newVal;
};
/**
 * returns the next number above the given value that is divisible by
 * another value, or the original value if it already is divisible by it.
 *
 * @param val start value
 * @param divisibleBy  number you want the return divisible by
 */
export const getPreviousNumberDivisibleBy = (
  val: number,
  divisibleBy: number,
) => {
  let counter = 0;
  let newVal = Math.floor(val);
  while (newVal % divisibleBy !== 0 && counter !== 10) {
    newVal = newVal - 1;
    counter = counter + 1;
  }

  return newVal;
};

/**
 * Rounds a number up to the closest value that is divisible
 * by a bound. For example, if you wanted to round a number to
 * the closest 500, you would use this function like so:
 *
 * roundUpTo(1200, 500) // 1500
 * roundUpTo(1501, 500) // 2000
 *
 * @param val the number you want rounded
 * @param bound  the "closest" increment
 */
const roundUpTo = (val: number, bound: number): number =>
  Math.ceil(val / bound) * bound;

/**
 * Rounds a number down to the closest value that is divisible
 * by a bound. For example, if you wanted to round a number to
 * the closest 500, you would use this function like so:
 *
 * roundDownTo(1200, 500) // 1000
 * roundDownTo(1501, 500) // 1500
 *
 * @param val the number you want rounded
 * @param bound  the "closest" increment
 */
const roundDownTo = (val: number, bound: number): number =>
  Math.floor(val / bound) * bound;

// applies padding to a domain so charts can have some "breathing room"
export function paddedDomain(
  domain: IMinMaxVals<number>,
  percentPadding: number,
): IMinMaxVals<number> {
  if (domain.maxima === undefined || domain.minima === undefined) {
    return domain;
  }
  const diff = domain.maxima - domain.minima;
  const padAmount = diff * (percentPadding / 100);
  const padded = {
    maxima: domain.maxima + padAmount,
    minima: domain.minima - padAmount,
  };
  return padded;
}

// returns a padded domain where the minima is set to ZERO if
// it less than zero
export function positivePaddedDomain(
  domain: IMinMaxVals<number>,
  percentPadding: number,
): IMinMaxVals<number> {
  const padded = paddedDomain(domain, percentPadding);
  if (padded.minima !== undefined && padded.minima < 0) {
    padded.minima = 0;
  }
  return padded;
}

// TODO: this should use a "real" type instead of this special instance
type StartAndEndTimes = { startTime: Date; endTime: Date };

/**
 * Adjusts range to line up with time buckets of the given granularity.
 *
 * @param startTime
 * @param endTime
 * @param granularity
 */
export const adjustRangeForGranularity = (
  { startTime, endTime }: StartAndEndTimes,
  granularity: ChartDataGranularity,
): StartAndEndTimes => {
  const roundDateTimeToMinutes = (date: Date, minutes: number): Date => {
    const dt = DateTime.fromJSDate(date);
    const newMins = Math.floor(dt.minute / minutes) * minutes;
    return dt.set({ minute: newMins, second: 0, millisecond: 0 }).toJSDate();
  };
  switch (granularity) {
    case ChartDataGranularity.Hour:
      return {
        startTime: DateTime.fromJSDate(startTime)
          .startOf("hour")
          .toJSDate(),
        endTime: DateTime.fromJSDate(endTime)
          .startOf("hour")
          .toJSDate(),
      };
    case ChartDataGranularity.FifteenMinute:
      return {
        startTime: roundDateTimeToMinutes(startTime, 15),
        endTime: roundDateTimeToMinutes(endTime, 15),
      };
    case ChartDataGranularity.FiveMinute:
    default:
      return {
        startTime: roundDateTimeToMinutes(startTime, 5),
        endTime: roundDateTimeToMinutes(endTime, 5),
      };
  }
};

/**
 * Expands range to include any partial hours.
 */
export const expandRangeToHourlyGranularity = ({
  startTime,
  endTime,
}: ITimeRange): ITimeRange => {
  const originalEnd = DateTime.fromISO(endTime);
  const newEnd = DateTime.fromISO(endTime).startOf("hour");
  const newStart = DateTime.fromISO(startTime).startOf("hour");
  const end = newEnd < originalEnd ? newEnd.plus({ day: 1 }) : newEnd;
  return {
    startTime: newStart.toISO() as string,
    endTime: end.toISO() as string,
  };
};

/**
 * Creates local start & end range times for a query based
 * on the selected granularity, and range size.
 *
 * Creates local midnight range dates with one day in advance
 * so we can fetch for current day data
 *
 * I.e.: if today is 05/14 a 30 day range this function will return:
 * (Given the user is at New York Time)
 *
 * start: 2020-04-16T04:00:00.000Z
 * end: 2020-05-16T04:00:00.000Z
 *
 * Returns Date objects
 */
export const createLocalMidnightRangeDates = (
  range: ChartDataRange = ChartDataRange.Today,
  granularity: ChartDataGranularity,
): StartAndEndTimes => {
  if (range === ChartDataRange.Today) {
    const now = DateTime.local();
    return adjustRangeForGranularity(
      {
        startTime: now.minus({ hours: 24 }).toJSDate(),
        endTime: now.toJSDate(),
      },
      granularity,
    );
  } else {
    const startTime = DateTime.local()
      .endOf("day")
      .minus({ day: range - 1 })
      .plus({ millisecond: 1 })
      .toJSDate();
    const endTime = DateTime.local()
      .endOf("day")
      .plus({ day: 1, millisecond: 1 })
      .toJSDate();
    return { startTime, endTime };
  }
};

/**
 * Creates start & end range times for a query based on the selected granularity,
 * building timezone, and range size.
 *
 * If the range is 24 hours, the generated range is adjusted to the previous time
 * bucket of the specified granularity.
 *
 * If the range is for more than 1 day, the generated range is adjusted to begin
 * and end at midnight for tomorrow  in the building's timezone.
 *
 * Returns Date objects
 */
export const createRangeDates = (
  range: ChartDataRange = ChartDataRange.Today,
  timezone: string | undefined,
  granularity: ChartDataGranularity,
): StartAndEndTimes => {
  if (range === ChartDataRange.Today) {
    const now = DateTime.local();
    return adjustRangeForGranularity(
      {
        startTime: now.minus({ hours: 24 }).toJSDate(),
        endTime: now.toJSDate(),
      },
      granularity,
    );
  } else {
    const localMidnight = timezone
      ? DateTime.local()
          .setZone(timezone)
          .startOf("day")
      : DateTime.local().startOf("day");

    const startTime = localMidnight.minus({ day: range - 1 }).toJSDate();
    const endTime = localMidnight.plus({ day: 1 }).toJSDate();
    return { startTime, endTime };
  }
};

/**
 * Creates start & end range times for a query based on the selected granularity,
 * building timezone, and range size.
 *
 * If the range is 24 hours, the generated range is adjusted to the previous time
 * bucket of the specified granularity.
 *
 * If the range is for more than 1 day, the generated range is adjusted to begin
 * and end at midnight in the building's timezone.
 *
 * Returns an ITimeRange (ISO strings)
 */
export const createTimeRange = (
  range: ChartDataRange = ChartDataRange.Today,
  timezone: string | undefined,
  granularity: ChartDataGranularity,
): ITimeRange => {
  const { startTime, endTime } = createRangeDates(range, timezone, granularity);
  return {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  };
};

/**
 * Creates start & end range times for a query based on the selected granularity,
 * building timezone, and range size.
 *
 * If the range is 24 hours, the generated range is adjusted to the previous time
 * bucket of the specified granularity.
 *
 * If the range is for more than 1 day, the generated range is adjusted to begin
 * and end at midnight with one day in advance.
 *
 * Returns an ITimeRange (ISO strings)
 */
export const createOneDayInAdvanceTimeRange = (
  range: ChartDataRange = ChartDataRange.Today,
  granularity: ChartDataGranularity,
): ITimeRange => {
  const { startTime, endTime } = createLocalMidnightRangeDates(
    range,
    granularity,
  );
  return {
    startTime: startTime.toISOString(),
    endTime: endTime.toISOString(),
  };
};

/**
 * Returns the default selected granularity option for a given range.
 */
export const getDefaultGranularityForRange = (
  range: ChartDataRange,
): ChartDataGranularity => {
  switch (range) {
    case ChartDataRange.Today:
      return ChartDataGranularity.FiveMinute;
    case ChartDataRange.SevenDays:
      return ChartDataGranularity.FifteenMinute;
    case ChartDataRange.ThirtyDays:
    case ChartDataRange.NinetyDays:
      return ChartDataGranularity.Hour;
    case ChartDataRange.Year:
      return ChartDataGranularity.Day;
    default:
      throw Error("Mapping for range to granularity not defined.");
  }
};

/**
 * Returns granularity options based on a time frame.
 * @param range Timeframe used to determine available granularity
 */
export const getGranularityOptions = (
  range: ChartDataRange,
  translate: (args: { id: string }) => string,
): { [key: string]: string } => {
  const options: { [key: string]: string } = {};
  const INTERVAL = translate({ id: "dataGranularity.interval" });

  if (range === ChartDataRange.Today || range === ChartDataRange.SevenDays) {
    options[ChartDataGranularity.FiveMinute] = `${INTERVAL}: ${translate({
      id: "dataGranularity.fiveMinutes",
    })}`;
  }

  if (range === ChartDataRange.Today || range === ChartDataRange.SevenDays) {
    options[ChartDataGranularity.FifteenMinute] = `${INTERVAL}: ${translate({
      id: "dataGranularity.fifteenMinutes",
    })}`;
  }

  if (range <= ChartDataRange.NinetyDays) {
    options[ChartDataGranularity.Hour] = `${INTERVAL}: ${translate({
      id: "dataGranularity.hourly",
    })}`;
  }

  if (range > ChartDataRange.Today) {
    options[ChartDataGranularity.Day] = `${INTERVAL}: ${translate({
      id: "dataGranularity.daily",
    })}`;
  }

  /** TODO: Enable this when monthly granularity works */
  /*
  if (range > ChartDataRange.ThirtyDays) {
    options[ChartDataGranularity.Month] = `${INTERVAL}: ${translate(
      {id: "dataGranularity.monthly"}
    )}`;
  }
*/

  return options;
};

/**
 * Returns granularity options based on a time frame.
 * @param range Timeframe used to determine available granularity
 */
export const getExportGranularityOptions = (
  range: number,
  translate: (args: { id: string }) => string,
): { [key: string]: string } => {
  const options: { [key: string]: string } = {};

  if (range >= 0 && range <= 14) {
    options[ChartDataGranularity.FiveMinute] = `${translate({
      id: "dataGranularity.fiveMinutes",
    })}`;
  }

  options[ChartDataGranularity.FifteenMinute] = `${translate({
    id: "dataGranularity.fifteenMinutes",
  })}`;

  options[ChartDataGranularity.Hour] = `${translate({
    id: "dataGranularity.hourly",
  })}`;

  options[ChartDataGranularity.Day] = `${translate({
    id: "dataGranularity.daily",
  })}`;

  /** TODO: Enable this when monthly granularity works */
  /*
  if (range > 90) {
    options[ChartDataGranularity.Month] = `${INTERVAL}: ${translate(
      {id:"dataGranularity.monthly"}
    )}`;
  }
*/

  return options;
};

// returns the number of hours a granularity represents
// only really exported for testing...
export const hoursFromGranularity = (
  granularity: ChartDataGranularity,
): number => {
  switch (granularity) {
    case ChartDataGranularity.Hour:
      return 1;
    case ChartDataGranularity.FifteenMinute:
      return 0.25;
    case ChartDataGranularity.FiveMinute:
      return 0.08333;
    case ChartDataGranularity.Day:
      return 24;
  }
  return 1;
};

// returns the electricity DEMAND (kW) given a usage (kWh)
export const electricityDemandFromUsage = (
  usage: number | null,
  usageGranularity: ChartDataGranularity,
): number | null => {
  if (usage === null) {
    return null;
  }
  return usage / hoursFromGranularity(usageGranularity);
};

/**
 * Formats numbers to a certain precision for display.
 * @param num the number to format
 */
export const precisionNumberFormat = (num: number | null): string => {
  if (num === null) {
    return "";
  }
  if (num < 1000) {
    return formatNumber(Number(num.toPrecision(3)));
  } else if (num < 10000) {
    return formatNumber(Number(num.toPrecision(5)));
  } else if (num < 100000) {
    return formatNumber(Number(num.toPrecision(6)));
  } else if (num < 1000000) {
    return formatNumber(Number(num.toPrecision(7)));
  }
  return formatNumber(Number(num.toFixed(0)), undefined, 0);
};

export const friendlyNumberFormat = (
  num: number | null,
  maxFractionDigits: number = 2,
  minFractionDigits: number = 0,
  locale: string | undefined = undefined,
): string => {
  if (num === null) {
    return "";
  }
  if (num < 10000) {
    return formatNumber(num, locale, maxFractionDigits, minFractionDigits);
  }
  return formatNumber(num, locale, 0);
};

// determines how many data values you *should* have given a range
// and granularity
export const expectedValuesForDataSet = (
  range: ChartDataRange,
  granularity: ChartDataGranularity,
): number => {
  let valuesPerDay = 0;
  switch (granularity) {
    case ChartDataGranularity.Hour:
      valuesPerDay = 24;
      break;
    case ChartDataGranularity.FifteenMinute:
      valuesPerDay = 96;
      break;
    case ChartDataGranularity.FiveMinute:
      valuesPerDay = 288;
      break;
    case ChartDataGranularity.Day:
    default:
      valuesPerDay = 1;
  }
  return valuesPerDay * range;
};

// returns the percentage of values "missing" given what we have
// and the range and granularity we asked for. Return is an integer
// (ex: if we expected 100 and only had 40, we return 60, not 0.6)
export const percentValuesMissing = (
  range: ChartDataRange,
  granularity: ChartDataGranularity,
  numValues: number,
): number => {
  const expected = expectedValuesForDataSet(range, granularity);
  const percentThere = Math.round((numValues / expected) * 100);
  return 100 - percentThere;
};

/**
 * Give back a formatted string of a percentage value (ex: 23.847213 or -82.73213)
 * Return looks like '+23.85%' or '-82.73%' The value is rounded by .toFixed, FYI
 * @param percent - the percentage to format
 * @param showSign - should I return a sign in front? (default true)
 *
 */
export const formattedPercentDifference = (
  percent: number,
  showSign: boolean = true,
  locale: string | undefined = undefined,
): string => {
  const value = formatPercentage(percent / 100, locale, 1, 1);
  if (showSign) {
    return `${percent > 0 ? "+" : ""}${value}`;
  }
  return formatPercentage(Math.abs(percent) / 100, locale, 1, 1);
};

/**
 * Calculates the percent difference between two numbers and returns it as a
 * percentage. Will return a negative value if the second parameter is lower
 * than the first.
 *
 * @param baseline - a badly name parameter
 * @param actual - another padly named parameter
 */
export const calculatePercentDiff = (
  baseline: number,
  actual: number,
): number => {
  if (baseline === 0) {
    return 0;
  }
  if (actual === 0) {
    return -100;
  }
  return ((actual - baseline) / baseline) * 100; // in 33.333 format...
};

/**
 * Gives you a color to represent the percent value to the user. Red if it's
 * positive, green if it's negative, blue if it is the same or very close.
 *
 * @param percent - 100 based percentage (i.e.: ten percent is 10.0, not .10)
 * @param isInverted - If the colors should be inverted. Red if negative,
 * green if positive
 */
export const colorFromPercent = (
  percent: number,
  isInverted: boolean = false,
): string => {
  if (percent < -0.5) {
    if (isInverted) {
      return AppColors.semantic.red.red;
    }
    return AppColors.primary["msr-green"];
  } else if (percent > 0.5) {
    if (isInverted) {
      return AppColors.primary["msr-green"];
    }
    return AppColors.semantic.red.red;
  }
  return AppColors.semantic.blue.sky;
};

/**
 * Generates a set of 24 hour ITimeRages based on today's date. It will
 * return ranges that end on the day *prior* to today (so yesterday through
 * a week ago today)
 *
 * @param timezone - optional timezone you'd like the ranges to be in
 * @returns - an array of seven ITimeRanges. See `sevenDayRangesFromDate()`
 */
export const sevenDayRangesFromToday = (timezone?: string): ITimeRange[] => {
  const localMidnight = timezone
    ? DateTime.local()
        .setZone(timezone)
        .startOf("day")
    : DateTime.local().startOf("day");

  const startTime = localMidnight.minus({ day: 1 }).toISO() as string;
  const endTime = localMidnight.toISO() as string;
  const ranges = [{ startTime, endTime }];
  for (let i = 1; i <= 6; i += 1) {
    let _e = localMidnight.minus({ day: i });
    let _s = _e.minus({ day: 1 }); //.nearest("day");
    // this pushes EST -> DST boundary day to midnight instead of 11pm prev. day
    // we wind up with a day that only has 23 hours, but that is reality, so there!
    if (_e.hour === 23) {
      _e = _e.plus({ hour: 1 });
    }
    if (_s.hour === 23) {
      _s = _s.plus({ hour: 1 });
    }
    // how to look for hours?
    ranges.push({
      startTime: _s.toISO() as string,
      endTime: _e.toISO() as string,
    });
  }
  return ranges;
};

const luxonIncrementors = {
  [ChartDataGranularity.Day]: (input: DateTime) => input.plus({ day: 1 }),
  [ChartDataGranularity.Month]: (input: DateTime) => input.plus({ month: 1 }),
  [ChartDataGranularity.Hour]: (input: DateTime) => input.plus({ hour: 1 }),
  [ChartDataGranularity.FifteenMinute]: (input: DateTime) =>
    input.plus({ minutes: 15 }),
  [ChartDataGranularity.FiveMinute]: (input: DateTime) =>
    input.plus({ minutes: 5 }),
};
const luxonDecrementors = {
  [ChartDataGranularity.Day]: (input: DateTime) => input.minus({ day: 1 }),
  [ChartDataGranularity.Month]: (input: DateTime) => input.minus({ month: 1 }),
  [ChartDataGranularity.Hour]: (input: DateTime) => input.minus({ hour: 1 }),
  [ChartDataGranularity.FifteenMinute]: (input: DateTime) =>
    input.minus({ minutes: 15 }),
  [ChartDataGranularity.FiveMinute]: (input: DateTime) =>
    input.minus({ minutes: 5 }),
};

/**
 *
 * combines commodity and weather data into one object
 * for use with charts
 */
export const convertAndMergeCommodity = ({
  commodityData,
  commodityGranularity,
  weatherData,
  range,
  labels,
}: {
  commodityData: { values: IMeasurement[]; name: string; unit: Unit };
  commodityGranularity: ChartDataGranularity;
  weatherData: {
    values: IWeatherMeasurement[];
    temperatureUnit:
      | TemperatureUnit
      | typeof Unit.CELSIUS
      | typeof Unit.FAHRENHEIT;
  };
  range: ITimeRange;
  labels: {
    temperature: string;
    humidity: string;
    noop: string;
  };
}): ICombinedIntervalData => {
  const converted = convertAndMergeElectricity(
    commodityData,
    commodityGranularity,
    range,
    labels.noop,
  );

  // determine weather offset
  let weatherOffset = calculateOffset(
    range,
    weatherData.values[0].timestamp,
    commodityGranularity,
  );

  converted.values = converted.values
    // map weather data
    .map((commodityMeasurement, index) => {
      const offsetIndex = index - weatherOffset;
      return {
        ...commodityMeasurement,
        temperature:
          offsetIndex >= 0 && offsetIndex < weatherData.values.length
            ? weatherData.values[offsetIndex].temperature
            : null,
        HUMIDITY:
          offsetIndex >= 0 && offsetIndex < weatherData.values.length
            ? weatherData.values[offsetIndex].relativeHumidity
            : null,
      };
    });

  converted.units.TEMPERATURE =
    weatherData.temperatureUnit === "FAHRENHEIT" ? "ºF" : "ºC";
  converted.units.HUMIDITY = "%";

  converted.labels.TEMPERATURE = labels.temperature;
  converted.labels.HUMIDITY = labels.humidity;
  converted.labels.nonOperatingHours = labels.noop;

  return converted;
};

/**
 * Combines energy and heating/cooling data into an object to be used for charting
 * @param commodityData Energy data used to shape the object
 * @param commodityGranularity Granularity of the chart
 * @param range Starting and ending time span
 * @param degreeDayData Heating and Cooling data to be merged
 */
export const convertAndMergeHeatingCooling = (
  commodityData: IIntervalData,
  commodityGranularity: ChartDataGranularity,
  range: ITimeRange,
  degreeDayData: IDegreeDayData,
  labels: {
    hdd: string;
    cdd: string;
    usage: string;
    dd_unit: string;
    noop: string;
  },
): ICombinedIntervalData => {
  const combinedData = convertAndMergeElectricity(
    commodityData,
    commodityGranularity,
    range,
    labels.noop,
  );

  combinedData.values = combinedData.values.map(
    (commodityMeasurement, index) => ({
      ...commodityMeasurement,
      hdd:
        index < degreeDayData.heatingDegreeDayData.length
          ? degreeDayData.heatingDegreeDayData[index].value
          : null,
      cdd:
        index < degreeDayData.coolingDegreeDayData.length
          ? degreeDayData.coolingDegreeDayData[index].value
          : null,
    }),
  );

  combinedData.units[ChartType.heatingDegreeDays] = labels.dd_unit;
  combinedData.units[ChartType.coolingDegreeDays] = labels.dd_unit;

  combinedData.labels.value = labels.usage;
  combinedData.labels[ChartType.heatingDegreeDays] = labels.hdd;
  combinedData.labels[ChartType.coolingDegreeDays] = labels.cdd;

  return combinedData;
};

/**
 * Determines the offset in the array an object should be added based on its starting timestamp.
 * @param range the start and end datetime ranges used to calculate the offset
 * @param timestamp the starting date time of the object that the offset is being calculated for
 * @param granularity the size of date/time chunks to calculate
 */
const calculateOffset = (
  range: ITimeRange,
  timestamp: string | Date,
  granularity: ChartDataGranularity,
): number => {
  // Generate timeseries for range at given granularity
  // const start = new Date(range.startTime);

  let current = DateTime.fromISO(range.startTime);

  // determine weather offset
  let offset = 0;
  const _d = typeof timestamp === "string" ? new Date(timestamp) : timestamp;
  const timestampStart = DateTime.fromJSDate(_d);

  if (current < timestampStart) {
    while (current < timestampStart) {
      offset++;
      current = luxonIncrementors[granularity](current);
    }
  } else if (current > timestampStart) {
    while (current > timestampStart) {
      offset--;
      current = luxonDecrementors[granularity](current);
    }
  }

  return offset;
};

/**
 * Creates an ICombinedIntervalData object shaped based on the provided energy data, time frame, and granularity
 * Ensures that an entry exists for every possible timevalue in the range given the granularity. It also seems
 * to pad the ends if for some reason we don't get data at the ends.
 *
 * Nothing is actually merged via this method though.
 *
 * @param commodityData Energy array that is used as the base for merged data
 * @param commodityGranularity Granularity of the data for grouping
 * @param range Timeframe of when the data occurs
 */
export const convertAndMergeElectricity = (
  commodityData: { values: IMeasurement[]; unit: Unit; name: string },
  commodityGranularity: ChartDataGranularity,
  range: ITimeRange,
  nonOperatingLabel: string,
): ICombinedIntervalData => {
  // Generate timeseries for range at given granularity
  const start = new Date(range.startTime);
  const _e = new Date(range.endTime);
  const end = DateTime.fromJSDate(_e);

  let timeseries: Date[] = [];

  let current = DateTime.fromJSDate(start);

  while (current < end) {
    timeseries.push(current.toJSDate());
    current = luxonIncrementors[commodityGranularity](current);
  }

  let commodityOffset = calculateOffset(
    range,
    commodityData.values[0].timestamp,
    commodityGranularity,
  );

  const values = timeseries
    // map commodity data
    .map((date, index) => {
      const offsetIndex = index + commodityOffset;
      return {
        timestamp: date.valueOf(),
        value:
          offsetIndex >= 0 && offsetIndex < commodityData.values.length
            ? commodityData.values[offsetIndex].value
            : null,
      };
    });

  return {
    range,
    granularity: commodityGranularity,
    values,
    units: {
      value: getSymbolForUnit(commodityData.unit),
    },
    labels: {
      value: commodityData.name,
      nonOperatingHours: nonOperatingLabel,
    },
  };
};

export const formattedLegendDate = (
  date: string | Date,
  timezone?: string,
): string => {
  const _d = new Date(date);

  return dateToLocaleString(
    _d,
    undefined,
    timezone,
    FormatOptions.WEEKDAY_AND_DATE,
  ).replace(",", "");
};

/**
 * Creates an ICombinedIntervalData object shaped based on the provided energy data, time frame, and granularity
 * @param commodityData Energy array that is used as the base for merged data
 * @param commodityGranularity Granularity of the data for grouping
 * @param range Timeframe of when the data occurs
 */
export const convertAndMergeDailyDemand = (
  series1: ProfileSeries,
  series2: ProfileSeries,
  series3: ProfileSeries,
  series4: ProfileSeries,
  series5: ProfileSeries,
  series6: ProfileSeries,
  series7: ProfileSeries,
  commodityGranularity: ChartDataGranularity,
  range: ITimeRange,
  timezone?: string,
): ICombinedIntervalData => {
  // Generate timeseries for range at given granularity
  const start = new Date(range.startTime);
  const _e = new Date(range.endTime);
  const end = DateTime.fromJSDate(_e);

  let timeseries = [start];

  let current = DateTime.fromJSDate(start);

  while (current < end) {
    current = luxonIncrementors[commodityGranularity](current);
    timeseries.push(current.toJSDate());
  }

  const values = timeseries.map((date, index) => ({
    timestamp: date.valueOf(),
    day1: index < series1.values.length ? series1.values[index].value : null,
    day2: index < series2.values.length ? series2.values[index].value : null,
    day3: index < series3.values.length ? series3.values[index].value : null,
    day4: index < series4.values.length ? series4.values[index].value : null,
    day5: index < series5.values.length ? series5.values[index].value : null,
    day6: index < series6.values.length ? series6.values[index].value : null,
    day7: index < series7.values.length ? series7.values[index].value : null,
  }));

  return {
    range,
    granularity: commodityGranularity,
    values,
    units: {
      day1: getSymbolForUnit(series1.unit),
      day2: getSymbolForUnit(series2.unit),
      day3: getSymbolForUnit(series3.unit),
      day4: getSymbolForUnit(series4.unit),
      day5: getSymbolForUnit(series5.unit),
      day6: getSymbolForUnit(series6.unit),
      day7: getSymbolForUnit(series7.unit),
    },
    labels: {
      day1: formattedLegendDate(series1.range.startTime, timezone),
      day2: formattedLegendDate(series2.range.startTime, timezone),
      day3: formattedLegendDate(series3.range.startTime, timezone),
      day4: formattedLegendDate(series4.range.startTime, timezone),
      day5: formattedLegendDate(series5.range.startTime, timezone),
      day6: formattedLegendDate(series6.range.startTime, timezone),
      day7: formattedLegendDate(series7.range.startTime, timezone),
    },
  };
};

export const paddedTemperatureDomain = (
  domain: IMinMaxVals<number | null>,
  pad: number = 30,
): IMinMaxVals<number> => {
  const _min = Math.floor((domain.minima || 0) / 10) * 10 - pad;
  const _max = Math.ceil((domain.maxima || 100) / 10) * 10 + pad;
  return { minima: _min, maxima: _max };
};

/**
 * Converts a time range in ISO 8601 format (from midnight to midnight in the
 * building timezone, start inclusive - end exclusive) into corresponding dates
 * in the user's local timezone which are correctly formatted for the timepicker.
 *
 * The timepicker expects (and outputs) dates which are at noon local time.
 */
export const convertRangeToPickerDates = (
  range: ITimeRange,
  timezone: string,
): StartAndEndTimes => {
  const startTime = DateTime.fromISO(range.startTime).setZone(timezone);
  const endTime = DateTime.fromISO(range.endTime)
    .setZone(timezone)
    .minus({ days: 1 });
  return {
    startTime: new Date(startTime.year, startTime.month - 1, startTime.day, 12),
    endTime: new Date(endTime.year, endTime.month - 1, endTime.day, 12),
  };
};

/**
 * Converts a pair of dates from the timepicker into ISO 8601 format (midnight
 * to midnight in the building timezone, start inclusive - end exclusive).
 */

export const convertPickerDatesToTimeRange = (
  dates: StartAndEndTimes,
  timezone: string,
): ITimeRange => {
  const startTime = midnightInTimezone(dates.startTime, timezone).toISOString();
  const endTime = DateTime.fromJSDate(dates.endTime, { zone: timezone })
    .plus({ days: 1 })
    .startOf("day")
    .setZone("utc") // this makes sure we get a "Z" ISO string instead of "-07:00" or "-05:00" or whatever timezone we are in
    .toISO() as string;
  return { startTime, endTime };
};

/**
 * Converts a pair of dates from the timepicker into ISO 8601 format (times
 * based on current time window, start inclusive - end exclusive).
 */
export const convertPickerDatesToCustomTimeRange = (
  dates: StartAndEndTimes,
  timezone: string,
  startOffset: number,
  endOffset: number,
): ITimeRange => {
  const startTime = DateTime.fromJSDate(dates.startTime, { zone: timezone })
    .set({ hour: startOffset })
    .setZone("utc") // this makes sure we get a "Z" ISO string instead of "-07:00" or "-05:00" or whatever timezone we are in
    .toISO() as string;
  const endTime = DateTime.fromJSDate(dates.endTime, { zone: timezone })
    .plus({ days: 1 })
    .set({ hour: endOffset })
    .setZone("utc") // this makes sure we get a "Z" ISO string instead of "-07:00" or "-05:00" or whatever timezone we are in
    .toISO() as string;
  return { startTime, endTime };
};

// TODO: fix the link below... not sure if it's even valid anymore
//   Possibly this one?
//   https://measurabl.atlassian.net/wiki/spaces/PROD/pages/2579726642/Time+Range+-+Granularity+Options
/**
 * Given a start and end time, map to one of our fixed Ranges. This is so
 * we can do dropdown options, axis formats, etc. which are based on the
 * fixed bucket sizes of ChartDataRange.
 *
 * These mappings come from UX :
 * https://hatch-data.atlassian.net/wiki/spaces/UED/pages/108036113/Time+Range+-+Interval+Options
 */
export const chartDataRangeFromInterval = (interval: ITimeRange) => {
  // get the number of days between start and end.
  // fit that to UX's mapping.
  const dayDiff = daysBetweenDates(interval.startTime, interval.endTime);
  if (dayDiff <= 1) {
    return ChartDataRange.Today;
  } else if (dayDiff <= 14) {
    return ChartDataRange.SevenDays;
  } else if (dayDiff <= 45) {
    return ChartDataRange.ThirtyDays;
  } else if (dayDiff <= 180) {
    return ChartDataRange.NinetyDays;
  } else {
    return ChartDataRange.Year;
  }
};
export const getSymbolForAreaUnit = (unit: AreaUnit): string => {
  switch (unit) {
    case AreaUnit.SQFT:
      return "sqft";
    case AreaUnit.SQM:
      return "m²";
  }
};

/**
 * Returns the number of minutes in a given granularity. Note
 * that the nuber returned for MONTH is based on 30 days so it may
 * not match the month you care about!
 *
 * @param granularity
 */
export const chartGranularityAsMinutes = (
  granularity: ChartDataGranularity,
): number => {
  switch (granularity) {
    case ChartDataGranularity.Day:
      return 24 * 60;
    case ChartDataGranularity.FifteenMinute:
      return 15;
    case ChartDataGranularity.FiveMinute:
      return 5;
    case ChartDataGranularity.Hour:
      return 60;
    case ChartDataGranularity.Month:
      return 30 * 24 * 60;
    default:
      return 0;
  }
};

/**
 * Returns the number of seconds in a given granularity. Note that the number returned
 * for MONTH is based on 30 days, so take it with a grain of salt.
 *
 * @param granularity
 */
export const chartGranularityAsSeconds = (
  granularity: ChartDataGranularity,
): number => chartGranularityAsMinutes(granularity) * 60;

export class InvalidChartDataTypeSpecifier extends Error {}

/***
 * Map a ChartDataType from a given string.
 */
export const chartDataTypeFromString = (
  key: string,
): PointType | InvalidChartDataTypeSpecifier =>
  PointType[key as keyof typeof PointType] ??
  new InvalidChartDataTypeSpecifier();

export class InvalidPointTypeSpecifier extends Error {}

/**
 * This is a bandaid solution to prevent the demand chart from rendering a
 * misleading drop. A more complete solution will eventually be implemented
 * in readings-service where the chart data originates. Once that has been done,
 * this can be removed.
 */
export const setLastIntervalWithDataToNull = (
  values: IMeasurement[],
): IMeasurement[] => {
  const findLastIndexWithData = () => {
    for (let i = values.length - 1; i >= 0; i--) {
      if (values[i].value !== null) {
        return i;
      }
    }
    return -1;
  };

  const lastIndexWithData = findLastIndexWithData();

  if (lastIndexWithData < 0) {
    return values;
  } else {
    return [
      ...values.slice(0, lastIndexWithData),
      {
        timestamp: values[lastIndexWithData].timestamp,
        value: null,
      },
      ...values.slice(lastIndexWithData + 1),
    ];
  }
};

export const isFifteenMinOrHourGranularity = (
  granularity: ChartDataGranularity,
) => {
  return (
    granularity === ChartDataGranularity.FifteenMinute ||
    granularity === ChartDataGranularity.Hour
  );
};

// Maybe this can be added to equipment types package instead
export const getSymbolForPointType = (pointType: PointType, locale: Locale) => {
  const unit = getUnitForPointType(pointType, locale);

  if (!unit) {
    return undefined;
  } else {
    return getSymbolForUnit(unit);
  }
};

/**
 * Given domain 'a' whose 0 tick is at index 2, and domain 'b' whose 0 tick is
 * at index 0, generates new ticks for 'b' such that the index of its 0 tick
 * matches a's. This occurs when domain 'a' has a negative min, and domain 'b'
 * has a min >= 0. If 'a' does not have a negative min, returns default ticks.
 * NOTE: This has only been tested for a tickCount of 5
 * @param a The domain to match, should contain a negative min
 * @param b The domain with a min >= 0, that requires generated ticks
 */
export const generateTicksWithMatchingZero = (
  a: IMinMaxVals<number>,
  b: IMinMaxVals<number>,
  count = 5,
) => {
  const aMin = a.minima as number;
  const aMax = a.maxima as number;
  const bMin = b.minima as number;
  const bMax = b.maxima as number;

  if (aMin >= 0) {
    return getNiceTickValues([0, bMax], count);
  }

  const aTicks = getNiceTickValues([aMin, aMax], count);

  const indexOfZero = aTicks.findIndex(val => val === 0);

  const multiplier =
    indexOfZero === 0 ? 1 : indexOfZero === 1 ? 0.5 : indexOfZero;

  // Need additonal modification for small maxes
  const addend = bMax < 2 && multiplier >= 3 ? 1 : 0;

  const scaledMin = -1 * (bMax + addend) * multiplier;

  return getNiceTickValues([scaledMin, bMax], count, false);
};
