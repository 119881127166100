import { useIntl } from "react-intl";
import ReactTooltip from "react-tooltip";
import { AnnotationEventTooltip } from "src/components/app/ExplorerPage/ChartFactory/AnnotationEventTooltip";
import { AnnotationEvent } from "src/components/app/ExplorerPage/types";
import { VerticalLayout } from "src/components/common/Layout";
import { BuildingAnnotationReason } from "src/types/graphql";

type AnnotationsTooltipProps = {
  timezone?: string;
};

export const AnnotationsTooltip = ({
  timezone = "UTC",
}: AnnotationsTooltipProps) => {
  const translate = useIntl();

  // There are complications when using hooks within ReactTooltip so we're
  // using useIntl here to pre-translate, creating a translated object, then
  // passing to AnnotationEventTooltip.
  const translateReasons = () => {
    const reasons = Object.values(BuildingAnnotationReason);
    const translated: {
      [key: string]: string;
    } = {};
    reasons.forEach(reason => {
      translated[reason] = translate.formatMessage({
        id: `charts.explorer.overlays.annotationCategory.${reason}`,
      });
    });
    return translated;
  };

  return (
    <ReactTooltip
      id="annotationsTooltip"
      globalEventOff="scroll mousewheel"
      delayHide={200}
      type="light"
      clickable={true}
      border={true}
      effect="solid"
      getContent={content => {
        if (!content) {
          return;
        }

        const events: AnnotationEvent[] = JSON.parse(content);
        return (
          <VerticalLayout
            key={`tooltip-content-${events[0].id}`}
            childSpacing={4}
            style={{
              overflowY: "auto",
              overflowX: "clip",
              height: "180px",
              maxWidth: "360px",
            }}
          >
            {events.map(event =>
              AnnotationEventTooltip(event, timezone, translateReasons()),
            )}
          </VerticalLayout>
        );
      }}
    />
  );
};
