import React from "react";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components/macro";
import {
  MxReactIcon,
  ErrorTriangle,
  CircleCheck,
} from "src/componentLibrary/react/mx-icon-react";
import { FormattedMessage } from "react-intl";
import { AppColors } from "../Styling";

/**
 * The container that lets Toasts be shown.
 * There is one at the top level of AuthenticatedPage, so you should
 * rarely, if ever, need to add this yourself anywhere.
 */
export const ModifiedToastContainer = () => {
  return (
    <StyledToastContainer>
      <ToastContainer position={"bottom-right"} icon={false} />
    </StyledToastContainer>
  );
};

/**
 * Shows an Error style toast notification that can only show a
 * single text message.
 *
 * @param textId ID of the message to show in the Toast
 */
export const ErrorToast = (textId: string) => {
  return toast.error(
    <div data-testid="toast-error">
      <MxReactIcon Icon={ErrorTriangle} /> <FormattedMessage id={textId} />
    </div>,
  );
};
// just like above, but you can put what you want in it, like pluralized messages or cat pictures
export const ComposableErrorToast = (children: React.ReactNode) => {
  return toast.error(
    <div data-testid="toast-error">
      <MxReactIcon Icon={ErrorTriangle} /> {children}
    </div>,
  );
};

/**
 * Shows a toast notification that can only show a single text message.
 *
 * @param textId ID of the message to show in the Toast
 */
export const InfoToast = (textId: string) => {
  return toast.info(
    <div data-testid="toast-info">
      <MxReactIcon Icon={ErrorTriangle} /> <FormattedMessage id={textId} />
    </div>,
  );
};
// just like above, but you can put what you want in it, like pluralized messages or cat pictures
export const ComposableInfoToast = (
  children: React.ReactNode,
  options: ToastOptions = {},
) => {
  return toast.info(
    <div data-testid="toast-info">
      <MxReactIcon Icon={ErrorTriangle} /> {children}
    </div>,
    options,
  );
};

/**
 * Shows a success happy toast notification that can only display one line of text.
 *
 * @param textId ID of the message to show in the Toast
 * @param options options that toasts can take
 */
export const SuccessToast = (textId: string, options: ToastOptions = {}) => {
  return toast.success(
    <div data-testid="toast-success">
      <MxReactIcon Icon={CircleCheck} /> <FormattedMessage id={textId} />
    </div>,
    options,
  );
};

// just like above, but you can put what you want in it, like pluralized messages or cat pictures
export const ComposableSuccessToast = (
  children: React.ReactNode,
  options: ToastOptions = {},
) => {
  return toast.success(
    <div data-testid="toast-success">
      <MxReactIcon Icon={CircleCheck} /> {children}
    </div>,
    options,
  );
};

/**
 * A generic toast with no formatting or colors or icons that you can
 * fill with whatever your heart desires.
 *
 * @param children what goes in the Toast
 * @param options toasty options
 * @returns
 */
export const ComposablePlainToast = (
  children: React.ReactNode,
  options: ToastOptions = {},
) => {
  return toast(<div>{children}</div>, options);
};

const StyledToastContainer = styled.div`
  .Toastify__toast--info {
    background: ${AppColors.semantic.blue["dark-sky"]};
    color: ${AppColors.neutral["white"]};
  }

  .Toastify__toast--success {
    background: ${AppColors.primary["msr-green"]};
    color: ${AppColors.neutral["white"]};
  }

  .Toastify__toast--error {
    background: ${AppColors.semantic.red.red};
    color: ${AppColors.neutral["white"]};
  }

  .Toastify__toast-body {
    font-size: 18px;
    line-height: 24px;
    padding: 24px;
    font-weight: 500;
    display: flex;
  }

  svg {
    margin-right: 8px;
  }
`;

export default ModifiedToastContainer;
