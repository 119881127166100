import {
  ChartDataGranularity,
  ChartDataRange,
  ITimeRange,
} from "src/types/charting";
import { dateToLocaleString, FormatOptions } from "@hatchdata/intl-formatter";

/**
 * Returns granularity options based on a time frame.
 * @param range Timeframe used to determine available granularity
 * @param translate A function that will translate the i18n message ID hard coded inside
 * @param supportsHourly Does this chart / building have hourly baselines?
 */
export const getGranularityOptions = (
  range: ChartDataRange,
  translate: (args: { id: string }) => string,
  supportsHourly: boolean = false,
): { [key: string]: string } => {
  const options: { [key: string]: string } = {};
  const INTERVAL = translate({ id: "dataGranularity.interval" });
  if (
    supportsHourly &&
    (range === ChartDataRange.Today ||
      range === ChartDataRange.SevenDays ||
      range === ChartDataRange.ThirtyDays)
  ) {
    options[ChartDataGranularity.Hour] = `${INTERVAL}: ${translate({
      id: "dataGranularity.hourly",
    })}`;
  }

  options[ChartDataGranularity.Day] = `${INTERVAL}: ${translate({
    id: "dataGranularity.daily",
  })}`;

  return options;
};

export function setTrainingPeriodAdjustedForTZ(
  trainingPeriod: ITimeRange | { startTime: undefined; endTime: undefined },
  timezone: string,
): ITimeRange | { startTime: undefined; endTime: undefined } {
  if (trainingPeriod?.startTime && trainingPeriod?.endTime) {
    return {
      startTime: formattedDate(new Date(trainingPeriod.startTime), timezone),
      endTime: formattedDate(new Date(trainingPeriod.endTime), timezone),
    };
  } else {
    return { startTime: undefined, endTime: undefined };
  }
}

/*
 * This function is used frequently in places other than baselines.
 * We should find a better place for it to live, where it makes sense
 * that everyone can use it!
 *  */
export const formattedDate = (time: Date, timezone: string): string => {
  return dateToLocaleString(
    time,
    undefined,
    timezone,
    FormatOptions.DATE_SHORT,
  );
};
