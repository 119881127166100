import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling";

// min-width required because of Firefox adhering (correclty)
// to the Felxbox spec.
const DefaultContentWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  min-width: 0;
  background-color: ${AppColors.neutral["light-gray-9"]};
`;
export default DefaultContentWrapper;
