import React, { useState } from "react";
import { DataKey } from "recharts/types/util/types";
import { ChartRenderData } from "src/types/charting";
import { LegendCheckboxItem } from "src/components/common/ChartParts";
import { truncateStringEnd } from "src/helpers/strings";
import { AppColors } from "src/components/common/Styling";
import { useIntl } from "react-intl";

// render some legends based on the charts maybe?
export const Legends = (
  chartData: ChartRenderData,
  onClick: (dataKey: DataKey<string>) => void,
  additionalLegendItems?: {
    operatingHours?: {
      visible: boolean;
      inactive: boolean;
      onClick: (inactive: boolean) => void;
    };
  },
): React.ReactNode[] => {
  const translate = useIntl();

  const legendCollection: React.ReactNode[] = [];
  chartData.forEach((val, key) => {
    const _n = val.chartProps.name ? val.chartProps.name.toString() : "";
    const _color = val.chartProps.stroke
      ? val.chartProps.stroke.toString()
      : AppColors.neutral["light-navy-1"];
    legendCollection.push(
      <LegendCheckboxItem
        key={key}
        inactive={!val.visible}
        color={_color}
        text={`${truncateStringEnd(_n)} (${val.chartProps.unit})`}
        title={_n}
        stroked={val.isPastComparison}
        onClick={() => onClick(val.chartProps.dataKey || "")}
      />,
    );
  });

  if (additionalLegendItems?.operatingHours?.visible) {
    legendCollection.push(
      <LegendCheckboxItem
        key={"operatingSchedule"}
        inactive={additionalLegendItems?.operatingHours.inactive}
        color={"rgba(145,171,233,0.2)"}
        text={translate.formatMessage({
          id: "charts.common.non_operating_hours",
        })}
        title={translate.formatMessage({
          id: "charts.common.non_operating_hours",
        })}
        onClick={() => {
          additionalLegendItems?.operatingHours?.onClick(
            !additionalLegendItems?.operatingHours.inactive,
          );
        }}
      />,
    );
  }
  return legendCollection;
};
