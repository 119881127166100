import styled from "styled-components/macro";

import {
  MxReactIcon,
  ThreeDotMenu,
} from "src/componentLibrary/react/mx-icon-react";
import { AppColors } from "../Styling";

const HitTarget = styled.div`
  height: 32px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IDotsIcon {
  onClick: () => void;
  color?: string;
}
export const DotsIcon: React.FC<IDotsIcon> = ({
         onClick,
         color = AppColors.neutral.navy,
       }) => (
         <HitTarget data-testid="dot-menu-icon" onClick={onClick}>
           <MxReactIcon Icon={ThreeDotMenu} color={color} size="m" />
         </HitTarget>
       );
