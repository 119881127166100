import styled from "styled-components/macro";
import { LayoutProps, propsToPx } from './Layout';

/**
 * Will stack direct children vertically, and keep them left-aligned.
 * If you need right or centered alignment, make a new component instead
 * of adding props to this one pretty please!
 */
export const VerticalLayout = styled.div<LayoutProps>`
  display: flex;
  position: relative;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  & > * {
    margin-bottom: ${props => propsToPx(props.childSpacing)};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default VerticalLayout;