import styled from "styled-components/macro";
import { BoxProps } from "./Box";
import { propsToPx } from "./Layout";

/**
 * Same as the Box component except it has the flex property
 */
export const CenteredFlexBox = styled.div<BoxProps>`
  margin: ${props => propsToPx(props.margin)};
  padding: ${props => propsToPx(props.padding)};
  width: 100%;
  display: flex;
  justify-content: center;
  /* min-height: 95vh; */
`;
export default CenteredFlexBox;
