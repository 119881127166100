import {
  BuildingAnnotationReason,
  ExceptionOperatingTimeBlock,
  ExceptionType,
} from "./graphql";

export type OperatingScheduleDays = {
  [key in DayOfWeek]: DailyOperatingTimeBlock | null;
};

export interface OperatingSchedule extends OperatingScheduleDays {
  scheduleId: string | null;
}

export type ScheduleException = {
  id: string;
  title: string;
  reasons: BuildingAnnotationReason[];
  notes?: string;
  exceptionDate: string;
  exceptionEndDate?: string;
  exceptionTimeRanges?: ExceptionOperatingTimeBlock[];
};

export interface DailyOperatingTimeBlock {
  startHour: number;
  endHour: number;
  startMinute: number;
  endMinute: number;
}

export interface OperatingPeriodInput {
  startDay: string;
  startTime: string;
  endDay: string;
  endTime: string;
}

export interface OperatingScheduleEditVariables {
  scheduleId: string;
  operatingPeriods: OperatingPeriodInput[];
}

export interface OperatingScheduleCreateVariables {
  buildingId: string;
  operatingPeriods: OperatingPeriodInput[];
}

export type OperatingScheduleInput = {
  [key in DayOfWeek]: {
    checked: boolean;
    start: Date | undefined;
    end: Date | undefined;
    isAllDay: boolean;
  };
};

export enum DayOfWeek {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}

export const DAYS_OF_WEEK: DayOfWeek[] = [
  DayOfWeek.monday,
  DayOfWeek.tuesday,
  DayOfWeek.wednesday,
  DayOfWeek.thursday,
  DayOfWeek.friday,
  DayOfWeek.saturday,
  DayOfWeek.sunday,
];

export type Holiday = {
  name: string;
  observed: string;
};
