import { OverlayFormData } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { DateTime } from "luxon";
import { AnnotationMutationVariables } from "src/components/app/ExplorerPage/OverlayStuff/EditOverlayStuff/types";

/**
 * Maps form data to the required GraphQL variables for saving the
 * overlay.
 *
 * @param initialInput - the form data in the shape of ExceptionFormData; note that start and
 *   end dates are handled separately for now, fix this to make it cleaner later.
 * @param localEndDate - local (changed?) DateTime object, because it might have
 *   changed in the form and we haven't jumped through the hoops to hammer it into
 *   the right format yet.
 * @param localStartDate - same description as localEndDate
 */
export function mapFormDataToGraphQLVariablesForCreateAnnotation(
  initialInput: OverlayFormData,
  localEndDate: Date,
  localStartDate: Date,
): AnnotationMutationVariables | false {
  const tz = initialInput.timezone;
  if (
    initialInput.annotationCategories.length > 0 &&
    initialInput.overlayType !== null
  ) {
    const vars: AnnotationMutationVariables = {
      buildingId: initialInput.buildingId ?? "",
      endTime: DateTime.fromJSDate(localEndDate)
        .setZone(tz, { keepLocalTime: true })
        .toUTC()
        .toISO() as string,
      notes: initialInput.note,
      reasons: initialInput.annotationCategories,
      startTime: DateTime.fromJSDate(localStartDate)
        .setZone(tz, { keepLocalTime: true })
        .toUTC()
        .toISO() as string,
      title: initialInput.title,
      type: initialInput.annotationType!,
    };
    return vars;
  }
  return false;
}
