import React from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { MxReactIcon, Info } from "src/componentLibrary/react/mx-icon-react";

import { InlineH4 } from "src/components/common/Typography";
import { AppColors } from "../Styling";

const SmallMessageBarWrapper = styled.div`
  background-color: ${AppColors.neutral["light-gray-9"]};
  border: 1px solid ${AppColors.semantic.blue.sky};
  padding: 8px 20px;
  display: flex;
  align-items: center;
`;

type SmallMessageBarProps = {
  messageID: string;
  values?: { [key: string]: string | number };
};

export const SmallMessageBar: React.FC<SmallMessageBarProps> = props => {
  const { messageID, values } = props;
  return (
    <SmallMessageBarWrapper>
      <MxReactIcon
        Icon={Info}
        size="s"
        color={AppColors.semantic.blue.sky}
        style={{ marginRight: "8px" }}
      />
      <InlineH4>
        <FormattedMessage id={messageID} values={values} />
      </InlineH4>
    </SmallMessageBarWrapper>
  );
};
