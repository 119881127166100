import CustomSelect from "./CustomSelect";

export interface BasicSelectProps {
  values: Record<PropertyKey, string>;
  value?: string | undefined;
  onChange: (selectedItem: string) => void;
  disabled?: boolean;
  width?: string;
  dataTestId?: string;
}
export const BasicSelect = (props: BasicSelectProps) => {
  const { values, value, onChange = () => undefined, disabled, width } = props;
  const renderer = (val: string | undefined) => {
    return val ? values[`${val}`] : "undefined";
  };
  return (
    <CustomSelect<string>
      values={Object.keys(values)}
      value={value}
      onChange={onChange}
      renderSelectedItem={renderer}
      renderItem={renderer}
      disabled={disabled}
      width={width}
    />
  );
};

export interface BetterBasicSelectProps {
  values: Record<PropertyKey, string>;
  value?: string | undefined;
  onChange: (selectedItem: string) => void;
  disabled?: boolean;
  width?: string;
  dataTestId?: string;
}
export const BetterBasicSelect = (props: BetterBasicSelectProps) => {
  const { values, value, onChange = () => undefined } = props;
  const renderer = (val: string | undefined) => {
    return val ? values[`${val}`] : "whut";
  };
  return (
    <CustomSelect<string>
      values={Object.keys(values)}
      value={value}
      onChange={onChange}
      renderSelectedItem={renderer}
      renderItem={renderer}
    />
  );
};

export default BasicSelect;
