import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { AppColors } from "../Styling";

export const HyperLink = styled.a`
  color: ${AppColors.neutral.navy};
  font-size: 16px;
  font-weight: 300;
  text-decoration: none;
  & :visited {
    color: ${AppColors.neutral.navy};
  }
`;

export const HyperLink2 = styled.a`
  color: ${AppColors.primary["msr-green"]};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  & :visited {
    color: ${AppColors.primary["msr-green"]};
  }
`;

export const Link1 = styled(Link)`
  color: ${AppColors.neutral.navy};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  & :visited {
    color: ${AppColors.neutral.navy};
  }
  &:after,
  a:visited:after {
    content: "";
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    background: ${AppColors.neutral["light-navy-1"]};
    transition: 0.2s;
  }
  &:hover:after,
  &:visited:hover:after {
    width: 100%;
  }
`;

export const Link2 = styled(Link)`
  color: ${AppColors.primary["msr-green"]};
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  & :visited {
    color: ${AppColors.primary["msr-green"]};
  }
`;

export const ButtonLink = styled(Link)`
  padding: 16px;
  color: ${AppColors.neutral["light-gray-9"]};
  background-color: ${AppColors.primary["msr-green"]};
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  min-width: 300px;
  width: 20%;
  max-width: 600px;
  outline: none;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  &:visited {
    color: ${AppColors.neutral["light-gray-9"]};
  }
  &:hover {
    color: ${AppColors.neutral["light-gray-9"]};
  }
  &:active {
    color: ${AppColors.neutral["light-gray-9"]};
  }
  &:focus {
    color: ${AppColors.neutral["light-gray-9"]};
  }
`;
