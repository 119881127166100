/**
 * Will chop off  the end of a string and add some dots if it is longer than the length
 * that you desire the string to be. Useful for legends and asset selectors!
 *
 * If your string is longer than `length` characters, it will make the string 4 characters
 * shorter, and append three periods. So it gives you a string that is actually one less
 * than `length` if it does truncate. Why? No real reason.
 *
 * @param str - the string to maybe truncate
 * @param length - the number of characters the string has to be longer than to get truncated
 */
export const truncateStringEnd = (str: string, length: number = 24): string => {
  if (str.length > length) {
    return `${str.substring(0, length - 4)}...`;
  }
  return str;
};
