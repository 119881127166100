import React, { useEffect, useState } from "react";
import DayPicker, { DayModifiers } from "react-day-picker"; // https://react-day-picker.js.org/
import "react-day-picker/lib/style.css";
import { FormattedMessage } from "react-intl";
import { ChartDataRange } from "src/types/charting";
import { H5 } from "src/components/common/Typography";
import {
  MxReactIcon,
  Calendar,
} from "src/componentLibrary/react/mx-icon-react";
import {
  getLocalDateFormat,
  parseDate,
  rangeToLimitForTimezone,
} from "src/helpers/dates";
import {
  DateUtilRange,
  ITimeRangeChange,
  PickerCalendar,
} from "./TimePickerHolder";
import { DateTime } from "luxon";
import { isDayBefore, isSameDay, isSameMonth } from "./helpers";
import {
  PickerBorderButton,
  PickerButton,
  PickerButtonContainer,
  PickerCalendarContainer,
  PickerCalendarHeader,
  PickerDateDisplay,
  PickerDateInput,
  PickerGreyBorderButton,
  TimeRangePickerOuter,
  TimeRangePickerWrapper,
} from "./RangePickerParts";
import { AppColors } from "../Styling";
import { dateToLocaleString } from "@hatchdata/intl-formatter";

interface ITimeRangePickerProps {
  /** What timezone should the dates picked be in */
  timezone: string;
  /** Handler for when user confirms selected dates */
  onChange: (data: ITimeRangeChange) => void;
  /** Pre-selected range for the picker: start and end dates will be calculated from this if none are provided */
  selectedRange?: ChartDataRange;
  /** Pre-selected start date for the picker */
  startDate?: Date;
  /** Pre-selected end date for the picker */
  endDate?: Date;
  /** Handler for when user cancels selection */
  onCancel?: () => void;
  /** Number of days of data a user can select : will default to 365 if not set */
  limit?: number;
  /** Can the user pick today? */
  allowToday?: boolean;
  /**
   * Should we show "Last 24 hours" in sidebar
   * Note: this will break some charts, so use wisely!
   * You will almost always want `allowToday` if you
   * set this to true
   */
  showPast24?: boolean;
  /**
   * The total number of years prior a user can enter dates for. Defaults to 3 if a value is not provided.
   */
  maxYears?: number;
}

// calculate the allowed months / times we can select
const datesFromChartRange = (
  range: ChartDataRange,
  timezone: string,
  allowToday: boolean,
): DateUtilRange => {
  const endTime =
    range === ChartDataRange.Today
      ? DateTime.local().setZone(timezone)
      : DateTime.local()
          .setZone(timezone)
          .startOf("day")
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
          .minus({ day: allowToday ? 0 : 1 });

  const startTime = endTime.minus(
    range === ChartDataRange.Today ? { hours: 24 } : { days: range - 1 },
  );

  const _r =
    range === ChartDataRange.Today
      ? { from: startTime.toJSDate(), to: endTime.toJSDate() }
      : {
          from: new Date(
            startTime.year,
            startTime.month - 1,
            startTime.day,
            startTime.hour,
            startTime.minute,
          ),
          to: new Date(
            endTime.year,
            endTime.month - 1,
            endTime.day,
            endTime.hour,
            endTime.minute,
          ),
        };
  return _r;
};

// set up an initial set of dates based on what we got sent in.
// If the inputs don't make any sense, do your best to return something
// rational, or at least not awful.
const initialSelectedDates = (
  timezone: string,
  allowToday: boolean,
  range?: ChartDataRange,
  startDate?: Date,
  endDate?: Date,
): DateUtilRange => {
  if (startDate || endDate) {
    return { from: startDate, to: endDate };
  }
  if (range) {
    return datesFromChartRange(range, timezone, allowToday);
  }
  // nothing doing.
  return { from: undefined, to: undefined };
};

const TimeRangePicker: React.FC<ITimeRangePickerProps> = props => {
  const {
    onCancel,
    onChange,
    selectedRange,
    startDate,
    endDate,
    timezone,
    limit = 365,
    allowToday = false,
    showPast24 = false,
    maxYears = 3,
  } = props;

  // NOTE: times and so on are mangled for the timezone param later,
  // so do not be alarmed that these are "bare" local dates.
  const defaultRangeLimitAfter = allowToday
    ? new Date()
    : rangeToLimitForTimezone(timezone);
  const defaultRangeLimitBefore = new Date();

  // we allow users to go back three years and one day (for REASONS, okay?)
  defaultRangeLimitBefore.setFullYear(
    defaultRangeLimitBefore.getFullYear() - maxYears,
  );
  defaultRangeLimitBefore.setDate(defaultRangeLimitBefore.getDate() - 1);

  // which input is focused at the moment??
  const [focusedInput, setFocusedInput] = useState<"start" | "end">("start");

  /** The "bucket size" that is selected (seven days, thirty days, etc.) */
  const [currentRange, setCurrentRange] = useState(selectedRange);
  /** the actual dates that are picked */
  const [selectedDates, setSelectedDates] = useState<DateUtilRange>(
    initialSelectedDates(
      timezone,
      allowToday,
      selectedRange,
      startDate,
      endDate,
    ),
  );

  /** Dates for custom input */
  const [customDateRange, setCustomDateRange] = useState<{
    from: string;
    to: string;
  }>({
    from: selectedDates.from ? dateToLocaleString(selectedDates.from) : "",
    to: selectedDates.to ? dateToLocaleString(selectedDates.to) : "",
  });

  const [rangeLimitBefore, setRangeLimitBefore] = useState(
    defaultRangeLimitBefore,
  );
  const [rangeLimitAfter, setRangeLimitAfter] = useState(
    defaultRangeLimitAfter,
  );

  // called when user picks 'custom'
  const handleCustomSelect = (): void => {
    // what to do!? UX says reset everything!
    setCurrentRange(undefined);
    setSelectedDates({ from: undefined, to: undefined });
    setCustomDateRange({ from: "", to: "" });
    // reset ranges if we were limited
    if (limit) {
      setRangeLimitAfter(defaultRangeLimitAfter);
      setRangeLimitBefore(defaultRangeLimitBefore);
    }
  };
  // called when user picks a pre-defined Range
  const handleRangeSelect = (range: ChartDataRange): void => {
    setCurrentRange(range);
    setSelectedDates(datesFromChartRange(range, timezone, allowToday));
  };
  // called when the user clicks the 'Apply' button
  const handleChangeCommit = () => {
    /**
     * Returns either aDate or the minimum allowed date, whichever is more recent.
     * @param aDate the date to test
     * @returns either the date provided or the minimum date if aDate is older.
     */
    const dateOrMinimumDate = (aDate: Date) => {
      const minimumDate = DateTime.fromJSDate(new Date())
        .minus({ years: maxYears })
        .minus({ days: 1 })
        .toJSDate();
      return aDate.valueOf() >= minimumDate.valueOf() ? aDate : minimumDate;
    };
    if (selectedDates.from && selectedDates.to) {
      const from = dateOrMinimumDate(selectedDates.from);
      const to = dateOrMinimumDate(selectedDates.to);
      onChange({
        selectedRange: currentRange,
        selectedDates: { from, to },
      });
    }
  };
  // sets class names on the calendar so it can be pretty
  const modifiers = {
    highlighted:
      selectedDates.from && selectedDates.to
        ? { from: selectedDates.from, to: selectedDates.to }
        : [],
    start: selectedDates.from,
    end: selectedDates.to,
    insideLimit: { from: rangeLimitBefore, to: rangeLimitAfter },
  };

  // disables days based on a limit
  const updateLimitBoundaries = (range: DateUtilRange): void => {
    if (limit && range.from) {
      const _from = range.from;
      const _to = range.to || range.from;
      const _a = new Date(_from);
      const _b = new Date(_to);
      const _l = limit - 1;
      _a.setDate(_from.getDate() + _l);
      _b.setDate(_to.getDate() - _l);

      const _newAfter =
        _a < defaultRangeLimitAfter ? _a : defaultRangeLimitAfter;
      setRangeLimitAfter(_newAfter);
      const _newBefore =
        _b > defaultRangeLimitBefore ? _b : defaultRangeLimitBefore;
      setRangeLimitBefore(_newBefore);
    }
  };
  useEffect(() => updateLimitBoundaries(selectedDates), []);

  useEffect(() => {
    updateLimitBoundaries({ from: startDate, to: endDate });
  }, [limit]);

  /**
   * Updates the date fields to use the same start and end date and focuses
   * input on the end date
   */
  const setDatesToSameDay = (day: Date) => {
    focusEndInput();
    const _n: DateUtilRange = {
      from: day,
      to: day,
    };
    setSelectedDates(_n);
    updateLimitBoundaries(_n);

    // we're in custom mode, so update the custom entry fields
    if (!currentRange) {
      setCustomDateRange({
        from: _n.from ? dateToLocaleString(_n.from) : "",
        to: _n.to ? dateToLocaleString(_n.to) : "",
      });
    }
  };

  // called when the user interacts with the calendar
  const calendarSelect = (
    day: Date,
    modifiers: DayModifiers,
    event:
      | React.MouseEvent<HTMLDivElement>
      | React.FocusEvent<HTMLInputElement>,
  ) => {
    event.persist();
    event.nativeEvent.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    // if there are limits, we want to handle "disabled" days
    // if they are inside the default boundaries so a user can
    // reset the range around a new date with a click
    if (modifiers.disabled) {
      return;
    }
    // this day is outside the limit, so create a new range based on this selection
    if (!modifiers.insideLimit) {
      setDatesToSameDay(day);
      return;
    }
    if (!selectedDates.from || !selectedDates.to) {
      setDatesToSameDay(day);
      return;
    }
    // these will come in handy a lot
    const _selectedBeforeStart = isDayBefore(day, selectedDates.from);
    const _selectedAfterEnd = isDayBefore(selectedDates.to, day);
    let newRange: DateUtilRange;

    /* When a single Day is selected: */
    if (isSameDay(selectedDates.to, selectedDates.from)) {
      /* A: Click further in past than selected single date */
      if (_selectedBeforeStart) {
        newRange = {
          from: day,
          to: selectedDates.to,
        };
        focusEndInput();
        /* B: Click further in future than selected single date */
      } else {
        /* B.2: END has focus
             existing range is expanded with newly selected date as end date.
             focus moves to START
          */
        newRange = {
          from: selectedDates.from,
          to: day,
        };
        focusStartInput();
      }
      setSelectedDates(newRange);
      updateLimitBoundaries(newRange);
      setCustomDateRange({
        from: newRange.from ? dateToLocaleString(newRange.from) : "",
        to: newRange.to ? dateToLocaleString(newRange.to) : "",
      });
      return;
    }
    /* When a range is selected: */
    /* A: Click outside of range further in past than start date */
    if (_selectedBeforeStart) {
      /* A.1: START has focus
      existing range is expanded with newly selected date as start date.
      focus moves to END
      */
      if (focusedInput === "start") {
        focusEndInput();
        newRange = {
          from: day,
          to: selectedDates.to,
        };
      } else {
        /* A.2: END has focus
      existing range is cleared and the newly selected date is a single-day range.
      focus moves to END
      */
        newRange = {
          from: day,
          to: day,
        };
        focusEndInput();
      }
    } else if (_selectedAfterEnd) {
      /* B: Click outside of range further in future than end date */
      /* B.1: START has focus
      existing range is cleared and the newly selected date is a single-day range.
      focus moves to END
      */
      if (focusedInput === "start") {
        newRange = {
          from: day,
          to: day,
        };
        focusEndInput();
      } else {
        /* B.1: END has focus
      existing range is expanded with newly selected date as end date.
      focus moves to START
      */
        newRange = {
          from: selectedDates.from,
          to: day,
        };
        focusStartInput();
      }
    } else {
      /* C: Click inside range  */
      /* C.1: START has focus
      existing range is contracted with newly selected date as start date.
      focus moves to END
      */
      if (focusedInput === "start") {
        newRange = {
          from: day,
          to: selectedDates.to,
        };
        focusEndInput();
      } else {
        /* C.1: END has focus
      existing range is contracted with newly selected date as end date.
      focus moves to START
      */
        newRange = {
          from: selectedDates.from,
          to: day,
        };
        focusStartInput();
      }
    }

    setSelectedDates(newRange);
    updateLimitBoundaries(newRange);
    setCurrentRange(undefined);
    setCustomDateRange({
      from: newRange.from ? dateToLocaleString(newRange.from) : "",
      to: newRange.to ? dateToLocaleString(newRange.to) : "",
    });
  };

  const lastMonth = (): Date => {
    const _m = DateTime.local()
      .setZone(timezone)
      .minus({ month: 1 });
    return _m.toJSDate();
  };

  const leftCalendarMonth = (): Date => {
    if (selectedDates.from && selectedDates.to) {
      if (!isSameMonth(selectedDates.from, selectedDates.to)) {
        return selectedDates.from;
      } else {
        const _m = DateTime.fromJSDate(selectedDates.from, {
          zone: timezone,
        }).minus({ month: 1 });
        return _m.toJSDate();
      }
    }
    return lastMonth();
  };

  const rightCalendarMonth = () => {
    if (selectedDates.to) {
      return selectedDates.to;
    }
    return new Date();
  };

  const allowRange = (range: ChartDataRange): boolean => {
    if (limit) {
      return range <= limit;
    }
    return true;
  };

  /**
   * Updates the customDateRange and selectedDate objects on value change for a date
   * entry box.
   * @param customDates
   */
  const updateCustomDates = (customDates: { from: string; to: string }) => {
    setCustomDateRange({
      from: customDates.from,
      to: customDates.to,
    });

    const fromDate = parseDate(customDates.from);
    const toDate = parseDate(customDates.to);

    setSelectedDates({
      from:
        fromDate &&
        fromDate.getFullYear() >= defaultRangeLimitBefore.getFullYear()
          ? DateTime.fromJSDate(fromDate)
              .setZone(timezone)
              .startOf("day")
              .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
              .toJSDate()
          : undefined,
      to:
        toDate && toDate.getFullYear() >= defaultRangeLimitBefore.getFullYear()
          ? DateTime.fromJSDate(toDate)
              .setZone(timezone)
              .startOf("day")
              .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
              .toJSDate()
          : undefined,
    });
  };

  /**
   * Returns true if the value falls in the range of allowable dates.
   * @param value
   */
  const customDateWithinRange = (value: string): boolean => {
    const date = new Date(value);

    return (
      date.getTime() >= defaultRangeLimitBefore.getTime() &&
      date.getTime() <= defaultRangeLimitAfter.getTime()
    );
  };

  /**
   * Returns true if the value falls in the range of the inside limit.
   * @param value
   */
  const customDateWithinLimit = (value: string): boolean => {
    const date = new Date(value);

    return (
      date.getTime() >= modifiers.insideLimit.from.getTime() &&
      date.getTime() <= modifiers.insideLimit.to.getTime()
    );
  };

  const focusEndInput = () => setFocusedInput("end");
  const focusStartInput = () => setFocusedInput("start");

  /** Submits the form if the user hits the enter key */
  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 13) {
      handleChangeCommit();
    }
  };

  return (
    <TimeRangePickerOuter>
      <TimeRangePickerWrapper>
        <PickerButtonContainer>
          <div>
            <H5>
              <FormattedMessage id="dataRange.dateRangeLabel" />
            </H5>
            <PickerButton
              onClick={handleCustomSelect}
              selected={currentRange === undefined}
            >
              <FormattedMessage id="dataRange.custom" />
            </PickerButton>
            {showPast24 && (
              <PickerButton
                onClick={() => handleRangeSelect(ChartDataRange.Today)}
                selected={currentRange === ChartDataRange.Today}
              >
                <FormattedMessage id="dataRange.today" />
              </PickerButton>
            )}
            {allowRange(ChartDataRange.SevenDays) && (
              <PickerButton
                onClick={() => handleRangeSelect(ChartDataRange.SevenDays)}
                selected={currentRange === ChartDataRange.SevenDays}
              >
                <FormattedMessage id="dataRange.sevenDays" />
              </PickerButton>
            )}
            {allowRange(ChartDataRange.ThirtyDays) && (
              <PickerButton
                onClick={() => handleRangeSelect(ChartDataRange.ThirtyDays)}
                selected={currentRange === ChartDataRange.ThirtyDays}
              >
                <FormattedMessage id="dataRange.thirtyDays" />
              </PickerButton>
            )}
            {allowRange(ChartDataRange.NinetyDays) && (
              <PickerButton
                onClick={() => handleRangeSelect(ChartDataRange.NinetyDays)}
                selected={currentRange === ChartDataRange.NinetyDays}
              >
                <FormattedMessage id="dataRange.ninetyDays" />
              </PickerButton>
            )}
            {allowRange(ChartDataRange.Year) && (
              <PickerButton
                onClick={() => handleRangeSelect(ChartDataRange.Year)}
                selected={currentRange === ChartDataRange.Year}
              >
                <FormattedMessage id="dataRange.twelveMonths" />
              </PickerButton>
            )}
          </div>
          <div>
            {/*
              WARNING! Our monitoring tool (hatch-mon) looks at the button below
              and clicks it during it's run. If you change the testId or remove
              the button, it will think the site is down. Please update hatch-mon
              (or ask how / someone else to) as well if you remove this.
              */}
            <PickerBorderButton
              onClick={handleChangeCommit}
              data-testid="date-picker-apply"
            >
              <FormattedMessage id="common.button.labels.apply" />
            </PickerBorderButton>
            <PickerGreyBorderButton onClick={onCancel}>
              <FormattedMessage id="common.button.labels.cancel" />
            </PickerGreyBorderButton>
          </div>
        </PickerButtonContainer>
        <PickerCalendarContainer>
          <PickerCalendar data-testid="calendar1">
            <div>
              <PickerCalendarHeader short={false}>
                <H5>
                  <FormattedMessage id="common.label.startDate" />
                </H5>
                <PickerDateDisplay
                  onClick={focusStartInput}
                  focused={focusedInput === "start"}
                >
                  <MxReactIcon
                    Icon={Calendar}
                    color={AppColors.neutral.navy}
                    style={{ marginRight: "8px" }}
                  />
                  {currentRange && selectedDates.from ? (
                    dateToLocaleString(selectedDates.from)
                  ) : (
                    <PickerDateInput
                      maxLength={10}
                      placeholder={getLocalDateFormat()}
                      value={customDateRange.from}
                      onChange={e =>
                        updateCustomDates({
                          from: e.currentTarget.value,
                          to: customDateRange.to,
                        })
                      }
                      tabIndex={125}
                      onKeyDown={handleEnterKey}
                      onBlur={e =>
                        parseDate(customDateRange.from) &&
                        customDateWithinRange(customDateRange.from)
                          ? calendarSelect(
                              parseDate(customDateRange.from)!,
                              {
                                today: allowToday,
                                outside: false,
                                insideLimit: customDateWithinLimit(
                                  customDateRange.from,
                                ),
                              },
                              e,
                            )
                          : setCustomDateRange({
                              from: "",
                              to: customDateRange.to,
                            })
                      }
                    />
                  )}
                </PickerDateDisplay>
              </PickerCalendarHeader>
            </div>
            <DayPicker
              month={leftCalendarMonth()}
              disabledDays={{
                after: defaultRangeLimitAfter,
                before: defaultRangeLimitBefore,
              }}
              fromMonth={defaultRangeLimitBefore}
              toMonth={defaultRangeLimitAfter}
              selectedDays={[selectedDates.from, selectedDates.to]}
              modifiers={modifiers}
              onDayClick={calendarSelect}
            />
          </PickerCalendar>
          <PickerCalendar data-testid="calendar2">
            <div>
              <PickerCalendarHeader short={false}>
                <H5>
                  <FormattedMessage id="common.label.endDate" />
                </H5>
                <PickerDateDisplay
                  onClick={focusEndInput}
                  focused={focusedInput === "end"}
                >
                  <MxReactIcon
                    Icon={Calendar}
                    color={AppColors.neutral.navy}
                    style={{ marginRight: "8px" }}
                  />
                  {currentRange && selectedDates.to ? (
                    dateToLocaleString(selectedDates.to)
                  ) : (
                    <PickerDateInput
                      maxLength={10}
                      placeholder={getLocalDateFormat()}
                      value={customDateRange.to}
                      onChange={e =>
                        updateCustomDates({
                          from: customDateRange.from,
                          to: e.currentTarget.value,
                        })
                      }
                      tabIndex={126}
                      onKeyDown={handleEnterKey}
                      onBlur={e =>
                        parseDate(customDateRange.to) &&
                        customDateWithinRange(customDateRange.to)
                          ? calendarSelect(
                              parseDate(customDateRange.to)!,
                              {
                                today: allowToday,
                                outside: false,
                                insideLimit: customDateWithinLimit(
                                  customDateRange.to,
                                ),
                              },
                              e,
                            )
                          : setCustomDateRange({
                              from: customDateRange.from,
                              to: "",
                            })
                      }
                    />
                  )}
                </PickerDateDisplay>
              </PickerCalendarHeader>
            </div>
            <DayPicker
              month={rightCalendarMonth()}
              disabledDays={{
                after: defaultRangeLimitAfter,
                before: defaultRangeLimitBefore,
              }}
              fromMonth={defaultRangeLimitBefore}
              toMonth={defaultRangeLimitAfter}
              selectedDays={[selectedDates.from, selectedDates.to]}
              modifiers={modifiers}
              onDayClick={calendarSelect}
            />
          </PickerCalendar>
        </PickerCalendarContainer>
      </TimeRangePickerWrapper>
    </TimeRangePickerOuter>
  );
};

export default TimeRangePicker;
