import styled from "styled-components/macro";

/**
 * Defines the top region of a Card where titles
 * and controls and things of that nature go.
 */
export const CardHeaderBar = styled.div`
  border-bottom: 1px solid rgba(43, 48, 52, 0.1);
  padding: 0px 20px 0px 20px;
`;
