import React, { useEffect, useReducer, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  BaselineModelResponse,
  ChartDataGranularity,
  GetBaselineUsageResponse,
  ITimeRange,
  PointType,
  TemperatureUnit,
  Unit,
} from "src/types/charting";
import ReactTooltip from "react-tooltip";
import {
  Card,
  CardHeaderBar,
  CardHeading,
} from "src/components/common/CardLayout";
import { ChartControls } from "src/components/common/ChartParts";
import InfoAccordion from "src/components/common/InfoAccordion";
import { Body, H3 } from "src/components/common/Typography";
import { HyperLink } from "src/components/common/Link";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MxReactIcon,
  PaperAirplane,
  Download,
  Chart,
  Info,
} from "src/componentLibrary/react/mx-icon-react";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import {
  chartDataRangeFromInterval,
  colorFromPercent,
  convertPickerDatesToTimeRange,
  convertRangeToPickerDates,
  formattedPercentDifference,
  getDefaultGranularityForRange,
} from "src/helpers/charting";
import { BaselineChart } from "./BaselineChart";
import { BaselineDNAChart } from "./BaselineDNAChart";
import TimeRangePicker, {
  ITimeRangeChange,
  TimePickerHolder,
} from "src/components/common/TimeRangePicker";
import { daysBetweenDates } from "src/helpers/dates";
import { useOutsideClickEvent } from "src/hooks/useOutsideClickEvent";
import { getGranularityOptions } from "./baselineHelpers";
import GenericDataError, {
  GenericDataErrorType,
} from "src/components/common/GenericDataError/GenericDataError";
import { useUnitPrefs } from "src/hooks/useUnitPrefs";
import { availableBaselinePoints, baselineBuildPDFURL } from "./helpers";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";
// subscription related things
import { DataProxy } from "@apollo/client/cache";
import MessageBar, { MessageBarType } from "src/components/common/MessageBar";
import { tracking } from "src/tracking";
import {
  ConsolidatedSubscriptionVariables,
  RecurringPeriod,
  ReportIdentifier,
} from "src/types/subscriptions";
import { ConsolidatedBaselineSubscriptionModal } from "./ConsolidatedBaselineSubscriptionModal";
import DotMenu, { DotMenuItem } from "src/components/common/DotMenu";
import { exportToCsv } from "src/components/app/BaselineChart/baselineCSVExporter";
import Auth from "src/auth/Auth";
import { downloadBlobFile } from "src/helpers/csvExporter";
import { ErrorToast } from "src/components/common/Toast";
import DotMenuIconItem from "src/components/common/DotMenu/DotMenuIconItem";
import { AppColors } from "src/components/common/Styling";
import {
  GetBaselineUsageDocument,
  GetSubscriptionsForUserDocument,
  SubscribeToConsolidatedReportDocument,
} from "src/queries/typed";
import { BaselineVarianceEvents } from "../PortfolioPerformance/BaselineVarianceCard/BaselineVarianceCard";
import { BaselineManagementModal } from "src/components/app/BaselineChart/BaselineManagementModal";
import config from "src/configuration";
import { formatNumber } from "@hatchdata/intl-formatter";
import {
  ComposedMetric,
  Metric,
  MetricDescription,
  MetricLayout,
  MetricValue,
} from "src/components/common/Metrics";
import {
  baselineCardReducer,
  initializeBaselineCardState,
} from "src/components/app/BaselineChart/baselineCardReducer";
import { baselineCardDataChangeProcessor } from "src/components/app/BaselineChart/baselineCardDataChangeProcessor";
import { HorizontalLayout } from "src/components/common/Layout";
import { BasicSelect } from "src/components/common/Dropdown/BasicSelect";
import TimePickerButton from "src/components/common/TimeRangePicker/TimePickerButton";

export enum BaselineCardEvents {
  DOWNLOAD_CSV = "PerformanceBaselineExportCSV",
  DOWNLOAD_PDF = "PerformanceBaselineExportPDF",
  SUBSCRIBE = "PerformanceBaselineSubscribe",
  TOGGLE_TEMPERATURE = "PerformanceBaselineToggleTemperature",
  FILTER_CHANGE = "PerformanceBaselineFilterChange",
}

const overId = "charts.baseline.metric.difference.overLabel";
const underId = "charts.baseline.metric.difference.underLabel";
const overInfoId = "charts.baseline.metric.infoText.over";
const underInfoId = "charts.baseline.metric.infoText.under";

interface IBaselineCard {
  buildingId: string;
  timezone: string;
  supportsHourly?: boolean; // FALSE by default
  availableCommodities: PointType[]; // what can we baseline?
  buildingName?: string;
  userId?: string;
  baselineModels: BaselineModelResponse[];
}

interface IBaselineQueryVars {
  buildingId: string;
  granularity: ChartDataGranularity;
  range: ITimeRange;
  includeOperatingHours: Boolean;
  pointType?: PointType;
  unit?: Unit;
  type: PointType;
  temperatureUnit:
    | TemperatureUnit
    | typeof Unit.CELSIUS
    | typeof Unit.FAHRENHEIT;
  modelId: string;
  missingModel: boolean;
}

export const BaselineCard: React.FC<IBaselineCard> = ({
  buildingId,
  timezone,
  supportsHourly,
  availableCommodities,
  buildingName = "Not Provided",
  userId = "abc123",
  baselineModels = [],
}) => {
  const [baselineCardState, dispatchBaselineCardReducerAction] = useReducer(
    baselineCardReducer,
    {
      baselineModels,
      availableCommodities,
      supportsHourly,
      timezone,
    },
    initializeBaselineCardState,
  );

  /* =============================================================== *\
     REPORT SUBSCRIPTION STUFF

     TODO: think about making this a hook as we add more subscriptions
     because this is all copy / pasted from DailyDemandCard so maybe
     it will make sense to do so. Maybe not though! Life is funny.
  \* =============================================================== */
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  const [showManagementModal, setShowManagementModal] = useState(false);
  const closeManagementModal = () => setShowManagementModal(false);
  const openManagementModal = () => {
    setShowManagementModal(true);
    tracking.fireEvent(BaselineVarianceEvents.MANAGE_BASELINES, {
      userId,
      buildingId,
    });
  };
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] = useState("");
  // TODO: get rid of `any` and replace with actual types!
  const cachedSubscriptions: DataProxy.Query<any, any> = {
    query: GetSubscriptionsForUserDocument,
    variables: { userId: userId },
  };

  const intl = useIntl();

  const [
    subscribeToReport,
    {
      loading: subscriptionInProgress,
      error: subscriptionError,
      data: subscriptionData,
    },
  ] = useMutation<ConsolidatedSubscriptionVariables>(
    SubscribeToConsolidatedReportDocument,
    {
      onCompleted: () => closeModal(),
      onError: () => closeModal(),
      refetchQueries: [cachedSubscriptions],
    },
  );
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [baselineCardState.aggregateStatistics.percentDifference]);

  useEffect(() => {
    const errorMessageId =
      (subscriptionError?.graphQLErrors?.length &&
        (subscriptionError?.graphQLErrors[0]?.extensions?.response as {
          body?: { message: string };
        }).body?.message) === "Duplicate Subscription"
        ? "subscriptions.duplicate_error"
        : "subscriptions.error";
    setSubscriptionErrorMessage(errorMessageId);
    setShowErrorMessage(subscriptionError !== undefined);
  }, [subscriptionError]);

  useEffect(() => {
    setShowSuccessMessage(subscriptionData !== undefined);
  }, [subscriptionData]);

  // NOTE: that crazy Enum[Enum.VALUE] thing gets the string value LABELS
  // of non-string Enums if you didn't use a string for some reason (which wasn't done here)
  const handleSubscribe = (
    period: RecurringPeriod,
    buildingIds: string[],
    reportName?: string,
  ) => {
    // GET THE ID based on PERIOD
    const reportId =
      period === RecurringPeriod.WEEKLY_MONDAY_AM && supportsHourly
        ? ReportIdentifier[ReportIdentifier.BUILDING_HOURLY_BASELINES]
        : ReportIdentifier[ReportIdentifier.BUILDING_DAILY_BASELINES];
    //*
    subscribeToReport({
      variables: {
        reportAssetIds: buildingIds,
        reportName: reportName || undefined,
        userId: userId,
        report: reportId,
        deliverySchedule: RecurringPeriod[period],
      },
    });

    tracking.fireEvent(BaselineCardEvents.SUBSCRIBE, {
      commodity: baselineCardState.selectedCommodity,
    });
    // */
  };

  /* =============================================================== *\
     END REPORT SUBSCRIPTION STUFF
  \* =============================================================== */

  const handleExportClick = () => {
    exportToCsv(
      baselineCardState.chartData,
      timezone,
      intl.formatMessage({
        id: `commodity.name.${baselineCardState.selectedCommodity}`,
      }),
    );
    tracking.fireEvent(BaselineCardEvents.DOWNLOAD_CSV, {
      commodity: baselineCardState.selectedCommodity,
    });
  };

  const { unitPrefForPoint, unitPrefForTemperature } = useUnitPrefs();
  const commodityUnit = unitPrefForPoint(baselineCardState.selectedCommodity);
  /* =============================================================== *\
     HANDLERS FOR THINGS THAT HAPPEN WHEN A USER DOES STUFF
  \* =============================================================== */
  // Handle the time range picker open / close
  const timeRangeRef = useRef(null);
  const handleNonPickClick = () => {
    if (showPicker) {
      hidePicker();
    }
  };
  useOutsideClickEvent(handleNonPickClick, timeRangeRef);
  const [showPicker, setShowPicker] = useState(false);
  const hidePicker = () => setShowPicker(false);
  const togglePicker = () => setShowPicker(!showPicker);

  // THE GRAPHQL QUERY!!!!!!
  const { loading, data, error, refetch } = useQuery<
    GetBaselineUsageResponse,
    IBaselineQueryVars
  >(GetBaselineUsageDocument, {
    variables: {
      buildingId,
      granularity: baselineCardState.granularity,
      range: baselineCardState.chartTimeRange,
      includeOperatingHours:
        baselineCardState.granularity === ChartDataGranularity.Hour,
      unit: commodityUnit,
      pointType: baselineCardState.selectedCommodity,
      type: baselineCardState.selectedCommodity,
      temperatureUnit: unitPrefForTemperature,
      modelId: baselineCardState.selectedModel.id,
      missingModel: baselineCardState.selectedModel.id === undefined,
    },
  });
  const commodityLabels = {
    temperature: intl.formatMessage({ id: "charts.TEMPERATURE" }),
    humidity: intl.formatMessage({ id: "charts.HUMIDITY" }),
    noop: intl.formatMessage({ id: "charts.common.non_operating_hours" }),
  };

  // THIS WILL PARSE THE DATA AGAIN WHEN IT CHANGES
  useEffect(() => {
    const response = baselineCardDataChangeProcessor(
      data,
      timezone,
      baselineCardState.granularity,
      baselineCardState.chartTimeRange,
      commodityLabels,
    );

    dispatchBaselineCardReducerAction({
      type: "UPDATE_CHANGED_DATA",
      payload: response,
    });
  }, [data]);

  // THIS WILL RUN THE QUERY AGAIN WHEN SOMETHING CHANGES THAT WOULD
  // CAUSE THE DATA TO NOT BE GOOD ANY MORE
  useEffect(() => {
    refetch({
      buildingId,
      granularity: baselineCardState.granularity,
      range: baselineCardState.chartTimeRange,
      includeOperatingHours:
        baselineCardState.granularity === ChartDataGranularity.Hour,
      pointType: baselineCardState.selectedCommodity,
      type: baselineCardState.selectedCommodity,
      unit: commodityUnit,
      modelId: baselineCardState.selectedModel.id,
      missingModel: baselineCardState.selectedModel.id === undefined,
    });

    tracking.fireEvent(BaselineCardEvents.FILTER_CHANGE, {
      granularity: baselineCardState.granularity,
      selectedCommodity: baselineCardState.selectedCommodity,
      startTime: baselineCardState.chartTimeRange.startTime,
      endTime: baselineCardState.chartTimeRange.endTime,
      dayRange: daysBetweenDates(
        baselineCardState.chartTimeRange.startTime,
        baselineCardState.chartTimeRange.endTime,
      ),
    });
  }, [
    baselineCardState.chartTimeRange.startTime,
    baselineCardState.chartTimeRange.endTime,
    baselineCardState.granularity,
    baselineCardState.selectedCommodity,
    baselineCardState.selectedModel.id,
  ]);

  // called when the date picker gives us new values
  const handleRangeAccept = (vals: ITimeRangeChange) => {
    hidePicker();
    const { selectedDates } = vals;
    const _f = selectedDates.from as Date;
    const _t = selectedDates.to as Date;
    const _newRange = convertPickerDatesToTimeRange(
      { startTime: _f, endTime: _t },
      timezone,
    );
    // get the fixed range that corresponds to
    const _r = chartDataRangeFromInterval(_newRange);
    /*
    figure what is allowed at the new range
    see if we match that
    otherwise, set it to the smallest allowed I guess?
    */
    const _allowedGranularities = getGranularityOptions(
      _r,
      intl.formatMessage,
      supportsHourly,
    );
    const _g = _allowedGranularities[baselineCardState.granularity]
      ? baselineCardState.granularity
      : (Object.keys(_allowedGranularities)[0] as ChartDataGranularity);
    dispatchBaselineCardReducerAction({
      type: "UPDATE_FROM_TIME_PICKER_CHANGE",
      payload: {
        granularity: _g,
        chartTimeRange: _newRange,
        chartDataRange: _r,
      },
    });
    tracking.fireEvent(BaselineVarianceEvents.CHANGE_DATE_RANGE, {
      dateRange: {
        from: _newRange.startTime,
        to: _newRange.endTime,
      },
    });
  };

  const granularityOptions = getGranularityOptions(
    baselineCardState.chartDataRange,
    intl.formatMessage,
    supportsHourly,
  );
  const granularityChange: (s: string) => void = _granularity => {
    dispatchBaselineCardReducerAction({
      type: "SET_GRANULARITY",
      payload: { granularity: _granularity as ChartDataGranularity },
    });
  };

  const displayUnit = data?.getBuildingById?.commodityUsageData?.unit
    ? getSymbolForUnit(data?.getBuildingById?.commodityUsageData?.unit)
    : "";

  const availableSelectCommodities = availableBaselinePoints(
    availableCommodities,
  ).reduce((_obj: { [key: string]: string }, _current) => {
    _obj[_current as string] = intl.formatMessage({
      id: `commodity.name.${_current}`,
    });
    return _obj;
  }, {});

  const handleCommodityChange = (newSelectedCommodity: string) => {
    tracking.fireEvent(BaselineVarianceEvents.CHANGE_POINT_TYPE, {
      pointType: newSelectedCommodity,
    });
    // TODO: Maybe rename this action CHANGE_COMMODITY?
    dispatchBaselineCardReducerAction({
      type: "SET_SELECTED_COMMODITY",
      payload: {
        baselineModels,
        selectedCommodity: newSelectedCommodity as PointType,
      },
    });
  };
  const [loadingPDF, setLoadingPDF] = useState(false);
  const handlePdfClick = () => {
    const url = baselineBuildPDFURL(
      buildingId,
      baselineCardState.chartTimeRange,
      baselineCardState.granularity,
      new Date(),
      baselineCardState.selectedModel.id,
      baselineCardState.selectedCommodity,
    );
    tracking.fireEvent(BaselineCardEvents.DOWNLOAD_PDF, {
      commodity: baselineCardState.selectedCommodity,
    });

    if (url !== "") {
      // make a loading thing for the user....
      setLoadingPDF(true);
      fetch(url, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${Auth.getAccessToken()}`,
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Unable to generate PDF");
          }
          return response.blob();
        })
        .then(blob => {
          downloadBlobFile(blob, `baseline_${Date.now()}.pdf`);
          // remove a loading thing for the user....
          setLoadingPDF(false);
        })
        .catch(err => {
          console.error(err);
          setLoadingPDF(false);
          ErrorToast("common.error.pdfDownloadFailed");
        });
    } else {
      ErrorToast("common.error.pdfDownloadFailed");
    }
  };

  const baselineCommoditySelect = () => {
    return (
      <BasicSelect
        values={availableSelectCommodities}
        value={baselineCardState.selectedCommodity}
        onChange={handleCommodityChange}
        dataTestId={"baseline-commodity-picker"}
      />
    );
  };

  const modelChange: (s: string) => void = _modelId => {
    const _model = baselineCardState.validModels.filter(
      model => model.id === _modelId,
    )[0];
    dispatchBaselineCardReducerAction({
      type: "SET_SELECTED_MODEL",
      payload: { selectedModelId: _model.id },
    });
    tracking.fireEvent(BaselineVarianceEvents.CHANGE_BASELINE_MODEL, {
      userId,
      buildingId,
      modelName: baselineCardState.selectedModel.modelName,
    });
  };

  const headerContents = () => {
    if (baselineCardState.chartData) {
      return (
        <>
          {!baselineCardState.baselineUnavailable && !error && (
            <MetricLayout>
              {!!baselineCardState.aggregateStatistics.totalBaselineUsage && (
                <Metric
                  value={
                    baselineCardState.aggregateStatistics.totalBaselineUsage
                  }
                  unit={displayUnit}
                  description="charts.baseline.metric.baseline.label"
                />
              )}
              {!!baselineCardState.aggregateStatistics.totalUsage && (
                <Metric
                  value={baselineCardState.aggregateStatistics.totalUsage}
                  unit={displayUnit}
                  description="charts.baseline.metric.actual.label"
                />
              )}
              {baselineCardState.aggregateStatistics.percentDifference !==
                undefined && (
                <ComposedMetric
                  value={
                    <MetricValue
                      color={colorFromPercent(
                        baselineCardState.aggregateStatistics.percentDifference,
                      )}
                    >
                      {formattedPercentDifference(
                        baselineCardState.aggregateStatistics.percentDifference,
                        false,
                      )}
                    </MetricValue>
                  }
                  description={
                    <HorizontalLayout childSpacing={2}>
                      <MetricDescription>
                        <FormattedMessage
                          id={
                            baselineCardState.aggregateStatistics
                              .percentDifference > 0
                              ? overId
                              : underId
                          }
                        />
                      </MetricDescription>
                      <MxReactIcon
                        data-for="over-under-info"
                        data-tip={formattedPercentDifference(
                          baselineCardState.aggregateStatistics
                            .percentDifference,
                        )}
                        Icon={Info}
                        color={AppColors.semantic.blue.sky}
                      />
                      <ReactTooltip
                        id="over-under-info"
                        type="light"
                        border={true}
                        backgroundColor={AppColors.neutral.white}
                        getContent={c => (
                          <FormattedMessage
                            id={parseFloat(c) > 0 ? overInfoId : underInfoId}
                            values={{
                              percentDifference: formattedPercentDifference(
                                parseFloat(c),
                                false,
                              ),
                            }}
                          />
                        )}
                      />
                    </HorizontalLayout>
                  }
                />
              )}
            </MetricLayout>
          )}
        </>
      );
    }
    return null;
  };
  const panelContents = () => {
    if (loading) {
      return <CenteredLoadingIndicator />;
    }
    if (error) {
      return <GenericDataError type={GenericDataErrorType.Info} />;
    }
    // check for data and the parts we need.
    if (baselineCardState.chartData) {
      return (
        <>
          {baselineCardState.granularity === ChartDataGranularity.Hour ? (
            <BaselineDNAChart
              data={baselineCardState.chartData}
              timeRange={baselineCardState.chartTimeRange}
              rangeSize={baselineCardState.chartDataRange}
              timezone={timezone}
              unit={displayUnit}
              height={600 - 72 - 82 - 32}
              buildingNonOperatingHours={baselineCardState.nonOperatingHours}
            />
          ) : (
            <BaselineChart
              data={baselineCardState.chartData}
              rangeSize={baselineCardState.chartDataRange}
              timeRange={baselineCardState.chartTimeRange}
              timezone={timezone}
              unit={displayUnit}
              height={600 - 72 - 82 - 32}
              data-testid="daily-baseline-chart"
            />
          )}
          <InfoAccordion
            titleId="charts.baseline.userInfo.title"
            startOpen={false}
            onClick={() => {
              tracking.fireEvent(BaselineVarianceEvents.BASELINE_INFO, {});
            }}
          >
            {baselineCardState.trainingPeriod.startTime &&
            baselineCardState.trainingPeriod.endTime ? (
              <>
                <Body style={{ fontWeight: "bold" }}>
                  <FormattedMessage id="charts.baseline.userInfo.trainingPeriod" />
                  {": "}
                  {baselineCardState.trainingPeriod.startTime} -{" "}
                  {baselineCardState.trainingPeriod.endTime}
                </Body>
                {baselineCardState.smae !== undefined && (
                  <Body style={{ fontWeight: "bold" }}>
                    <FormattedMessage id="charts.baseline.userInfo.overallSMAE" />
                    {": "}
                    {formatNumber(baselineCardState.smae, undefined, 3, 3)}
                  </Body>
                )}
                <Body style={{ marginTop: "15px" }}>
                  <FormattedMessage id="charts.baseline.userInfo.paragraph1" />
                </Body>
              </>
            ) : (
              <Body>
                <FormattedMessage id="charts.baseline.userInfo.paragraph1" />
              </Body>
            )}
          </InfoAccordion>
        </>
      );
    }

    if (baselineCardState.baselineUnavailable) {
      return (
        <InfoAccordion
          titleId="charts.baseline.tooltip.noBaselineTitle"
          startOpen={true}
        >
          <Body>
            <FormattedMessage id="charts.baseline.tooltip.noBaselineMessage" />
            {": "}
            <HyperLink href={"mailto:" + config.SUPPORT_EMAIL}>
              <FormattedMessage id="support.url" />
            </HyperLink>
          </Body>
        </InfoAccordion>
      );
    }
    // handle error and no renderable data cases
    return (
      <GenericDataError
        messageId="charts.noData"
        type={GenericDataErrorType.Info}
      />
    );
  };

  const { startTime: startDate, endTime: endDate } = convertRangeToPickerDates(
    baselineCardState.chartTimeRange,
    timezone,
  );

  return (
    <>
      {showErrorMessage && (
        <MessageBar
          type={MessageBarType.Alert}
          showClose={true}
          textId={subscriptionErrorMessage}
          marginBottom="-16px"
          onClick={() => setShowErrorMessage(false)}
        />
      )}

      {showSuccessMessage && (
        <MessageBar
          type={MessageBarType.Success}
          showClose={true}
          textId="subscriptions.baselineHourly.success"
          marginBottom="-16px"
          onClick={() => setShowSuccessMessage(false)}
        />
      )}

      <Card>
        <CardHeaderBar>
          <CardHeading>
            <H3>
              <FormattedMessage id="charts.baseline.usage.title" />
            </H3>
            <ChartControls>
              {supportsHourly && baselineCommoditySelect()}
              <TimePickerButton
                togglePicker={togglePicker}
                dataTestId={"baseline-date-picker-button"}
                startTime={startDate}
                endTime={endDate}
                timezone={timezone}
              />
              {showPicker && (
                <TimePickerHolder ref={timeRangeRef}>
                  <TimeRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    timezone={timezone}
                    onChange={handleRangeAccept}
                    onCancel={hidePicker}
                    limit={365}
                    allowToday={true}
                  />
                </TimePickerHolder>
              )}
              <BasicSelect
                values={granularityOptions}
                value={
                  baselineCardState.granularity ??
                  Object.values(granularityOptions)[0]
                }
                onChange={granularityChange}
                dataTestId={"baseline-granularity-picker"}
              />
              {baselineCardState.validModels.length > 0 && (
                <BasicSelect
                  values={baselineCardState.modelNames}
                  value={baselineCardState.selectedModel.id}
                  onChange={modelChange}
                  dataTestId={"baseline-model-picker"}
                />
              )}
            </ChartControls>
            <DotMenu>
              <DotMenuItem
                data-testid="export-dot-menu-export"
                onClick={handleExportClick}
              >
                <DotMenuIconItem
                  icon={Download}
                  intlTextId="common.menuItem.labels.exportCSV"
                />
              </DotMenuItem>
              <DotMenuItem
                data-testid="export-dot-menu-export-pdf"
                onClick={handlePdfClick}
              >
                <DotMenuIconItem
                  icon={Download}
                  intlTextId="common.menuItem.labels.exportPDF"
                />
              </DotMenuItem>
              <DotMenuItem data-testid="demand-dot-menu" onClick={openModal}>
                <DotMenuIconItem
                  icon={PaperAirplane}
                  intlTextId="subscriptions.subscribe.menuItem"
                />
              </DotMenuItem>
              {/* TODO: When this modal is replaced with actual admin features, delete the whole
              BaselineManagementModal file, as well as all associated translation strings in en.json */}
              <DotMenuItem
                data-testId="baseline-management-dot-menu"
                onClick={openManagementModal}
              >
                <DotMenuIconItem
                  intlTextId={"performance.baselineManagement.modal.title"}
                  icon={Chart}
                />
              </DotMenuItem>
            </DotMenu>
          </CardHeading>
          {headerContents()}
        </CardHeaderBar>
        {panelContents()}

        {showModal && (
          <ConsolidatedBaselineSubscriptionModal
            inProgress={subscriptionInProgress}
            propertyName={buildingName}
            closeOnClick={closeModal}
            subscribeOnClick={handleSubscribe}
            supportsHourly={supportsHourly}
            buildingId={buildingId}
          />
        )}
        {showManagementModal && (
          <BaselineManagementModal closeOnClick={closeManagementModal} />
        )}
      </Card>
    </>
  );
};
BaselineCard.defaultProps = {
  supportsHourly: false,
};
