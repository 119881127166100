import auth0 from "auth0-js";
import config from "src/configuration";
import { tracking } from "src/tracking";
import Cookies from "js-cookie";
import { DateTime } from "luxon";

// TODO: This breaks tests and stuff where files import things that import
//   other things that import this file, because it produces a
//   "options parameter is not valid" error. Probably best not to "new" anything
//   to use as a global variable at the top level in files that get imported.
const webAuth = new auth0.WebAuth(config.AUTH0_SETTINGS);

const REFRESH_WINDOW_MS = 300e3; // how many ms before we expire do we allow refresh

type AuthCookieContents = {
  sub: string | undefined;
  accessToken?: string;
  refreshToken?: string;
  expires: number;
};

export class AuthClient {
  static AUTH_RESULT = "authResult";
  static REMEMBER_CREDS = "rememberCredentials";
  static USER_NAME = "userName";

  // Seems to be called on redirect
  handleAuthentication(): Promise<undefined | null | auth0.Auth0Error> {
    const parent = this;

    return new Promise((resolve, reject) =>
      webAuth.parseHash({ hash: window.location.hash }, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          parent.setSession(authResult);
          resolve(null);
        } else if (err) {
          console.error("Unable to set credentials", err);
          reject(err);
        } else {
          reject();
        }
      }),
    );
  }

  shouldRememberCredentials(): boolean {
    return localStorage.getItem(AuthClient.REMEMBER_CREDS) === "yes";
  }

  getAccessToken(): string | undefined {
    return this.getAuthResult()?.accessToken;
  }

  getUsername(): string {
    return localStorage.getItem(AuthClient.USER_NAME) || "";
  }

  getUserId(): string {
    return this.getAuthResult()?.sub ?? "";
  }

  /**
   * Will attempt to renew a token. Returns a promise that resolves to `true`
   * if it works, or `false` if not. Will also log you out if it doesn't work.
   */
  renewSession(): Promise<boolean> {
    return new Promise(resolve => {
      webAuth.checkSession(
        {},
        (err: null | auth0.Auth0Error, authResult: auth0.Auth0DecodedHash) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            resolve(true);
          } else if (err) {
            resolve(false);
          }
        },
      );
    });
  }

  parseErrorMessage(err: null | auth0.Auth0Error): string {
    // based off this:
    // https://auth0.com/docs/libraries/auth0js/v9#error-codes-and-descriptions
    // return something nice.
    if (err && err.code && err.code === "access_denied") {
      return "login.bad.credentials.error";
    }
    return "login.generic.auth.error";
  }

  login(
    username: string,
    password: string,
    rememberCredentials: boolean,
    errCallback: (message: string) => void,
  ) {
    this.setRememberCredentials(rememberCredentials);

    webAuth.login(
      {
        username: username,
        password: password,
        realm: config.AUTH0_REALM,
      },
      (err: null | auth0.Auth0Error) => {
        this.setRememberCredentials(false);
        const errMessage: string = this.parseErrorMessage(err);
        errCallback(errMessage);
      },
    );
  }

  logout(wasTimeout = false) {
    Cookies.remove(AuthClient.AUTH_RESULT);
    sessionStorage.clear();

    // removing any legacy auth results from local storage
    localStorage.removeItem(AuthClient.AUTH_RESULT);

    let loginPath = "/login";
    if (wasTimeout) {
      // TODO: add where you were?
      loginPath += "?timeout=true";
    }
    tracking.fireEvent("LogoutSucceeded", { wasTimeout: wasTimeout });
    webAuth.logout({
      returnTo: window.location.origin + loginPath,
    });
  }

  /**
   * Method that will initiate  a password reset email for a user
   *
   * @param email the user's email address to send reset to
   * @returns - Promise that will resolve true or false based on if Auth0 reset worked
   */
  passwordReset(email: string): Promise<boolean> {
    return new Promise(resolve => {
      webAuth.changePassword(
        {
          connection: config.AUTH0_REALM,
          email: email,
        },
        err => {
          if (err) {
            console.error("reset operation failed", err);
            resolve(false);
          } else {
            resolve(true);
          }
        },
      );
    });
  }

  isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    return new Date().getTime() < this.getExpiresAt();
  }

  isTokenReadyForRefresh(): boolean {
    const refreshTime = this.getExpiresAt() - REFRESH_WINDOW_MS;
    return new Date().getTime() >= refreshTime;
  }

  private setSession(authResult: auth0.Auth0DecodedHash) {
    // Set the time that the Access Token will expire at
    const expiresAt = DateTime.now()
      .plus({ seconds: authResult.expiresIn })
      .toJSDate();

    Cookies.set(
      AuthClient.AUTH_RESULT,
      JSON.stringify({
        sub: authResult.idTokenPayload?.sub,
        accessToken: authResult.accessToken,
        refreshToken: authResult.refreshToken,
        expires: expiresAt?.getTime(),
      } as AuthCookieContents),
      {
        sameSite: "strict",
        expires: expiresAt,
        secure: true,
      },
    );

    if (this.shouldRememberCredentials()) {
      this.setUserName(authResult.idTokenPayload.email);
    } else {
      localStorage.removeItem(AuthClient.USER_NAME);
    }
  }

  private getAuthResult(): AuthCookieContents | undefined {
    const item = Cookies.get(AuthClient.AUTH_RESULT);
    return item ? JSON.parse(item) : undefined;
  }

  private getExpiresAt(): number {
    return this.getAuthResult()?.expires ?? 0;
  }

  private setRememberCredentials(rememberCredentials: boolean) {
    localStorage.setItem(
      AuthClient.REMEMBER_CREDS,
      rememberCredentials ? "yes" : "no",
    );
  }

  private setUserName(userName: string) {
    localStorage.setItem(AuthClient.USER_NAME, userName);
  }
}

const Auth = new AuthClient();
export default Auth;
