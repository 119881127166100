import React, { useEffect, useState } from "react";
import {
  ChartDataRange,
  ICombinedIntervalData,
  ITimeRange,
} from "src/types/charting";
import {
  ComposedChart,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Area,
} from "recharts";
import useElementResize from "src/hooks/useElementResize";
import {
  dateTickFormatterFunction,
  minmaMaximaReducer,
  xTicksForRangeAndSize,
  tickMarkValues,
  friendlyNumberFormat,
  formattedPercentDifference,
  calculatePercentDiff,
  colorFromPercent,
  multiDomainYTickMarkValues,
} from "src/helpers/charting";
import {
  LegendCheckboxItem,
  LegendContainer,
  LegendDotItem,
  TooltipWithDots,
} from "src/components/common/ChartParts";
import { FormattedMessage, useIntl } from "react-intl";
import { AppColors } from "src/components/common/Styling";
import { DottedTooltipProps } from "src/components/common/ChartParts/TooltipWithDots";
import { tracking } from "src/tracking";
import { BaselineCardEvents } from ".";
import { dateToLocaleString, FormatOptions } from "@hatchdata/intl-formatter";

//const RED_COLOR = AppColors.semantic.red.red;
const RED_COLOR = AppColors.semantic.red["light-red-3"];
const GREY_COLOR = AppColors.semantic.green["light-msr-green-3"];

const MakeFooter = (props: DottedTooltipProps) => {
  const _p = props.payload || [];
  if (_p.length) {
    const _vals = _p[0].payload; // gets delicious data inside
    if (_vals.value && _vals.baseline) {
      const _diff = calculatePercentDiff(_vals.baseline, _vals.value);
      const _fmt = formattedPercentDifference(_diff);
      return (
        <p style={{ marginTop: "8px", fontSize: "12px" }}>
          <FormattedMessage id="charts.baseline.tooltip.difference.footerText" />{" "}
          <span style={{ color: colorFromPercent(_diff) }}>{_fmt}</span>
        </p>
      );
    }
  }
  return null;
};

const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation();
  e.nativeEvent.stopImmediatePropagation();
};

interface IBaselineChart {
  data: ICombinedIntervalData;
  timeRange: ITimeRange;
  rangeSize: ChartDataRange;
  unit: string;
  height?: number;
  timezone?: string;
}
export const BaselineChart: React.FC<IBaselineChart> = ({
  data,
  timeRange,
  rangeSize,
  unit,
  height = 450,
  timezone,
}) => {
  const [resizeListener, sizes] = useElementResize();
  const [sizeSet, setSizeSet] = useState(false);
  useEffect(() => {
    if (sizes.width) {
      setSizeSet(true);
    }
  }, [sizes.height, sizes.width]);
  const intl = useIntl();

  const [showTooltip, setShowTooltip] = useState(true);
  const toggleTooltips = () => setShowTooltip(!showTooltip);
  const initialRenderWidth = 800;
  // ================================================
  const xTickFormatter = dateTickFormatterFunction(rangeSize, timezone) as (
    date: Date,
  ) => string;

  const domains = {
    value: minmaMaximaReducer(data.values, "value"),
    baseline: minmaMaximaReducer(data.values, "baseline"),
    x: minmaMaximaReducer(data.values, "timestamp"),
    temperature: minmaMaximaReducer(data.values, "temperature"),
  };

  const [TEMPERATUREDomain] = multiDomainYTickMarkValues([
    {
      min: domains.temperature.minima!,
      max: domains.temperature.maxima!,
      minTickGap: 10,
    },
  ]);

  const yDomain =
    domains.value.maxima! >= domains.baseline.maxima!
      ? domains.value
      : domains.baseline;
  const yTicks = tickMarkValues(yDomain.maxima!);

  const useWidth = sizes.width || initialRenderWidth;
  const xTicks = xTicksForRangeAndSize(
    useWidth,
    data.values,
    undefined,
    timeRange,
    false,
  );

  const tooltipLabelFormatter = (value: number, name: string) => {
    let thing = "Baseline";

    if (name === "value") {
      thing = "Actual";
    } else if (name === "temperature") {
      thing = "Temperature";
    }

    return (
      <span>
        {thing}:
        <span style={{ fontWeight: "bold" }}>
          {" "}
          {friendlyNumberFormat(value as number, 1)}{" "}
          {name !== "temperature" ? unit : undefined}
        </span>
      </span>
    );
  };

  const tooltipLabelFormat = (_v: number | string) => {
    if (!_v) {
      return "";
    }
    const _d = new Date(_v);
    return dateToLocaleString(
      _d,
      undefined,
      timezone,
      FormatOptions.DATE_SHORT,
    ).replace(",", "");
  };

  const [visibility, setVisibility] = useState({ temperature: false });

  const renderLegend = () => {
    return (
      <LegendContainer onClick={stopPropagation}>
        <LegendDotItem
          color={GREY_COLOR}
          text={intl.formatMessage({
            id: `charts.baseline.legend.${data.labels["value"]}`,
          })}
          inactive={false}
        />
        <LegendDotItem
          color={AppColors.neutral.navy}
          stroked={true}
          text={intl.formatMessage({
            id: `charts.baseline.legend.${data.labels["baseline"]}`,
          })}
          inactive={false}
        />
        {data.labels["TEMPERATURE"] && (
          <LegendCheckboxItem
            color={AppColors.semantic.blue["light-sky-1"]}
            text={intl.formatMessage({
              id: `charts.baseline.legend.${data.labels["TEMPERATURE"]}`,
            })}
            onClick={() => {
              tracking.fireEvent(BaselineCardEvents.TOGGLE_TEMPERATURE, {});
              setVisibility({
                temperature: !visibility.temperature,
              });
            }}
            inactive={!visibility.temperature}
          />
        )}
      </LegendContainer>
    );
  };
  // ================================================
  return (
    <div onClick={toggleTooltips} data-testid="daily-baseline-chart">
      {resizeListener}
      {sizeSet && (
        <>
          <ResponsiveContainer debounce={300} width="100%" height={height}>
            <ComposedChart
              data={data.values}
              margin={{ top: 30, bottom: 20, right: 40, left: 60 }}
            >
              <XAxis
                dataKey="timestamp"
                type="number"
                scale="time"
                domain={[
                  domains.x.minima as number,
                  domains.x.maxima as number,
                ]}
                minTickGap={0}
                tick={{
                  fontSize: 12,
                  dy: rangeSize === ChartDataRange.SevenDays ? 0 : 5,
                }}
                angle={rangeSize === ChartDataRange.SevenDays ? 0 : 80}
                ticks={xTicks}
                tickMargin={10}
                tickLine={false}
                tickFormatter={(t: number) =>
                  xTickFormatter(new Date(t as number))!
                }
                axisLine={false}
              />
              {/* LEFT SIDE energy Axis */}
              <YAxis
                domain={[0, yTicks[yTicks.length - 1]]}
                ticks={yTicks}
                unit={data.units["value"]}
                tick={{ fontSize: 12, dy: -10, dx: 40, width: 100 }}
                tickLine={{ stroke: AppColors.neutral["light-navy-9"] }}
                tickSize={80}
                axisLine={false}
              />
              {/* RIGHT SIDE TEMPERATURE Axis */}
              {visibility.temperature && (
                <YAxis
                  yAxisId="temperature"
                  orientation="right"
                  domain={[TEMPERATUREDomain.min, TEMPERATUREDomain.max]}
                  axisLine={false}
                  tickLine={{ stroke: AppColors.neutral["light-navy-9"] }}
                  unit={data.units["TEMPERATURE"]}
                  tick={{ fontSize: 12, dy: -10, dx: -30 }}
                  ticks={TEMPERATUREDomain.ticks}
                  width={40}
                  tickSize={40}
                />
              )}
              <CartesianGrid
                stroke={AppColors.neutral["light-navy-9"]}
                vertical={false}
              />
              <Tooltip
                isAnimationActive={false}
                separator=""
                content={
                  showTooltip ? (
                    <TooltipWithDots
                      footer={MakeFooter}
                      strokeDataKeys={{ baseline: true }}
                    />
                  ) : (
                    () => false
                  )
                }
                labelFormatter={tooltipLabelFormat}
                formatter={tooltipLabelFormatter}
              />
              <Bar
                name="Actual"
                isAnimationActive={false}
                dataKey="value"
                fill={GREY_COLOR}
                maxBarSize={16}
                stroke={GREY_COLOR}
                strokeWidth={0}
              >
                {data.values.map((val, idx) => (
                  <Cell
                    key={idx}
                    fill={
                      val["baseline"]! < val["value"]! ? RED_COLOR : GREY_COLOR
                    }
                  />
                ))}
              </Bar>
              <Line
                name="Baseline"
                isAnimationActive={false}
                dataKey="baseline"
                dot={false}
                strokeDasharray="5 5"
                stroke={AppColors.neutral.navy}
                strokeWidth={2}
                activeDot={{
                  r: 6,
                  fill: AppColors.neutral.white,
                  stroke: AppColors.neutral.navy,
                  strokeWidth: 2,
                }}
                type="step"
              />
              {visibility.temperature && (
                <Area
                  name={data.labels["TEMPERATURE"]}
                  yAxisId="temperature"
                  type="monotone"
                  unit={data.units["TEMPERATURE"]}
                  dataKey="temperature"
                  fill={"rgba(129,142,153,0.15)"}
                  stroke={AppColors.semantic.blue["light-sky-1"]}
                  strokeWidth={1}
                  isAnimationActive={false}
                  activeDot={{ r: 6, fill: AppColors.neutral["light-navy-3"] }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
          {renderLegend()}
        </>
      )}
    </div>
  );
};
