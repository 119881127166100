import styled from "styled-components/macro";
import { propsToPx } from "./Layout";

const columnsToWidths = (cols: number[]): string =>
  cols.reduce((val, cur) => (val += `${cur}fr `), "");
/**
 * Lays out columns using display: 'grid'. This
 * styling will size each column in the grid
 * to be equal and switch to a row layout when
 * the page is reduced in size.
 *
 * By default, it will render children in equal sized columns. ex:
 *
 *  <ResponsiveGridColumnLayout>
 *    <div>one</div>
 *    <div>two</div>
 *    <div>three</div>
 *  </ResponsiveGridColumnLayout>
 *
 * Will render three columns of 33.3% width each. If you need different ratios, you can use the
 * `columns` prop to give the proportions you need. So if you wanted the markup above to be
 * the first column taking half the width, and the reaming two columns taking the rest equally,
 * you would do:
 *
 *  <ResponsiveGridColumnLayout columns={[2,1,1]}>
 *    <div>one</div>
 *    <div>two</div>
 *    <div>three</div>
 *  </ResponsiveGridColumnLayout>
 *
 */
export const ResponsiveGridColumnLayout = styled.div<{
         columns?: number[];
         maxWidth?: number;
         childSpacing?: number;
       }>`
         display: grid;
         grid-auto-flow: column;
         grid-auto-columns: ${props =>
           props.columns ? columnsToWidths(props.columns) : "1fr"};
         gap: ${props =>
           props.childSpacing ? propsToPx(props.childSpacing) : "0"};
         @media (max-width: ${props => props.maxWidth || "800px"}) {
           grid-auto-flow: row;
           grid-template-columns: 100%;
         }
       `;

export default ResponsiveGridColumnLayout;
