import styled from "styled-components/macro";
import { controlText1_style } from "src/components/common/ControlText";
import { AppColors } from "../Styling";

export const ChartControls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 8px 45px 8px 0px;

  > * {
    margin: 4px;
  }

  /* This is allows us to have full width pickers when we are small*/
  // TODO: this will never do anything because the below will be doing it already...
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`;

/**
 * makes a rounded rectangle looking thing that looks like a button
 * but isn't!
 */
export const ChartControlOutlineContainer = styled.div<{ disabled: boolean }>`
         font-size: ${controlText1_style.fontSize};
         cursor: default;
         display: inline-flex;
         align-items: center;
         justify-content: space-between;
         margin-left: 8px;
         line-height: 1;
         padding: 8px;
         border: 1px solid ${AppColors.neutral["light-navy-1"]};
         border-radius: 4px;
         border-style: solid;
         background-color: ${AppColors.neutral.white};
         color: ${props =>
           props.disabled
             ? controlText1_style.inactiveColor
             : controlText1_style.activeColor};
         font-weight: ${controlText1_style.activeWeight};
       `;
