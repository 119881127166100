import React, { useState, useContext } from "react";
import styled from "styled-components/macro";
import { useQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import {
  MxReactIcon,
  X,
  MapPin,
} from "src/componentLibrary/react/mx-icon-react";
import { H3, Body } from "src/components/common/Typography";
import { SmallSpindicator } from "src/components/common/LoadingIndicator";
import {
  TintedBGModal,
  ModalHeader,
  ModalRow,
} from "src/components/common/ModalParts";
import { RecurringPeriod } from "src/types/subscriptions";
import { AppColors } from "src/components/common/Styling";
import { SubscriptionLocationSelector } from "src/components/common/SubscriptionLocationSelector/SubscriptionLocationSelector";
import { AutoCompleteItem } from "src/components/common/AutoComplete";
import { CurrentOrganizationContext } from "../AuthenticatedPage";
import { GetBuildingsForSubscriptionsDocument } from "src/queries/typed";
import Input from "src/components/common/Input";
import MessageBar, { MessageBarType } from "src/components/common/MessageBar";
import { MxPrimaryReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";
import { BasicSelect } from "src/components/common/Dropdown/BasicSelect";

const ButtonSpacer = styled.div`
  margin-left: 68px;
  display: flex;
  align-items: center;
`;

const RowItem = styled.td`
  padding-left: 32px;
  &:first-child {
    padding-left: 0px;
  }
`;
interface ConsolidatedBaselineSubscriptionModalProps {
  closeOnClick: () => void;
  subscribeOnClick: (
    period: RecurringPeriod,
    buildingIds: string[],
    reportName: string,
  ) => void;
  inProgress: boolean;
  propertyName?: string;
  supportsHourly: boolean | undefined;
  buildingId?: string;
}
export const ConsolidatedBaselineSubscriptionModal: React.FC<ConsolidatedBaselineSubscriptionModalProps> = ({
  closeOnClick,
  subscribeOnClick,
  inProgress = false,
  propertyName,
  supportsHourly,
  buildingId,
}) => {
  const { currentOrganizationId } = useContext(CurrentOrganizationContext);

  const [period, setPeriod] = useState(RecurringPeriod.WEEKLY_MONDAY_AM);
  const [buildingIds, setBuildingIds] = useState(
    buildingId ? [buildingId] : [],
  );
  const [reportName, setReportName] = useState("");
  const intl = useIntl();
  /*
Build SELECT of the three report periods

The onSubscribe thing needs to take a PERIOD
(the granularity will be defined by that so we don't need to pass it)
*/
  const REPORT_PERIOD_SELECT_DATA: { [p: string]: string } = {
    [RecurringPeriod.WEEKLY_MONDAY_AM]: intl.formatMessage({
      id: "subscriptions.baseline.period.WEEKLY_MONDAY_AM",
    }),
    [RecurringPeriod.MONTHLY_FIRST_AM]: intl.formatMessage({
      id: "subscriptions.baseline.period.MONTHLY_FIRST_AM",
    }),
    [RecurringPeriod.QUARTERLY_FIRST_AM]: intl.formatMessage({
      id: "subscriptions.baseline.period.QUARTERLY_FIRST_AM",
    }),
  };

  const handleClose = () => {
    if (!inProgress) {
      closeOnClick();
    }
  };
  const handleSubscribe = () => {
    subscribeOnClick(period, buildingIds, reportName);
  };
  const handlePeriodChange = (s: RecurringPeriod) => {
    setPeriod(s);
  };

  const handleSelectionChanged = (assets: AutoCompleteItem[]) => {
    setBuildingIds(assets.map(x => x.id));
  };

  const intervalStringID =
    supportsHourly && period === RecurringPeriod.WEEKLY_MONDAY_AM
      ? "subscriptions.interval.hourly"
      : "subscriptions.interval.daily";

  const { data, loading } = useQuery(GetBuildingsForSubscriptionsDocument, {
    variables: { organizationId: currentOrganizationId ?? "" },
  });

  const buildingItems: AutoCompleteItem[] =
    data?.getOrganizationById?.buildings?.map(b => ({
      name: b!.name,
      id: b!.id,
    })) ?? [];

  return (
    <TintedBGModal>
      <ModalHeader>
        <H3>
          <FormattedMessage id="subscriptions.baselineHourly.title" />
        </H3>
        <ButtonSpacer>
          {(inProgress || loading) && <SmallSpindicator />}
          <MxPrimaryReactButton
            disabled={inProgress || loading || buildingIds.length === 0}
            onClick={handleSubscribe}
            intlTextId="subscriptions.subscribe"
          />
          <div style={{ display: "inline-block" }} onClick={handleClose}>
            <MxReactIcon
              Icon={X}
              color={
                inProgress
                  ? AppColors.neutral["light-navy-9"]
                  : AppColors.neutral.navy
              }
              style={{ marginLeft: "30px" }}
            />
          </div>
        </ButtonSpacer>
      </ModalHeader>
      <MessageBar
        type={MessageBarType.Neutral}
        showClose={false}
        textId="subscriptions.modal.defaultOnly"
        isCompact
        marginBottom={"10px"}
      />
      {buildingItems.length === 1 && (
        <ModalRow>
          <Body>
            <MxReactIcon
              Icon={MapPin}
              color={AppColors.neutral.navy}
              style={{ marginRight: "8px" }}
            />{" "}
            {propertyName}
          </Body>
        </ModalRow>
      )}
      <table style={{ marginBottom: "30px" }}>
        <tbody>
          <tr>
            <RowItem>
              <Body>
                <b>
                  <FormattedMessage id="subscriptions.period" />
                </b>
              </Body>
            </RowItem>
            <RowItem>
              <Body>
                <b>
                  <FormattedMessage id="subscriptions.interval" />
                </b>
              </Body>
            </RowItem>
            <RowItem>
              <Body>
                <b>
                  <FormattedMessage id="subscriptions.deliveryTime" />
                </b>
              </Body>
            </RowItem>
          </tr>
          <tr>
            <RowItem>
              <BasicSelect
                values={REPORT_PERIOD_SELECT_DATA}
                value={period}
                onChange={selectedItem =>
                  handlePeriodChange(selectedItem as RecurringPeriod)
                }
              />
            </RowItem>
            <RowItem>
              <Body>
                <FormattedMessage id={intervalStringID} />
              </Body>
            </RowItem>
            <RowItem>
              <Body>
                <FormattedMessage id={`subscriptions.deliveryTime.${period}`} />
              </Body>
            </RowItem>
          </tr>
        </tbody>
      </table>

      <ModalRow>
        <Body>
          <b>
            <FormattedMessage id="subscriptions.subscriptionTitle" />
          </b>{" "}
          <span style={{ color: AppColors.neutral["light-navy-3"] }}>
            <FormattedMessage id="subscriptions.optional" />
          </span>
        </Body>
        <Input
          maxLength={100}
          onChange={value => setReportName(value.target.value)}
          placeholder="subscriptions.baselineHourly.title"
          style={{ width: "500px" }}
        />
      </ModalRow>

      {buildingItems.length > 1 && (
        <SubscriptionLocationSelector
          buildingList={buildingItems}
          defaultSelected={buildingItems.find(x => x.id === buildingId)}
          onSelectedChange={handleSelectionChanged}
        />
      )}
    </TintedBGModal>
  );
};
