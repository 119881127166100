import {
  BaselineModelResponse,
  ChartDataGranularity,
  ChartDataRange,
  ICombinedIntervalData,
  ITimeRange,
} from "src/types/charting";
import { PointType } from "src/types/graphql";
import {
  chartDataRangeFromInterval,
  createTimeRange,
} from "src/helpers/charting";

export type BaselineCardState = {
  validModels: BaselineModelResponse[];
  selectedModel: BaselineModelResponse;
  defaultModel: BaselineModelResponse;
  modelNames: { [key: string]: string };
  baselineUnavailable: boolean;
  selectedCommodity: PointType;
  granularity: ChartDataGranularity;
  allowedGranularities: ChartDataGranularity[];
  chartDataRange: ChartDataRange;
  aggregateStatistics: {
    totalUsage: number | undefined;
    totalBaselineUsage: number | undefined;
    percentDifference: number | undefined;
  };
  trainingPeriod:
    | ITimeRange
    | {
        startTime: undefined;
        endTime: undefined;
      };
  smae: number | undefined;
  nonOperatingHours: ITimeRange[];
  chartData: ICombinedIntervalData | false;
  chartTimeRange: ITimeRange;
};

/**
 * Returns the first valid model found that is marked as the global default
 * or the first model. If no valid models are found, an empty object is returned.
 * @returns the first valid model found that is marked as the global default
 * or the first model.
 */
export const findDefaultModel = (
  models: BaselineModelResponse[],
): BaselineModelResponse => {
  if (models.length === 0) {
    return {} as BaselineModelResponse;
  }

  const defaultModel = models.filter(model => model.isGlobalDefault);

  return defaultModel.length > 0 ? defaultModel[0] : models[0];
};

export function validModelsForPointType(
  models: BaselineModelResponse[],
  pointType: PointType,
): BaselineModelResponse[] {
  return models
    .filter(model => model.valid)
    .filter(model => model.pointType === pointType);
}

/**
 * Chooses the default commodity type from the
 * list of available availableCommodities, *excluding* weather-related ones
 * (TEMPERATURE, HUMIDITY). If ELECTRICITY_USAGE is available,
 * it will be the default. Otherwise, the first non-weather type
 * in the list will be used. If there are no non-weather types
 * available, ELECTRICITY_USAGE will be returned for lack of a
 * better option.
 *
 * @param availableCommodities
 */
function defaultCommodity(availbleCommodities: PointType[]): PointType {
  const availableNonWeatherCommodities = availbleCommodities.filter(
    commodity =>
      !(
        commodity === PointType.TEMPERATURE || commodity === PointType.HUMIDITY
      ),
  );
  return availableNonWeatherCommodities.includes(PointType.ELECTRICITY_USAGE)
    ? PointType.ELECTRICITY_USAGE
    : availableNonWeatherCommodities[0] || PointType.ELECTRICITY_USAGE;
}

/**
 * Initializes the BaselineCardState given a list of baselineModels
 * and a list of availableCommodities, and also supportsHourly (in case the
 * building doesn't have hourly baselines) and the timezone so we can do
 * local time calculations. The selected commodity and the selected model
 * will both be initialized to the appropriate defaults.
 *
 * @param input
 */
export function initializeBaselineCardState(input: {
  baselineModels: BaselineModelResponse[];
  availableCommodities: PointType[];
  supportsHourly?: boolean;
  timezone: string;
}): BaselineCardState {
  const selectedCommodity = defaultCommodity(input.availableCommodities);
  const validModels = validModelsForPointType(
    input.baselineModels,
    selectedCommodity,
  );
  const defaultModel = findDefaultModel(validModels);
  const modelNames = validModels.reduce((prev, curr) => {
    return { ...prev, [curr.id]: curr.modelName };
  }, {});

  const chartDataRange = input.supportsHourly
    ? ChartDataRange.SevenDays
    : ChartDataRange.ThirtyDays;
  const granularity = input.supportsHourly
    ? ChartDataGranularity.Hour
    : ChartDataGranularity.Day;
  return {
    validModels,
    defaultModel,
    selectedModel: defaultModel,
    modelNames,
    baselineUnavailable: validModels.length === 0,
    selectedCommodity,
    granularity,
    allowedGranularities: input.supportsHourly
      ? [ChartDataGranularity.Hour, ChartDataGranularity.Day]
      : [ChartDataGranularity.Day],
    chartDataRange,
    aggregateStatistics: {
      totalUsage: undefined,
      totalBaselineUsage: undefined,
      percentDifference: undefined,
    },
    trainingPeriod: {
      startTime: undefined,
      endTime: undefined,
    },
    smae: undefined,
    nonOperatingHours: [] as ITimeRange[],
    chartData: false,
    chartTimeRange: createTimeRange(
      chartDataRange,
      input.timezone,
      granularity,
    ),
  };
}

type BaselineCardReducerInitializeAction = {
  type: "INITIALIZE";
  payload: {
    baselineModels: BaselineModelResponse[];
    availableCommodities: PointType[];
    supportsHourly?: boolean;
    timezone: string;
  };
};

type BaselineCardReducerSetSelectedModelAction = {
  type: "SET_SELECTED_MODEL";
  payload: {
    selectedModelId: string;
  };
};

type BaselineCardReducerSetSelectedCommodityAction = {
  type: "SET_SELECTED_COMMODITY";
  payload: {
    selectedCommodity: PointType;
    baselineModels: BaselineModelResponse[];
  };
};

type BaselineCardReducerSetGranularityAction = {
  type: "SET_GRANULARITY";
  payload: {
    granularity: ChartDataGranularity;
  };
};

type BaselineCardReducerUpdateChangedDataAction = {
  type: "UPDATE_CHANGED_DATA";
  payload: {
    trainingPeriod: ITimeRange | { startTime: undefined; endTime: undefined };
    consolidatedData: ICombinedIntervalData | false;
    smae: number | undefined;
    summedUsage: number | undefined;
    summedBaseline: number | undefined;
    percentDiff: number | undefined;
    nonOperatingTimeRange: ITimeRange[];
    baselineUnavailable: boolean;
  };
};

type BaselineCardReducerSetChartTimeRangeAction = {
  type: "SET_CHART_TIME_RANGE";
  payload: {
    startTime: string;
    endTime: string;
  };
};

type BaselineCardReducerUpdateFromTimePickerChangeAction = {
  type: "UPDATE_FROM_TIME_PICKER_CHANGE";
  payload: {
    granularity: ChartDataGranularity;
    chartTimeRange: ITimeRange;
    chartDataRange: ChartDataRange;
  };
};

type BaselineCardReducerAction =
  | BaselineCardReducerInitializeAction
  | BaselineCardReducerSetSelectedModelAction
  | BaselineCardReducerSetSelectedCommodityAction
  | BaselineCardReducerSetGranularityAction
  | BaselineCardReducerSetChartTimeRangeAction
  | BaselineCardReducerUpdateFromTimePickerChangeAction
  | BaselineCardReducerUpdateChangedDataAction;

/**
 * Some logic this reducer operates with:
 *
 * Granularity can be either HOUR or DAY. It can only be HOUR if supportsHourly
 * is true (hourly baselines are enabled) and if the range supports it (Today,
 * Seven Days, or Thirty Days). User can set granularity arbitrarily as long as
 * it's supported, but defaults will be determined by supportsHourly and chartDataRange.
 *
 * Default commodity is ELECTRICITY_USAGE if it's available. If it's not available,
 * the default is the first non-weather (TEMPERATURE, HUMIDITY) commodity in the
 * available list. If there are no non-weather commodities available, just set
 * it to ELECTRICITY_USAGE because it's gotta be something. The user can select any
 * of the available commodities, but it will initialize to the default.
 *
 * baselineUnavailable will be true if there are no valid models. Otherwise, it should
 * be false unless we later set it to true because we couldn't pull baseline data.
 *
 * Chart defaults to 7 day chart if hour granularity, and 30 day chart if day granularity.
 *
 * Chart data will have to be updated if any of chart data range / time range, granularity,
 * or commodity change.
 *
 * @param state
 * @param action
 */
export function baselineCardReducer(
  state: BaselineCardState,
  action: BaselineCardReducerAction,
): BaselineCardState {
  switch (action.type) {
    case "SET_SELECTED_MODEL":
      return {
        ...state,
        selectedModel:
          state.validModels.find(
            model => model.id === action.payload.selectedModelId,
          ) || ({} as BaselineModelResponse),
      };
    case "SET_SELECTED_COMMODITY":
      const newValidModels = validModelsForPointType(
        action.payload.baselineModels,
        action.payload.selectedCommodity,
      );
      const newModelNames = newValidModels.reduce((prev, curr) => {
        return { ...prev, [curr.id]: curr.modelName };
      }, {});
      return {
        ...state,
        validModels: newValidModels,
        modelNames: newModelNames,
        selectedCommodity: action.payload.selectedCommodity,
        selectedModel: findDefaultModel(newValidModels),
        defaultModel: findDefaultModel(newValidModels),
        baselineUnavailable: newValidModels.length === 0,
      };
    case "SET_GRANULARITY":
      const granularity = state.allowedGranularities.includes(
        action.payload.granularity,
      )
        ? action.payload.granularity
        : ChartDataGranularity.Day;
      return { ...state, granularity };
    case "SET_CHART_TIME_RANGE":
      const _newChartDataRange = chartDataRangeFromInterval({
        ...state.chartTimeRange,
      });
      return {
        ...state,
        chartTimeRange: { ...action.payload },
        chartDataRange: _newChartDataRange,
      };
    case "UPDATE_CHANGED_DATA":
      return {
        ...state,
        trainingPeriod: action.payload.trainingPeriod,
        chartData: action.payload.consolidatedData || false,
        aggregateStatistics: {
          totalUsage: action.payload.summedUsage,
          totalBaselineUsage: action.payload.summedBaseline,
          percentDifference: action.payload.percentDiff,
        },
        smae: action.payload.smae,
        nonOperatingHours: action.payload.nonOperatingTimeRange,
        baselineUnavailable: action.payload.baselineUnavailable,
      };
    case "UPDATE_FROM_TIME_PICKER_CHANGE":
      return {
        ...state,
        granularity: action.payload.granularity,
        chartTimeRange: action.payload.chartTimeRange,
        chartDataRange: action.payload.chartDataRange,
      };
    case "INITIALIZE":
      return initializeBaselineCardState(action.payload);
    default:
      return { ...state };
  }
}
