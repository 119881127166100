import React, { forwardRef, ForwardRefRenderFunction } from "react";
import styled from "styled-components/macro";
import {
  MxReactIcon,
  Checkmark,
  X,
} from "src/componentLibrary/react/mx-icon-react";
import { useIntl } from "react-intl";
import { AppColors } from "../Styling";

export interface IInputStateProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string | boolean;
  success?: string | boolean;
  minWidth?: string;
}

function getColor(
  props: IInputStateProps,
  positiveColor: string,
  negativeColor: string,
  defaultColor: string,
) {
  return props.success
    ? positiveColor
    : props.error
    ? negativeColor
    : defaultColor;
}

const green = AppColors.primary["msr-green"];
const red = AppColors.semantic.red.red;
const white = AppColors.neutral["light-gray-9"];
const mediumGray = AppColors.neutral.navy;
const lightGray = AppColors.neutral["light-navy-9"];

const StyleInput = styled.input`
  padding: 11px 32px 11px 16px;
  color: ${props => getColor(props, green, red, mediumGray)};
  background: ${white};
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid ${props => getColor(props, green, red, white)};
  width: 100%;
  :focus {
    outline: none;
  }
  :disabled {
    background: ${lightGray};
  }
  ::placeholder {
    color: ${AppColors.neutral["light-navy-1"]};
  }
`;

const Icon = styled(MxReactIcon)`
  position: absolute;
  right: 16px;
  top: 16px;
  color: ${props => (props.Icon === Checkmark ? green : red)};
`;

const InputWrapper = styled.div<{ minWidth?: string }>`
  position: relative;
  display: inline-block;
  min-width: ${props => props.minWidth ?? "300px"};
  width: 20%;
  max-width: 600px;
`;

const Input: ForwardRefRenderFunction<HTMLInputElement, IInputStateProps> = (
  { placeholder, success, error, minWidth, ...props },
  ref,
) => {
  const translate = useIntl();
  return (
    <InputWrapper minWidth={minWidth}>
      <StyleInput
        {...props}
        ref={ref}
        placeholder={translate.formatMessage({ id: placeholder ?? "" })}
      />
      {success && (
        // TODO: Styles of "Icon" component defined above were not being
        // applied as expected, so using the style prop here. Hopefully
        // temporarily.
        <MxReactIcon
          Icon={Checkmark}
          style={{
            position: "absolute",
            right: "16px",
            top: "12px",
            color: green,
          }}
        />
      )}
      {error && (
        <MxReactIcon
          Icon={X}
          style={{
            position: "absolute",
            right: "16px",
            top: "12px",
            color: red,
          }}
        />
      )}
    </InputWrapper>
  );
};

export default forwardRef(Input);

export const InputArea = styled.div`
  margin: 24px 0;
`;

export const Inputs = styled.div`
  margin-top: 48px;
`;
