import React from "react";
import { ReferenceArea } from "recharts";
import { AppColors } from "src/components/common/Styling";
import { OverlayTimeSpans, OverlayEventState, OverlayTimeSpan } from "../types";
import { includesDifferentEventTypes } from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";

export const eventReferenceAreas = (
  overlaySpans: OverlayTimeSpans,
  yAxisId: string | number | undefined,
  xAxisId: string | number | undefined,
  overlayState: OverlayEventState,
): React.ReactNode[] => {
  const referenceAreaCollection: React.ReactNode[] = [];
  overlaySpans.forEach((spans, index) => {
    const enabledSpans = spans.filter(span => overlayState[span.eventType]);

    if (enabledSpans.length === 1) {
      const span = enabledSpans[0];

      if (span.visible) {
        referenceAreaCollection.push(
          <ReferenceArea
            key={`referenceArea${index}`}
            ifOverflow={"hidden"}
            x1={span.xAxisStart}
            x2={span.endTime.getTime()}
            fill={span.fillColor}
            yAxisId={yAxisId}
            xAxisId={xAxisId}
          />,
        );
      }
    } else if (
      enabledSpans.length > 1 &&
      includesDifferentEventTypes(enabledSpans)
    ) {
      const { startTime, endTime } = getMinStartAndMaxEnd(enabledSpans);
      referenceAreaCollection.push(
        <ReferenceArea
          key={`referenceArea${index}`}
          ifOverflow={"hidden"}
          x1={startTime.getTime()}
          x2={endTime.getTime()}
          fill={AppColors.semantic.green["light-msr-green-8"]}
          yAxisId={yAxisId}
          xAxisId={xAxisId}
        />,
      );
    } else {
      enabledSpans.forEach((span, index2) => {
        if (span.visible) {
          referenceAreaCollection.push(
            <ReferenceArea
              key={`referenceArea${index}_${index2}`}
              ifOverflow={"hidden"}
              x1={span.xAxisStart}
              x2={span.endTime.getTime()}
              fill={span.fillColor}
              yAxisId={yAxisId}
              xAxisId={xAxisId}
            />,
          );
        }
      });
    }
  });

  return referenceAreaCollection;
};

export default eventReferenceAreas;

// timezone should already have been accounted for at this point
const getMinStartAndMaxEnd = (spans: OverlayTimeSpan[]) => {
  const earliestStart = Math.min(
    // xAxisStart determines where on the chart it should be rendered, not startTime
    ...spans.map(span => span.xAxisStart),
  );
  const latestEnd = Math.max(...spans.map(span => span.endTime.getTime()));

  return {
    startTime: new Date(earliestStart),
    endTime: new Date(latestEnd),
  };
};
