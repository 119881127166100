import { OverlayType } from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import CustomSelect from "src/components/common/Dropdown/CustomSelect";
import { FormattedMessage } from "react-intl";
import React from "react";

export const OverlayTypeSelector = ({
  onChange,
  defaultSelected,
  width = "100%",
  disabled = false,
}: {
  onChange: (value: OverlayType) => void;
  defaultSelected?: OverlayType;
  width?: string;
  disabled?: boolean;
}) => {
  const items: OverlayType[] = Object.values(OverlayType).filter(
    t =>
      !(
        t === OverlayType.MeasureIdentified ||
        t === OverlayType.MeasureImplemented
      ),
  );
  return (
    <CustomSelect
      disabled={disabled}
      values={items}
      value={defaultSelected}
      renderSelectedItem={selectedItem => {
        return selectedItem ? (
          <FormattedMessage id={`charts.explorer.overlays.${selectedItem}`} />
        ) : (
          <FormattedMessage id="charts.explorer.overlays.create.selectType" />
        );
      }}
      renderItem={item => {
        return <FormattedMessage id={`charts.explorer.overlays.${item}`} />;
      }}
      onChange={onChange}
      displayPropsForButton={() => {
        return {
          width: width,
        };
      }}
    />
  );
};
