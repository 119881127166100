import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components/macro";
import selectionIcon from "src/components/common/MakeSelection/make-a-selection.svg";
import { H1, Body } from "src/components/common/Typography";

export const MakeSelection: React.FC<any> = () => {
  return (
    <SelectionContainer>
      <img src={selectionIcon} alt="Make a selection" />
      <SelectionHeading>
        <FormattedMessage id="charts.makeSelection" />
      </SelectionHeading>
      <SelectionText>
        <FormattedMessage id="charts.selection_instructions" />
      </SelectionText>
    </SelectionContainer>
  );
};

const SelectionContainer = styled.div`
  text-align: center;
  margin-top: 64px;
`;

const SelectionHeading = styled(H1)`
  margin: 24px;
`;

const SelectionText = styled(Body)`
  max-width: 400px;
  margin: auto;
`;
