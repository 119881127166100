import React from "react";
import { styled } from "@storybook/theming";
import { AssetType } from "../ExplorerPage/types";
import RoleBasedComponent from "src/components/common/RoleOnlyComponent";
import { Body, InlineH4, InfoText2 } from "src/components/common/Typography";
import { Box, VerticalLayout } from "src/components/common/Layout";
import { AppColors } from "src/components/common/Styling";
import {
  LatestSelection,
  useLatestSelectionContext,
} from "src/components/app/AdminTools/LastSelectionContext";
import {
  MxReactIcon,
  ChevronDown,
} from "src/componentLibrary/react/mx-icon-react";
import InfoAccordion from "../../common/InfoAccordion";
import { PageErrorBoundary } from "../../common/ApplicationError/PageErrorBoundary";
import { LoadedBuilding } from "./Buildings/LoadedBuilding";
import { LoadedMeter } from "./Meters/LoadedMeter";
import { tracking } from "../../../tracking";
import { LoadedEquipment } from "./Equipment/LoadedEquipment";
import { FloatingAdminToolsEvents } from "./FloatingAdminToolsEvents";
import { UserRole } from "src/types/roles";

const FeatureDescription = styled.div`
  max-width: 345px;
  margin-bottom: 20px;
`;

const FloatingDialog = styled.div<{ expanded?: boolean }>`
  position: fixed;
  right: 10px;
  top: 75px;
  opacity: 0.5;
  // TODO: make this better possibly
  transform: ${props =>
    props.expanded ? "none" : "translate(8px, 0px) rotate(90deg)"};

  &:hover {
    opacity: 1;
  }
`;

const ExpandHideButton = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${AppColors.primary["msr-green"]};
`;
const DialogInner = styled.div`
  background-color: ${AppColors.neutral.white};
`;

const DialogTitle = styled.div<{ expanded: boolean }>`
  display: flex;
  background-color: ${AppColors.primary["msr-green"]};
  color: ${AppColors.neutral.white};
  height: 36px;
  align-items: center;
  padding-right: ${props => (props.expanded ? "8px" : "0px")};
`;

const DialogContent = styled(Box)`
  background-color: ${AppColors.primary["light-msr-green"]};
  min-height: 15em;
  min-width: 25em;

  td {
    padding-left: 8px;
  }

  /*
  MAKE ME LIVE TO HAVE THE TOP STUFF SIDE BY SIDE!!
  dt {
    float: left;
    margin-right: 4px;
  }
  */
`;

export const BasicData = (props: LatestSelection) => {
  const { asset } = props;
  if (asset !== undefined) {
    return (
      <VerticalLayout childSpacing={0}>
        {asset.name && <Body>{asset.name}</Body>}
        <InlineH4>{asset.assetType}</InlineH4>
        <InfoText2>{asset.assetId}</InfoText2>
      </VerticalLayout>
    );
  } else {
    return <div>No asset selected.</div>;
  }
};

const LoadData = (props: LatestSelection) => {
  const { asset } = props;
  if (asset === undefined) {
    return null;
  }

  tracking.fireEvent(FloatingAdminToolsEvents.CLICK_ASSET_WITH_TOOL_SHOWING, {
    props,
  });

  switch (asset.assetType) {
    case AssetType.BUILDING:
      return <LoadedBuilding asset={asset} />;
    case AssetType.METER:
      return <LoadedMeter asset={asset} />;
    // TODO: add equipment point type here
    case AssetType.EQUIPMENT_POINT:
      return <LoadedEquipment asset={asset} />;
    // TODO: add equipment point type here
    default:
      return <div>Unsupported type {asset.assetType}</div>;
  }
};

export const FloatingAdminTools = () => {
  const { latestSelection } = useLatestSelectionContext();
  const [expanded, setExpanded] = React.useState(false);
  return (
    <PageErrorBoundary>
      <RoleBasedComponent
        require={[
          UserRole.ADMIN,
          UserRole.FIELD_OPERATOR,
          UserRole.CUSTOMER_SUPPORT,
        ]}
      >
        <FloatingDialog expanded={expanded}>
          <div style={{ display: "flex" }}>
            <DialogInner>
              <DialogTitle
                onClick={() => {
                  setExpanded(e => !e);
                  tracking.fireEvent(
                    FloatingAdminToolsEvents.ADMIN_TOOLS_TOGGLED,
                    {
                      expanded: !expanded,
                    },
                  );
                }}
                expanded={expanded}
              >
                <ExpandHideButton>
                  <MxReactIcon
                    Icon={ChevronDown}
                    color={AppColors.neutral.white}
                  />
                </ExpandHideButton>
                {expanded && <Body>HatchAdmin Tools (Preview)</Body>}
              </DialogTitle>
              {expanded && (
                <>
                  {latestSelection === undefined && <p>No current selection</p>}
                  {latestSelection && (
                    <DialogContent padding={2}>
                      <VerticalLayout childSpacing={2}>
                        <LoadData asset={latestSelection.asset} />
                        {/*<BasicData asset={latestSelection.asset} />*/}
                        {/*{latestSelection.asset && (*/}
                        {/*  <LoadData asset={latestSelection.asset} />*/}
                        {/*)}*/}
                      </VerticalLayout>
                    </DialogContent>
                  )}
                  <InfoAccordion
                    titleId="adminTools.FloatingAdminTools.InfoAccordion.title"
                    startOpen={false}
                  >
                    <FeatureDescription>
                      <Body>
                        <InlineH4>
                          This is an experimental feature for Hatch operators
                          that provides metadata about the building model in a
                          self-serve manner. At the moment it is only available
                          for building, equipment, and meter entities.
                        </InlineH4>
                      </Body>
                    </FeatureDescription>
                    <FeatureDescription>
                      How to use it
                      <Body>
                        <InlineH4>
                          Click on an element in the Explorer or Performance
                          asset selector to populate the dialog with information
                          about the entity. The dialog will be populated with
                          the last element you click on. This approach to
                          navigation will be replaced in the future for
                          something more intuitive.
                        </InlineH4>
                      </Body>
                    </FeatureDescription>
                  </InfoAccordion>
                </>
              )}
            </DialogInner>
          </div>
        </FloatingDialog>
      </RoleBasedComponent>
    </PageErrorBoundary>
  );
};
