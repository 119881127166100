import { VerticalLayout } from "src/components/common/Layout/VerticalLayout";
import { Body, InlineH4, InfoText2 } from "src/components/common/Typography";
import { AssetType } from "../../ExplorerPage/types";
import { useQuery } from "@apollo/client";
import {
  EquipmentByIdDocument,
  EquipmentIdFromPointIdDocument,
} from "../../../../queries/typed";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import { EquipmentPointsTable } from "./EquipmentPointsTable";
import React, { useState } from "react";
import { BasicData } from "../FloatingAdminTools";
import {
  Equipment,
  EquipmentPoint,
  Maybe,
  PointDiagnostics,
} from "../../../../types/graphql";

const AdvancedData = (props: {
  asset: { __typename?: "Equipment" } & Pick<
    Equipment,
    "id" | "name" | "equipmentType" | "customerId"
  > & {
      points?: Maybe<
        Array<
          Maybe<
            { __typename?: "EquipmentPoint" } & Pick<
              EquipmentPoint,
              "id" | "name" | "customerPointId" | "ingestPointId"
            > & {
                diagnostics?: Maybe<
                  { __typename?: "PointDiagnostics" } & Pick<
                    PointDiagnostics,
                    | "lastDefinedTime"
                    | "lastNonZeroTime"
                    | "lastReceiveTime"
                    | "lastUpdateTime"
                  >
                >;
              }
          >
        >
      >;
    };
}) => {
  if (props.asset) {
    const equipment = props.asset;
    if (equipment) {
      return (
        <VerticalLayout childSpacing={4}>
          <dl>
            <dt>
              <InfoText2>Name:</InfoText2>
            </dt>
            <dl>
              <InlineH4>{equipment?.name ?? "--"}</InlineH4>
            </dl>
            <dt>
              <InfoText2>Customer Id:</InfoText2>
            </dt>
            <dl>
              <InlineH4>{equipment?.customerId ?? "--"}</InlineH4>
            </dl>
          </dl>
          <EquipmentPointsTable points={equipment.points} />
        </VerticalLayout>
      );
    }
  }
  return (
    <div>
      <Body>Unknown Selection Type</Body>
    </div>
  );
};

export const LoadedEquipment = (props: {
  asset: {
    assetType: AssetType;
    assetId: string;
    name?: string;
  };
}) => {
  // TODO: this multiple query approach really shouldn't be necessary but the equipmentPointById
  //  resolver returns equipmentId when it really should return Equipment. Then you could
  //  traverse directly. This can be simplified if that API is shifted to our standard approach.
  const [equipmentId, setEquipmentId] = useState<string>();

  const { loading: idLoading, error: idError } = useQuery(
    EquipmentIdFromPointIdDocument,
    {
      variables: {
        id: props.asset.assetId,
      },
      onCompleted: data => {
        setEquipmentId(data.equipmentPointById?.equipmentId);
      },
    },
  );

  const { loading, data, error } = useQuery(EquipmentByIdDocument, {
    variables: {
      id: equipmentId ?? "",
    },
    skip: !equipmentId,
    onCompleted: data => console.log("Completed with ", data),
  });

  if (idLoading || loading) {
    return <CenteredLoadingIndicator />;
  } else if (idError || error) {
    console.error(idError || error);
  } else if (data && data.equipmentById) {
    // TODO: this hack is because you get the equipment point id, not the equipment. We override
    //  the behavior and pretend you clicked on the equipment. As with several other comments in this module,
    //  if we fixed the asset selector so it didn't try to "fix" the clicking so much or provided the original context,
    //  these hacks wouldn't be necessary.
    return (
      <>
        <BasicData
          asset={{
            assetId: data.equipmentById.id,
            assetType: AssetType.EQUIPMENT,
            name: data.equipmentById.name,
          }}
        />
        <AdvancedData asset={data.equipmentById} />
      </>
    );
  }
  return <div>Loading failed.</div>;
};
