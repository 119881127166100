import styled from "styled-components/macro";
import { FC } from "react";

type IconSize = "xs" | "s" | "m" | "l";

/**
 * OMG, Why is `Icon` capitalized!! Surely that is a mistake!
 *
 * No! We are taking in an unrendered React Component as a prop,
 * which will be directly called like `<Icon />` inside. The capital "I"
 * is a reminder of this (and avoids us having to do `const Icon = props.icon` inside)
 */
interface MxReactIconProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  size?: IconSize;
  color?: string;
  Icon: FC;
}
// NOTE: if you add sizes, multiples / divisors of 18 look best
const pxFromSize = (s: IconSize) => {
  switch (s) {
    case "l":
      return 36;
    case "m":
      return 24;
    case "xs":
      return 9;
    default:
      return 18;
  }
};
const ReactIconDiv = styled.div<{ size: IconSize; color?: string }>`
  box-sizing: border-box;
  display: inline-flex;
  height: ${props => `${pxFromSize(props.size)}px`};
  width: ${props => `${pxFromSize(props.size)}px`};
  color: ${props => props.color};

  // this is because some of the view boxes are wonky, so this forces them to fit
  & svg {
    height: ${props => `${pxFromSize(props.size)}px`};
    width: ${props => `${pxFromSize(props.size)}px`};
    position: relative;
  }
`;

export const MxReactIcon = (props: MxReactIconProps) => {
  const { Icon, size = "s", color, style, onClick, ...rest } = props;
  return (
    <ReactIconDiv
      onClick={onClick}
      color={color}
      size={size}
      style={style}
      {...rest}
    >
      <Icon />
    </ReactIconDiv>
  );
};
