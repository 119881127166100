import styled from "styled-components/macro";
import React from "react";
import { AppColors } from "../Styling";

/**
 * Makes a tinted (translucent black) overlay that will
 * cover the whole screen and center any content it holds.
 */
export const TintedFullScreenContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(43, 48, 52, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const GenericModalContentWrapper = styled.div`
  background-color: ${AppColors.neutral.white};
  padding: 36px 32px;
  border-radius: 4px;
`;

/**
 * just a bar that sends stuff to either side and would
 * pushes content down with it's margin.
 */
export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

/**
 * Modals seem to have 30px of space between rows of stuff, so
 * you can use this to do that if you want!
 */
export const ModalRow = styled.div`
  margin-bottom: 30px;
`;

export const ButtonSpacer = styled.div`
  margin-left: 68px;
  display: flex;
  align-items: center;
`;

export const RowItem = styled.td`
  padding-left: 32px;
  &:first-child {
    padding-left: 0px;
  }
`;

export const BulletList = styled.ul`
  padding-left: 32px;
`;

interface ITintedBGModal {
  children: React.ReactNode;
}
export const TintedBGModal: React.FC<ITintedBGModal> = ({ children }) => (
  <TintedFullScreenContainer>
    <GenericModalContentWrapper>{children}</GenericModalContentWrapper>
  </TintedFullScreenContainer>
);
