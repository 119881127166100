import React from "react";
import styled from "styled-components/macro";
import { H1, Body } from "src/components/common/Typography";
import {
  MxReactIcon,
  Clock,
  X,
} from "src/componentLibrary/react/mx-icon-react";
import { FormattedMessage } from "react-intl";
import {
  TintedFullScreenContainer,
  ModalHeader,
} from "src/components/common/ModalParts";
import { AppColors } from "src/components/common/Styling";
import {
  MxPrimaryReactButton,
  MxSecondaryReactButton,
} from "src/componentLibrary/react/mx-button/MxReactButton";

interface ISessionTimeoutModalProps {
  time: number;
  logoutHandler: () => void;
}

// take MS and make a nice _ minute _ seconds
const formatTime = (time: number): React.ReactChild => {
  if (time <= 0) {
    return <FormattedMessage id="session.timeout.expired" />;
  }
  const seconds = Math.round(time / 1000);
  const minutes = Math.floor(seconds / 60);
  return (
    <>
      {minString(minutes)} {secondsString(seconds - minutes * 60)}
    </>
  );
};
const minString = (minutes: number): React.ReactChild => {
  if (minutes <= 0) {
    return "";
  }
  const msgId =
    minutes > 1 ? "session.timeout.minutes" : "session.timeout.minute";
  return <FormattedMessage id={msgId} values={{ minutes }} />;
};
const secondsString = (seconds: number): React.ReactChild => {
  if (seconds <= 0) {
    return "";
  }
  const msgId = "session.timeout.seconds";
  return <FormattedMessage id={msgId} values={{ seconds }} />;
};

const SessionTimeoutModal: React.FC<ISessionTimeoutModalProps> = ({
  time,
  logoutHandler,
}) => {
  const handleLogoutButton = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    logoutHandler();
  };

  return (
    <TintedFullScreenContainer>
      <ModalContentWrapper>
        <ModalHeader>
          <FormattedMessage id="session.timeout.label" />
          <MxReactIcon Icon={X} color={AppColors.neutral.navy} />
        </ModalHeader>
        <ModalContent>
          <Body>
            <FormattedMessage id="session.timeout.expireMessage" />
          </Body>
          <CountDownTimer>
            <MxReactIcon Icon={Clock} color={AppColors.primary["msr-green"]} />{" "}
            {formatTime(time)}
          </CountDownTimer>
          <Body>
            <FormattedMessage id="session.timeout.instructions" />
          </Body>
        </ModalContent>
        <ModalFooter>
          <MxSecondaryReactButton
            onClick={handleLogoutButton}
            intlTextId="session.timeout.logout"
          />
          <MxPrimaryReactButton intlTextId="session.timeout.continue" />
        </ModalFooter>
      </ModalContentWrapper>
    </TintedFullScreenContainer>
  );
};
export default SessionTimeoutModal;

const ModalContentWrapper = styled.div`
  background-color: ${AppColors.neutral.white};
  padding: 36px 32px;
  text-align: center;
  max-width: 500px;
  border-radius: 4px;
`;

const CountDownTimer = styled(H1)`
  color: ${AppColors.primary["msr-green"]};
  margin-top: 24px;
  margin-bottom: 24px;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ModalContent = styled.div`
  margin-bottom: 80px;
  margin-top: 48px;
  padding: 0px 48px;
`;
