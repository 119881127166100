import React from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";
import { HyperLink } from "src/components/common/Link";
import { InfoText2 } from "src/components/common/Typography";
import config from "src/configuration";
import { AppColors } from "src/components/common/Styling";

const FooterStyle = styled.div<{ hasBackground: boolean }>`
  padding: ${props => (props.hasBackground ? "24px 16px" : "24px 120px")};
  background-color: ${props =>
    props.hasBackground ? AppColors.neutral.black : "transparent"};
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 24px 16px;
    & div:last-child {
      margin-top: 8px;
    }
  }
`;

const SupportLink = styled(HyperLink)`
  font-size: 12px;
`;

const LegalLink = styled.a`
  font-size: 12px;
  margin-left: 24px;
  font-weight: 400;
`;

type IFooterProps = {
  showSupport?: boolean;
};

const Footer: React.FC<IFooterProps> = ({
  showSupport = true,
}: IFooterProps) => {
  return (
    <FooterStyle hasBackground={showSupport}>
      <div>
        <InfoText2>
          &copy; 2022 {config.COMPANY_NAME}.{" "}
          <FormattedMessage id="footer.all_rights_reserved" />
        </InfoText2>
        <LegalLink href="https://www.hatchdata.com/privacy-terms/">
          Privacy & Terms
        </LegalLink>
      </div>
      {showSupport && (
        <div>
          <InfoText2>
            <FormattedMessage id="footer.need_help" />{" "}
          </InfoText2>
          <SupportLink
            href={"mailto:" + config.SUPPORT_EMAIL}
            target="_blank"
            rel="noreferrer noopener"
          >
            {config.SUPPORT_EMAIL}
          </SupportLink>
        </div>
      )}
    </FooterStyle>
  );
};

export default Footer;
