import React, { useContext } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import CreateProgress from "./CreateProgress";
import RangeSlider from "src/components/app/ExplorerPage/OverlayStuff/CreateOverlayStuff/RangerSlider";
import {
  OverlayFormAction,
  OverlayFormState,
  OverlayType,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import {
  BuildingAnnotationReason,
  ExceptionOperatingPeriodInput,
} from "src/types/graphql";
import { ToggleButton } from "src/components/common/Button";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { ErrorToast, SuccessToast } from "src/components/common/Toast";
import {
  CreateScheduleExceptionDocument,
  GetOperatingScheduleDocument,
  LoadScheduleExceptionsForBuildingInExplorerDocument,
  ModifyScheduleExceptionDocument,
} from "src/queries/typed";
import {
  Box,
  HorizontalLayout,
  VerticalLayout,
} from "src/components/common/Layout";
import { HyperLink2 } from "src/components/common/Link";
import { CurrentOrganizationContext } from "src/components/app/AuthenticatedPage";
import { BodyLabel } from "src/components/common/BodyCopy";
import DatePickerOverlay, {
  CalendarIcon,
  NewDatePickerInputStyles,
} from "src/components/common/TimeRangePicker/DatePickerOverlay";
import { useUserContext } from "src/auth/UserContext";
import { ChartDataAction } from "../../chartDataReducer";
import {
  BuildingScheduleExceptionsResponse,
  ScheduleStorage,
} from "../../types";
import { ITimeRange } from "src/types/charting";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import { tracking } from "src/tracking";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { getLocalDateFormat } from "src/helpers/dates";
import { useNavigate } from "react-router-dom";
import {
  MxPrimaryReactButton,
  MxSecondaryReactButton,
} from "src/componentLibrary/react/mx-button/MxReactButton";
import { convertStateToMutationVariablesForStepThreeExceptions } from "src/components/app/ExplorerPage/OverlayStuff/CreateOverlayStuff/convertStateToMutationVariablesForStepThree";
import { Info, MxReactIcon } from "src/componentLibrary/react/mx-icon-react";

const ScheduleStepThreeExceptions = (props: {
  dispatch: (arg0: OverlayFormAction) => void;
  chartDataDispatch: React.Dispatch<ChartDataAction>;
  state: OverlayFormState;
  timeRange: ITimeRange;
}) => {
  const [
    ,
    setScheduleExceptionStorage,
  ] = useLocalStorage<ScheduleStorage | null>("schedule-exception", null);
  const { userData } = useUserContext();
  const navigate = useNavigate();
  const userId = userData.id;
  const dateFormat = getLocalDateFormat();
  const translate = useIntl();

  const parseDate = (input: string) => {
    const date = DateTime.fromFormat(input, dateFormat);

    return date.isValid ? date.toJSDate() : undefined;
  };

  const handleBackButton = () => {
    if (props.state.overlayPanel.state === "EDIT_3") {
      props.dispatch({ type: "GO_EDIT_STEP_TWO" });
    } else {
      props.dispatch({ type: "GO_STEP_TWO" });
    }
  };

  const formatDate = (date: Date) => {
    return DateTime.fromJSDate(date).toFormat(dateFormat);
  };

  const apolloClient = useApolloClient();

  const initializeTimeRange = () => {
    const start = props.state.formData.startTime ?? 8;
    const end = props.state.formData.endTime ?? 20;

    // This means the drag selection extended into the next day, which we are not allowing
    if (end < start) {
      return [start, 24];
    }

    return [start, end];
  };

  const [timeRange, setTimeRange] = React.useState(initializeTimeRange());
  const [isOccupied, setIsOccupied] = React.useState(
    props.state.formData.isOccupied ?? true,
  );

  const { currentOrganizationId } = useContext(CurrentOrganizationContext);
  const {
    getValues,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      startDate:
        props.state.formData.startDate ??
        DateTime.local()
          .startOf("day")
          .plus({ days: 1 })
          .toJSDate(),
    },
  });

  const handleSaveStep = () => {
    props.dispatch({
      type: "SAVE_STEP_THREE",
    });

    const variables = convertStateToMutationVariablesForStepThreeExceptions(
      {
        ...props.state.formData,
        isOccupied,
        startDate: getValues("startDate"),
        startTime: timeRange[0],
        endTime: timeRange[1],
      },
      operatingScheduleResponse?.getBuildingById?.operatingSchedule ||
        undefined,
    );

    if (props.state.formData.overlayType === OverlayType.ScheduleException) {
      if (props.state.formData.overlayId) {
        modifyException({
          variables: {
            ...variables,
            exceptionOperatingPeriods: variables.exceptionOperatingPeriods as ExceptionOperatingPeriodInput[],
            exceptionId: props.state.formData.overlayId,
          },
        });
      } else {
        createException({
          variables,
        });
      }
    }
  };

  const { data: operatingScheduleResponse } = useQuery(
    GetOperatingScheduleDocument,
    {
      variables: { buildingId: props.state.formData.buildingId ?? "" },
      onCompleted: data => {
        if (data && !data.getBuildingById) {
          navigate("/app/404");
        }
      },
    },
  );

  const [createException, { loading }] = useMutation(
    CreateScheduleExceptionDocument,
    {
      onError: e => {
        if (e.message.includes("overlap")) {
          ErrorToast("settings.building.operatingSchedule.conflictError");
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
      onCompleted: response => {
        if (response.createBuildingOperatingScheduleException?.success) {
          tracking.fireEvent(ExplorerPageEvents.COMPLETE_ANNOTATION_CREATE, {
            userId,
            buildingID: props.state.formData.buildingId,
            type: props.state.formData.overlayType,
          });
          SuccessToast("settings.building.operatingSchedule.saved");

          // Reload the exceptions so the user can see the new one!
          fetchScheduleExceptions(
            props.state.formData.buildingId!,
            props.state.formData.timezone!,
          );
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
    },
  );

  const [modifyException, { loading: modLoading }] = useMutation(
    ModifyScheduleExceptionDocument,
    {
      onError: e => {
        if (e.message.includes("overlap")) {
          ErrorToast("settings.building.operatingSchedule.conflictError");
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
      onCompleted: response => {
        if (response.modifyScheduleException?.id) {
          tracking.fireEvent(ExplorerPageEvents.COMPLETE_ANNOTATION_CREATE, {
            userId,
            buildingID: props.state.formData.buildingId,
            type: props.state.formData.overlayType,
          });
          SuccessToast("settings.building.operatingSchedule.saved");

          // Reload the exceptions so the user can see the new one!
          fetchScheduleExceptions(
            props.state.formData.buildingId!,
            props.state.formData.timezone!,
          );
        } else {
          ErrorToast("settings.building.operatingSchedule.error");
        }
      },
    },
  );

  const fetchScheduleExceptions = async (
    buildingId: string,
    tz: string | undefined,
  ) => {
    const { data, errors } = await apolloClient.query({
      query: LoadScheduleExceptionsForBuildingInExplorerDocument,
      variables: {
        buildingId,
      },
      fetchPolicy: "network-only", // forces the data to get refreshed
    });

    if (errors) {
      console.error(
        "failed to receive schedule exceptions for building %s",
        buildingId,
      );
      return;
    } else if (data.getBuildingById?.scheduleExceptions) {
      if (props.state.formData.overlayId) {
        props.chartDataDispatch({
          type: "REMOVE_OVERLAY_EVENT_FROM_CHART",
          payload: { deletedOverlayId: props.state.formData.overlayId },
        });
      }
      props.chartDataDispatch({
        type: "RECEIVE_SCHEDULE_EXCEPTION_EVENT_DATA",
        payload: {
          response: data as BuildingScheduleExceptionsResponse,
          timezone: tz,
          timeRange: props.timeRange,
        },
      });
    }
  };

  const handleMoreOptionsClick = () => {
    tracking.fireEvent(ExplorerPageEvents.MORE_OPTIONS, {
      buildingId: props.state.formData.buildingId,
      userId,
    });

    const { formData } = props.state;

    setScheduleExceptionStorage({
      startDate: getValues("startDate").toISOString(),
      title: formData.title,
      note: formData.note,
      annotationCategories: formData.annotationCategories,
      startHour: timeRange[0] < timeRange[1] ? timeRange[0] : timeRange[1],
      endHour: timeRange[0] < timeRange[1] ? timeRange[1] : timeRange[0],
      isOccupied,
    });
  };

  return (
    <>
      <CreateProgress step={3} />
      <fieldset style={{ border: "none" }}>
        <Box margin={[6, 0, 8, 0]}>
          <VerticalLayout childSpacing={4}>
            <VerticalLayout childSpacing={2}>
              <BodyLabel>
                <MxReactIcon Icon={Info} />{" "}
                <FormattedMessage id="charts.explorer.overlays.create.advancedOptions" />{" "}
                <HyperLink2
                  href={
                    props.state.formData.overlayId
                      ? `/settings/${currentOrganizationId}/building/${props.state.formData.buildingId}/operating-schedule/${props.state.formData.overlayId}/edit`
                      : `/settings/${currentOrganizationId}/building/${props.state.formData.buildingId}/operating-schedule/create`
                  }
                  target="_new"
                  onClick={handleMoreOptionsClick}
                >
                  <FormattedMessage id="charts.explorer.overlays.create.moreOptions" />
                </HyperLink2>
              </BodyLabel>
              <HorizontalLayout>
                <NewDatePickerInputStyles />
                <CalendarIcon />
                <Controller
                  name="startDate"
                  control={control}
                  rules={{
                    required: translate.formatMessage({
                      id:
                        "settings.building.operatingScheduleExceptions.form.validation.startDate.required",
                    }),
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DayPickerInput
                      inputProps={{
                        disabled: props.state.formData.annotationCategories?.includes(
                          BuildingAnnotationReason.HOLIDAY,
                        ),
                        required: true,
                        id: "startDate",
                        className: errors.startDate ? "invalid" : "",
                      }}
                      placeholder={dateFormat}
                      parseDate={parseDate}
                      formatDate={formatDate}
                      overlayComponent={DatePickerOverlay}
                      onDayChange={day => onChange(day)}
                      value={value}
                    />
                  )}
                />
              </HorizontalLayout>
            </VerticalLayout>

            <Box padding={[4, 0, 8]}>
              <RangeSlider values={timeRange} setValues={setTimeRange} />
            </Box>
            <BodyLabel>
              <FormattedMessage id="settings.building.operatingScheduleExceptions.form.isDuringOperating" />
            </BodyLabel>
            <HorizontalLayout childSpacing={4}>
              <ToggleButton
                onClick={() => setIsOccupied(true)}
                isEnabled={isOccupied}
              >
                <FormattedMessage id="common.button.labels.yes" />
              </ToggleButton>
              <ToggleButton
                onClick={() => setIsOccupied(false)}
                isEnabled={!isOccupied}
              >
                <FormattedMessage id="common.button.labels.no" />
              </ToggleButton>
            </HorizontalLayout>
          </VerticalLayout>
        </Box>
      </fieldset>
      <HorizontalLayout childSpacing={2}>
        <MxSecondaryReactButton
          fullWidthOfParent
          onClick={handleBackButton}
          disabled={loading}
          intlTextId="common.button.labels.back"
        />
        <MxPrimaryReactButton
          fullWidthOfParent
          onClick={handleSaveStep}
          disabled={Object.keys(errors).length > 0 || loading}
          intlTextId="common.button.labels.save"
        />
      </HorizontalLayout>
    </>
  );
};
export default ScheduleStepThreeExceptions;
