import {
  DropdownMenu,
  DropdownOption,
} from "src/components/common/Dropdown/Dropdown";
import { useSelect, UseSelectState } from "downshift";
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { AppColors } from "src/components/common/Styling/Colors";
import { controlText1_style } from "src/components/common/ControlText";

export const CustomSelectButton = styled.button<{
  isOpen: boolean;
  width?: string;
  disabled?: boolean;
  backgroundColor?: string;
  color?: string;
  textTransform?: string;
  textDecoration?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : AppColors.neutral.white};
  color: ${props => (props.color ? props.color : AppColors.neutral.navy)};
  height: 36px;
  width: ${props => (props.width ? props.width : "fit-content")};
  min-width: 0px;
  max-width: fit-content;
  text-align: left;
  ${props =>
    props.textTransform ? `text-transform: ${props.textTransform}` : ""}
  ${props =>
    props.textDecoration ? `text-decoration: ${props.textDecoration}` : ""}
  border: ${props =>
    !props.disabled
      ? `1px ${AppColors.neutral["light-navy-3"]} solid`
      : "none"};
  border-radius: 6px;
  padding-left: 16px;
  padding-right: 44px;
  font-size: 16px;
  font-weight: inherit;
  cursor: pointer;
  position: relative;
  font-size: ${controlText1_style.fontSize};
  &:disabled {
    color: ${controlText1_style.inactiveColor};
    background-color: ${AppColors.neutral["light-navy-9"]};
    cursor: default;
  }
`;

export const CustomSelectCaret = styled.div<{
  isOpen: boolean;
  caretColor?: string;
}>`
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: 32px;
  rotate: ${props => (props.isOpen ? "180deg;" : "0deg;")};
  border-radius: 0% 15% 15% 0%;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23${props =>
    props.caretColor
      ? props.caretColor.slice(1)
      : AppColors.neutral.navy.slice(
          1,
        )}%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: center;
  background-size: 25% 25%;
`;

interface ItemDisplayProps {
  backgroundColor?: string;
  color?: string;
  caretColor?: string;
  caretBackgroundColor?: string;
  width?: string;
  textTransform?: string;
  textDecoration?: string;
}

export interface CustomSelectProps<T> {
  values: T[];
  value?: T;
  renderSelectedItem: (value: T | undefined) => ReactNode;
  renderItem: (value: T) => ReactNode;
  onChange: (selectedItem: T) => void;
  displayPropsForButton?: (value: T | undefined) => ItemDisplayProps;
  disabled?: boolean;
  width?: string;
  dataTestId?: string;
}

const basicDisplayProps = (): ItemDisplayProps => ({
  backgroundColor: AppColors.neutral.white,
  color: AppColors.neutral.navy,
  caretColor: AppColors.neutral.navy,
  width: "fit-content",
});

export const CustomSelect = <T,>({
  values,
  value,
  renderSelectedItem,
  renderItem,
  onChange,
  displayPropsForButton = basicDisplayProps,
  disabled = false,
  width = "100%",
  dataTestId,
}: CustomSelectProps<T>) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    items: values,
    selectedItem: value,
    onSelectedItemChange: changes => {
      if (changes.selectedItem) {
        onChange(changes.selectedItem);
      }
    },
  });

  return (
    <div style={{ position: "relative", maxWidth: width }}>
      <CustomSelectButton
        isOpen={isOpen}
        type={"button"}
        role={"select-button"}
        disabled={disabled}
        data-testid={dataTestId}
        {...getToggleButtonProps()}
        {...displayPropsForButton(selectedItem)}
      >
        {renderSelectedItem(selectedItem)}
        <CustomSelectCaret
          isOpen={isOpen}
          caretColor={
            displayPropsForButton(selectedItem).caretColor ??
            AppColors.neutral.navy
          }
        />
      </CustomSelectButton>
      <DropdownMenu role={"listbox"} {...getMenuProps()}>
        {isOpen &&
          values.map(
            (item, index) =>
              item !== selectedItem && (
                <DropdownOption
                  selected={highlightedIndex === index}
                  key={`${item}${index}`}
                  role={"listitem"}
                  {...getItemProps({ item, index })}
                >
                  {renderItem(item)}
                </DropdownOption>
              ),
          )}
      </DropdownMenu>
    </div>
  );
};

export default CustomSelect;
