import config from "./configuration";
import { datadogLogs } from "@datadog/browser-logs";

import { datadogRum } from "@datadog/browser-rum";

const extractOriginFromUrl = (url: string) => new URL(url).origin;

export function init() {
  if (config.DD_TOKEN) {
    datadogLogs.init({
      clientToken: config.DD_TOKEN,
      forwardErrorsToLogs: true,
      env: config.ENVIRONMENT,
      service: "hatch-ui",
      version: config.VERSION,
    });
  }

  if (config.DD_RUM_TOKEN && config.DD_RUM_APP_ID) {
    datadogRum.init({
      applicationId: config.DD_RUM_APP_ID,
      clientToken: config.DD_RUM_TOKEN,
      sampleRate: 100,
      trackInteractions: true,
      env: config.ENVIRONMENT,
      service: `hatch-ui`,
      version: config.VERSION,
      allowedTracingOrigins: [
        extractOriginFromUrl(config.BUILDING_MODEL_API_URL),
        extractOriginFromUrl(config.PDF_SERVICE_URL),
        extractOriginFromUrl(config.UTILITY_SERVICE_URL),
      ],
    });
  }
}
