import {
  buildChartPropertiesForChart,
  buildDataForChart,
  buildMetadataForChart,
  buildXAxisForChart,
  buildYAxisForChart,
  extractMinimaMaximaValueFromMetadata,
  shouldCalculateCommodityAverage,
  shouldCalculateCommoditySum,
  chartDataTimedReducer,
  buildMetricsForChart,
} from "src/components/app/ExplorerPage/parsers/commonBuildChart";
import {
  SpaceGroupResponse,
  ChartDataGranularity,
  ChartDataRange,
  PointType,
  ChartMetadata,
  CommodityChartData,
  IIntervalData,
  IMeasurement,
  ParsedChartData,
} from "src/types/charting";

import {
  averageReducer,
  sumReducer,
  isFifteenMinOrHourGranularity,
  setLastIntervalWithDataToNull,
} from "src/helpers/charting";
import {
  DataQueueItem,
  TimeseriesNode,
} from "src/components/app/ExplorerPage/types";
import {
  keyFromQueueItem,
  timeRangeFromTimeseriesKey,
} from "src/components/app/ExplorerPage/helpers";
import { PresentationProps, ChartDataPayload } from "../chartDataReducer";
import { isFutureDate } from "src/helpers/dates";
import { getSymbolForUnit } from "@hatchdata/equipment-types-package/dist/src";

enum MEASUREMENT_TYPE {
  ELECTRICITY_USAGE = "electricityUsageData",
  ELECTRICITY_DEMAND = "electricityDemandData",
  GENERIC_COMMODITY_USAGE = "commodityUsageData",
}

export type TypedGroupResponse = {
  data: SpaceGroupResponse;
  type: MEASUREMENT_TYPE;
};

export const parseGroupData = (
  payload: ChartDataPayload,
  parsedData: ParsedChartData,
): ParsedChartData => {
  const combinedResponse = {
    data: payload.response as SpaceGroupResponse,
    type: MEASUREMENT_TYPE.GENERIC_COMMODITY_USAGE,
  };

  parsedData.queueItems.push(payload.queueItem);

  let _newYAxisMap = undefined;
  let _newXAxisMap = undefined;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return parsedData;
  }

  const unitName = extractUnitName(combinedResponse);

  const oldMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    parsedData.metadata,
    unitName,
  );

  // Parse metadata
  const { _newMetadata, _newMetrics } = parseMetadataAndMetrics(
    parsedData,
    combinedResponse,
    payload.queueItem,
    unitName,
  );
  // Parse data
  const _newData = parseData(
    parsedData,
    combinedResponse,
    payload.queueItem.commodity,
    payload.queueItem.timeseriesKey,
    payload.queueItem.assetKey,
  );

  // Parse XAxis
  if (!parsedData.xAxes.get(payload.queueItem.timeseriesKey)) {
    const xAxisProps = parseXAxis(
      parsedData,
      combinedResponse,
      payload.rangeSize,
      payload.queueItem.timeseriesKey,
      payload.chartWidth,
    );

    if (xAxisProps) {
      // Clone the map
      _newXAxisMap = new Map(parsedData.xAxes);
      _newXAxisMap.set(payload.queueItem.timeseriesKey, xAxisProps);
    }
  }

  const newMixMaxYAxisValues = extractMinimaMaximaValueFromMetadata(
    _newMetadata,
    unitName,
  );
  // Parse YAxis
  if (oldMixMaxYAxisValues !== newMixMaxYAxisValues) {
    const yAxisProps = parseYAxis(
      parsedData,
      combinedResponse,
      payload.queueItem.commodity,
      _newMetadata,
    );

    if (yAxisProps) {
      // Clone the map
      _newYAxisMap = new Map(parsedData.yAxes);
      _newYAxisMap.set(unitName, yAxisProps);
    }
  }

  // Parse Chart data
  const chartProps = parseChart(
    parsedData,
    combinedResponse,
    payload.queueItem,
    payload.presentationProps,
    payload.isPastComparison,
  );

  const _newChartMap = new Map(parsedData.charts);
  if (chartProps) {
    _newChartMap.set(keyFromQueueItem(payload.queueItem), chartProps);
  }

  return {
    ...parsedData,
    metrics: _newMetrics,
    metadata: _newMetadata,
    data: _newData,
    xAxes: _newXAxisMap ? _newXAxisMap : parsedData.xAxes,
    yAxes: _newYAxisMap ? _newYAxisMap : parsedData.yAxes,
    charts: _newChartMap,
  } as ParsedChartData;
};

const parseChart = (
  currentState: ParsedChartData,
  combinedResponse: TypedGroupResponse,
  queueItem: DataQueueItem,
  presentationProps: PresentationProps,
  isPastComparison?: boolean,
): CommodityChartData | undefined => {
  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.charts.get(keyFromQueueItem(queueItem));
  }

  const unitName = extractUnitName(combinedResponse);

  return buildChartPropertiesForChart(
    queueItem,
    unitName,
    presentationProps,
    isPastComparison,
  );
};

// this needs to take the timeRange, not the rangeSize
const parseXAxis = (
  currentState: ParsedChartData,
  combinedResponse: TypedGroupResponse,
  rangeSize: ChartDataRange,
  timestampKey: string,
  width: number,
) => {
  const response = combinedResponse.data;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.xAxes.get(timestampKey);
  }

  const timezone = response.spaceGroup.commodityUsageData.timezone;
  const values = response.spaceGroup.commodityUsageData.commodityUsage.values;

  const timeRange = timeRangeFromTimeseriesKey(timestampKey);
  const granularity = extractGranularity(combinedResponse);

  return buildXAxisForChart(
    width,
    values,
    timeRange,
    rangeSize,
    timezone,
    timestampKey,
    granularity,
  );
};

const parseYAxis = (
  currentState: ParsedChartData,
  combinedResponse: TypedGroupResponse,
  commodityName: PointType,
  metadata: ChartMetadata,
) => {
  // This guards against bad data!
  const unitName = extractUnitName(combinedResponse);
  if (!isValidData(combinedResponse)) {
    return currentState.yAxes.get(unitName);
  }

  const orientation = "left";

  return buildYAxisForChart(metadata, commodityName, unitName, orientation);
};

const parseData = (
  currentState: ParsedChartData,
  combinedResponse: TypedGroupResponse,
  commodityName: PointType,
  timestampKey: string,
  assetKey: string,
): TimeseriesNode[] => {
  const response = combinedResponse.data;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return currentState.data;
  }

  const values: IMeasurement[] =
    response.spaceGroup.commodityUsageData.commodityUsage.values;

  const timeRange = timeRangeFromTimeseriesKey(timestampKey);
  const granularity = extractGranularity(combinedResponse);

  return buildDataForChart(
    currentState.data,
    timestampKey,
    commodityName,
    assetKey,
    // Only do this for commodity data, not weather data.
    isFifteenMinOrHourGranularity(granularity) &&
      isFutureDate(timeRange.endTime) &&
      values[0].hasOwnProperty("value")
      ? setLastIntervalWithDataToNull(values as IMeasurement[])
      : values,
  );
};

const parseMetadataAndMetrics = (
  currentState: ParsedChartData,
  combinedResponse: TypedGroupResponse,
  queueItem: DataQueueItem,
  unitName: string,
): { _newMetadata: ChartMetadata; _newMetrics: ChartMetadata } => {
  const response = combinedResponse.data;

  // This guards against bad data!
  if (!isValidData(combinedResponse)) {
    return {
      _newMetadata: currentState.metadata,
      _newMetrics: currentState.metrics,
    };
  }
  const values: IMeasurement[] =
    response.spaceGroup.commodityUsageData.commodityUsage.values;

  // for GROUPS we don't know the timezone until we get the data back, so we
  // have to fill in the timezone at this point because the metadata looks at the queueItem to store TZ stuff
  const timezone = response.spaceGroup.commodityUsageData.timezone;
  queueItem.timezone = timezone;

  let minMaxValues;
  minMaxValues = chartDataTimedReducer(values as IMeasurement[], "value");

  const _sumVal = shouldCalculateCommoditySum(queueItem.commodity)
    ? sumReducer(values as IMeasurement[], "value")
    : undefined;
  const _avgVal = shouldCalculateCommodityAverage(queueItem.commodity)
    ? averageReducer(values as IMeasurement[], "value")
    : undefined;

  const _newMetadata = buildMetadataForChart(
    currentState.metadata,
    queueItem,
    minMaxValues,
    _sumVal,
    _avgVal,
    unitName,
  );
  const _newMetrics = buildMetricsForChart(
    currentState.metrics,
    queueItem,
    minMaxValues,
    _sumVal,
    _avgVal,
  );
  return { _newMetadata, _newMetrics };
};

const extractUnitName = (combinedResponse: TypedGroupResponse): string => {
  const response = combinedResponse.data;

  const groupResponse: IIntervalData | undefined =
    response.spaceGroup.commodityUsageData.commodityUsage;

  return groupResponse
    ? getSymbolForUnit((groupResponse as IIntervalData).unit)
    : "";
};

const extractGranularity = (
  combinedResponse: TypedGroupResponse,
): ChartDataGranularity => {
  const response = combinedResponse.data;
  const groupResponse: IIntervalData =
    response.spaceGroup.commodityUsageData.commodityUsage;

  return groupResponse.granularity;
};

const isValidData = (combinedResponse: TypedGroupResponse): boolean => {
  const response = combinedResponse.data;
  const groupResponse = response.spaceGroup.commodityUsageData.commodityUsage;
  return !!(
    groupResponse &&
    groupResponse.values &&
    groupResponse.values.length > 0
  );
};
