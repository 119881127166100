import React, { useContext, useState } from "react";
import CreateProgress from "./CreateProgress";
import { BuildingAnnotationReason } from "src/types/graphql";
import {
  AssetBuilding,
  OverlayFormAction,
  OverlayFormData,
  OverlayFormState,
  OverlayType,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { FormattedMessage } from "react-intl";
import { Box, VerticalLayout } from "src/components/common/Layout";
import Body, { BodyLabel } from "src/components/common/BodyCopy";
import AnnotationTypePicker from "./AnnotationTypePicker";
import { BuildingSelector } from "src/components/app/ExplorerPage/OverlayStuff/Dropdowns";
import { MxPrimaryReactButton } from "src/componentLibrary/react/mx-button/MxReactButton";
import { OverlayTypeSelector } from "src/components/app/ExplorerPage/OverlayStuff/CreateOverlayStuff/OverlayTypeSelector";
import { defaultBuildingForOverlayForm } from "src/components/app/ExplorerPage/OverlayStuff/overlayHelpers";
import { Info, MxReactIcon } from "src/componentLibrary/react/mx-icon-react";
import { HyperLink2 } from "src/components/common/Link";
import { useLocalStorage } from "src/hooks/useLocalStorage";
import { ScheduleStorage } from "src/components/app/ExplorerPage/types";
import { CurrentOrganizationContext } from "src/components/app/AuthenticatedPage";
import { useUserContext } from "src/auth/UserContext";
import { tracking } from "src/tracking";
import { ExplorerPageEvents } from "src/components/app/ExplorerPage/ExplorerPage";
import { DateTime } from "luxon";

const ScheduleStepOne = (props: {
  dispatch: (arg0: OverlayFormAction) => void;
  state: OverlayFormState;
  latitude: number;
  longitude: number;
}) => {
  const [
    ,
    setScheduleExceptionStorage,
  ] = useLocalStorage<ScheduleStorage | null>("schedule-exception", null);

  const { currentOrganizationId } = useContext(CurrentOrganizationContext);
  const { userData } = useUserContext();
  const userId = userData.id;

  const defaultBuilding = (): AssetBuilding | undefined => {
    if (props.state.ui?.buildingsSelected.length === 1) {
      return props.state.ui.buildingsSelected[0];
    }

    if (props.state.formData?.buildingId) {
      return (
        props.state.ui?.buildingsSelected.find(
          x => x.id === props.state.formData?.buildingId,
        ) ?? undefined
      );
    }

    return undefined;
  };
  const [building, setSelectedBuilding] = useState(
    defaultBuildingForOverlayForm(props.state),
  );
  const [overlayType, setOverlayType] = useState<OverlayType | null>(
    props.state.formData.overlayType ?? null,
  );
  const [annotationCategories, setAnnotationCategories] = useState<
    BuildingAnnotationReason[]
  >([]);

  // a comment
  const handleSaveStep = () => {
    if (overlayType !== null && building !== undefined) {
      const payload: Partial<OverlayFormData> = {
        buildingId: building.id,
        overlayType,
        timezone: building.timezone,
        latitude: props.latitude,
        longitude: props.longitude,
        annotationCategories,
      };
      props.dispatch({
        type: "SAVE_STEP_ONE",
        payload,
      });
    }
  };

  function renderOverlayCategoryPicker() {
    switch (overlayType) {
      case null:
        return null;
      default:
        return (
          <AnnotationTypePicker
            defaultSelected={annotationCategories ?? undefined}
            onChange={setAnnotationCategories}
          />
        );
    }
  }

  const handleMoreOptionsClick = () => {
    tracking.fireEvent(ExplorerPageEvents.MORE_OPTIONS, {
      buildingId: building?.id,
      userId,
    });

    const { formData } = props.state;

    const startDate =
      props.state?.formData?.startDate ??
      DateTime.local()
        .startOf("day")
        .toJSDate();

    const endDate =
      props.state?.formData?.endDate ??
      DateTime.local()
        .startOf("day")
        .toJSDate();

    setScheduleExceptionStorage({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      title: formData?.title,
      note: formData?.note,
      annotationCategories,
      startHour: startDate.getHours(),
      endHour: endDate.getHours(),
      isOccupied: true,
    });
  };

  return (
    <>
      <CreateProgress step={1} />
      <Box margin={[6, 0, 8, 0]}>
        <Box margin={[0, 0, 2, 0]}>
          {overlayType === OverlayType.ScheduleException && (
            <Body>
              <MxReactIcon Icon={Info} />{" "}
              <FormattedMessage id="charts.explorer.overlays.create.advancedOptions" />{" "}
              <HyperLink2
                href={`/settings/${currentOrganizationId}/building/${building?.id}/operating-schedule/create`}
                target="_new"
                onClick={handleMoreOptionsClick}
              >
                <FormattedMessage id="charts.explorer.overlays.create.moreOptions" />
              </HyperLink2>
            </Body>
          )}
        </Box>
        <VerticalLayout childSpacing={8}>
          <VerticalLayout childSpacing={2}>
            <BodyLabel className="labels" htmlFor="buildingId">
              <FormattedMessage id="charts.explorer.overlays.create.building" />
            </BodyLabel>
            <BuildingSelector
              defaultValue={building}
              items={props.state.ui.buildingsSelected ?? []}
              onChange={building => {
                setSelectedBuilding(building);
              }}
            />
          </VerticalLayout>
          <VerticalLayout childSpacing={2}>
            <BodyLabel className="labels" htmlFor="overlayType">
              <FormattedMessage id="charts.explorer.overlays.create.overlayType" />
            </BodyLabel>
            <OverlayTypeSelector
              defaultSelected={overlayType ?? undefined}
              onChange={value => {
                if (value) {
                  setOverlayType(value);
                }
              }}
            />
          </VerticalLayout>
          {overlayType !== null && (
            <VerticalLayout childSpacing={2}>
              <BodyLabel className="labels" htmlFor="category">
                <FormattedMessage id="settings.building.operatingScheduleExceptions.form.exceptionCategory" />
              </BodyLabel>
              {renderOverlayCategoryPicker()}
            </VerticalLayout>
          )}
        </VerticalLayout>
      </Box>
      <MxPrimaryReactButton
        fullWidthOfParent
        onClick={handleSaveStep}
        disabled={
          building === undefined ||
          overlayType === null ||
          annotationCategories.length === 0
        }
        intlTextId="common.button.labels.next"
      />
    </>
  );
};

export default ScheduleStepOne;
