import { VerticalLayout } from "src/components/common/Layout/VerticalLayout";
import { Body, InfoText2, InlineH4 } from "src/components/common/Typography";
import { useQuery } from "@apollo/client";
import { MeterMetaDataDocument } from "../../../../queries/typed";
import { CenteredLoadingIndicator } from "src/components/common/LoadingIndicator";
import { PointsTable } from "./PointsTable";
import { BasicData } from "../FloatingAdminTools";
import { Asset } from "../LastSelectionContext";

const AdvancedData = (props: { asset: Asset }) => {
  const { loading, data, error } = useQuery(MeterMetaDataDocument, {
    variables: {
      id: props.asset.assetId,
    },
  });

  if (loading) {
    return <CenteredLoadingIndicator />;
  } else if (error) {
    return <div>Loading failed.</div>;
  } else if (data && data.getMeterById) {
    // NOTE: to change the layout of the DT/DLs, there is some commented out
    // code up in the
    return (
      <VerticalLayout childSpacing={4}>
        <dl>
          <dt>
            <InfoText2>Commodity Name:</InfoText2>
          </dt>
          <dl>
            <InlineH4>{data.getMeterById?.commodityName ?? "--"}</InlineH4>
          </dl>
          <dt>
            <InfoText2>Customer Meter Id:</InfoText2>
          </dt>
          <dl>
            <InlineH4>{data.getMeterById?.customerMeterId ?? "--"}</InlineH4>
          </dl>
          <dt>
            <InfoText2>Include in Aggregates:</InfoText2>
          </dt>
          <dl>
            <InlineH4>
              {data.getMeterById?.meterAggregateTypes.join(", ")}
            </InlineH4>
          </dl>
        </dl>
        <PointsTable points={data.getMeterById.points} />
      </VerticalLayout>
    );
  } else {
    return (
      <div>
        <Body>Unknown Selection Type</Body>
      </div>
    );
  }
};
export const LoadedMeter = (props: { asset: Asset }) => {
  return (
    <>
      <BasicData asset={props.asset} />
      <AdvancedData asset={props.asset} />
    </>
  );
};
