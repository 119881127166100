import {
  AssetType,
  OverlayEventState,
} from "src/components/app/ExplorerPage/types";
import {
  ChartDataGranularity,
  PointType,
  ITimeRange,
} from "src/types/charting";
import { parse } from "query-string";
import { datadogRum } from "@datadog/browser-rum";

/* =============================================================== *\
  URLS look like this

   /explorer/aa726d75-3d10-4612-b572-33d4ada3142b
   ?granularity=FIFTEEN_MINUTE
   &buildings=c2cd6246-6e08-4400-88ee-e70b8839b1bb:STEAM_MASS;7570b924-5eb9-4293-9afc-591f7804b857:CHILLED_WATER_USAGE,TEMPERATURE
   &points=c89ab08b-fb8b-4f45-9c84-dce3d4598dc8:HOT_WATER_USAGE
   &timezone=America/New_York
   &start=2020-04-22T04:00:00.000Z
   &end=2020-04-29T04:00:00.000Z
\* =============================================================== */

export interface AssetData {
  id: string;
  commodities: string[];
}

const convertOverlaysToBinary = (overlays: OverlayEventState): number => {
  let overlaysBinaryFlag = 0;

  if (overlays) {
    if (overlays.IDENTIFIED) {
      overlaysBinaryFlag += 1;
    }
    if (overlays.IMPLEMENTED) {
      overlaysBinaryFlag += 2;
    }
    if (overlays.SCHEDULE_EXCEPTIONS) {
      overlaysBinaryFlag += 4;
    }
    if (overlays.THRESHOLDS) {
      overlaysBinaryFlag += 8;
    }
    if (overlays.PEAK_DEMAND) {
      overlaysBinaryFlag += 16;
    }
    if (overlays.HIGH_USAGE) {
      overlaysBinaryFlag += 32;
    }
    if (overlays.CUSTOM) {
      overlaysBinaryFlag += 64;
    }
    if (overlays.OPERATING_SCHEDULE) {
      overlaysBinaryFlag += 128;
    }
  }

  return overlaysBinaryFlag;
};

const buildURLFiltersFromState = (
  currentSearch: string,
  granularity: ChartDataGranularity,
  timeRange: ITimeRange,
  overlays: OverlayEventState,
): string => {
  const overlayBinary = convertOverlaysToBinary(overlays);

  if (timeRange && timeRange.startTime && timeRange.endTime && datadogRum) {
    datadogRum.addRumGlobalContext("graphqlQuery", {
      timeRange: {
        startTime: timeRange.startTime,
        endTime: timeRange.endTime,
        totalInMillis:
          Date.parse(timeRange.endTime) - Date.parse(timeRange.startTime),
      },
    });
  }

  const _s = parse(currentSearch);
  // No Buildings or points selected?
  // So the user is coming to the explorer page from hatch, just do nothing.
  if (!_s.buildings && !_s.points && !_s.groups && !_s.equipmentPoints) {
    return "";
  }
  return `?granularity=${granularity}${
    _s.buildings ? "&buildings=" + _s.buildings : ""
  }${_s.points ? "&points=" + _s.points : ""}${
    _s.equipmentPoints ? "&equipmentPoints=" + _s.equipmentPoints : ""
  }${_s.groups ? "&groups=" + _s.groups : ""}&timezone=${_s.timezone}&start=${
    timeRange.startTime
  }&end=${timeRange.endTime}&overlays=${overlayBinary}`;
};

const timezoneURLPart = (timezone: string | undefined) => {
  if (timezone && timezone !== "undefined") {
    return `&timezone=${timezone}`;
  }
  return "";
};

/** returns a URL with a new asset added to it */
const buildURLFromNewAssetSelection = (
  assetId: string,
  assetType: AssetType,
  currentSearch: string,
  granularity: ChartDataGranularity,
  timeRange: ITimeRange,
  commodity: PointType,
  timezone: string | undefined,
  overlays: OverlayEventState,
): string => {
  const _s = parse(currentSearch);

  const buildingsUrlParam = _s.buildings ? (_s.buildings as string) : "";
  const pointsUrlParam = _s.points ? (_s.points as string) : "";
  const groupsUrlParam = _s.groups ? (_s.groups as string) : "";
  const equipmentPointsUrlParam = _s.equipmentPoints
    ? (_s.equipmentPoints as string)
    : "";
  // do not change our timezone if we have one!
  const tzURLParam = _s.timezone ? (_s.timezone as string) : timezone;

  const granularityUrlParam = _s.granularity
    ? (_s.granularity as string)
    : granularity;
  const startUrlParam = _s.start ? (_s.start as string) : timeRange.startTime;
  const endUrlParam = _s.end ? (_s.end as string) : timeRange.endTime;
  const overlaysParam = _s.overlays
    ? parseInt(_s.overlays as string)
    : convertOverlaysToBinary(overlays);

  // TODO: refactor the heck out of this.
  if (AssetType.BUILDING === assetType) {
    const buildingURL = _addAssetInURL(buildingsUrlParam, assetId, commodity);
    return `?granularity=${granularityUrlParam}${
      buildingURL ? "&buildings=" + buildingURL : ""
    }${pointsUrlParam ? "&points=" + pointsUrlParam : ""}${
      groupsUrlParam ? "&groups=" + groupsUrlParam : ""
    }${
      equipmentPointsUrlParam
        ? "&equipmentPoints=" + equipmentPointsUrlParam
        : ""
    }${timezoneURLPart(
      tzURLParam,
    )}&start=${startUrlParam}&end=${endUrlParam}&overlays=${overlaysParam}`;
  }

  if (AssetType.GROUP === assetType) {
    const groupParam = _addAssetInURL(groupsUrlParam, assetId, commodity);
    return `?granularity=${granularityUrlParam}${
      buildingsUrlParam ? "&buildings=" + buildingsUrlParam : ""
    }${pointsUrlParam ? "&points=" + pointsUrlParam : ""}${
      groupParam ? "&groups=" + groupParam : ""
    }${
      equipmentPointsUrlParam
        ? "&equipmentPoints=" + equipmentPointsUrlParam
        : ""
    }${timezoneURLPart(
      tzURLParam,
    )}&start=${startUrlParam}&end=${endUrlParam}&overlays=${overlaysParam}`;
  }

  if (AssetType.EQUIPMENT_POINT === assetType) {
    const eqP = _addAssetInURL(equipmentPointsUrlParam, assetId, commodity);
    return `?granularity=${granularityUrlParam}${
      buildingsUrlParam ? "&buildings=" + buildingsUrlParam : ""
    }${pointsUrlParam ? "&points=" + pointsUrlParam : ""}${
      groupsUrlParam ? "&groups=" + groupsUrlParam : ""
    }${eqP ? "&equipmentPoints=" + eqP : ""}${timezoneURLPart(
      tzURLParam,
    )}&start=${startUrlParam}&end=${endUrlParam}&overlays=${overlaysParam}`;
  }

  const pointURL = _addAssetInURL(pointsUrlParam, assetId, commodity);
  return `?granularity=${granularityUrlParam}${
    buildingsUrlParam ? "&buildings=" + buildingsUrlParam : ""
  }${pointURL ? "&points=" + pointURL : ""}${
    groupsUrlParam ? "&groups=" + groupsUrlParam : ""
  }${
    equipmentPointsUrlParam ? "&equipmentPoints=" + equipmentPointsUrlParam : ""
  }${timezoneURLPart(
    tzURLParam,
  )}&start=${startUrlParam}&end=${endUrlParam}&overlays=${overlaysParam}`;
};

const _addAssetInURL = (
  assets: string,
  assetId: string,
  commodity: PointType,
): string => {
  let assetData = buildAssetDataFromURL(assets as string);

  const exsiting = assetData.find(asset => asset.id === assetId);
  if (exsiting) {
    exsiting.commodities.push(commodity);
  } else {
    assetData.push({ id: assetId, commodities: [commodity] });
  }

  return _reduceAssetListIntoQueryParam(assetData);
};

/** returns a URL with an asset REMOVED  */
const buildURLFromAssetRemoval = (
  assetId: string,
  assetType: AssetType,
  commodity: PointType,
  currentSearch: string,
): string => {
  const _s = parse(currentSearch);

  const buildingURL = _removeAssetFromURL(
    _s.buildings as string,
    assetId,
    commodity,
  );
  const groupURL = _removeAssetFromURL(_s.groups as string, assetId, commodity);
  const pointURL = _removeAssetFromURL(_s.points as string, assetId, commodity);
  const equipmentPointURL = _removeAssetFromURL(
    _s.equipmentPoints as string,
    assetId,
    commodity,
  );

  if (!buildingURL && !pointURL && !groupURL && !equipmentPointURL) {
    return "";
  }
  // TODO: refactor this!!!
  if (AssetType.BUILDING === assetType) {
    return `?granularity=${_s.granularity}${
      buildingURL ? "&buildings=" + buildingURL : ""
    }${_s.equipmentPoints ? "&equipmentPoints=" + _s.equipmentPoints : ""}${
      _s.points ? "&points=" + _s.points : ""
    }${_s.groups ? "&groups=" + _s.groups : ""}&timezone=${_s.timezone}&start=${
      _s.start
    }&end=${_s.end}&overlays=${_s.overlays}`;
  }

  if (AssetType.GROUP === assetType) {
    return `?granularity=${_s.granularity}${
      _s.buildings ? "&buildings=" + _s.buildings : ""
    }${_s.equipmentPoints ? "&equipmentPoints=" + _s.equipmentPoints : ""}${
      _s.points ? "&points=" + _s.points : ""
    }${groupURL ? "&groups=" + groupURL : ""}&timezone=${_s.timezone}&start=${
      _s.start
    }&end=${_s.end}&overlays=${_s.overlays}`;
  }

  if (AssetType.EQUIPMENT_POINT === assetType) {
    return `?granularity=${_s.granularity}${
      _s.buildings ? "&buildings=" + _s.buildings : ""
    }${equipmentPointURL ? "&equipmentPoints=" + equipmentPointURL : ""}${
      _s.points ? "&points=" + _s.points : ""
    }${_s.groups ? "&groups=" + _s.groups : ""}&timezone=${_s.timezone}&start=${
      _s.start
    }&end=${_s.end}&overlays=${_s.overlays}`;
  }
  return `?granularity=${_s.granularity}${
    _s.buildings ? "&buildings=" + _s.buildings : ""
  }${_s.equipmentPoints ? "&equipmentPoints=" + _s.equipmentPoints : ""}${
    pointURL ? "&points=" + pointURL : ""
  }${_s.groups ? "&groups=" + _s.groups : ""}&timezone=${_s.timezone}&start=${
    _s.start
  }&end=${_s.end}&overlays=${_s.overlays}`;
};

const _removeAssetFromURL = (
  assets: string,
  assetId: string,
  commodity: PointType,
): string => {
  let assetData = buildAssetDataFromURL(assets as string);

  const exsiting = assetData.find(asset => asset.id === assetId);
  if (exsiting) {
    // only do this if the last commodity was removed!
    if (exsiting.commodities.length === 1) {
      assetData = assetData.filter(asset => asset.id !== assetId);
    } else {
      exsiting.commodities = exsiting.commodities.filter(
        _c => _c !== commodity,
      );
    }
  }

  return _reduceAssetListIntoQueryParam(assetData);
};

const _reduceAssetListIntoQueryParam = (assetData: AssetData[]): string => {
  return assetData.reduce((acc, currVal, index) => {
    return index === 0
      ? `${currVal.id}:${currVal.commodities.join(",")}`
      : `${acc};${currVal.id}:${currVal.commodities.join(",")}`;
  }, "");
};

const buildAssetDataFromURL = (assetDataURL: string): AssetData[] => {
  if (!assetDataURL) {
    return [];
  }

  const assets = assetDataURL.split(";");
  let assetData: AssetData[] = [];

  for (const asset of assets) {
    const keyValues = asset.split(":");
    if (keyValues[0] && keyValues[1]) {
      const data: AssetData = { id: keyValues[0], commodities: [] };
      for (const commodity of keyValues[1].split(",")) {
        data.commodities.push(commodity);
      }
      assetData.push(data);
    }
  }

  return assetData;
};

export {
  buildURLFromNewAssetSelection,
  buildURLFromAssetRemoval,
  buildURLFiltersFromState,
  buildAssetDataFromURL,
};
