import styled from "styled-components/macro";
import { H3, InlineH4 } from "../Typography";

export * from "./Metric";
export * from "./MetricDivider";
export * from "./MetricWithIcon";
export * from "./MetricLayout";
export * from "./ComposedMetric";

export const MetricValue = styled(H3)<{ color?: string }>`
  color: ${props => (props.color ? props.color : "inherit")};
`;

export const MetricDescription = InlineH4;
