import {
  dateTimeToLocaleString,
  FormatOptions,
} from "@hatchdata/intl-formatter";
import { DateTime } from "luxon";
import {
  AnnotationEventType,
  MeasureEventType,
  OverlayEventType,
  ScheduleEventType,
  ThresholdEventType,
} from "src/components/app/ExplorerPage/types";
import {
  AssetBuilding,
  OverlayFormData,
  OverlayFormState,
} from "src/components/app/ExplorerPage/OverlayStuff/overlayReducer";
import { getLocalDateFormat } from "src/helpers/dates";

// formats a decimal hour like 4.75 into a time string
// sometimes it comes in as a string like "4.50" and sometimes
// it comes in as an actual number like 4.5
export function decimalToHourFormatter(val: string): string {
  const valAsString: string = val.toString();
  const time: number = parseFloat(valAsString);
  if (isNaN(time)) {
    return "";
  }

  const date = DateTime.local()
    .startOf("day")
    .set({ second: time * 60 * 60 });

  return dateTimeToLocaleString(
    date,
    undefined,
    undefined,
    FormatOptions.TIME_SIMPLE,
  );
}

export function xToDateTime(x: number, buildingTimezone: string) {
  return DateTime.fromJSDate(new Date(x)).setZone(buildingTimezone);
}

/**
 * Determines whether the given array of events contains the same type of event
 * @param events Array of possibly different event types
 * @returns true if events are NOT the same type; false if they are the same or
 * the array is empty
 */
export const includesDifferentEventTypes = (
  events: { eventType: OverlayEventType }[],
) => {
  if (!events || events?.length === 0) {
    return false;
  }

  // Compare first event to the rest
  const [firstEvent, ...rest] = events;
  const overlayType = mapEventTypeToOverlayType(firstEvent.eventType);

  for (const event of rest) {
    if (overlayType !== mapEventTypeToOverlayType(event.eventType)) {
      return true;
    }
  }
  return false;
};

/**
 * OverlayEventType can be divided into categories that include measure,
 * schedule exception, or annotation. Within those categories are subcategories
 * of event types that are even more specific. Given the most specific
 * eventType, this function determines which category it belongs to.
 * @param eventType
 * @returns Overlay type (category) the eventType belongs to
 */
export const mapEventTypeToOverlayType = (eventType: OverlayEventType) => {
  if (Object.values(MeasureEventType).includes(eventType as MeasureEventType)) {
    return MeasureEventType;
  }
  if (
    Object.values(ScheduleEventType).includes(eventType as ScheduleEventType)
  ) {
    return ScheduleEventType;
  }
  if (
    Object.values(ThresholdEventType).includes(eventType as ThresholdEventType)
  ) {
    return ThresholdEventType;
  }
  if (
    Object.values(AnnotationEventType).includes(
      eventType as AnnotationEventType,
    )
  ) {
    return AnnotationEventType;
  }
};

export const getStartAndEndDatesFromFormData = (
  inputFormData: OverlayFormData,
): { startDate: Date; endDate: Date } => {
  const startDate = inputFormData.startDate
    ? DateTime.fromJSDate(inputFormData.startDate, {
        zone: inputFormData.timezone,
      })
        .startOf("day")
        .setZone("local", { keepLocalTime: true })
        .set({ hour: inputFormData.startTime ?? 0 })
        .toJSDate()
    : DateTime.local()
        .startOf("day")
        .toJSDate();
  const endDate = inputFormData.endDate
    ? DateTime.fromJSDate(inputFormData.endDate, {
        zone: inputFormData.timezone,
      })
        .startOf("day")
        .setZone("local", { keepLocalTime: true })
        .set({ hour: inputFormData.endTime ?? 0 })
        .toJSDate()
    : DateTime.local()
        .startOf("day")
        .toJSDate();
  return {
    startDate: startDate,
    endDate: endDate,
  };
};

export const parseDateFromLocalDateFormat = (input: string) => {
  const date = DateTime.fromFormat(input, getLocalDateFormat());
  return date.isValid ? date.toJSDate() : undefined;
};

export const formatDateInLocalDateFormat = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat(getLocalDateFormat());
};

export const timeRangeEndsBeforeStart = (start: Date, end: Date): boolean => {
  return start > end ? true : false;
};
export const defaultBuildingForOverlayForm = (
  state: OverlayFormState,
): AssetBuilding | undefined => {
  if (state.ui.buildingsSelected.length === 1) {
    return state.ui.buildingsSelected[0];
  }
  if (state.formData.buildingId) {
    return (
      state.ui.buildingsSelected.find(
        x => x.id === state.formData.buildingId,
      ) ?? undefined
    );
  }
  return undefined;
};
